import React, { Component } from 'react';
import {Button, Form} from 'react-bootstrap';
import {getRequest, postRequest, getHumanReadableDate} from '../../globalhelper/helper';
import './COD.css';
import ApiConstants from '../../config'
import download from 'downloadjs';
import axios from 'axios'
import './COD.css'
import { CSVLink } from 'react-csv';
import Pagination1 from './Pagination';

export class StationNotPosted extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         client: [],   
         startdate: '',
         enddate: '',
         formData: {
            client: [],
            model: '',
            zone: '',
            stationcode: '',
            type: '',
            startdate: '',
            enddate: ''
         },
         error: '',
         fetching: false,
         buttonEnable: false,
         showtable: false,
         filterData: [],
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         dropdown_stationcode: [],
         uniqueStationCode: [],
         allData: [],
         tableData: [],
         currentPage: 1,
         postsPerPage: 15,
         tableDisplay: [],
         dropdown_type: []
      }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            fetching: true
        })
        getRequest('api/cod/getClient').then(response => {

            let client = response.res.data

            let dropdown_client = client.map(value=> value.client_short_name)

            this.setState({
                ...this.state,
                fetching: false,
                dropdown_client: dropdown_client
            })
        })

        getRequest(`api/cod/getDistinctStationCode`).then(response => {
            if(response.res.status === 'success'){
                let stationcode = response.res.data.map(value=> value.client_hub_code)

                this.setState({
                    ...this.state,
                    dropdown_stationcode: stationcode,
                    uniqueStationCode: stationcode
                })
            }
        })

        getRequest(`api/cod/getAllData`).then(response => {
            let data = JSON.parse(response.res.data)

            this.setState({
                ...this.state,
                allData: data,
                fetching: false
            })
        })

        getRequest(`api/cod/getStationsNotPostedDropdown`).then(response => {
            let data = response.res.data

            this.setState({
                ...this.state,
                dropdown_type: data
            })
        })
    }

    getRemainingValues = () => {
        getRequest(`api/cod/getRemainingValues?input=` + this.state.formData.stationcode).then(response => {
            if(response.res.status === 'success'){
                let client = response.res.data.map(value => value.client_short_name)
                let model = response.res.data.map(value => value.ci_business_type)
                let zone = response.res.data.map(value => value.zone)

                this.setState({
                    ...this.state,
                    dropdown_model: [model[0]],
                    dropdown_zone: [zone[0]],
                    formData: {
                        ...this.state.formData,
                        client: client[0],
                        model: model[0],
                        zone: zone[0]
                    }
                })
            }
        })
    }

    handleClientChange = (e) => {
        const VALUE = e.target.value
        let model = []
        
        this.setState({
            ...this.state,
            formData: {
                client: VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.client_short_name === VALUE){
                model.push(item.ci_business_type)
                return model
            }
        })

        let model1 = [... new Set(model)]

        this.setState({
            ...this.state,
            dropdown_model: model1,
            dropdown_stationcode: [],
            dropdown_zone: [],
            formData: {
                ...this.state.formData,
                client: VALUE,
                model: '',
                zone: '',
                stationcode: '',
                type: '',
                startdate: '',
                enddate: ''
            }
        })
    }

    handleModelChange = (e) => {
        const VALUE = e.target.value
        let zone = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.ci_business_type === VALUE && item.client_short_name === this.state.formData.client){
                zone.push(item.zone)
                return zone
            } 
        })

        let zone1 = [... new Set(zone)]

        this.setState({
            ...this.state,
            dropdown_zone: zone1,
            dropdown_stationcode: [],
            formData: {
                ...this.state.formData,
                model: VALUE,
                zone: '',
                stationcode: '',
                type: '',
                startdate: '',
                enddate: ''
            }
        })
    }

    handleZoneChange = (e) => {
        const VALUE = e.target.value
        let stationcode = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                zone: VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.ci_business_type === this.state.formData.model && item.client_short_name === this.state.formData.client && item.zone === VALUE){
                stationcode.push(item.client_hub_code)
                return stationcode
            } 
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formData: {
                ...this.state.formData,
                zone: VALUE,
                stationcode: '',
                type: '',
                startdate: '',
                enddate: ''
            }
        })
    }

    handleStationChange = (e) => {
        const VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                stationcode: VALUE
            }
        }, () => {
            this.getRemainingValues()
        })
    }

    handleTypeChange = (e) => {
        const VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                type: VALUE
            }
        })
    }

    handleChange = (event) => {
        const NAME = event.target.name;
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            error: '',
            buttonEnable: false,
            formData: {
                ...this.state.formData,
                enddate: '',
                [NAME] : VALUE
            }
            
        })
    }

    handleEndDateChange = (event) => {
        const VALUE = event.target.value 

        if(VALUE < this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'End date must be greater than start date',
                buttonEnable: false,
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        } else if (!this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'Please select start date first',
                buttonEnable: false,
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: '',
                formData: {
                    ...this.state.formData,
                    enddate: VALUE
                }
            }, () => this.handleEnable())
        }
    }

    handleEnable = () => {
        if(this.state.formData.client && this.state.formData.type && this.state.formData.startdate && this.state.formData.enddate){
            this.setState({
                ...this.state,
                buttonEnable: true
            })
        }
    }

    filterNotPostedContents = async () => {
        this.setState({
            ...this.state,
            fetching: true
        })
        await postRequest(`api/cod/filterNotPosted?startdate=${this.state.formData.startdate}&client=${this.state.formData.client}&enddate=${this.state.formData.enddate}&model=${this.state.formData.model}&zone=${this.state.formData.zone}&stationcode=${this.state.formData.stationcode}&status=${this.state.formData.type}`).then(response => {
             let filterData = JSON.parse(response.res.data)
            

             console.log(filterData)
 
             this.setState({
                 ...this.state,
                 fetching: false,
                 filterData: filterData
             }, () => {
                 this.downloadLink.link.click()
             })
         })
     }

     handleView = async () => {
        this.setState({
            ...this.state,
            fetching: true
        })

        await postRequest(`api/cod/filterNotPosted?startdate=${this.state.formData.startdate}&enddate=${this.state.formData.enddate}&client=${this.state.formData.client}&model=${this.state.formData.model}&zone=${this.state.formData.zone}&stationcode=${this.state.formData.stationcode}&status=${this.state.formData.type}`).then(response => {
            let tableData = JSON.parse(response.res.data)

            this.setState({
                ...this.state,
                fetching: false,
                showtable: true,
                tableData: tableData
            }, () => this.handlePagination())
        })
     }

     handlePagination = () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        // console.log(this.state.allData)
        let currentPosts = this.state.tableData.slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            tableDisplay: currentPosts
        })
    }

    paginate = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

     handleReset = () => {
        this.setState({
            ...this.state,
            error: '',
            buttonEnable: false,
            showtable: false,
            dropdown_model: [],
            dropdown_zone: [],
            dropdown_stationcode: this.state.uniqueStationCode,
            formData: {
                client: '',
                model: '',
                zone: '',
                stationcode: '',
                type: '',
                startdate: '',
                enddate: ''
            }
        })
     }

  render() {
    return (
        <div>
            <div className='header_div'>Stations Not Posted</div>

            <br/>

            {!this.state.fetching &&
            <div>
                <div>
                    <Button variant='success' style={{marginLeft:10}} className='pull-right' onClick={this.filterNotPostedContents} disabled={!this.state.buttonEnable}>Download</Button>
                    <CSVLink data={this.state.filterData} filename='Not Posted.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
                </div> 
                <div>
                    <Button className='pull-right' variant='danger' onClick={this.handleReset}>Reset Filter</Button>
                </div>   
            </div>
            }

            <br/>
            <br/>

            {!this.state.fetching &&

            <div className='row'>
            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Client</Form.Label>
                    <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_client.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Model</Form.Label>
                    <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_model.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Zone</Form.Label>
                    <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_zone.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Station Code</Form.Label>
                    <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_stationcode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <select className='spacing' value={this.state.formData.type} name='type' onChange={this.handleTypeChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_type.map((item, index) => <option value={item} key={index}>{item}</option>)}
                        {/* <option value='Deposit Not Posted'>Deposit</option>
                        <option value='Remittance Not Posted'>Remittance</option> */}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
            <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <input type='date' className='spacing' value={this.state.formData.startdate} name='startdate' onChange={this.handleChange}></input>
            </Form.Group>
            </div>

            <div className='col-md-3'>
            <Form.Group>
                <Form.Label>End Date</Form.Label>
                <input type='date' className='spacing' value={this.state.formData.enddate} name='enddate' onChange={this.handleEndDateChange}></input>
            </Form.Group>
            {this.state.error && <div style={{color: 'red'}}>{this.state.error}</div>}
            </div>
            </div>} 

            {!this.state.fetching &&
            <div className='wrapper'>
                <Button variant='warning' onClick={this.handleView} disabled={!this.state.buttonEnable}>View</Button>
            </div>}

            {this.state.fetching ? <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div>: null}
            <br/>
            <br/>

            {this.state.showtable &&
            <div>
                <table border='1' id='table_styling'>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Business Model</th>
                            <th>Zone</th>
                            <th>Station Code</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tableDisplay.map((item, index) => {
                            return(
                                <tr key={index}>
                                    <td>{item.client}</td>
                                    <td>{item.businessmodel}</td>
                                    <td>{item.zone}</td>
                                    <td>{item.stationcode}</td>
                                    <td>{getHumanReadableDate(item.Date)}</td>
                                    <td>{item.status}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>}

            {this.state.showtable &&
            <div>
                <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.tableData.length} paginate={this.paginate}></Pagination1>
            </div>}


            {/* {!this.state.fetching &&

            <div className='row'>
                <div className='col-md-2'>
                    <Button variant='warning' onClick={this.filterNotPostedContents} disabled={!this.state.startdate}>Download</Button>
                    <CSVLink data={this.state.filterData} filename='Not Posted.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
                </div>            
            </div>} */}
        </div>
      
    )
  }
}

export default StationNotPosted