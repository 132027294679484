import React, { Component } from 'react';
// import background from '../../assets/Audire_Login.svg';
import background_ from '../../assets/DataTwin First Page-1.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import '../../App.css';
import {Animated} from "react-animated-css";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ApiConstants from "../../config";
import axios from 'axios'; 
import { Redirect } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { onLogin, onRequestPassword, onLogout } from '../../actions/login_actions'
import { connect } from 'react-redux'
import Signup from './SignupNew'
// function importAll(r) {
//     return r.keys().map(r);
//   }
  
// const images = importAll(require.context('../../assets', false, /\.(png|jpe?g|svg)$/));
// console.log(images);
let interval;
class Login extends Component {
    
    constructor (props){
        super(props);
        this.state = {
            redirect : null,
            user_type : null,
            fields : {
                email : "",
                password : "",
                emailError : "Please Enter Email! ",
                passwordError : "Please Enter Password!"
            },
            formData : {
                email : '',
                password : '',
                phone : '',
                organization : '',
                username : '',
                type : 'organization',
                city : '',
                state : '',
                country : '',
                address1 : '',
                address2 : '',
                zip : '',
            },
            error : {
                email : false,
                password : false
            },
            pages : {
                login : true,
                signup : false,
                forgetPassword : false
            },
            passwordType : 'password',
            loginSuccess : false,
            loginError : false,
            errorMessage : "",
            alert : "",
            buttonDisabled : true,
            organizations : [],
            showModal : false
        }

        var d = window.location.pathname;

        let signup = d.includes('signup') ? true : false;

        console.log(signup, 'signup')

        if(signup){

            this.state.pages.signup = true;
            this.state.pages.login = false;
            this.state.pages.forgetPassword = false;
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      

      handleChange(event) {
        var id = [event.target.id];
        this.setState({
            formData: {
                  ...this.state.formData,
                  [id]: event.target.value
            }
        })
      }

      validateForm = () => {
          var formData = this.state.formData;

          if(this.state.pages.login)
          {
              if(formData.email !== "" && formData.password !== "")
              {
                  this.setState({
                      ...this.state,
                    buttonDisabled : false
                  })
              }
              else{
                  this.setState({
                      ...this.state,
                    buttonDisabled : true
                  })
              }
          }
          else if (this.state.pages.signup)
          {
            if(formData.email !== "" && formData.password !== ""  && formData.phone !== ""  && formData.type !== ""  && formData.username !== "" && formData.address1 !== "" && formData.address2 !== "" && formData.city !== "" && formData.state !== "" && formData.country !== "" && formData.zip !== "")
            {
                if(formData.type === 'self')
                {
                    this.setState({
                        ...this.state,
                      buttonDisabled : false
                    })
                }
                else {
                    if(formData.organization !== "")
                    {
                        this.setState({
                            ...this.state,
                          buttonDisabled : false
                        })
                    }
                    else{  
                        this.setState({
                            ...this.state,
                        buttonDisabled : true
                        })
                    }
                }
            }
            else{
                this.setState({
                    ...this.state,
                  buttonDisabled : true
                })
            }
          }
          else if (this.state.pages.forgetPassword)
          {
            if(formData.email !== "")
            {
                this.setState({
                    ...this.state,
                  buttonDisabled : false
                })
            }
            else{
                this.setState({
                    ...this.state,
                  buttonDisabled : true
                })
            }
          }

      }

      componentDidMount() {
        interval = setInterval(() => {
            this.validateForm();
        }, 1000);
      }
    
      componentWillUnmount() {
        clearInterval(interval);
      }
      
      handleSubmit =(event) => {
          console.log("login")
        event.preventDefault();
        this.props.onLogout();
        let userData = {
            email : this.state.formData.email,
            password : this.state.formData.password
        };
        console.log(userData, "USER DATA")
        // this.props.onLogin(userData)
        axios.post(ApiConstants.externals.serverUrl + 'api/user/getOrganisations', userData)
        .then(response => {
            if (response.data.status === "error") {
                this.setState({alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{response.data.message}</strong></div> </Animated> });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
                // return ()
            } 
            else if (response.data.status === "warning") {
                this.setState({alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-warning"><strong>{response.data.message}</strong></div> </Animated> });
                setTimeout(() => { this.setState({alert : null}); }, 3000);
                // return ()
            } 
            else {
                if(response.data.data.org.length === 1)
                {
                    this.getLoggedIn(response.data.data.org[0].Tenant_ID);
                }
                else{
                    this.setState({
                        ...this.state,
                        showModal : true,
                        organizations : response.data.data.org,
                        user_type : response.data.data.user_type
                    })
                }
            }
        })
        .catch(errr => {
            this.setState({alert : <div className="alert alert-danger"><strong>Problem with connecting Server</strong></div> });
            setTimeout(() => { this.setState({alert : null}); }, 3000);
        })
        this.setState({buttonDisabled : false});

      }
    
      getLoggedIn = (tenant_id) => {
          
        let userData = {
            email : this.state.formData.email,
            password : this.state.formData.password,
            tenantId : tenant_id
        };
        localStorage.setItem("tenant_id",tenant_id)
        this.setState({buttonDisabled : true});
        this.props.onLogin(userData);
        this.setState({buttonDisabled : false});
      }

      onRegister = (event) => {
        this.setState({buttonDisabled : true});
        event.preventDefault();
        let userData = this.state.formData;
          axios.post( ApiConstants.externals.serverUrl + "api/user/register", userData)
            .then(response => {
                if (response.data.status === "error") {
                    this.setState({alert : <div className="alert alert-danger"><strong>{ response.data.message.response ? response.data.message.response : response.data.message }</strong></div> });
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                    // return ()
                  } else {
                    this.setState({alert : <div className="alert alert-success"><strong>A verification email has been sent to the registered ID.</strong></div> });
                    localStorage.setItem("tempTenant", response.data.data.tempTenant);
                    localStorage.setItem("tempOrg", response.data.data.tempOrg);
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                    // this.$router.push("/modules");
                    this.setState({buttonDisabled : false});
                  }
            });
        }

        onforgot = (event) => {
            this.setState({buttonDisabled : true});
            event.preventDefault();
            const payload = {
                email: this.state.formData.email
            };
            axios
              .post( ApiConstants.externals.serverUrl + "api/user/passwordReset", payload)
              .then(response => {
                if (response.data.data.status === "failed") {
                    this.setState({alert : <div className="alert alert-danger"><strong>{response.data.data.message}</strong></div> });
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                    // return ()
                  } else {
                    this.setState({alert : <div className="alert alert-success"><strong>Password Reset link sent to the provided e-mail.</strong></div> });
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                    // this.$router.push("/modules");
                    this.setState({buttonDisabled : false});
                }
            })
            .catch(err => console.log(err));
        }

        togglePassword = () => {
            if(this.state.passwordType === 'password')
            {
                this.setState({ passwordType : 'text' });
            }
            else{
                this.setState({ passwordType : 'password' });
            }
        }


        checkValid = () => {
            window.event.preventDefault();
            var form = document.getElementById('login');
            if(true)
            {
                this.handleSubmit();
            }
            console.log(this.state);
        }


        navigator = (nav) => {
            if(nav === 'signup')
            {
                this.setState({
                    ...this.state,
                    buttonDisabled : true,
                    pages : {
                        login : false,
                        signup : true,
                        forgetPassword : false
                    },
                });
            }
            else if(nav === 'login')
            {
                this.setState({
                    ...this.state,
                    buttonDisabled : true,
                    pages : {
                        login : true,
                        signup : false,
                        forgetPassword : false
                    }
                });
            }
            else if(nav === 'forgetPassword')
            {
                this.setState({
                    ...this.state,
                    buttonDisabled : true,
                    pages : {
                        login : false,
                        signup : false,
                        forgetPassword : true
                    }
                });
            }
            console.log(this.state);
        }

        toggleModal = () => {
            this.setState({
                ...this.state,
                showModal : false
            })
        }

        handleCallback = (input) => {

            if(input === 'signup'){

                this.setState({
                    ...this.state,
                    buttonDisabled : true,
                    pages : {
                        login : true,
                        signup : false,
                        forgetPassword : false
                    }
                });
            }
        }
              

    render (){
        
        var graphImage =    require('../../assets/logo.png')
        
        function getSource(tenant_id) {
            var t = tenant_id ? tenant_id + '.png' : 'user.svg';
            // const images = require.context('../../assets', true);
            // loadImage = imageName => (assets(`./${imageName}`).default);
            console.log(t);
            try{
                graphImage = require(`../../assets/${t}`)
            }
            catch(err){
                console.log(err);
            }
            finally {
                console.log(graphImage);
                return graphImage;
            }
        }
        // setSource()
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <>

            
            <Modal
                show={this.state.showModal}
                onHide={() => this.toggleModal()}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Multiple Organizations Available</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <h6> Multiple Organizations Available for your Account. Choose one to continue.</h6>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row ">
                    { this.state.organizations.map(org => 
                        <div className="col-md-3 org-container" key={ org.Tenant_ID } onClick={ () => this.getLoggedIn(org.Tenant_ID) }>
                            <img alt={ org.Organisation } title={ org.Organisation } className="logo-box"  src={ ApiConstants.externals.serverUrl + "api/user/getTenantImage?tenant_id=" + org.Tenant_ID +"&usr_type=" + this.state.user_type }></img>
                            {/* <b> { org.Organisation } </b> */}
                        </div>
                    )}
                    </div>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary">Understood</Button> */}
                </Modal.Footer>
            </Modal>


            <div className="row login-main">
                <div className="col-md-6 login-sub1">
                    <img src={background_} alt="bg" className="bg"/>
                </div>
                {/* <div className={`col-md-6 flex-screen`} style={{ marginTop : this.state.pages.signup ?  '0px' : '175px'  }} > */}
                <div className={`col-md-6 align-self-center`} style={{ marginTop : this.state.pages.signup ?  '80px' : '175px'  }} >
                    {/* <div className="container" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'fit-content'}}> */}
                    <div className={`container ${ this.state.pages.signup ? null : 'login-padding' }`} align='center'>
                        <form className="needs-validation" id="login" noValidate>
                            <div className="col-md-12 ">
                                <b> <h3 className="audire-header">DataTwin</h3> </b>
                            </div>

                            { this.state.pages.login  ? (
                                <div>
                                    <div className="row">
                                        <h6 className="grey-text">Welcome back! Please login to your account.</h6>
                                        <br/><br/>
                                    </div>
                                    <div className="row">
                                        <input type="email" value={ this.state.formData.email } className={ this.state.error.email ? 'col-md-12 login-textbox' + ' error' : 'col-md-12 login-textbox' } id="email" placeholder="E-mail" onChange={ this.handleChange } required/>
                                        { this.state.error.email  ? 
                                        <div>
                                            <p className="text-danger" style={{fontSize : '14px', fontWeight : '400'}} > {this.state.fields.emailError} </p>
                                        </div>
                                        : null }
                                    </div>
                                    <br/>

                                    <div className="row">
                                        <div className="input-group mb-3">
                                            <input type={this.state.passwordType} value={this.state.password} className={ this.state.error.password ? 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' + ' error' : 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' } id="password" placeholder="Password" onChange={ this.handleChange } required />
                                            <span className="input-group-append bg-white border-left-0 border-right-0 border-top-0">
                                                <span className="input-group-text bg-transparent border-left-0 border-right-0 border-top-0  login-textbox">
                                                { this.state.passwordType === 'password' ? <VisibilityIcon style={{ fontSize : 17 }} onClick={ this.togglePassword } /> : <VisibilityOffIcon style={{ fontSize : 17 }} onClick={ this.togglePassword } /> }
                                                </span>
                                            </span>
                                        </div>
                                        
                                        { this.state.error.password ? 
                                        <div>
                                            <p className="text-danger" style={{fontSize : '14px', fontWeight : '400'}} > {this.state.fields.passwordError} </p>
                                        </div>  : null }
                                    </div><br/>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <button className="btn btn-purple"  type="submit" disabled={this.state.buttonDisabled} onClick= { this.handleSubmit }>Submit</button>
                                            {/* <button className="btn btn-default" type="button" onClick= { () => this.navigator('signup') } >Sign Up</button> */}
                                        </div>
                                    </div> <br/>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <a  onClick= { () => this.navigator('forgetPassword') } className="ui_link">Forgot Password?.</a>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            )
                                : (
                                    this.state.pages.signup ? (
                                        <div>
                                            < Signup parentCallback = {this.handleCallback} />
                                            {/* <div className="row text-center">
                                                <h6 className="grey-text"> Please complete to create your account. </h6> <br/>
                                            </div>

                                            <div className="row">
                                                <input type="text" value={ this.state.formData.username } className={ this.state.error.email ? 'col-md-12 login-textbox' + ' error' : 'col-md-12 login-textbox' } id="username" name="username" autoComplete="off" placeholder="Username" onChange={ this.handleChange } required/>
                                            </div><br/>

                                            <div className="row">
                                                <input type="text" value={ this.state.formData.phone } className={ this.state.error.email ? 'col-md-12 login-textbox' + ' error' : 'col-md-12 login-textbox' } id="phone" name="phone" placeholder="Phone" onChange={ this.handleChange } required/>
                                            </div><br/>

                                            <div className="row">
                                                <input type="email" value={ this.state.formData.email } className={ this.state.error.email ? 'col-md-12 login-textbox' + ' error' : 'col-md-12 login-textbox' } id="email" name="email" placeholder="Email" autocomplete="off" onChange={ this.handleChange } required/>
                                            </div><br/>

                                            

                                            <div className="row">
                                                <div className="input-group mb-3">
                                                    <input type={this.state.passwordType} value={this.state.password} className={ this.state.error.password ? 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' + ' error' : 'form-control border-right-0 border-top-0 border-left-0 col-md-11 login-textbox' } id="password" name="password" placeholder="Password" onChange={ this.handleChange } required />
                                                    <span className="input-group-append bg-white border-left-0 border-right-0 border-top-0">
                                                        <span className="input-group-text bg-transparent border-left-0 border-right-0 border-top-0  login-textbox">
                                                        { this.state.passwordType === 'password' ? <VisibilityIcon style={{ fontSize : 17 }} onClick={ this.togglePassword } /> : <VisibilityOffIcon style={{ fontSize : 17 }} onClick={ this.togglePassword } /> }
                                                        </span>
                                                    </span>
                                                </div>

                                                { this.state.error.password ? 
                                                <div>
                                                    <p className="text-danger" style={{fontSize : '14px', fontWeight : '400'}} > {this.state.fields.passwordError} </p>
                                                </div>  : null }
                                            </div>

                                            <div className="row">
                                               
                                                <select id="type" name="type" value={ this.state.formData.type } className={ this.state.formData.type === 'self' ? 'col-md-12 login-textbox' : 'col-md-12 login-textbox'} onChange={ this.handleChange } required>
                                                    <option key="organization" value="organization">Organization</option>
                                                    <option key="self" value="self">Self</option>
                                                </select>

                                                <div className="col-md-1"></div>

                                            </div><br />

                                                { this.state.formData.type === "organization" ?
                                                    <>
                                                        <div className="row">
                                                            <input type="text" value={ this.state.formData.organization } className='col-md-12 login-textbox' id="organization" name="organization" autocomplete="off" placeholder="Organization" onChange={ this.handleChange } required/>
                                                        </div><br/>
                                                    </>
                                                : null
                                                }


                                            
                                            <div className="row">
                                                <input type="text" value={ this.state.formData.address1 } className={ this.state.error.email ? 'col-md-12 login-textbox' + ' error' : 'col-md-12 login-textbox' } id="address1" name="address1" placeholder="Address 1" onChange={ this.handleChange } required/>
                                            </div><br/>

                                            
                                            <div className="row">
                                                <input type="text" value={ this.state.formData.address2 } className={ this.state.error.email ? 'col-md-12 login-textbox' + ' error' : 'col-md-12 login-textbox' } id="address2" name="address2" placeholder="Address 2" onChange={ this.handleChange } required/>
                                            </div><br/>

                                            
                                            <div className="row">
                                                <input type="text" value={ this.state.formData.city } className={ this.state.error.email ? 'col-md-5 login-textbox' + ' error' : 'col-md-5 login-textbox' } id="city" name="city" placeholder="City" onChange={ this.handleChange } required/>

                                                <div className="col-md-2"></div>

                                                <input type="text" value={ this.state.formData.state } className={ this.state.error.email ? 'col-md-5 login-textbox' + ' error' : 'col-md-5 login-textbox' } id="state" name="state" placeholder="State" onChange={ this.handleChange } required/>
                                            </div><br/>

                                            

                                            
                                            <div className="row">
                                                <input type="text" value={ this.state.formData.country } className={ this.state.error.email ? 'col-md-5 login-textbox' + ' error' : 'col-md-5 login-textbox' } id="country" name="country" placeholder="Country" onChange={ this.handleChange } required/>

                                                <div className="col-md-2"></div>
                                                
                                                <input type="text" value={ this.state.formData.zip } className={ this.state.error.email ? 'col-md-5 login-textbox' + ' error' : 'col-md-5 login-textbox' } id="zip" name="zip" placeholder="Zip" onChange={ this.handleChange } required/>
                                            </div><br/>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button className="btn btn-purple" disabled={this.state.buttonDisabled}  type="submit" onClick= { this.onRegister }>Submit</button>
                                                </div><br/><br/>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <a  onClick= { () => this.navigator('login') } className="ui_link">Already have an account? Sign in.</a>
                                                </div>
                                            </div><br/> */}

                                        </div>
                                    )
                                    : (
                                        <div>
                                            <div className="row">
                                                <h6 className="grey-text">Enter your email and we send you a password reset link.</h6>
                                                <br/><br/>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <input type="email" value={ this.state.formData.email } className={ this.state.error.email ? 'col-md-8 login-textbox' + ' error' : 'col-md-8 login-textbox' } id="email" placeholder="E-mail" onChange={ this.handleChange } required/>
                                                    { this.state.error.email  ? 
                                                    <div>
                                                        <p className="text-danger" style={{fontSize : '14px', fontWeight : '400'}} > {this.state.fields.emailError} </p>
                                                    </div>
                                                    : null }
                                                </div>
                                            </div>
                                            <br/>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button className="btn btn-purple" type="submit" disabled={this.state.buttonDisabled}  onClick= { this.onforgot }>Send Request</button>
                                                </div>
                                            </div> <br/>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <a  onClick= { () => this.navigator('login') } className="ui_link">Go Back.</a>
                                                </div>
                                            </div>
                                            <br/>
                                        </div>
                                    )
                                )
                            }   
                            {this.state.alert}
                        </form> 
                    </div>
                </div>
            </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
      state
    }
  };


export default connect(
    mapStateToProps,
    { onLogin, onRequestPassword, onLogout  }
)(Login);
