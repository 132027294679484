import React, { Fragment, PureComponent  } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getRequest, postRequest } from '../globalhelper/helper';
import _ from 'lodash';
import Popup from '../components/Rodal_popup'


const DATA_TYPES = [
    {
        name : "VARCHAR",
        value : "VARCHAR",
    },
    {
        name : "INTEGER",
        value : "INTEGER",
    },
    {
        name : "FLOAT",
        value : "FLOAT",
    },
    {
        name : "DATE",
        value : "DATE",
    },
    {
        name : "DATETIME",
        value : "DATETIME",
    }
]

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.text}
    </Tooltip>
  );

class CreateKG extends PureComponent {

    constructor(props){
        super(props);
        this.state = {
            showPopup : false,
            groups : [],
            lockInputs : false,
            generateDisabled : false,
            showRelationShipModal : false,
            relationship_modal_data : {
                relationships : []
            },
            acronyms : [],
            selectedModule : {},
            modules : [],
            process : [],
            module_process : [],
            types : [
                {
                    name : 'Product',
                    value : 'PRODUCT'
                },
                {
                    name : 'Group Company',
                    value : 'GROUP_COMPANY'
                },
            ],
            tasks : [
                {
                    name : 'Work with Process',
                    value : 'work_with_process'
                },
                {
                    name : 'Map process to Tenant',
                    value : 'ProcessVsModule'
                },
            ],
            data_stores : [
                {
                    name : 'DynamoDB',
                    value : 'DynamoDB'
                },
                {
                    name : 'S3',
                    value : 'S3'
                },
            ],
            showError : true,
            newTenant : false,
            formData : {
                process_name : "",
                process_code : "",
                type : "",
                task : "",
                system : "",
                data_store : "",
                columns : "",
                headers : [{}],
            }
        }
    }

    getProcessForModule = (module, arr="process") => {
        this.setState({
            ...this.state,
            process : [],
            process_code : ""
        }, () => {
            var modules = JSON.parse(JSON.stringify(this.state.modules));
            var selectedModule = {};
            modules.map(item => {
                if (item.value === module) {
                    selectedModule = item;
                    this.setState({
                        ...this.state,
                        selectedModule : arr === "process" ? selectedModule : this.state.selectedModule
                    }, () => {
                        getRequest('api/kg/getProcessForModule?module=' + module + "&type=" + selectedModule.type).then(result => {
                            if(result.type === "success")
                            {
                                result.res.data.sort((a,b) => a.name - b.name)
                                result.res.data.splice(0,0,{
                                    name : "Create New",
                                    value : 'new'
                                });
                                this.setState({
                                    ...this.state,
                                    [arr] : result.res.data
                                })
                            }
                        })
                    })
                }
            });
        })
    }

    handleChange = (ev) => {
        var name = ev.target.name;
        var value = ev.target.value;
        console.log(name, value);
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                [name] : value
            }
        }, () => {
            if(name === "tenant_id")
            {
                if(value === "new")
                {
                    this.setState({
                        ...this.state,
                        newTenant : true
                    })
                }
                else{
                    this.setState({
                        ...this.state,
                        newTenant : false
                    })
                    this.getProcessForModule(value);
                }
            }
            else if(name === "process_code")
            {
                this.onNext();
            }
            else if(name === "module")
            {
                this.getProcessForModule(value, "module_process");
            }
            console.log(this.state.formData);
        })
    }

    componentDidMount = () => {
        this.getAllTenantSB();
    }

    handleHeaderChange = (ev) => {
        const NAME = ev.target.name, VALUE = ev.target.value;
        console.log(NAME, VALUE)
        this.setState(prevState => {
            var header = prevState.formData.headers[0];
            header[NAME] = VALUE;
            return {
                ...prevState,
                formData : {
                    ...prevState.formData,
                    headers : [
                        header
                    ]
                }
            };
        })

    }

    onNext = () => {
        if(this.state.formData.process_code === "new")
        {
            var headers = [
                {
                    acronym: "HDR",
                    domain: "",
                    label: "",
                    process_code: "",
                    relationships: [],
                    rows: [],
                    short_text: "",
                    tenant_id: ""
                }
            ]
            this.setState({
                ...this.state,
                formData : {
                    ...this.state.formData,
                    headers : headers,
                },
                lockInputs : this.state.formData.process_code && this.state.formData.tenant_id ? true : false,
                showError : this.state.formData.process_code && this.state.formData.tenant_id ? false : true,
            })
            
        }
        else{

            var url = 'api/kg/getGraph?tenant_id=' + this.state.formData.tenant_id + "&process_code=" + this.state.formData.process_code;

            if(this.state.formData.task === "ProcessVsModule")
            {
                url = 'api/kg/getGraph?tenant_id=' + this.state.formData.module + "&process_code=" + this.state.formData.process_code;
            }

            getRequest(url).then(result => {
                if(result.type === "success")
                {
                    this.setState({
                        ...this.state,
                        formData : {
                            ...this.state.formData,
                            headers : result.res.data,
                        },
                        acronyms : result.res.data[0].acronyms
                    }, () => {

                        let groups = []

                        var headers = JSON.parse(JSON.stringify(this.state.formData.headers));
                        headers.map((data, headerInd) => {
                            data.rows.map((row,rowInd) => {
                                row.group && row.group !== "null" && groups.push(row.group)
                                if(row.derive_formula !== "" && row.derive_formula !== undefined)
                                {
                                    var ev = {
                                        target : {
                                            name : "derive_formula",
                                            value : row.derive_formula
                                        }
                                    }
                                    console.log(ev,headerInd,rowInd, "237");
                                    this.onDeriveChange(ev,headerInd,rowInd);
                                }
                            })
                        })
                        this.setState({
                            ...this.state,
                            groups : [...new Set(groups)],
                            lockInputs : this.state.formData.process_code && this.state.formData.tenant_id ? true : false,
                            showError : this.state.formData.process_code && this.state.formData.tenant_id ? false : true,
                        })
                    })
                }
            })
        }
    }

    // senseDataType = (data) => {
        
    // }

    onColumnsChange = (ev) => {
        var name = ev.target.name;
        var value = ev.target.value;
        var splittedColumns = value.split("\n");
        // console.log(value, splittedColumns);
        var rows = [];
        var prevHeader = JSON.parse(JSON.stringify(this.state.formData.headers[0]))
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                headers : []
            }
        }, () =>  {
            var acronyms = [];
            splittedColumns.map((column, colInd) => {
                if(column !== "")
                {
                    var child_nodes = [];
                    var newColumns = column.split("\t");
                    newColumns.map((newCol, newColInd) => {
                        if(newCol !== ""){
                            var newRelation = {
                                type : 'basic',
                                from  : "HDR",
                                to : (newCol.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join(""),
                                derive_type : ""
                            }
                            rows.push({
                                short_text : newCol,
                                domain : (newCol.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join(""),
                                label : prevHeader.process_code + "_" + (newCol.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join(""),
                                data_type : "VARCHAR",
                                default_value : "",
                                mandatory : false,
                                lde : "",
                                group : "",
                                derive_formula : "",
                                predefined_list : "",
                                integerity_check : "",
                                relationships: [ newRelation ],
                                acronym : prevHeader.process_code + "_" + (newCol.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join("")
                            });
                            acronyms.push({
                                name : prevHeader.process_code + "_" + (newCol.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join(""),
                                value : prevHeader.process_code + "_" + (newCol.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join("")
                            })

                            child_nodes.push({
                                type : 'basic',
                                from  : "HDR",
                                to : (newCol.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join(""),
                                derive_type : "",
                                toConst : ""
                            });
                        }
                    })
                }
                var header = {
                    label : prevHeader.process_code + "_" + + "Header",
                    process_code : prevHeader.process_code,
                    process_name : prevHeader.process_name,
                    short_text : prevHeader.short_text,
                    data_store : prevHeader.data_store,
                    system : prevHeader.system,
                    domain : "Header",
                    tenant_id : prevHeader.tenant_id,
                    acronym : "HDR",
                    relationships: child_nodes,
                    rows : rows
                }
                if(colInd === splittedColumns.length -1)
                {
                    // acronyms.push({
                    //     name : "Custom Constant",
                    //     value : "constant"

                    // })
                    this.setState({
                        ...this.state,
                        formData : {
                            ...this.state.formData,
                            [name] : value,
                            headers : [ header,...this.state.formData.headers ]
                        },
                        acronyms : acronyms
                    })
                }
            })
    })
    }

    onChange = () => {
        window.location.reload();
    }

    onAdd = (headerInd,ind) => {
        var headers = this.state.formData.headers;
        // var rows = header.rows;
        var newItem = {
            short_text : "",
            domain : "",
            label : "",
            default_value : "",
            relationships : [
                {
                    type : 'basic',
                    from  : "HDR",
                    to : "",
                    derive_type : "",
                    toConst : ""  
                }
            ]
        }
        headers[headerInd].rows.splice(ind + 1,0,newItem);
        // console.log(rows);
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                headers : headers
            }
        })
    }

    onAddRelation = (headerInd,rowInd, relInd) => {
        console.log(headerInd,rowInd,relInd)
        var headers = this.state.formData.headers;
        var row = headers[headerInd].rows[rowInd];
        var newItem = {
            type : 'derive',
            from  : (row.short_text.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join(""),
            to : "",
            derive_type : "NODE",
            toConst : ""
        }
        row.relationships.splice(relInd + 1,0,newItem);
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                headers : headers
            },
            relationship_modal_data : {
                ...row,
                rowInd,
                headerInd
            }
        })
    }

    onRemove = (headerInd,ind) => {
        var headers = this.state.formData.headers;
        headers[headerInd].rows.splice(ind,1);
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                headers : headers
            }
        })
    }

    onRemoveRelation = (headerInd,rowInd, relInd) => {
        var headers = this.state.formData.headers;
        var row = headers[headerInd].rows[rowInd];
        row.relationships.splice(relInd,1);
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                headers : headers
            }
        })
    }

    handleInput = (ev, headerInd, ind, type='input') => {
        var name = ev.target.name, value = type === "checkbox" ? ev.target.checked : ev.target.value;;
        console.log(name, value, headerInd, ind );

        if(name === "group" && value === "create_new_group"){

            let event={}

            event.target = {}

            event.target.name = name

            this.setState({ showPopup: true, headerInd, ind, ev : event })
        }
        else {
            this.setState(prevState => {
                var formData = prevState.formData;
                var rows = formData.headers[headerInd].rows;
                var currentItem = rows[ind];
                currentItem[name] = value;
                if(name === "short_text")
                {
                    currentItem.relationships[0] = {
                            type : 'basic',
                            from  : "HDR",
                            to : (value.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join(""),
                            derive_type : "",
                            toConst : ""  
                        }
                }
                currentItem.domain = (currentItem.short_text.replace(/[^a-zA-Z0-9 ]/g, "")).split(" ").join("");
                currentItem.label = formData.headers[headerInd].process_code + "_" + currentItem.domain;
                var acronyms = prevState.acronyms;
    
                if(name === "acronym")
                {
                    acronyms = [];
                    rows.map(row => {
                        acronyms.push({
                            name : row.acronym,
                            value : row.acronym,
                        })
                    })
                }
    
                // prevState.formData.rows[ind][name] = value;
                return {...prevState, acronyms, formdata : { ...prevState.formData, rows : rows } };
            })
            // row[ev.target.name] = ev.target.value
        }
    }

    onDeriveChange = (ev, headerInd, ind) => {
        var name = ev.target.name, value = ev.target.value;
        var process_code = this.state.formData.headers[0].process_code;
        // console.log("process_code : ",this.state.formData.headers[0].process_code);
        this.setState(prevState => {
            var formData = prevState.formData;
            var rows = formData.headers[headerInd].rows;
            var currentItem = rows[ind];
            console.log(formData.process_code + "_", currentItem, value);
            if(value.includes(process_code + "_"))
            {
                console.log(prevState.acronyms, "prevState.acronyms")
                currentItem.relationships.length = 1;
                prevState.acronyms.map((acr, ind) => {
                    if(value.includes(acr.value))
                    {
                        rows.map((row, indx) => {
                            if(row.acronym === acr.value)
                            {
                                currentItem.relationships.push({ 
                                    type : 'derive',
                                    from  : row.acronym,
                                    to : currentItem.acronym,
                                    derive_type : "NODE",
                                    toConst : "",
                                    relType :"from_derive"
                                })
                                // currentItem.relationships = [...new Set(currentItem.relationships)];
                                currentItem.relationships = _.uniqBy(currentItem.relationships, 'from');
                            }
                            // if(indx === rows.length - 1)
                            // {
                            // }
                        })
                    }
                })
                // var indices = [];
                // for(var i=0; i<value.length;i++) {
                //     if (value[i] === process_code + "_") indices.push(i);
                // }
                // console.log(value, indices);
                // for(var i=0; i < indices.length; i += 2)
                // {
                //     var acronym = value.substring(indices[i] +1, Number(indices[i + 1]) );
                    
                // }
            }
            // prevState.formData.rows[ind][name] = value;
            return {...prevState, formdata : { ...prevState.formData, rows : rows } };
        })
        // row[ev.target.name] = ev.target.value
    }

    handleRelationInput = (ev, headerInd, rowInd, relInd) => {
        var name = ev.target.name, value = ev.target.value;
        this.setState(prevState => {
            var formData = prevState.formData;
            var rows = formData.headers[headerInd].rows;
            var currentItem = rows[rowInd];
            currentItem.relationships[relInd][name] = value;
            // prevState.formData.rows[ind][name] = value;
            return {...prevState, formdata : { ...prevState.formData, rows : rows } };
        })
        // row[ev.target.name] = ev.target.value
    }

    getAllTenantSB = () => {
        getRequest('api/kg/getAllModules').then(result => {
            if(result.type === "success")
            {
                result.res.data.sort((a,b) => a.name - b.name)

                // result.res.data

                result.res.data.splice(0,0,{
                    name : "Create New",
                    value : 'new'
                });
                this.setState({
                    ...this.state,
                    modules : result.res.data
                })
            }
        })
    }

    openRelationshipModal = (headerInd, rowInd, row) => {

        this.setState({
            ...this.state,
            relationship_modal_data : {
                ...row, 
                rowInd : rowInd,
                headerInd : headerInd
            }
        }, () => {
            this.setState({
                ...this.state,
                showRelationShipModal : true
            })
        })
    }

    closeRelationshipModal = () => {
        this.setState({
            ...this.state,
            showRelationShipModal : false
        })
    }

    onSubmit = () => {
        this.setState({
            ...this.state,
            generateDisabled : true
        }, () => {
            var FORMDATA = Object.assign({}, this.state.formData);
            FORMDATA.type = FORMDATA.type !== "" ? FORMDATA.type : this.state.selectedModule.type;
            delete FORMDATA.columns;
            console.log(FORMDATA)
            postRequest('api/kg/generateQueryFile', { data : JSON.stringify(FORMDATA) }).then(res => {
                console.log(res)
                // if(res.type === "success" || res.type === "warning")
                // {
                    // console.log(JSON.parse(res.res.data));
                    window.location.reload();
                // }
            })
        })
    }


    render(){
        return(
            <Fragment>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="tenant_id"> Module </label><br />
                        {/* <input type="text" id="tenant_id" autoFocus name="tenant_id" disabled={this.state.lockInputs} value={this.state.formData.tenant_id} className="col-md-12" onChange={this.handleChange} /> */}
                        <select className="browser-default custom-select" id="tenant_id" autoFocus name="tenant_id" disabled={this.state.lockInputs} value={this.state.formData.tenant_id} onChange={this.handleChange} >
                            <option> Choose a module </option>
                            { this.state.modules.map((item, key) => 
                                <option key={key} value={item.value}> {item.name} </option>
                            ) }
                        </select>
                    </div>
                    { this.state.formData.tenant_id !== "new" ?
                        this.state.selectedModule.type === "GROUP_COMPANY" ? 
                            <Fragment>
                                <div className="col-md-3">
                                    <label htmlFor="task"> Task </label><br />
                                    <select className="browser-default custom-select" id="task" name="task" disabled={this.state.lockInputs} value={this.state.formData.task} onChange={this.handleChange} >
                                        <option> Choose a task you need to do </option>
                                        { this.state.tasks.map((item, key) => 
                                            <option key={key} value={item.value}> {item.name} </option>
                                        ) }
                                    </select>
                                </div>
                                { this.state.formData.task && this.state.formData.task !== "ProcessVsModule" &&
                                    <Fragment>
                                        <div className="col-md-3">
                                            <label htmlFor="process_code"> Process </label><br />
                                            <select className="browser-default custom-select" id="process_code" name="process_code" disabled={this.state.lockInputs} value={this.state.formData.process_code} onChange={this.handleChange} >
                                                <option> Choose a process </option>
                                                { this.state.process.map((item, key) => 
                                                    <option key={key} value={item.value}> {item.name} </option>
                                                ) }
                                            </select>
                                        </div>
                                    </Fragment>
                                }
                            </Fragment> 
                        :
                        <div className="col-md-3">
                            <label htmlFor="process_code"> Process </label><br />
                            <select className="browser-default custom-select" id="process_code" name="process_code" disabled={this.state.lockInputs} value={this.state.formData.process_code} onChange={this.handleChange} >
                                <option> Choose a process </option>
                                { this.state.process.map((item, key) => 
                                    <option key={key} value={item.value}> {item.name} </option>
                                ) }
                            </select>
                        </div>
                        : 
                        <div className="col-md-3">
                            <label htmlFor="type"> Type </label><br />
                            <select className="browser-default custom-select" id="type" name="type" disabled={this.state.lockInputs} value={this.state.formData.type} onChange={this.handleChange} >
                                <option> Choose a type </option>
                                { this.state.types.map((item, key) => 
                                    <option key={key} value={item.value}> {item.name} </option>
                                ) }
                            </select>
                        </div>
                        }
                        <br />
                        
                    {/* <div className="col-md-3">
                        <label htmlFor="process_code"> Process Code </label><br />
                        <input type="text" id="process_code" name="process_code" disabled={this.state.lockInputs} value={this.state.formData.process_code} className="col-md-12" onChange={this.handleChange} />
                    </div> */}
                    <div className="col-md-3" align="middle">
                        <br />
                        
                        { this.state.lockInputs ?
                            <button className="btn btn-danger" onClick={ this.onChange }> Change </button>
                             : null }
                             {/* <button className="btn btn-primary" disabled={ this.state.formData.tenant_id === "" && this.state.formData.process_code === "" } onClick={ this.onNext }> Next </button> */}

                    </div>
                </div>
                <br />
                
                { this.state.formData.task && this.state.formData.task === "ProcessVsModule" ?
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="module"> Module </label><br />
                            <select className="browser-default custom-select" id="module" autoFocus name="module" value={this.state.formData.module} onChange={this.handleChange} >
                                <option> Choose a module </option>
                                { this.state.modules.map((item, key) => 
                                    <option key={key} value={item.value}> {item.name} </option>
                                ) }
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="process_code"> Process </label><br />
                            <select className="browser-default custom-select" id="process_code" name="process_code" value={this.state.formData.process_code} onChange={this.handleChange} >
                                <option> Choose a process </option>
                                { this.state.module_process.map((item, key) => 
                                    <option key={key} value={item.value}> {item.name} </option>
                                ) }
                            </select>
                        </div>
                    </div>
                    : null
                }
                { this.state.formData.process_code === "new"  ?
                    <Fragment>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="process_name">Process Name</label><br />
                                <input name="process_name" className="col-md-12" id="process_name" value={ this.state.formData.headers[0] ? this.state.formData.headers[0].process_name : ""} onChange={this.handleHeaderChange} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="process_code">Process Code</label><br />
                                <input name="process_code" className="col-md-12" id="process_code" value={ this.state.formData.headers[0] ? this.state.formData.headers[0].process_code : ""} onChange={this.handleHeaderChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="short_text">Short Text</label><br />
                                <input name="short_text" className="col-md-12" id="short_text" value={ this.state.formData.headers[0] ? this.state.formData.headers[0].short_text : ""} onChange={this.handleHeaderChange} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="system">System</label><br />
                                <input name="system" className="col-md-12" id="system" value={ this.state.formData.headers[0] ? this.state.formData.headers[0].system : ""} onChange={this.handleHeaderChange} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="data_store">Data store</label><br />
                                <select name="data_store" className="col-md-12" id="data_store" value={ this.state.formData.headers[0] ? this.state.formData.headers[0].data_store : ""} onChange={this.handleHeaderChange} >
                                    { this.state.data_stores.map((item, ind) => 
                                        <option value={item.value}> {item.name} </option>
                                    ) }
                                </select>
                            </div>
                        </div>
                    </Fragment>
                : null }

                { !this.state.formData.task && this.state.formData.task !== "ProcessVsModule" ?
                 this.state.formData.tenant_id === "new" ? 
                    this.state.formData.type === "PRODUCT" ?
                    <Fragment>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="name">Name</label><br />
                                <input name="name" className="col-md-12" id="name" value={this.state.formData.name} onChange={this.handleChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="module_tenant_id">Tenant ID</label><br />
                                <input name="module_tenant_id" className="col-md-12" id="module_tenant_id" value={this.state.formData.module_tenant_id} onChange={this.handleChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="short_text">Short Text</label><br />
                                <input name="short_text" id="short_text" className="col-md-12" value={this.state.formData.short_text} onChange={this.handleChange} />
                            </div>
                        </div>
                    </Fragment>
                    : this.state.formData.type === "GROUP_COMPANY" ?
                    <Fragment>
                        <h4>Module Details</h4> <br />
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="name">Name</label><br />
                                <input name="name" className="col-md-12" id="name" value={this.state.formData.name} onChange={this.handleChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="module_tenant_id">Tenant ID</label><br />
                                <input name="module_tenant_id" className="col-md-12" id="module_tenant_id" value={this.state.formData.module_tenant_id} onChange={this.handleChange} />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="short_text">Short Text</label><br />
                                <input name="short_text" id="short_text" className="col-md-12" value={this.state.formData.short_text} onChange={this.handleChange} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="system">System</label><br />
                                <input name="system" className="col-md-12" id="system" value={ this.state.formData.headers[0] ? this.state.formData.headers[0].system : ""} onChange={this.handleHeaderChange} />
                            </div>
                        </div>
                        <br />
                        <h4>Company Details</h4> <br />
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="company_name">Company Name</label><br />
                                <input name="company_name" className="col-md-12" id="company_name" value={this.state.formData.compnay_name} onChange={this.handleChange} />
                            </div>
                            {/* <div className="col-md-3">
                                <label htmlFor="tenant_id">Tenant ID</label><br />
                                <input name="tenant_id" className="col-md-12" id="tenant_id" value={this.state.formData.tenant_id} onChange={this.handleChange} />
                            </div> */}
                            <div className="col-md-3">
                                <label htmlFor="company_short_text">Company Short Text</label><br />
                                <input name="company_short_text" id="company_short_text" className="col-md-12" value={this.state.formData.company_short_text} onChange={this.handleChange} />
                            </div>
                        </div>
                    </Fragment>
                    : null
                : null
                : null
                }
                
                { this.state.lockInputs && this.state.formData.task !== "ProcessVsModule" ?
                <Fragment>
                    
                    { this.state.formData.process_code === "new" ?
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="columns"> Paste your columns</label> <br />
                                <textarea rows={3} className="col-md-9" name="columns" id="columns" onChange={this.onColumnsChange} />
                            </div>
                        </div>
                        : null}
                    <br />
                    { this.state.formData.headers.map((header, headerInd) => 
                        <div className="row" key={headerInd} >
                            <div className="col-md-12 scroll-table">
                                <h4> Header </h4>
                                <table id='table-css' width="100%" border="1">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Short Text</th>
                                            <th>Process Name</th>
                                            <th>Process Code</th>
                                            {/* <th>Label</th> */}
                                            <th>Module</th>
                                            <th>Acronym</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{ headerInd + 1 }</td>
                                            <td>{ header.short_text }</td>
                                            <td>{ header.process_name }</td>
                                            <td>{ header.process_code }</td>
                                            {/* <td>{header.process_code}</td> */}
                                            <td>{header.tenant_id}</td>
                                            <td>{header.acronym}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            <br />
                            </div>
                            <div className="col-md-12" align="right">
                                <button className="btn btn-primary" onClick={() => this.onAdd(headerInd,-1)}> Add at the Beginning </button>
                                <br />
                                <br />
                            </div>
                            <div className="col-md-12 scroll-table">
                                <table id='table-css' width="100%" border="0.5">
                                    <thead>
                                        <tr>
                                            <th width="50px">S.No</th>
                                            <th width="15%" >Short Text</th>
                                            <th width="10%" >Data Type</th>
                                            <th width="5%" >Mandatory</th>
                                            <th width="10%" >Default Value</th>
                                            <th width="10%" >LDE</th>
                                            <th width="30%" >Group</th>
                                            {/* <th width="10%">Label</th> */}
                                            <th width="30%" >Derive Formula </th>
                                            <th width="10%">Predefined List</th>
                                            <th width="5%">Integerity Check</th>
                                            <th width="5%">Acronym</th>
                                            <th width="5%">Relationship</th>
                                            <th width="10%">Action</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        { header.rows.map((row, rowInd) => 
                                            <tr key={rowInd} >
                                                <td> {rowInd + 1} </td>
                                                <td> 
                                                    <input name="short_text" id={"short_text" + rowInd} model="short_text" value={row.short_text} onChange={(ev) => this.handleInput(ev, headerInd, rowInd)} />
                                                </td>
                                                {/* <td> {row.domain} </td> */}
                                                <td>
                                                    <select name="data_type" id={"data_type" + rowInd} model="data_type" onChange={(ev) => { this.handleInput(ev,headerInd, rowInd) }} value={row.data_type} >
                                                        { DATA_TYPES.map((data, ind) =>     
                                                            <option key={ind} value={data.value} >{ data.name }</option>
                                                        ) }
                                                    </select>
                                                </td>
                                                
                                                <td>
                                                    <input type="checkbox" name="mandatory" id="mandatory" onChange={(ev) => { this.handleInput(ev,headerInd, rowInd, "checkbox") }} checked={Boolean(row.mandatory)}  />
                                                </td>
                                                <td> 
                                                    <input type={ row.data_type === "INTEGER" || row.data_type === "FLOAT" ? "number" : row.data_type === "DATE" ? "date" : "text" } 
                                                    decimal={ row.data_type === "FLOAT" ? 2 : 0 } name="default_value" id={"default_value" + rowInd} model="default_value" value={row.default_value} onChange={(ev) => this.handleInput(ev, headerInd,rowInd)} />
                                                </td>

                                                <td>
                                                    <input type="text" name="lde" id={"lde" + rowInd} value={row.lde} onChange={(ev) => this.handleInput(ev, headerInd,rowInd)} />
                                                </td>
                                                <td>
                                                    <select name="group" id={"group" + rowInd} value={row.group} onChange={(ev) => this.handleInput(ev, headerInd,rowInd)}>
                                                        <option value=""></option>
                                                        <option value="create_new_group">Create new</option>
                                                        { this.state.groups.map( each => <option value={each}>{each}</option>)}
                                                    </select>
                                                </td>
                                                {/* <td> {row.label} </td> */}
                                                <td> 
                                                    <input name="derive_formula" id={"derive_formula" + rowInd} model="derive_formula" value={row.derive_formula} onChange={(ev) => { this.handleInput(ev, headerInd,rowInd); }} onBlur={(ev) => this.onDeriveChange(ev, headerInd,rowInd)} />
                                                </td>
                                                <td> 
                                                    <input name="predefined_list" id={"predefined_list" + rowInd} model="predefined_list" value={row.predefined_list} onChange={(ev) => this.handleInput(ev, headerInd,rowInd)} />
                                                </td>
                                                <td> 
                                                    <input name="integerity_check" id={"integerity_check" + rowInd} model="integerity_check" value={row.integerity_check} onChange={(ev) => this.handleInput(ev, headerInd,rowInd)} />
                                                </td>
                                                <td> 
                                                    <input name="acronym" id={"acronym" + rowInd} model="acronym" value={row.acronym} onChange={(ev) => this.handleInput(ev, headerInd,rowInd)} />
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-warning" onClick={() => this.openRelationshipModal(headerInd, rowInd, row)}> Relationship </button>
                                                </td>
                                                <td> 
                                                    <div className="row">
                                                        <div className="col-md-6" align="middle">
                                                            <button type="button" className="btn btn-success" onClick={() => this.onAdd(headerInd,rowInd)}> <i className="fa fa-plus"></i> </button>
                                                        </div>
                                                        <div className="col-md-6" align="middle">
                                                            <button type="button" className="btn btn-danger" onClick={() => this.onRemove(headerInd,rowInd)}> <i className="fa fa-times" ></i> </button>
                                                        </div>
                                                    </div>    
                                                </td>
                                            </tr>
                                        ) }
                                    </tbody>
                                </table>
                            </div>
                    </div>
                    ) }
                    <Popup 
                    show={this.state.showPopup}
                    hide={ () => this.setState( {showPopup: false, a_group_name : '', headerInd : null, ind : null}) }
                    popupcontent = {
                    <div align="center" className='mt-5'>
                        <label htmlFor="groupName">Group Name :</label>
                        <input name="groupName" type="text" autoFocus value={this.state.a_group_name} onChange={(e) => this.setState({ a_group_name : e.target.value})} /><br/><br/>
                        <button className='btn btn-primary' disabled={!this.state.a_group_name} onClick={ () => {

                            let groups = this.state.groups
                            groups.push(this.state.a_group_name)  
                            groups = [...new Set(groups)]

                            let ev = this.state.ev
                            ev.target.value = this.state.a_group_name

                            this.handleInput( ev, this.state.headerInd, this.state.ind)
                            this.setState({ groups, showPopup: false, a_group_name : '', headerInd : null, ind : null })
                        }} >Save</button>
                    </div>}
                    />
                </Fragment>
                 : null
                } 
                { (this.state.showError && this.state.formData.tenant_id !== "new") &&
                <p className="text-danger" > *Please input Process name and Proces code </p> }

                    {this.state.showRelationShipModal &&
                        <MDBContainer>
                            {/* <MDBBtn color="primary" onClick={this.toggle(14)}>MDBModal</MDBBtn> */}
                            <MDBModal isOpen={this.state.showRelationShipModal} toggle={this.closeRelationshipModal} size="lg">
                            <MDBModalHeader toggle={this.closeRelationshipModal}>Relationships</MDBModalHeader>
                            <MDBModalBody>
                                <div className="row">
                                    <div className="col-md-12 scroll-table">
                                        <table id='table-css' width="100%" border={1}>
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Type</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>DERIVE_TYPE</th>
                                                    <th>Constant</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.state.relationship_modal_data.relationships.map((relation, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{ relation.type }</td>
                                                        <td>
                                                            { relation.from === "HDR" ? relation.from :
                                                            <select name="from" id={"from" + index} disabled={relation.from === "HDR"} model="from" onChange={(ev) => { this.handleRelationInput(ev,this.state.relationship_modal_data.headerInd,this.state.relationship_modal_data.rowInd, index) }} value={relation.from} >
                                                                { this.state.acronyms.map((data, ind) =>     
                                                                    <option value={data.value} key={ind} >{ data.name }</option>
                                                                ) }
                                                            </select>
                                                            }
                                                        </td>
                                                        <td>
                                                            { relation.from === "HDR" ? relation.to :
                                                            <select name="to" id={"to" + index} disabled={relation.from === "HDR"} model="to" onChange={(ev) => { this.handleRelationInput(ev,this.state.relationship_modal_data.headerInd,this.state.relationship_modal_data.rowInd, index) }} value={relation.to} >
                                                                { this.state.acronyms.map((data, ind) =>     
                                                                    <option value={data.value} key={ind} >{ data.name }</option>
                                                                ) }
                                                                    <option value="constant" >Custom Constant</option>
                                                            </select>}
                                                        </td>
                                                        <td>{ relation.derive_type }</td>
                                                        <td>
                                                            <input name="constant" disabled={relation.to !== "constant" || relation.from === "HDR"} id={"constant" + index} model="constant" value={relation.constant} onChange={(ev) => this.handleRelationInput(ev,this.state.relationship_modal_data.headerInd,this.state.relationship_modal_data.rowInd, index)} />
                                                        </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-6" align="middle">
                                                                    <button type="button" className="btn btn-success" onClick={() => this.onAddRelation(this.state.relationship_modal_data.headerInd,this.state.relationship_modal_data.rowInd, index)}> <i className="fa fa-plus"></i> </button>
                                                                </div>
                                                                { relation.relType !== "from_derive" &&
                                                                    <div className="col-md-6" align="middle">
                                                                        <button type="button" className="btn btn-danger" disaabled={relation.relType === "from_derive" || relation.from === "HDR"} onClick={() => this.onRemoveRelation(this.state.relationship_modal_data.headerInd,this.state.relationship_modal_data.rowInd, index)}> <i className="fa fa-times" ></i> </button>
                                                                    </div>
                                                                }
                                                            </div>   
                                                        </td>
                                                    </tr>
                                                ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                {/* <MDBBtn color="secondary" >Close</MDBBtn> */}
                                <MDBBtn color="primary" onClick={this.closeRelationshipModal}>Done</MDBBtn>
                            </MDBModalFooter>
                            </MDBModal>
                        </MDBContainer>
                    }
                    <div className="row">
                        <div className="col-md-12" align="right">
                            <button className="btn btn-primary" type="button" disabled={this.state.generateDisabled} onClick={this.onSubmit} >Generate</button>
                        </div>
                    </div>

            </Fragment>
        )
    }
}


export default CreateKG;