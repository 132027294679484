import React, { Component } from 'react';
import {Form, Button, Select, Modal}  from 'react-bootstrap';
import {components} from 'react-select';
import { connect } from 'react-redux';
import {getRequest, postRequest, getHumanReadableDate, convertToINR, getHumanReadableDateTime, getYYYYFormat} from '../../globalhelper/helper';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ApiConstants from '../../config'
import download from 'downloadjs';
import axios from 'axios'
import { default as ReactSelect } from "react-select";
import './COD.css';
import {dropdowndata} from './dropdowndata'
import {dropdown} from './dropdown';
import Pagination1 from './Pagination'
import CIPopup from './CIPopup';

const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

export class ConfirmationScreen extends Component {
    constructor(props) {
      super(props)

      this.current = this.props.state

      console.log(this.current)
    
      this.state = {
         formData: {
            client: '',
            model:'',
            zone: '',
            stationcode: '',
            date: '',
            status: '',
            approvalreason: [],
            appstatus: 'Pending For Approval'
         },
         data: {
            approvalstatus: '',
            businessmodel: '',
            client: '',
            date: '',
            depositamount: '',
            depositstatus: '',
            pendingamount: '',
            slipnumber: '',
            sourcetype: '',
            stationcode: '',
            zone: '',
            reason: '',
            rejectionreason: '',
            postedon: '',
            approvedon: '',
            approvedby: this.current.login.userData.USR_Name,
            postedby: '',
            erpscreenshot: ''
         },
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         dropdown_stationcode: [],
         tableData: [],
         show_model: false,
         show_model1: false,
         show_rejection_model: false,
         show_maintable: false,
         show_filtertable: false,
         fetching: false,
         dropdownOptions: [],
         button: 'Approve',
         buttonColor: 'primary',
         curObject: [],
         currentPage: 1,
         postsPerPage: 15,
         currentPage1: 1,
         postsPerPage1: 15,
         tableDisplay: [], 
         filterTable: [],
         filterDisplay: [],
         uniqueModel: [],
         uniqueZone: [],
         uniqueStationcode: [],
         uniqueData: [],
         approveButton: "enabled",
         showpopup: false,
         deleteitem: []
      }
    }

    // Handles the selection reason from the admin (Approved/Rejected)

    handledropdownChange = (event) => {
        this.setState({
            dropdownOptions: event,
            formData: {
                ...this.state.formData,
                approvalreason: event
            }          
        }, () => {
            const tempDropDownArray = this.state.dropdownOptions
            console.log(tempDropDownArray)

            if(tempDropDownArray.length === 3){
                this.setState({
                    data: {
                        ...this.state.data,
                        approvalstatus: 'Approved',
                        rejectionreason: ''
                    }
                })
            } else if (tempDropDownArray.length <3){
                this.setState({
                    data: {
                        ...this.state.data,
                        approvalstatus: 'Rejected',
                        rejectionreason: 'One/Two of the parameters not matched'
                    }
                })
            }
        })
    }

    handleDropdown1Change = (e) => {
        this.setState({
            dropdownOptions: e
        })
    }

    componentDidMount(){

        this.setState({
            ...this.state,
            fetching: true
        })

        // Fetches all the deposited entries

        getRequest('api/cod/getDepositReport').then(response => {

            if(response.res.status === 'success') {

                let client = []

                let dropdown_model = response.res.data.map(value=> value.businessmodel)
                let dropdown_zone = response.res.data.map(value => value.zone)
                let dropdown_stationcode = response.res.data.map(value => value.stationcode)

                let temp1 = [... new Set(dropdown_stationcode)]

                response.res.data.forEach(item => {
                    client.push(item.client)
                })

                let temp = [... new Set(client)]

                let tableData = response.res.data

                console.log(tableData)

                this.setState({
                    ...this.state,
                    tableData: tableData,
                    dropdown_client: temp,
                    uniqueModel: dropdown_model,
                    uniqueZone: dropdown_zone,
                    uniqueStationcode: temp1,
                    uniqueData: tableData,
                    fetching: false,
                    dropdown_stationcode: temp1,
                    show_maintable: true
                }, () => this.handlePagination())
            } else {
                let tableData = 'No Data Found'

                this.setState({
                    ...this.state,
                    tableData: tableData
                })
            } 
        })
    }

    // To get the date format in the specified format

    dateFormatForInPrdFrom = () => {
        const currentDate = new Date();

        // Get individual components of the date
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

        // Create the formatted time string
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    // Changes Client State and fills the Model Dropdown

    handleClientChange = (event) => {
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            show_maintable: false,
            formData: {
                ...this.state.formData,
                client: VALUE
            }
        })

        let arrModel = []

        this.state.tableData.forEach((item) => {
            if(item.client === VALUE){
                arrModel.push(item.businessmodel)
                console.log(arrModel)
                return arrModel
            }
        })

        let arr1 = [...new Set(arrModel)]
        this.setState({
            ...this.state,
            dropdown_model: arr1,
            formData: {
                ...this.state.formData,
                model: '',
                stationcode: '',
                zone: '',
                status: '',
                client: VALUE
            }
        }, () => this.handleClientFilter()) 
    }

    // Handles Client Filter

    handleClientFilter = () => {
        let newArray = []

        this.state.tableData.forEach(item => {
            if(item.client === this.state.formData.client){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    // Changes Model State and fills the zone dropdown

    handleModelChange = (event) => {
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        let arrZone = []

        this.state.tableData.forEach(each => {
            if(each.client === this.state.formData.client && each.businessmodel === VALUE){
                arrZone.push(each.zone)
                return arrZone
            }
        })
        let arr2 = [...new Set(arrZone)]
            this.setState({
                ...this.state,
                dropdown_zone: arr2,
                formData: {
                    ...this.state.formData,
                    stationcode: '',
                    zone: '',
                    status: '',
                    model: VALUE
                    }
            }, () => this.handleModelFilter())       
    }

    // Handles model filter

    handleModelFilter = () => {
        let newArray = []

        this.state.tableData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    // Changes Zone changes and fills the station dropdown

    handleZoneChange = (event) => {
        const VALUE = event.target.value;

        let stationcode = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                zone : VALUE
            }
        })

        this.state.tableData.forEach(item => {
            if(item.zone === VALUE && item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                stationcode.push(item.stationcode)
                return stationcode
            }
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formData: {
                ...this.state.formData,
                stationcode: '',
                status: '',                
                zone: VALUE
            }
        }, () => this.handleZoneFilter())
    }

    // Handles zone filter

    handleZoneFilter = () => {
        let newArray = []

        this.state.tableData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model && item.zone === this.state.formData.zone){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    // Handles StationCode State

    handleStationChange = (event) => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                stationcode: event.target.value
            }
        }, () => this.handleStationFilter())
    }

    // Handles Station Code Filter

    handleStationFilter = () => {
        let newArray = []

        // console.log(this.state.tableData)

        this.state.tableData.forEach(item => {
            if(item.stationcode === this.state.formData.stationcode){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage1: 1,
            filterTable: newArray.length >= 1 ? newArray : []
        }, () => this.handlePagination1())
    }

    // Handles Status change

    handleStatusChange = (event) => {
        const VALUE = event.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                // client: '',
                // model: '',
                // zone: '',
                // stationcode: '',
                status: VALUE
            }
        }, () => this.handleStatusFilter())
    }

    // Handles Status Filter

    handleStatusFilter = () => {
    
        let client = [this.state.formData.client]
        let model = [this.state.formData.model]
        let zone = [this.state.formData.zone]
        let stationcode = [this.state.formData.stationcode]
        let status = [this.state.formData.status]
        let data = this.state.uniqueData

        console.log(client, model, zone, stationcode, status)

        if(!client[0]){
            client = this.state.dropdown_client
        }

        if(!model[0]){
        model = this.state.uniqueModel
       }

       if(!zone[0]){
        zone = this.state.uniqueZone
       }

       if(!stationcode[0]){
        stationcode = this.state.uniqueStationcode
       }

       let filteredData = []

       for(const[i, item] of data.entries()){
        console.log(item.client, item.businessmodel, item.zone, item.stationcode, item.approvalstatus)
        if(client.includes(item.client) && model.includes(item.businessmodel) && zone.includes(item.zone) && stationcode.includes(item.stationcode) && status.includes(item.approvalstatus)){
            filteredData.push(item)
        }

        if(i===data.length-1){
            this.setState({
                ...this.state,
                show_filtertable: true,
                show_maintable: false,
                currentPage1: 1,
                filterTable: filteredData.length >= 1 ? filteredData : [] 
            }, () => this.handlePagination1())
        }
       }

    }

    handleReset = () => {
        this.setState({
            ...this.state,
            show_filtertable: false,
            show_maintable: true,
            dropdown_model: [],
            dropdown_zone: [],
            dropdown_stationcode: this.state.uniqueStationcode,
            formData: {
                client: '',
                model: '',
                zone: '',
                stationcode: '',
                status: ''
            }
        })
    }

    // Downloads deposit slips

    downloadReceipt = (item) => {
        const fileName = item.receipt
        const date = item.date
        axios.post(ApiConstants.externals.serverUrl +  `api/tds/downloadConvertedFile`, {filename: fileName} ,
          { responseType: 'blob' }
        )
        .then(response => {
                console.log(response.data)
                const blob = response.data;
                download(blob, item.receipt);
        })
    }

    // Downloads ERP screenshots

    downloadERP = (item) => {
        const fileName = item.erpscreenshot
        const date = item.date
        axios.post(ApiConstants.externals.serverUrl +  `api/tds/downloadERPFile`, {filename: fileName} ,
          { responseType: 'blob' }
        )
        .then(response => {
                console.log(response.data)
                const blob = response.data;
                download(blob, item.erpscreenshot);
        })
    }

    // Makes the approve JSON 

    approveFunction = (item, i) => {
        const dateTime = getHumanReadableDateTime(new Date())
        this.setState({
            ...this.state,
            show_model: item.depositstatus === 'Yes' || item.depositstatus === "Short Deposit" ? true : false,
            show_model1: item.depositstatus === 'No' ? true : false,
            curObject: [item],
            data: {
                approvalstatus: 'Approved',
                postedon: item.postedon,
                postedby: item.postedby,
                erpscreenshot: item.erpscreenshot === undefined ? '' : item.erpscreenshot,
                businessmodel: item.businessmodel,
                client: item.client,
                date: item.date,
                depositamount: item.depositamount,
                depositstatus: item.depositstatus,
                pendingamount: item.pendingamount,
                receipt: item.receipt === undefined ? '' : item.receipt,
                slipnumber: item.slipnumber,
                sourcetype: item.sourcetype,
                stationcode: item.stationcode,
                zone: item.zone,
                reason: item.reason === undefined ? '' : item.reason,
                rejectionreason: '',
                approvedby: this.current.login.userData.USR_Name,
                approvedon: this.dateFormatForInPrdFrom(),
                transactionid: item.transactionid,
                pk: item.pk,
                sk: item.sk,
                input: item.input,
                output: item.output,
                inprdfrom: this.dateFormatForInPrdFrom(),
                inprdto: item.inprdto,
                checksum: item.checksum
            }
        }, () => console.log(this.state.dropdownOptions))
    }

    // Makes the rejection JSON

    rejectionFunction = (item,i) => {
        const dateTime = getHumanReadableDateTime(new Date())
        this.setState({
            ...this.state,
            show_rejection_model : true,
            curObject: [item],
            data: {
                approvalstatus: 'Rejected',
                postedon: item.postedon,
                postedby: item.postedby,
                erpscreenshot: item.erpscreenshot === undefined ? '' : item.erpscreenshot,
                businessmodel: item.businessmodel,
                client: item.client,
                date: item.date,
                depositamount: item.depositamount,
                depositstatus: item.depositstatus,
                pendingamount: item.pendingamount,
                receipt: item.receipt === undefined ? '' : item.receipt,
                slipnumber: item.slipnumber,
                sourcetype: item.sourcetype,
                stationcode: item.stationcode,
                zone: item.zone,
                reason: item.reason === undefined ? '' : item.reason,
                rejectionreason: this.state.data.rejectionreason,
                approvedby: this.current.login.userData.USR_Name,
                approvedon: this.dateFormatForInPrdFrom(),
                transactionid: item.transactionid,
                pk: item.pk,
                sk: item.sk,
                input: item.input,
                output: item.output,
                inprdfrom: this.dateFormatForInPrdFrom(),
                inprdto: item.inprdto,
                checksum: item.checksum
            }
        }, () => console.log(this.state.data))  
    }

    // Updates the rejection reason to the state

    handleRejectionChange = (event) => {
        this.setState({
            ...this.state.data,
            data: {
                ...this.state.data,
                rejectionreason: event.target.value
            }
        })
    }

    // API call to update the status of the deposit

    handleClose = () => {

        const formData = new FormData()

        formData.append("file", this.state.data)
        console.log(this.state.data)

        this.setState({
            ...this.state,
            show_model: false,
            show_model1: false,
            show_rejection_model: false,
            button: 'Verify',
            buttonColor: 'primary',
            approveButton: "disabled"
        }, () => {
            postRequest(`api/cod/updateRecords`, this.state.data).then(response => {
                console.log(response.res.data)
                const dateTime = getYYYYFormat(new Date())
                console.log(dateTime)
                const array1 = [...this.state.tableData]
                const array2 = [...this.state.tableDisplay]
                const array3 = [...this.state.filterTable]
                const array4 = [this.state.data]

                const timeChangedObj = {...this.state.data}

                console.log(timeChangedObj)

                timeChangedObj.approvedon = dateTime

                console.log(timeChangedObj)


                const objIndex1 = array1.findIndex(obj => obj.postedon === array4[0].postedon && obj.stationcode === array4[0].stationcode)
                const objIndex2 = array2.findIndex(obj => obj.postedon === array4[0].postedon && obj.stationcode === array4[0].stationcode)
                const objIndex3 = array3.findIndex(obj => obj.postedon === array4[0].postedon && obj.stationcode === array4[0].stationcode)

                console.log(objIndex1)
                console.log(objIndex2)
                console.log(objIndex3)

                if(objIndex1 !== -1){

                    array1.splice(objIndex1, 1)

                    console.log(array1)

                    console.log(this.state.data)
    
                    array1.splice(objIndex1, 0, timeChangedObj)
    
    
                    this.setState({
                        ...this.state,
                        approveButton: "enabled",
                        tableData: array1
                    }, () => this.handlePagination())
                } 

                if (objIndex2 !== -1){

                    array2.splice(objIndex2, 1)

                    array2.splice(objIndex2, 0, timeChangedObj)

                    this.setState({
                        ...this.state,
                        approveButton: "enabled",
                        tableDisplay: array2
                    }, () => this.handlePagination())
                }

                if (objIndex3 !== -1){

                    array3.splice(objIndex3, 1)

                    array3.splice(objIndex3, 0, timeChangedObj)

                    this.setState({
                        ...this.state,
                        approveButton: "enabled",
                        filterTable: array3
                    }, () => this.handlePagination1())
                }

            })
        })
    }

    // Closes the popup

    closeModel = () => {
        this.setState({
            ...this.state,
            dropdownOptions: [],
            show_model: false,
            show_model1: false,
            show_rejection_model: false
        })
    }

    // Helps in filtering functionality

    filterContents = async () => {
        await getRequest(`api/cod/filterConfirmationContents?stationcode=${this.state.formData.stationcode}&date=${this.state.formData.date}&client=${this.state.formData.client}`).then(response => {
            let tableData = response.res.data

            console.log(tableData)

            this.setState({
                ...this.state,
                tableDisplay: tableData
            })
        })
    }

    handlePagination = () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        console.log(this.state.tableData)
        let currentPosts = this.state.tableData.sort((a,b)=>b.date.localeCompare(a.date)).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            tableDisplay: currentPosts
        })
    }

    handlePagination1 = () => {
        let indexOfLastPost = this.state.currentPage1 * this.state.postsPerPage1
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage1
        let currentPosts = this.state.filterTable.sort((a,b)=>b.date.localeCompare(a.date)).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            filterDisplay: currentPosts
        })
    }

    paginate = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

    paginate1 = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage1: pageNumber
        }, () => this.handlePagination1())
    }

    handleDelete = (item) => {
        console.log(item)
        this.setState({
            ...this.state,
            showpopup: true,
            deleteitem: item
        })
    }

  render() {
    return (
      <div>
        <div className='header_div'>
            HO COD Form Approval
        </div>

        {!this.state.fetching && this.state.tableData.length > 0 ?
        <div>
            <Button variant='danger' className='pull-right' onClick={this.handleReset}>Reset Filter</Button>
        </div> : null}

        <br/>
        <br/>

        {!this.state.fetching && this.state.tableData.length > 0 ?

        <div className='row'>
            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Client</Form.Label>
                    <select className='spacing' name='client' value={this.state.formData.client} onChange={this.handleClientChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_client.sort().map((item, index)=> <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Model</Form.Label>
                    <select className='spacing' name='model' value={this.state.formData.model} onChange={this.handleModelChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_model.sort().map((item, index)=> <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Zone</Form.Label>
                    <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_zone.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Station Code</Form.Label>
                    <select className='spacing' name='stationcode' value={this.state.formData.stationcode} onChange={this.handleStationChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_stationcode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            {/* <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Deposit Date</Form.Label>
                    <input className='spacing' type='date' min='2021-01-01' name='date' value={this.state.formData.date} onChange={this.handleChange}></input>
                </Form.Group>
            </div> */}

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Deposit Status</Form.Label>
                    <select className='spacing' name='status' value={this.state.formData.status} onChange={this.handleStatusChange}>
                        <option value='' disabled>Select</option>
                        <option value='Approved'>Approved</option>
                        <option value='Rejected'>Rejected</option>
                        <option value='Pending For Approval'>Pending For Approval</option>
                    </select>
                </Form.Group>
            </div>
        </div> : null }

        {this.state.fetching ? <div className='center'>
            <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
        </div>: null}

        {/* <div className='wrapper'>
                <Button onClick={this.filterContents} disabled={!this.state.formData.date}>Filter</Button>
        </div> */}

        <br/>
        <br/>

        {this.state.show_maintable && this.state.tableData.length > 0 ?
        <div className='table_wrapper'>
        <table border='1' id='table_styling'>
            <thead>
                <tr>
                    <th style={{maxWidth:50}}>Client</th>
                    <th style={{maxWidth:50}}>Business Model</th>
                    <th>Zone</th>
                    <th>Station Code</th>
                    <th style={{minWidth:90}}>Deposit Date</th>
                    <th style={{minWidth:90}}>Submitted On</th>
                    <th>Posted By</th>
                    <th>Client Remittance Number</th>
                    <th>Receipt Download</th>
                    <th>ERP Screenshot</th>
                    <th>Deposit Status</th>
                    {/* <th>Source Type</th> */}
                    <th>Deposit Amount</th>
                    <th>Pending Amount</th>
                    <th>Approve</th>
                    <th>Reject</th>
                    {/* <th>Edit</th> */}
                    <th>Delete</th>
                    <th>Reason</th>
                    <th>Rejection Reason</th>
                    <th>Approved By</th>
                    <th>Approved On</th>
                    <th>Approval Status</th>
                    
                </tr>
            </thead>
            <tbody>
                {this.state.tableDisplay.map((item, i) => {
                    // Initialize the original date string
                        
                    const originalDate = item.postedon
                    const approvedDate = item.approvedon
                    // console.log(originalDate) 

                    // Create a new Date object from the original string
                    const dateObject = new Date(originalDate);
                    const dateObject1 = new Date(approvedDate)

                    // Extract the day, month, and year from the date object
                    const day = dateObject.getDate().toString().padStart(2, '0');
                    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                    const year = dateObject.getFullYear();
                    const hours = dateObject.getHours().toString().padStart(2, '0');
                    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
                    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

                    const day1 = dateObject1.getDate().toString().padStart(2, '0');
                    const month1 = (dateObject1.getMonth() + 1).toString().padStart(2, '0');
                    const year1 = dateObject1.getFullYear();
                    const hours1 = dateObject1.getHours().toString().padStart(2, '0');
                    const minutes1 = dateObject1.getMinutes().toString().padStart(2, '0');
                    const seconds1 = dateObject1.getSeconds().toString().padStart(2, '0');

                    // Build up a new string in the desired format
                    const newDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
                    const newDate1 = `${day1}-${month1}-${year1} ${hours1}:${minutes1}:${seconds1}`;
                    // console.log(newDate)
                    return(
                        <tr key={i}>
                            <td value={item.client}>{item.client}</td>
                            <td value={item.businessmodel}>{item.businessmodel}</td>
                            <td value={item.zone}>{item.zone}</td>
                            <td value={item.stationcode}>{item.stationcode}</td>
                            <td value={item.date}>{getHumanReadableDate(item.date)}</td>
                            <td>{newDate === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate}</td>
                            <td>{item.postedby}</td>
                            <td value={item.slipnumber}>{item.slipnumber}</td>
                            <td><Button variant='link' onClick={()=>this.downloadReceipt(item)} disabled={!item.receipt}><FileDownloadIcon/></Button></td>
                            <td><Button variant='link' onClick={()=>this.downloadERP(item)} disabled={!item.erpscreenshot}><FileDownloadIcon/></Button></td>
                            {/* <td value={item.sourcetype}>{item.sourcetype}</td> */}
                            <td value={item.depositstatus}>{item.depositstatus}</td>    
                            <td value={item.depositamount}>{convertToINR(item.depositamount)}</td>
                            <td value={item.pendingamount}>{convertToINR(item.pendingamount)}</td>
                            <td><Button size='sm' variant={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected' ? 'secondary' : 'success'} onClick={() => this.approveFunction(item, i)} disabled={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected'}>Approve</Button></td>
                            <td><Button size='sm' variant={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected' ? 'secondary' : 'danger'} onClick={() => this.rejectionFunction(item, i)} disabled={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected'}>Reject</Button></td>
                            {/* <td><Button size='sm' variant='warning' onClick={() => this.handleEdit()}>Edit</Button></td> */}
                            <td><Button size='sm' variant='warning' onClick={() => this.handleDelete(item)}>Delete</Button></td>
                            <td value={item.reason}>{item.reason}</td>
                            <td value={item.rejectionreason}>{item.rejectionreason}</td>
                            <td value={item.approvedby}>{item.approvedby}</td>
                            <td>{newDate1 === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate1}</td>
                            <td value={item.approvalstatus}>{item.approvalstatus}</td>
                        </tr>
                    )
                })}                            
            </tbody>                       
        </table>
</div>
: null}

{this.state.show_filtertable ?
        <div className='table_wrapper'>
        <table border='1' id='table_styling'>
            <thead>
                <tr>
                    <th style={{maxWidth:50}}>Client</th>
                    <th style={{maxWidth:50}}>Business Model</th>
                    <th>Zone</th>
                    <th>Station Code</th>
                    <th style={{minWidth:90}}>Deposit Date</th>
                    <th style={{minWidth:90}}>Submitted On</th>
                    <th>Posted By</th>
                    <th>Client Remittance Number</th>
                    <th>Receipt Download</th>
                    <th>ERP Screenshot</th>
                    <th>Deposit Status</th>
                    {/* <th>Source Type</th> */}
                    <th>Deposit Amount</th>
                    <th>Pending Amount</th>
                    <th>Approve</th>
                    <th>Reject</th>
                    {/* <th>Edit</th> */}
                    <th>Delete</th>
                    <th>Reason</th>
                    <th>Rejection Reason</th>
                    <th>Approved By</th>
                    <th>Approved On</th>
                    <th>Approval Status</th>
                    
                </tr>
            </thead>
            <tbody>
                {this.state.filterDisplay.sort((a,b)=>b.date.localeCompare(a.date)).map((item, i) => {
                    // Initialize the original date string
                        
                    const originalDate = item.postedon
                    const approvedDate = item.approvedon
                    // console.log(originalDate) 

                    // Create a new Date object from the original string
                    const dateObject = new Date(originalDate);
                    const dateObject1 = new Date(approvedDate)

                    // Extract the day, month, and year from the date object
                    const day = dateObject.getDate().toString().padStart(2, '0');
                    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                    const year = dateObject.getFullYear();
                    const hours = dateObject.getHours().toString().padStart(2, '0');
                    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
                    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

                    const day1 = dateObject1.getDate().toString().padStart(2, '0');
                    const month1 = (dateObject1.getMonth() + 1).toString().padStart(2, '0');
                    const year1 = dateObject1.getFullYear();
                    const hours1 = dateObject1.getHours().toString().padStart(2, '0');
                    const minutes1 = dateObject1.getMinutes().toString().padStart(2, '0');
                    const seconds1 = dateObject1.getSeconds().toString().padStart(2, '0');

                    // Build up a new string in the desired format
                    const newDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
                    const newDate1 = `${day1}-${month1}-${year1} ${hours1}:${minutes1}:${seconds1}`;
                    // console.log(newDate)

                    return(
                        <tr key={i}>
                            <td value={item.client}>{item.client}</td>
                            <td value={item.businessmodel}>{item.businessmodel}</td>
                            <td value={item.zone}>{item.zone}</td>
                            <td value={item.stationcode}>{item.stationcode}</td>
                            <td value={item.date}>{getHumanReadableDate(item.date)}</td>
                            <td>{newDate === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate}</td>
                            <td>{item.postedby}</td>
                            <td value={item.slipnumber}>{item.slipnumber}</td>
                            <td><Button variant='link' onClick={()=>this.downloadReceipt(item)} disabled={!item.receipt}><FileDownloadIcon/></Button></td>
                            <td><Button variant='link' onClick={()=>this.downloadERP(item)} disabled={!item.erpscreenshot}><FileDownloadIcon/></Button></td>
                            {/* <td value={item.sourcetype}>{item.sourcetype}</td> */}
                            <td value={item.depositstatus}>{item.depositstatus}</td>    
                            <td value={item.depositamount}>{convertToINR(item.depositamount)}</td>
                            <td value={item.pendingamount}>{convertToINR(item.pendingamount)}</td>
                            <td><Button size='sm' variant={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected' ? 'secondary' : 'success'} onClick={() => this.approveFunction(item, i)} disabled={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected'}>Approve</Button></td>
                            <td><Button size='sm' variant={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected' ? 'secondary' : 'danger'} onClick={() => this.rejectionFunction(item, i)} disabled={item.approvalstatus === 'Approved' || item.approvalstatus === 'Rejected'}>Reject</Button></td>
                            {/* <td><Button size='sm' variant='warning' onClick={() => this.handleEdit()}>Edit</Button></td> */}
                            <td><Button size='sm' variant='warning' onClick={() => this.handleDelete(item)}>Delete</Button></td>                      
                            <td value={item.reason}>{item.reason}</td>
                            <td value={item.rejectionreason}>{item.rejectionreason}</td>
                            <td value={item.approvedby}>{item.approvedby}</td>
                            <td>{newDate1 === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate1}</td>
                            <td value={item.approvalstatus}>{item.approvalstatus}</td>
                        </tr>
                    )
                })}                            
            </tbody>                       
        </table>
</div>
: null}

    {this.state.showpopup ?
        <CIPopup 
        
        handleClose = {() => this.setState({
            ...this.state,
            deleteitem: [],
            showpopup: false
        })} 

        handleDeleteAfterConfirmation = {() => {
            console.log(this.state.deleteitem)

            this.setState({
                ...this.state,
                showpopup: false
            })

            postRequest(`api/cod/deleteRecords`, this.state.deleteitem).then(response => {
                console.log(response.res.data)
                const array1 = [...this.state.tableData]
                const array2 = [...this.state.tableDisplay]
                const array3 = [...this.state.filterTable]
                const array4 = [this.state.deleteitem]

                const objIndex1 = array1.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.date === array4[0].date)
                const objIndex2 = array2.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.date === array4[0].date)
                const objIndex3 = array3.findIndex(obj => obj.pk === array4[0].pk && obj.sk === array4[0].sk && obj.date === array4[0].date)

                if(objIndex1 !== -1){
                    array1.splice(objIndex1, 1)

                    this.setState({
                        ...this.state,
                        tableData: array1
                    }, () => this.handlePagination())
                }

                if(objIndex2 !== -1){
                    array2.splice(objIndex2, 1)

                    this.setState({
                        ...this.state,
                        tableDisplay: array2
                    }, () => this.handlePagination())
                }

                if(objIndex3 !== -1){
                    array3.splice(objIndex3, 1)

                    this.setState({
                        ...this.state,
                        filterTable: array3
                    }, () => this.handlePagination1())
                }

            }).catch(err => console.log(err))
        }}
        
        
        /> : null}

    {this.state.show_maintable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.tableData.length} paginate={this.paginate}></Pagination1>
        </div> : null }

        {this.state.show_filtertable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.filterTable.length} paginate={this.paginate1}></Pagination1>
        </div> : null }

        {this.state.tableData.length <= 0 && !this.state.fetching? <div className='center-screen'>
            <h5>No Data Found</h5>
        </div>: null}
            

        
            <div>
            <Modal
                show={this.state.show_model}
                onHide={false}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Select Reasons To Approve</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span
                        data-toggle="popover"
                        data-trigger="focus"
                        data-content="Please select account"
                    >
                    <ReactSelect
                        options={dropdowndata}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        components={{
                            Option
                        }}
                        onChange={this.handledropdownChange}
                        allowSelectAll={true}
                        value={this.state.dropdownOptions}
                    />
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={this.closeModel}>Close</Button>
                    <Button variant={this.state.dropdownOptions.length <3 ?"secondary" : "primary"} disabled={this.state.dropdownOptions.length <3 || this.state.approveButton === "disabled"} onClick={this.handleClose}>Approve</Button>
                </Modal.Footer>
            </Modal>
            </div>

            <div>
            <Modal
                show={this.state.show_model1}
                onHide={false}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Select Reasons To Approve</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span
                        data-toggle="popover"
                        data-trigger="focus"
                        data-content="Please select account"
                    >
                    <ReactSelect
                        options={dropdown}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        components={{
                            Option
                        }}
                        onChange={this.handleDropdown1Change}
                        allowSelectAll={true}
                        value={this.state.dropdownOptions}
                    />
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={this.closeModel}>Close</Button>
                    <Button variant={this.state.dropdownOptions.length <1 ?"secondary" : "primary"} disabled={this.state.dropdownOptions.length <1 || this.state.approveButton === "disabled"} onClick={this.handleClose}>Approve</Button>
                </Modal.Footer>
            </Modal>
            </div>

            <div>
            <Modal
                show={this.state.show_rejection_model}
                onHide={false}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Enter Rejection Reason</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input style={{width:400}} value={this.state.data.rejectionreason} onChange={this.handleRejectionChange}></input>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={this.closeModel}>Close</Button>
                    <Button variant = {!this.state.data.rejectionreason ?"secondary" : "primary"} onClick={this.handleClose} disabled={!this.state.data.rejectionreason || this.state.approveButton === "disabled"}>Reject</Button>
                </Modal.Footer>
            </Modal>
            </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(mapStateToProps)
(ConfirmationScreen) 