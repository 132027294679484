import {
  SHOWALERT,
  HIDEALERT,
  FLUSH_MENU_TREE,
  NAVURL,
  INITIATECOMMON,
  SET_MENU_TREE,
  FLESH_COMMON_OBJECT,
  GET_CURRENT_MENU_TREE,
  SHOW_LOADER,
  HIDE_LOADER,
  GET_USER_CONFIGURATION,
} from "../constants/action-types";

export default function common(state = [], action) {
  switch (action.type) {
    case SHOWALERT:
      return {
        ...state,
        alert: action.text,
        status: action.status,
      };
    case HIDEALERT:
      return {
        ...state,
        alert: null,
        status: null,
      };
    case GET_CURRENT_MENU_TREE:
      return state.menuTree ? state.menuTree : [];
    case FLESH_COMMON_OBJECT:
      return {
        url: "",
        alert: null,
        status: null,
        menuTree: [],
      };
    case FLUSH_MENU_TREE:
      return {
        ...state,
        menuTree: [],
      };
    case NAVURL:
      return {
        ...state,
        url: action.url,
      };
    case SET_MENU_TREE:
      return {
        ...state,
        menuTree: action.menuTree,
      };
    case INITIATECOMMON:
      return action.data;
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case GET_USER_CONFIGURATION:
      return {
        ...state,
        configuration: {
          ...action.data,
        },
      };
    default:
      return state;
  }
}
