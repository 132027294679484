import React, { Fragment, Component } from 'react';
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux';
import { getRequest, postRequest } from '../globalhelper/helper';
import Switch from '@material-ui/core/Switch';
import * as Maticons from '@material-ui/icons';


class MenuTree extends Component{
    constructor(props){
        super(props);
        this.state = {
            formData : {
                module : '',
                process : '',
                type : '',
            },
            moduleList : [],
            typeList : [
                {
                    name : "Create menu tree for a module",
                    value : 'module',
                },
                {
                    name : "Attach menu tree to a tenant",
                    value : 'tenant',
                }
            ],
            iconsList : Object.keys(Maticons),
            processList : [],
            menuList : [],
            showTable : false,
            colors: ["antiquewhite", "aquamarine", "yellow", "darkturquoise", "lightgreen", "aqua", "pink"],
        }
    }

    onProcessChange = (process) => {
        console.log(process);
    }

    onModuleChange = (module) => {
        // postRequest('api/module/moduleProcess', { moduleName : module }).then(response => {
        //     if(response.type === "success")
        //     {
        //         this.setState({
        //             ...this.state,
        //             processList : response.res.data
        //         }, () => {
        //             if(this.state.processList.length === 1)
        //             {
        //                 this.setState({
        //                     ...this.state,
        //                     formData : {
        //                         ...this.state.formData,
        //                         process : this.state.processList[0].name
        //                     }
        //                 }, () => {
        //                     this.onProcessChange(this.state.formData.process);
        //                 })
        //             }
        //         })
        //     }
        // })
        getRequest('api/module/getMenuTreeForModule?module=' + module).then(response => {
            if(response.type === "success")
            {
                const INITIAL_MENU = {
                    submenus : [
                        {
                            submenus : [
                                {
                                    submenus : []
                                }
                            ]
                        }
                    ]
                }
                this.setState({
                    ...this.state,
                    menuList : response.res.data.length > 0 ? response.res.data : INITIAL_MENU,
                    showTable : true
                })
            }
        })
    }

    getModuleList = () => {
        getRequest('api/module/modules').then(response => {
            if(response.type === "success")
            {
                this.setState({
                    ...this.state,
                    moduleList : response.res.data
                // }, () => {
                //     if(this.state.moduleList.length === 1)
                //     {
                //         this.setState({
                //             ...this.state,
                //             formData : {
                //                 ...this.state.formData,
                //                 module : this.state.moduleList[0].name
                //             }
                //         }, () => {
                //             this.onModuleChange(this.state.formData.module);
                //         })
                //     }
                })
            }
        })
    }

    componentDidMount = () => {
        this.getModuleList();
    }

    handleChange = (event) => {
        const name = event.target.name, value = event.target.value;

        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                [name] : value
            }
        })
    }

    addRow = (ind) => {
        var previousTree = this.state.menuList;

        var item = {
            dashboard_id: "",
            group: false,
            icon: "",
            name: "",
            node: "",
            open: false,
            pagename: null,
            pagetype: "",
            short_text: "",
            submenus : [],
            url: "",
        }
        previousTree.splice(ind+1,0,item);
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
        })
    }

    removeRow = (ind) => {
        var previousTree = this.state.menuList;
        previousTree.splice(ind,1);
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
        })
    }

    addSubRow = (ind, subInd) => {
        var previousTree = this.state.menuList;

        var item = {
            dashboard_id: "",
            group: false,
            icon: "",
            name: "",
            node: "",
            open: false,
            pagename: null,
            pagetype: "",
            short_text: "",
            submenus : [],
            url: "",
        }
        previousTree[ind].submenus.splice(subInd+1,0,item);
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
        })
    }

    removeSubRow = (ind,subInd) => {
        var previousTree = this.state.menuList;
        previousTree[ind].submenus.splice(subInd,1);
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
        })
    }

    addSuperSubRow = (ind, subInd, superSubInd) => {
        var previousTree = this.state.menuList;

        var item = {
            dashboard_id: "",
            group: false,
            icon: "",
            name: "",
            node: "",
            open: false,
            pagename: null,
            pagetype: "",
            short_text: "",
            submenus : [],
            url: "",
        }
        previousTree[ind].submenus[subInd].submenus.splice(superSubInd+1,0,item);
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
        })
    }

    removeSuperSubRow = (ind,subInd, superSubInd) => {
        var previousTree = this.state.menuList;
        previousTree[ind].submenus[subInd].submenus.splice(superSubInd,1);
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
        })
    }


    addOrRemoveGroupToMenu = (ind) => {
        var previousTree = this.state.menuList;

        previousTree[ind].submenus = this.state.menuList[ind].group ? [{ submenus : [] }] : [];
    }

    addOrRemoveGroupToSubMenu = (ind, subInd) => {
        var previousTree = this.state.menuList;

        previousTree[ind].submenus[subInd].submenus = this.state.menuList[ind].submenus[subInd].group ? [{ submenus : [] }] : [];
    }

    addOrRemoveGroupToSuperSubMenu = (ind,subInd,superSubInd) => {
        var previousTree = this.state.menuList;

        previousTree[ind].submenus[subInd].submenus[superSubInd].submenus = this.state.menuList[ind].submenus[subInd].submenus[superSubInd].group ? [{ submenus : [] }] : [];
    }

    
    handleMainRowChange = (ev, ind) => {
        const name = ev.target.name, value = name === "group" ? ev.target.checked : ev.target.value;
        console.log(name, value)
        var previousTree = this.state.menuList;
        previousTree[ind][name] = value;
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            // this.forceUpdate();
            if(name === "group")
            {
                this.addOrRemoveGroupToMenu(ind);
            }
        })

    }

    
    handleSubRowChange = (ev, ind, subInd) => {
        const name = ev.target.name, value = name === "group" ? ev.target.checked : ev.target.value;
        console.log(name, value)
        var previousTree = this.state.menuList;
        previousTree[ind].submenus[subInd][name] = value;
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
            if(name === "group")
            {
                this.addOrRemoveGroupToSubMenu(ind, subInd);
            }
        })

    }

    
    handleSuperSubRowChange = (ev, ind, subInd, superSubInd) => {
        const name = ev.target.name, value = name === "group" ? ev.target.checked : ev.target.value;
        console.log(name, value)
        var previousTree = this.state.menuList;
        previousTree[ind].submenus[subInd].submenus[superSubInd][name] = value;
        this.setState({
            ...this.state,
            menuList : previousTree
        }, () => {
            this.forceUpdate();
            if(name === "group")
            {
                this.addOrRemoveGroupToSuperSubMenu(ind);
            }
        })

    }

    onSubmit = () => {
        const module = this.state.formData.module;
        const menu_tree = this.state.menuList;

        postRequest('api/module/saveMenuTree', { data : JSON.stringify({ module, menu_tree }) }).then(response => {
            if(response.type === "success")
            {
                console.log(response.res.data);
                window.location.reload();
            }
        })
    }

    render(){
        const { menuList } = this.state;
        return(
            <Fragment>
                <form>
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Label>Type</Form.Label>
                            {/* this.ontypeChange(ev.target.value) */}
                            <Form.Control as="select" name="type" id="type" onChange={(ev) => {this.handleChange(ev);}} defaultValue={this.state.formData.type}>
                                <option>Select a type</option>
                                { this.state.typeList.map((type, ind) => 
                                    <option key={ind} value={type.value} > {type.name} </option>
                                    ) }
                            </Form.Control>
                        </div>
                        { this.state.formData.type === "module" &&
                            <div className="col-md-3">
                                <Form.Label>Module</Form.Label>
                                <Form.Control as="select" name="module" id="module" onChange={(ev) => {this.handleChange(ev); this.onModuleChange(ev.target.value)}} defaultValue={this.state.formData.module}>
                                    <option>Select a module</option>
                                    { this.state.moduleList.map((module, ind) => 
                                        <option key={ind} value={module.name} > {module.short_text} </option>
                                        ) }
                                </Form.Control>
                            </div>
                        }
                    </div>
                </form>
                <br /><br />
                { this.state.showTable ? 
                    <div className="row">
                        <div className="col-md-12">
                            <table border={1} width="100%">
                                <thead>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>URL</th>
                                    <th>Has Submenu</th>
                                    <th>Icon</th>
                                    <th>Type</th>
                                    <th>Visual Rendering Name</th>
                                    <th>Dashboard ID</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {menuList.map((menu, ind) => {
                                        return( <Fragment  key={ind} >
                                            {/* this.state.colors[Math.floor(Math.random() * this.state.colors.length)] */}
                                            <tr style={{ backgroundColor : 'skyblue' }} >
                                                <td>{ ind + 1 }</td>
                                                <td>
                                                    <input value={menu.short_text} name="short_text" id="short_text" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } />
                                                </td>
                                                <td>
                                                    <input value={menu.url} name="url" id="url" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } />
                                                </td>
                                                <td>
                                                    {/* <input type="checkbox" value={menu.group} name="group" id="group" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } /> */}
                                                    <Switch defaultChecked={menu.group} name="group" id="group" onChange={(ev) =>  this.handleMainRowChange(ev, ind)} />
                                                    {/* <checkbox checked={menu.submenu} name="submenu" id="submenu" onChange={(ev) => { menu[ev.target.name] = ev.target.checked }} ></checkbox> */}
                                                </td>
                                                <td>
                                                    {/* <input value={menu.icon} name="icon" id="icon" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } /> */}
                                                    <select value={menu.icon} name="icon" id="icon" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } >
                                                        { this.state.iconsList.map((item, key) => {
                                                            const IconToRender = () =>  Maticons[item]
                                                            return(
                                                                <option key={key} value={item} > 
                                                                    {/* <IconToRender/>  */}
                                                                    {item}
                                                                    {/* <div>
                                                                    </div> */}
                                                                </option>
                                                            )
                                                        }
                                                        ) }
                                                    </select>
                                                </td>
                                                <td>
                                                    <input value={menu.pagetype} name="pagetype" id="pagetype" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } />
                                                </td>
                                                <td>
                                                    <input value={menu.pagename} name="pagename" id="pagename" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } />
                                                </td>
                                                <td>
                                                    <input value={menu.dashboard_id} name="dashboard_id" id="dashboard_id" onChange={(ev) =>  this.handleMainRowChange(ev, ind) } />
                                                </td>
                                                <td>
                                                    <button className="btn btn-success" onClick={() => this.addRow(ind) } > <i className="fa fa-plus" ></i> </button>
                                                    <button className="btn btn-danger" onClick={() => this.removeRow(ind) }> <i className="fa fa-times" ></i> </button>
                                                </td>
                                            </tr>
                                            {  menu.submenus.length > 0 ?
                                                <tr>
                                                    <td ></td>
                                                    <td colSpan={8} style={{ backgroundColor : 'thistle' }} >
                                                        <table width="100%" > 
                                                            <thead>
                                                                <tr>
                                                                    <th> S.No</th>
                                                                    <th> Name </th>
                                                                    <th> URL </th>
                                                                    <th> Has Submenu</th>
                                                                    <th> Icon </th>
                                                                    <th> Type </th>
                                                                    <th> Visual Rendering Name</th>
                                                                    <th> Dashboard ID </th>
                                                                    <th> Action </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                menu.submenus.map((submenu, subInd) => 
                                                                    <Fragment key={subInd}>
                                                                        <tr >
                                                                            <td>{subInd + 1}</td>
                                                                            <td>
                                                                                <input value={submenu.short_text} name="short_text" id="short_text" onChange={(ev) => this.handleSubRowChange(ev, ind, subInd)} />
                                                                            </td>
                                                                            <td>
                                                                                <input value={submenu.url} name="url" id="url" onChange={(ev) => this.handleSubRowChange(ev, ind, subInd)} />
                                                                            </td>
                                                                            <td width="100px">
                                                                                <Switch defaultChecked={submenu.group} name="group" id="group" onChange={(ev) => this.handleSubRowChange(ev, ind, subInd)} />
                                                                            </td>
                                                                            <td>
                                                                                {/* <input value={submenu.icon} name="icon" id="icon" onChange={(ev) => this.handleSubRowChange(ev, ind, subInd)} /> */}
                                                                                <select value={submenu.icon} name="icon" id="icon" onChange={(ev) =>  this.handleSubRowChange(ev, ind, subInd) } >
                                                                                    { this.state.iconsList.map((item, key) => {
                                                                                        const IconToRender = () =>  Maticons[item]
                                                                                        return(
                                                                                            <option key={key} value={item} > 
                                                                                                {/* <IconToRender/>  */}
                                                                                                {item}
                                                                                                {/* <div>
                                                                                                </div> */}
                                                                                            </option>
                                                                                        )
                                                                                    }
                                                                                    ) }
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <input value={submenu.pagetype} name="pagetype" id="pagetype" onChange={(ev) => this.handleSubRowChange(ev, ind, subInd)} />
                                                                            </td>
                                                                            <td>
                                                                                <input value={submenu.pagename} name="pagename" id="pagename" onChange={(ev) => this.handleSubRowChange(ev, ind, subInd)} />
                                                                            </td>
                                                                            <td>
                                                                                <input value={submenu.dashboard_id} name="dashboard_id" id="dashboard_id" onChange={(ev) => this.handleSubRowChange(ev, ind, subInd)} />
                                                                            </td>
                                                                            <td>
                                                                                <button className="btn btn-success" onClick={() => this.addSubRow(ind,subInd) } > <i className="fa fa-plus" ></i> </button>
                                                                                <button className="btn btn-danger" onClick={() => this.removeSubRow(ind, subInd) }> <i className="fa fa-times" ></i> </button>
                                                                            </td>
                                                                        </tr>
                                                                        {  submenu.submenus.length > 0 ?
                                                                            <tr >
                                                                                <td></td>
                                                                                <td colSpan={16} style={{ backgroundColor : 'lightgrey' }}  >
                                                                                    <table>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th> S.No </th>
                                                                                                <th> Name </th>
                                                                                                <th> URL </th>
                                                                                                <th> Icon </th>
                                                                                                <th> Type </th>
                                                                                                <th> Visual Rendering Name</th>
                                                                                                <th> Dashboard ID </th>
                                                                                                <th> Action </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            { submenu.submenus.map((superSub, superInd) => 
                                                                                                <tr key={superInd} >
                                                                                                    <td width="5%" >{superInd + 1}</td>
                                                                                                    <td width="15%" >
                                                                                                        <input value={superSub.short_text} name="short_text" id="short_text" onChange={(ev) => this.handleSuperSubRowChange(ev, ind, subInd,superInd)} />
                                                                                                    </td>
                                                                                                    <td width="12.5%" >
                                                                                                        <input value={superSub.url} name="url" id="url" onChange={(ev) => this.handleSuperSubRowChange(ev, ind, subInd,superInd)} />
                                                                                                    </td>
                                                                                                    <td width="12.5%" >
                                                                                                        {/* <input value={superSub.icon} name="icon" id="icon" onChange={(ev) => this.handleSuperSubRowChange(ev, ind, subInd,superInd)} /> */}
                                                                                                        <select value={superSub.icon} name="icon" id="icon" onChange={(ev) =>  this.handleSuperSubRowChange(ev, ind, subInd,superInd) } >
                                                                                                            { this.state.iconsList.map((item, key) => {
                                                                                                                const IconToRender = () =>  Maticons[item]
                                                                                                                return(
                                                                                                                    <option key={key} value={item} > 
                                                                                                                        {/* <IconToRender/>  */}
                                                                                                                        {item}
                                                                                                                        {/* <div>
                                                                                                                        </div> */}
                                                                                                                    </option>
                                                                                                                )
                                                                                                            }
                                                                                                            ) }
                                                                                                        </select>
                                                                                                    </td>
                                                                                                    <td width="12.5%" >
                                                                                                        <input value={superSub.pagetype} name="pagetype" id="pagetype" onChange={(ev) => this.handleSuperSubRowChange(ev, ind, subInd,superInd)} />
                                                                                                    </td>
                                                                                                    <td width="12.5%" >
                                                                                                        <input value={superSub.pagename} name="pagename" id="pagename" onChange={(ev) => this.handleSuperSubRowChange(ev, ind, subInd,superInd)} />
                                                                                                    </td>
                                                                                                    <td width="12.5%" >
                                                                                                        <input value={superSub.dashboard_id} name="dashboard_id" id="dashboard_id" onChange={(ev) => this.handleSuperSubRowChange(ev, ind, subInd,superInd)} />
                                                                                                    </td>
                                                                                                    <td width="12.5%" >
                                                                                                        <button className="btn btn-success" onClick={() => this.addSuperSubRow(ind,subInd, superInd) } > <i className="fa fa-plus" ></i> </button>
                                                                                                        <button className="btn btn-danger" onClick={() => this.removeSuperSubRow(ind, subInd, superInd) }> <i className="fa fa-times" ></i> </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        : null}
                                                                    </Fragment>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            : null
                                            }
                                        </Fragment>
                                    )}
                                    )
                                }
                                </tbody>
                                </table>
                        </div>
                        <br /><br />
                        <div className="col-md-12" align="right">
                            <button className="btn btn-primary" onClick={this.onSubmit} >Save</button>
                        </div>
                    </div>
                    : null}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
  return {
    store : state
  }
};

export default connect(
  mapStateToProps
)(MenuTree);
