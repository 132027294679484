import React, {useState, useEffect, Fragment} from 'react';
import {Form, Button} from 'react-bootstrap';
import '../CI/COD.css';
import { postRequest, getRequest } from '../../globalhelper/helper';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer'
import Select from 'react-select';

const Input = (props) => {
    // Convert the dropdown array to an array of objects with label and value properties
    const options = props.dropdown.sort().map((item) => ({
      label: item,
      value: item,
    }));
  
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <div
          className="col-lg-2"
          style={{
            marginRight: 15,
            fontFamily: 'sans-serif',
            fontSize: '15px', // Add 'px' to fontSize for proper CSS formatting
            fontWeight: 'bold',
          }}
        >
          {props.title}
        </div>
        <Select
          className="col-lg-3"
          name={props.name}
          value={options.find((option) => option.value === props.value)} // Set the value to the selected option
          options={options}
          onChange={(selectedOption) =>
            props.handleChange({
              target: { name: props.name, value: selectedOption ? selectedOption.value : '' },
            })
          }
          placeholder="Select"
          styles={{
            control: (provided) => ({
              ...provided,
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }),
          }}
        />
      </div>
    );
  };
  

export default function GoodsAndServicesSearch() {
    const [tableData, setTableData] = useState([])
    const [input, setInput] = useState({
        document_number: '',
        vendor_id: '',
        vendor_name: '',
        document_type: '',
        orgname: ''
    })
    const [redirect, setRedirect] = useState(false)
    const [searchJSON, setSearchJSON] = useState([])
    const [dropdown, setDropdown] = useState({
        documentNumber: [],
        vendorID: [],
        vendorName: [],
        documentType: [],
        orgname: []
    })
    const [companyDetails, setCompanyDetails] = useState([])
    const [showTable, setShowTable] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const arr = responseData.map(item => item.orgname)
            setDropdown((prevState) => {
                return {...prevState,
                orgname: arr}
            })
            setCompanyDetails(responseData)
        })
    }, [])

    const getFilteredArray = (value, key) => {
        let dropdown_array = value.map((value) => value[key])
        let filtered_dropdown_array = [...new Set(dropdown_array)]
        return filtered_dropdown_array.filter((item) => item !== undefined && item !== '')
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setInput((prevState) => {
            return {...prevState, [name]: value}
        })
    }

    const handleSearch = () => {
        const {document_number, vendor_id, vendor_name, document_type, orgname} = input

        if(orgname){

            setError(false)

            postRequest(`api/edureka/getGoodsAndServicesInfoSearch`, {documentNumber:document_number, vendorID: vendor_id, vendorName: vendor_name, documentType: document_type}).then(response => {
    
                console.log(JSON.parse(response.res.data))
                
                const data = JSON.parse(response.res.data) 
    
                if(data && data.length){
                    setTableData(data)
                    setShowTable(true)
                } else {
                    setError(true)
                    setErrorMessage("No data found. Please try some other combination")
                }
            })
        } else {
            setError(true)
            setErrorMessage("Please select all the required fields")
        }
    }

    const handleReset = () => {
        setInput({
            document_number: '',
            vendor_id: '',
            vendor_name: '',
            document_type: '',
            orgname: ''
        })
        setTableData([])
        setShowTable(false)
        setError(false)
    }

    const handleJSON = (item) => {
        const documentNumber = item["GRN/SRN Number"]

        const id = companyDetails.filter(item => item.orgname === input.orgname)

        const companyid = id.map(item => item.orgid)

        postRequest(`api/edureka/getGoodsAndServicesInfoJSON?orgid=${companyid[0]}`, {documentNumber: documentNumber}).then(response => {
            console.log(response.res.data)
            if(response.res.status === "success"){
                setSearchJSON(response.res.data)
                setRedirect(true)
            } else {
                console.log('No JSON exists')
            }
        })
    }

    const onBack = () => {
        setRedirect(false)
        setSearchJSON([])
    }

    const handleOrgChange = (e) => {
        const {name, value} = e.target
        console.log(name, value)
        setInput((prevState) => {
            return {...prevState, orgname: value}
        })

        setTimeout(() => {
            handleRemainingAPIs(value)
        }, 100)       
    }

    const handleRemainingAPIs = (value) => {
        console.log(input.orgname)
        const id = companyDetails.filter(item => item.orgname === value)
        const companyid = id.map(item => item.orgid)
        console.log("Entered")

        getRequest(`api/edureka/getGoodsAndServicesInfo?orgid=${companyid[0]}`).then(response => {
            const data = JSON.parse(response.res.data) ? JSON.parse(response.res.data) : []

            let document_number = getFilteredArray(data, "GRN")
            let vendorid = getFilteredArray(data, "Vendor ID")
            let vendorname = getFilteredArray(data, "Vendor Name")
            let documenttype = getFilteredArray(data, "Document Type")

            setDropdown((prevState) => {
                return {...prevState,
                documentNumber: document_number,
                vendorID: vendorid,
                vendorName: vendorname,
                documentType: documenttype
            }
            })
        })
    }

    if(redirect){
        return (
            <VR2_Forms
                screenName={"GoodsAndServicesEntries"}
                screenAction={"edit"}
                From_SearchJSON={searchJSON}
                onBack={onBack}/>
        )
    }

  else return (
    <Fragment>
        <div className='header_div'>Goods and Services Entries</div>

        <br/>

        <Form style={{display:'flex', flexDirection:'column'}}>

            <Input title='Organization Name' name='orgname' value={input.orgname} dropdown={dropdown.orgname} handleChange={handleOrgChange}/>

            <Input title="SRN/GRN Number" name="document_number" value={input.document_number} dropdown={dropdown.documentNumber} handleChange={handleChange}/>

            <Input title="Vendor ID" name="vendor_id" value={input.vendor_id} dropdown={dropdown.vendorID} handleChange={handleChange}/>

            <Input title="Vendor Name" name="vendor_name" value={input.vendor_name} dropdown={dropdown.vendorName} handleChange={handleChange}/>

            <Input title="Document Type" name="document_type" value={input.document_type} dropdown={dropdown.documentType} handleChange={handleChange}/>

            <div style={{textAlign:'center'}}>
                <Button variant='success' onClick={handleSearch}>Search</Button>
                <Button variant='danger' style={{marginLeft:10}} onClick={handleReset}>Reset</Button>
            </div>
        </Form>

        <br/>

        {showTable && tableData.length ?
        <div>
            <table border="1" id="table_styling">
                <thead>
                    <tr>
                        <th>GRN/SRN Number</th>
                        <th>Vendor ID</th>
                        <th>Vendor Name</th>
                        <th>Status</th>
                        <th>Document Type</th>
                    </tr>
                </thead>

                <tbody>
                    {tableData.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td><Button variant="link" size="sm" onClick={() => handleJSON(item)}>{item["GRN/SRN Number"]}</Button></td>
                                <td>{item["Sender ID"]}</td>
                                <td>{item["Sender Name"]}</td>
                                <td>{item["Status"]}</td>
                                <td>{item["Document Type"]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}

        {error ?
        <div className='center-screen'>
            {errorMessage}
        </div> :  null}
    </Fragment>
  )
}



// import React, {useState, useEffect, Fragment} from 'react';
// import {Form, Button} from 'react-bootstrap';
// import '../CI/COD.css';
// import { postRequest, getRequest } from '../../globalhelper/helper';
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
// import ReactSelect from 'react-select';

// // const Input = (props) => {
// //     return (
// //         <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
// //             <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>{props.title}</div>
// //             <select className='col-lg-3' name={props.name} value={props.value} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={props.handleChange}>
// //                 <option value=''>Select</option>
// //                 {props.dropdown.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
// //             </select>
// //         </div>
// //     )
// // }

// const Input = (props) => {
//     return (
//         <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//             <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
//                 {props.title}
//             </div>
//             <ReactSelect
//                 className='col-lg-3'
//                 name={props.name}
//                 value={props.dropdown.find(option => option.value === props.value)}
//                 onChange={(selectedOption) => props.handleChange({ target: { name: props.name, value: selectedOption.value } })}
//                 options={props.dropdown.map(item => ({ value: item, label: item }))}
//                 styles={{
//                     container: (base) => ({ ...base, padding: 5 }),
//                     control: (base) => ({ 
//                         ...base, 
//                         borderRadius: 5, 
//                         border: '1px solid #ccc', 
//                         minHeight: '38px',  
//                         paddingLeft: '0px', // Remove left padding on the control to avoid clipping
//                     }),
//                     valueContainer: (base) => ({
//                         ...base,
//                         padding: '0 15px', // Add horizontal padding here instead
//                     }),
//                     input: (base) => ({
//                         ...base,
//                         margin: 0,
//                         padding: 0, 
//                     }),
//                     singleValue: (base) => ({
//                         ...base,
//                         margin: 0,
//                         padding: 0,
//                     })
//                 }}
//                 placeholder="Select"
//             />
//         </div>
//     );
// };

// export default function GoodsAndServicesSearch() {
//     const [tableData, setTableData] = useState([])
//     const [input, setInput] = useState({
//         document_number: '',
//         vendor_id: '',
//         vendor_name: '',
//         document_type: '',
//         orgname: ''
//     })
//     const [redirect, setRedirect] = useState(false)
//     const [searchJSON, setSearchJSON] = useState([])
//     const [dropdown, setDropdown] = useState({
//         documentNumber: [],
//         vendorID: [],
//         vendorName: [],
//         documentType: [],
//         orgname: []
//     })
//     const [companyDetails, setCompanyDetails] = useState([])
//     const [showTable, setShowTable] = useState(false)
//     const [error, setError] = useState(false)
//     const [errorMessage, setErrorMessage] = useState("")

//     useEffect(() => {
//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const arr = responseData.map(item => item.orgname)
//             setDropdown((prevState) => {
//                 return {...prevState,
//                 orgname: arr}
//             })
//             setCompanyDetails(responseData)
//         })
//     }, [])

//     const getFilteredArray = (value, key) => {
//         let dropdown_array = value.map((value) => value[key])
//         let filtered_dropdown_array = [...new Set(dropdown_array)]
//         return filtered_dropdown_array.filter((item) => item !== undefined && item !== '')
//     }

//     const handleChange = (e) => {
//         const {name, value} = e.target
//         setInput((prevState) => {
//             return {...prevState, [name]: value}
//         })
//     }

//     const handleSearch = () => {
//         const {document_number, vendor_id, vendor_name, document_type, orgname} = input

//         if(orgname){

//             setError(false)

//             postRequest(`api/edureka/getGoodsAndServicesInfoSearch`, {documentNumber:document_number, vendorID: vendor_id, vendorName: vendor_name, documentType: document_type}).then(response => {
    
//                 console.log(JSON.parse(response.res.data))
                
//                 const data = JSON.parse(response.res.data) 
    
//                 if(data && data.length){
//                     setTableData(data)
//                     setShowTable(true)
//                 } else {
//                     setError(true)
//                     setErrorMessage("No data found. Please try some other combination")
//                 }
//             })
//         } else {
//             setError(true)
//             setErrorMessage("Please select all the required fields")
//         }
//     }

//     const handleReset = () => {
//         setInput({
//             document_number: '',
//             vendor_id: '',
//             vendor_name: '',
//             document_type: '',
//             orgname: ''
//         })
//         setTableData([])
//         setShowTable(false)
//         setError(false)
//     }

//     const handleJSON = (item) => {
//         const documentNumber = item["GRN/SRN Number"]

//         const id = companyDetails.filter(item => item.orgname === input.orgname)

//         const companyid = id.map(item => item.orgid)

//         postRequest(`api/edureka/getGoodsAndServicesInfoJSON?orgid=${companyid[0]}`, {documentNumber: documentNumber}).then(response => {
//             console.log(response.res.data)
//             if(response.res.status === "success"){
//                 setSearchJSON(response.res.data)
//                 setRedirect(true)
//             } else {
//                 console.log('No JSON exists')
//             }
//         })
//     }

//     const onBack = () => {
//         setRedirect(false)
//         setSearchJSON([])
//     }

//     const handleOrgChange = (e) => {
//         const {name, value} = e.target
//         console.log(name, value)
//         setInput((prevState) => {
//             return {...prevState, orgname: value}
//         })

//         setTimeout(() => {
//             handleRemainingAPIs(value)
//         }, 100)       
//     }

//     const handleRemainingAPIs = (value) => {
//         console.log(input.orgname)
//         const id = companyDetails.filter(item => item.orgname === value)
//         const companyid = id.map(item => item.orgid)
//         console.log("Entered")

//         getRequest(`api/edureka/getGoodsAndServicesInfo?orgid=${companyid[0]}`).then(response => {
//             const data = JSON.parse(response.res.data) ? JSON.parse(response.res.data) : []

//             let document_number = getFilteredArray(data, "GRN")
//             let vendorid = getFilteredArray(data, "Vendor ID")
//             let vendorname = getFilteredArray(data, "Vendor Name")
//             let documenttype = getFilteredArray(data, "Document Type")

//             setDropdown((prevState) => {
//                 return {...prevState,
//                 documentNumber: document_number,
//                 vendorID: vendorid,
//                 vendorName: vendorname,
//                 documentType: documenttype
//             }
//             })
//         })
//     }

//     if(redirect){
//         return (
//             <VR2_Forms
//                 screenName={"GoodsAndServicesEntries"}
//                 screenAction={"edit"}
//                 From_SearchJSON={searchJSON}
//                 onBack={onBack}/>
//         )
//     }

//   else return (
//     <Fragment>
//         <div className='header_div'>Goods and Services Entries</div>

//         <br/>

//         <Form style={{display:'flex', flexDirection:'column'}}>

//             <Input title='Organization Name' name='orgname' value={input.orgname} dropdown={dropdown.orgname} handleChange={handleOrgChange}/>

//             <Input title="SRN/GRN Number" name="document_number" value={input.document_number} dropdown={dropdown.documentNumber} handleChange={handleChange}/>

//             <Input title="Vendor ID" name="vendor_id" value={input.vendor_id} dropdown={dropdown.vendorID} handleChange={handleChange}/>

//             <Input title="Vendor Name" name="vendor_name" value={input.vendor_name} dropdown={dropdown.vendorName} handleChange={handleChange}/>

//             <Input title="Document Type" name="document_type" value={input.document_type} dropdown={dropdown.documentType} handleChange={handleChange}/>

//             <div style={{textAlign:'center'}}>
//                 <Button variant='success' onClick={handleSearch}>Search</Button>
//                 <Button variant='danger' style={{marginLeft:10}} onClick={handleReset}>Reset</Button>
//             </div>
//         </Form>

//         <br/>

//         {showTable && tableData.length ?
//         <div>
//             <table border="1" id="table_styling">
//                 <thead>
//                     <tr>
//                         <th>GRN/SRN Number</th>
//                         <th>Vendor ID</th>
//                         <th>Vendor Name</th>
//                         <th>Status</th>
//                         <th>Document Type</th>
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {tableData.map((item, index) => {
//                         return (
//                             <tr key={index}>
//                                 <td><Button variant="link" size="sm" onClick={() => handleJSON(item)}>{item["GRN/SRN Number"]}</Button></td>
//                                 <td>{item["Sender ID"]}</td>
//                                 <td>{item["Sender Name"]}</td>
//                                 <td>{item["Status"]}</td>
//                                 <td>{item["Document Type"]}</td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div> : null}

//         {error ?
//         <div className='center-screen'>
//             {errorMessage}
//         </div> :  null}
//     </Fragment>
//   )
// }
