import React, { Component } from 'react';
import './upload.css'
import {Form, Button, FormGroup, Label, FormText, Input} from 'reactstrap';
import FileBase64 from 'react-file-base64';
import {getRequest, postRequest, convertNumberIntoINR, getHumanReadableDate} from '../../globalhelper/helper';

export class Textract extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         file: null,
         confirmation: 'Please Upload your PDF',
         isLoading: '',
         files: [],
         showtable: false,
         allData: [],
         formData: {
            ADDRESS: '',
            CITY: '',
            ITEM: '',
            PRICE: '',
            QUANTITY: '',
            DISCOUNT: '',
            INVOICE_RECEIPT_DATE: '',
            INVOICE_RECEIPT_ID:'',
            RECEIVER_ADDRESS: '',
            RECEIVER_NAME: '',
            RECEIVER_GST_NUMBER: '',
            STATE: '',
            STREET: '',
            TAX: '',
            TOTAL: '',
            VENDOR_NAME: '',
            VENDOR_GST_NUMBER: '',
            VENDOR_PAN_NUMBER: '',
            PRODUCT_CODE: '',
            SUBTOTAL: ''
         }
      }
    }

    handleChange = (event) => {
        event.preventDefault()
        const NAME = event.target.name;
        const VALUE = event.target.value;

        this.setState({
            [NAME]: VALUE,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })

    }

    handleSubmit = (event) => {
        event.preventDefault()
        this.setState({
            ...this.state,
            confirmation: 'Uploading'
        })
    }

    getFiles = async (files) => {
        this.setState({
            ...this.state,
            isLoading: "Extracting",
            files: files,
            showtable: false
        })

        let UID= Math.round(1+ Math.random() * (1000000-1))

        const data={
            fileExt:"pdf",
            imageID: UID,
            folder: UID,
            img : this.state.files[0].base64
        };

        console.log(data)

        this.setState({
            ...this.state,
            confirmation: "Processing..."
        })

        await fetch(
            'https://wlk86uyo09.execute-api.us-east-1.amazonaws.com/Dev',
            {
            method: "POST",
            headers: {
                Accept : "application/json",
                "Content-Type": "application.json"
            },
            body : JSON.stringify(data)
            }
        );

        this.setState({
            confirmation: 'Almost there...'
        })

        let targetFile = UID + '.pdf';
        const finalData = []

        await fetch(
            ' https://wlk86uyo09.execute-api.us-east-1.amazonaws.com/Dev/ocr',
            {
            method: "POST",
            headers: {
                Accept : "application/json",
                "Content-Type": "application.json"
            },
            body : JSON.stringify(targetFile)
            }
        ).then(res=> {
            console.log(res)
            return res.json()
        }).then(res=>{ 
            console.log(res)
            finalData.push(res)
            console.log(finalData)
            return finalData
        })

        const address = finalData.map(res=>res[0].ADDRESS)
        const city = finalData.map(res=>res[0].CITY)
        const item = finalData.map(res=>res[0].ITEM)
        const discount = finalData.map(res=>res[0].DISCOUNT)
        const quantity = finalData.map(res=>res[0].QUANTITY)
        const receipt_date = finalData.map(res=>res[0].INVOICE_RECEIPT_DATE)
        const receipt_id = finalData.map(res=>res[0].INVOICE_RECEIPT_ID)
        const receiver_address = finalData.map(res=>res[0].RECEIVER_ADDRESS)
        const name = finalData.map(res=>res[0].RECEIVER_NAME)
        const price = finalData.map(res=>res[0].PRICE)
        const state = finalData.map(res=>res[0].STATE)
        const street = finalData.map(res=>res[0].STREET)
        const tax = finalData.map(res=>res[0].TAX)
        const total = finalData.map(res=>res[0].TOTAL)
        const vendor_name = finalData.map(res=>res[0].VENDOR_NAME)
        const subtotal = finalData.map(res=>res[0].SUBTOTAL)
        const product_code = finalData.map(res=>res[0].PRODUCT_CODE)
        const receivergstnumber = finalData.map(res=>res[0].RECEIVER_GST_NUMBER)
        const taxpayerid = finalData.map(res=>res[0].TAX_PAYER_ID)
        const vendoraddress = finalData.map(res=>res[0].VENDOR_ADDRESS)
        const vendorgstnumber = finalData.map(res=>res[0].VENDOR_GST_NUMBER)
        const vendorpannumber = finalData.map(res=>res[0].VENDOR_PAN_NUMBER)

        console.log(price)

        this.setState({
            ...this.state,
            allData: finalData,
            formData: {
                ADDRESS: address[0],
                CITY: city[0],
                DISCOUNT: discount[0] ? discount[0] : 0,
                QUANTITY: quantity[0] ? quantity[0] : 0,
                ITEM: item[0],
                PRICE: price[0],
                INVOICE_RECEIPT_DATE: receipt_date[0],
                INVOICE_RECEIPT_ID: receipt_id[0],
                RECEIVER_ADDRESS: receiver_address[0],
                RECEIVER_GST_NUMBER: receivergstnumber[0] ? receivergstnumber[0] : '',
                RECEIVER_NAME: name[0],
                STATE: state[0],
                STREET: street[0],
                TAX: tax[0],
                TAX_PAYER_ID: taxpayerid[0] ? taxpayerid[0] : '',
                TOTAL: total[0],
                VENDOR_ADDRESS:  vendoraddress[0] ? vendoraddress[0] : '',
                VENDOR_GST_NUMBER:  vendorgstnumber[0] ? vendorgstnumber[0] : '',
                VENDOR_NAME: vendor_name[0],
                VENDOR_PAN_NUMBER:  vendorpannumber[0] ? vendorpannumber[0] : '',
                SUBTOTAL: subtotal[0] ? subtotal[0] : 0,
                PRODUCT_CODE: product_code[0] ? product_code[0] : 'NA'
            },
            confirmation: "Completed",
            showtable: true
        }, () => this.dataUpload())
    }

    
    dataUpload = async () => {
        const formData = new FormData()

        console.log(this.state.formData)

        formData.append("file", this.state.formData)
        console.log(this.state.formData)
        await postRequest('api/cod/textractPDM', this.state.formData).then(response => {
            console.log(response)
        })
    }

  render() {
    return (
    <div>
        <div className='row'>
            <div className='col-6 offset-3' >
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <div style={{textAlign: 'center', paddingLeft: 110, paddingBottom: 50}}>
                            <h4 className='text-danger'>{this.state.confirmation}</h4>
                        </div>
                        
                        {/* <h4>Please upload your PDF</h4> */}
                        {/* <FormText color='muted'>JPG</FormText>                        */}
                        <div className='form-group files color' style={{paddingBottom: 50}}>
                            <FileBase64 multiple={true} onDone={this.getFiles}></FileBase64>
                        </div> 
                    </FormGroup> 
                </Form>
            </div>
        </div>

        {this.state.showtable ? 
        
        <div className='col-md-6 offset-3' >

            <table border='1' id='table_styling'>
                <tr>
                    <th>Component</th>
                    <th>Value</th>
                </tr>
                <tr>
                    <td>Vendor Name</td>
                    <td>{this.state.formData.VENDOR_NAME}</td>
                </tr>
                <tr>
                    <td>Vendor Address</td>
                    <td>{this.state.formData.ADDRESS}</td>
                </tr>
                <tr>
                    <td>Vendor GST Number</td>
                    <td>{this.state.formData.VENDOR_GST_NUMBER}</td>
                </tr>
                <tr>
                    <td>Vendor PAN Number</td>
                    <td>{this.state.formData.VENDOR_PAN_NUMBER}</td>
                </tr>
                <tr>
                    <td>Receiver Name</td>
                    <td>{this.state.formData.RECEIVER_NAME}</td>
                </tr>
                <tr>
                    <td>Receiver Address</td>
                    <td>{this.state.formData.RECEIVER_ADDRESS}</td>
                </tr>
                <tr>
                    <td>Receiver GST Number</td>
                    <td>{this.state.formData.RECEIVER_GST_NUMBER}</td>
                </tr>
                <tr>
                    <td>Invoice Date</td>
                    <td>{getHumanReadableDate(this.state.formData.INVOICE_RECEIPT_DATE)}</td>
                </tr>
                <tr>
                    <td>Invoice Number/ID</td>
                    <td>{this.state.formData.INVOICE_RECEIPT_ID}</td>
                </tr>
                <tr>
                    <td>Particulars/Item</td>
                    <td>{this.state.formData.ITEM}</td>
                </tr>
                <tr>
                    <td>Product Code</td>
                    <td>{this.state.formData.PRODUCT_CODE}</td>
                </tr>
                <tr>
                    <td>Price</td>
                    <td>{convertNumberIntoINR(this.state.formData.PRICE)}</td>
                </tr>
                <tr>
                    <td>Quantity</td>
                    <td>{this.state.formData.QUANTITY}</td>
                </tr>
                <tr>
                    <td>Discount</td>
                    <td>{this.state.formData.DISCOUNT}</td>
                </tr>
                <tr>
                    <td>Subtotal</td>
                    <td>{convertNumberIntoINR(this.state.formData.SUBTOTAL)}</td>
                </tr>
                <tr>
                    <td>Tax</td>
                    <td>{convertNumberIntoINR(this.state.formData.TAX)}</td>
                </tr>
                <tr>
                    <td>Total Amount</td>
                    <td>{convertNumberIntoINR(this.state.formData.TOTAL)}</td>
                </tr>
            </table>
                   
        </div>
        
        : null}

        
            
        
    </div>
    )
  }
}

export default Textract