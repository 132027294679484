import { Settings } from "@material-ui/icons";
import { Button, Popover } from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import DraggableList from "../DraggableList/DraggableList";

export default function GridToolbarReOrder({ columns, onDragEnd }) {
  return (
    <PopupState disableAutoFocus={true} variant="popover">
      {(popupState) => (
        <>
          <Button
            size="small"
            startIcon={<Settings />}
            {...bindTrigger(popupState)}
          >
            reorder
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <DraggableList
              items={columns}
              onDragEnd={onDragEnd}
              itemLabel="headerName"
              itemId="field"
            ></DraggableList>
          </Popover>
        </>
      )}
    </PopupState>
  );
}
