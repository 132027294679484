import './COD.css';
import {Form} from 'react-bootstrap';

function FormBasicDate({label, value, name, onChange}){
    return (
        <div className='col-md-3'>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input type='date' className='spacing' value={value} name={name} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

export default FormBasicDate