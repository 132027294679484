import React, { Component, Fragment } from 'react'
import { Table } from 'react-bootstrap';
// import tableData from './tableData';
import FORM from './form'
import { connect } from 'react-redux';
import { handleTreeChange, getDropdownOptions, updateChangeLogs, updateStoreVals, updateAthenaStorage, changeDependencyValues, changeValByserviceID, updateDynamicOption_Done } from '../../actions/vr2Action';

import  ConfirmDialog, { confirmDialog } from '../../components/dialog';

import Select from 'react-select'
import _, { forEach } from 'lodash';
import moment from 'moment';
import Spinner from '../../components/BackDrop_Spinner'
import { getRequest, postRequest } from '../../globalhelper/helper'
import Popup from '../../components/SimplePOPUP';
import { AiFillCheckSquare, AiFillCloseSquare, AiFillClockCircle } from 'react-icons/ai';
import { FcSearch } from 'react-icons/fc';
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io';

import Button from '@material-ui/core/Button';
import { ImFolderUpload } from 'react-icons/im';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Tooltip} from '@mui/material'

import GetAppIcon from '@material-ui/icons/GetApp';
import {CSVLink} from 'react-csv';
import CostContributionPopup from '../Edureka/CostContribution/CostContributionPopup'



class MyTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      downloadData: [],
      showSpinner : false,
      showPopUP : false,
      ask_reason : false,
      dynamicOption : [],
      billExtract_theme_save : false,
      popup_table : false,
      open_popup_select : false,
      Give_Val_By_ServiceKey : {},
      isPopupOpen: false,
      Lob:[],
      dynamicValue:[],
      preFillValues:[],
      lastServiceIDSegment:""
    };

    this.token = this.props.screenName
    this.PO_IP_Item_Ref_Pattern_Match_Called = false
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.time !== nextProps.time) {
      // Perform any logic you need to update your component state based on the new props
      this.setState({
        // Update the component's state as needed
        arvind: 'python master'
      });
    }
  }

  componentDidMount() {
    this.props.setFnToCallonVendorIDonChange(this.functionToCall);
    this.getLobOPtions();
  }


  Give_Val_By_ServiceKey = (serviceKey) => {

      console.log(serviceKey, 'Give_Val_By_ServiceKey')

      let VALUE;

      let VR2_Form_Json = this.props.VR2_Form_Json

      let found = false

      this.setState({
          ...this.state, 
          Give_Val_By_ServiceKey : {
              ...this.state.Give_Val_By_ServiceKey,
              [serviceKey]: ""                
          } 
      }, () => {

          VR2_Form_Json.forEach( (each , i) => {

              if(found) return VALUE;
              if(each.children && each.children.length){

                  each.children.forEach( (each2, j) => {

                      if(found) return VALUE;
                      if(each2.children && each2.children.length){

                          each2.children.forEach( (each3, k) => {

                              if(found) return VALUE;

                              if(!each3.children && each3.render && each3.render === "true" && each3.serviceKey === serviceKey ){

                                  VALUE = each3.val

                                  this.setState({
                                      ...this.state, 
                                      Give_Val_By_ServiceKey : {
                                          ...this.state.Give_Val_By_ServiceKey,
                                          [serviceKey]: each3.val               
                                      } 
                                  })    

                                  console.log(VALUE, 'Give_Val_By_ServiceKey')

                                  if(VALUE) found = true                            
                                
                                  if(found) return VALUE; 
                              }
                          }) 
                      }
                  })
              }
          })
      })
  }


  Next_serviceID = (input, num) => {

    let numb = Number(input.split('#').pop()) + num

    let nextNO = numb.toString().padStart(4, '0')

    let array = input.split('#')

    array[array.length -1] = nextNO

    return array.join("#");
  }

  
  COPYTOBELOW = (treeNode) => {

    let INPUT = JSON.parse(JSON.stringify(treeNode))

    let Count = INPUT.children.length

    for(let i=0; i < Count; i++ ){

      let temp = JSON.parse(JSON.stringify(INPUT.children[i]))

      temp.title = this.Next_serviceID(temp.title, Count)
      temp.serviceID = this.Next_serviceID(temp.serviceID, Count)
      temp.serviceKey = this.Next_serviceID(temp.serviceKey, Count)

      let tempChildren = temp.children

      tempChildren.map( each => {
        if(each.serviceKey === "Line ID")  each.val = String(Number(each.val) + Count)
        if(each.serviceKey === "Cost Allocation Category" && each.val === "Plan")  each.val = "Actual"
        each.serviceID = this.Next_serviceID(each.serviceID , Count );
      })

      INPUT.children.push(temp)


        if(i === (Count-1)){

            let found = false
            let VR2_Form_Json = this.props.VR2_Form_Json

            VR2_Form_Json.forEach((each, i) => {

              if (found) return
              if (each.children && each.children.length) {

                  each.children.forEach((each2, j) => {

                    if (found) return
                    if(each2.title === INPUT.title){

                      each2.children = INPUT.children
                      this.props.handleTreeChange(VR2_Form_Json)
                      this.props.rerender("noQuit")
                      found = true
                      return
                    }
                  })
              }
            })
        }
    }
  }


  tableRowAdd = ( obj, parentObj) => {

    const parentID = parentObj.serviceID


    let OBJ = JSON.parse(JSON.stringify(obj))
    let parent_obj = JSON.parse(JSON.stringify(parentObj))
    let VR2_Form_Json = this.props.VR2_Form_Json
    console.log("PARENTOBJ", parent_obj);
    console.log("OBJ", OBJ);

      console.log(OBJ, parent_obj)
     
      parent_obj.title = this.Next_serviceID(parent_obj.title , 1 )
      parent_obj.serviceID = this.Next_serviceID(parent_obj.serviceID , 1 )
      parent_obj.serviceKey = this.Next_serviceID(parent_obj.serviceKey , 1 )


      OBJ.map((each, i) => {
        if(OBJ[i].serviceKey === "Line ID")  OBJ[i].val = String(Number(OBJ[i].val) + 1)
        else OBJ[i].val = '';
        OBJ[i].serviceID = this.Next_serviceID(each.serviceID , 1 );
      })

      parent_obj.children = OBJ

      console.log(parent_obj)

      let found = false
      VR2_Form_Json.forEach((each, i) => {

        if (found) return
        if (each.children && each.children.length) {

          each.children.forEach((each2, j) => {

            if (found) return
            if (each2.children && each2.children.length) {

              each2.children.forEach((each3, m) => {

                if (found) return
                if (each3.serviceID === parentID) {

                  each2.children.push(parent_obj)

                  this.props.handleTreeChange(VR2_Form_Json)
                  this.props.rerender("noQuit")
                  found = true
                  return
                }
              })
            }
          })
        }
      })

  }  

  tableRowDel = ( obj, parentObj) => {

    const parentID = parentObj.serviceID
    
    let VR2_Form_Json = this.props.VR2_Form_Json

      let found = false
      VR2_Form_Json.forEach((each, i) => {

        if (found) return
        if (each.children && each.children.length) {

          each.children.forEach((each2, j) => {

            if (found) return
            if (each2.children && each2.children.length) {

              each2.children.forEach((each3, m) => {

                if (found) return
                if (each3.serviceID === parentID) {

                  each2.children.splice(-1)

                  this.props.handleTreeChange(VR2_Form_Json)
                  this.props.rerender("noQuit")
                  found = true
                  return
                }
              })
            }
          })
        }
      })

  }  



  //from form

  option_undef = (childrenNode) => {

    console.log("if option_undef", childrenNode.title)

    // this.props.formarray undefined

    // let formArray_obj={}

    // this.props.formArray.forEach( each => {
    //     formArray_obj[each.serviceKey] = each.val ? each.val : ""
    // })

    // getDropdownOptions(childrenNode.serviceID, this.props.formArray, formArray_obj, this.props.VR2_Form_Json, false)

    // if(childrenNode.val){

    //     let ev = {
    //         target : {
    //             id : childrenNode.serviceID,
    //             name : childrenNode.serviceKey,
    //             value : childrenNode.val
    //         }
    //     }

    //     this.onDropDownValueChange( ev, "false", "no_onBlur")
    // }

  }

  myChangeHandler = (event, date) => {
      let serviceKey = event.target.name;
      let serviceID = event.target.id;
      let val = event.target.value;

      if(date){
        let date = moment(val, 'YYYY-MM-DD').format('DD-MM-YYYY') 

        val = date === 'Invalid date' ? val : date
      }

      console.log(val)

      let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss')
      console.log(DateTime)

      let logs = this.props.changeLogs
      let vals = this.props.storeVals
      
      
      logs[serviceID] = {
        serviceKey : serviceKey,
        serviceID : serviceID,
        value : val,
        DateTime,
        user: this.props.userData.USR_Email
      }
      
      vals[serviceID] = val
      
      // changeValByserviceID(this.props.VR2_Form_Json, this.props.formArray, serviceID, "val", val, serviceKey)
      
      this.changeValByserviceID( serviceID, "val", val)
      
      this.props.updateChangeLogs(logs)
      this.props.updateStoreVals(vals)

      // this.setState({ [serviceKey]: val, logs });
      this.props.rerender("noQuit")
  }


  changeValByserviceID = (serviceID, key, val) => {

      console.log(serviceID, val)

      let VR2_Form_Json = this.props.VR2_Form_Json

      let found = false

      VR2_Form_Json.forEach( (each , i) => {

          if(found) return
          if(each.children && each.children.length){

              each.children.forEach( (each2, j) => {

                  if(found) return
                  if(each2.children && each2.children.length){

                      each2.children.forEach( (each3, row) => {

                          if(found) return
                          if(!each3.children && each3.render && each3.render === "true" && each3.serviceID === serviceID ){

                              each3[key] = val
                              console.log('super1')

                              this.props.handleTreeChange(VR2_Form_Json)
                              found = true
                              return 
                          }

                          else if(each3.children && each3.children.length){

                              each3.children.forEach( (each4, m)=> {

                                  if(found) return
                                  if(each4.render === "true" && each4.serviceID === serviceID ){

                                    if((this.token === "BillExtract" || this.token === "GoodsAndServicesEntries" ) && (each4.serviceKey === "Item ID" || each4.serviceKey === "ForPrdFrom" || each4.serviceKey === "ForPrdTo") ) {

                                      this.costAllocationMethod( row, each4.serviceKey, val )
                                    }

                                      each4[key] = val
                                      console.log('super2')
      
                                      this.props.handleTreeChange(VR2_Form_Json)
                                      // this.props.rerender()
                                      found = true
                                      if(each4.serviceKey === "Tax Percentage") this.onTaxPerChange(val, row)
                                      // if(each4.serviceKey === "PO/IP Item Ref" && key === "val") this.onPOIPItemRefChange(val, row)

                                      return 
                                  }
                              })
                          }
                      }) 
                  }
              })
          }
      })
  }

  
  changeValByserviceID_or_Key = (serviceID_or_Key, matchBy, key, val, push) => {

    console.log(serviceID_or_Key, val)

    let VR2_Form_Json = this.props.VR2_Form_Json

    let found = false

    VR2_Form_Json.forEach( (each , i) => {

        if(found) return
        if(each.children && each.children.length){

            each.children.forEach( (each2, j) => {

                if(found) return
                if(each2.children && each2.children.length){

                    each2.children.forEach( (each3, k) => {

                        if(found) return

                        if(!each3.children && each3.render && each3.render === "true" && this.trim(each3[matchBy]) === this.trim(serviceID_or_Key) ){

                          
                          if(push){
                            console.log(each3)

                            let arr = each3[key]

                            arr.push(val)
                            console.log(arr)
                            // console.log(arr.push(val))
                            each3[key] = arr
                          } else{
                            each3[key] = val
                          }

                            console.log('super1')
                            console.log(each3)
                            
                            this.props.handleTreeChange(VR2_Form_Json)
                            found = true
                           
                            return 
                        }

                        else if(each3.children && each3.children.length){

                            each3.children.forEach( (each4, m)=> {

                                if(found) return
                                if(each4.render === "true" && this.trim(each4[matchBy]) === this.trim(serviceID_or_Key) ){

                                    each4[key] = val
                                    console.log('super2')
    
                                    this.props.handleTreeChange(VR2_Form_Json)
                                    // if( each4.serviceKey === "PO/IP Item Ref") found = false
                                    found = true
                                    return
                                }
                            })
                        }
                    }) 
                }
            })
        }
    })
}


  onPOIPItemRefChange = ( val, rowNO ) => {

    let Vendor_ID;
    let Item_ID;

    let VR2_Form_Json = this.props.VR2_Form_Json

    let found = false


    VR2_Form_Json.forEach( (each , i) => {

        if(found) return
        if(each.children && each.children.length){

            each.children.forEach( (each2, j) => {

                if(found) return
                if(each2.children && each2.children.length){

                    each2.children.forEach( (each3, k) => {

                        if(found) return
                        if(!each3.children && each3.render && each3.render === "true" && each3.serviceKey === "Vendor ID" ){

                            Vendor_ID = each3.val
                            if( Vendor_ID !== undefined && Item_ID !== undefined ) found = true
                            if(found) return 
                        }
                    }) 
                }

                if(each2.children && each2.children.length && each2.children[rowNO] && each2.children[rowNO].children){

                  each2.children[rowNO].children.forEach( (each4, m) => {

                        if(found) return
                        if(each4.render && each4.render === "true" && each4.serviceKey === "Item ID" ){

                          Item_ID = each4.val
                          if( Vendor_ID !== undefined && Item_ID !== undefined ) found = true
                          if(found) return 
                        }
                    })
                }
            })
        }
    })


    console.log(Vendor_ID, Item_ID)



    this.setState({
      billExtract_theme_save : {
        ItemID : Item_ID,
        VendorID : Vendor_ID,
        KeyWord : ''
      }
    })


  }

  onChange_billExtract_theme_save = (e) => {

    let key = e.target.id
    let val = e.target.value

    this.setState({
      ...this.state, 
      billExtract_theme_save : {
          ...this.state.billExtract_theme_save,
          [key]: val              
      } 
    }, 
    () => {
      console.log(this.state.billExtract_theme_save)
    })
  }

  save_billExtract_theme = (e) => {

    e.preventDefault()

    let obj = this.state.billExtract_theme_save

    postRequest('api/vr2/save_billExtract_theme', obj)
    .then(res => {

      if( res.res.status === "success"){

        this.setState({ billExtract_theme_save : false}, () => {
  
        })
        
      }

    })
    

  }



  onTaxPerChange = ( Tax_Percentage, rowNO) => {

    let Tax_Category;

    let VR2_Form_Json = this.props.VR2_Form_Json

    let found = false

    VR2_Form_Json.forEach( (each , i) => {

        if(found) return
        if(each.children && each.children.length){

            each.children.forEach( (each2, j) => {

                if(found) return
                if(each2.children && each2.children.length){

                    each2.children.forEach( (each3, k) => {

                        if(found) return

                        if(!each3.children && each3.render && each3.render === "true" && each3.serviceKey === "Tax Category" ){

                          Tax_Category = each3.val
                          if(found) return 
                        }
                    }) 
                }
            })
        }
    })

    let taxCode = Tax_Category + ' ' + String(Number(Tax_Percentage)) + '%'

    this.changeValBy_row( "Tax Code", "serviceKey", "val", taxCode, rowNO )
  }


  costAllocationMethod = ( rowNO, serviceKey, val ) => {

    console.log(rowNO, '"costAllocationMethod"')

    let obj = {
      ForPrdFrom : '',
      ForPrdTo: '',
      ItemID: ''
    }

    obj[serviceKey.replaceAll(/\s/g,'')] = val

    console.log(obj)

    let {
      ForPrdFrom,
      ForPrdTo,
      ItemID
    } = obj

    let VendorID = ''

    let _ID_KEY;
    if(this.token === "BillExtract") _ID_KEY = "Vendor ID"

    if(this.token === "GoodsAndServicesEntries") _ID_KEY = "Sender ID"

    let VR2_Form_Json = this.props.VR2_Form_Json

    let found = false

    VR2_Form_Json.forEach( (each , i) => {

        if(found) return
        if(each.children && each.children.length){

            each.children.forEach( (each2, j) => {

                if(found) return
                if(each2.children && each2.children.length){

                    each2.children.forEach( (each3, k) => {

                        if(found) return
                        if(!each3.children && each3.render && each3.render === "true" && each3.serviceKey === _ID_KEY ){

                            VendorID = each3.val
                            if( ForPrdFrom && ForPrdTo && ItemID && VendorID) found = true
                            if(found) return 
                        }
                    }) 
                }
                if(each2.children && each2.children.length && each2.children[rowNO] && each2.children[rowNO].children){

                  each2.children[rowNO].children.forEach( (each4, m)=> {

                        if(found) return
                        if(each4.render === "true" && (each4.serviceKey === "ForPrdFrom" || each4.serviceKey === "ForPrdTo" || each4.serviceKey === "Item ID") ){

                          if( each4.serviceKey === "ForPrdFrom" ){

                            if(!ForPrdFrom) ForPrdFrom = each4.val

                            if( ForPrdFrom && ForPrdTo && ItemID && VendorID) found = true
                            if(found) return 
                          }

                          if( each4.serviceKey === "ForPrdTo" ){

                            if(!ForPrdTo) ForPrdTo = each4.val

                            if( ForPrdFrom && ForPrdTo && ItemID && VendorID) found = true
                            if(found) return 
                          }

                          if( each4.serviceKey === "Item ID" ){

                            if(!ItemID) ItemID = each4.val

                            if( ForPrdFrom && ForPrdTo && ItemID && VendorID) found = true
                            if(found) return 
                          }
                            
                        }
                    })
                }
            }) 
        }
    })

    console.log(ForPrdFrom , ForPrdTo , ItemID, VendorID)

    if(ForPrdFrom && ForPrdTo && ItemID && VendorID){

      let input = {
        ForPrdFrom : moment(ForPrdFrom, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        ForPrdTo : moment(ForPrdTo, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        ItemID,
        VendorID,
        ORG_ID_CONST: this.props.ORG_ID_CONST
      }

      postRequest('api/vr2apicall/getcostAllocationMethods', input)
      .then( res => {

        if( res.res.status === "success"){
    
          let DATA = JSON.parse(res.res.data)
          // let DATA = JSON.parse("[{\"costallocationmethod\":\"USERCNT-ECOM#USERCNT-ECOM&LMS\"}]")
          console.log(DATA)

          if(DATA.length){

            let options = DATA.flatMap(obj =>
              obj.costallocationmethod.split('#')
            );
            
            console.log(options)
  
            let ok = <Popup
            content={
                <div className='row m-4'>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Cost Allocation Method</th>
                        </tr>
                        </thead>
                        <tbody>
                            { options.map( (each, index) => {
                                return(
                                    <tr key={index} onClick={() => {
  
                                      this.changeValBy_row( "CostAllocationMethod", "serviceKey", "val", each, rowNO )
  
                                      this.setState({pop_up_1 : null})
                                    }}>
                                      <td>{ each }</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </Table>
                </div>
            }
    
            handleClose={() => this.setState({ pop_up_1 : null}) } />
  
  
            this.setState({pop_up_1 : ok})
            
          }
          
        }

      })
      postRequest('api/vr2apicall/getSectionName', input)
      .then( res => {

        if( res.res.status === "success"){
    
          let DATA = JSON.parse(res.res.data)
          // let DATA = JSON.parse("[{\"costallocationmethod\":\"USERCNT-ECOM#USERCNT-ECOM&LMS\"}]")
          console.log(DATA)

          if(DATA.length){

            let options = DATA.flatMap(obj =>
              obj.SectionName.split('#')
            );
            
            console.log(options)
  
            let ok = <Popup
            content={
                <div className='row m-4'>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Section Name</th>
                        </tr>
                        </thead>
                        <tbody>
                            { options.map( (each, index) => {
                                return(
                                    <tr key={index} onClick={() => {
  
                                      this.changeValBy_row( "Section Name", "serviceKey", "val", each, rowNO )
  
                                      this.setState({pop_up_2 : null})
                                    }}>
                                      <td>{ each }</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </Table>
                </div>
            }
    
            handleClose={() => this.setState({ pop_up_2 : null}) } />
  
  
            this.setState({pop_up_2 : ok})
            
          }
          
        }

      })

    }
    
  }

  
  changeValBy_row = (serviceID_or_Key, matchBy, key, val, rowNO) => {

    console.log(rowNO, 'rowNO')

      console.log(serviceID_or_Key, val)

      let VR2_Form_Json = this.props.VR2_Form_Json

      let found = false

      VR2_Form_Json.forEach( (each , i) => {

          if(found) return
          if(each.children && each.children.length){

              each.children.forEach( (each2, j) => {

                  if(found) return
                  if(each2.children && each2.children.length && each2.children[rowNO] && each2.children[rowNO].children){

                    each2.children[rowNO].children.forEach( (each4, m)=> {

                          if(found) return
                          if(each4.render === "true" && this.trim(each4[matchBy]) === this.trim(serviceID_or_Key) ){

                            if((this.token === "BillExtract" || this.token === "GoodsAndServicesEntries" ) && (each4.serviceKey === "Item ID" || each4.serviceKey === "ForPrdFrom" || each4.serviceKey === "ForPrdTo") ) {

                              this.costAllocationMethod( rowNO, each4.serviceKey, val )
                            }

                              each4[key] = val
                              console.log('super243')

                              this.props.handleTreeChange(VR2_Form_Json)
                              found = true
                              if(each4.serviceKey === "Tax Percentage"  ) this.onTaxPerChange(val, rowNO)

                              return 
                          }
                      })
                  }
              }) 
          }
      })
  }


  // onDropDownValueChange = (ev, valEmpty, no_onBlur) => {

  //   let serviceID = ev.target.id
  //   let serviceKey = ev.target.name
  //   let val = ev.target.value

  //   // changeValByserviceID(this.props.VR2_Form_Json, this.props.formArray, serviceID, "val", val, serviceKey)

  //   this.changeValByserviceID( serviceID, "val", val)

    
  //   let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss')
  //   console.log(DateTime)

  //   let logs = this.props.changeLogs
  //   let vals = this.props.storeVals
    
  //   logs[serviceID] = {
  //     serviceKey : serviceKey,
  //     serviceID : serviceID,
  //     value : val,
  //     DateTime,
  //     user: this.props.userData.USR_Email
  //   }
    
  //   vals[serviceID] = val
  //   // let formArray_obj={}
    
  //   // this.props.formArray.forEach( each => {
  //     //     formArray_obj[each.serviceKey] = each.val ? each.val : ""
  //     // })
      
  //     // changeDependencyValues(serviceID, this.props.formArray, formArray_obj, this.props.VR2_Form_Json, valEmpty && valEmpty === "false" ? false : true );
      
  //   this.props.updateChangeLogs(logs)
  //   this.props.updateStoreVals(vals)

  //   this.props.rerender()
  //   this.setState({ [serviceKey]: val, logs },

  //       () => 
  //       no_onBlur ? 
  //       null
  //       : 
  //       this.onBlur({
  //           target: {
  //               id: serviceID,
  //               value: val
  //           }
  //       })

  //   );
  // }

  onDropDownValueChange = (ev, valEmpty, no_onBlur) => {

    let serviceID = ev.target.id
    let serviceKey = ev.target.name
    let val = ev.target.value

    let serviceIDLastSegment = serviceID.split('#').pop();
    console.log(serviceIDLastSegment)
   
    this.setState({
      lastServiceIDSegment: serviceIDLastSegment,
  },()=>{ console.log(`billingsummary#totalvalue#${this.state.lastServiceIDSegment}`,"billingsummary last segment")});
//mbp
    if(val === "DYNAMIC"){     
      console.log("dropdown chandge value ", val)
      console.log(this.state.costAllocationMethodOptions)
      this.setState({
        isPopupOpen:true,
        popupServiceId:serviceID,
        popupserviceKey:serviceKey
      })


    }

    // changeValByserviceID(this.props.VR2_Form_Json, this.props.formArray, serviceID, "val", val, serviceKey)

    this.changeValByserviceID( serviceID, "val", val)

    
    let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss')
    console.log(DateTime)

    let logs = this.props.changeLogs
    let vals = this.props.storeVals
    
    logs[serviceID] = {
      serviceKey : serviceKey,
      serviceID : serviceID,
      value : val,
      DateTime,
      user: this.props.userData.USR_Email
    }
    
    vals[serviceID] = val
    // let formArray_obj={}
    
    // this.props.formArray.forEach( each => {
      //     formArray_obj[each.serviceKey] = each.val ? each.val : ""
      // })
      
      // changeDependencyValues(serviceID, this.props.formArray, formArray_obj, this.props.VR2_Form_Json, valEmpty && valEmpty === "false" ? false : true );
      
    this.props.updateChangeLogs(logs)
    this.props.updateStoreVals(vals)

    this.props.rerender()
    this.setState({ [serviceKey]: val, logs },

        () => 
        no_onBlur ? 
        null
        : 
        this.onBlur({
            target: {
                id: serviceID,
                value: val
            }
        })

    );
  }

  onDropDownDynamicValueChange = (no_onBlur) => {

    let serviceID = this.state.popupServiceId
    let serviceKey = this.state.popupserviceKey
    let val = this.state.dynamicValue
    console.log('from the ondropdowndynamicvaluechange',val)

    // changeValByserviceID(this.props.VR2_Form_Json, this.props.formArray, serviceID, "val", val, serviceKey)

    this.changeValByserviceID( serviceID, "val", val)

    
    let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss')
    console.log(DateTime)

    let logs = this.props.changeLogs
    let vals = this.props.storeVals
    
    logs[serviceID] = {
      serviceKey : serviceKey,
      serviceID : serviceID,
      value : val,
      DateTime,
      user: this.props.userData.USR_Email
    }
    
    vals[serviceID] = val
    // let formArray_obj={}
    
    // this.props.formArray.forEach( each => {
      //     formArray_obj[each.serviceKey] = each.val ? each.val : ""
      // })
      
      // changeDependencyValues(serviceID, this.props.formArray, formArray_obj, this.props.VR2_Form_Json, valEmpty && valEmpty === "false" ? false : true );
      
    this.props.updateChangeLogs(logs)
    this.props.updateStoreVals(vals)

    this.props.rerender()
    this.setState({ [serviceKey]: val, logs },

        () => 
        no_onBlur ? 
        null
        : 
        this.onBlur({
            target: {
                id: serviceID,
                value: val
            }
        })

    );
  }

  getLobOPtions = ()=>{
    let request = {
    company:this.props.ORG_ID_CONST
    }
    console.log("company",request)
    postRequest(`api/edureka/getDistinctLOBs`, request).then(res=>{
     
      console.log(JSON.parse(res.res.data))
      const responseLOB = JSON.parse(res.res.data)

      const distinctLOBs = responseLOB.map(item => item.lob)
      this.setState({
          Lob:distinctLOBs
   
      }, () => {
        console.log("Updated Lob state: ", this.state.Lob);
      })
    })
  }


  
  if2hashRemoveLast = (myString) => {

    if (myString.includes("#") && (myString.match(/#/g) || []).length === 2) {    // only containd 2 #

        let str = myString.split("#")

        str.splice(-1)

        return str.join("#")
    }
    else {
        return myString
    }
  }



  onBlur = (e) => {

    let val = e.target.value
    let id = e.target.id

    console.log(val)
    console.log(id)

    if( val ){
        let API = _.filter(this.props.dependency_json, item => item.type === "apiCall" && item.parentId === this.if2hashRemoveLast(id) );

        console.log(API)

        if(API.length && API.length === 1){

            let obj = {
                LOGS : this.props.changeLogs,
                TREE_JSON : this.props.VR2_Form_Json
            }

            console.log(obj)


            this.setState({ showSpinner : true}, 
            () => {

                postRequest(`api/vr2/EditJson?module=${localStorage.getItem("module")}&screenName=${this.token}`, obj)
                .then( res => {
                    if( res.res.status === "success"){
                        console.log(res.res.data)
        
                        this.props.handleTreeChange(res.res.data)
                    }

                    this.setState({ showSpinner : false})
                })
            })
        }
    }
  }


  date_DD_MM_YYYY_TO_YYYY_MM_DD = (json) => {

    let ok = JSON.parse(JSON.stringify(json))
    // console.log(ok.val)
    let date = moment(ok.val, 'DD-MM-YYYY').format('YYYY-MM-DD') 
    // console.log(date)
    return date === 'Invalid date' ? ok.val : date
  }

  current_date = (childrenNode) => {

      let date = moment(new Date()).format("DD-MM-YYYY")
      let ev = {
          target : {
              id : childrenNode.serviceID,
              name : childrenNode.serviceKey,
              value : date
          }
      }

      this.myChangeHandler(ev)
  }

  yesterday_date = (childrenNode) => {

    let date = moment(new Date()).subtract(1, 'day').format("DD-MM-YYYY")
    let ev = {
        target : {
            id : childrenNode.serviceID,
            name : childrenNode.serviceKey,
            value : date
        }
    }

    this.myChangeHandler(ev)
  }

  generateUniqueNumber = () => {
    var timestamp = new Date().getTime();
    var uniqueNumber = timestamp.toString().substr(0, 10);

    console.log(uniqueNumber)
    return uniqueNumber;
}


removeSpecialCharacters_exceptDOT = (str) => {
    return str.replace(/[^\w\s.]/gi, '');      // only dot allowed
}

removeSpecialCharacters = (str) => {
    return str.replace(/[^\w\s]/gi, '');
}


onUpload = ( e, childrenNode ) => {

    
    let serviceKey = e.target.name;
    let serviceID = e.target.id;

    let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss')
    console.log(DateTime)

    let logs = this.props.changeLogs
    let vals = this.props.storeVals
    
    
    let file = e.target.files[0]  

    if(file && e.target.files && e.target.files.length){

        let fileName = this.generateUniqueNumber() + this.removeSpecialCharacters_exceptDOT(file.name)

        console.log(fileName)

        const Module = localStorage.getItem("module")
        const screenName = this.removeSpecialCharacters(this.token)
        const fieldName = this.removeSpecialCharacters(serviceKey)

        let s3key = `UploadedFiles/${Module}/${screenName}/${fieldName}/${fileName}`


        console.log(s3key)
            
       
        const formData = new FormData();
        formData.append("file", file);

        postRequest(`api/vr2/uploadPDF?module=${localStorage.getItem("module")}&screenName=${screenName}&fieldName=${fieldName}&fileName=${fileName}`, formData)
        .then( res => {

            if( res.res.status === "success"){

                this.changeValByserviceID( serviceID, "val", s3key)


                logs[serviceID] = {
                    serviceKey : serviceKey,
                    serviceID : serviceID,
                    value : s3key,
                    DateTime,
                    user: this.props.userData.USR_Email
                }
                vals[serviceID] = s3key
                
                this.props.updateChangeLogs(logs)
                this.props.updateStoreVals(vals)

                this.props.rerender("noQuit")
                this.setState({
                    [serviceID]: file
                }, () => this.onBlur({ 
                            target: {
                                id: serviceID,
                                value : file
                            }
                        })
                )
            }
        })
    }
  }


  openPDF = ( e, childrenNode) => {
      
      e.preventDefault();

      postRequest(`api/vr2/getPDF_file`, {key : childrenNode.val} )
      .then( res => {

          if( res.res.status === "success"){

              // if(childrenNode.subtype === 'upload_show') this.props.update_one_side_PDF_Key(res.res.data)

              // else 
              this.setState({ showPopUP : childrenNode.serviceID, popData : res.res.data })
          }
      })
  }



  ifReject = (childrenNode, children_Node) => {

    console.log(children_Node)

    let reason = children_Node.find( each => each.serviceKey === "Reason")
    
    if(reason){

      this.setState({ask_reason : reason})
    }

  }


  ifNotReject = (childrenNode, children_Node) => {


    let reason = children_Node.find( each => each.serviceKey === "Reason")
    
    if(reason && reason.val){

      let ev = {
        target : {
            id : reason.serviceID,
            name : reason.serviceKey,
            value : ""
        }
      }

    this.myChangeHandler(ev)

    }

  }

  trim = (str) => {
    return str.replace(/\s+/g, '').toLowerCase();
  }


  dynamic_option_fn = async ( childrenNode ) => {

      console.log('calling dynamic_option_fn =>' + childrenNode.serviceID )

      let hit = `${childrenNode.dynamic_option.api_Directory}?query_Name=${childrenNode.dynamic_option.query_Name}`

      console.log('hitting', hit)

      let array;

      if(childrenNode.dynamic_option.dynamic_query){

        array = []

        await childrenNode.dynamic_option.dynamic_query.forEach( async each => {

            if(each.emailid){
              let obj = {
                  replaceStringWith : each.replaceStringWith,
                  value : this.props.userData.USR_Email
              }
              array.push(obj)
            }
            else if(each.getValueFrom === "Organization ID"){

                let obj = {
                    replaceStringWith : each.replaceStringWith,
                    value : this.props.ORG_ID_CONST
                }
                array.push(obj)
            } 
            else {

                await this.Give_Val_By_ServiceKey(each.getValueFrom)
                let obj = {
                    replaceStringWith : each.replaceStringWith,
                    value : this.state.Give_Val_By_ServiceKey[each.getValueFrom]
                }
                array.push(obj)
            }
        })

        console.log(array, childrenNode.serviceID + 'llllllllllllllllllllllllllllll')

      }

      postRequest(hit, array ? {info : array} : null)
      .then( res => {

          if( res.res.status === "success"){

              let DATA = res.res.data && res.res.data.length ? JSON.parse(res.res.data) : []

              console.log(DATA)

              if(DATA.length){

                if(childrenNode.dynamic_option.what_to_store){

                  let athenaStorage = this.props.athenaStorage

                  athenaStorage[childrenNode.dynamic_option.what_to_store] = DATA

                  this.props.updateAthenaStorage(athenaStorage)
                }

                // if(!childrenNode.options || !childrenNode.options.length){

                  let matchKey = Object.keys(DATA[0]).find( each => this.trim(each) === this.trim(childrenNode.serviceKey) )
                  
                  console.log(matchKey, 'matchKey')

                  if(matchKey) {

                      let newSet = [...new Set(
                          DATA.map( each => each[ matchKey ] )
                      )]

                      let options = []

                      newSet.forEach(one => {
                          options.push({
                              label: one,
                              value: one
                          })
                      })

                      console.log(options)

                      this.changeValByserviceID( childrenNode.serviceID, "options", options)
                      this.props.rerender()
                  }
                // }

              }
              else {
                this.changeValByserviceID( childrenNode.serviceID, "options", [])
                this.props.rerender()
              }
          }
      })
  }

  removeSpecialChars = (str) => {
    return str.replace(/[^\w\s]/gi, '');
  }

  onChange_operation = (childrenNode, val, rowNO) => {

    let get = childrenNode.serviceID.split('#')

      get.splice(1, 1);

      get = get.join('#')

    let ARRAY = this.props.athenaStorage[childrenNode.onChange_operation.get_From] //gv

    if(this.props.athenaStorage[get]){

      ARRAY = this.props.athenaStorage[get]
    }

    let matchKey = Object.keys(ARRAY[0]).find( each => this.trim(each) === this.trim(childrenNode.serviceKey) )
                
    console.log(matchKey, 'matchKey')

    if(matchKey) {
    
        let LINES = ARRAY.filter( each => each[matchKey] === val )
    
        console.log(LINES)


        if(childrenNode.onChange_operation.operation && childrenNode.onChange_operation.operation === 'show_next_dropdown' ){

            let child_Name = childrenNode.onChange_operation.child_Name

            console.log(child_Name)

            let OPTIONS = []

            if(LINES.length){

                let newSet = [...new Set(
                                LINES.map( each => each[ child_Name ] )
                            )] 

                
                newSet.forEach(each => {
                    OPTIONS.push({
                        label: each,
                        value: each
                    })
                })

                console.log(OPTIONS)

                this.changeValBy_row( child_Name, "serviceKey", "options", OPTIONS, rowNO )
                this.props.rerender()
            }
            else{

                this.changeValBy_row( child_Name, "serviceKey", "options", OPTIONS, rowNO )
                this.props.rerender()
            }
            

        }

        else {
            

            if( LINES.length === 1 ){

                let LINE = LINES[0]

                Object.keys(LINE).map( one => {

                    if(one !== matchKey ){

                        let willStore_val = LINE[one]

                        console.log( one, "serviceKey", "val", willStore_val, rowNO )
                        this.changeValBy_row( one, "serviceKey", "val", willStore_val, rowNO )
                        this.props.rerender("noQuit")

                    }
                })
            }
        }
    }
  }

  functionToCall = () => {
    // Implement the function logic here
    console.log('Function called from ChildComponent1Child');

    let found;

    let VR2_Form_Json = this.props.VR2_Form_Json

    VR2_Form_Json.forEach( (each , i) => {
    
      if(found) return;
      if(each.children && each.children.length){

        each.children.forEach( (each2, j) => {

          if(found) return;
          if(each2.children && each2.children.length){

            if(this.token === "BillExtract" && each2.title === "Billing Summary"){

              each2.children.forEach( (each3, k) => {

                if(found) return;

                if(each3.children && each3.children.length){

                  each3.children.forEach( (each4, m) => {

                    if(found) return
                    if(each4.render === "true" && each4.serviceKey === "PO/IP Item Ref" && each4.type === "select" ){

                      this.filterPOIPItemRef(each4.serviceID, k, each4)
                    }
                  })
                }
              }) 
            }
            if(this.token === "GoodsAndServicesEntries" && each2.title === "Goods & Services Entries Item"){

              each2.children.forEach( (each3, k) => {

                if(found) return;

                if(each3.children && each3.children.length){

                  each3.children.forEach( (each4, m) => {

                    if(found) return
                    if(each4.render === "true" && each4.serviceKey === "Item Name" && each4.type === "select" ){

                      this.filterPOIPItemRef(each4.serviceID, k, each4)
                    }
                  })
                }
              }) 
            }
          }
        })
      }
    })   

  };

  handleClose =()=>{
    this.setState({isPopupOpen:false})
  }



  PO_IP_Item_Ref_Pattern_Match = () => {
        
    let Description = []
  
    let found;

    let VR2_Form_Json = this.props.VR2_Form_Json

    VR2_Form_Json.forEach( (each , i) => {
    
      if(found) return;
      if(each.children && each.children.length){

        each.children.forEach( (each2, j) => {

            if(found) return;
            if(each2.children && each2.children.length){

              if(this.token === "BillExtract" && each2.title === "Billing Summary"){

                each2.children.forEach( (each3, k) => {

                    if(found) return;

                    if(each3.children && each3.children.length){

                      each3.children.forEach( (each4, m) => {

                          if(found) return
                          if(each4.render === "true" && each4.serviceKey === "Item Name"){

                            Description.push(each4.val)
                          }
                      })
                    }
                }) 
              }
            }
        })
      }
    })
    
    if(Description.length){

      console.log(Description, `"Alannnnnnnnnnnnnnn"`)

      let input = {
        Description,
        ORG_ID_CONST : this.props.ORG_ID_CONST
      }

      postRequest('api/vr2/PO_IP_Item_Ref_Pattern_Match', input)
      .then( res => {

        // {
        //   "success": "Pattern applied successfully!",
        //   "result": [{
        //     "Description": "ROYAL NATURAL FACIAL TISSUE",
        //     "PatternGroup": "GT-BE1",
        //     "PatternID": "12",
        //     "Item Name": "ROYAL NATURAL FACIAL TISSUE",
        //     "Item ID": "IM0000000001"
        //   }]
        // }
        let reslt = res.res.data
        console.log(reslt)


        VR2_Form_Json.forEach( (each , i) => {
    
          if(found) return;
          if(each.children && each.children.length){
    
            each.children.forEach( (each2, j) => {
    
                if(found) return;
                if(each2.children && each2.children.length){
    
                  if(this.token === "BillExtract" && each2.title === "Billing Summary"){
    
                    each2.children.forEach( (each3, rowNO) => {
    
                        if(found) return;
    
                        if(each3.children && each3.children.length){
    
                          each3.children.forEach( (each4, m) => {
    
                              if(found) return
                              if(each4.render === "true" && each4.serviceKey === "Item Name"){

                                reslt.result.forEach( per => {
                                  
                                  if(per["Item Name"] && per["Item ID"] && each4.val === per["Item Name"]){

                                    each3.children.forEach( (each5, g) => {

                                      if(each5.render === "true" && each5.serviceKey === "PO/IP Item Ref"){

                                        each5.options.forEach( async one_opt => {

                                          if( one_opt.value.includes(per["Item ID"]) ){

                                            console.log(one_opt.value, '1111111111')

                                            each5.val = one_opt.value

                                            //
                                            // if (each5.onChange_operation) {
                                            //   this.onChange_operation(each5, one_opt.value, rowNO);
                                            // }
                                            // this.onDropDownValueChange({
                                            //   target: {
                                            //       name: each5.serviceKey,
                                            //       id: each5.serviceID,
                                            //       value : one_opt.value
                                            //   }
                                            // })
                                            //
                                            
                                          }
                                          
                                        })
                                        
                                      }
                                      
                                    })

                                  }
                                  
                                })
                                
                              }
                          })
                        }
                    }) 
                  }
                }
            })
          }
        })

        this.props.handleTreeChange(VR2_Form_Json)
        this.props.rerender()

      })

    }

  }


  filterPOIPItemRef = (serviceID, rowNO, childrenNode) => {

    // const serviceID = e.target.id

    console.log("filterPOIPItemRef")

    let ID;
    let Number;
    let IDonChange;

    let ID_Key;
    let Number_Key;
    let IDonChange_Key;

    if(this.token === "BillExtract"){

      ID_Key = "Vendor ID"
      Number_Key = "PO/IP Number"
      IDonChange_Key = "VendorIDonChange"      
    }

    if(this.token === "GoodsAndServicesEntries"){

      ID_Key = "Sender ID"
      Number_Key = "Order Number"
      IDonChange_Key = "SenderIDonChange" 
    }

    let VR2_Form_Json = this.props.VR2_Form_Json

    let found = false

    VR2_Form_Json.forEach( (each , i) => {

        if(found) return
        if(each.children && each.children.length){

            each.children.forEach( (each2, j) => {

                if(found) return
                if(each2.children && each2.children.length){

                    each2.children.forEach( (each3, k) => {

                        if(found) return

                        if(!each3.children && each3.render && each3.render === "true" && 
                        (each3.serviceKey === ID_Key || each3.serviceKey === Number_Key ) ){

                            if(each3.serviceKey === ID_Key){
                                ID = each3.val
                                IDonChange = each3[IDonChange_Key] ? each3[IDonChange_Key] : null

                                console.log(each3)

                                if( ID && Number ) found = true
                            }
                            else if(each3.serviceKey === Number_Key){
                                Number = each3.val

                                console.log(each3)

                                if( ID && Number ) found = true
                            }                           
                           
                            if(found) return 
                        }
                    }) 
                }
                if(this.token === "BillExtract"){

                  if(each2.title === "Billing Summary"){
                    if(each2.children && each2.children.length && each2.children[rowNO] && each2.children[rowNO].children){
  
                      each2.children[rowNO].children.forEach( (each4, m)=> {

                        if(found) return

                        if(!each4.children && each4.render && each4.render === "true" && 
                        (each4.serviceKey === ID_Key || each4.serviceKey === Number_Key ) ){

                            if(each4.serviceKey === Number_Key){
                                Number = each4.val

                                console.log(each4)

                                if( ID && Number ) found = true
                            }                           
                           
                            if(found) return 
                        }

                      })
                    }
                  }
                }
            })
        }
    })

    console.log(ID , Number, IDonChange)


    // if( (!IDonChange || (IDonChange && IDonChange === "true") )  && ID.length && !Number.length){
    if( ID !== undefined && Number !== undefined && IDonChange !== undefined ){

      // if( (!IDonChange || (IDonChange && !IDonChange.includes(serviceID)) )  && ID.length && !Number.length){
  
      //   let ok = <Popup
      //             handleClose={() => this.setState({filterPOIPItemRef: null})}
      //             content={
      //             <>
    
      //               <div className="mt-3 mb-3" align="center">
      //                 <div>
      //                   Do you want to filter the options based on {ID_Key} ?
      //                 </div>
      //               </div>
    
      //               <div align="center">
      //                 <button onClick={() => this.filterPOIPItemRef_yes("")} className="btn btn-secondary btn-sm">No</button>
      //                 <button onClick={() => this.filterPOIPItemRef_yes(ID)} className="btn btn-success btn-sm ml-1">Yes</button>
      //               </div>
    
      //             </>
      //             }
      //           />
    
                
      //   this.setState({filterPOIPItemRef : ok, ROW_NUM : rowNO})
      //   this.changeValByserviceID_or_Key( ID_Key, "serviceKey", IDonChange_Key, serviceID, "push")
  
      // }

      if( ID.length && !Number.length){

        this.filterPOIPItemRef_yes(ID, rowNO, childrenNode)
      }

    }
  }



  filterPOIPItemRef_yes = (ID, rowNO, childrenNode) => {

    let POIPItemRef;
    // let rowNO = this.state.ROW_NUM

    let fiter_options_of;
    let ID_Key;

    if(this.token === "BillExtract"){
      
      fiter_options_of = "PO/IP Item Ref"
      ID_Key = "Vendor ID"
    } 
    if(this.token === "GoodsAndServicesEntries"){

      fiter_options_of = "Item Name"
      ID_Key = "Sender ID"
    } 

    let found;

    let VR2_Form_Json = this.props.VR2_Form_Json

    VR2_Form_Json.forEach( (each , i) => {
    
        if(found) return;
        if(each.children && each.children.length){

            each.children.forEach( (each2, j) => {

                if(found) return;
                if(each2.children && each2.children.length){

                    each2.children.forEach( (each3, k) => {

                        if(found) return;

                        if(each3.children && each3.children.length){

                            each3.children.forEach( (each4, m)=> {

                                if(found) return
                                if(each4.render === "true" && each4.serviceKey === fiter_options_of && each4.type === "select" ){

                                  POIPItemRef = each4

                                  console.log(POIPItemRef, fiter_options_of)

                                  if(ID && POIPItemRef) found = true

                                  if(found) return; 
                                }
                            })
                        }
                    }) 
                }
            })
        }
    })

    let athenaStorage = this.props.athenaStorage

    if((ID && ID.length) && POIPItemRef && athenaStorage[POIPItemRef.dynamic_option.what_to_store] && athenaStorage[POIPItemRef.dynamic_option.what_to_store].length) {
      
      let OPTS = athenaStorage[POIPItemRef.dynamic_option.what_to_store]

      console.log(OPTS)

      let newOPTS = OPTS.filter( each => {

        // some times ID may be more than one joined with ", " eg..  "VM0000000197, VM0000000079"
        
        let V_ID = each[ID_Key] ? each[ID_Key] : ""

        // console.log(ID_Key, V_ID)

        if(V_ID.includes(', ')){
            V_ID = V_ID.split(', ')
        }
        else if(V_ID.includes(',')){
            V_ID = V_ID.split(',')
        }

        if(typeof V_ID === 'object'){
          return V_ID.includes(ID)
        }
        else{
          return V_ID && V_ID === ID 
        }
      })

      console.log(newOPTS)

      let newSet = [...new Set(
        newOPTS.map( each => each[ fiter_options_of ] )
      )]

      let options = []

      newSet.forEach(one => {
          options.push({
              label: one,
              value: one
          })
      })

      console.log(options)


        if(options.length === 1){
          // if option is one fill automatically
          if(this.token === "BillExtract"){

            this.changeValBy_sec_row( fiter_options_of, "serviceKey", "options", options, "Billing Summary", rowNO)
          } 
          if(this.token === "GoodsAndServicesEntries"){
            
            this.changeValByserviceID_or_Key( fiter_options_of, "serviceKey", "options", options )
          }

          if(!childrenNode.val){

            if (childrenNode.onChange_operation) {
              this.onChange_operation(childrenNode, options[0].value, rowNO);
            }
            this.onDropDownValueChange({
              target: {
                  name: childrenNode.serviceKey,
                  id: childrenNode.serviceID,
                  value : options[0].value
              }
            })
          }


          this.props.rerender()

          this.setState({filterPOIPItemRef: null, ROW_NUM: null})

        }
        else if(options.length){

          if(this.token === "BillExtract"){

              // if(!this.PO_IP_Item_Ref_Pattern_Match_Called){

              //     this.PO_IP_Item_Ref_Pattern_Match_Called = true
  
              //     this.PO_IP_Item_Ref_Pattern_Match()
              // }

      
            this.changeValBy_sec_row( fiter_options_of, "serviceKey", "options", options, "Billing Summary", rowNO)
          } 
          if(this.token === "GoodsAndServicesEntries"){
            
            this.changeValByserviceID_or_Key( fiter_options_of, "serviceKey", "options", options )
          } 


          this.props.rerender()

          this.setState({filterPOIPItemRef: null, ROW_NUM: null})

        }
        
        else {

          // let ok = <Popup
          //       // handleClose={() => this.setState({filterPOIPItemRef: null})}
          //       noClose={true}
          //       content={
          //       <>
  
          //         <div className="mt-3 mb-3" align="center">
          //           <div>
          //             There is no option found based on this "{ID}" ID. Click "Yes" to show all the options
          //           </div>
          //         </div>
  
          //         <div align="center">
          //           <button onClick={() => this.filterPOIPItemRef_All(POIPItemRef, fiter_options_of)} className="btn btn-success btn-sm ml-1">Yes</button>
          //         </div>
  
          //       </>
  
          //       }
          //     />
  
              
          // this.setState({filterPOIPItemRef : ok})

          this.filterPOIPItemRef_All(POIPItemRef, fiter_options_of)
        }

    }

    else if(!ID.length && POIPItemRef){

      this.filterPOIPItemRef_All(POIPItemRef, fiter_options_of)

    }
      
  }    



  filterPOIPItemRef_All = (POIPItemRef, fiter_options_of) => {

      console.log(POIPItemRef, fiter_options_of)

      let rowNO = this.state.ROW_NUM

      let athenaStorage = this.props.athenaStorage
      
      let OPTS = athenaStorage[POIPItemRef.dynamic_option.what_to_store]

      console.log(OPTS)

      let newSet = [...new Set(
        OPTS.map( each => each[ fiter_options_of ] )
      )]

      let options = []

      newSet.forEach(one => {
          options.push({
              label: one,
              value: one
          })
      })

      console.log(options)

      // this.changeValByserviceID_or_Key( fiter_options_of, "serviceKey", "options", options)
      this.changeValBy_sec_row( fiter_options_of, "serviceKey", "options", options, "Billing Summary", rowNO)
      this.props.rerender()

      this.setState({filterPOIPItemRef: null, ROW_NUM: null})

  }

  store_all_vals = () => {

    let VALS = this.props.storeVals

    this.props.VR2_Form_Json.forEach( (each , i) => {

        if(each.children && each.children.length){

            each.children.forEach( (each2, j) => {

                if(each2.children && each2.children.length){

                    each2.children.forEach( (each3, k) => {

                        if(!each3.children && each3.render === "true"){

                            VALS[each3.serviceID] = each3.val
                        }

                        if(each3.children && each3.children.length){

                            each3.children.forEach( (each4, m)=> {

                                if(each4.render === "true"){

                                    VALS[each4.serviceID] = each4.val
                                }
                            })
                        }
                    }) 
                }
            })
        }
    })

    this.props.updateStoreVals(VALS)
  }


  popup_select_fn = (childrenNode) => {

      this.store_all_vals()

      let SerIDS = childrenNode.type === "popup_select" ? ['vendorid', 'vendorgstnumber', 'gstnumber'] 
      : childrenNode.type === "popup_select_goods" ? ["Order Type", "Sender ID"] : []
      // : childrenNode.type === "popup_select_goods" ? ["POIP Type", "Vendor ID"] : []

      SerIDS = SerIDS.map(each => this.trim(each))

      let storeVals = this.props.storeVals

      let obj = {}

      Object.keys(storeVals).forEach( each => {

          let key = each.split('#')[1]

          if(SerIDS.includes(key)){

              obj[key] = storeVals[each]
          }
      })

      obj.ORG_ID_CONST = this.props.ORG_ID_CONST

      // billExtract_POIPNumber_popup
      let hit = `${childrenNode.api_info.api_Directory}`
    
      console.log('hitting', hit)

      postRequest(hit , obj)
      .then( res => {

          if( res.res.status === "success"){

              let DATA = res.res.data && res.res.data.length ? JSON.parse(res.res.data) : []

              if(DATA.length){

                  console.log(DATA)


                  if(this.token === "BillExtract"){

                      // Checks whether an element is even
                      const even = (element) => {

                          if( element["PO/IP Type"] === "GoodsReceipt" || element["PO/IP Type"] === "ServiceReceipt" ){

                              return element
                          }
                      }

                      const present = DATA.some(even)   // true or false

                      if(present){

                          DATA = DATA.map( each => {
      
                              if( each["PO/IP Type"] === "PurchaseOrder" || each["PO/IP Type"] === "InternalPlan" ){

                                  each.disabled = true
                              }

                              return each
                          })
                      }
                  }

                  console.log(DATA)


                  this.setState({
                      popup_table: {
                          search: "",
                          data: DATA,
                          filteredData: DATA,
                          header: Object.keys(DATA[0])
                      }
                  })
              }
          }
      })
  }

  on_popup_table_search = (e) => {

    const VAL = e.target.value

    const data = this.state.popup_table.data

    const filteredData = data.filter((row) =>
        Object.values(row).some(
            (value) =>
            value &&
            value.toString().replace(/\s/g, '').toLowerCase().includes(VAL.replace(/\s/g, '').toLowerCase())
        )
    );

    this.setState({
        ...this.state,
        popup_table: {
            ...this.state.popup_table,
            search: VAL,
            filteredData : filteredData
        }
    })
  }

  tableRow_click = (ROW) => {

    console.log(ROW)

    let ROWNUM = this.state.open_popup_select

    Object.keys(ROW).map( one => {

        let willStore_val = ROW[one]
        
        this.changeValBy_sec_row( one, "serviceKey", "val", willStore_val, "Billing Summary", ROWNUM)

    })

    this.setState({ open_popup_select: false, popup_table: false})
    this.props.rerender("noQuit")
  }


  changeValBy_sec_row = (serviceID_or_Key, matchBy, key, val, TITLE, rowNO) => {

    console.log(serviceID_or_Key, matchBy, key, val, TITLE, rowNO, 'rowNO')

      let VR2_Form_Json = this.props.VR2_Form_Json

      let found = false

      VR2_Form_Json.forEach( (each , i) => {

          if(found) return
          if(each.children && each.children.length){

              each.children.forEach( (each2, j) => {

                  if(found) return
                  if(each2.title === TITLE){
                    if(each2.children && each2.children.length && each2.children[rowNO] && each2.children[rowNO].children){
  
                      each2.children[rowNO].children.forEach( (each4, m)=> {
  
                            if(found) return
                            if(each4.render === "true" && this.trim(each4[matchBy]) === this.trim(serviceID_or_Key) ){
  
                              if((this.token === "BillExtract" || this.token === "GoodsAndServicesEntries" ) && (each4.serviceKey === "Item ID" || each4.serviceKey === "ForPrdFrom" || each4.serviceKey === "ForPrdTo") ) {
  
                                this.costAllocationMethod( rowNO, each4.serviceKey, val )
                              }
  
                                each4[key] = val
                                console.log(each4, 'super243')
  
                                this.props.handleTreeChange(VR2_Form_Json)
                                found = true
                                if(each4.serviceKey === "Tax Percentage"  ) this.onTaxPerChange(val, rowNO)

                                if(each4.serviceKey === "PO/IP Number"  ) this.PO_IPNumber(each4, rowNO)

                                return 
                            }
                        })
                    }
                  }
              }) 
          }
      })
  }


  PO_IPNumber = (obj, rowNO) => {

    if( obj.type === 'popup_select' ){

        let Input = {
            PO_IPNumber: obj.val,
            screen: 'BillExtract'
        }
        
        postRequest('api/vr2apicall/PO_IPNumber', Input )
        .then( res => {
            if( res.res.status === "success"){

                let DATA = JSON.parse(res.res.data)

                console.log(DATA)

                let athenaStorage = this.props.athenaStorage

                let toStore = obj.serviceID.split('#')

                toStore.splice(1, 1);

                toStore = toStore.join('#')
        
                athenaStorage[toStore] = DATA //gv

                this.props.updateAthenaStorage(athenaStorage)


                let newSet = [...new Set(
                    DATA.map( each => each[ "PO/IP Item Ref" ] )
                )]

                let options = []

                newSet.forEach(one => {
                    options.push({
                        label: one,
                        value: one
                    })
                })

                this.changeValBy_sec_row( "PO/IP Item Ref", "serviceKey", "options", options, "Billing Summary", rowNO)
                this.props.rerender()
            }
        })   
    }
  }


  handleDownload = (Data) => {

    let result=[]
  
    Data.forEach((each) => {
      let OBJ = {}
      each.children.forEach(obj => {
        if (obj.render === "true") {
            OBJ[obj.serviceKey] = obj.val
        }
      });
      result.push(OBJ)
    });

    this.setState({
        ...this.state,
        downloadData: result
    }, () => {
        this.downloadLink.link.click()
    })
  }


  render() {
    const {isPopupOpen} = this.state;
    const costAllocationMethodOptions = this.state

    const optionsList = this.state.optionsList
    const ask_reason = this.state.ask_reason
    const billExtract_theme_save = this.state.billExtract_theme_save

    const popup_table = this.state.popup_table
    const open_popup_select = this.state.open_popup_select

    if(this.props.tableData){
      return (
        <div>

          {this.state.filterPOIPItemRef && this.state.filterPOIPItemRef }
          {this.state.pop_up_1 && this.state.pop_up_1 }
          {this.state.pop_up_2 && this.state.pop_up_2 }

          {isPopupOpen && 
          <CostContributionPopup
          mode="dynamic"  //mbp
          lobs={this.state.Lob}
          tableName ={this.state.lastServiceIDSegment}
          totalValue={this.props.storeVals[`billingsummary#basevalue#${this.state.lastServiceIDSegment}`]}
          preFillValues= {this.state.preFillValues}
          // totalValue={this.props.storeVals[`billingsummary#totalvalue#0001`]}

          
          handleClose={this.handleClose}

          handleData = {(prop) => {
            console.log("data from popup",prop)
            let optiontype = "DYNAMIC";
            let value1 = prop.map((item)=>(`${item.lob}:${item.amountPercentage}%`))
            let value2 = `${optiontype}(${value1})`
            console.log(value1)
            this.setState({  preFillValues: prop.map((item) => `${item.lob}: ${item.amount}:${item.amountPercentage}`)
          })
            this.setState({dynamicValue:value2},
              ()=>{ 
                console.log(this.state.dynamicValue,"dynamic value");
              this. onDropDownDynamicValueChange();
            })
           

            this.handleClose()
           
          }}
          />}
          
          <Spinner open = { this.state.showSpinner } />
          { billExtract_theme_save && <Popup
              noClose={true}
              content={
              <>

                <div className="row">
                  <div className='col-md-3'>
                    <label htmlFor='KeyWord'>Key Word</label>
                  </div>
                  <div className='col-md-6'>
                    <input type='text' id='KeyWord' name='KeyWord' value={billExtract_theme_save.KeyWord} onChange={this.onChange_billExtract_theme_save}/>
                  </div>
                </div>

                <div className="row">
                  <div className='col-md-3'>
                    <label htmlFor='ItemID'>Item ID</label>
                  </div>
                  <div className='col-md-6'>
                    <input type='text' id='ItemID' name='ItemID' value={billExtract_theme_save.ItemID} onChange={this.onChange_billExtract_theme_save}/>
                  </div>
                </div>

                <div className="row">
                  <div className='col-md-3'>
                    <label htmlFor='VendorID'>Vendor ID (optional)</label>
                  </div>
                  <div className='col-md-6'>
                    <input type='text' id='VendorID' name='VendorID' value={billExtract_theme_save.VendorID} onChange={this.onChange_billExtract_theme_save}/>
                  </div>
                </div>

                <div align="center">
                  <button onClick={this.save_billExtract_theme} className="btn btn-primary"
                  disabled={ !billExtract_theme_save.KeyWord }
                  >Save</button>
                </div>

              </>

              }
            />
          }
  
          {
              this.props.tableData.children.map((treenode, index) => {
              if (treenode.type == 'section' && treenode.view == 'table') {
                // if (treenode.headerView == 'h') {
                //   return (
                //    <div key={index} className="table-container"> 
                //     <div className="section-title">{treenode.title}</div>
                //     <Table striped bordered hover>
                //       <thead>
                //         <tr>
                //           {
                //             treenode.children.map((childrenNode, index) => {
                //               if (childrenNode.type == 'group' && childrenNode.hasOwnProperty('children')) {
                //                 // return (<th key={index} colSpan={childrenNode.type == 'group' ? childrenNode.children.length : null} rowSpan={childrenNode.type == 'field' ? 2 : null}>{childrenNode.title}</th>)
                //                 return (
                //                 <th key={index} colSpan={childrenNode.children.length} rowSpan={null}>{childrenNode.title}</th>)
                //               } 
                //               else if (childrenNode.type == 'field') {
                //                 return (
                //                 <th key={index} rowSpan={2}>{childrenNode.title}</th>)
                //               }
    
                //             })
                //           }
                //         </tr>
                //         <tr>
                //           {
                //             treenode.children.map((childrenNode, index) => {
                //               if (childrenNode.type == 'group' && childrenNode.hasOwnProperty('children')) {
                //                 return childrenNode.children.map((grandchild, index) => {
                //                   return (
                //                     <th key={index}>{grandchild.title}</th>
                //                   )
                //                 })
                //               }
                //             })
                //           }
                //         </tr>
                //       </thead>
                //       <tbody>
                //         {
                //           this.state.tableRowData.map((data, index) => {
                //             return (
                //             <tr key={index}>
                //               {
                //                 treenode.children.map((childrenNode, index) => {
                //                   if (childrenNode.type == 'group' && childrenNode.hasOwnProperty('children')) {
                //                     return childrenNode.children.map((grandchild, index) => {
                //                       return (
                //                         <td key={index}>{data[grandchild.serviceKey]}</td>
                //                       )
                //                     })
                //                   } 
                //                   else if (childrenNode.type == 'field') {
                //                     return (
                //                       <td key={index}>{data[childrenNode.serviceKey]}</td>
                //                     )
                //                   }
                //                 })
                //               }
                //             </tr>)
                //           })
    
                //         }
                //       </tbody>
                //     </Table>
                //   </div>
                //   )
  
                // }
                // else {
                //   return (
                //   <div key={index} className="table-container"> 
                //     <div className="section-title">{treenode.title}</div>
                //     <Table striped bordered hover>
                //       <tbody>
                //       {treenode.children.map((childrenNode, index) => {
                //         if (childrenNode.type == 'group' && childrenNode.hasOwnProperty('children')) {
                //           return childrenNode.children.map((grandchild, index) => {
                            
                //             if (index == 0) {
                //               return (
                //               <tr key={index}>
                //                 <th rowSpan={childrenNode.type == 'group' ? childrenNode.children.length : null}>{childrenNode.title}</th>
                //                 <th> {grandchild.title}</th>
                //                 { this.state.tableRowData.map((data, index) => {
                //                   return (<td key={index}>{data[grandchild.serviceKey]}</td>) })
                //                 }
                //               </tr>)
                //             } 
                //             else {
                //               return (
                //               <tr key={index}>
                //                 <th> {grandchild.title} </th> 
                //                 { this.state.tableRowData.map((data, index) => {
                //                   return (<td key={index}>{data[grandchild.serviceKey]}</td>) })
                //                 }
                //               </tr>)
                //             }
                //           })
                //         } 
                //         else if (childrenNode.type == 'field') {
                //           return (
                //           <tr key={index}>
                //             <th colSpan={2}>{childrenNode.title}</th>
                //             { this.state.tableRowData.map((data, index) => {
                //               return (<td key={index}>{data[childrenNode.serviceKey]}</td>) })
                //             }
                //           </tr>)
                //         }
                //       })
                //       }
                //       </tbody>
                //     </Table>
                //   </div>)
                // }

                let newCode = ''
                // new code

                return(
                <>
                  <div key={index} className="table-container">
                    <div className="section-title">{treenode.title}&nbsp;
                        <button className='btn btn-primary' onClick={()=>this.handleDownload(treenode.children)}> <GetAppIcon /> </button>
                        <CSVLink data={this.state.downloadData} filename={`${treenode.title}.csv`} ref={(r)=>this.downloadLink = r} target='_blank'></CSVLink>
                    </div>
                    {this.token === 'CostAllocationMaster' && <div align='right'><button className='btn btn-success mb-1' onClick={(e)=> this.COPYTOBELOW(treenode)}>Copy Plan to Actual</button></div>}
                   
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                        {
                          treenode.children[0].children.map((childrenNode, index) => {
                            return (<>
                                {
                                  childrenNode.render === "true" &&
                                  <th key={index}
                                   style = { childrenNode.css ? childrenNode.css : childrenNode.serviceKey === "Line ID" ? {maxWidth: '3.5rem'} : {}}
                                  >{childrenNode.title}</th>
                                }
                            </>)
                          })
                        }
                        </tr>

                      </thead>
                      <tbody>
                          {
                            treenode.children.map((children__Node, index) => {
                              let ROWNUM = index
                              let children_Node = children__Node.children
                              return (
                                <>
                                <tr key={index}>

                                  {children_Node.map( (eachNode, i )=> {

                                    let childrenNode = eachNode

                                    if(childrenNode.render === "true") {
                                                                
                                     if(childrenNode.type === "select" && (childrenNode.options === undefined ) && !childrenNode.dynamic_option )
                                      {
                                          this.option_undef(childrenNode);
                                      }
                                      // if(childrenNode.type ==="select" && childrenNode.dynamic_option && childrenNode.dynamic_option.api_Directory && !this.state.dynamicOption.includes(String(i) + String(index)) )
                                      if(childrenNode.type ==="select" && childrenNode.dynamic_option && childrenNode.dynamic_option.api_Directory && !this.props.dynamicOption_Done.includes(childrenNode.serviceKey +'$'+ String(ROWNUM)) )
                                      {
                                          // this.setState(prevState => ({
                                          //     dynamicOption: [...prevState.dynamicOption, String(i) + String(index) ]
                                          // }), () => {
                                          //       this.dynamic_option_fn(childrenNode)
                                          //   })

                                          let dynamicOption_Done = this.props.dynamicOption_Done

                                          dynamicOption_Done.push(childrenNode.serviceKey +'$'+ String(ROWNUM) )

                                          this.props.updateDynamicOption_Done(dynamicOption_Done)
                                          this.dynamic_option_fn(childrenNode);

                                      }
                                      if(childrenNode.type === 'input' && childrenNode.subtype === 'date' && childrenNode.range_fn && childrenNode.range_fn === "current_date" && !childrenNode.val)
                                      {
                                          this.current_date(childrenNode)
                                      }
                                      if(childrenNode.type === 'input' && childrenNode.subtype === 'date' && childrenNode.range_fn && childrenNode.range_fn === "yesterday_date" && !childrenNode.val)
                                      {
                                          this.yesterday_date(childrenNode)                               
                                      }

                                      if (childrenNode.position === undefined || (childrenNode.position !== 'header' && childrenNode.position !== 'footer')) {
                                          return (

                                          <Tooltip title={ childrenNode.val }>
                                          <td
                                          style = { childrenNode.css ? childrenNode.css : childrenNode.serviceKey === "Line ID" ? { maxWidth: '3.5rem'} : {}}>

                                          {
                                              (childrenNode.type === 'input' && childrenNode.range_fn === "ticketing_status" ) ?
                                              <div>

                                                <button className='btn btn-danger mr-1' 
                                                style={{ opacity: childrenNode.editable === 'false' || childrenNode.val === 'Closed' ? '0.4' : '1'}} 
                                                disabled={
                                                  childrenNode.editable && childrenNode.editable === 'false' || childrenNode.val === 'Closed'
                                                }
                                                onClick={
                                                  () => {
                                                    let ev = {
                                                      target : {
                                                          id : childrenNode.serviceID,
                                                          name : childrenNode.serviceKey,
                                                          value : "Closed"
                                                      }
                                                  }

                                                  this.myChangeHandler(ev)
                                                  }
                                                }
                                                >Close</button>


                                                <button className='btn btn-success mr-1' 
                                                style={{ opacity: childrenNode.editable === 'false' || childrenNode.val === 'Open' ? '0.4' : '1'}} 
                                                disabled={
                                                  childrenNode.editable && childrenNode.editable === 'false' || childrenNode.val === 'Open'
                                                }
                                                onClick={
                                                  () => {
                                                    let ev = {
                                                      target : {
                                                          id : childrenNode.serviceID,
                                                          name : childrenNode.serviceKey,
                                                          value : "Open"
                                                      }
                                                  }

                                                  this.myChangeHandler(ev)
                                                  }
                                                }
                                                >Re-open</button>

                                                <input type={childrenNode.subtype} ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                                value={childrenNode.val ? childrenNode.val : ''} 
                                                disabled />

                                              </div>

                                              :

                                              (childrenNode.type === 'input' && childrenNode.range_fn === "activation_status" ) ?
                                              <div>

                                                <button className='btn btn-secondary mr-1' 
                                                style={{ opacity: childrenNode.editable === 'false' || childrenNode.val === 'Waiting For Approval' ? '0.4' : '1'}} 
                                                disabled={
                                                  childrenNode.editable && childrenNode.editable === 'false' || childrenNode.val === 'Waiting For Approval'
                                                }
                                                onClick={
                                                  () => {
                                                    let ev = {
                                                      target : {
                                                          id : childrenNode.serviceID,
                                                          name : childrenNode.serviceKey,
                                                          value : "Waiting For Approval"
                                                      }
                                                  }

                                                  this.ifNotReject(childrenNode, children_Node)
                                                  this.myChangeHandler(ev)
                                                  }
                                                }
                                                >Reset</button>

                                                <button className='btn btn-success mr-1' 
                                                style={{ opacity: childrenNode.editable === 'false' || childrenNode.val === 'Approved' || childrenNode.val === 'Rejected' ? '0.4' : '1'}} 
                                                disabled={
                                                  childrenNode.editable && childrenNode.editable === 'false' || childrenNode.val === 'Approved' || childrenNode.val === 'Rejected'
                                                }
                                                onClick={
                                                  () => {
                                                    let ev = {
                                                      target : {
                                                          id : childrenNode.serviceID,
                                                          name : childrenNode.serviceKey,
                                                          value : "Approved"
                                                      }
                                                  }

                                                  this.ifNotReject(childrenNode, children_Node)                                          
                                                  this.myChangeHandler(ev)
                                                  }
                                                }
                                                
                                                >Approve</button>

                                                <button className='btn btn-danger mr-1' 
                                                style={{ opacity: childrenNode.editable === 'false' || childrenNode.val === 'Approved' || childrenNode.val === 'Rejected' ? '0.4' : '1'}} 
                                                disabled={
                                                  childrenNode.editable && childrenNode.editable === 'false' || childrenNode.val === 'Approved' || childrenNode.val === 'Rejected'
                                                }
                                                onClick={
                                                  () => {
                                                    let ev = {
                                                      target : {
                                                          id : childrenNode.serviceID,
                                                          name : childrenNode.serviceKey,
                                                          value : "Rejected"
                                                      }
                                                  }

                                                  this.ifReject(childrenNode, children_Node)
                                                  this.myChangeHandler(ev)
                                                  }
                                                }
                                                >Reject</button>


                                                <input type={childrenNode.subtype} ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                                value={childrenNode.val ? childrenNode.val : ''} 
                                                disabled />

                                                {
                                                  childrenNode.val === 'Approved' ? 
                                                  <span style={{fontSize: '1.5rem',  color: 'green'}}>
                                                    <AiFillCheckSquare /> 
                                                  </span>
                                                  : childrenNode.val === 'Rejected' ?
                                                  <span style={{fontSize: '1.5rem',  color: 'red'}}>
                                                    <AiFillCloseSquare /> 
                                                  </span>
                                                  : childrenNode.val === 'Waiting For Approval' ?
                                                  <span style={{fontSize: '1.5rem',  color: 'orange'}}>
                                                    <AiFillClockCircle /> 
                                                  </span>
                                                  : 
                                                  <span style={{fontSize: '1.5rem', padding: '.8rem'}}>
                                                  </span>
                                                }


                                                { ask_reason && <Popup
                                                    noClose={true}
                                                    content={
                                                    <>

                                                      <div className="row">
                                                        <div className="col-lg-4">{ask_reason.title} for Rejection: <span style={{color: 'red'}}>{ask_reason.mandatory === "true" ? "*" : null}</span> </div>
                                                        <textarea type={ask_reason.subtype} className="col-lg-4" ref={ask_reason.serviceID} name={ask_reason.serviceKey} id={ask_reason.serviceID} 
                                                        value={ask_reason.val ? ask_reason.val : ''} 
                                                        onBlur={this.onBlur} 
                                                        onChange={this.myChangeHandler} />
                                                      </div>

                                                      <div align="center">
                                                        <button onClick={() => this.setState({ ask_reason : null})} className="btn btn-primary"
                                                        disabled={ !ask_reason.val }
                                                        >Enter</button>
                                                      </div>

                                                    </>

                                                    }
                                                  />
                                                }



                                              </div>

                                              :
                                              (childrenNode.children === undefined && childrenNode.type === 'input' && childrenNode.subtype !== 'null' && childrenNode.subtype !== 'date' && childrenNode.subtype !== 'radio' && childrenNode.subtype !== 'upload' ) ? 
                                                  <div >
                                                      <input type={childrenNode.subtype} ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                                      style={childrenNode.subtype === "number" ? {width: '8rem'} : childrenNode.css ? childrenNode.css : {}}
                                                      value={childrenNode.val ? childrenNode.val : ''} 
                                                      onBlur={this.onBlur} 
                                                      onChange={this.myChangeHandler} 
                                                      disabled={childrenNode.editable && childrenNode.editable === 'false' } />
                                                  </div> 
                                              :

                                              (childrenNode.children === undefined && childrenNode.type === 'input' && childrenNode.subtype === 'date' ) ? 
                                              
                                              <div>
                                                  <input type={childrenNode.subtype} ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID}
                                                  style = { childrenNode.css ? childrenNode.css : {} }
                                                  value={childrenNode.val ? this.date_DD_MM_YYYY_TO_YYYY_MM_DD(childrenNode) : ''}
                                                  onBlur={ this.onBlur }
                                                  onChange={ (e) => this.myChangeHandler(e, true) }
                                                  disabled={childrenNode.editable && childrenNode.editable === 'false' } />

                                              </div> 
                                              :

                                              (childrenNode.children === undefined && childrenNode.type === 'textarea' ) ? 
                                              <div>
                                                  <textarea type={childrenNode.subtype} ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                                  style = { childrenNode.css ? childrenNode.css : {} }
                                                  value={childrenNode.val} 
                                                  onBlur={ this.onBlur }
                                                  onChange={ this.myChangeHandler }
                                                  rows="4" cols="50"
                                                  disabled={childrenNode.editable && childrenNode.editable === 'false' } />                                                      
                                              </div> 
                                              :

                                              (childrenNode.children === undefined && childrenNode.type === 'input' && childrenNode.subtype === 'radio' ) ? 
                                              <div>
                                                  <div>

                                                      <div className='row'>

                                                      {childrenNode.values && childrenNode.values.length && 
                                                          childrenNode.values.map( (each, index) => {
                                                              return(
                                                              <div className='col-lg-6'>
                                                                  <input type={childrenNode.subtype}  name={childrenNode.serviceKey} id={childrenNode.serviceID}
                                                                  value={each}
                                                                  key={each}
                                                                  checked={each === childrenNode.val}
                                                                  onBlur={this.onBlur }
                                                                  onChange={ this.myChangeHandler}
                                                                  disabled={childrenNode.editable && childrenNode.editable === 'false' } />
                                                                  {each} 
                                                              </div>
                                                              )
                                                          })
                                                      } 

                                                      </div>
                                                  </div>
                                                      
                                              </div> 
                                              :

                                              (childrenNode.children === undefined && childrenNode.type.includes('popup_select')) ? 

                                              <div >                                                  
                                                  <input type={childrenNode.subtype} ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                                  value={childrenNode.val ? childrenNode.val : ''} 
                                                  onBlur={this.onBlur} 
                                                  onChange={(e) => this.myChangeHandler(e, childrenNode)} 
                                                  disabled />
                                                  <button className='btn btn-link' onClick={(e) =>{
                                                      e.preventDefault()
                                                      this.popup_select_fn(childrenNode)
                                                      this.setState({ open_popup_select : ROWNUM})
                                                  }} ><FcSearch /></button>

                                                  { (open_popup_select === 0 || open_popup_select) && popup_table &&
                                                  
                                                      <Popup
                                                      content={
                                                          <>
                                                          <div className="row m-4">
                                                            <input type="text" autoFocus className="col-lg-6" name="search" placeholder='search here' value={popup_table.search} onChange={ this.on_popup_table_search } />
                                                          </div>

                                                          <div className='row m-4'>

                                                          {!popup_table.filteredData.length ?

                                                              <div>No match found</div>
                                                              :

                                                              <Table striped bordered hover>
                                                                  <thead>
                                                                  <tr>
                                                                      { popup_table.header.map( (each, indx) => {
                                                                          return(
                                                                              <th key={indx}>{each}</th>
                                                                          )
                                                                      })
                                                                      }
                                                                  </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                      { popup_table.filteredData.map( (each, index) => {
                                                                          return(
                                                                              <tr key={index} onClick={() =>
                                                                                  {
                                                                                      if(!each.disabled) this.tableRow_click(each)
                                                                                  }
                                                                              } 
                                                                              style={each.disabled ? { opacity: 0.5, pointerEvents: 'none' } : null}
                                                                              >
                                                                                  {popup_table.header.map( (one, indx) => {
                                                                                      return(
                                                                                          <td>{ each[one] } </td>
                                                                                      )
                                                                                  })
                                                                                  }
                                                                              </tr>
                                                                          )
                                                                      })
                                                                      }
                                                                  </tbody>
                                                              </Table>
                                                            }
                                                          </div>
                                                          </>
                                              
                                                      }
                                                      handleClose={() => this.setState({ open_popup_select : false, popup_table: false}) }
                                                      />
                                                  }
                                              </div>
                                        
                                              :


                                              (childrenNode.children === undefined && childrenNode.type === "select") ? 
                                              <div>

                                                      {/* <select name={childrenNode.serviceKey} id={childrenNode.serviceID} value={childrenNode.val} disabled={childrenNode.editable && childrenNode.editable === 'false' }
                                                      style = { childrenNode.css ? childrenNode.css : {} }
                                                      onChange={(e) =>{
                                                        if(childrenNode.onChange_operation) this.onChange_operation(childrenNode, e.target.value, index )
                                                        this.onDropDownValueChange(e)
                                                      }} 
                                                      onFocus={
                                                        (e) => {
                                                            // if(childrenNode.options !== undefined && childrenNode.options && childrenNode.options.length === 1){
                                                            // }
                                                            // else{
                                                              if(childrenNode.serviceKey === 'PO/IP Item Ref') this.filterPOIPItemRef(childrenNode.serviceID, ROWNUM)
                                                              if(childrenNode.serviceKey === 'Item Name') this.filterPOIPItemRef(childrenNode.serviceID, ROWNUM)
                                                            // }
                                                          }
                                                      }
                                                        >
                                                          <option value={''}></option>
                                                          { childrenNode.options !== undefined && childrenNode.options && 
                                                          childrenNode.options.map((opt, optKey) => 
                                                              <option key={optKey} value={opt.value} >{opt.label}</option>
                                                          ) }
                                                      </select> */}

                                                        <Autocomplete
                                                        disablePortal
                                                        id={childrenNode.serviceID}
                                                        options={childrenNode.options !== undefined && childrenNode.options.length ? childrenNode.options : []}
                                                        sx={{ width: 300, fontSize: '1rem' }}
                                                        value={childrenNode.val}
                                                        disabled={childrenNode.editable && childrenNode.editable === 'false' }
                                                        onChange={(event, newValue) => {
                                                          console.log(event, newValue)
                                                          // Assuming newValue is the selected value
                                                          if(!newValue && !childrenNode.val){
                                                          }else{

                                                            if (childrenNode.onChange_operation) {
                                                              this.onChange_operation(childrenNode, newValue ? newValue.value : "", index);
                                                            }
                                                            this.onDropDownValueChange({
                                                              target: {
                                                                  name: childrenNode.serviceKey,
                                                                  id: childrenNode.serviceID,
                                                                  value : newValue ? newValue.value : ""
                                                              }
                                                            })
                                                          }
                                                        }}
                                                        // onFocus={
                                                        //   (e) => {
                                                        //       // if(childrenNode.options !== undefined && childrenNode.options && childrenNode.options.length === 1){
                                                        //       // }
                                                        //       // else{
                                                        //         if(childrenNode.serviceKey === 'PO/IP Item Ref') this.filterPOIPItemRef(childrenNode.serviceID, ROWNUM, childrenNode)
                                                        //         if(childrenNode.serviceKey === 'Item Name') this.filterPOIPItemRef(childrenNode.serviceID, ROWNUM, childrenNode)
                                                        //       // }
                                                        //     }
                                                        // }
                                                        renderInput={(params) => <TextField {...params} label={childrenNode.serviceKey} />}
                                                      />

                                                      {/* <Select
                                                          style = { childrenNode.css ? childrenNode.css : {} }
                                                          name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                                          isDisabled={childrenNode.editable && childrenNode.editable === 'false' }
                                                          onChange={
                                                              value =>{

                                                                if(childrenNode.onChange_operation) this.onChange_operation(childrenNode, value.value, index )

                                                                this.onDropDownValueChange({
                                                                    target: {
                                                                        name: childrenNode.serviceKey,
                                                                        id: childrenNode.serviceID,
                                                                        value : value.value
                                                                    }
                                                                })
                                                              } 
                                                              }
                                                          onFocus={
                                                            (e) => childrenNode.serviceKey === 'PO/IP Item Ref' ? this.filterPOIPItemRef() : null
                                                          }
                                                          value={childrenNode.options && childrenNode.options.length ? childrenNode.options.find(each => each.value === childrenNode.val) : null}
                                                          options={childrenNode.options}
                                                          isSeachable={true}
                                                          isMulti={false}
                                                      /> */}

                                              </div>                                              

                                              : 

                                              (childrenNode.children === undefined && childrenNode.type === 'input' && childrenNode.subtype === 'upload' ) ? 
                                    
                                              <div>

                                                  {/* <input type='file' accept='.pdf' ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                                  style = { childrenNode.css ? childrenNode.css : {} }
                                                  onChange={e => this.onUpload(e, childrenNode)} 
                                                  disabled={childrenNode.editable && childrenNode.editable === 'false' } /> */}

                                                  <input
                                                    type="file"
                                                    accept='.pdf'
                                                    style={{ display: 'none' }}
                                                    id={childrenNode.serviceID + 'l'}
                                                    name={childrenNode.serviceKey} ref={childrenNode.serviceID}
                                                    disabled={childrenNode.editable && childrenNode.editable === 'false' }
                                                    onChange={e =>{
                                                        let event = { 
                                                            target: {
                                                                id: childrenNode.serviceID,
                                                                name: childrenNode.serviceKey,
                                                                files : e.target.files
                                                            }
                                                        }
                                                        
                                                        this.onUpload(event, childrenNode)} 
                                                    }
                                                  />

                                                  <label htmlFor={childrenNode.serviceID + 'l'}>
                                                      <Button id={childrenNode.serviceID} variant="contained" color="primary" component="span" disabled={childrenNode.editable && childrenNode.editable === 'false' }>
                                                      Upload <span className='ml-2'><ImFolderUpload/></span>
                                                      </Button>
                                                  </label>


                                                  { childrenNode.val && 
                                                      <button className="btn btn-success ml-2" onClick={(e) => this.openPDF(e, childrenNode)}>Open</button> }


                                                  { childrenNode.val && this.state.popData && this.state.showPopUP && this.state.showPopUP === childrenNode.serviceID ?

                                                  <Popup
                                                    content={
                                                        <>
                                                          {/* <iframe src={ URL.createObjectURL(this.state[childrenNode.serviceID]) } width="100%" height="500px" /> */}

                                                            <iframe
                                                            src={`data:application/pdf;base64,${this.state.popData}`}
                                                            title="pdf-viewer"
                                                            style={{ width: '100%', height: '500px' }}
                                                            />

                                                        </>

                                                    }
                                                    handleClose={() => this.setState({ showPopUP : null}) }
                                                  />
                                                  : null
                                              }
                                              </div>
                                              :

                                              childrenNode.val
                                          }                                         
                                          </td>
                                          </Tooltip>)
                                      }
                                    }
                                    
                                  }) 
                                  }
                                </tr>

                                { index === (treenode.children.length -1) && treenode.AddRow && treenode.AddRow === "true" &&
                                  <tr>
                                    {index !== 0 &&
                                    <button className='btn' style={{color: 'red', padding: '0'}} onClick={() => this.tableRowDel(children_Node, children__Node)}><IoMdRemoveCircle/></button>
                                    }
                                    <button className='btn' style={{color: 'green', padding: '0'}} onClick={() => this.tableRowAdd(children_Node, children__Node)}><IoMdAddCircle/></button>
                                  </tr>
                                }
                                </>

                              )
                            })
                          }
                      </tbody>
                    </Table>

                  </div>

                </>

                )
                
              }
            })
  
          }
        </div>
      )
    }else{
      return(<div>Loading.....</div>)
    }    
  }
}


function mapStateToProps(state) {
  return {
      VR2_Form_Json: state.VR2_Reducer.VR2_Form_Json,
      changeLogs: state.VR2_Reducer.changeLogs,
      storeVals: state.VR2_Reducer.storeVals,
      athenaStorage: state.VR2_Reducer.athenaStorage,
      dependency_json : state.VR2_Reducer.dependency_json,
      dynamicOption_Done : state.VR2_Reducer.dynamicOption_Done,
      ORG_ID_CONST : state.VR2_Reducer.ORG_ID_CONST,
      userData : state.login.userData,
  }
}


export default connect(
  mapStateToProps,
  { handleTreeChange, updateChangeLogs, updateStoreVals, updateAthenaStorage, updateDynamicOption_Done }
)(MyTable);
