import {
    ListItem,
    ListItemText
} from '@mui/material';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';


const DraggableListItem = ({ item, index, itemLabel, itemId }) => {
    return (
        <Draggable draggableId={item[itemId]} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : ''}
                >
                    <ListItemText primary={item[itemLabel]} />
                </ListItem>
            )}
        </Draggable>
    );
};

export default DraggableListItem;
