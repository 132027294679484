// import React, { useState, useEffect} from 'react';
// import { getPreviousDate, getDateFormat, getRequest, getStandardDate, postRequest} from '../../../globalhelper/helper';
// import {Form, Button, Spinner, Modal} from 'react-bootstrap';
// import {toast, ToastContainer} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
// import '../../CI/COD.css';
// import { Select } from './Select';
// import { SelectMonth } from './SelectMonth';
// import { connect } from 'react-redux';
// import CostContributionPopup from './CostContributionPopup';
// import { CSVLink } from 'react-csv';

// const CostContribution = (props) => {

//     const [formData, setFormData] = useState({
//         company: "",
//         fromDate: "",
//         toDate: "",
//         transType: "",
//         vendorName: "",
//         cost: "",
//         expen: ""
//     })

//     const [companyName, setCompanyName] = useState([])

//     const [companyDetails, setCompanyDetails] = useState([])

//     const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

//     const [dropdown_year, setDropdown_year] = useState(['2023', '2024'])

//     const [month, setMonth] = useState("")

//     const [year, setYear] = useState("")

//     const [loading, setLoading] = useState(false)

//     const [tableData, setTableData] = useState([])

//     const [dropdownTransType, setDropdownTransType] = useState([])

//     const [dropdownVendorName, setDropdownVendorName] = useState([])

//     const [dropdownCAM, setDropdownCAM] = useState([])

//     const [completeDataFromDB, setCompleteDataFromDB] = useState([])

//     const [changed, setChanged] = useState([])

//     const [camValue, setCamValue] = useState([])

//     const [showSpinner, setShowSpinner] = useState(false)

//     const [showPopup, setShowPopup] = useState(false)

//     const [items, setItems] = useState({})

//     const [LOB, setLOB] = useState([])

//     const [tableDataCheck, setTableDataCheck] = useState(["Test"])

//     const [selectedCreatedId, setSelectedCreatedId] = useState(null);

//     const [selectedCreatedIds, setSelectedCreatedIds] = useState([]);

//     const [DD,setDD] = useState([]);

//     const [poop, setpoop] = useState([]);

//     const [showDateModal, setShowDateModal] = useState(false);  // State for date input popup
//     const [tempForPrdFrom, setTempForPrdFrom] = useState('');    // Temporary state to store 'For Prd From'
//     const [tempForPrdTo, setTempForPrdTo] = useState('');        // Temporary state to store 'For Prd To'
//     const [currentRow, setCurrentRow] = useState(null); 
//     const [availableCAMOptions, setAvailableCAMOptions] = useState([]);         // Track the row that triggered the popup
//     const [VName, setVName] = useState([]);
//     const [EName, setEName] = useState([]);
//     const [ expenseList ,setExpenseList] = useState([])

//     useEffect(() => {
//         const uniqueCAMMethods = Array.from(new Set(tableData.map((item) => item.costallocationmethod).filter(Boolean)));
//         const vendorNamesFromTable = Array.from(new Set(tableData.map((item) => item.sendername).filter(Boolean)));
//         const ExpenseFromTable = Array.from(new Set(tableData.map((item) => item.ledgername).filter(Boolean)));
//         setVName(vendorNamesFromTable); // This will update vendor names in the dropdown
//         setAvailableCAMOptions(uniqueCAMMethods); // Set the dropdown options only once
//         setEName(ExpenseFromTable)
//     }, [tableData]);

//     // To get companies assigned to the user

//     useEffect(() => {
//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             const responseData = response.res.data
//             const orgname = responseData.map(item => item.orgname)
//             setCompanyDetails(responseData)
//             setCompanyName(orgname)
//         })
//     }, [])

//     // Function to get dates of a selected month

//     useEffect(() => {
//         const selectedYear = year
//         const selectedMonth = month 

//         const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
//         const startDate = new Date(selectedYear, monthIndex, 1)
//         const endDate = new Date(selectedYear, monthIndex + 1, 0)

//         console.log(getStandardDate(startDate), getStandardDate(endDate))

//         setFormData(prev => {
//             return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
//         })
//     }, [year, month])

//     const handleChange = (e) => {
//         const {name, value} = e.target
//         setFormData((prev) => {
//             return {...prev, [name]: value}
//         })
//     }

//     const handleMonthChange = (e) => {
//         setMonth(e.target.value)
//     }

//     const handleYearChange = (e) => {
//         setYear(e.target.value)
//     }

//     const handleReset = () => {
//         setFormData({
//             fromDate: '',
//             toDate: '',
//             company: '',
//             transType: '',
//             vendorName: '',
//             expen:'',
//             cost: ''
//         })
//         setYear("")
//         setMonth("")
//         setChanged([])
//         setDropdownTransType([])
//         setDropdownVendorName([])
//         setTableData([])
//         setCompleteDataFromDB([])
//     }

//     const getFilteredArray = async (value, key) => {
//         console.log(key)
//         const dropdown_array = value.map(val => val[key])
//         console.log(dropdown_array)
//         const filtered_dropdown_array = [...new Set(dropdown_array)]
//         console.log(filtered_dropdown_array)
//         return filtered_dropdown_array.filter(item => item !== undefined && item !== '')
//     }

//     const handleFilterChange = (e) => {
//         const {name, value} = e.target

//         // const data = [...completeDataFromDB]

//         setFormData(prevState => {
//             return {...prevState, [name]: value}
//         })

//         // const filteredData = tableData.filter(item => item.vendorName === value)

//         // setTableData(filteredData)
//     }

//     // useEffect(() => {
//     //     let Array = [];

//     //     const data = [...completeDataFromDB];

//     //     let transactionType = [formData.transType];
//     //     let vendorName = [formData.vendorName];

//     //     if (!transactionType[0]) {
//     //         transactionType = [...dropdownTransType];
//     //     }

//     //     if (!vendorName[0]) {
//     //         vendorName = [...dropdownVendorName];
//     //         vendorName.push(undefined);
//     //     }

//     //     for (const [i, item] of data.entries()) {
//     //         if (transactionType.includes(item.transactiontype) && vendorName.includes(item.sendername)) {
//     //             Array.push(item);
//     //         }

//     //         if (i === data.length - 1) {
//     //             setTableData(Array);
//     //             setTableDataCheck(Array);
//     //         }
//     //     }

//     //     // Automatically set the first transaction type if not already set
//     //     if (!formData.transType && dropdownTransType.length > 0) {
//     //         setFormData(prev => ({
//     //             ...prev,
//     //             transType: dropdownTransType[0],
//     //         }));
//     //     }

//     // }, [formData.transType, formData.vendorName, dropdownTransType]);


//     useEffect(() => {
//         // Initialize an empty array for the filtered data
//         let filteredArray = [];

//         // Fetch all data from the DB
//         const data = [...completeDataFromDB];

//         // Destructure the selected form data for ease of use
//         const { transType, vendorName, expen } = formData;

//         // Check whether the selected transaction type and vendor name are empty
//         const isTransactionTypeSelected = transType && transType !== ""; // true if a transaction type is selected
//         const isVendorNameSelected = vendorName && vendorName !== ""; // true if a vendor name is selected
//         const isExpense = expen && expen !== "";

//         // Filter the data based on the selected transaction type and vendor name
//         filteredArray = data.filter(item => {
//             // Check for both conditions
//             const matchTransactionType = isTransactionTypeSelected ? item.transactiontype === transType : true;
//             const matchVendorName = isVendorNameSelected ? item.sendername === vendorName : true;
//             const matchExpense = isExpense ? item.ledgername === expen : true;

//             // Only include items that match both conditions (or all if "Select" is chosen)
//             return matchTransactionType && matchVendorName && matchExpense;
//         });

//         // Update the table with the filtered data
//         setTableData(filteredArray);
//         setTableDataCheck(filteredArray); 

//     }, [formData.transType, formData.vendorName, formData.expen, completeDataFromDB]);

//     // useEffect(() => {
//     //     if(!tableDataCheck.length){
//     //         toast.error("No data found for the given combination")
//     //     }
//     // }, [tableDataCheck])

//     const handleTableCAMChange = (item) => {
//         // Open modal if forprdfrom or forprdto are missing
//         if (!item.forprdfrom || !item.forprdto) {
//             setCurrentRow(item);
//             setShowDateModal(true);
//             return;
//         }

//         // Filter CAM values based on forprdfrom and forprdto
//         const filteredCAM = camValue.filter(cam =>
//             cam.forprdfrom <= item.forprdto &&
//             (cam.forprdto === null || cam.forprdto >= item.forprdfrom || cam.forprdto === '')
//         );

//         const uniqueMethods = [...new Set(filteredCAM.map(cam => cam.costallocationmethod))];

//         if (uniqueMethods.length === 0) {
//             toast.warning("No matching cost allocation methods found for the selected period");
//             return;
//         }

//         const updatedDropdownOptions = [...uniqueMethods, "Dynamic"];
//         setDropdownCAM(updatedDropdownOptions);
//         setDD(filteredCAM);
//     };

//     const handleSaveDates = () => {
//         if (!tempForPrdFrom || !tempForPrdTo) {
//             toast.error("Both dates are required");
//             return;
//         }

//         const updatedTableData = tableData.map(row => {
//             if (row.createdid === currentRow.createdid && row.ADLineRef === currentRow.ADLineRef) {
//                 return {
//                     ...row,
//                     forprdfrom: tempForPrdFrom,
//                     forprdto: tempForPrdTo
//                 };
//             }
//             return row;
//         });

//         setTableData(updatedTableData);  // Update the table with new dates
//         setShowDateModal(false);  // Close modal
//         setTempForPrdFrom('');
//         setTempForPrdTo('');

//         // Proceed with filtering CAM based on new dates
//         handleTableCAMChange({ ...currentRow, forprdfrom: tempForPrdFrom, forprdto: tempForPrdTo });
//     };

//     const handleDropdownChange = (item, e) => {
//         setpoop(item.ExpAmt)
//         const value = e.target.value;
//         // Step 4: Handle what happens when a value is selected from the dropdown
//         if (!selectedCreatedIds.includes(item.createdid)) {
//             setSelectedCreatedIds([...selectedCreatedIds, item.createdid, item.transactiontype1]); // Add the new createdid to the array
//         }
//         setSelectedCreatedId(item.createdid);

//         const costMethodVal = DD.filter(cam => cam.costallocationmethod === value);
//         console.log("CM",costMethodVal);

//         if (!costMethodVal.length && value !== "Dynamic") {
//             toast.error("No value found for that method, please select another method.");
//         } else if (value === 'Dynamic') {
//             console.log(item);
//             setItems(item);
//             setShowPopup(true);
//         } else {
//             const totalCostAllocationValue = costMethodVal.reduce((acc, val) => acc + parseFloat(val.costallocationvalue), 0);

//             const newArray = costMethodVal.map((val, ind) => {
//                 const output = {};
//                 let calculatedPercentage = 0;

//                 if (totalCostAllocationValue !== 0) {
//                     calculatedPercentage = (parseFloat(val.costallocationvalue) / totalCostAllocationValue) * 100;
//                 }

//                 output[`LOB${ind + 1}`] = val.lob;
//                 output[`LOB${ind + 1}Percentage`] = calculatedPercentage.toFixed(2);

//                 let amount = 0;
//                 if (totalCostAllocationValue !== 0) {
//                     amount = (parseFloat(item.ExpAmt) * calculatedPercentage) / 100;
//                 }

//                 output[`LOB${ind + 1}Amt`] = amount.toFixed(2);

//                 return output;
//             });

//             const plainObject = newArray.reduce((acc, obj) => ({ ...acc, ...obj }), {});

//             // Update Table Data
//             const updatedTableData = tableData.map(row => {
//                 if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
//                     const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
//                     return { ...filteredObj, ...plainObject, costallocationmethod: value };
//                 }
//                 return row;
//             });

//             setTableData(updatedTableData);

//             // Update Complete Data
//             const updatedCompleteData = completeDataFromDB.map(row => {
//                 if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
//                     const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
//                     return { ...filteredObj, ...plainObject, costallocationmethod: value };
//                 }
//                 return row;  // Return unchanged rows
//             });

//             setCompleteDataFromDB(updatedTableData);

//             const changedIDS = [...changed, item.createdid];
//             setChanged(changedIDS);
//         }
//     };
//     const handleSubmit = () => {
//         const {fromDate, toDate, company} = formData

//         if(fromDate && toDate && company){
//             setLoading(true)
//             setTableData([])
//             setChanged([])
//             const id = companyDetails.filter(item => item.orgname === company)

//             const companyid = id.map(item => item.orgid)

//             const request = {
//                 fromDate,
//                 toDate, 
//                 company: companyid[0],
//                 userID: props.state.login.userData.USR_Name
//             }

//             Promise.all([
//                 postRequest(`api/edureka/getCostAllocationAndContribution`, request),
//                 postRequest(`api/edureka/getDistinctCAMForGivenPeriod`, request),
//                 postRequest(`api/edureka/getDistinctLOBs`, request)
//             ]).then(async ([item, value, val]) => {
//                 const responseData = JSON.parse(item.res.data)
//                 console.log(responseData)
//                 const camData = JSON.parse(value.res.data)
//                 console.log(camData)

//                 console.log(JSON.parse(val.res.data))
//                 const responseLOB = JSON.parse(val.res.data)

//                 const distinctLOBs = responseLOB.map(item => item.lob)

//                 if(responseData.length && camData.length){
//                     const transTypeList = await getFilteredArray(responseData, "transactiontype1")
//                     const costAllocationMethodList = await getFilteredArray(camData, "costallocationmethod")
//                     const vendorNameList = await getFilteredArray(responseData, "sendername")
//                     const ExpenseList = await getFilteredArray(responseData, "ledgername")
//                     console.log("VNL",vendorNameList);


//                     costAllocationMethodList.push("Dynamic")
//                     costAllocationMethodList.push("Equal")

//                     const transitionedData = await processTransactions(responseData)

//                     console.log(transitionedData)
//                     console.log(transTypeList)
//                     console.log(costAllocationMethodList)

//                     setDropdownTransType(transTypeList)
//                     setDropdownVendorName(vendorNameList)
//                     setExpenseList(ExpenseList)
//                     setDropdownCAM([... new Set(costAllocationMethodList)])
//                     setTableData(transitionedData)
//                     setCompleteDataFromDB(transitionedData)
//                     setLoading(false)
//                     setCamValue(camData)
//                     setLOB(distinctLOBs)
//                 } else {
//                     setLoading(false)
//                     toast.warning("No data found, please try some other combination")
//                 }
//             }).catch(err => {
//                 console.log(err)
//                 toast.error("Oops something went wrong")
//             })

//         } else {
//             toast.error("Please select all the required fields")
//         }
//     }

//     useEffect(() => {
//         let filteredData = [];

//         // Check if dropdownTransType and completeDataFromDB are populated
//         if (dropdownTransType.length > 0 && completeDataFromDB.length > 0 && dropdownVendorName.length > 0 && expenseList.length > 0) {
//             // Use the selected transaction type from formData
//             let transactionType = formData.transType ? [formData.transType] : dropdownTransType;

//             // Use the selected vendor name from formData, or include all if not set
//             let vendorName = formData.vendorName ? [formData.vendorName] : dropdownVendorName;

//             let expense = formData.expen ? [formData.expen] : expenseList;

//             // Filter the data based on transaction type and vendor name
//             filteredData = completeDataFromDB.filter(item =>
//                 transactionType.includes(item.transactiontype1) && vendorName.includes(item.sendername) && expense.includes(item.ledgername)
//             );

//             // Update table data
//             setTableData(filteredData);
//             setTableDataCheck(filteredData);
//         }

//     }, [formData.transType, formData.vendorName, dropdownTransType, completeDataFromDB, dropdownVendorName]);

//     async function processTransactions(transactions) {
//         return transactions.map(transaction => {
//           const output = {};
//           const lobOrder = []

//           if (transaction.LOBAllocList) {
//             const LOBParts = transaction.LOBAllocList.split(',');

//             LOBParts.forEach(part => {
//               const [lob, amount] = part.split('(');
//               const lobKey = lob.trim();
//               let amtValue = parseFloat(amount.replace(/[^\d.-]/g, ''));

//                 const lobIndex = lobOrder.length + 1;
//                 lobOrder.push(lobKey);

//                 output[`LOB${lobIndex}`] = lobKey;
//                 output[`LOB${lobIndex}Amt`] = amtValue
//             });
//           }

//           lobOrder.forEach((lob, index) => {
//             output[`LOB${index + 1}`] = output[`LOB${index + 1}`] || lob;
//             output[`LOB${index + 1}Amt`] = output[`LOB${index + 1}Amt`] || 0;
//           });

//           return {
//             ...transaction,
//             ...output
//           };
//         });
//     }

//     const handleUpdate = () => {
//         const data = [...completeDataFromDB];

//         // Ensure that you're filtering by both createdid and transactiontype
//         const changedData = data.filter(item => 
//             changed.includes(item.createdid) && 
//             item.transactiontype1 === formData.transType
//         );

//         // Ensure rows to update are filtered by both createdid and transactiontype
//         const rowsToUpdate = tableData.filter(row => 
//             row.createdid === selectedCreatedId && 
//             row.transactiontype1 === formData.transType
//         );

//         console.log('Updating rows: ', rowsToUpdate);

//         // Aggregate values for ledgername, accounttype, and ADLineRef
//         const ledgernames = new Set();
//         const accounttypes = new Set();
//         const ADLineRefs = new Set();

//         rowsToUpdate.forEach(row => {
//             if (row.ledgername) ledgernames.add(row.ledgername);
//             if (row.accounttype) accounttypes.add(row.accounttype);
//             if (row.ADLineRef) ADLineRefs.add(row.ADLineRef);
//         });

//         const aggregatedLedgernames = Array.from(ledgernames).join(', ');
//         const aggregatedAccounttypes = Array.from(accounttypes).join(', ');
//         const aggregatedADLineRefs = Array.from(ADLineRefs).join(', ');

//         const id = companyDetails.find(item => item.orgname === formData.company);
//         const companyid = id ? id.orgid : "";

//         const request = {
//             data: changedData,
//             transtype: formData.transType,
//             company: companyid,
//             userID: props.state.login.userData.USR_Name,
//             ledgernames: aggregatedLedgernames,
//             accounttypes: aggregatedAccounttypes,
//             ADLineRefs: aggregatedADLineRefs
//         };

//         console.log("REQUEST", request);

//         setShowSpinner(true);

//         postRequest(`api/edureka/updateCAMAllocation`, request)
//             .then(response => {
//                 console.log(response.res.data);
//                 toast.success("Records updated successfully, please press submit to know the updated value");
//                 setShowSpinner(false);
//                 setChanged([]);
//                 setFormData(prev => ({
//                     ...prev,
//                     transType: "",
//                     vendorName: ""
//                 }));
//             })
//             .catch(err => {
//                 console.log(err);
//                 toast.error("Oops something went wrong");
//                 setShowSpinner(false);
//             });
//     };

//     const handleCostAllocationFilterChange = (event) => {
//         const { value } = event.target;

//         // Update the formData state with the selected cost allocation method
//         setFormData((prevState) => ({
//           ...prevState,
//           costAllocationFilter: value
//         }));
//       };


//   return (
//     <>
//         <form>
//             <div className='row'>
//                 <div className='col-md-2'>
//                     <Form.Group>
//                         <Form.Label>Company</Form.Label>
//                         <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
//                             <option value='' disabled>Select</option>
//                             {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
//                         </select>
//                     </Form.Group>
//                 </div>

//                 <div className="col-md-2">
//                     <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
//                 </div>

//                 <div className="col-md-2">
//                     <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
//                 </div>

//                 {dropdownTransType.length ? (
//                     <div className='col-md-2'>
//                         <Form.Group>
//                             <Form.Label>Transaction</Form.Label>
//                             <select className='spacing' value={formData.transType} name='transType' onChange={handleFilterChange}>
//                                 <option value="">Select</option> {/* This option will now show all transaction types */}
//                                 {dropdownTransType.sort().map((item, index) => (
//                                     <option key={index} value={item}>
//                                         {item}
//                                     </option>
//                                 ))}
//                             </select>
//                         </Form.Group>
//                     </div>
//                 ) : null}

//                 {VName.length ?
//                 <div className='col-md-2'>
//                     <Form.Group>
//                         <Form.Label>Vendor/Party Name</Form.Label>
//                         <select className='spacing' value={formData.vendorName} name='vendorName' onChange={handleFilterChange}>
//                             <option value="">Select</option>
//                             {VName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                         </select>
//                     </Form.Group>
//                 </div> 
//                 :null}

//                 {/* New Dropdown for Cost Allocation Method */}
//                 {availableCAMOptions.length ? (
//                 <div className='col-md-2'>
//                     <Form.Group>
//                     <Form.Label>CA Method</Form.Label>
//                     <select
//                         className='spacing'
//                         value={formData.costAllocationFilter || ''}
//                         name='costAllocationFilter'
//                         onChange={handleCostAllocationFilterChange} // Add a new handler
//                     >
//                         <option value=''>Select</option>
//                         {availableCAMOptions.sort().map((item, index) => (
//                         <option key={index} value={item}>
//                             {item}
//                         </option>
//                         ))}
//                     </select>
//                     </Form.Group>
//                 </div>
//                 ) : null}

//                 {EName.length ?
//                     <div className='col-md-2'>
//                         <Form.Group>
//                             <Form.Label>Expense Ledger</Form.Label>
//                             <select className='spacing' value={formData.expen} name='expen' onChange={handleFilterChange}>
//                                 <option value="">Select</option>
//                                 {EName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                             </select>
//                         </Form.Group>
//                     </div> 
//                 :null}

//             </div>

//             <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
//                 <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
//                 <Button style={{marginLeft:'10px'}} size='sm' variant='danger' onClick={handleReset}>Reset</Button>
//                 {tableData.length ?
//                         <CSVLink data={tableData} filename='Cost_Allocation_Report.csv' style={{ marginLeft: '10px' }}>Download</CSVLink> : null}

//                 {selectedCreatedId && changed.length && !showSpinner ?
//                 <Button style={{marginLeft:'10px'}} size='sm' variant='primary' onClick={handleUpdate} disabled={formData.transType === ""}>
//                     Update
//                 </Button> : null}

//                 {showSpinner ?
//                 <Button style={{marginLeft:'10px'}} variant="warning" disabled>
//                     <Spinner
//                         as="span"
//                         animation="border"
//                         size="sm"
//                         role="status"
//                         aria-hidden="true"
//                     />
//                 <span className="visually-hidden">Loading...</span>
//                 </Button> : null}
//             </div>
//         </form>

//         <br/>

//         {loading ? <div className='center'>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//               </div>: null}

//         <ToastContainer/>
//         {showPopup ?
//         <CostContributionPopup
//         mode="amount"  //mbp
//         totalValue = {poop}
//         // totalValue ={tableData.map((item)=>(item.ExpAmt))}

//            lobs={LOB}
//             handleClose = {() => {
//                 setShowPopup(false)

//             }}

//             handleData = {(prop) => {
//                     console.log(prop)
//                     setShowPopup(false)

//                     const data = [...tableData]
//                     const completeData = [...completeDataFromDB]

//                     let newArray

//                     newArray = prop.map((val, ind) => {
//                         const output = {}

//                         output[`LOB${ind+1}`] = val.lob
//                         output[`LOB${ind +1}Percentage`] = val.amountPercentage

//                         const amount = parseFloat(items.ExpAmt) * parseFloat(val.amountPercentage) /100

//                         output[`LOB${ind +1}Amt`] = amount.toFixed(2)

//                         console.log(output)

//                         return output
//                     })

//                     const plainObject = newArray.reduce((acc, obj) => {
//                         return {...acc, ...obj}
//                     }, {})

//                     const objIndex1 = data.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)
//                     const objIndex2 = completeData.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)

//                     const update1 = [items].map(obj => {

//                         const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => {
//                             acc[key] = obj[key]
//                             return acc
//                         }, {})

//                         const rest = {...filteredObj, ...plainObject}
//                         rest["costallocationmethod"] = "Dynamic"
//                         return rest
//                     })

//                     const updatedObj1 = {...update1[0]}

//                     const updatedArray1 = [...data.slice(0, objIndex1), updatedObj1, ...data.slice(objIndex1 + 1)]

//                     console.log(updatedArray1)

//                     //[completeData[objIndex2]]
//                     const update2 = [items].map(obj => {

//                         const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
//                         .reduce((acc, key) => {
//                             acc[key] = obj[key]
//                             return acc
//                         }, {})

//                         const rest = {...filteredObj, ...plainObject}
//                         rest["costallocationmethod"] = "Dynamic"
//                         return rest
//                     })

//                     const updatedObj2 = {...update2[0]}

//                     const updatedArray2 = [...completeData.slice(0, objIndex2), updatedObj2, ...completeData.slice(objIndex2 +1)]

//                     setTableData(updatedArray1)
//                     setCompleteDataFromDB(updatedArray2)

//                     const changedIDS = [...changed]
//                     changedIDS.push(items.createdid)
//                     console.log(changedIDS)
//                     setChanged(changedIDS)
//                     console.log(update1)

//                 }
//             } 
//         /> : null}

//         {!loading && tableData.length ? (
//             <div>
//                 <table border="1" id="table-css">
//                     <thead>
//                         <tr>
//                             <th>Document Number</th>
//                             <th>Accounting Journal</th>
//                             <th style={{ minWidth: '20px', width:'20px'  }}>Ac Date</th>
//                             <th>For Prd From</th>
//                             <th>For Prd To</th>
//                             <th>Party Name</th>
//                             <th>Account Class/Ledger Name</th>
//                             <th>ItemID</th>
//                             <th>AccountType</th>
//                             <th style={{ minWidth: '20px', width:'20px'  }}>DocumentRef</th>
//                             <th style={{width:'210px'}}>Cost Allocation Method</th>
//                             <th>Amount in Local Currency</th>
//                             <th>LOB1</th>
//                             <th>Amt</th>
//                             <th>LOB2</th>
//                             <th>Amt</th>
//                             <th>LOB3</th>
//                             <th>Amt</th>
//                             <th>LOB4</th>
//                             <th>Amt</th>
//                             <th>LOB5</th>
//                             <th>Amt</th>
//                             <th>LOB6</th>
//                             <th>Amt</th>
//                             <th>LOB7</th>
//                             <th>Amt</th>
//                             <th>LOB8</th>
//                             <th>Amt</th>
//                             <th>LOB9</th>
//                             <th>Amt</th>
//                             <th>LOB10</th>
//                             <th>Amt</th>
//                         </tr>
//                     </thead>

//                     <tbody>
//                         {tableData
//                             .filter((item) => !formData.costAllocationFilter || item.costallocationmethod === formData.costAllocationFilter)
//                             .sort((a, b) => a.createdid.localeCompare(b.createdid))
//                             .map((item, index) => {
//                                 const isHighlighted = selectedCreatedIds.includes(item.createdid) && item.transactiontype1 === formData.transType;

//                                 return (
//                                     <tr key={index} className={isHighlighted ? 'highlight' : ''}>
//                                         <td>{item.createdid}</td>
//                                         <td>{item.accountingdocnumber}</td>
//                                         <td style={{ minWidth: '20px', width:'20px'  }}>{getPreviousDate( item.accountingdate)}</td>
//                                         <td>{item.forprdfrom}</td>
//                                         <td>{item.forprdto}</td>
//                                         <td>{item.sendername}</td>
//                                         <td>{item.ledgername}</td>
//                                         <td>{item.itemid}</td>
//                                         <td>{item.accounttype}</td>
//                                         <td style={{ minWidth: '20px', width:'20px'  }}>{item.documentref}</td>
//                                         <td style={{ minWidth: '280px' }}>
//                                             <div>
//                                             <select
//                                                 className="spacing"
//                                                 value={item.costallocationmethod || ''}
//                                                 name="costallocationmethod"
//                                                 onFocus={() => handleTableCAMChange(item)}  // Filter CAM based on forprdfrom and forprdto when dropdown opens
//                                                 onChange={(e) => handleDropdownChange(item, e)}  // Handle selected value from dropdown
//                                             >
//                                                 <option value="">Select</option>
//                                                 {dropdownCAM
//                                                     .sort()
//                                                     .map((camItem, camIndex) => (
//                                                         <option key={camIndex} value={camItem}>
//                                                             {camItem}
//                                                         </option>
//                                                     ))}
//                                             </select>   
//                                             </div>
//                                         </td>
//                                         <td style={{ textAlign: 'right' }}>
//                                             {Number(item.ExpAmt ? item.ExpAmt : 0).toFixed(2)}
//                                         </td>
//                                         <td>{item.LOB1}</td>
//                                         <td>{item.LOB1Amt}</td>
//                                         <td>{item.LOB2}</td>
//                                         <td>{item.LOB2Amt}</td>
//                                         <td>{item.LOB3}</td>
//                                         <td>{item.LOB3Amt}</td>
//                                         <td>{item.LOB4}</td>
//                                         <td>{item.LOB4Amt}</td>
//                                         <td>{item.LOB5}</td>
//                                         <td>{item.LOB5Amt}</td>
//                                         <td>{item.LOB6}</td>
//                                         <td>{item.LOB6Amt}</td>
//                                         <td>{item.LOB7}</td>
//                                         <td>{item.LOB7Amt}</td>
//                                         <td>{item.LOB8}</td>
//                                         <td>{item.LOB8Amt}</td>
//                                         <td>{item.LOB9}</td>
//                                         <td>{item.LOB9Amt}</td>
//                                         <td>{item.LOB10}</td>
//                                         <td>{item.LOB10Amt}</td>
//                                     </tr>
//                                 );
//                             })}
//                     </tbody>
//                 </table>
//             </div>
//         ) : null}

// <Modal show={showDateModal} onHide={() => setShowDateModal(false)}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Enter Dates for Cost Allocation</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form>
//                         <Form.Group>
//                             <Form.Label>For Prd From</Form.Label>
//                             <Form.Control
//                                 type="date"
//                                 value={tempForPrdFrom}
//                                 onChange={(e) => setTempForPrdFrom(e.target.value)}
//                             />
//                         </Form.Group>
//                         <Form.Group>
//                             <Form.Label>For Prd To</Form.Label>
//                             <Form.Control
//                                 type="date"
//                                 value={tempForPrdTo}
//                                 onChange={(e) => setTempForPrdTo(e.target.value)}
//                             />
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShowDateModal(false)}>
//                         Cancel
//                     </Button>
//                     <Button variant="primary" onClick={handleSaveDates}>
//                         Save
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//     </>
//   )
// }

// function mapStateToProps(state){
//     return {
//         state
//     }
// }

// export default connect(mapStateToProps) (CostContribution)



import React, { useState, useEffect} from 'react';
import { getPreviousDate, getDateFormat, getRequest, getStandardDate, postRequest} from '../../../globalhelper/helper';
import {Form, Button, Spinner, Modal} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import '../../CI/COD.css';
import { Select } from './Select';
import { SelectMonth } from './SelectMonth';
import { connect } from 'react-redux';
import CostContributionPopup from './CostContributionPopup';
import { CSVLink } from 'react-csv';

const CostContribution = (props) => {

    const [formData, setFormData] = useState({
        company: "",
        fromDate: "",
        toDate: "",
        transType: "",
        vendorName: "",
        cost: "",
        costAllocationFilter: null,
        expen: ""
    })

    const [companyName, setCompanyName] = useState([])

    const [companyDetails, setCompanyDetails] = useState([])

    const [dropdown_month, setDropdown_month] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])

    const [dropdown_year, setDropdown_year] = useState(['2023', '2024'])

    const [month, setMonth] = useState("")

    const [year, setYear] = useState("")

    const [loading, setLoading] = useState(false)

    const [tableData, setTableData] = useState([])

    const [dropdownTransType, setDropdownTransType] = useState([])

    const [dropdownVendorName, setDropdownVendorName] = useState([])

    const [dropdownCAM, setDropdownCAM] = useState([])
    const [ukcam, setukcam] = useState([])

    const [completeDataFromDB, setCompleteDataFromDB] = useState([])

    const [changed, setChanged] = useState([])

    const [camValue, setCamValue] = useState([])

    const [showSpinner, setShowSpinner] = useState(false)

    const [showPopup, setShowPopup] = useState(false)

    const [items, setItems] = useState({})

    const [LOB, setLOB] = useState([])

    const [tableDataCheck, setTableDataCheck] = useState(["Test"]);
    const [dropdownCAMOptions, setDropdownCAMOptions] = useState({});

    const [selectedCreatedId, setSelectedCreatedId] = useState(null);

    const [selectedCreatedIds, setSelectedCreatedIds] = useState([]);

    const [DD,setDD] = useState([]);

    const [poop, setpoop] = useState([]);

    const [showDateModal, setShowDateModal] = useState(false);  // State for date input popup
    const [tempForPrdFrom, setTempForPrdFrom] = useState('');    // Temporary state to store 'For Prd From'
    const [tempForPrdTo, setTempForPrdTo] = useState('');        // Temporary state to store 'For Prd To'
    const [currentRow, setCurrentRow] = useState(null); 
    const [availableCAMOptions, setAvailableCAMOptions] = useState([]);         // Track the row that triggered the popup
    const [VName, setVName] = useState([]);
    const [EName, setEName] = useState([]);
    const [expenseList, setExpenseList] = useState([])
    const [selectedRows, setSelectedRows] = useState([]); // To store selected rows
    const [selectAll, setSelectAll] = useState(false); // To handle "Select All" checkbox
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [dropdownValue, setDropdownValue] = useState("");
    const [applyToAll, setApplyToAll] = useState(false); // To track user choice
    const handleCloseModal = () => setShowConfirmModal(false);
    const handleShowModal = () => setShowConfirmModal(true);
    // State to store the original options
    const [originalDropdownTransType, setOriginalDropdownTransType] = useState([]);
    const [originalDropdownVendorName, setOriginalDropdownVendorName] = useState([]);
    const [originalExpenseList, setOriginalExpenseList] = useState([]);
    // Add a new state to keep the original unfiltered data
const [originalTableData, setOriginalTableData] = useState([]);

// Fetch or initialize your table data
useEffect(() => {
    // Assume you have fetched `fetchedData` from an API or some other source
    if (tableData.length > 0) {
        setTableData(tableData);          // Set the data to display in the table
        setOriginalTableData(tableData);  // Save a copy of the original data
    }
}, [tableData]);  // This depends on your data fetching logic




    const ConfirmationModal = ({ show, handleClose, handleYes, handleNo }) => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Apply Changes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to apply this change to all selected rows, or just the current row?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNo}>
                        Just this row
                    </Button>
                    <Button variant="primary" onClick={handleYes}>
                        Apply to all rows
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    // Select all rows
// Modified handleSelectAllRows to select all rows based on ADLineRef
const handleSelectAllRows = () => {
    if (selectedCreatedIds.length === tableData.length) {
        setSelectedCreatedIds([]); // Deselect all
    } else {
        const allIds = tableData.map((item) => item.createdid);
        setSelectedCreatedIds(allIds); // Select all
    }
};

const handleRowSelect = (createdId) => {
    if (selectedCreatedIds.includes(createdId)) {
        setSelectedCreatedIds(selectedCreatedIds.filter(id => id !== createdId));
    } else {
        setSelectedCreatedIds([...selectedCreatedIds, createdId]);
    }
};



    useEffect(() => {
        // Check if original dropdown values have already been initialized
        if (originalDropdownTransType.length === 0 && tableData.length > 0) {
            // Get unique options from the original data
            const allTransactionTypes = [...new Set(tableData.map(item => item.transactiontype1))];
            const allVendorNames = [...new Set(tableData.map(item => item.sendername))];
            const allExpenses = [...new Set(tableData.map(item => item.ledgername))];
    
            // Set both original and filtered options only once
            setOriginalDropdownTransType(allTransactionTypes);
            setDropdownTransType(allTransactionTypes);
    
            setOriginalDropdownVendorName(allVendorNames);
            setDropdownVendorName(allVendorNames);
    
            setOriginalExpenseList(allExpenses);
            setExpenseList(allExpenses);
        }
    }, [tableData]); // Dependencies only on tableData
    
    

    // useEffect(() => {
    //     const uniqueCAMMethods = Array.from(new Set(tableData.map((item) => item.costallocationmethod).filter(Boolean)));
    //     setAvailableCAMOptions(uniqueCAMMethods); // Set the dropdown options only once
    // }, [tableData]);

    useEffect(() => {
        const uniqueCAMMethods = Array.from(
            new Set(
                tableData.map((item) => item.costallocationmethod)
            )
        );
        
        // Include a blank or empty value if it’s not already in the list
        if (!uniqueCAMMethods.includes('')) {
            uniqueCAMMethods.unshift(''); // Add an empty string at the beginning of the list
        }
        
        setAvailableCAMOptions(uniqueCAMMethods);
    }, [tableData]);
    

    // To get companies assigned to the user

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    // Function to get dates of a selected month

    useEffect(() => {
        const selectedYear = year
        const selectedMonth = month 

        const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth()
        const startDate = new Date(selectedYear, monthIndex, 1)
        const endDate = new Date(selectedYear, monthIndex + 1, 0)

        console.log(getStandardDate(startDate), getStandardDate(endDate))

        setFormData(prev => {
            return {...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate)}
        })
    }, [year, month])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        
        // Reset selected IDs
        setSelectedCreatedIds([]);
    };

    const handleMonthChange = (event) => {
        const { value } = event.target;
        setMonth(value);
        
        // Reset selected IDs
        setSelectedCreatedIds([]);
    };
    
    const handleYearChange = (event) => {
        const { value } = event.target;
        setYear(value);
        
        // Reset selected IDs
        setSelectedCreatedIds([]);
    };

    const handleReset = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            company: '',
            transType: '',
            vendorName: '',
            expen:'',
            cost: ''
        })
        setYear("")
        setMonth("")
        setChanged([])
        setDropdownTransType([])
        setDropdownVendorName([])
        setTableData([])
        setCompleteDataFromDB([])
    }

    const getFilteredArray = async (value, key) => {
        console.log(key)
        const dropdown_array = value.map(val => val[key])
        console.log(dropdown_array)
        const filtered_dropdown_array = [...new Set(dropdown_array)]
        console.log(filtered_dropdown_array)
        return filtered_dropdown_array.filter(item => item !== undefined && item !== '')
    }

    const getFilteredArrays = async (value, key) => {
        console.log(key);
        const dropdown_array = value.map(val => val[key]);
        console.log(dropdown_array);
        
        const filtered_dropdown_array = [...new Set(dropdown_array)];
        console.log(filtered_dropdown_array);
        
        // Filter to only include items that start with "DYNAMIC"
        return filtered_dropdown_array.filter(item => item && item.startsWith("DYNAMIC"));
    }

    // const handleFilterChange = (e) => {
    //     const {name, value} = e.target

    //     // const data = [...completeDataFromDB]

    //     setFormData(prevState => {
    //         return {...prevState, [name]: value}
    //     })

    //     // const filteredData = tableData.filter(item => item.vendorName === value)

    //     // setTableData(filteredData)
    // }

    // useEffect(() => {
    //     let Array = [];
    
    //     const data = [...completeDataFromDB];
    
    //     let transactionType = [formData.transType];
    //     let vendorName = [formData.vendorName];
    
    //     if (!transactionType[0]) {
    //         transactionType = [...dropdownTransType];
    //     }
    
    //     if (!vendorName[0]) {
    //         vendorName = [...dropdownVendorName];
    //         vendorName.push(undefined);
    //     }
    
    //     for (const [i, item] of data.entries()) {
    //         if (transactionType.includes(item.transactiontype) && vendorName.includes(item.sendername)) {
    //             Array.push(item);
    //         }
    
    //         if (i === data.length - 1) {
    //             setTableData(Array);
    //             setTableDataCheck(Array);
    //         }
    //     }
    
    //     // Automatically set the first transaction type if not already set
    //     if (!formData.transType && dropdownTransType.length > 0) {
    //         setFormData(prev => ({
    //             ...prev,
    //             transType: dropdownTransType[0],
    //         }));
    //     }
    
    // }, [formData.transType, formData.vendorName, dropdownTransType]);
    
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
    
        // Update the selected form data
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    
        // Filter table data based on selected filters
        let filteredData = [...originalTableData];
    
        if (name === 'transType' && value) {
            filteredData = filteredData.filter(item => item.transactiontype1 === value);
        }
        if (name === 'vendorName' && value) {
            filteredData = filteredData.filter(item => item.sendername === value);
        }
        if (name === 'expen' && value) {
            filteredData = filteredData.filter(item => item.ledgername === value);
        }
    
        // Update the table data
        setTableData(filteredData);
    
        // Reset dropdown options to original options
        setDropdownTransType(originalDropdownTransType);
        setDropdownVendorName(originalDropdownVendorName);
        setExpenseList(originalExpenseList);
        setSelectedCreatedIds([]);
    };
    
    

    useEffect(() => {
        // Initialize an empty array for the filtered data
        let filteredArray = [];
    
        // Fetch all data from the DB
        const data = [...completeDataFromDB];
    
        // Destructure the selected form data for ease of use
        const { transType, vendorName, expen } = formData;
    
        // Check whether the selected transaction type and vendor name are empty
        const isTransactionTypeSelected = transType && transType !== ""; // true if a transaction type is selected
        const isVendorNameSelected = vendorName && vendorName !== ""; // true if a vendor name is selected
        const isExpense = expen && expen !== "";
    
        // Filter the data based on the selected transaction type and vendor name
        filteredArray = data.filter(item => {
            // Check for both conditions
            const matchTransactionType = isTransactionTypeSelected ? item.transactiontype === transType : true;
            const matchVendorName = isVendorNameSelected ? item.sendername === vendorName : true;
            const matchExpense = isExpense ? item.ledgername === expen : true;
    
            // Only include items that match both conditions (or all if "Select" is chosen)
            return matchTransactionType && matchVendorName && matchExpense;
        });
    
        // Update the table with the filtered data
        setTableData(filteredArray);
        setTableDataCheck(filteredArray); 
    
    }, [formData.transType, formData.vendorName, formData.expen, completeDataFromDB]);
    
    // useEffect(() => {
    //     if(!tableDataCheck.length){
    //         toast.error("No data found for the given combination")
    //     }
    // }, [tableDataCheck])

    // const handleTableCAMChange = (item) => {
    //     // Open modal if forprdfrom or forprdto are missing
    //     if (!item.forprdfrom || !item.forprdto) {
    //         setCurrentRow(item);
    //         setShowDateModal(true);
    //         return;
    //     }

    //     // Filter CAM values based on forprdfrom and forprdto
    //     // const filteredCAM = camValue.filter(cam =>
    //     //     cam.forprdfrom <= item.forprdto &&
    //     //     (cam.forprdto === null || cam.forprdto >= item.forprdfrom || cam.forprdto === '')
    //     // );
    //     console.log(camValue);
    //     const cvalue = [...camValue, ...dropdownCAM]
    //     const calue = [...new Set(cvalue)]
    //     console.log((calue));
        
        
    //     const uniqueMethods = [...new Set(camValue.map(cam => cam.costallocationmethod))];
    //     console.log(uniqueMethods);
        
    //     const umethods = [...uniqueMethods, ...dropdownCAM]
    //     const mehtod = [...new Set(umethods)]
    //     console.log(mehtod);
        
    //     if (uniqueMethods.length === 0) {
    //         toast.warning("No matching cost allocation methods found for the selected period");
    //         return;
    //     }
    //     const updatedDropdownOptions = [...mehtod];
    //     console.log(updatedDropdownOptions);
        
    //     setDropdownCAM(updatedDropdownOptions);
    //     setDD(calue);
    // };

    const handleTableCAMChange = (item) => {
        if (item.costallocationmethod === "") {
            setDropdownCAMOptions(prev => ({
                ...prev,
                [`${item.createdid}-${item.accountingdocnumber}`]: []
            }));
            return;
        }
    
        if (!item.forprdfrom || !item.forprdto) {
            setCurrentRow(item);
            setShowDateModal(true);
            return;
        }
    
        const filteredCAM = camValue.filter(cam =>
            cam.forprdfrom <= item.forprdto &&
            (cam.forprdto === null || cam.forprdto >= item.forprdfrom || cam.forprdto === '')
        );
        console.log(filteredCAM);
        
    
        const uniqueMethods = [...new Set(filteredCAM.map(cam => cam.costallocationmethod))];
        console.log(uniqueMethods);
        
    
        if (uniqueMethods.length === 0) {
            toast.warning("No matching cost allocation methods found for the selected period");
            return;
        }
    
        // Fetch dynamic option specific to this row
        const dynamicOptions = dropdownCAMOptions[`${item.createdid}-${item.accountingdocnumber}`] || [];
        console.log(dynamicOptions);
        
                
        // Ensure the "Dynamic" option is included alongside other methods and dynamic values for this row
        const updatedDropdownOptions = [
            ...uniqueMethods,
            ...ukcam,
            "Dynamic",
            ...dynamicOptions  // Include dynamically generated options (e.g., dynamicString)
        ];
        console.log(updatedDropdownOptions);
        
    
        setDropdownCAMOptions(prev=>({
            ...prev,
            [`${item.createdid}-${item.accountingdocnumber}`]:updatedDropdownOptions
        }));
    
        setDD(filteredCAM);
    };
    

    const handleSaveDates = () => {
        if (currentRow) {
            const updatedTableData = tableData.map(row => {
                if (row.createdid === currentRow.createdid) {
                    return { ...row, forprdfrom: tempForPrdFrom, forprdto: tempForPrdTo };
                }
                return row;
            });

            setTableData(updatedTableData);
            setCompleteDataFromDB(prevData =>
                prevData.map(row => {
                    if (row.createdid === currentRow.createdid) {
                        return { ...row, forprdfrom: tempForPrdFrom, forprdto: tempForPrdTo };
                    }
                    return row;
                })
            );

            // Close the modal after saving
            setShowDateModal(false);
        }
    };

    const handleDropdownChange = (item, e) => {
        setpoop(item.ExpAmt);
        const value = e.target.value;
    
        let plainObject = {}; // Initialize plainObject at the start of the function
    
        if (value === "") {
            setCurrentItem(item);
            setDropdownValue(value);
            return;
        }
    
        const costMethodVal = DD.filter(cam => cam.costallocationmethod === value);
        console.log(costMethodVal);
    
        if (!costMethodVal.length && value !== "Dynamic") {
            toast.error("No value found for that method, please select another method.");
        } else if (value === 'Dynamic') {
            setItems(item);
            setShowPopup(true);
        } else {
            setCurrentItem(item);
            setDropdownValue(value);
    
            if (selectedCreatedIds.length > 1) {
                handleShowModal();
            } else {
                const totalCostAllocationValue = costMethodVal.reduce((acc, val) => acc + parseFloat(val.costallocationvalue), 0);
    
                const newArray = costMethodVal.map((val, ind) => {
                    const output = {};
                    let calculatedPercentage = 0;
    
                    if (totalCostAllocationValue !== 0) {
                        calculatedPercentage = (parseFloat(val.costallocationvalue) / totalCostAllocationValue) * 100;
                    }
    
                    output[`LOB${ind + 1}`] = val.lob;
                    output[`LOB${ind + 1}Percentage`] = calculatedPercentage.toFixed(2);
    
                    let amount = 0;
                    if (totalCostAllocationValue !== 0) {
                        amount = (parseFloat(item.ExpAmt) * calculatedPercentage) / 100;
                    }
    
                    output[`LOB${ind + 1}Amt`] = amount.toFixed(2);
    
                    return output;
                });
    
                plainObject = newArray.reduce((acc, obj) => ({ ...acc, ...obj }), {}); // Update plainObject with calculated values
    
                // Update Table Data
                const updatedTableData = tableData.map(row => {
                    if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
                        const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
                            .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
                        return { ...filteredObj, ...plainObject, costallocationmethod: value };
                    }
                    return row;
                });
    
                setTableData(updatedTableData);
    
                // Update Complete Data
                const updatedCompleteData = completeDataFromDB.map(row => {
                    if (row.createdid === item.createdid && row.ADLineRef === item.ADLineRef) {
                        const filteredObj = Object.keys(row).filter(key => !key.startsWith('LOB'))
                            .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
                        return { ...filteredObj, ...plainObject, costallocationmethod: value };
                    }
                    return row;
                });
    
                setCompleteDataFromDB(updatedCompleteData);
    
                const changedIDS = [...changed, item.createdid];
                setChanged(changedIDS);
            }
        }
    };
    
    
    const handleSubmit = () => {
        const {fromDate, toDate, company} = formData

        if(fromDate && toDate && company){
            setLoading(true)
            setTableData([])
            setChanged([])
            const id = companyDetails.filter(item => item.orgname === company)

            const companyid = id.map(item => item.orgid)

            const request = {
                fromDate,
                toDate, 
                company: companyid[0],
                userID: props.state.login.userData.USR_Name
            }

            Promise.all([
                postRequest(`api/edureka/getCostAllocationAndContribution`, request),
                postRequest(`api/edureka/getDistinctCAMForGivenPeriod`, request),
                postRequest(`api/edureka/getDistinctLOBs`, request)
            ]).then(async ([item, value, val]) => {
                const responseData = JSON.parse(item.res.data)
                console.log(responseData)
                const camData = JSON.parse(value.res.data)
                console.log(camData)

                console.log(JSON.parse(val.res.data))
                const responseLOB = JSON.parse(val.res.data)

                const distinctLOBs = responseLOB.map(item => item.lob)

                if(responseData.length && camData.length){
                    const transTypeList = await getFilteredArray(responseData, "transactiontype1")
                    const costAllocationMethodList = await getFilteredArray(camData, "costallocationmethod")
                    const ukcamethod = await getFilteredArrays(responseData, "costallocationmethod")
                    console.log(ukcamethod);
                    
                    const vendorNameList = await getFilteredArray(responseData, "sendername")
                    const ExpenseList = await getFilteredArray(responseData, "ledgername")
                    console.log("VNL",vendorNameList);
                    costAllocationMethodList.push("Dynamic")
                    costAllocationMethodList.push("Equal")

                    const one = [...costAllocationMethodList, ...ukcamethod]
                    console.log(one);
                    


                    // costAllocationMethodList.push("Dynamic")
                    // costAllocationMethodList.push("Equal")

                    const transitionedData = await processTransactions(responseData)

                    console.log(transitionedData)
                    console.log(transTypeList)
                    console.log(costAllocationMethodList)

                    setDropdownTransType(transTypeList)
                    setDropdownVendorName(vendorNameList)
                    setExpenseList(ExpenseList)
                    setukcam(ukcamethod)
                    setDropdownCAM([... new Set(one)])
                    setTableData(transitionedData)
                    setCompleteDataFromDB(transitionedData)
                    setLoading(false)
                    setCamValue(camData)
                    setLOB(distinctLOBs)
                } else {
                    setLoading(false)
                    toast.warning("No data found, please try some other combination")
                }
            }).catch(err => {
                console.log(err)
                toast.error("Oops something went wrong")
            })

        } else {
            toast.error("Please select all the required fields")
        }
    }

    useEffect(() => {
        let filteredData = [];

        // Check if dropdownTransType and completeDataFromDB are populated
        if (dropdownTransType.length > 0 && completeDataFromDB.length > 0 && dropdownVendorName.length > 0 && expenseList.length > 0) {
            // Use the selected transaction type from formData
            let transactionType = formData.transType ? [formData.transType] : dropdownTransType;
            
            // Use the selected vendor name from formData, or include all if not set
            let vendorName = formData.vendorName ? [formData.vendorName] : dropdownVendorName;

            let expense = formData.expen ? [formData.expen] : expenseList;

            // Filter the data based on transaction type and vendor name
            filteredData = completeDataFromDB.filter(item =>
                transactionType.includes(item.transactiontype1) && vendorName.includes(item.sendername) && expense.includes(item.ledgername)
            );

            // Update table data
            setTableData(filteredData);
            setTableDataCheck(filteredData);
        }

    }, [formData.transType, formData.vendorName, dropdownTransType, completeDataFromDB, dropdownVendorName]);

    async function processTransactions(transactions) {
        return transactions.map(transaction => {
          const output = {};
          const lobOrder = []
      
          if (transaction.LOBAllocList) {
            const LOBParts = transaction.LOBAllocList.split(',');
            
            LOBParts.forEach(part => {
              const [lob, amount] = part.split('(');
              const lobKey = lob.trim();
              let amtValue = parseFloat(amount.replace(/[^\d.-]/g, ''));
                
                const lobIndex = lobOrder.length + 1;
                lobOrder.push(lobKey);

                output[`LOB${lobIndex}`] = lobKey;
                output[`LOB${lobIndex}Amt`] = amtValue
            });
          }

          lobOrder.forEach((lob, index) => {
            output[`LOB${index + 1}`] = output[`LOB${index + 1}`] || lob;
            output[`LOB${index + 1}Amt`] = output[`LOB${index + 1}Amt`] || 0;
          });
      
          return {
            ...transaction,
            ...output
          };
        });
    }

    const handleUpdate = () => {
        // const data = [...completeDataFromDB];
    
        // // Use a Set to track unique createdids
        // const uniqueCreatedIds = new Set(selectedCreatedIds);
        // console.log(uniqueCreatedIds);
        
    
        // // Filter all rows that match the selected created ids and update their corresponding rows
        // const rowsToUpdate = tableData.filter(row => uniqueCreatedIds.has(row.createdid));
    
        // console.log('Updating rows: ', rowsToUpdate);

        const data = [...completeDataFromDB];

        // Use a Set to track unique createdids
        const uniqueCreatedIds = new Set(selectedCreatedIds);
        console.log(uniqueCreatedIds);

        // Filter all rows that match the selected created ids and update their corresponding rows
        let rowsToUpdate = tableData.filter(row => uniqueCreatedIds.has(row.createdid));

        // Clean the filtered rows by removing empty LOB keys
        rowsToUpdate = rowsToUpdate.map(row => {
            const cleanedRow = { ...row };

            // Remove empty LOB keys
            Object.keys(cleanedRow).forEach(key => {
                if (key.startsWith('LOB') && (cleanedRow[key] === '' || cleanedRow[key] === null)) {
                    delete cleanedRow[key];
                }
            });

            return cleanedRow;
        });

        console.log('Updating rows: ', rowsToUpdate);
    
        // Aggregate unique values for ledgername, accounttype, and ADLineRef
        const ledgernames = new Set();
        const accounttypes = new Set();
        const ADLineRefs = new Set();
        const transactionTypes = new Set(); // Collect unique transaction types
    
        rowsToUpdate.forEach(row => {
            if (row.ledgername) ledgernames.add(row.ledgername);
            if (row.accounttype) accounttypes.add(row.accounttype);
            if (row.ADLineRef) ADLineRefs.add(row.ADLineRef);
            if (row.transactiontype1) transactionTypes.add(row.transactiontype1);
        });
    
        const aggregatedLedgernames = Array.from(ledgernames).join(', ');
        const aggregatedAccounttypes = Array.from(accounttypes).join(', ');
        const aggregatedADLineRefs = Array.from(ADLineRefs).join(', ');
    
        const id = companyDetails.find(item => item.orgname === formData.company);
        const companyid = id ? id.orgid : "";
    
        // Prepare the base request object without transaction type
        const baseRequest = {
            company: companyid,
            userID: props.state.login.userData.USR_Name,
            ledgernames: aggregatedLedgernames,
            accounttypes: aggregatedAccounttypes,
            ADLineRefs: aggregatedADLineRefs
        };
    
        setShowSpinner(true);
    
        // Function to make the API call
        const makeApiCall = (transtype, rows) => {
            const request = { ...baseRequest, transtype, data: rows };
            return postRequest(`api/edureka/updateCAMAllocation`, request);
        };
    
        // Iterate over unique transaction types and make API calls with relevant rows
        const updateTransactionTypes = async () => {
            const uniqueTransactionTypes = Array.from(transactionTypes);
    
            for (const transtype of uniqueTransactionTypes) {
                // Filter rows relevant to the current transaction type
                const relevantRows = rowsToUpdate.filter(row => row.transactiontype1 === transtype);
    
                if (relevantRows.length > 0) { // Only make the API call if there are relevant rows
                    try {
                        const response = await makeApiCall(transtype, relevantRows);
                        console.log(response.res.data);
                    } catch (err) {
                        console.error("Error updating transaction type:", transtype, err);
                        toast.error(`Error updating transaction type: ${transtype}`);
                    }
                }
            }
    
            toast.success("Records updated successfully, please press submit to know the updated value");
            setShowSpinner(false);
            setChanged([]);
            setFormData(prev => ({
                ...prev
                // transType: "",
                // vendorName: ""
            }));
            setSelectedCreatedIds([]);
        };
    
        updateTransactionTypes();
    };
    
  

    const handleCostAllocationFilterChange = (event) => {
        const { value } = event.target;
    
        setFormData((prevState) => ({
            ...prevState,
            costAllocationFilter: value === "Select" ? null : value
        }));
    };
    
    
    const handleApplyChanges = (applyToAll) => {
        const value = dropdownValue;
        console.log("Selected dropdown value:", value);
        console.log("Original Data (DD):", DD);
        
        // Filter the data based on the selected dropdown value
        const costMethodVal = DD.filter(cam => cam.costallocationmethod === value);
        console.log("Filtered cost allocation method values:", costMethodVal);
        
        // Ensure all entries in costMethodVal have a valid costallocationvalue
        const totalCostAllocationValue = costMethodVal
            .filter(val => val.costallocationvalue != null && val.costallocationvalue !== "")
            .reduce((acc, val) => {
                // Log each step to troubleshoot NaN issues
                console.log("Current costallocationvalue:", val.costallocationvalue);
                console.log("Accumulator before addition:", acc);
                return acc + parseFloat(val.costallocationvalue);
            }, 0);
        
        console.log("Final totalCostAllocationValue:", totalCostAllocationValue);
        
        
    
        const updateRow = (row) => {
            // Use the current row's ExpAmt
            const rowExpAmt = row.ExpAmt;
            console.log(rowExpAmt);
            
            const newArray = costMethodVal.map((val, ind) => {
                const output = {};
                let calculatedPercentage = 0;
                if (totalCostAllocationValue !== 0) {
                    calculatedPercentage = (parseFloat(val.costallocationvalue) / totalCostAllocationValue) * 100;
                }
                output[`LOB${ind + 1}`] = val.lob;
                output[`LOB${ind + 1}Percentage`] = calculatedPercentage.toFixed(2);
                let amount = 0;
                if (totalCostAllocationValue !== 0) {
                    amount = (parseFloat(rowExpAmt) * calculatedPercentage) / 100;  // Use the current row's ExpAmt here
                }
                output[`LOB${ind + 1}Amt`] = amount.toFixed(2);
                return output;
            });
            console.log(newArray);
    
            const plainObject = newArray.reduce((acc, obj) => ({ ...acc, ...obj }), {});
            const filteredObj = Object.keys(row)
                .filter(key => !key.startsWith('LOB'))
                .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
    
            return { ...filteredObj, ...plainObject, costallocationmethod: value };
        };
    
        let updatedTableData;
        if (applyToAll) {
            // Update all selected rows with their own ExpAmt
            updatedTableData = tableData.map(row => {
                if (selectedCreatedIds.includes(row.createdid)) {
                    return updateRow(row);  // Each row gets its own ExpAmt considered
                }
                return row;
            });
        } else {
            // Update only the current row
            updatedTableData = tableData.map(row => {
                if (row.createdid === currentItem.createdid ) {
                    return updateRow(row);  // Only update the selected row
                }
                return row;
            });
        }
    
        setTableData(updatedTableData);
        
        // Update the complete data set as well
        const updatedCompleteData = completeDataFromDB.map(row => {
            if (applyToAll && selectedCreatedIds.includes(row.createdid) ) {
                return updateRow(row);
            } else if (!applyToAll && row.createdid === currentItem.createdid ) {
                return updateRow(row);
            }
            return row;
        });
    
        setCompleteDataFromDB(updatedCompleteData);
    
        // Mark rows as changed
        const changedIDS = applyToAll
            ? [...changed, ...selectedCreatedIds]
            : [...changed, currentItem.createdid];
        setChanged(changedIDS);
    
        // Close the modal
        handleCloseModal();
    };
      
     
  return (
    <>
        <form>
            <div className='row'>
                <div className='col-md-2'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                            <option value='' disabled>Select</option>
                            {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className="col-md-2">
                    <SelectMonth label="Month" name="month" options={dropdown_month} value={month} onChange={handleMonthChange} />
                </div>

                <div className="col-md-2">
                    <Select label="Year" name="year" options={dropdown_year} value={year} onChange={handleYearChange} />
                </div>

{/* Transaction Type Dropdown */}
{dropdownTransType.length ? (
    <div className='col-md-2'>
        <Form.Group>
            <Form.Label>Transaction</Form.Label>
            <select className='spacing' value={formData.transType} name='transType' onChange={handleFilterChange}>
                <option value="">Select</option>
                {dropdownTransType.sort().map((item, index) => (
                    <option key={index} value={item}>
                        {item}
                    </option>
                ))}
            </select>
        </Form.Group>
    </div>
) : null}

{/* Vendor Name Dropdown */}
{dropdownVendorName.length ? (
    <div className='col-md-2'>
        <Form.Group>
            <Form.Label>Vendor/Party Name</Form.Label>
            <select className='spacing' value={formData.vendorName} name='vendorName' onChange={handleFilterChange}>
                <option value="">Select</option>
                {dropdownVendorName.sort().map((item, index) => (
                    <option key={index} value={item}>
                        {item}
                    </option>
                ))}
            </select>
        </Form.Group>
    </div>
) : null}


                {/* New Dropdown for Cost Allocation Method */}
                {dropdownVendorName.length ? (
                    <div className='col-md-2'>
                        <Form.Group>
                            <Form.Label>CA Method</Form.Label>
                            <select
                                className='spacing'
                                value={formData.costAllocationFilter ?? "Select"} // "Select" for default option
                                name='costAllocationFilter'
                                onChange={handleCostAllocationFilterChange}
                            >
                                <option value="Select">Select</option> {/* Shows all rows */}
                                <option value="">Blank</option> {/* Shows rows with empty values */}
                                {availableCAMOptions.sort().map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>
                    </div>
                ) : null}

{/* Expense Ledger Dropdown */}
{expenseList.length ? (
    <div className='col-md-2'>
        <Form.Group>
            <Form.Label>Expense Ledger</Form.Label>
            <select className='spacing' value={formData.expen} name='expen' onChange={handleFilterChange}>
                <option value="">Select</option>
                {expenseList.sort().map((item, index) => (
                    <option key={index} value={item}>
                        {item}
                    </option>
                ))}
            </select>
        </Form.Group>
    </div>
) : null}

            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
                <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
                <Button style={{marginLeft:'10px'}} size='sm' variant='danger' onClick={handleReset}>Reset</Button>
                {tableData.length ?
                        <CSVLink data={tableData} filename='Cost_Allocation_Report.csv' style={{ marginLeft: '10px' }}>Download</CSVLink> : null}

                    {selectedCreatedIds.length > 0 && (
                        <Button style={{ marginLeft: '10px' }} size='sm' variant='primary' onClick={handleUpdate}>
                            Update
                        </Button>)}


                {showSpinner ?
                <Button style={{marginLeft:'10px'}} variant="warning" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                <span className="visually-hidden">Loading...</span>
                </Button> : null}
            </div>
        </form>

        <br/>

        {loading ? <div className='center'>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
              </div>: null}

        <ToastContainer/>
        {showPopup ?
        <CostContributionPopup
        mode="amount"  //mbp
        totalValue = {poop}
        // totalValue ={tableData.map((item)=>(item.ExpAmt))}
        
           lobs={LOB}
            handleClose = {() => {
                setShowPopup(false)

            }}

            // handleData = {(prop) => {
            //         console.log(prop)
            //         setShowPopup(false)

            //         const data = [...tableData]
            //         const completeData = [...completeDataFromDB]

            //         let newArray

            //         newArray = prop.map((val, ind) => {
            //             const output = {}

            //             output[`LOB${ind+1}`] = val.lob
            //             output[`LOB${ind +1}Percentage`] = val.amountPercentage

            //             const amount = parseFloat(items.ExpAmt) * parseFloat(val.amountPercentage) /100

            //             output[`LOB${ind +1}Amt`] = amount.toFixed(2)

            //             console.log(output)

            //             return output
            //         })

            //         const plainObject = newArray.reduce((acc, obj) => {
            //             return {...acc, ...obj}
            //         }, {})

            //         const objIndex1 = data.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)
            //         const objIndex2 = completeData.findIndex(obj => obj.createdid === items.createdid && obj.accountingdocnumber === items.accountingdocnumber)

            //         const update1 = [items].map(obj => {
    
            //             const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
            //             .reduce((acc, key) => {
            //                 acc[key] = obj[key]
            //                 return acc
            //             }, {})
            
            //             const rest = {...filteredObj, ...plainObject}
            //             rest["costallocationmethod"] = "Dynamic"
            //             return rest
            //         })
            
            //         const updatedObj1 = {...update1[0]}
            
            //         const updatedArray1 = [...data.slice(0, objIndex1), updatedObj1, ...data.slice(objIndex1 + 1)]
            
            //         console.log(updatedArray1)
            
            //         //[completeData[objIndex2]]
            //         const update2 = [items].map(obj => {
            
            //             const filteredObj = Object.keys(obj).filter(key => !key.startsWith('LOB'))
            //             .reduce((acc, key) => {
            //                 acc[key] = obj[key]
            //                 return acc
            //             }, {})
            
            //             const rest = {...filteredObj, ...plainObject}
            //             rest["costallocationmethod"] = "Dynamic"
            //             return rest
            //         })
            
            //         const updatedObj2 = {...update2[0]}
            
            //         const updatedArray2 = [...completeData.slice(0, objIndex2), updatedObj2, ...completeData.slice(objIndex2 +1)]
            
            //         setTableData(updatedArray1)
            //         setCompleteDataFromDB(updatedArray2)
                    
            //         const changedIDS = [...changed]
            //         changedIDS.push(items.createdid)
            //         console.log(changedIDS)
            //         setChanged(changedIDS)
            //         console.log(update1)

            //     }
            // } 
            handleData={(prop) => {
                console.log(prop);
                setShowPopup(false);
            
                const data = [...tableData];
                const completeData = [...completeDataFromDB];
            
                // Clear previous LOB allocations for the specific row
                const updatedTableData = data.map(row => {
                    if (row.createdid === items.createdid && row.accountingdocnumber === items.accountingdocnumber) {
                        // Clear LOB fields before updating
                        for (let i = 1; i <= 4; i++) {
                            row[`LOB${i}`] = '';
                            row[`LOB${i}Amt`] = '';
                            row[`LOB${i}Percentage`] = '';
                        }
                    }
                    return row;
                });
            
                let newArray = prop.map((val, ind) => {
                    const output = {};
            
                    output[`LOB${ind + 1}`] = val.lob;
                    output[`LOB${ind + 1}Percentage`] = val.amountPercentage;
            
                    const amount = parseFloat(items.ExpAmt) * parseFloat(val.amountPercentage) / 100;
                    output[`LOB${ind + 1}Amt`] = amount.toFixed(2);
            
                    return output;
                });
            
                const dynamicFormat = newArray.map((lobData, index) => {
                    const lobName = lobData[`LOB${index + 1}`];
                    const lobAmount = lobData[`LOB${index + 1}Amt`];
                    const lobPercentage = lobData[`LOB${index + 1}Percentage`];
                    return `${lobName}:${lobAmount} (${lobPercentage}%)`;
                }).join(', ');
            
                const dynamicString = `DYNAMIC(${dynamicFormat})`;
                console.log(dynamicString);
            
                if (!dropdownCAM.includes(dynamicString)) {
                    setDropdownCAM(prev => [...prev, dynamicString]);
                }
            
                const plainObject = newArray.reduce((acc, obj) => ({ ...acc, ...obj }), {});
            
                const updatedData = updatedTableData.map(row => {
                    if (row.createdid === items.createdid && row.accountingdocnumber === items.accountingdocnumber) {
                        return { ...row, costallocationmethod: dynamicString, ...plainObject };
                    }
                    return row;
                });
            
                setTableData(updatedData);
                setCompleteDataFromDB(updatedData);
            
                const changedIDS = [...changed];
                if (!changedIDS.includes(items.createdid)) {
                    changedIDS.push(items.createdid);
                }
                setChanged(changedIDS);
            }}
                

        /> : null}

        {!loading && tableData.length ? (
            <div>
                <table border="1" id="table-css">
                    <thead>
                        <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={tableData.length && selectedCreatedIds.length === tableData.length}
                                        onChange={handleSelectAllRows}
                                    />
                                    Select All
                                </th>
                                <th>Transaction Type</th>
                            <th>Document Number</th>
                            <th>Accounting Journal</th>
                            <th style={{ minWidth: '20px', width:'20px'  }}>Ac Date</th>
                            <th>For Prd From</th>
                            <th>For Prd To</th>
                            <th>Party Name</th>
                            <th>Account Class/Ledger Name</th>
                            <th>ItemID</th>
                            <th>AccountType</th>
                            <th style={{ minWidth: '20px', width:'20px'  }}>DocumentRef</th>
                            <th style={{width:'210px'}}>Cost Allocation Method</th>
                            <th>Amount in Local Currency</th>
                            <th>LOB1</th>
                            <th>Amt</th>
                            <th>LOB2</th>
                            <th>Amt</th>
                            <th>LOB3</th>
                            <th>Amt</th>
                            <th>LOB4</th>
                            <th>Amt</th>
                            <th>LOB5</th>
                            <th>Amt</th>
                            <th>LOB6</th>
                            <th>Amt</th>
                            <th>LOB7</th>
                            <th>Amt</th>
                            <th>LOB8</th>
                            <th>Amt</th>
                            <th>LOB9</th>
                            <th>Amt</th>
                            <th>LOB10</th>
                            <th>Amt</th>
                        </tr>
                    </thead>

                    <tbody>
                        {tableData
                            .filter((item) => {
                                // Show all rows if "Select" (null) is chosen
                                if (formData.costAllocationFilter === null) {
                                    return true;
                                }
                    
                                // Show only rows with an empty `costallocationmethod` if "Blank" option is chosen
                                if (formData.costAllocationFilter === '') {
                                    return !item.costallocationmethod;
                                }
                    
                                // Show only rows matching the selected `costallocationmethod`
                                return item.costallocationmethod === formData.costAllocationFilter;
                            })
                            .sort((a, b) => a.createdid.localeCompare(b.createdid))
                            .map((item, index) => {
                                const isHighlighted = selectedCreatedIds.includes(item.createdid);                            
                                const isChecked = selectedRows.includes(item.createdid);
                                return(
                                    <tr key={index} className={isHighlighted ? 'highlight' : ''}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCreatedIds.includes(item.createdid)}
                                                    onChange={() => handleRowSelect(item.createdid)}
                                                />
                                            </td>
                                        <td>{item.transactiontype1}</td>
                                        <td>{item.createdid}</td>
                                        <td>{item.accountingdocnumber}</td>
                                        <td style={{ minWidth: '20px', width:'20px'  }}>{getPreviousDate( item.accountingdate)}</td>
                                        <td>{item.forprdfrom}</td>
                                        <td>{item.forprdto}</td>
                                        <td>{item.sendername}</td>
                                        <td>{item.ledgername}</td>
                                        <td>{item.itemid}</td>
                                        <td>{item.accounttype}</td>
                                        <td style={{ minWidth: '20px', width:'20px'  }}>{item.documentref}</td>
                                        <td style={{ minWidth: '280px' }}>
                                            <div>
                                            <select
                                                className="spacing"
                                                value={item.costallocationmethod || ''}
                                                name="costallocationmethod"
                                                onFocus={() => handleTableCAMChange(item)}  // Filter CAM based on forprdfrom and forprdto when dropdown opens
                                                onChange={(e) => handleDropdownChange(item, e)}  // Handle selected value from dropdown
                                            >
                                                <option value="">Select</option>
                                                {dropdownCAM
                                                    .sort()
                                                    .map((camItem, camIndex) => (
                                                        <option key={camIndex} value={camItem}>
                                                            {camItem}
                                                        </option>
                                                    ))}
                                            </select>   
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                            {Number(item.ExpAmt ? item.ExpAmt : 0).toFixed(2)}
                                        </td>
                                        <td>{item.LOB1}</td>
                                        <td>{item.LOB1Amt}</td>
                                        <td>{item.LOB2}</td>
                                        <td>{item.LOB2Amt}</td>
                                        <td>{item.LOB3}</td>
                                        <td>{item.LOB3Amt}</td>
                                        <td>{item.LOB4}</td>
                                        <td>{item.LOB4Amt}</td>
                                        <td>{item.LOB5}</td>
                                        <td>{item.LOB5Amt}</td>
                                        <td>{item.LOB6}</td>
                                        <td>{item.LOB6Amt}</td>
                                        <td>{item.LOB7}</td>
                                        <td>{item.LOB7Amt}</td>
                                        <td>{item.LOB8}</td>
                                        <td>{item.LOB8Amt}</td>
                                        <td>{item.LOB9}</td>
                                        <td>{item.LOB9Amt}</td>
                                        <td>{item.LOB10}</td>
                                        <td>{item.LOB10Amt}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        ) : null}

<Modal show={showDateModal} onHide={() => setShowDateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Dates for Cost Allocation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>For Prd From</Form.Label>
                            <Form.Control
                                type="date"
                                value={tempForPrdFrom}
                                onChange={(e) => setTempForPrdFrom(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>For Prd To</Form.Label>
                            <Form.Control
                                type="date"
                                value={tempForPrdTo}
                                onChange={(e) => setTempForPrdTo(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSaveDates}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                show={showConfirmModal}
                handleClose={handleCloseModal}
                handleYes={() => handleApplyChanges(true)} // Apply to all rows
                handleNo={() => handleApplyChanges(false)} // Apply to only this row
            />
        </>
    )
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps) (CostContribution)
