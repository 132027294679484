import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai';
import './NewTable.css'

class Selector1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selected: props.defaultValue || "",
      open: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value.toLowerCase() });
  };

  handleOptionSelect = (selectedValue) => {
    console.log(selectedValue)
    const { onChange } = this.props;
    this.setState({ selected: selectedValue, open: false, inputValue: "" });
    if (onChange) {
      onChange(selectedValue);
    }
  };

  componentDidMount(){
    console.log(this.props.options)
  }

  render() {
    const { options } = this.props;
    const { inputValue, selected, open } = this.state;

    return (
        
        <div className="div-container1">
            <div
            onClick={() => this.setState({ open: !open })}
            className={`div-container2 ${!selected && "div-container22"}`}
            >
            {selected
                ? selected?.length > 25
                ? selected?.substring(0, 25) + "..."
                : selected
                : "Select Option"}
            <BiChevronDown
                size={20}
                className={`${open && "bichevron-rotate"}`}
            />
            </div>
            <ul className={`ul-container ${open ? "ul-maxheight" : "ul-minheight"}`}>
            <div className="div-container3">
                <AiOutlineSearch size={18} className="aioutlinesearch" />
                <input
                type="text"
                value={inputValue}
                onChange={this.handleInputChange}
                placeholder="Search options..."
                className="placeholder-input select-input"
                />
            </div>
            {options.sort().map((option) => (
                <li
                key={option}
                className={`li-container
                    ${
                    option.toLowerCase() === selected?.toLowerCase() &&
                    "li-container-special"
                    }
                    ${
                    option.toLowerCase().includes(inputValue.toLowerCase())
                        ? "li-block"
                        : "li-hidden"
                    }`}
                onClick={() => this.handleOptionSelect(option)}
                >
                {option}
                </li>
            ))}
            </ul>
        </div>
    );
  }
}

export default Selector1;
