import React, { useState, useEffect, useRef } from 'react'
import { getRequest, postRequest } from '../../../globalhelper/helper'
import _, { set } from 'lodash';
import { FaToggleOn, FaToggleOff } from "react-icons/fa";


const BillExtract = ["Bill", "SubseqDr", "SubseqCr"]

const GoodsAndServicesEntries = ["GoodsReceipt", "ServiceReceipt", "ReversalGoodsReceipt", "ReversalServiceReceipt", "ReturnGoodsReceipt", "ReturnServiceReceipt", "GoodsIssue", "ServiceIssue", "InternalTransferReceipt", "InternalTransferIssue", "GoodsReceiptDirectCapitalization", "GoodsReceiptCWIP", "GoodsConsumption"]

const BillExtract_GoodsAndServicesEntries = {
    "Bill": "close",
    "SubseqDr": "close",
    "SubseqCr": "close",
    "GoodsReceipt": "close",
    "ServiceReceipt": "close",
    "ReversalGoodsReceipt": "close",
    "ReversalServiceReceipt": "close",
    "ReturnGoodsReceipt": "close",
    "ReturnServiceReceipt": "close",
    "GoodsIssue": "close",
    "ServiceIssue": "close",
    "InternalTransferReceipt": "close",
    "InternalTransferIssue": "close",
    "GoodsReceiptDirectCapitalization": "close",
    "GoodsReceiptCWIP": "close",
    "GoodsConsumption": "close"
}


function Book_Closure() {

    const [ Companies, setCompanies] = useState([])
    const [ Company, setCompany] = useState("")
    
    const [ Years, setYears] = useState([])
    const [ Year, setYear] = useState("")
    const [ Months, setMonths] = useState([])
    const [ Month, setMonth] = useState("")

    const [ DisplayRecord, setDisplayRecord] = useState(null)
    const [ warn, set_warn] = useState(null)

    useEffect(  () => {

        getRequest(`api/veranda/getCompany0`)
        .then( res => {

            if(res.type === "success"){
                
                let data = JSON.parse(res.res.data)

                data = _.sortBy(data, "orgname")

                console.log(data)

                setCompanies(data)
            }
        })

        // Get the current date
        const currentDate = new Date();

        // Get the current year
        const currentYear = currentDate.getFullYear();

        // Calculate the last three years
        const lastThreeYears = [currentYear - 2, currentYear - 1, currentYear].reverse();
        setYears(lastThreeYears)


        // Create an array to store the month strings
        const monthStrings = [];

        // Loop through the last 12 months
        for (let i = 0; i < 12; i++) {
        // Calculate the month for the current iteration
        const currentMonth = (currentDate.getMonth() - i + 12) % 12 + 1;

        // Convert the month to a two-digit string (e.g., "01", "02")
        const monthString = currentMonth < 10 ? "0" + currentMonth : "" + currentMonth;

        // Add the month string to the array
        monthStrings.push(monthString);
        }

        setMonths(monthStrings)

    }, [])


    const getBookClosureRecord = () => {

        set_warn(null)
        setDisplayRecord(null)

        getRequest(`api/vr2/getBookClosureRecord?Company=${Company}&Year=${Year}&Month=${Month}`)
        .then( res => {

            if(res.res.status === "success"){
                let DATA = res.res.data
                console.log(DATA)
                set_warn("Modifying the existing")
                setDisplayRecord(DATA)
            }
            else{

                set_warn("Creating New")
               
                setDisplayRecord(BillExtract_GoodsAndServicesEntries)
            }
        })
    }


    const onStatusChange = ( val, key) => {

        let DATA = JSON.parse(JSON.stringify(DisplayRecord))

        DATA[key] = val
        
        console.log(DATA)
        setDisplayRecord(DATA)
    }


    const saveBookClosureRecord = () => {

        postRequest(`api/vr2/saveBookClosureRecord?Company=${Company}&Year=${Year}&Month=${Month}`, DisplayRecord)
        .then( res => {

            if(res.res.status === "success"){
                console.log(res.res)
                Reset()
            }
            else{
                console.log(res.res)
            }
        })
    }

    const Reset = () => {

        setYear("")
        setMonth("")
        set_warn(null)
        setDisplayRecord(null)
    }



  return (
    <div>
        
        <div className="row mt-2">

            <div className='col-md-3'>
                <label htmlFor="Company" style={{fontSize: '1rem'}}> Company </label>
                <select className="browser-default custom-select" disabled={DisplayRecord} id="Company" autoFocus name="Company" value={Company} onChange={e => setCompany(e.target.value)} >
                    <option value=""> select </option>
                    { Companies.map((item, key) => 
                        <option key={key} value={item.orgid}> {`${item.orgname}(${item.orgid})`} </option>
                    )}
                </select>
            </div>

            <div className='col-md-2'>
                <label htmlFor="Year" style={{fontSize: '1rem'}}> Year </label>
                <select className="browser-default custom-select" disabled={DisplayRecord} id="Year" name="Year" value={Year} onChange={e => setYear(e.target.value)} >
                    <option value=""> select </option>
                    { Years.map((item, key) => 
                        <option key={key} value={item}> {item} </option>
                    )}
                </select>
            </div>

            <div className='col-md-2'>
                <label htmlFor="Month" style={{fontSize: '1rem'}}> Month </label>
                <select className="browser-default custom-select" disabled={DisplayRecord} id="Month" name="Month" value={Month} onChange={e => setMonth(e.target.value)} >
                    <option value=""> select </option>
                    { Months.map((item, key) => 
                        <option key={key} value={item}> {item} </option>
                    )}
                </select>
            </div>

            <div className='col-md-2 mt-4'>
                <button className='btn btn-primary' disabled={!Company || !Year || !Month} onClick={getBookClosureRecord}>Get</button>
                <button className='ml-2 btn btn-danger' onClick={Reset}>Reset</button>
            </div>

        </div>

        {
            DisplayRecord &&
        <div className='mt-5'>
            { warn && 
            <div style={{color: 'red'}}>{warn}</div> }
            <div className={`scroll-table`} style={{width: '700px'}}>
            <table id="table-css" >
                <thead>
                    <tr>
                        <th>ScreenName</th>
                        <th>DocumentType</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(DisplayRecord).map( each => (
                        <tr>
                            <td>{BillExtract.includes(each) ? "BillExtract" : GoodsAndServicesEntries.includes(each) ? "GoodsAndServicesEntries" : null}</td>
                            <td>{each}</td>
                            <td>
                                <button onClick={(e) => onStatusChange(DisplayRecord[each] === "open" ? "close" : "open", each)}
                                style = {{
                                        margin: '0 0 0 1.4rem',
                                        padding: '0',
                                        background: 'none',
                                        border: 'none',
                                        fontSize: '1.3rem',
                                        color: `${DisplayRecord[each] === "open" ? 'green' : DisplayRecord[each] === "close" ? 'grey' : null }`
                                    }}
                                >
                                    {DisplayRecord[each] === "open" ? <FaToggleOn /> : DisplayRecord[each] === "close" ? <FaToggleOff /> : null }
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>

            <div align='center'>
                <button className='btn btn-success mt-4' onClick={saveBookClosureRecord}>Save</button>
            </div>

        </div>

        }


    </div>
  )
}

export default Book_Closure;