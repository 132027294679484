
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { Accordion } from 'react-bootstrap';
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { TbArrowBackUp } from 'react-icons/tb';
import { connect } from 'react-redux';
import { fetchTreeData, handleTreeChange, Set_ORG_ID_CONST, update_dependencyJson, update_one_side_PDF_Key, updateStoreVals } from '../../actions/vr2Action';
import Spinner from '../../components/BackDrop_Spinner';
import Popup from '../../components/SimplePOPUP';
import { getRequest, postRequest } from '../../globalhelper/helper';
import MyForm from './form';
import MyTable from './mytable';
import './vr2FormRendering.css';


class DisplayContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {

            fnToCallonVendorIDonChange: null,
            showSpinner : false,
            render : false,
            time : null,
            canQuit : true,
            showScrollButton: true,
            OldAccountingDate: ""
        }
        
        this.token = this.props.screenName
    }


    componentDidMount () {

        window.addEventListener('scroll', this.handleScroll);

        console.log(this.token)

        let screenAction = this.props.screenAction === "PartialCreate" ? "create" : this.props.screenAction

        localStorage.setItem("pageName", screenAction.charAt(0).toUpperCase() + screenAction.slice(1) + ' ' + this.token)

        this.props.fetchTreeData()

        if(this.props.screenAction === "create"){

            getRequest(`api/vr2/get_screen_json_repository?module=${localStorage.getItem("module")}&screenName=${this.token}` )
            .then( res => {
                if( res.res.status === "success"){
                    console.log(res.res.data)
    
                    this.props.handleTreeChange(res.res.data)
                    this.store_all_vals(res.res.data)
                    // this.props.handleTreeChange(j_son)
                    // this.store_all_vals(j_son)

                    if(this.token === "BillExtract"){
                        this.Store_ORG_ID("Organization ID")
                        this.putDefaultValue_BillExtract( "DocumentType", "Bill")
                        this.putDefaultValue_BillExtract( "ExchangeRate", "1")
                        this.putDefaultValue_BillExtract_copyFrom( "Accounting Date", "Invoice Date" )
                    }
                    if(this.token === "GoodsAndServicesEntries"){

                        this.Store_ORG_ID("Receiver ID")
                    }
    
                    getRequest(`api/vr2/get_screen_dependency_json?module=${localStorage.getItem("module")}&screenName=${this.token}`)
                    .then( res => {
            
                        if( res.res.status === "success"){
                            console.log(res.res.data)

                            this.props.update_dependencyJson(res.res.data)
                            // this.props.update_dependencyJson([])
    
                            this.setState({ render : true })
                        }
                    })
                }
            })
        }


        if(this.props.screenAction === "edit"  || this.props.screenAction === "approval" || this.props.screenAction === "PartialCreate" ){

            this.props.handleTreeChange(this.props.From_SearchJSON)
            this.store_all_vals(this.props.From_SearchJSON)

            this.props.noQuit && this.rerender("noQuit")

            setTimeout( async()=>{

                if(this.token === "BillExtract"){
    
                    this.getTDSAsExp( "CIN Number", "select")
                    this.getTDSAsExp( "GSTReference", "GSTasExp")
                    this.getTDSAsExp( "TDSReference", "TDSasExp")
                    
                    this.getTDSAsExp( "PO/IP Type", "showSelect", true)
                    
                    this.Store_ORG_ID("Organization ID")
                    this.putDefaultValue_BillExtract( "DocumentType", "Bill" )
                    this.putDefaultValue_BillExtract( "ExchangeRate", "1" )
                    this.putDefaultValue_BillExtract_copyFrom( "Accounting Date", "Invoice Date" )

                    await this.Give_Val_By_ServiceKey("Accounting Date")
                    let OldAccountingDate = this.state.Give_Val_By_ServiceKey["Accounting Date"]
                    this.setState({OldAccountingDate})
                }
                if(this.token === "GoodsAndServicesEntries"){

                    this.Store_ORG_ID("Receiver ID")
                    this.getTDSAsExp( "DeductionRef", "DeductTDS")

                    await this.Give_Val_By_ServiceKey("Accounting Date")
                    let OldAccountingDate = this.state.Give_Val_By_ServiceKey["Accounting Date"]
                    this.setState({OldAccountingDate})
                }
            }, 500)

            getRequest(`api/vr2/get_screen_dependency_json?module=${localStorage.getItem("module")}&screenName=${this.token}`)
            .then( res => {
    
                if( res.res.status === "success"){
                    console.log(res.res.data)

                    this.props.update_dependencyJson(res.res.data)
                    this.setState({ render : true })
                }
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    store_all_vals = (TREE) => {

        let VALS = this.props.storeVals ? this.props.storeVals : {}

        let TREE_ = TREE ? TREE : this.props.VR2_Form_Json

        TREE_.forEach( (each , i) => {

            if(each.children && each.children.length){
    
                each.children.forEach( (each2, j) => {
    
                    if(each2.children && each2.children.length){
    
                        each2.children.forEach( (each3, k) => {
    
                            if(!each3.children && each3.render === "true"){
    
                                VALS[each3.serviceID] = each3.val
                            }
    
                            if(each3.children && each3.children.length){
    
                                each3.children.forEach( (each4, m)=> {
    
                                    if(each4.render === "true"){
    
                                        VALS[each4.serviceID] = each4.val
                                    }
                                })
                            }
                        }) 
                    }
                })
            }
        })

        this.props.updateStoreVals(VALS)
    }



    onClick_Validate = async(event) => {
        event.preventDefault();
        console.log(this.props.storeVals)

        let found = false;

        //condiotional mandatory
        this.props.VR2_Form_Json.forEach( (each , i) => {

            if(found) return
    
            if(each.children && each.children.length){

                each.children.forEach( (each2, j) => {

                    if(found) return

                    if(each2.children && each2.children.length){

                        each2.children.forEach( (each3, k) => {

                            if(found) return

                            if(!each3.children && each3.mandatory && each3.mandatory === "true" && each3.render === "true" && each3.editable === "true" && !each3.val ){

                                console.log(each3.serviceID)
                                document.getElementById(each3.serviceID).focus();
                                this.TopErrAlert(`${each3.serviceKey} is mandatory`)
                                found = true
                                return 
                            }

                            if(!each3.children && each3.render === "true" && each3.editable === "true" && each3.val === "Invalid date"){

                                console.log(each3.serviceID)
                                document.getElementById(each3.serviceID).focus();
                                this.TopErrAlert(`${each3.serviceKey} is mandatory`)
                                found = true
                                return 
                            }

                            if(each3.conditional_mandatory) {

                                // console.log(each3)
                                // console.log(each3.conditional_mandatory)
                                // console.log(this.props.storeVals[each3.conditional_mandatory.serviceID])

                                if(
                                    each3.conditional_mandatory.val.includes(
                                        this.props.storeVals[each3.conditional_mandatory.serviceID]
                                        ) && each3.render === "true" && each3.editable === "true" && !each3.val 
                                ){
                                    console.log(each3.serviceID, '"helohelo321"')
                                    document.getElementById(each3.serviceID).focus();
                                    this.TopErrAlert(`${each3.serviceKey} is mandatory`)
                                    found = true
                                    return 
                                }
                            }

                            if(each3.children && each3.children.length){

                                each3.children.forEach( (each4, m)=> {

                                    if(each4.mandatory === "true" && each4.render === "true" && each4.editable === "true" && !each4.val){

                                        console.log(each4.serviceID)
                                        document.getElementById(each4.serviceID).focus();
                                        this.TopErrAlert(`${each4.serviceKey} is mandatory`)
                                        found = true
                                        return 
                                    }

                                    if(each4.render === "true" && each4.editable === "true" && each4.val === "Invalid date"){

                                        console.log(each4.serviceID)
                                        document.getElementById(each4.serviceID).focus();
                                        this.TopErrAlert(`${each4.serviceKey} is mandatory`)
                                        found = true
                                        return 
                                    }

                                    if(each4.conditional_mandatory) {

                                        if(
                                            each4.conditional_mandatory.val.includes(
                                                this.props.storeVals[each4.conditional_mandatory.serviceID]
                                            ) && each4.render === "true" && each4.editable === "true" && !each4.val
                                        ){
                                            console.log(each4.serviceID, '"helohelo123"')
                                            document.getElementById(each4.serviceID).focus();
                                            this.TopErrAlert(`${each4.serviceKey} is mandatory`)
                                            found = true
                                            return 
                                        }
                                    }
                                })
                            }
                        }) 
                    }
                })
            }
        })

        //checking forprdfrom and forprdto
        this.props.VR2_Form_Json.forEach( (each , i) => {

            if(found) return
    
            if(each.children && each.children.length){

                each.children.forEach( (each2, j) => {

                    if(found) return

                    if(each2.children && each2.children.length){

                        each2.children.forEach( (each3, row) => {

                            if(found) return

                            let from_dt;
                            let to_dt;

                            if(each3.children && each3.children.length){

                                each3.children.forEach( (each4, m)=> {

                                    if( each4.render === "true" && (each4.serviceKey === "ForPrdFrom" || each4.serviceKey === "ForPrdTo")){

                                        if(each4.serviceKey === "ForPrdFrom") from_dt = each4.val
                                        if(each4.serviceKey === "ForPrdTo") to_dt = each4.val

                                        if(from_dt && to_dt){

                                            if(this.isToDateAfterFromDate(from_dt, to_dt)) {
                                            } else {
                                                console.log(`Invalid ForPrdTo in Section "${each2.title}" Row no ${row+1}`);
                                                this.TopErrAlert(`Invalid ForPrdTo in Section "${each2.title}" Row no ${row+1}`)
                                                found = true
                                                return 
                                            }
                                        }
                                    }
                                })
                            }
                        }) 
                    }
                })
            }
        })

        if(this.token === "BillExtract"){

            // if PO/IP Type = PurchaseOrder then check all Qty is valid number or not
            await this.Give_Val_By_ServiceKey("PO/IP Type")
            let PO_IP_Type = this.state.Give_Val_By_ServiceKey["PO/IP Type"]
            console.log(PO_IP_Type)
            
            if(PO_IP_Type === "PurchaseOrder"){
                
                await this.Give_Val_By_ServiceKey("Qty")
                let Qty = this.state.Give_Val_By_ServiceKey["Qty"]
                console.log(Qty)

                function isNumber(value) {
                    // checking number != 0 and not a number
                    return !isNaN(value) && value != 0
                }

                const Valid = Qty.every((num) => isNumber(num));

                if(!Valid){

                    found = true
                    
                    this.TopErrAlert(`Please enter valid Qty in Billing Summary`)
                    return
                }

            }

            if(this.state[this.token] && this.state[this.token].showSelect && (this.state[this.token].showSelect === "GoodsReceipt" || this.state[this.token].showSelect === "ServiceReceipt")){

                if(this.state[this.token]&&this.state[this.token].select){
                }
                else{
                    document.getElementById("FullReversal_ProportionateReversal").focus();
                    found = true
                    return
                }
            }
        }

        if(found) return

        else{

            console.log('"hello Nothing from validate"')

            this.setState({ showSpinner : true}, 
            async () => {

                let Input =  {
                    TREE_JSON: this.props.VR2_Form_Json,
                    ORG_ID_CONST: this.props.ORG_ID_CONST
                }


                if(this.token === 'BillExtract'){

                    await this.Give_Val_By_ServiceKey("DocumentType")
                    let DocumentType = this.state.Give_Val_By_ServiceKey["DocumentType"]
                    await this.Give_Val_By_ServiceKey("Base Value")
                    let BaseValue = this.state.Give_Val_By_ServiceKey["Base Value"]
                    await this.Give_Val_By_ServiceKey("ExchangeRate")
                    let ExchangeRate = this.state.Give_Val_By_ServiceKey["ExchangeRate"]
                    await this.Give_Val_By_ServiceKey("Original Invoice Ref")
                    let OriginalInvoiceRef = this.state.Give_Val_By_ServiceKey["Original Invoice Ref"]

                    await this.Give_Val_By_ServiceKey("Invoice Ref")
                    let InvoiceRef = this.state.Give_Val_By_ServiceKey["Invoice Ref"]
                    await this.Give_Val_By_ServiceKey("Vendor ID")
                    let VendorID = this.state.Give_Val_By_ServiceKey["Vendor ID"]
                    await this.Give_Val_By_ServiceKey("Bill Number")
                    let BillNumber = this.state.Give_Val_By_ServiceKey["Bill Number"]
                
    
                    // BaseValue is a array ['543', '293.93'] - sum the all numbers
                    const sum = BaseValue.reduce((accumulator, currentValue) => {
                        const number = Number(currentValue);
                        return accumulator + number;
                    }, 0);
                    console.log(sum);

                    Input = {
                        ...Input,
                        DocumentType,
                        BaseValue : sum,
                        ExchangeRate,
                        OriginalInvoiceRef,
                        InvoiceRef,
                        VendorID,
                        BillNumber
                    }
                }

                if(this.token === 'VendorMaster'){

                    await this.Give_Val_By_ServiceKey("Vendor ID")
                    let VendorID = this.state.Give_Val_By_ServiceKey["Vendor ID"]
                    await this.Give_Val_By_ServiceKey("Vendor Name")
                    let VendorName = this.state.Give_Val_By_ServiceKey["Vendor Name"]
                    await this.Give_Val_By_ServiceKey("PAN")
                    let PAN = this.state.Give_Val_By_ServiceKey["PAN"]
    
                    Input = {
                        ...Input,
                        VendorID,
                        VendorName,
                        PAN
                    }
                }
            
                console.log(Input)

                postRequest(`api/vr2/validateDocuments?module=${localStorage.getItem("module")}&screenName=${this.token}`, Input )
                .then( res => {
    
                    this.setState({ showSpinner : false})

                    if( res.res.status === "success" && this.token === 'BillExtract'){
                        console.log(res.res.data)
                        
                        setTimeout(() => {
                            // window.location.reload()
                            this.props.onBack()
                        }, 1000);
                    }

                    else if( res.res.status === "success" ){
                        console.log(res.res.data)

                        this.props.handleTreeChange(res.res.data)
                    }

                    else if( res.res.status === "info"){

                        let ok = <Popup
                        noClose={true}
                        content={
                        <>
                        <div className="mt-3 mb-3" align="center">
                            <div>{res.res.data}</div>
                        </div>
        
                        <div align="center">
                            <button onClick={() => this.setState({confirmationToActivate: null})} className="btn btn-warning btn-sm ml-1">Ok</button>
                        </div>
        
                        </>
        
                        }
                        />
                
                    this.setState({confirmationToActivate : ok})
                    }
                    
                })
                
            })
        }
    }
                   

    Give_Val_By_ServiceKey = (serviceKey) => {

        console.log(serviceKey, 'Give_Val_By_ServiceKey')

        let VALUE;

        let VR2_Form_Json = this.props.VR2_Form_Json

        let found = false

        this.setState({
            ...this.state, 
            Give_Val_By_ServiceKey : {
                ...this.state.Give_Val_By_ServiceKey,
                [serviceKey]: ""                
            } 
        }, () => {

            VR2_Form_Json.forEach( (each , i) => {
    
                if(found) return VALUE;
                if(each.children && each.children.length){
    
                    each.children.forEach( (each2, j) => {
    
                        if(found) return VALUE;
                        if(each2.children && each2.children.length){
    
                            each2.children.forEach( (each3, k) => {
    
                                if(found) return VALUE;
    
                                if(!each3.children && each3.render && each3.render === "true" && each3.serviceKey === serviceKey ){
    
                                    VALUE = each3.val

                                    this.setState({
                                        ...this.state, 
                                        Give_Val_By_ServiceKey : {
                                            ...this.state.Give_Val_By_ServiceKey,
                                            [serviceKey]: each3.val               
                                        } 
                                    })    

                                    console.log(VALUE, 'Give_Val_By_ServiceKey')
    
                                    if(VALUE) found = true                            
                                   
                                    if(found) return VALUE; 
                                }

                                //only for base value in billextract
                                if( (serviceKey === 'Base Value' && each2.title === "Billing Summary") || serviceKey === "Qty"){

                                    this.setState({
                                        ...this.state, 
                                        Give_Val_By_ServiceKey : {
                                            ...this.state.Give_Val_By_ServiceKey,
                                            [serviceKey]: []               
                                        } 
                                    }, () => {

                                        let arr = this.state.Give_Val_By_ServiceKey[serviceKey]

                                        if(each3.children && each3.children.length){
        
                                            each3.children.forEach( (each4, m)=> {
            
                                                if(!each4.children && each4.render && each4.render === "true" && each4.serviceKey === serviceKey){

                                                    arr.push(each4.val) 

                                                    if(m === each3.children.length - 1){

                                                        found = true  
                                                        this.setState({
                                                            ...this.state, 
                                                            Give_Val_By_ServiceKey : {
                                                                ...this.state.Give_Val_By_ServiceKey,
                                                                [serviceKey]: arr              
                                                            } 
                                                        }, () => console.log(this.state.Give_Val_By_ServiceKey[serviceKey], 'Give_Val_By_ServiceKey'))
                                                        
                                                        if(found) return
                                                    }
                                                }
                                            })
                                        }
                                    }) 
                                }
                            }) 
                        }
                    })
                }
            })
        })
    }

    TopErrAlert = (msg) => {

        this.setState({
            alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{msg}</strong></div> </Animated>
        });
        setTimeout(() => { this.setState({alert : null}); }, 3000);
    }


    isToDateAfterFromDate = (fromDate, toDate) => {
        // Convert "DD-MM-YYYY" string dates to Date objects
        const [fromDay, fromMonth, fromYear] = fromDate.split("-");
        const fromDateObj = new Date(`${fromYear}-${fromMonth}-${fromDay}`);
      
        const [toDay, toMonth, toYear] = toDate.split("-");
        const toDateObj = new Date(`${toYear}-${toMonth}-${toDay}`);
      
        // Compare the dates
        return toDateObj >= fromDateObj;
    }


    onClick_Activate = async(event, dont_stop) => {

        event.preventDefault();
        console.log(this.props.storeVals)

        let found = false
        let stop = false
        let stop_2 = false
        let stop_3 = false

        //condiotional mandatory
        this.props.VR2_Form_Json.forEach( (each , i) => {

            if(found) return
    
            if(each.children && each.children.length){

                each.children.forEach( (each2, j) => {

                    if(found) return

                    if(each2.children && each2.children.length){

                        each2.children.forEach( (each3, k) => {

                            if(found) return

                            if(!each3.children && each3.mandatory && each3.mandatory === "true" && each3.render === "true" && each3.editable === "true" && !each3.val){

                                console.log(each3.serviceID)
                                document.getElementById(each3.serviceID).focus();
                                this.TopErrAlert(`${each3.serviceKey} is mandatory`)
                                found = true
                                return 
                            }

                            if(each3.conditional_mandatory) {

                                // console.log(each3)
                                // console.log(each3.conditional_mandatory)
                                // console.log(this.props.storeVals[each3.conditional_mandatory.serviceID])

                                if(
                                    each3.conditional_mandatory.val.includes(
                                        this.props.storeVals[each3.conditional_mandatory.serviceID]
                                        ) && each3.render === "true" && each3.editable === "true" && !each3.val 
                                ){
                                    console.log(each3.serviceID, '"helohelo321"')
                                    document.getElementById(each3.serviceID).focus();
                                    this.TopErrAlert(`${each3.serviceKey} is mandatory`)
                                    found = true
                                    return 
                                }
                            }

                            if(each3.children && each3.children.length){

                                each3.children.forEach( (each4, m)=> {

                                    if(each4.mandatory === "true" && each4.render === "true" && each4.editable === "true" && !each4.val){

                                        console.log(each4.serviceID)
                                        document.getElementById(each4.serviceID).focus();
                                        this.TopErrAlert(`${each4.serviceKey} is mandatory`)
                                        found = true
                                        return 
                                    }

                                    if(each4.conditional_mandatory) {

                                        if(
                                            each4.conditional_mandatory.val.includes(
                                                this.props.storeVals[each4.conditional_mandatory.serviceID]
                                            ) && each4.render === "true" && each4.editable === "true" && !each4.val
                                        ){
                                            console.log(each4.serviceID, '"helohelo123"')
                                            document.getElementById(each4.serviceID).focus();
                                            this.TopErrAlert(`${each4.serviceKey} is mandatory`)
                                            found = true
                                            return 
                                        }
                                    }
                                })
                            }
                        }) 
                    }
                })
            }
        })

        //checking forprdfrom and forprdto
        this.props.VR2_Form_Json.forEach( (each , i) => {

            if(found) return
    
            if(each.children && each.children.length){

                each.children.forEach( (each2, j) => {

                    if(found) return

                    if(each2.children && each2.children.length){

                        each2.children.forEach( (each3, row) => {

                            if(found) return

                            let from_dt;
                            let to_dt;

                            if(each3.children && each3.children.length){

                                each3.children.forEach( (each4, m)=> {

                                    if( each4.render === "true" && (each4.serviceKey === "ForPrdFrom" || each4.serviceKey === "ForPrdTo")){

                                        if(each4.serviceKey === "ForPrdFrom") from_dt = each4.val
                                        if(each4.serviceKey === "ForPrdTo") to_dt = each4.val

                                        if(from_dt && to_dt){

                                            if(this.isToDateAfterFromDate(from_dt, to_dt)) {
                                            } else {
                                                console.log(`Invalid ForPrdTo in Section "${each2.title}" Row no ${row+1}`);
                                                this.TopErrAlert(`Invalid ForPrdTo in Section "${each2.title}" Row no ${row+1}`)
                                                found = true
                                                return 
                                            }
                                        }
                                    }
                                })
                            }
                        }) 
                    }
                })
            }
        })

        if(this.token === "BillExtract"){

            // if PO/IP Type = PurchaseOrder then check all Qty is valid number or not
            await this.Give_Val_By_ServiceKey("PO/IP Type")
            let PO_IP_Type = this.state.Give_Val_By_ServiceKey["PO/IP Type"]
            console.log(PO_IP_Type)
            
            if(PO_IP_Type === "PurchaseOrder"){
                
                await this.Give_Val_By_ServiceKey("Qty")
                let Qty = this.state.Give_Val_By_ServiceKey["Qty"]
                console.log(Qty)

                function isNumber(value) {
                    // checking number != 0 and not a number
                    return !isNaN(value) && value != 0
                }

                const Valid = Qty.every((num) => isNumber(num));

                if(!Valid){

                    found = true
                    
                    this.TopErrAlert(`Please enter valid Qty in Billing Summary`)
                    return
                }

            }

            if(this.state[this.token] && this.state[this.token].showSelect && (this.state[this.token].showSelect === "GoodsReceipt" || this.state[this.token].showSelect === "ServiceReceipt")){

                if(this.state[this.token]&&this.state[this.token].select){
                }
                else{
                    document.getElementById("FullReversal_ProportionateReversal").focus();
                    found = true
                    return
                }
            }
        }

        if(found) return

        else{

            let Input =  {
                TREE_JSON: this.props.VR2_Form_Json,
                ORG_ID_CONST: this.props.ORG_ID_CONST,
                OldAccountingDate: this.state.OldAccountingDate
            }

            if(this.token === 'ItemMaster'){

                await this.Give_Val_By_ServiceKey("Item ID")
                let ItemID = this.state.Give_Val_By_ServiceKey["Item ID"]
                await this.Give_Val_By_ServiceKey("Item Name")
                let ItemName = this.state.Give_Val_By_ServiceKey["Item Name"]

                Input = {
                    ...Input,
                    ItemID,
                    ItemName
                }
                console.log(Input)
            }

            if(this.token === 'CostAllocationMaster'){

                await this.Give_Val_By_ServiceKey("Cost Allocation Method")
                let CostAllocationMethod = this.state.Give_Val_By_ServiceKey["Cost Allocation Method"]
                await this.Give_Val_By_ServiceKey("ForPrdFrom")
                let ForPrdFrom = this.state.Give_Val_By_ServiceKey["ForPrdFrom"]
                await this.Give_Val_By_ServiceKey("ForPrdTo")
                let ForPrdTo = this.state.Give_Val_By_ServiceKey["ForPrdTo"]

                Input = {
                    ...Input,
                    CostAllocationMethod,
                    ForPrdFrom,
                    ForPrdTo
                }
                console.log(Input)
            }
            
            if(this.token === 'BillExtract'){

                await this.Give_Val_By_ServiceKey("Invoice Ref")
                let InvoiceRef = this.state.Give_Val_By_ServiceKey["Invoice Ref"]
                await this.Give_Val_By_ServiceKey("Vendor ID")
                let VendorID = this.state.Give_Val_By_ServiceKey["Vendor ID"]
                await this.Give_Val_By_ServiceKey("Bill Number")
                let BillNumber = this.state.Give_Val_By_ServiceKey["Bill Number"]

                Input = {
                    ...Input,
                    InvoiceRef,
                    VendorID,
                    BillNumber
                }
                console.log(Input)

                // checking cost allocation CostAllocated Lcl Curr is not empty
                !dont_stop && this.props.VR2_Form_Json.forEach( (each , i) => {

                    if(found) return
                    if(each.children && each.children.length){

                        each.children.forEach( (each2, j) => {

                            if(found) return
                            if(each2.children && each2.children.length){

                                if(each2.title === 'Bill Cost Allocation'){

                                    each2.children.forEach( (each3, k) => {
    
                                        if(each3.children && each3.children.length){
    
                                            each3.children.forEach( (each4, m)=> { 

                                                let CostAllocatedLclCurr = []

                                                if(each4.title === 'CostAllocated Lcl Curr' ){

                                                    CostAllocatedLclCurr.push(each4.val)

                                                    if(m === each3.children.length - 1 ){

                                                        const sum = CostAllocatedLclCurr.reduce((accumulator, currentValue) => {
                                                            const number = Number(currentValue);
                                                            return accumulator + number;
                                                        }, 0);
                                                        console.log(sum);

                                                        if(!sum || isNaN(sum)){
                                                            
                                                            stop = true
                                                            
                                                            this.TopErrAlert(`Cost Allocation is not defined! Please don't activate`)
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        })
                    }
                })

                // 0 Value check Col - CostAllocationVariance, AJCrossFootErrorInd, ReversedAJCrossFootErrorInd
                this.store_all_vals()
    
                let SerIDS = ["costallocationvariance", "ajcrossfooterrorind", "reversedajcrossfooterrorind"]
        
                SerIDS = SerIDS.map(each => this.trim(each))
        
                let storeVals = this.props.storeVals
        
                let obj = {}
        
                Object.keys(storeVals).forEach( each => {
        
                    let key = each.split('#')[1]
        
                    if(SerIDS.includes(key)){
        
                        if(!obj[key]) obj[key] = storeVals[each]
                    }
                })
    
                console.log(obj)  //////////// roundoff
    
                if(Math.round(obj.costallocationvariance) == 0 && Math.round(obj.ajcrossfooterrorind) == 0 && (Math.round(obj.reversedajcrossfooterrorind) == 0 || !obj.reversedajcrossfooterrorind)){
                }
                else{
                    stop_2 = true
                   
                    this.TopErrAlert(`Bill Cost Error Detection Table Error! Please don't activate`)
                }


                //Expenses Journal checking LedgerName and AccountCode is not empty
                this.props.VR2_Form_Json.forEach( (each , i) => {

                    if(found) return
                    if(each.children && each.children.length){

                        each.children.forEach( (each2, j) => {

                            if(found) return
                            if(each2.children && each2.children.length){

                                if(found) return
                                if(each2.title === 'Expenses Journal'){

                                    each2.children.forEach( (each3, k) => {
    
                                        if(each3.children && each3.children.length){
                                            
                                            let AccountType;
                                            each3.children.forEach( (each4, m)=> { 

                                                let errMsg;
                                                if(each4.title === 'AccountType') AccountType = each4.val

                                                if(AccountType && AccountType === "CreditorsAc" && each4.title === 'SubLedgerName'){

                                                    if(!each4.val) errMsg = `${each4.title} is empty in Expenses Journal`
                                                }
                                                if(each4.title === 'AccountingDate' || each4.title === 'AccountType' || each4.title === 'ItemType' || each4.title === 'LedgerName'){

                                                    if(!each4.val) errMsg = `${each4.title} is empty in Expenses Journal`
                                                }
                                                if(each4.title === 'AccountCode'){

                                                    if(!each4.val || each4.val === "null" || each4.val === "0" || each4.val === "0.0" ){

                                                        errMsg = `${each4.title} is not valid in Expenses Journal`
                                                    } 
                                                }

                                                if(errMsg){
                                                    found = true
                                                    stop_3 = true
                                                  
                                                    this.TopErrAlert(errMsg)
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                        })
                    }
                })

                
            }

            !stop && !stop_2 && !stop_3 && this.setState({ showSpinner : true}, 
            () => {

                console.log('"hello Nothing from active"')
    
                postRequest(`api/vr2/UpdateDocuments?module=${localStorage.getItem("module")}&screenName=${this.token}&context=${this.props.screenAction === "PartialCreate" ? "create" : this.props.screenAction}`
                , Input)
                .then( res => {
                    this.setState({ showSpinner : false})
                    if( res.res.status === "success"){
                        console.log(res.res.data)
                        
                        setTimeout(() => {
                            // window.location.reload()
                            this.props.onBack()
                        }, 1000);
                    }
                    if( res.res.status === "info"){

                        let ok = <Popup
                        noClose={true}
                        content={
                        <>
                        <div className="mt-3 mb-3" align="center">
                            <div>{res.res.data}</div>
                        </div>
        
                        <div align="center">
                            <button onClick={() => this.setState({confirmationToActivate: null})} className="btn btn-warning btn-sm ml-1">Ok</button>
                            {/* <button onClick={(e) => this.confirmationToActivate(e)} className="btn btn-success btn-sm ml-1">Yes</button> */}
                        </div>
        
                        </>
        
                        }
                        />
              
                    this.setState({confirmationToActivate : ok})
                    }
                })
            })
        }
    }

    trim = (str) => {
        return str.replace(/\s+/g, '').toLowerCase();
    }

    confirmationToActivate = (e) => {

        e.preventDefault();

        this.setState({ showSpinner : true}, 
        () => {

            postRequest(`api/vr2/UpdateDocuments?proceed=true&module=${localStorage.getItem("module")}&screenName=${this.token}&context=${this.props.screenAction === "PartialCreate" ? "create" : this.props.screenAction}`, {
                TREE_JSON: this.props.VR2_Form_Json,
            })
            .then( res => {
                if( res.res.status === "success"){
                    console.log(res.res.data)
                }

                this.setState({ showSpinner : false})
                
                setTimeout(() => {
                    // window.location.reload()
                    this.props.onBack()
                }, 1000);
            })
        })
    }
    

    rerender = (noQuit) => {

        let canQuit = this.state.canQuit
        this.setState({
            time: new Date(),
            canQuit: noQuit ? false : canQuit
        }, () => console.log('cr7'))
    }

    Store_ORG_ID = async (FROM) => {

        await this.Give_Val_By_ServiceKey(FROM)
        let val = this.state.Give_Val_By_ServiceKey[FROM]

        if(val) this.props.Set_ORG_ID_CONST(val)
    }


    putDefaultValue_BillExtract_copyFrom = async (TO, FROM) => {

        await this.Give_Val_By_ServiceKey(FROM)
        let val = this.state.Give_Val_By_ServiceKey[FROM]

        if(val) this.putDefaultValue_BillExtract( TO, val)
    }


    putDefaultValue_BillExtract = ( KEY, VAL ) => {
        
        console.log(KEY, VAL)
        
        let VR2_Form_Json = this.props.VR2_Form_Json
        console.log(VR2_Form_Json)
        let found = false

        VR2_Form_Json.forEach( (each , i) => {
    
            if(found) return;
            if(each.children && each.children.length){

                each.children.forEach( (each2, j) => {

                    if(found) return;
                    if(each2.children && each2.children.length){

                        each2.children.forEach( (each3, k) => {

                            if(found) return;

                            if(!each3.children && each3.render && each3.render === "true" && each3.serviceKey === KEY){

                                if(!each3.val){

                                    each3.val = VAL
                                    this.props.handleTreeChange(VR2_Form_Json)
                                }

                                found = true
                                return
                            }
                        })
                    }
                })
            }
        })
    }


    getTDSAsExp = ( KEY, stateKey, render ) => {
        
        console.log(KEY, stateKey)
        
        let VR2_Form_Json = this.props.VR2_Form_Json
        console.log(VR2_Form_Json)
        let found = false

        VR2_Form_Json.forEach( (each , i) => {
    
            if(found) return;
            if(each.children && each.children.length){

                each.children.forEach( (each2, j) => {

                    if(found) return;
                    if(each2.children && each2.children.length){

                        each2.children.forEach( (each3, k) => {

                            if(found) return;

                            if(render && !each3.children && each3.render && each3.render === "true" && each3.serviceKey === KEY){

                                this.setState({
                                    ...this.state,
                                    [this.token] : {
                                        ...this.state[this.token],
                                        [stateKey]: each3.val
                                    }
                                }, () => console.log(this.state))
                                found = true
                                return
                            }

                            else if(!each3.children && each3.render && each3.render === "false" && each3.serviceKey === KEY ){
                                    
                                this.setState({
                                    ...this.state,
                                    [this.token] : {
                                        ...this.state[this.token],
                                        [stateKey]: each3.val
                                    }
                                }, () => console.log(this.state))
                                found = true
                                return
                            }
                        })
                    }
                })
            }
        })
    }


    TDSAsExp = ( KEY, VAL, stateKey ) => {

        let serviceKey = KEY
        let storeVal = VAL
        
        let VR2_Form_Json = this.props.VR2_Form_Json
        console.log(VR2_Form_Json)
        let found = false

        VR2_Form_Json.forEach( (each , i) => {
    
            if(found) return;
            if(each.children && each.children.length){

                each.children.forEach( (each2, j) => {

                    if(found) return;
                    if(each2.children && each2.children.length){

                        each2.children.forEach( (each3, k) => {

                            if(found) return;

                            if(!each3.children && each3.render && each3.render === "false" && each3.serviceKey === serviceKey ){

                                if(stateKey === "select" || !each3.val){

                                    each3.val = storeVal
                                }
                                else if(each3.val){

                                    each3.val = ""
                                    storeVal = ""
                                }
                                
                                // found = true
                                this.props.handleTreeChange(VR2_Form_Json)

                                this.setState({
                                    ...this.state,
                                    [this.token] : {
                                        ...this.state[this.token],
                                        [stateKey]: storeVal
                                    }
                                }, () => console.log(this.state))

                                return
                            }
                        })
                    }
                })
            }
        })
    }


    onClickBack = () => {

        if(this.state.canQuit){

            this.props.onBack()
        }
        else{

            let ok = <Popup
                handleClose={() => this.setState({alert: null})}
                content={
                <>
  
                  <div className="mt-3 mb-3" align="center">
                    <div>
                      If you go back, you will lose your changes. Are you sure ?
                    </div>
                  </div>
  
                  <div align="center">
                    <button onClick={() => this.setState({alert: null})} className="btn btn-secondary btn-sm">No</button>
                    <button onClick={this.props.onBack} className="btn btn-success btn-sm ml-1">Yes</button>
                  </div>
  
                </>
  
                }
              />
  
              
            this.setState({alert : ok})
            
        }
    }


    toParent = (input) => {

        this.setState({
            ...this.state,
            [this.token] : {
                ...this.state[this.token],
                showSelect: input
            }
        }, () => console.log(this.state))    
    }


    // scroll
    scrollToBottom = () => {
        const content = document.getElementById('main-content');
        content.scrollTo({
            top: content.scrollHeight,
            left: 0,
            behavior: "smooth",
        });
    };

    scrollToTop = () => {
        const content = document.getElementById('main-content');
        content.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    handleScroll = () => {
        const isAtBottom =
            window.innerHeight + window.scrollY >= document.body.offsetHeight;

        this.setState({ showScrollButton: isAtBottom });
    };

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }


    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // }


    render() {
        if (this.state.render && this.props.VR2_Form_Json && this.props.dependency_json) {
            return (
                

        <div className='row'>

            {this.state.confirmationToActivate && this.state.confirmationToActivate }
            {this.state.alert}

            <div style={{ position: 'fixed', to: 20, right: 20, zIndex: 999 }}>
                {this.state.showScrollButton && !this.props.PDF_Key ? (
                <>
                    <button className='btn' style={{border: 'none', fontSize: '1.3rem'}} onClick={this.scrollToTop}><FaArrowCircleUp/></button>
                    < br />
                    <button className='btn' style={{border: 'none', fontSize: '1.3rem'}} onClick={this.scrollToBottom}><FaArrowCircleDown /></button>
                </>
                ) : null}
            </div>

            <div className={this.props.PDF_Key ? 'col-lg-6' : 'col-lg-12'}>                        
                <Accordion defaultActiveKey={1} alwaysOpen={true} >
                {
                this.props.VR2_Form_Json.map((treeNode, index) => {
                    if(treeNode.type && treeNode.type !== "validate_button" && treeNode.type !== "submit_button" ){
                    return(
                        <>
                        {/* <Card key={index + 1}> */}
                            {/* <Card.Header>
                                <Accordion.Toggle eventKey={index + 1}>
                                    <div className="division-title" style={{color: "black", textAlign: "left"}}>{treeNode.title}</div>
                                </Accordion.Toggle>
                            </Card.Header> */}
                            {/* <Accordion.Collapse eventKey={index + 1}> */}
                                {/* <Card.Body> */}
                                    <div className="row">                                           
                                        <div className={treeNode.divisonView === 'v'  ? "col-lg-6" : "col-lg-12" }>
                                        <MyForm tree={treeNode} screenName = {this.props.screenName} rerender={this.rerender} time={this.state.time} toParent={this.toParent}
                                        onVendorIDonChange={this.state.fnToCallonVendorIDonChange} ></MyForm>
                                        </div>
                                        <div className={treeNode.divisonView === 'v'  ? "col-lg-6" : "col-lg-12" }>
                                        <MyTable tableData={treeNode} screenName = {this.props.screenName} rerender={this.rerender} time={this.state.time}
                                        setFnToCallonVendorIDonChange={func => this.setState({ fnToCallonVendorIDonChange: func })} ></MyTable>
                                        </div>
                                    </div>
                                {/* </Card.Body> */}
                            {/* </Accordion.Collapse> */}
                        {/* </Card> */}
                        </>

                    )
                    }
                    // else if ( treeNode.type && treeNode.type === "validate_button" && treeNode.render && treeNode.render === "true" ) {
                    // return (
                    //     <Card key={index + 1}>
                    //         <Card.Body>
                    //             <div align='center'>    
                    //             <button onClick={this.onClick_Validate} className="btn btn-warning" style={{ opacity: treeNode.editable === 'false' ? '0.4' : '1'}} disabled={treeNode.editable && treeNode.editable === 'false' }>{treeNode.title}</button>
                    //             </div>     
                    //         </Card.Body>
                    //     </Card>
                    // )
                    // }
                    // else if ( treeNode.type && treeNode.type === "submit_button" && treeNode.render && treeNode.render === "true" ) {
                    // return (
                    //     <Card key={index + 1}>
                    //         <Card.Body>
                    //             <div align='center'>    
                    //             <button onClick={this.onClick_Activate} className="btn btn-primary" style={{ opacity: treeNode.editable === 'false' ? '0.4' : '1'}} disabled={treeNode.editable && treeNode.editable === 'false' }>{treeNode.title}</button>
                    //             </div>     
                    //         </Card.Body>
                    //     </Card>
                    // )
                    // }
                })
                }


                <div style={{
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    zIndex: "1000",
                    backgroundColor: "#EEEEEE",
                    height: "3.8rem",
                    display: "grid",
                    gridTemplateColumns: "4fr 4fr 3fr",
                    padding: "1rem",
                    alignItems: "center",
                }}>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        {this.props.onBack && 

                        <div >
                            <button className='btn btn-secondary' onClick={this.onClickBack}><TbArrowBackUp /> Go Back</button>
                        </div>
                        }
                    </div>
                        
                    <div style={{
                        display: "flex",
                        justifySelf: "center",
                    }} >

                        {this.props.VR2_Form_Json.map((treeNode, index) => {
                            if ( treeNode.type && treeNode.type === "validate_button" && treeNode.render && treeNode.render === "true" ) {
                                return (

                                    <button onClick={this.onClick_Validate} className="btn btn-warning" style={{ opacity: treeNode.editable === 'false' ? '0.4' : '1', margin: '0 1rem'}} 
                                    disabled={treeNode.editable && treeNode.editable === 'false' }
                                    >{treeNode.title}</button>
                                )
                            }
                            else if ( treeNode.type && treeNode.type === "submit_button" && treeNode.render && treeNode.render === "true" ) {
                                return (

                                    <button onClick={this.onClick_Activate} className="btn btn-primary" style={{ opacity: treeNode.editable === 'false' ? '0.4' : '1', margin: '0 1rem'}} 
                                    disabled={treeNode.editable && treeNode.editable === 'false' }
                                    >{treeNode.title}</button>
                                )
                            }
                        })
                        }
                        </div>

                        { this.token === 'BillExtract' &&

                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}>
                                {/* {this.state[this.token] && this.state[this.token].showSelect && (this.state[this.token].showSelect === "GoodsReceipt" || this.state[this.token].showSelect === "ServiceReceipt") && 
                                <select id="FullReversal_ProportionateReversal" className='exclude-select' style={{appearance: 'auto', WebkitAppearance: 'menulist', marginRight: '1rem'}} value={this.state[this.token]&&this.state[this.token].select} onChange={(e) => this.TDSAsExp("CIN Number", e.target.value, "select")}>
                                    <option value=""></option>
                                    <option value="FullReversal">FullReversal</option>
                                    <option value="ProportionateReversal">ProportionateReversal</option>
                                </select>} */}
                                <input type='checkbox' checked={this.state[this.token]&&this.state[this.token].GSTasExp} onClick={() => this.TDSAsExp("GSTReference", "GSTasExp", "GSTasExp")} /><div style={{margin: '.4rem 1.5rem 0 .2rem'}}>GST as Exp</div>
                                <input type='checkbox' checked={this.state[this.token]&&this.state[this.token].TDSasExp} onClick={() => this.TDSAsExp("TDSReference", "TDSasExp", "TDSasExp")} /><div style={{margin: '.4rem 0 0 .2rem'}}>TDS As Exp</div>                                
                            </div>
                        }
                        { this.token === 'GoodsAndServicesEntries' &&

                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}>
                                {/* <input type='checkbox'/><div style={{margin: '.4rem 1.5rem 0 .2rem'}}>Enforce TDS</div> */}
                                <input type='checkbox' checked={this.state[this.token]&&this.state[this.token].DeductTDS} onClick={() => this.TDSAsExp("DeductionRef", "DeductTDS", "DeductTDS")}/><div style={{margin: '.4rem 0 0 .2rem'}}>Deduct TDS</div>                                
                            </div>
                        }
                </div>


                <Spinner open = { this.state.showSpinner } />
                </Accordion>
            </div>     
            
            <div className={this.props.PDF_Key ? 'col-lg-6  sticky-div' : null}>

                {this.props.PDF_Key &&
                
                    <>

                        <div align='right'>
                            <button className='btn btn-light bg-danger text-light' onClick={(e) => {
                                e.preventDefault()
                                this.props.update_one_side_PDF_Key(false)
                            }}>x</button>
                        </div>

                        <iframe
                            src={`data:application/pdf;base64,${this.props.PDF_Key}`}
                            title="pdf-viewer"
                            style={{ width: '100%', height: '600px' }}
                        />

                    </>
                

                }

                </div>
        </div>

            )
        } else {
            return (<div>Loading......</div>)
        }

    }
}
function mapStateToProps(state) {
    return {
        VR2_Form_Json: state.VR2_Reducer.VR2_Form_Json,
        changeLogs: state.VR2_Reducer.changeLogs,
        storeVals: state.VR2_Reducer.storeVals,
        dependency_json : state.VR2_Reducer.dependency_json,
        ORG_ID_CONST : state.VR2_Reducer.ORG_ID_CONST,
        PDF_Key : state.VR2_Reducer.PDF_Key,
    }
}

export default connect(
    mapStateToProps,
    { fetchTreeData, handleTreeChange, update_dependencyJson, updateStoreVals, update_one_side_PDF_Key, Set_ORG_ID_CONST }
)(DisplayContainer);

