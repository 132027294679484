// import React, { useState, useEffect } from 'react' 
// import { Modal } from 'react-bootstrap' 
// import { toast, ToastContainer } from 'react-toastify' 
// import 'react-toastify/dist/ReactToastify.css' 
// import './PaymentScreen.css' 

// const NewPaymentPopup = ({ data, handleClose, onSubmit }) => {
//     const [paymentMethod, setPaymentMethod] = useState('') 
//     const [paymentAmount, setPaymentAmount] = useState('') 
//     const [paymentPercentage, setPaymentPercentage] = useState('') 
//     const [finalData, setFinalData] = useState({}) 

//     const handleChange = (e) => {
//         setPaymentMethod(e.target.value) 
//     } 

//     const handleInputChange = (e, type) => {
//         const value = e.target.value 
//         if (type === 'Amount') {
//             if(value > (-1 * data.Lc_PayAmount)){
//                 toast.error("Entered amount is greater than the bill amount")
//             } else {
//                 setPaymentAmount(value) 
//             }
//         } else if (type === 'Percentage') {
//             if(value <= 100){
//                 setPaymentPercentage(value) 
//             } else {
//                 toast.error("Percentage cannot exceed 100%")
//             }
//         }
//     } 

//     const handleSubmit = () => {
//         if (paymentMethod || (paymentPercentage || paymentAmount)) {
//             if (paymentMethod === 'Full') {
//                 const newData = {...data}
//                 newData.Lc_FinalAmount = newData.Lc_PayAmount
//                 setFinalData(newData) 
//             } else {
//                 const newData = { ...data } 
//                 if (paymentPercentage) {
//                     newData.Lc_FinalAmount = (data.Lc_PayAmount * paymentPercentage) / 100 
//                     newData.percentage = paymentPercentage
//                 } else {
//                     newData.Lc_FinalAmount = -1 * paymentAmount 
//                     newData.percentage = ((paymentAmount / (-1 * data.Lc_PayAmount) ) * 100).toFixed(2)
//                 }
//                 setFinalData(newData) 
//             }
//         } else {
//             toast.error('Please select at least one method to proceed') 
//         }
//     } 

//     useEffect(() => {
//         if (Object.keys(finalData).length !== 0 && onSubmit) {
//             onSubmit(finalData)
//         }
//     }, [finalData, onSubmit])

//     return (
//         <div>
//             <Modal show={true} onHide={false} backdrop='static'>
//                 <Modal.Header>
//                     <Modal.Title style={{ fontSize: '1rem' }}>Please select any one to proceed further</Modal.Title>
//                 </Modal.Header>

//                 <Modal.Body>
//                     <>
//                         <div className='column'>
//                             <div className='row'>
//                                 <p className='col-lg-3'>Payment type</p>
//                                 <select className='col-lg-6' value={paymentMethod} onChange={handleChange}>
//                                     <option value='' disabled>Select</option>
//                                     <option value='Full'>Pay in full</option>
//                                     <option value='Percentage'>Pay by percentage</option>
//                                     <option value='Amount'>Pay by amount</option>
//                                 </select>
//                             </div>

//                             {(paymentMethod === 'Percentage' || paymentMethod === 'Amount') && (
//                                 <div style={{ marginTop: '10px' }} className='row'>
//                                     <p className='col-lg-3'>Enter {paymentMethod === 'Percentage' ? 'Percentage' : 'Amount'}</p>
//                                     <input
//                                         type='number'
//                                         className='col-lg-6'
//                                         value={paymentMethod === 'Percentage' ? paymentPercentage : paymentAmount}
//                                         onChange={(e) => handleInputChange(e, paymentMethod)}
//                                     />
//                                 </div>
//                             )}
//                         </div>
//                     </>
//                 </Modal.Body>

//                 <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
//                     <button className='btn green_button' onClick={handleSubmit}>
//                         Submit
//                     </button>
//                     <button className='btn red_button' onClick={handleClose}>
//                         Close
//                     </button>
//                 </Modal.Footer>
//             </Modal>

//             <ToastContainer />
//         </div>
//     ) 
// } 

// export default NewPaymentPopup 


// import React, { useState, useEffect } from 'react';
// import { Modal } from 'react-bootstrap';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './PaymentScreen.css';

// const NewPaymentPopup = ({ data, handleClose, onSubmit }) => {
//     const [paymentMethod, setPaymentMethod] = useState('');
//     const [paymentAmount, setPaymentAmount] = useState('');
//     const [paymentPercentage, setPaymentPercentage] = useState('');
//     const [finalData, setFinalData] = useState({});

//     const handleChange = (e) => {
//         setPaymentMethod(e.target.value);
//     };

//     const handleInputChange = (e, type) => {
//         const value = parseFloat(e.target.value);
//         const positivePayAmount = Math.abs(data.Lc_PayAmount); // Ensure the amount is positive
//         const roundedPayAmount = Math.round(positivePayAmount); // Round to the nearest integer

//         if (type === 'Amount') {
//             if (value > roundedPayAmount) { // Allowing a tolerance of +1
//                 toast.error("Entered amount is greater than the bill amount");
//             } else {
//                 setPaymentAmount(value);
//             }
//         } else if (type === 'Percentage') {
//             if (value <= 100) {
//                 setPaymentPercentage(value);
//             } else {
//                 toast.error("Percentage cannot exceed 100%");
//             }
//         }
//     };

//     const handleSubmit = () => {
//         if (paymentMethod && (paymentPercentage || paymentAmount)) {
//             const newData = { ...data };
//             if (paymentMethod === 'Full') {
//                 newData.Lc_FinalAmount = newData.Lc_PayAmount;
//             } else {
//                 if (paymentPercentage) {
//                     newData.Lc_FinalAmount = (data.Lc_PayAmount * paymentPercentage) / 100;
//                     newData.percentage = paymentPercentage;
//                 } else {
//                     newData.Lc_FinalAmount = paymentAmount > 0 ? -1 * paymentAmount : paymentAmount;
//                     newData.percentage = ((paymentAmount / (-1 * data.Lc_PayAmount)) * 100).toFixed(2);
//                 }
//             }
//             setFinalData(newData);
//         } else {
//             toast.error('Please select at least one method to proceed');
//         }
//     };

//     useEffect(() => {
//         if (paymentMethod === 'Amount') {
//             const positivePayAmount = Math.abs(data.Lc_PayAmount);
//             const roundedPayAmount = Math.round(positivePayAmount);
//             setPaymentAmount(roundedPayAmount);
//         }
//     }, [paymentMethod, data.Lc_PayAmount]);

//     useEffect(() => {
//         if (Object.keys(finalData).length !== 0 && onSubmit) {
//             onSubmit(finalData);
//         }
//     }, [finalData, onSubmit]);

//     return (
//         <div>
//             <Modal show={true} onHide={handleClose} backdrop='static'>
//                 <Modal.Header>
//                     <Modal.Title style={{ fontSize: '1rem' }}>Please select any one to proceed further</Modal.Title>
//                 </Modal.Header>

//                 <Modal.Body>
//                     <div className='column'>
//                         <div className='row'>
//                             <p className='col-lg-3'>Payment type</p>
//                             <select className='col-lg-6' value={paymentMethod} onChange={handleChange}>
//                                 <option value='' disabled>Select</option>
//                                 <option value='Full'>Pay in full</option>
//                                 <option value='Percentage'>Pay by percentage</option>
//                                 <option value='Amount'>Pay by amount</option>
//                             </select>
//                         </div>

//                         {(paymentMethod === 'Percentage' || paymentMethod === 'Amount') && (
//                             <div style={{ marginTop: '10px' }} className='row'>
//                                 <p className='col-lg-3'>
//                                     Enter {paymentMethod === 'Percentage' ? 'Percentage' : 'Amount'}
//                                     {/* {paymentMethod === 'Amount' && (
//                                         <span style={{ display: 'block', marginTop: '5px', fontSize: '0.9em', color: '#666' }}>
//                                             Rounded Amount: {Math.round(Math.abs(data.Lc_PayAmount))}
//                                         </span>
//                                     )} */}
//                                 </p>
//                                 <input
//                                     type='number'
//                                     className='col-lg-6'
//                                     value={paymentMethod === 'Percentage' ? paymentPercentage : paymentAmount}
//                                     onChange={(e) => handleInputChange(e, paymentMethod)}
//                                 />
//                             </div>
//                         )}
//                     </div>
//                 </Modal.Body>

//                 <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
//                     <button className='btn green_button' onClick={handleSubmit}>
//                         Submit
//                     </button>
//                     <button className='btn red_button' onClick={handleClose}>
//                         Close
//                     </button>
//                 </Modal.Footer>
//             </Modal>

//             <ToastContainer />
//         </div>
//     );
// };

// export default NewPaymentPopup;


import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './PaymentScreen.css';

const NewPaymentPopup = ({ data, handleClose, onSubmit }) => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentPercentage, setPaymentPercentage] = useState('');
    const [finalData, setFinalData] = useState({});

    const handleChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleInputChange = (e, type) => {
        const value = parseFloat(e.target.value);
        const positivePayAmount = Math.abs(data.PayableAmt); // Ensure the amount is positive
        const roundedPayAmount = Math.round(positivePayAmount); // Round to the nearest integer

        if (type === 'Amount') {
            if (value > roundedPayAmount) { // Allowing a tolerance of +1
                toast.error("Entered amount is greater than the bill amount");
            } else {
                setPaymentAmount(value);
            }
        } else if (type === 'Percentage') {
            if (value <= 100) {
                setPaymentPercentage(value);
            } else {
                toast.error("Percentage cannot exceed 100%");
            }
        }
    };

    const handleSubmit = () => {
        if (paymentMethod && (paymentPercentage || paymentAmount)) {
            const newData = { ...data };
            if (paymentMethod === 'Full') {
                newData.Lc_FinalAmount = newData.Lc_PayAmount;
            } else {
                if (paymentPercentage) {
                    newData.Lc_FinalAmount = (data.Lc_PayAmount * paymentPercentage) / 100;
                    newData.percentage = paymentPercentage;
                } else {
                    newData.Lc_FinalAmount = paymentAmount > 0 ? -1 * paymentAmount : paymentAmount;
                    newData.percentage = ((paymentAmount / (-1 * data.Lc_PayAmount)) * 100).toFixed(2);
                }
            }
            setFinalData(newData);
        } else {
            toast.error('Please select at least one method to proceed');
        }
    };

    useEffect(() => {
        if (paymentMethod === 'Amount') {
            const positivePayAmount = Math.abs(data.Lc_PayAmount1);
            const roundedPayAmount = Math.round(positivePayAmount);
            setPaymentAmount(roundedPayAmount);
        }
    }, [paymentMethod, data.Lc_PayAmount]);

    useEffect(() => {
        if (Object.keys(finalData).length !== 0 && onSubmit) {
            onSubmit(finalData);
        }
    }, [finalData, onSubmit]);

    return (
        <div>
            <Modal show={true} onHide={handleClose} backdrop='static'>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '1rem' }}>Please select any one to proceed further</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='column'>
                        <div className='row'>
                            <p className='col-lg-3'>Payment type</p>
                            <select className='col-lg-6' value={paymentMethod} onChange={handleChange}>
                                <option value='' disabled>Select</option>
                                <option value='Full'>Pay in full</option>
                                <option value='Percentage'>Pay by percentage</option>
                                <option value='Amount'>Pay by amount</option>
                            </select>
                        </div>

                        {(paymentMethod === 'Percentage' || paymentMethod === 'Amount') && (
                            <div style={{ marginTop: '10px' }} className='row'>
                                <p className='col-lg-3'>
                                    Enter {paymentMethod === 'Percentage' ? 'Percentage' : 'Amount'}
                                    {/* {paymentMethod === 'Amount' && (
                                        <span style={{ display: 'block', marginTop: '5px', fontSize: '0.9em', color: '#666' }}>
                                            Rounded Amount: {Math.round(Math.abs(data.Lc_PayAmount))}
                                        </span>
                                    )} */}
                                </p>
                                <input
                                    type='number'
                                    className='col-lg-6'
                                    value={paymentMethod === 'Percentage' ? paymentPercentage : paymentAmount}
                                    onChange={(e) => handleInputChange(e, paymentMethod)}
                                />
                            </div>
                        )}
                    </div>
                </Modal.Body>

                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className='btn green_button' onClick={handleSubmit}>
                        Submit
                    </button>
                    <button className='btn red_button' onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </div>
    );
};

export default NewPaymentPopup;