import GetAppIcon from "@material-ui/icons/GetApp";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  AiFillCheckSquare,
  AiFillClockCircle,
  AiFillCloseSquare,
} from "react-icons/ai";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getRequest, postRequest } from "../../../globalhelper/helper";
import { fetchBillsByOrgID } from "../../../redux/BillData/BillDataReducer";
import Pattern_Popup from "./PatternScreen";
// import Pattern_Popup from './NewPattern'
import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Connections from "./Connections";

const Ledger_Type = [
  // "Instructor", "Batch",
  "CreditCard",
  "DigitalWalletAccounting",
  "EmployeeReimbursement",
  "EmployeeReimbursement",
  "EmployeeCost",
  "EmployeeCostAccrued",
  "EmployeeCostFnF",
  "EmployeeCostAccruedReversal",
  "GL",
  "InstructorCostAccrual",
  "InstructorAccrualReversal",
  "InstructorInvoice",
  "Journal",
  "PettyCash",
  "Payment",
  "VendorAdvancePayment",
  "Vendor"
];
// const View_Type = [ "Ledger", "CostVsRev", "Accruals", "Account Ledger", "Tax Liability" ]
const Ac_Type = ["ProvAc", "ExpAc", "CreditorsAc", "PrePaidAc"];

const PATTERN_GROUP = {
  CreditCard: "HDFC-CC1",
  PettyCash: "Edureka_PC",
  EmployeeReimbursement: "Edureka_ER",
  DigitalWalletAccounting: "VOLO-DWA1",
};

const ALLOW_FOR_PATTERN = [
  "CreditCard",
  "PettyCash",
  "EmployeeReimbursement",
  "DigitalWalletAccounting",
];

export default function LedgerView() {
  // for dialouge box
  const [openDialogue, setOpenDialogue] = useState(false);
  const [popupView, setPopupview] = useState("pattern");
  const [Companies, setCompanies] = useState([]);
  const [Company, setCompany] = useState("");
  const [LedgerType, setLedgerType] = useState("");
  const [AllKeyRef, setAllKeyRef] = useState([]);
  const [KeyRef, setKeyRef] = useState("");
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const [totalDebit, set_totalDebit] = useState("");
  const [totalCredit, set_totalCredit] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef = useRef(null);
  const buttonRef = useRef(null);

  const [fetchedData, set_fetchedData] = useState([]);
  const [displayData, set_displayData] = useState([]);

  const [AcGroupList, set_AcGroupList] = useState([]);
  const [AcClassList, set_AcClassList] = useState([]);
  const [AcCodeList, set_AcCodeList] = useState([]);
  const [KeyRefList, set_KeyRefList] = useState([]);

  const [All_AcGroupList, set_All_AcGroupList] = useState([]);
  const [All_AcClassList, set_All_AcClassList] = useState([]);
  const [All_AcCodeList, set_All_AcCodeList] = useState([]);
  const [All_KeyRefList, set_All_KeyRefList] = useState([]);

  const [SelAcGroupList, set_SelAcGroupList] = useState([]);
  const [SelAcClassList, set_SelAcClassList] = useState([]);
  const [SelAcCodeList, set_SelAcCodeList] = useState([]);
  const [SelKeyRefList, set_SelKeyRefList] = useState([]);

  const [PATTERN__JSONS, set_PATTERN__JSONS] = useState({});
  const [PATTERN_JSON, set_PATTERN_JSON] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const [ItemMaster, set_ItemMaster] = useState([]);
  const [VendorMaster, set_VendorMaster] = useState([]);
  const [BankRef, set_BankRef] = useState([]);

  let [CreditorsAc, setCreditorsAc] = useState({});

  let [BillsByOrgID, setBillsByOrgID] = useState({});
  let dispatch = useDispatch();
  useEffect(() => {
    getRequest(`api/veranda/getCompany0`).then((res) => {
      if (res.type === "success") {
        let data = JSON.parse(res.res.data);

        data = _.sortBy(data, "orgname");

        console.log(data);

        setCompanies(data);
      }
    });

    // Get current date
    var currentDate = new Date();

    // Get first day of the month
    var firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    // Get last day of the month
    var lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Function to format date as "YYYY-MM-DD"
    function formatDate(date) {
      var year = date.getFullYear();
      var month = (date.getMonth() + 1).toString().padStart(2, "0");
      var day = date.getDate().toString().padStart(2, "0");
      return year + "-" + month + "-" + day;
    }

    // Format the dates if needed
    var formattedFirstDay = formatDate(firstDay);
    var formattedLastDay = formatDate(lastDay);

    setFrom(formattedFirstDay);
    setTo(formattedLastDay);
  }, []);

  const fetchBills = async (OrgID) => {
    setBillsByOrgID((prevState) => ({
      ...prevState,
      isLoading: true,
      Bills:[],
      Error: "",
    }));

    try {
      const response = await getRequest(
        `api/veranda/getBillsByOrgID?OrgID=${OrgID}`
      );
      if (response?.res?.data) {
        const bills = JSON.parse(response.res.data);
        console.log(bills);
        setBillsByOrgID((prevState) => ({
          ...prevState,
          Bills: bills,
        }));
      } else {
        throw new Error("Invalid response data");
      }
    } catch (err) {
      console.error("Error fetching bills:", err);
      setBillsByOrgID((prevState) => ({
        ...prevState,
        Error: "Failed to fetch bills data.",
      }));
    } finally {
      setBillsByOrgID((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };
  const updateCompany = async (e) => {
    let val = e.target.value;
    setCompany(val);
    dispatch(fetchBillsByOrgID({ OrgID: val }));
    fetchBills(val);
    //   postRequest("api/veranda/fetchDataForLedger", { data: val }).then((res) => {
    //     if (res.type === "success") {
    //       let data = JSON.parse(res.res.data);

    //       data = _.sortBy(data, "itemdescription");

    //       console.log(data);

    //       set_fetchedData(data);
    //       set_displayData(data);
    //   }
    // })

    getRequest(`api/veranda/getALLvendor_master?OrgID=${val}`).then((res) => {
      if (res.res.status === "success") {
        let data = res.res.data;
        console.log(data);

        set_VendorMaster(data);
      }
    });
    getRequest(`api/veranda/getALLitem_master?OrgID=${val}`).then((res) => {
      if (res.res.status === "success") {
        let data = res.res.data;
        console.log(data);

        set_ItemMaster(data);
      }
    });
    getRequest(`api/veranda/getALLBankRef?OrgID=${val}`).then((res) => {
      if (res.res.status === "success") {
        let data = res.res.data;
        console.log(data);

        set_BankRef(data);
      }
    });
  };

  useEffect(() => {
    if (From && To && Company && LedgerType) {
      getRequest(
        `api/veranda/getKeyRef?From=${From}&To=${To}&LedgerType=${LedgerType}&OrgID=${Company}`
      ).then((res) => {
        if (res.type === "success") {
          const data = JSON.parse(res.res.data);

          console.log(data);

          setAllKeyRef(data.map((each) => each["Key Ref"]));
        }
      });
      // getBillsByVendor();
    }
  }, [From, To, Company, LedgerType]);

  const updateLedger = (e) => {
    let val = e.target.value;
    setLedgerType(val);
  };

  const updateKeyRef = (e) => {
    let val = e.target.value;
    setKeyRef(val);
  };

  const updateFrom = (e) => {
    let val = e.target.value;
    console.log(val);
    setFrom(val);
  };

  const updateTo = (e) => {
    let val = e.target.value;
    console.log(val);
    setTo(val);
  };

  const onFetch = (e) => {
    buttonRef.current.blur();

    let obj = {
      LedgerType: LedgerType,
      OrgID: Company,
      KeyRef: KeyRef.value,
      From: From,
      To: To,
    };

    postRequest("api/veranda/fetchDataForLedger", { data: obj }).then((res) => {
      if (res.type === "success") {
        let data = JSON.parse(res.res.data);

        data = _.sortBy(data, "itemdescription");

        console.log(data);

        set_fetchedData(data);
        set_displayData(data);

        let AcGrpArr = [
          ...new Set(
            data.map((each) => {
              if (each.AcGrp) return each.AcGrp;
            })
          ),
        ].filter((each) => each);

        let AcClassArr = [
          ...new Set(
            data.map((each) => {
              if (each.AcClass) return each.AcClass;
            })
          ),
        ].filter((each) => each);

        let AcCodeArr = [
          ...new Set(
            data.map((each) => {
              if (each.AcCode) return each.AcCode;
            })
          ),
        ].filter((each) => each);

        // let KeyRefArr = [...new Set(data.map(each => {
        //     if(each.KeyRef) return each.KeyRef
        // }))].filter( each => each )

        set_AcGroupList(AcGrpArr);
        set_All_AcGroupList(AcGrpArr);

        set_AcClassList(AcClassArr);
        set_All_AcClassList(AcClassArr);

        set_AcCodeList(AcCodeArr);
        set_All_AcCodeList(AcCodeArr);

        // set_KeyRefList(KeyRefArr)
        // set_All_KeyRefList(KeyRefArr)

        // if(data.length){
        //     setSelectedRow(0);
        // }
        // if(data[0] && data[0]["PatternGroup"]){
        if (data.length) {
          let PatternGroup = [
            ...new Set(data.map((each) => each["PatternGroup"])),
          ];

          console.log(PatternGroup);

          ALLOW_FOR_PATTERN.includes(LedgerType) &&
            PatternGroup.forEach((each) => {
              each &&
                getRequest(
                  `api/veranda/getPatternGrp?LedgerType=${LedgerType}&PatternGroup=${each}&OrgID=${Company}`
                ).then((res) => {
                  if (res.res.status === "success") {
                    let File = res.res.data;

                    console.log(File);

                    let PATTERJSONS = PATTERN__JSONS;

                    PATTERJSONS[each] = File;

                    set_PATTERN__JSONS(PATTERJSONS);
                  }
                });
            });
        }
        console.log(PATTERN_JSON);

        set_totalDebit(
          data.reduce((total, item) => total + parseFloat(item["Debit"]), 0)
        );
        set_totalCredit(
          data.reduce((total, item) => total + parseFloat(item["Credit"]), 0)
        );
      }
    });
  };

  const onAcGroupFilter = (newValue) => {
    console.log(newValue);
    set_SelAcGroupList(newValue);
    set_SelAcClassList([]);
    set_SelAcCodeList([]);
    set_KeyRefList([]);

    let newSet;

    if (newValue.length) {
      newSet = fetchedData.filter((each) => newValue.includes(each.AcGrp));
    } else {
      newSet = fetchedData;
    }

    console.log(newSet);

    let AcClassArr = [
      ...new Set(
        newSet.map((each) => {
          if (each.AcClass) return each.AcClass;
        })
      ),
    ].filter((each) => each);

    set_AcClassList(AcClassArr);

    let AcCodeArr = [
      ...new Set(
        newSet.map((each) => {
          if (each.AcCode) return each.AcCode;
        })
      ),
    ].filter((each) => each);

    set_AcCodeList(AcCodeArr);

    set_displayData(newSet);
    //gv
    if (newSet.length) {
      setSelectedRow(0);
    }
    set_totalDebit(
      newSet.reduce((total, item) => total + parseFloat(item["Debit"]), 0)
    );
    set_totalCredit(
      newSet.reduce((total, item) => total + parseFloat(item["Credit"]), 0)
    );
  };

  const onAcClassFilter = (newValue) => {
    console.log(newValue);
    set_SelAcClassList(newValue);
    set_SelAcCodeList([]);
    set_KeyRefList([]);

    let sel_AcGrp = SelAcGroupList.length ? SelAcGroupList : All_AcGroupList;
    let sel_AcCls = newValue.length ? newValue : All_AcClassList;

    let newSet = fetchedData.filter(
      (each) =>
        sel_AcGrp.includes(each.AcGrp) && sel_AcCls.includes(each.AcClass)
    );

    console.log(newSet);

    let AcCodeArr = [
      ...new Set(
        newSet.map((each) => {
          if (each.AcCode) return each.AcCode;
        })
      ),
    ].filter((each) => each);

    set_AcCodeList(AcCodeArr);

    set_displayData(newSet);
    if (newSet.length) {
      setSelectedRow(0);
    }
    set_totalDebit(
      newSet.reduce((total, item) => total + parseFloat(item["Debit"]), 0)
    );
    set_totalCredit(
      newSet.reduce((total, item) => total + parseFloat(item["Credit"]), 0)
    );
  };

  const onAcCodeFilter = (newValue) => {
    console.log(newValue);
    set_SelAcCodeList(newValue);
    set_KeyRefList([]);

    let sel_AcGrp = SelAcGroupList.length ? SelAcGroupList : All_AcGroupList;
    let sel_AcCls = SelAcClassList.length ? SelAcClassList : All_AcClassList;
    let sel_AcCd = newValue.length ? newValue : All_AcCodeList;

    let newSet = fetchedData.filter(
      (each) =>
        sel_AcGrp.includes(each.AcGrp) &&
        sel_AcCls.includes(each.AcClass) &&
        sel_AcCd.includes(each.AcCode)
    );

    console.log(newSet);

    set_displayData(newSet);
    if (newSet.length) {
      setSelectedRow(0);
    }
    set_totalDebit(
      newSet.reduce((total, item) => total + parseFloat(item["Debit"]), 0)
    );
    set_totalCredit(
      newSet.reduce((total, item) => total + parseFloat(item["Credit"]), 0)
    );
  };

  const onKeyRefFilter = (newValue) => {
    console.log(newValue);
    set_KeyRefList(newValue);

    let newSet;

    if (newValue.length) {
      newSet = fetchedData.filter(
        (each) =>
          SelAcGroupList.includes(each.AcGrp) &&
          SelAcClassList.includes(each.AcClass) &&
          newValue.includes(each.KeyRef)
      );
    } else {
      newSet = fetchedData.filter(
        (each) =>
          SelAcGroupList.includes(each.AcGrp) &&
          SelAcClassList.includes(each.AcClass)
      );
    }

    console.log(newSet);
    set_displayData(newSet);
    if (newSet.length) {
      setSelectedRow(0);
    }
    set_totalDebit(
      newSet.reduce((total, item) => total + parseFloat(item["Debit"]), 0)
    );
    set_totalCredit(
      newSet.reduce((total, item) => total + parseFloat(item["Credit"]), 0)
    );
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 38 || e.keyCode === 40) {
        if (e.keyCode === 38 && selectedRow > 0) {
          e.preventDefault();
          setSelectedRow((prev) => prev - 1);
        } else if (e.keyCode === 40 && selectedRow < fetchedData.length - 1) {
          e.preventDefault();
          setSelectedRow((prev) => (prev === null ? 0 : prev + 1));
        }
        scrollSelectedRowIntoView();
      }
      if (e.key === "Enter" && selectedRow !== null) {
        CreditorsAc = Object.assign(
          {},
          fetchedData.find(
            (data) =>
              data.JournalRef === fetchedData[selectedRow].JournalRef &&
              data.createdid === fetchedData[selectedRow].createdid &&
              data.accounttype === "CreditorsAc"
          )
        );
        setCreditorsAc(CreditorsAc);
        console.log(CreditorsAc);

        // Open the popup here
        if (ALLOW_FOR_PATTERN.includes(LedgerType)) {
          let patgrp = displayData[selectedRow]["PatternGroup"];

          if (!patgrp) {
            patgrp = PATTERN_GROUP[LedgerType];
          }

          console.log(patgrp);

          set_PATTERN_JSON(PATTERN__JSONS[patgrp]);
          console.log(popupVisible);
          console.log(popupVisible);
        }
        setOpenDialogue(true);
        // setPopupVisible(true);
      }
    };

    const scrollSelectedRowIntoView = () => {
      if (tableRef.current && selectedRow !== null) {
        const row_up = tableRef.current.querySelector(
          `tr:nth-child(${selectedRow - 1})`
        );
        const row_down = tableRef.current.querySelector(
          `tr:nth-child(${selectedRow + 2})`
        );

        const row = tableRef.current.querySelector(
          `tr:nth-child(${selectedRow + 1})`
        );

        if (row) {
          const tableRect = tableRef.current.getBoundingClientRect();

          if (row_up) {
            const rowRect_up = row_up.getBoundingClientRect();

            if (rowRect_up.top < tableRect.top) {
              // Row is above the visible area, scroll up
              // console.log(rowRect_up.top - tableRect.top)
              tableRef.current.scrollTop += rowRect_up.top - tableRect.top;
            }
          }

          if (row_down) {
            const rowRect_down = row_down.getBoundingClientRect();

            if (rowRect_down.bottom > tableRect.bottom) {
              // Row is below the visible area, scroll down
              // console.log(rowRect_down.bottom - tableRect.bottom)
              tableRef.current.scrollTop +=
                rowRect_down.bottom - tableRect.bottom;
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedRow]);

  const on_Save = (PATTERJSON, givenInfo) => {
    let patgrp = displayData[selectedRow]["PatternGroup"];

    if (!patgrp) {
      patgrp = PATTERN_GROUP[LedgerType];
    }

    set_PATTERN_JSON(PATTERJSON);

    let FULL = PATTERN__JSONS;

    FULL[patgrp] = PATTERJSON;

    console.log(FULL);

    set_PATTERN__JSONS(FULL);

    let tosend = {
      ...displayData[selectedRow],
      ...givenInfo,
      PatternGroup: patgrp,
    };

    console.log(tosend);

    postRequest(
      `api/veranda/saveUpdatedPattern?LedgerType=${LedgerType}&PatternGroup=${patgrp}&OrgID=${Company}`,
      PATTERJSON
    ).then((res) => {
      console.log(res.res);

      postRequest(
        `api/veranda/LedgerViewUpdate?module=${localStorage.getItem(
          "module"
        )}&LedgerType=${LedgerType}&PatternGroup=${patgrp}&OrgID=${Company}`,
        tosend
      ).then((res) => {
        console.log(res.res);

        setPopupVisible(false);

        set_fetchedData([]);
        set_displayData([]);
        set_AcGroupList([]);
        set_AcClassList([]);
        set_PATTERN__JSONS({});
        set_totalDebit("");
        set_totalCredit("");

        // onFetch()
      });
    });
  };

  const handleClose = () => {
    setOpenDialogue(false);
  };

  const handleBackdropClose = (event, reason) => {
    // Prevent closing when backdrop is clicked
    if (reason && reason !== "backdropClick") {
      handleClose();
    }
  };

  const handleChange = (event, newValue) => {
    setPopupview(newValue);
  };

  useEffect(() => {
    console.log("Popup view changed to:", popupView);
  }, [popupView]);
  return (
    <div>
      {/* Dialog Component */}
      <Dialog
        open={openDialogue}
        onClose={handleBackdropClose}
        maxWidth="lg"
        fullWidth
      >
        <TabContext value={popupView}>
          <DialogTitle
            sx={{
              display: "flex", // Enable Flexbox
              alignItems: "center", // Align items vertically in the center
              justifyContent: "space-between", // Push children to both ends
              position: "relative", // Ensure absolute positioning of close icon works
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {displayData[selectedRow] &&
              displayData[selectedRow].accounttype === "CreditorsAc" ? (
                <Tab label="connection" value="connection" />
              ) : (
                ""
              )}
              {ALLOW_FOR_PATTERN.includes(LedgerType) ? (
                <Tab label="pattern" value="pattern" />
              ) : (
                ""
              )}
            </TabList>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 0,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {ALLOW_FOR_PATTERN.includes(LedgerType) ? (
              <TabPanel value="pattern">
                <Pattern_Popup
                  CreditorsAc={CreditorsAc}
                  PATTERN_JSON={PATTERN_JSON}
                  ROW={displayData[selectedRow]}
                  setPopupVisible={setPopupVisible}
                  on_Save={on_Save}
                  IM={ItemMaster}
                  VM={VendorMaster}
                  LedgerType={LedgerType}
                  BankAcClass={BankRef}
                  OrgID={Company}
                />
              </TabPanel>
            ) : (
              ""
            )}

            {CreditorsAc.accounttype &&
            CreditorsAc.accounttype === "CreditorsAc" ? (
              <TabPanel value="connection">
                <Connections
                  setBillsByOrgID={setBillsByOrgID}
                  CreditorsAc={CreditorsAc}
                  ROW={displayData[selectedRow]}
                  setOpenDialogue={setOpenDialogue}
                  LedgerType={LedgerType}
                  OrgID={Company}
                  Bills={BillsByOrgID}
                  fetchBills={fetchBills}
                />
              </TabPanel>
            ) : (
              ""
            )}
          </DialogContent>
        </TabContext>
      </Dialog>

      <div className="row mt-2">
        <div className="col-md-2">
          <label htmlFor="Company" style={{ fontSize: "1rem" }}>
            {" "}
            Company{" "}
          </label>
          <select
            className="browser-default custom-select"
            id="Company"
            autoFocus
            name="Company"
            value={Company}
            onChange={updateCompany}
          >
            <option value=""> select </option>
            {Companies.map((item, key) => (
              <option key={key} value={item.orgid}>
                {" "}
                {`${item.orgname}(${item.orgid})`}{" "}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2">
          <label htmlFor="LedgerType" style={{ fontSize: "1rem" }}>
            {" "}
            Ledger Type{" "}
          </label>

          <select
            className="browser-default custom-select"
            id="LedgerType"
            name="LedgerType"
            value={LedgerType}
            onChange={updateLedger}
          >
            <option value=""> select </option>
            {Ledger_Type.map((item, key) => (
              <option key={key} value={item}>
                {" "}
                {item}{" "}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-2">
          <label htmlFor="From" style={{ fontSize: "1rem" }}>
            {" "}
            From{" "}
          </label>{" "}
          <br />
          <input
            type="date"
            name="From"
            style={{ width: "100%" }}
            value={From}
            onChange={updateFrom}
          ></input>
        </div>
        <div className="col-md-2">
          <label htmlFor="To" style={{ fontSize: "1rem" }}>
            {" "}
            To{" "}
          </label>{" "}
          <br />
          <input
            type="date"
            name="To"
            style={{ width: "100%" }}
            value={To}
            onChange={updateTo}
          ></input>
        </div>

        <div className="col-md-2">
          <label htmlFor="KeyRef" style={{ fontSize: "1rem" }}>
            {" "}
            Key Ref{" "}
          </label>
          {/* <select className="browser-default custom-select" id="KeyRef" name="KeyRef" value={KeyRef} onChange={updateKeyRef} >
                        <option value=""> select </option>
                        { AllKeyRef.map((item, key) => 
                            <option key={key} value={item}> {item} </option>
                        ) }
                    </select> */}
          <Select
            value={KeyRef}
            onChange={(value) => {
              console.log(value);
              updateKeyRef({
                target: {
                  value: value,
                },
              });
            }}
            options={AllKeyRef.map((each) => ({
              label: each,
              value: each,
            }))}
          />
        </div>

        <div className="col-md-2 mt-4">
          <button
            className="btn btn-primary"
            ref={buttonRef}
            disabled={!LedgerType || !From || !To}
            onClick={onFetch}
          >
            Fetch
          </button>
        </div>
      </div>

      {AcClassList.length >= 1 && (
        <div className="row mt-2">
          <div className="col-md-2">
            <label htmlFor="AcGroup" style={{ fontSize: "1rem" }}>
              {" "}
              AcGroup{" "}
            </label>

            <Autocomplete
              multiple
              id={"AcGroup"}
              options={AcGroupList}
              getOptionLabel={(option) => option}
              sx={{ width: 200, fontSize: "1rem" }}
              value={SelAcGroupList}
              onChange={(event, newValue) => {
                onAcGroupFilter(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label={"AcGroup"} />
              )}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="AcClass" style={{ fontSize: "1rem" }}>
              {" "}
              AcClass{" "}
            </label>

            <Autocomplete
              multiple
              id={"AcClass"}
              options={AcClassList}
              getOptionLabel={(option) => option}
              sx={{ width: 200, fontSize: "1rem" }}
              value={SelAcClassList}
              onChange={(event, newValue) => {
                onAcClassFilter(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label={"AcClass"} />
              )}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="AcCode" style={{ fontSize: "1rem" }}>
              {" "}
              AcCode {popupVisible}
            </label>

            <Autocomplete
              multiple
              id={"AcCode"}
              options={AcCodeList}
              getOptionLabel={(option) => option}
              sx={{ width: 200, fontSize: "1rem" }}
              value={SelAcCodeList}
              onChange={(event, newValue) => {
                onAcCodeFilter(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label={"AcCode"} />
              )}
            />
          </div>
          {/* <div className='col-md-3'>
                <label htmlFor="KeyRef" style={{fontSize: '1rem'}}> KeyRef </label>

                <Autocomplete
                    multiple
                    id={"KeyRef"}
                    options={KeyRefList}
                    getOptionLabel={(option) => option}
                    sx={{ width: 200, fontSize: '1rem' }}
                    value={SelKeyRefList}
                    onChange={(event, newValue) => {
                        onKeyRefFilter(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label={"KeyRef"} />}
                />
            </div> */}
        </div>
      )}

      {displayData.length >= 1 && (
        <div>
          <div align="right">
            <CSVLink data={displayData} filename="Result.csv" target="_blank">
              <Button style={{ marginLeft: 10 }} variant="primary">
                <GetAppIcon />
              </Button>
            </CSVLink>
          </div>
          <div className={`scroll-table`} tabIndex="0" ref={tableRef}>
            <table id="table-css">
              <thead>
                <tr>
                  <th style={{ width: "1rem" }}>Txn</th>
                  <th style={{ width: "4rem" }}>Date</th>
                  <th style={{ width: "2rem" }}>JournalRef</th>
                  <th style={{ width: "2rem" }}>createdid</th>
                  <th style={{ width: "2rem" }}>ExtDocRef</th>
                  <th style={{ width: "5rem" }}>TallyLedgerName</th>
                  <th style={{ width: "4rem" }}>accounttype</th>
                  <th style={{ width: "4rem" }}>AcCode</th>
                  <th style={{ width: "4rem" }}>Item Description</th>
                  <th style={{ width: "2rem" }}>Debit</th>
                  <th style={{ width: "2rem" }}>Credit</th>
                </tr>
              </thead>
              <tbody>
                {displayData.map((item, rowIndex) => (
                  <tr
                    onClick={() => handleRowClick(rowIndex)}
                    style={
                      selectedRow === rowIndex
                        ? { backgroundColor: "lightblue" }
                        : null
                    }
                    key={rowIndex}
                  >
                    <td>
                      {item["TxnStatus"] === "Approved" ? (
                        <span style={{ fontSize: "1rem", color: "green" }}>
                          <AiFillCheckSquare />
                        </span>
                      ) : item["TxnStatus"] === "Rejected" ? (
                        <span style={{ fontSize: "1rem", color: "red" }}>
                          <AiFillCloseSquare />
                        </span>
                      ) : item["TxnStatus"] === "Waiting For Approval" ? (
                        <span style={{ fontSize: "1rem", color: "orange" }}>
                          <AiFillClockCircle />
                        </span>
                      ) : (
                        <span style={{ fontSize: "1rem", color: "orange" }}>
                          <AiFillClockCircle />
                        </span>
                      )}
                    </td>
                    <td>{item["Date"]}</td>
                    <td>{item["JournalRef"]}</td>
                    <td>{item["createdid"]}</td>
                    <td>{item["ExtDocRef"]}</td>
                    <td>{item["TallyLedgerName"]}</td>
                    <td>{item["accounttype"]}</td>
                    <td>{item["AcCode"]}</td>
                    <td>{item["itemdescription"]}</td>
                    <td style={{ textAlign: "right" }}>{item["Debit"]}</td>
                    <td style={{ textAlign: "right" }}>{item["Credit"]}</td>
                  </tr>
                ))}
                <tr>
                  <td
                    style={{
                      border: "none",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                    colSpan="9"
                  >
                    Total:
                  </td>
                  <td style={{ textAlign: "right" }}>{totalDebit}</td>
                  <td style={{ textAlign: "right" }}>{totalCredit}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {displayData.length >= 1 && selectedRow !== null && (
        <div className={`mt-3`}>
          <div className="row">
            <div className="col-md-3">
              <label style={{ fontSize: "1rem" }} className="mr-3">
                {" "}
                AcGrp{" "}
              </label>
              {displayData[selectedRow]["AcGrp"]
                ? displayData[selectedRow]["AcGrp"]
                : ""}
            </div>
            <div className="col-md-3">
              <label style={{ fontSize: "1rem" }} className="mr-3">
                {" "}
                AcClass{" "}
              </label>
              {displayData[selectedRow]["AcClass"]
                ? displayData[selectedRow]["AcClass"]
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label style={{ fontSize: "1rem" }} className="mr-3">
                {" "}
                ForPrdFrom{" "}
              </label>
              {displayData[selectedRow]["ForPrdFrom"]
                ? displayData[selectedRow]["ForPrdFrom"]
                : ""}
            </div>
            <div className="col-md-3">
              <label style={{ fontSize: "1rem" }} className="mr-3">
                {" "}
                ForPrdTo{" "}
              </label>
              {displayData[selectedRow]["ForPrdTo"]
                ? displayData[selectedRow]["ForPrdTo"]
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label style={{ fontSize: "1rem" }} className="mr-3">
                {" "}
                Sender{" "}
              </label>
              {displayData[selectedRow]["Sender"]
                ? displayData[selectedRow]["Sender"]
                : ""}
            </div>
            <div className="col-md-3">
              <label style={{ fontSize: "1rem" }} className="mr-3">
                {" "}
                Receiver{" "}
              </label>
              {displayData[selectedRow]["Receiver"]
                ? displayData[selectedRow]["Receiver"]
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label style={{ fontSize: "1rem" }} className="mr-3">
                {" "}
                CostAllocationMethod{" "}
              </label>
              {displayData[selectedRow]["CostAllocationMethod"]
                ? displayData[selectedRow]["CostAllocationMethod"]
                : ""}
            </div>
            {/* <div className='col-md-3'>
                        <label style={{fontSize: '1rem'}} className='mr-3'> Receiver </label>
                        {displayData[selectedRow]["Receiver"]}
                    </div> */}
          </div>
        </div>
      )}
    </div>
  );
}