import React, { Component } from 'react'
import { getRequest, postRequest } from '../../globalhelper/helper'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {ProgressBarLine} from 'react-progressbar-line'
import { AiOutlineRight, AiOutlineDoubleRight } from 'react-icons/ai';
import "../table.css";


import { connect } from 'react-redux'



export class Reconciliation extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        button_status : false,
        ETDS_Logs : [],
        single_Log : [],
        single_Log_ID : '',
        header : [
          {
            sort : true,
            dataField: 'data',
            text: '',
            formatter : (rowContent, row) => {
              return <div style={{fontSize: '1rem', fontWeight: "bold", textAlign: 'center', margin: '0', padding: '0'}} > 
                        <button style={{padding: ".1rem 1.5rem .4rem", border: 'none', background: 'none'}} onClick={() => this.singleLog(rowContent)}> 
                          <AiOutlineRight />
                        </button>
                    </div>
            }
          },
          {
            sort : true,
            dataField: 'id',
            text: 'ID',
            formatter : (rowContent, row) => {
              return <div style={{fontSize: '1rem', fontWeight: "bold", textAlign: 'center', padding: '.4rem', minWidth: '13rem'}} > 
                        { rowContent}
                    </div>
            }
          },       
        ],
      }
    }    
     

    componentDidMount = () => {

      getRequest('api/transactions/getETDSLogs')
      .then( res => {
        if(res.res.status !== 'error'){

          let DATA = JSON.parse(res.res.data)
          console.log(DATA)
  
          // default show the % for the first row
          this.singleLog(DATA[0].data)
  
          // if(DATA[0].cd_run_id )
  
          this.setState({ ETDS_Logs : DATA })
        }
      })
      .catch( err => {
        console.log(err)
      })

      getRequest('api/transactions/getETDSTotalLogs')
      .then( res => {
        if(res.res.status !== 'error'){

          let DATA = JSON.parse(res.res.data)
          console.log(DATA)

          let status = true

          DATA.forEach( each => {

            if(each.Status === "In Progress"){

              status = false
            }

          })

          this.setState( { button_status : status} )
          
        }
      })
      .catch( err => {
        console.log(err)
      })

    }


    singleLog = (data) => {

      let DATA = JSON.parse(JSON.stringify(data))

      let ITEM = data

      console.log(Object.entries(DATA))

      Object.entries(DATA).map( (each, i) => {

        const key = each[0]

        if(key !== 'id' && key !== 'RM2'){   

          if(data[key].Total === undefined) ITEM[key].percent = null
  
          else if(data[key].Total === 0 ) ITEM[key].percent = "No Changes"
  
          else if ( data[key].Total) ITEM[key].percent = (data[key].Success / data[key].Total) * 100;
        }

        if(key === 'RM2'){ 

          data[key].map( (each, i) => {

            if(each.Total === undefined) ITEM[key][i].percent = null

            else if(each.Total === 0 ) ITEM[key][i].percent = "No Changes"

            else if ( each.Total) ITEM[key][i].percent = (each.Success / each.Total) * 100;
          })

        }

      })

      this.setState({
          single_Log_ID: data.id,
          single_Log: ITEM
        },
        () => console.log(this.state.single_Log_ID, this.state.single_Log)
      )
    }


    startETDS = () => {

      this.setState({ button_status: false },
        () => {
          
          getRequest('api/transactions/startETDS?module=' + localStorage.getItem("module"))
          .then( res => {
            console.log(res)
          })
        })
    }


  render() {
    return (
      <div>

        <button className='btn btn-success' style={{ margin: '1rem'}} disabled={!this.state.button_status} onClick={this.startETDS} >Start Compile</button>
         <br />
         <br />
         <br />

        <div>
        {/* <h6 style={{ fontWeight: 'bold'}}>ETDS Logs</h6> */}

        <div className="row">
          
          <div className='col-md-9'>

            { this.state.single_Log_ID !== '' ?

            <>            
            <h3>{this.state.single_Log_ID}</h3>

            <br/>
            <br/>

            <div className='row'>
              
              <div className='col-md-3'>
                
                <h6 style={{ fontWeight: 'bold'}}>Sales & Returns Register</h6>
                
                { this.state.single_Log.SRR.percent === null ? 
                
                  <div>No Records</div> : this.state.single_Log.SRR.percent === "No Changes" ? 
                  
                  <ProgressBarLine value={100} text={this.state.single_Log.SRR.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7 ' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                  : 
                  <ProgressBarLine value={this.state.single_Log.SRR.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>                  
                }    

              </div>

              <div className='col-md-3'>

                <h6 style={{ fontWeight: 'bold'}}>Receipt</h6>

                { this.state.single_Log.RCPT.percent === null ? 
                
                  <div>No Records</div> : this.state.single_Log.RCPT.percent === "No Changes" ? 
                  
                  <ProgressBarLine value={100} text={this.state.single_Log.RCPT.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                  : 
                  <ProgressBarLine value={this.state.single_Log.RCPT.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>  
                
                } 
              </div>

              <div className='col-md-3'>

                <h6 style={{ fontWeight: 'bold'}}>TDS Receivable Accounts</h6>                

              { this.state.single_Log.TDSR.percent === null ? 
                
                <div>No Records</div> : this.state.single_Log.TDSR.percent === "No Changes" ? 
                
                <ProgressBarLine value={100} text={this.state.single_Log.TDSR.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                : 
                <ProgressBarLine value={this.state.single_Log.TDSR.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>  
              
              } 
              </div>

              <div className='col-md-3'>

                <h6 style={{ fontWeight: 'bold'}}>F26AS</h6>

              { this.state.single_Log.FORM26AS.percent === null ? 
                
                <div>No Records</div> : this.state.single_Log.FORM26AS.percent === "No Changes" ? 
                
                <ProgressBarLine value={100} text={this.state.single_Log.FORM26AS.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                : 
                <ProgressBarLine value={this.state.single_Log.FORM26AS.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>  
              
              } 
              </div>

            </div>

            <br/>
            <br/>
            <br/>
            <br/>

            <div className='row'>
              <div className='col-md-6'>

                <h6 style={{ fontWeight: 'bold'}}>Standard Reconciliation Instructions</h6>

                  <div className='row'>

                      <div className='col-md-6'>
                      
                          { this.state.single_Log.SRI_Instructions.percent === null ? 
                            
                            <div>No Records</div> : this.state.single_Log.SRI_Instructions.percent === "No Changes" ? 
                            
                            <ProgressBarLine value={100} text={this.state.single_Log.SRI_Instructions.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                            : 
                            <ProgressBarLine value={this.state.single_Log.SRI_Instructions.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>  
                          
                          } 

                      </div>   

                  </div>

              </div>             
            </div>

            <br/>
            <br/>
            <br/>
            <br/>

            {/* <div className='row'>
              <div className='col-md-3'>

                <h6 style={{ fontWeight: 'bold'}}>F26AS vs Sales</h6>

              { this.state.single_Log.F2S.percent === null ? 
                
                <div>No Records</div> : this.state.single_Log.F2S.percent === "No Changes" ? 
                
                <ProgressBarLine value={100} text={this.state.single_Log.F2S.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                : 
                <ProgressBarLine value={this.state.single_Log.F2S.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>  
              
              } 
              </div>

              <div className='col-md-3'>

                <h6 style={{ fontWeight: 'bold'}}>F26AS vs TDS Receivable</h6>

              { this.state.single_Log.F2T.percent === null ? 
                
                <div>No Records</div> : this.state.single_Log.F2T.percent === "No Changes" ? 
                
                <ProgressBarLine value={100} text={this.state.single_Log.F2T.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                : 
                <ProgressBarLine value={this.state.single_Log.F2T.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>  
              
              } 
              </div>
            </div> */}



            <div className='row'>
              { this.state.single_Log.RM2.map((item, index) => {
                return (
                  <div className='col-md-3'>
                    <h6 style={{ fontWeight: 'bold'}}>{item.TxnEntity}</h6>

                      { item.percent === null ? 
                        
                        <div>No Records</div> : item.percent === "No Changes" ? 
                        
                        <ProgressBarLine value={100} text={item.percent} styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: { fill:'#404040', textAlign: 'center' } }}/>
                        : 
                        <ProgressBarLine value={item.percent}  styles={{ path: { stroke: '#1E46A3' }, trail: { stroke: '#00BAF7' }, text: {  fill: '#404040', textAlign: 'center' } }}/>  
                      }
                      
                  </div>
                )
              })
            }
            
            </div>            

            </>
            
          : null
          }


          </div>

          <div className='col-md-3'>            
            {/* <BootstrapTable key="table" keyField='ComputeLog' classess="mainTable" filter={ filterFactory() }  headerWrapperClasses="BootstrapTable" data={ this.state.ETDS_Logs } columns={ this.state.header } expandRow={ this.expandRow } pagination={ paginationFactory() }/> */}

            <div className='scroll-table'>
                <table id='table-css' style={{'width': '100%', border: '.5px solid black'}} >
                    <thead>
                            <tr>
                                <th style={{'width': '3rem'}}>Compilation ID</th>                                
                            </tr>
                    </thead>
                    
                    <tbody >
                            { this.state.ETDS_Logs.map( (each, i) => {
                                
                                return(
                                    
                                <tr key={i} style={{ 'background': each.id === this.state.single_Log_ID ? '#00BAF7' : ''}}>
                                    <td>
                                      <div onClick={() => this.singleLog(each.data)} style={{ fontSize: '.9rem', padding: '.5rem', fontWeight: "bold", textAlign: 'center', minWidth: '15rem' }}>
                                      {each.id}
                                        
                                      </div>
                                    </td>                                
                                </tr>

                                ) 
                                })
                            }
                    </tbody>
                </table>
            </div>  
          </div>

        </div>

        </div>
      </div>
    )
  }
}

export default Reconciliation;