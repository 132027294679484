import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function BackDrop_Spinner(props) {

  return (
    <div>
      <Backdrop
        sx = {
          {
            color: '#fff',
            zIndex: (theme) => {return 9999999;}
          }
        }
        // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99 }}
        open={props.open}
        // onClick={props.onClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}