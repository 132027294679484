import React, {
    Component, Fragment
} from 'react';
import { connect } from 'react-redux'
import { postRequest } from '../../globalhelper/helper';
import { Modal, Button } from 'react-bootstrap'


class DisplayContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal : false,
            saveObject : {},
            saveIndex : 0
        }
        this.userData = this.props.state.login.userData;
    }

    toggleModal = (component, index) => {
        this.setState({
            showModal : true,
            saveObject : component,
            saveIndex : index
        })
    }

    handleChange= (ev, name) => {
        this.setState({
            ...this.state,
            [name] : ev.target.value
        })
    }
    
    saveElement = () => {
        console.log(this.state.saveObject)
        const BODY = {
            json  : this.state.saveObject,
            component : this.state.element_name,
            value_key : this.state.value_key
        }
        postRequest('api/visual/saveElement', BODY).then(res => {
            console.log(res);
            if(res.type === "success")
            {
                window.location.reload();
            }
        })

    }

    render() {
        return ( 
            <Fragment>
                <div className="tree-container">
                    {this.props.state.components.components.map((component,index) => 
                        this.props.state.components.component === "input" ?
                            <Fragment key={index}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width : 'fit-content'}}>
                                                <input type={component.inputType} name={component.name} id={component.name} style={{...component.style, border : '1px solid black'}} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btn btn-teal" onClick={ () => this.toggleModal(component, index) }> Next </button>
                            </Fragment>
                        : 
                        this.props.state.components.component === "dropdown" ?
                            <Fragment key={index}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width : 'fit-content'}}>
                                                <select name={component.name} id={component.name} style={component.style} >
                                                    <option value="test1">Sample Option 1</option>
                                                    <option value="test2">Sample Option 2</option>
                                                    <option value="test3">Sample Option 3</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btn btn-teal" onClick={ () => this.toggleModal(component, index) }> Next </button>
                            </Fragment>
                        : 
                        this.props.state.components.component === "image" ?
                            
                            <Fragment key={index}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width : 'fit-content'}}>
                                                <img src={component.src} alt={component.alt} style={component.style}></img>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <button className="btn btn-teal" onClick={ () => this.toggleModal(component, index) }> Next </button>
                            </Fragment>
                        : 
                        this.props.state.components.component === "label" ?
                            <Fragment key={index}>
                                
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width : 'fit-content'}}>
                                                <p style={component.style}> Test label </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btn btn-teal" onClick={ () => this.toggleModal(component, index) }> Next </button>
                            </Fragment>
                        : 
                        this.props.state.components.component === "button" ?
                            <Fragment key={index}>
                                
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width : 'fit-content'}}>
                                                <button className="btn" style={ component.style } > Text </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btn btn-teal" onClick={ () => this.toggleModal(component, index) }> Next </button>
                            </Fragment>
                        : 
                        this.props.state.components.component === "key-value" ?
                        <Fragment key={index}>
                            
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width : 'fit-content'}}>
                                                <p style={component.key_style}> Test Key : </p>
                                            </td>
                                            <td style={{ width : 'fit-content'}}>
                                                <p style={component.value_style}> Test Value </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btn btn-teal" onClick={ () => this.toggleModal(component, index) }> Next </button>
                            </Fragment>
                            : 
                            this.props.state.components.component === "table" ?
                                <Fragment>
                                    <div className="scroll-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th key={index} style={ component.key_style }> Test Header</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td key={index} style={ component.value_style }> Test Data</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                <br/>
                                <button className="btn btn-teal" onClick={ () => this.toggleModal(component, index) }> Next </button>
                                    </div>
                                </Fragment>
                            : 
                            null
                    )}
                </div>
                <Modal
                    show={this.state.showModal}
                    onHide={() => {
                        this.setState({
                            ...this.state,
                            showModal : false
                        })
                    }}
                    dialogClassName="modal-90w"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-custom-modal-styling-title"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Save Element
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div  className="row">
                            <div className="col-md-12">
                                <label htmlFor="page"> Element Name </label>
                                <input className="form-control" type="text" id="page" name="page" placeholder="Input the Name for the Design" aria-label="Search" onChange={ (ev) => this.handleChange(ev, 'element_name') } /> <br/>
                            </div>
                            {/* <div className="col-md-12">
                                <label htmlFor="value_key"> Value Key </label>
                                <input className="form-control" type="text" id="value_key" name="value_key" placeholder="Input the Name for the value key" aria-label="Search" onChange={ (ev) => this.handleChange(ev, "value_key") } /> <br/>
                            </div> */}
                        </div> 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-teal" disabled={ this.state.element_name === "" } onClick={this.saveElement}>Save</Button>
                    </Modal.Footer>
                    </Modal>

           </Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
      state
    }
  };



  export default connect(
    mapStateToProps,
)(DisplayContainer);