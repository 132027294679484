import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import React from "react";
import ReactDOM from "react-dom"; // Use ReactDOM for React 16
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import theme from "./theme";
loadProgressBar();
ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </ThemeProvider>
  </LocalizationProvider>,
  document.getElementById("root") // Mount the app to the DOM
);

// Register or unregister service worker (choose based on app requirements)
serviceWorker.unregister();
