// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';

// export default function NewDynamicTable({ data }) {
//   const [filterValue, setFilterValue] = useState(null);
//   const [uniqueFilterValues, setUniqueFilterValues] = useState([]);

//   useEffect(() => {
//     // Extract unique values for the FilterbyStudent column
//     const filterValues = [...new Set(data.map(row => row.FilterbyStudent))];
//     setUniqueFilterValues(filterValues.map(value => ({ label: value, value: value })));
//   }, [data]);

//   const handleFilterChange = (selectedOption) => {
//     setFilterValue(selectedOption);
//   };

//   const filteredData = data.filter(row => {
//     return filterValue ? row['FilterbyStudent'] === filterValue.value : true;
//   });

//   const columnNames = data.reduce((columns, row) => {
//     Object.keys(row).forEach(column => {
//       if (!columns.includes(column)) {
//         columns.push(column);
//       }
//     });
//     return columns;
//   }, []);

//   return (
//     <div>
//       <div className='col-md-3 pd-2' style={{paddingBottom: '20px'}}>
//         <label htmlFor="filterDropdown">Filter by Student:</label>
//         <Select
//           id="filterDropdown"
//           options={uniqueFilterValues}
//           isClearable
//           onChange={handleFilterChange}
//           value={filterValue}
//         />
//       </div>
//       <div className='scroll-table'>
//         <table border='1' id='table-css'>
//           <thead>
//             <tr>
//               {columnNames.map((column, index) => (
//                 <th key={index}>{column}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.map((row, rowIndex) => (
//               <tr key={rowIndex}>
//                 {columnNames.map((column, columnIndex) => (
//                   <td key={columnIndex}>{row[column] || ''}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }


import React from 'react'

export default function NewDynamicTable({data}) {
    // const columns = data.length > 0 ? Object.keys(data[0]) : []

    const columnNames = data.reduce((columns, row) => {
        Object.keys(row).forEach(column => {
          if (!columns.includes(column)) {
            columns.push(column)
          }
        })
        return columns
    }, [])

  return (
    <div className='scroll-table' style={{height:"2000px"}}>
        <table border='1' id='table-css'>
            <thead>
                <tr>
                    {columnNames.map((column, index) => (
                        <th key={index}>{column}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columnNames.map((column, columnIndex) => (
                            <td key={columnIndex}>{row[column] || ''}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
  )
}
