import { TOGGLE_SELECT_VR_SCREEN_MODE } from "../constants/action-types";

export default function treeReducer(state = { selection_mode : false }, action) {
    switch (action.type) {
        case 'receiveTreeData':
            console.log(action.treeData, "receiveTreeData");
            return {
                ...state,
                treeData: action.treeData
            }
            case 'receiveFormData':
                console.log(action, "receiveFormData")
                return {
                    ...state,
                    formData: [{
                        [action.process]: action.details
                    }]
                }
                case 'receiveAccountingData':
                    return {
                        ...state,
                        formData: [
                            state.formData[0] = {
                                ...state.formData[0],
                                [action.process]: action.treeData

                            }
                        ]
                    }
                    case 'addField':
                        return {
                            ...state,
                            treeData: action.treeData
                        }
                        case 'requestTreeData':
                            return state
                        case 'flushTree':
                            return {
                                ...state,
                                treeData: [],
                                    formData: []
                            }
                            case 'changeTreeData':
                                return {
                                    ...state,
                                    treeData: action.treeData
                                }
                            case TOGGLE_SELECT_VR_SCREEN_MODE:
                                return {
                                    ...state,
                                    selection_mode: !state.selection_mode
                                }
                                default:
                                    return state
    }
}
export const getTreeDatas = state => state.treeData;