import React, { Component } from 'react'
// import axios from 'axios'
import  ConfirmDialog, { confirmDialog } from '../../components/dialog';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ApiConstants from "../../config";
import { navUrl } from '../../actions/common_actions'
import { connect } from 'react-redux';
import { getRequest, postRequest } from '../../globalhelper/helper'
import { Animated } from "react-animated-css";
import download from 'downloadjs';
import axios from 'axios'; 
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Card, ProgressBar } from "react-bootstrap";
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import "../table.css";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});




class UploadV2 extends Component {
    constructor(props) {
      super(props)
      this.currentState = this.props.state;
      this.userData = this.currentState.login.userData;
    
      this.state = {
          listProcessOptions : [],
          system : localStorage.getItem("system"),
          percentCompleted : 0,
          isFileUploaded: false,
          showAction: false,
          showTable: false,
          allKeysInS3 : [],
          themesInDynamo: [],
          neo4jHeadings: [],
          ActionFromNeo4j: [],
          fileData: null,
          inputFileInfo: [],
          showTable2: false,
          showButton: false,
          enableButton: false,
          userSelectedFiles: [],
          PDM_Mappings : [],
          showtable3: false,
          PDMmappingData: [],
          applyToAll: "",
          allPDMCheckCompleted: false,
          radioI: null,
          formData : {
            branch :this.userData.USR_Organization,
            process : null,
            company: this.userData.USR_Organization,
            module : localStorage.getItem("module"),
            file : null,
            filename : "",
            fileSize : '0.0 B',
            exactFile : null,
            theme : "",
            action : ""
        },
        listModuleOptions: [],
        listCompanyOptions :[{ name : localStorage.getItem('organization') }],
        listBranchOptions :[{ name : localStorage.getItem('organization') }],
        template_FileNames : {
            FORM26AS: 'F26AS_Template.csv',
            TE1: 'TAN-Mapping_Template.csv',
            RCPT: 'RCPT_Template.csv',
            SRR: 'Sales_Template.csv',
            TDSR: 'TDSR_Template.csv',
        }
      }
    }

    
    navPage = (url) => {
        this.props.navUrl(url)
      }

    GetProcess = () => { 

        let module = this.state.formData.module;
        console.log(module);
        if(module !== "" && module !== null && module !== 'default1')
        {
            postRequest( 'api/module/moduleProcess', {
                tenantId : this.userData.tenant_id,
                moduleName : this.state.formData.module
            })
            .then(response => {
                if(response.res.status === "success")
                {
                    this.setState({
                        ...this.state,
                        listProcessOptions : response.res.data,

                        // when only one process

                        // formData   : {
                        //     ...this.state.formData,
                        //     process : response.res.data.length === 1 ? response.res.data[0].process : ''
                        // }
                    },() => {

                        // when only one process

                        // if(response.res.data.length === 1)
                        // {
                        //     this.getProcessDefn()
                        // }
                    })
                }
                else{
                    this.setState({
                        alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem With Connecting Server</strong></div> </Animated>
                    });
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                }
            })
            .catch(err => {
                    this.setState({
                        alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem With Connecting Server</strong></div> </Animated>
                    });
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
            })
        }
    }

    getModules = () => {
        getRequest("api/module/modules?tenantId=" + this.userData.USR_Tenant)
            .then(response => {
                if(response.res.status === "success")
                {
                this.setState({
                    ...this.state,
                    listModuleOptions : response.res.data
                }, () => {
                    this.GetProcess();
                })
            }
            })
    }

    onModuleChange = (e) => {
        console.log(e.target.value)
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                module: e.target.value
            }
        }, () => {
            this.GetProcess();
        })
    }
    
    // when page load or reload
    componentDidMount() {
        this.getModules()
    }


    setDefault = () => {
        this.setState( 
            { 
                ...this.state,
                percentCompleted : 0,
                isFileUploaded: false,
                showAction: false,
                showTable: false,
                allKeysInS3 : [],
                themesInDynamo: [],
                neo4jHeadings: [],
                ActionFromNeo4j: [],
                fileData: null,
                inputFileInfo: [],
                showTable2: false,
                showButton: false,
                enableButton: false,
                userSelectedFiles: [],
                PDM_Mappings : [],
                showtable3: false,
                PDMmappingData: [],
                applyToAll: "",
                allPDMCheckCompleted: false,
                radioI: null,
                formData: {
                    ...this.state.formData,
                    action: ""
                }

            }
        )
    }


    // when user select the process code
    onProcessCodeChange = (event) => {
        event.preventDefault();

        const VALUE = event.target.value

        // setting the state as default when change made in process select
        this.setState( 
            {  
                ...this.state,
                formData : {
                    ...this.state.formData,
                    process: VALUE,
                    action: ""
                }

            }, async () => {

                // setting state default
                this.setDefault();
                console.log(this.state.formData.process)                
                const FORMDATA = this.state.formData;
                const getallS3Files = await getRequest(`api/upload/gets3files?process=${FORMDATA.process}`);
                const getThemes = await getRequest(`api/upload/getThemes?module=${FORMDATA.module}&process_code=${FORMDATA.process}`);
                const getKGMapping = await getRequest(`api/upload/getKGHeadings?process=${FORMDATA.process}&module=${FORMDATA.module}`);
                const getActionFromNeo4j = await getRequest(`api/upload/getActionFromNeo4j?process=${FORMDATA.process}&module=${FORMDATA.module}`);

    
                /// multiple promises 
                Promise.all([getallS3Files, getThemes, getKGMapping, getActionFromNeo4j])
                // response.type
                // response.res
                // when all promises are resolved
                .then( res => {
                    res.map( (each, i) => {
                        
                        if(each.res.status ==="error"){
                            console.log("Error=", i)
                        }
                        else if (each.res.status === "success"){
                            
                            if( i === res.length -1){
                                let action = res[3].res.data
                                
                                this.setState({
                                    allKeysInS3: JSON.parse(res[0].res.data),
                                    themesInDynamo: res[1].res.data,
                                    neo4jHeadings: JSON.parse(res[2].res.data),
                                    ActionFromNeo4j: action.filter( one => one !== "Delete" && one !== "Replace"),
                                    showTable: true,
                                    showAction: true
                                    
                                }, () => {
                                    console.log(this.state.neo4jHeadings, "neo4jHeadings");
                                    console.log(this.state.ActionFromNeo4j, "ActionFromNeo4j");
                                    console.log(action, "ActionFromNeo4j");
                                    if(this.state.ActionFromNeo4j.length === 1){
                                        this.setState({
                                            ...this.state,
                                            formData: {
                                                ...this.state.formData,
                                                action: action[0]
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })

                })
                .catch( err => {
                    console.log( err, 'Catched Err')
                })

            })
    }   

  

    // when user select the file that shows from s3 folder to ask replace or over write
    onFileSelect = (index, event) => {
        const VALUE = event.target.checked;
        console.log(index, VALUE)
        
        
        this.setState( prevState => {
            let allKeysInS3 = prevState.allKeysInS3;

            allKeysInS3[index].checked = VALUE;
            
            let userSelectedFiles = prevState.userSelectedFiles
            
            if(VALUE === true) {
                
                userSelectedFiles.push(allKeysInS3[index].Key);
                
            }
            else if(VALUE === false){

                let removeIndex = userSelectedFiles.indexOf(allKeysInS3[index].Key);
                console.log(removeIndex, 'removeIndex')
                
                userSelectedFiles.splice(removeIndex, 1)
            }
            
            userSelectedFiles = [ ...new Set( userSelectedFiles )]
            
            return { userSelectedFiles, allKeysInS3 };
        },
        
        () => console.log(this.state.userSelectedFiles)       

        )

    }        



    // changing the file size format from bytes to MB
    bytesToSize = (bytes) =>  {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) { bytes = 'n/a'}  
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
        if (i === 0) { bytes = `${bytes} ${sizes[i]})`}
        return bytes = `${(bytes / (1024 ** i)).toFixed(3)} ${sizes[i]}`
    }

    
    
    // only for file upload fn
    fileUpload = () => {

        const fileData =  [...this.state.fileData]

        if(fileData.length !== 0){

            // checking all input file format is whether .txt or .xlsx or .csv otherwise show error
            for (let i = 0; i < fileData.length; i++) {

                let check = fileData[i]
                if(check.name.split('.').pop()=== 'txt' || check.name.split('.').pop()=== 'csv' || check.name.split('.').pop()=== 'xlsx'|| check.name.split('.').pop()=== 'xls') {
                    
                }
                else{

                    console.log(`".${check.name.split('.').pop()}" this is un-supported file format`)
                    this.setState( { fileData: "Err", showTable2: false } )
                    return;
                }
            }
    
            const data = new FormData()

            // sending file to back-end
            if(fileData !== 'Err'){

                for (let i = 0; i < fileData.length; i++) {

                    data.append('files', fileData[i]);
                    // data.append('files', this.state.formData[0])
                }    
    
                postRequest("api/upload/multiUpload", data)
                .then( res => {
                    console.log(res.res.data)



                    // showing the table after file uploaded
                    this.setState( prevState => {
                
                        let fileData = prevState.fileData
                        
                        if( fileData.length !== 0 && fileData !== "Err" ) {
                            
                            let inputInfo = []
            
                            for (let i=0; i < fileData.length; i++) {    
        
                                inputInfo[i] = {
        
                                    fileName : fileData[i].name,
                                    theme : "",
                                    red : "",    
                                    // changing the input file size from bytes to MB to display UI
                                    sizeToMB : this.bytesToSize(fileData[i].size)
        
                                }                                                 
                            }                        
                                
                            return { inputFileInfo: inputInfo, showTable2: true, isFileUploaded : true }
                            
                        }
                        else {
        
                            return { inputFileInfo: [], showTable2: false, isFileUploaded: false }
                                                                   
                        }
        
                    }, () => {
                        if(this.state.inputFileInfo.length === 1 && this.state.themesInDynamo.length === 1){
                            
                            this.setState( prevState => {

                                let inputFileInfo = prevState.inputFileInfo
                    
                                inputFileInfo[0].theme = this.state.themesInDynamo[0].theme
                    
                                return { inputFileInfo, allPDMCheckCompleted: false }
                    
                            }, () => {

                                this.setState( { applyToAll: "no" }, () => {

                                    // sending the particular file to back-end to check PDM mapping    
                                    this.PDMCheck( this.state.inputFileInfo[0], 0)
                                }) 
                                
                            })
                            
                        }
                    })

                    
                })    
                .catch( err => {
                    console.log(err)
                })    
            }       
        }
    }
    
      
    // When user select the file in their local system and click open
    fileInputOnChange = event => {

        const FILES = event.target.files
        // console.log(FILES)       

        this.setState( { fileData : FILES, enableButton: false, showtable3: false, PDMThemeData: [] },
            
            () => {
            
                // console.log(this.state.fileData,'fileData')
                // console.log(this.state.inputFileInfo, 'inputFileInfo')


                this.fileUpload()  

            }
            
            )            

    }


    // when user select the theme    
    themeOnChange = (index, event) => {
        
        const VALUE = event.target.value
        // console.log(VALUE)
        // console.log(index)

        this.setState( prevState => {

            let inputFileInfo = prevState.inputFileInfo

            inputFileInfo[index].theme = VALUE

            return { inputFileInfo, allPDMCheckCompleted: false }

        } , () => {


            let inputFileInfo = JSON.parse(JSON.stringify(this.state.inputFileInfo))

            // For first file theme select            
            if(index === 0 && inputFileInfo.length !==1 ) {    
    
                confirmDialog(`Do you want to apply "${VALUE}" theme for all ${inputFileInfo.length} files?`, 

                // if user click 'yes' to apply same theme to all
                () => {
                    console.log('yes')

                        // in this fuction when user selected apply the same theme for all 70 or 80 no need to query from every 70 times from dynamo so take theme details first and then send req with that theme details also reduces $cost and time
                        getRequest(`api/upload/getMappingsFromDynamo?theme=${inputFileInfo[0].theme}&processCode=${this.state.formData.process}`)
                        .then( (res) => {
    
                            this.setState ( { PDM_Mappings : JSON.parse(res.res.data), applyToAll: "yes" }, () => {


                                for(const [j] of inputFileInfo.entries() )  {
                                    
                                    inputFileInfo[j].theme = VALUE
                                    inputFileInfo[j].red = ''


                                    if( j === inputFileInfo.length -1 ){


                                        this.setState( { inputFileInfo : inputFileInfo }
                                            
                                        , () => {

                                            for(const [k] of this.state.inputFileInfo.entries() ) {

                                                
                                                // sending the particular file to back-end to check PDM mapping    
                                                this.PDMCheck(this.state.inputFileInfo[k], k)
                                            }
    
                                        })                                 
                                            
                                    }                                

                                }
                                        
                            })
    
                        })    
                             
                } ,


                //if user click 'no'    
                () => {
                    console.log('no')

                    this.setState( { applyToAll: "no" }, () => {

                        // sending the particular file to back-end to check PDM mapping    
                        this.PDMCheck(this.state.inputFileInfo[index], index)
                    })
                }
                );       
            }
                

            // For except first file theme select              
            else if( index !== 0 || this.state.inputFileInfo.length === 1 ) {
                    
                this.setState( { applyToAll: "no" }, () => {

                    // sending the particular file to back-end to check PDM mapping    
                    this.PDMCheck( this.state.inputFileInfo[index], index)
                })   
                
            }           
        })
    }
   

// sending the particular file to back-end to check PDM mapping that all columns are present or not, if not show error
PDMCheck = (inputFileInfo, index) => {

    const DATA = {
        inputFileInfo: inputFileInfo,
        applyToAll: this.state.applyToAll,
        PDM_Mappings: this.state.PDM_Mappings,
        neo4jHeadings: this.state.neo4jHeadings,
        processCode: this.state.formData.process,
        system: this.state.system,
    }
    postRequest("api/upload/PDMcheck", {data : JSON.stringify(DATA)})
        .then((res) => {
            if (res.type === "success") {
                // console.log(res.res.data)
                console.log(index)

                this.setState(prevState => {

                        let PDMmappingData = prevState.PDMmappingData
                        PDMmappingData[index] = res.res.data


                        let inputFileInfo = prevState.inputFileInfo

                        // if columns missing
                        if (res.res.data.MappingData[0] === 'REQUIRED COLUMN MISSING') {
                            inputFileInfo[index].red = "red"
                        }
                        // if all columns are present
                        else {
                            inputFileInfo[index].red = "no"
                        }


                        return {
                            PDMmappingData,
                            inputFileInfo,
                            showtable3: true
                        }

                    },

                    () => {

                        this.setState(prevState => {

                            let enableButton = prevState.enableButton
                            let inputFileInfo = prevState.inputFileInfo
                            let allPDMCheckCompleted = prevState.allPDMCheckCompleted
                            let showButton = prevState.showButton


                            // checking all files not in red ( i mean all columns are present ) to enable upload button, if enableButton is that means all prefectly set ready to upload
                            for (let j = 0; j < inputFileInfo.length; j++) {

                                if (inputFileInfo[j].red !== '' && inputFileInfo[j].red === 'no') {

                                    enableButton = true
                                } else {

                                    enableButton = false
                                    break;
                                }
                            }

                            // checking PDM is mapping is completed for all files or not if not completed dont allow user to delete input file from screen bcoz it collapse the array length's
                            for (let j = 0; j < inputFileInfo.length; j++) {

                                if (inputFileInfo[j].red !== '') {

                                    allPDMCheckCompleted = true
                                    showButton = true
                                } else {

                                    allPDMCheckCompleted = false
                                    showButton = false
                                    break;
                                }
                            }

                            return {
                                enableButton,
                                showButton,
                                allPDMCheckCompleted
                            }
                        })


                    })
            }

        })
        .catch(err => {
            console.log(err)
        })
}



    // when user click on delete icon of any file
    InputFileDelete = ( index ) => {

        
    console.log( this.state.fileData, 1)
    console.log( this.state.inputFileInfo, 2)

        this.setState( { radioI : null })

        let fileData = [...this.state.fileData]
        fileData.splice(index, 1)

        let inputFileInfo = [...this.state.inputFileInfo]
        inputFileInfo.splice(index, 1)

        let PDMmappingData = [...this.state.PDMmappingData]
        PDMmappingData.splice(index, 1)

        
        this.setState( { fileData, inputFileInfo, PDMmappingData }, 
            
            () => {

                console.log( this.state.fileData, 1)
                console.log( this.state.inputFileInfo, 2)

                this.setState( prevState => {
    
                    let inputFileInfo = prevState.inputFileInfo
                
                
                    if(inputFileInfo.length === 0){
                        
                        return { showButton : false, isFileUploaded : false, showTable2 : false, enableButton: false, showtable3: false, allPDMCheckCompleted: false } 
                    }                        
                    else {

                        let enableButton = prevState.enableButton

                        for( let i=0; i < inputFileInfo.length ; i++ ) {
                            
                            if(inputFileInfo[i].red === 'no' ) {
                                
                                enableButton = true                                 
                            }

                            else {
                                
                                enableButton = false 
                                break;                                
                            }
                        }  
                        
                        return { enableButton }
                    }                            
                    
                })   

            })                
    }
   



    // when user change the PDM mapping manually in UI
    finalMappingOnChange = ( index , event) => {

        const radioI = JSON.parse(JSON.stringify(this.state.radioI))

        console.log(radioI,'radioI')
        console.log(index,'index')


        this.setState( prevState => {

            let PDMmappingData = prevState.PDMmappingData

            for(let j=0; j < PDMmappingData[radioI].MappingData.length; j++ ){
    
                if(PDMmappingData[radioI].MappingData[j].inputSourceFieldName === event.target.value ){
    
                    alert(`"${event.target.value}" is already mapped with ${PDMmappingData[radioI].MappingData[j].TargetFieldName}.  You can't map again!!`);
                }
            }

            PDMmappingData[radioI].MappingData[index].inputSourceFieldName = event.target.value

            return { PDMmappingData }

        }, () =>  console.log(this.state.PDMmappingData[radioI]) 
        )            
    }


    s3fileAction = (e) => {

        const VALUE = e.target.value, NAME = e.target.name;
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        }, () => console.log(this.state.formData.action))

    }


    // when user click on radio button for input files to view the PDM mappings
    onChangeRadioButton = (index) => {

        this.setState( { radioI: index }, () => {

            console.log(this.state.radioI)
        })
    }


    // starting the PDM
    startPDM = () => {
        const sDATA = {
            processCode : this.state.formData.process,
            action : this.state.formData.action,
            inputFileInfo : this.state.inputFileInfo,
            userSelectedFiles : this.state.userSelectedFiles,
            Store_Action_Capture : true
        }
        postRequest("api/upload/startPDM", {data : JSON.stringify(sDATA)})
        .then( res => {
            console.log(res.res.data)
            // this.navPage('/transactions')
        })
        .catch( Err => {
            console.log(Err)
        })
    }


    // before pdm store raw file in s3
    preTransaction = () => {

        const data = new FormData()

        // sending file to back-end
        for (let i = 0; i < this.state.fileData.length; i++) {

            data.append('files', this.state.fileData[i]);
            // data.append('files', this.state.formData[0])
        }    

        // data.append("process_code", this.state.formData.process)

        postRequest("api/upload/pretransaction?process_code=" + this.state.formData.process, data)
        .then( res => {
            console.log(res.res.data)

            // starting the PDM
            this.startPDM()

        })    
        .catch( err => {
            console.log(err)
        })    
    }


    Upload = () => {

        // if( this.state.formData.action === 'Delete' || this.state.formData.action === 'IWI' || this.state.formData.action === 'replace' ){
        if( this.state.formData.action === 'Delete' || this.state.formData.action === 'Replace' ){

            if( this.state.userSelectedFiles.length === 0) {

                let action = this.state.formData.action
                // let action = this.state.formData.action === 'replace' ? 'Replace' : this.state.formData.action === 'IWI' ? 'Inactive With Input File' : this.state.formData.action === 'Delete' ? 'Inactive Without Input File' :  ''

                alert(`You've selected Action: "${action}". So please select files in the list.`);

            }
            else {

                
                this.preTransaction()
                this.navPage('/transactions')

            }

        }

        // else if( this.state.formData.action !== 'Collate' || this.state.formData.action !== 'Update' ){
        else {
            
            this.preTransaction()
            this.navPage('/transactions')

        }
    }


    downloadTemplate = () => {

        const fileName = this.state.template_FileNames[this.state.formData.process]
        

        axios.post(ApiConstants.externals.serverUrl +  "api/upload/downloadTemplateFiles",
          {
            filename: fileName,
          },
          { responseType: 'blob' }
        )
        .then(response => {
            console.log("response", response)
                const blob = response.data;
                download(blob, fileName);
        })
    }

    


  render() {
    return (
      <div>
        <div className='row'>
            <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="company">Company</InputLabel>
                    <Select labelId="company" disabled id="company" value={ this.state.formData.company } label="Company" >
                        {/* <MenuItem value="" disabled >Company</MenuItem> */}
                            { this.state.listCompanyOptions.map( each => <MenuItem key={each.name} value={each.name}>{each.name}</MenuItem> )}
                    </Select>

                    {/* <select  id="company" value={ this.state.formData.company } >
                        <option disabled defaultValue>Company</option>
                        { this.state.listCompanyOptions.map(opt => 
                            <option key={opt.name} value={opt.name}> {opt.name} </option> )
                        }
                    </select> */}
                </FormControl>
            </div>
            
            <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="branch">Branch</InputLabel>
                    <Select labelId="branch" disabled id="branch" value={ this.state.formData.branch } label="Branch" >
                        {/* <MenuItem value="" disabled >Branch</MenuItem> */}
                            { this.state.listBranchOptions.map( each => <MenuItem key={each.name} value={each.name}>{each.name}</MenuItem> )}
                    </Select>
                    {/* <select  id="branch" disabled={ this.state.listBranchOptions.length === 1 ? true : false } onChange={ this.handleChange } value={ this.state.formData.branch } >
                        <option disabled defaultValue>Branch</option>
                        { this.state.listBranchOptions.map(opt => 
                            <option key={opt.name} value={opt.name}  defaultValue={ this.state.listBranchOptions.length === 1 ? true : false }> {opt.name} </option> )
                        }
                    </select> */}
                </FormControl>
            </div>
            
            <div className='col-md-4'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>

                    <InputLabel id="module">Module</InputLabel>
                    <Select labelId="module" id="module" value={ this.state.formData.module } label="Module" onChange={this.onModuleChange} >
                        {/* <MenuItem value="" disabled >Module</MenuItem> */}
                            { this.state.listModuleOptions.map( each => <MenuItem key={each.name} value={each.name}>{each.name.split("_").join(" ") + " [ " + each.desc +"] "}</MenuItem> )}
                    </Select>
                {/* <InputLabel id="demo-simple-select-standard-label">Select a Process Code</InputLabel> */}
                    {/* <select  id="module" onChange={ this.handleChange } value={ this.state.formData.module }>
                        <option disabled value="default1" selected>Module</option>
                        { this.state.listModuleOptions.map(opt =>
                            <option key={opt.name} value={opt.name} selected={ this.state.listModuleOptions.length === 1 ? true : (  false)} > {opt.name.split("_").join(" ") + " [ " + opt.desc +"] "} </option> )
                        }
                    </select> */}
                </FormControl>
            </div>

        </div>

        <div className='row' style={{ marginBottom: "3rem"}}>
    
            <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="process_code">Select a Process</InputLabel>
                    <Select labelId="process_code" id="process_code" disabled={ this.state.isFileUploaded } value={this.state.formData.process} onChange={this.onProcessCodeChange} label="Select a Process Code" >
                    {/* <MenuItem value="" disabled >Select a Process</MenuItem> */}
                            { this.state.listProcessOptions.map( each => <MenuItem key={each.process_code} value={each.process_code}>{each.short_text} ({each.process_code})</MenuItem> )}
                    </Select>
                </FormControl>
            </div>

          <ConfirmDialog />
          

          { this.state.showAction  ?

          <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="action">Select Action</InputLabel>
                    <Select labelId="action" id="action" name="action" disabled={ this.state.isFileUploaded } value={this.state.formData.action} onChange={this.s3fileAction} label="Select Action" >
                    {/* <MenuItem value="" disabled >Select</MenuItem> */}
                    {/* <MenuItem value="collate" >Collate</MenuItem>
                    <MenuItem value="update" >Insert / Update</MenuItem>
                    <MenuItem value="replace" >Replace</MenuItem>
                    <MenuItem value="IWI" >Inactive With Input File</MenuItem>
                    <MenuItem value="IWOI" >Inactive Without Input File</MenuItem> */}
                    {this.state.ActionFromNeo4j.map( each => <MenuItem value={each} >{each}</MenuItem> )}
                    </Select>
                </FormControl>             
          </div>
          
          :  null    
          }   

              {/* <div className='col-md-6' style={{ marginTop: "2rem"}}> */}

                {this.state.showTable && this.state.formData.action !== '' && this.state.formData.action !== 'Delete' ?

                <>
                    {/* <div className='col-md-3' style={{ marginTop: "2rem"}} align="center">
                        <label htmlFor="file">
                            <input style={{ display: 'none' }} id="file" type="file" name='files' accept='.csv, .xlsx, .xls, .txt' onChange={this.fileInputOnChange} disabled={ this.state.isFileUploaded } multiple/>
                            <Fab color="primary" size="small" component="span" aria-label="add" variant="extended" disabled={ this.state.isFileUploaded }>
                                <AddIcon  /> Upload Files
                            </Fab>
                        </label>                
                                    
                    </div> */}

                    <div className='col-md-3' align="center">
                         <Button component="label" variant="contained" sx={{ m: 1, marginTop: 4 }} startIcon={<CloudUploadIcon />} disabled={ this.state.isFileUploaded }>
                            Upload Files
                            <VisuallyHiddenInput id="file" type="file" name='files' accept='.csv, .xlsx, .xls, .txt' onChange={this.fileInputOnChange} disabled={ this.state.isFileUploaded } multiple/>
                        </Button>             
                                    
                    </div>

                    { this.state.formData.module === "OneRetail" ? 
                        <div className='col-md-3' style={{ marginTop: "2rem"}} align="center">
                        <Card>
                            <Card.Header className="card-header"> 
                                        <div className="row" style={{ marginLeft: "0px" }}>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <b className="text-white"> Download Template</b>
                                                </div>                                           
                                            </div>                                       
                                        </div>
                            </Card.Header>
                            <Card.Body className="card-body">
                                { this.state.formData.process ? 
                                    <div>
                                        <Card.Text className="text-center">
                                            { this.state.template_FileNames[this.state.formData.process] ? 
                                            <div>
                                                {this.state.template_FileNames[this.state.formData.process]}
                                                <GetAppIcon style={{ cursor :"pointer" }} onClick={ this.downloadTemplate } /> 
                                            </div> 
                                            :
                                            <h6> Template Not Found </h6>
                                            }
                                        </Card.Text>
                                    </div>
                                    : (
                                        <div>
                                            <h6 className="text-center"><Card.Title>Select a Process above</Card.Title></h6>
                                        </div>
                                    )
                                }   
                            </Card.Body>
                        </Card>
                        </div>
                    : null
                    }

                    
                </>

                    
                    : null  
                }    

            {/* </div> */}

        </div>



        <div className='row' >

        { this.state.showTable2 && this.state.formData.action !== '' && this.state.formData.action !== 'Delete' ?

        <div className= 'col-md-6'>

            <h3 style={{'fontSize': '1.5rem', 'fontWeight': '700', 'letterSpacing': '0.05em'}}
            >Select Theme For New Files</h3>

            <div className='scroll-table'>

            <table id='table-css' style={{'width': '100%'}}>
                <thead>
                    <tr>
                        <th style={{'width': '30px'}}></th>
                        <th style={{'width': '40px'}}>No</th>
                        <th>File Name</th>
                        <th style={{'width': '130px'}}>Size</th>
                        <th style={{'width': '80px'}}>Theme</th>
                        <th style={{'width': '60px'}}>Delete</th>
                    </tr>
                </thead>    

                <tbody>
                    { this.state.inputFileInfo.map( (each, i) => {
                        
                        return(

                            // <tr  key={i} style={ { 'color': each.red === 'red' ?  'red': '', 'background': each.red === 'red' ? '#fde2e4' : ''} }>                                    
                            <tr  key={i} style={ { 'color': each.red ==='red' ? 'red': each.red === 'no' ? 'green' : '' , 'background': each.red === 'red' ? '#fde2e4' : each.red === 'no' ? '#d0f4de' : ''} }>                                    
                            
                                <td><input type="radio" name={i} id={i} disabled={each.red === ''} checked={this.state.radioI ===i } onChange={ () => this.onChangeRadioButton( i )}></input></td>                                    
                                <td>{i+1}</td>
                                <td>{each.fileName}</td>
                                <td>{each.sizeToMB}</td>
                                <td>
                                    <select name="theme" id="theme" value={each.theme} onChange={(e) => this.themeOnChange( i, e )}>
                                        <option value="" disabled >Select</option>

                                        { this.state.themesInDynamo.map( (each) => <option key={each.theme} value={each.theme}>{each.theme}</option> )  }
                                        
                                    </select>
                                </td>
                                <td>
                                <IconButton disabled={ !this.state.allPDMCheckCompleted } onClick={ () => this.InputFileDelete( i ) } style={{'color': !this.state.allPDMCheckCompleted ? '#ffb3c1' :'red'}} aria-label="delete" >
                                    <DeleteIcon />
                                </IconButton>
                                </td>
                            </tr>

                        ) 
                    })                    
                    }

                </tbody>

            </table>
            </div>
        </div>   
        :   
        <div>
        { this.state.fileData === "Err" ?
        <div>
        <h1>Un-supprted file format!! Please upload only .txt( tsv ) , .csv and .xlsx</h1>
        <h2>Kindly, Refresh the Page and Re-Upload the files in Right format</h2>
        </div>

        : null}           
        </div>

        }


        {/* displaying all file name from Tenant ID(AB00009) / process folder name (EH) in S3 */}


        { (this.state.showTable && this.state.showTable2 && this.state.formData.action !== '' && this.state.formData.action !== 'Collate' && this.state.formData.action !== 'Update') || this.state.formData.action === 'Delete' ?
        

        <div className='col-md-6'>  

            <h3 style={{'fontSize': '1.5rem', 'fontWeight': '700', 'letterSpacing': '0.05em'}}
            >Select Files You Want to Replace / Inactive</h3> 

            <div className='scroll-table'>

                <table id='table-css' style={{'width': '100%'}} >
                    <thead>
                            <tr>
                                <th style={{'width': '40px'}}>No</th>
                                <th>File Name</th>
                                <th style={{'width': '60px'}}>Select</th>
                                <th style={{'width': '200px'}}>Last Modified</th>
                                <th style={{'width': '130px'}}>Size</th>
                            </tr>
                    </thead>
                    
                    <tbody >
                            { this.state.allKeysInS3.map( (each, i) => {
                                
                                return(
                                    
                                <tr  key={i} style={{ 'background': each && each.checked ? '#ccdbfd' : '' }} >
                                    <td className='no'>{i+1}</td>
                                    <td>{each.Key}</td>
                                    <td className='check'><input type="checkbox" onChange={ (e) => this.onFileSelect( i , e )} defaultChecked={each.checked}></input></td>
                                    <td>{each.LastModified}</td>
                                    <td>{each.Size}</td>
                                </tr>

                                ) 
                                })
                            }
                    </tbody>    

                </table>
            </div>  
        </div> 
            :   null   
        }


        </div>

        


        {
            this.state.showtable3 && this.state.formData.action !=='Delete' && this.state.radioI !== null && this.state.inputFileInfo[this.state.radioI].red !== '' ? 

            <div className='mt-5'>
                
                {
                    this.state.PDMmappingData[this.state.radioI].MappingData[0] === 'REQUIRED COLUMN MISSING' 
                    ?
        
                    <div>
                    <h3>{this.state.radioI+1}) File Name : {this.state.inputFileInfo[this.state.radioI].fileName}</h3>
                    <h1 style={{'color': 'red'}}> "Error" - Required columns MISSING</h1>
                    <ul>
                        <li style={{'listStyle' : 'none'}}>Missing Columns as per the theme you've selected</li>
                        { this.state.PDMmappingData[this.state.radioI].MappingData.map( (each, i)  =>  <li style={ { 'listStyle' : 'none', 'display': i === 0 ? 'none' : '' } } key={each} >{i}. {each}</li> ) }
                    </ul>
                    </div>
        
                    :

                    <div className='row' >

                        <div className='col-md-2'></div>

                        <div className='col-md-8'>

                        <h3 style={{'fontSize': '1.5rem', 'fontWeight': '700', 'letterSpacing': '0.05em'}}
                        >{this.state.radioI+1}) File Name : {this.state.inputFileInfo[this.state.radioI].fileName} - PDM Mapping</h3>

                        <div className='scroll-table'>

                        <table id='table-css' style={{'width': '100%'}} >

                            <thead>
                            <tr>
                                <th style={{'width': '50px'}}>No</th>
                                <th style={{'width': '390px'}}>Standardised Header</th>
                                <th style={{'width': '430px'}}>Input File Header</th>
                            </tr>
                            </thead>
                        
                            <tbody>
                               
                                { this.state.PDMmappingData[this.state.radioI].MappingData.map( (each, i) => {                                    
                                    
                                    return(
                                    
                                        <tr key={each.short_text} >

                                            <td>{i+1}</td>
                                            <td>
                                            {each.short_text}
                                            </td>
                                            
                                            <td>

                                            <select value={each.inputSourceFieldName} onChange={ (e) => this.finalMappingOnChange( i , e)} >
                                                <option value="" disabled>Choose </option>
                                                {
                                                this.state.PDMmappingData[this.state.radioI].HeaderArray.map( (each) =>  <option key={each} value={each} disabled>{each}</option> )
                                                }  
                                            </select>
                                            </td>

                                        </tr>
                                    )
                                })
                                }        
                            </tbody>            

                        </table>
                        </div>
                        </div>
                        <div className='col-md-2'></div>
                    </div>                          
                }


            </div>

            
        : null

        }       


        <div className='row mt-3 mb-3'>
           

            { this.state.showButton && this.state.formData.action !== 'Delete' ? 
                <div  className='col-md-12' align="center">
                <button disabled={ !this.state.enableButton } onClick={this.Upload}  className='btn btn-success'> Upload </button>
                </div>
                :  
                null    
            }       
            { this.state.formData.action === 'Delete' ? 
                <div  className='col-md-12' align="center">
                <button  disabled={ this.state.userSelectedFiles.length === 0 } className='btn btn-success'> Inactivate </button>
                </div>
                :  
                null    
            }       

        </div> 

         

      </div>
    )
  }
}


function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(
    mapStateToProps,
    { navUrl }
)(UploadV2);