import React, { Component } from 'react';
import { getCurrentDate, checkValidDate, getStandardDate, getYearFromDate } from '../globalhelper/helper';

class MyLable extends Component {
    constructor(props) {
        super(props);
        this.state = { username: '', age: '', gender: '', address: '' };
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

    }
    mySubmitHandler = (event) => {
        event.preventDefault();
    }
    componentDidMount = () => {
        console.log(this.props)
    }

    getLableOutput = (value) => {
        if(typeof value === "string")
        {
            if (checkValidDate(value) && getYearFromDate(getStandardDate(value)) <= 9999  && getYearFromDate(getStandardDate(value)) >= 1800)
          {
              return value.split("T")[0] === "1800-01-01" || value.split("T")[0] === "2100-12-31" ? "" : getStandardDate(value) 
          }
          else{
              return value.split("_").join(" ")
          }
        }
        else if(typeof value === "number")
        {
          if(Number.isInteger(value))
          {
            return <p className="number-format"> { value.toFixed(2)} </p>;
          }
          else{
            var d = Math.round((value + Number.EPSILON) * 1000) / 1000
            return <p className="number-format"> { Number(d).toLocaleString() } </p>
          }
        }
        else if(typeof value === "date")
        {
            return value.split("T")[0]
        }
    }

    render() {
        return (
            <div className='view-container' >
                {this.props.tree.children.map((treenode, index) => {
                    // console.log(treenode);
                        if (treenode.type === 'section' && treenode.view === 'lable' && treenode.enable && this.props.formData) {
                            return (<div className='lable-container' key={index}>
                                    {
                                        treenode.children.map((childrenNode, index) => {
                                            if (childrenNode.position === 'header' && childrenNode.type === 'lable' && childrenNode.enable === true && childrenNode.children === undefined && childrenNode.title) {
                                                    return (<div key={index} className="row ">
                                                        {/* this.props.formData[0][treenode.title][0] */}
                                                        <div className="col-md-7">
                                                            <p>{childrenNode.title}</p>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <p>:</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <p> { this.props.formData[0][treenode.title] ? this.getLableOutput(this.props.formData[0][treenode.title][0][childrenNode.serviceKey]) : null} </p>
                                                        </div>
                                                </div>
                                                )
                                            }
                                        })
                                    }
                                    <div className='section-title'>{treenode.short_text}</div>

                                    <div className='row'>
                                        {/* <form className="row"> */}
                                            {
                                                treenode.children.map((childrenNode, index) => {
                                                    if ((childrenNode.position === undefined || (childrenNode.position !== 'header' && childrenNode.position !== 'footer'))   && childrenNode.enable === true && childrenNode.title !== '' && childrenNode.title !== '' && !treenode.title.includes(childrenNode.serviceKey)) {
                                                        // console.log(childrenNode.serviceKey, treenode.title, "treenode.title")
                                                            return (
                                                            <div key={index} className='col-md-6'>
                                                                {
                                                                    (childrenNode.children === undefined && childrenNode.type === 'lable' && childrenNode.enable === true) ? 
                                                                childrenNode.title !== null ?
                                                                <div className="row flex-nowrap" >
                                                                    <div className="col-md-7">
                                                                        <b className="text-lightgray">{childrenNode.title} </b> 
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <b>:</b> 
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        { this.props.formData[0] ? this.props.formData[0][treenode.title] && this.props.formData[0][treenode.title][0] ?  this.getLableOutput(this.props.formData[0][treenode.title][0][childrenNode.serviceKey]) : null : null }
                                                                    </div>
                                                                    </div>
                                                                    :  null
                                                                    :  null
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    else{
                                                        return (null)
                                                    }
                                                })
                                            }
                                            {/* <input type='submit' className="btn btn-info"/>
                                            <div>
                                                {
                                                    treenode.children.map((childrenNode, index) => {
                                                        if (childrenNode.position === 'footer' && childrenNode.type === 'field') {
                                                            return (<div className="row p-3 d-flex flex-row-reverse">
                                                                <input type='text' className="col-lg-2" name="{childrenNode.title}" onChange={this.myChangeHandler} />
                                                                <div className="col-lg-2">{childrenNode.title}:</div>
                                                                
                                                            </div>
                                                            )
                                                        }
                                                    })
                                                } */}
                                            {/* </div> */}
                                            
                                        {/* </form> */}
                                    </div>
                                </div>)
                            }
                        // })
                }
                )}


            </div >
        );
    }
}
export default MyLable

