import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ApiConstants from "../../config";
import './transactions.css'
import {Animated} from "react-animated-css";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios'; 
import GetAppIcon from '@material-ui/icons/GetApp';
import {  faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download from 'downloadjs';
import filterFactory from 'react-bootstrap-table2-filter';
import { postRequest } from '../../globalhelper/helper'
import { connect } from 'react-redux'
import ScheduleIcon from '@material-ui/icons/Schedule';
import moment from 'moment'


class Transactions extends Component {

  constructor (props){
    super(props);
    this.currentState = this.props.state;
    // console.log(this.props)
    this.userData = this.currentState.login.userData;
    this.state = {
      // tab : this.userData.USR_Tenant === "AB00009" || localStorage.getItem("module") === "OneRetail" ? "pdm" : "tnx",
      tab : "tnx",
      alert : '',
      header2 : [
        {
          sort : true,
          dataField: 'TransactionID',
          text: 'Transaction ID',
          formatter : (rowContent, row) => {
            return <div > 
              { rowContent}
            </div>
        }
      },
        {
          sort : true,
          dataField: 'Data_Key',
          text: 'File Name',
          formatter : (rowContent, row) => {
            return <div > 
              { rowContent.split('/').pop() }
            </div>
        }
      },
      {
        sort : true,
        dataField: 'ProcessCode',
        text: 'Process',
        formatter : (rowContent, row) => {
          return <div > 
            { rowContent }
          </div>
        }
      },
      {
        sort : true,
        dataField: 'Status',
        text: 'Status',
        formatter : (rowContent, row) => {
          return <div > 
            { rowContent }
          </div>
        }
      },    
      {
        sort : true,
        dataField: 'Start',
        text: 'Date & Time',
        formatter : (rowContent, row) => {
          // return <div style={{width: '150px'}}> 
          return <div > 
            { row.Start ? moment(row.Start / 1000000).format("DD/MM/YYYY HH:mm:ss") : row.Created_At }
          </div>
        }
      },
      ],
      header_1 : [
        {
          sort : true,
          dataField: 'TransactionID',
          text: 'Transaction ID',
          formatter : (rowContent, row) => {
            return <div > 
              { rowContent }
            </div>
        }
      },
        {
          sort : true,
          dataField: 'Data_Key',
          text: 'File Name',
          formatter : (rowContent, row) => {
            let ok = rowContent.split('/')
            ok.splice(0, 1)
            return <div > 
              { ok.join('/') }
              {/* { rowContent } */}
            </div>
        }
      },
      // {
      //   sort : true,
      //   dataField: 'ProcessCode',
      //   text: 'Process',
      //   formatter : (rowContent, row) => {
      //     return <div > 
      //       { rowContent }
      //     </div>
      //   }
      // },
      {
        sort : true,
        dataField: 'Status',
        text: 'Status',
        formatter : (rowContent, row) => {
          return <div > 
            { rowContent }
          </div>
        }
      },    
      {
        sort : true,
        dataField: 'SchemaRun',
        text: 'SchemaRun',
        formatter : (rowContent, row) => {
          return <div > 
            { rowContent }
          </div>
        }
      },    
      {
        sort : true,
        dataField: 'Start',
        text: 'Date & Time',
        formatter : (rowContent, row) => {
          // return <div style={{width: '150px'}}> 
          return <div > 
            { row.Start ? moment(row.Start / 1000000).format("DD/MM/YYYY HH:mm:ss") : row.Created_At }
          </div>
        }
      },
      ],
      header1 : [
        {
            sort : true,
            dataField: 'Transaction_ID',
            text: 'Transaction ID',
            formatter : (rowContent, row) => {
              return <div > 
                { rowContent}
              </div>
          }
        },
        {
            sort : true,
            dataField: 'type',
            text: 'Type',
            formatter : (rowContent, row) => {
              return <div style={{width: '30px'}}> 
                { row.Uploaded_File_Name.split(".").pop()}
              </div>
          }
        },
        {
            sort : true,
            dataField: 'System',
            text: 'System',
            headerStyle: (colum, colIndex) => {
                return { width: '50px' };
            },
            formatter : (rowContent, row) => {
              return <div style={{width: '50px'}}> 
                { rowContent}
              </div>
          }
        },
        {
            sort : true,
            dataField: 'Process',
            text: 'Process',
            formatter : (rowContent, row) => {
              return <div style={{width: '150px'}}> 
                { rowContent ? rowContent.split("_").join(" ") : ""}
              </div>
          }
        },
        {
            sort : true,
            dataField: 'Status',
            text: 'Status',
            headerStyle: (colum, colIndex) => {
                return { width: '250px' };
            },
            formatter : (rowContent, row) => {
              return <div className="text-center" style={{width: '300px'}}> 
                <div className="row" align="center">
                  {/* <div className="col-md-12"> */}
                  <div className="col-md-3"></div>
                    <p className={ rowContent === "Completed" ? "col-md-6 batch success alert-success" : "col-md-6 batch warning alert-warning"} style={{ margin : '2px', marginTop : '-7px', marginBottom : '5px', padding : '4px' }}> { rowContent } </p>
                  <div className="col-md-3"></div>
                  {/* </div> */}
                </div>
                
                <div className="row">
                  <div className="col-md-1"></div>
                  <span title="Success" className="col-md-3 batch success alert-success" style={{ margin : '2px', marginTop : '-2.3px', marginBottom : '5px', padding : '5.5px' }}> { row.Success ? row.Success : 0 } </span>
                  <span title="Failure" className="col-md-3 batch danger alert-danger" style={{ margin : '2px', marginTop : '-2.3px', marginBottom : '5px', padding : '5.5px' }}> { row.Failure ? row.Failure : 0 } </span>
                  <span title="Total" className="col-md-3 batch info alert-info" style={{ margin : '2px', marginTop : '-2.3px', marginBottom : '5px', padding : '5.5px' }}> { row.Total ? row.Total : 0 } </span>
                  <div className="col-md-1"></div>
                </div>
              </div>
          }
        },
        {
            sort : true,
            dataField: 'Start',
            text: 'Date & Time',
            headerStyle: (colum, colIndex) => {
                return { width: '250px' };
            },
            formatter : (rowContent, row) => {
              return <div style={{ width: '200px', whiteSpace : 'nowrap' }} className="text-center"> 
               <span title="Start Time" className="batch warning alert-warning"> <PlayArrowIcon /> { row.Start ? moment(row.Start / 1000000).format("DD/MM/YYYY HH:mm:ss") : row.Created_At } </span> <br/><br/>
              <span title="End Time"  className="batch success alert-success"> <StopIcon /> { row.End ? moment(row.End / 1000000).format("DD/MM/YYYY HH:mm:ss") : row.Created_At } </span><br/><br/>
              <span title="Total Time"  className="batch success alert-info"> <ScheduleIcon/> { ((((row.End - row.Start) / 1000000 )/ 1000) / 60).toFixed(3) + " Mins" } </span>
              </div>
          }
        }
      ],
      filename : '',
      transactionData1 : {},
      transactionData2 : {},
      showTable : false
    }
  }


  componentWillMount = () =>{
    postRequest( 'api/transactions/getTransactions', {
      system : localStorage.getItem("system"),
    })
    .then(result => {
      if(result.type === "success")
    {
        // result.res.data.forEach(function(d){
        //   // var nameArr = [];
        //   // if(d.Uploaded_File_Name)
        //   // {
        //   //   nameArr =  d.Uploaded_File_Name.split("_") ;
        //   //   nameArr.splice(0,4)
        //   //   console.log(nameArr)
        //   // }
        //   d.original_Name = d.Uploaded_File_Name;
        // })

        var length = JSON.parse(result.res.data).length
        this.setState({
          ...this.state,
          transactionData1 : JSON.parse(result.res.data),
          showTable : true,
          })
      }
      })

    postRequest( 'api/transactions/getPDMStatus', {
      system : localStorage.getItem("system"),
    })
    .then(result => {
      if(result.type === "success")
    {
        var length = JSON.parse(result.res.data).length
        let data = JSON.parse(result.res.data)
        // console.log(data)
        this.setState({
          ...this.state,
          transactionData2 : data,
          showTable : true,
          })
      }
      })
  }

  downloadReturnFile = (row) =>{
    console.log(row)
    axios.post( ApiConstants.externals.serverUrl +'api/transactions/downloadReturnFile', row ,
    { responseType: 'blob' } )
    .then(result => {
        if(result.data.type === 'text/csv')
        {
          const blob = result.data;
          download(blob, row.Process + '-' + row.Transaction_ID + '-Return.csv');
        }
        else{
          this.setState({
            ...this.state,
            alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong  > <FontAwesomeIcon icon={faExclamation} /> No such file found</strong></div> </Animated>
          })
          setTimeout(() => { this.setState({alert : null}); }, 3000);
      }
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        ...this.state,
        alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong  > <FontAwesomeIcon icon={faExclamation} /> No such file found</strong></div> </Animated>
      })
      setTimeout(() => { this.setState({alert : null}); }, 3000);
    })
  }

  downloadInputFile = (row) =>{
    axios.post( ApiConstants.externals.serverUrl +'api/transactions/downloadInputFile', row,
    { responseType: 'blob' } )
    .then(result => {
      console.log(result);
        if(result)
        {
          const blob = result.data;
          console.log(row.Uploaded_File_Name)
          download(blob, row.Uploaded_File_Name);
        }
        else{
          this.setState({
            ...this.state,
            alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong  > <FontAwesomeIcon icon={faExclamation} /> No such file found</strong></div> </Animated>
          })
          setTimeout(() => { this.setState({alert : null}); }, 3000);
      }
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        ...this.state,
        alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong  > <FontAwesomeIcon icon={faExclamation} /> No such file found</strong></div> </Animated>
      })
      setTimeout(() => { this.setState({alert : null}); }, 3000);
    })
  }

  expandRow = {
    renderer: row => (
      <table id="subtable" style={{ width: "100%" }}>
        <tbody>
          <tr style={{ backgroundColor : 'lightgray', color :'black ', textAlign : 'center', border: '1px solid black' }}>
            <th style={{ width : "10%" }}>User</th>
            {/* <th style={{ width : "40%" }}>Error</th> */}
            <th className="text-center" style={{ width : "25%" }}>Input File</th>
            <th className="text-center" style={{ width : "25%" }}>Return File</th>
          </tr>
          <tr>
            <td style={{ width : "10%" }}>{ row.User }</td>
            {/* <td style={{ width : "40%" }}>
              { row.Error && row.Error.length ?
                row.Error.map(err => 
                  <div>
                    External ID : { err.ExternalID } <br/>
                    ERROR : { err.ERROR }<br/>
                    <hr/>
                  </div>
              ) 
              : null
                }
            </td> */}
            <td className="text-center" style={{ width : "25%" }}>
              <span> { row.Uploaded_File_Name } </span> 
            <button type="button" className="btn btn-info" onClick={ () => this.downloadInputFile(row) }><GetAppIcon /></button>
            </td>
            <td className="text-center" style={{ width : "25%" }}>
              <span> { row.Process + '-' + row.Transaction_ID + '-Return.csv'} </span>
              <button type="button" className="btn btn-primary" onClick={ () => this.downloadReturnFile(row) }> <GetAppIcon /> </button>
            </td>
          </tr>
        </tbody>
      </table>
    ),
    onlyOneExpanding: true,
  }

  
  expandRow2 = {
    renderer: row => (

      <div> 
          { row.Status === "In Progress" ?       
                  <>
                    <h4> File is "In Progress" </h4>                  
                  </>
                  : null
          }
          { row.Status === "Success" || row.Status === "Successfully Created PDT" ?       
                <>
                  <h4> File Successfully processed </h4> 
                </>
                : null
          }
          { row.Status === "Failed" ?       
                <>
                  <h4> Failed Reason </h4> 
                  <div style={{ overflow: 'visible'}}> 
                    <p> {row.Reason} </p> 
                  </div>
                </>
                : null
          }                
      </div>

      
      // <table id="subtable" style={{ width: "100%" }}>
      //   <tbody>
      //     <tr style={{ backgroundColor : 'lightgray', color :'black ', textAlign : 'center', border: '1px solid black' }}>
      //       <th className="text-center" style={{overflow: 'visible'}}>Reason</th>
      //     </tr>
      
      //     <tr style={{overflow: 'visible'}}>
      //       <td style={{overflow: 'visible'}}>{ row.Reason }</td>  
      //     </tr>
      
      //   </tbody>
      // </table>

    ),
    onlyOneExpanding: false,
  }


  tnx = () => {
    this.setState({ tab : 'tnx' })
  }

  pdm = () => {
    this.setState({ tab : 'pdm' })
  }



  render() {

    return (
      <div>
        <button onClick={this.tnx} className={ this.state.tab === 'tnx' ? 'btn btn-primary' : 'btn btn-secondary'  } >Transactions</ button> 
        <button onClick={this.pdm} className={ this.state.tab === 'pdm' ? 'btn btn-primary' : 'btn btn-secondary'  } >PDM status</ button>

        { this.state.tab === 'tnx' ? 
           <div>
            { this.state.transactionData1 && this.state.showTable ?
              <div className="scroll-div">
                  <BootstrapTable key="table1" keyField='Transaction_ID' classess="mainTable"  filter={ filterFactory() }  headerWrapperClasses="BootstrapTable" expandRow={ this.expandRow2 } data={ this.state.transactionData1 } columns={ this.state.header_1 } pagination={ paginationFactory() }/>
              </div>
              : null }
              
              {this.state.alert}
           </div>
        :
           <div>
            { this.state.transactionData2 && this.state.showTable ?
              <div className="scroll-div">
                  <BootstrapTable key="table2" keyField='TransactionID' classess="mainTable"  filter={ filterFactory() }  headerWrapperClasses="BootstrapTable" expandRow={ this.expandRow2 } data={ this.state.transactionData2 } columns={ this.state.header2 } pagination={ paginationFactory() } />
              </div>
              : null }
              
              {this.state.alert}        
           </div>
        }

        

      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
      state
  }
}


export default connect(
  mapStateToProps
)(Transactions);

// export default  Transactions;
