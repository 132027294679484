const globalhelper = {
  getBearerToken: function () {
    if (localStorage.getItem("authorization")) {
      let loginData = localStorage.getItem("authorization");
      let AuthStr = "Bearer " + loginData;
      return AuthStr;
    } else {
      return "";
    }
  },
  isAuthendicated: function () {
    if (localStorage.getItem("loggin_data")) {
      return true;
    } else {
      return false;
    }
  },
  getTimestamp: function (key) {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return key === "year" ? year : key === "month" ? month : "";
  },
  reload: function () {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  },
  currencyFormatter: function (value) {
    if (!value) {
      value = 0;
    }
    if (typeof value === "string") {
      value = Number(value);
    }
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  },
  roundOff: function (number, digits = 2) {
    number = number * Math.pow(10, digits);
    number = Math.round(number);
    number = number / Math.pow(10, digits);
    return number;
  },
  debounce: function (func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  },
};

export default globalhelper;
