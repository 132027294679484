import '../pages/CI/COD.css';

function LoadingAnimation(){
    return (
        <div className='center'>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
            <div className='wave'></div>
        </div>
    )
}

export default LoadingAnimation