import { SET_FORM_VALUE, SET_SELECTED_OBJ } from "./formTypes";

const initialState = {
  test : "test",
  selectedObj : {},
  objectSelected : false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return {
        ...state,
        [action.payload.key] : action.payload.value
      };
    case SET_SELECTED_OBJ:
      return {
        ...state,
        selectedObj : action.payload,
        objectSelected : true
      };
    default:
      return state;
  }
};

export default reducer;
