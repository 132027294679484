import React, { Fragment, PureComponent } from 'react';



class GroupingRules extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            formData : {
                name : "",
                group : "",
                field : "",
                condition : "",
                component : "",
                base_field : "",
                filter_field : "",
                value : "",
                rules : [
                    {
                        field : "",
                        condition : "",
                        value : ""
                    }
                ]
            },
            fileds : [
                "Source",
                "LinkRef",
                "SourceObjectName",
                "TxnHeaderText",
                "TxnZone",
                "TxnEntity",
                "TxnNumber",
                "SettledDate",
                "GL",
                "TxnHeaderRef",
                "StdObjectName",
                "TxnType",
                "TxnDocDate",
                "TxnPostingDate",
                "TxnCurr",
                "TxnItemText",
                "UserName",
                "Account",
                "TxnModifiedDate",
                "SettledRef",
                "TxnStatus",
                "TxnDocCurr",
                "TxnDocVal",
                "TxnVal",
                "ReversedRef",
                "ReversedDate",
                "OtherParameters"
            ],
            conditions : [
                "=",
                ">",
                ">=",
                "<",
                "<=",
                // "Between",
                // "Begins With",
                // "Contains",
            ],
            groups : [
                "All",
                "Top 5",
                "Top 10",
                "Bottom 5",
                "Bottom 10"
            ],
            components : [
                "All",
                "Invoices",
                "Receipts",
                "TDS",
                "Form26AS"
            ],
        }
    }

    handleChange = (ev) => {
        const NAME = ev.target.name, VALUE = ev.target.value;

        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                [NAME] : VALUE
            }
        })

    }


    addRuleRow = (ev) => {
        ev.preventDefault();
        const NEW_RULE = {
            field : "",
            condition : "",
            value : ""
        }

        this.setState(prevState => {
            var rules = prevState.formData.rules;
            rules.push(NEW_RULE);
            return prevState;
        }, () => {
            this.forceUpdate()
        })
    }


    removeRuleRow = (ev,ind) => {
        ev.preventDefault();
        this.setState(prevState => {
            prevState.formData.rules.splice(ind, 1);
            return prevState;
        }, () => {
            this.forceUpdate()
        })
    }

    handleRuleChange = (ev, ind) => {
        const NAME = ev.target.name, VALUE = ev.target.value;

        this.setState(prevState => {
            var currentRule = prevState.formData.rules[ind];
            currentRule[NAME] = VALUE
            return prevState;
        }, this.forceUpdate())
    }

    getSQLQuery = () => {
        var sql = `SELECT \n`;
        const FORMDATA = JSON.parse(JSON.stringify(this.state.formData));

        if(FORMDATA.base_field !== "")
        {
            if(FORMDATA.base_field === "All")
            {
                sql += ` * `
            }
            else{
                sql += ` ${FORMDATA.base_field} `
            }
        }
        
        sql += " \nFROM  tds_recon \n "

        if(FORMDATA.filter_field !== "")
        {
            sql += ` WHERE ${FORMDATA.filter_field} ${FORMDATA.condition} ${FORMDATA.value}`
            
        }
        
        if( FORMDATA.base_field !== "" && FORMDATA.base_field !== "All")
        {
            sql += ` \nGROUP BY ${FORMDATA.base_field} `
        }
        
        if( FORMDATA.group !== "" && FORMDATA.group !== "All")
        {
            sql += ` \LIMIT ${FORMDATA.group} `
        }

        
        return sql;
    }


    render(){

        const DynamicValues = (props) => {
            return(
                <b style={{ color : 'blue', textDecoration : 'underline' }} > {props.value} </b>
            )
        }

        const SelectBox = (props) => {
            const MULTIPLE = props.multiple ? props.multiple : false;
            return (
                <select multiple={MULTIPLE} id={props.modelName} value={props.value} name={props.modelName} onChange={this.handleChange} >
                    <option selected>Please select a {props.name}</option>
                    { props.options.map((item, ind) => 
                        <option selected={item === props.value} value={item} key={ind} >{item}</option>
                    ) }
                </select>
            )
        }
        return(
            <Fragment>
                <form>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="name"> Rule Name </label> <br />
                            <input type="text" name="name" id="name" placeholder="Please enter Rule name" className="col-md-12" value={this.state.formData.name} onChange={this.handleChange} />
                        </div>
                    </div>
                    <br /><br />
                    <div className="row flex-nowrap">
                        <div className="col-md-12">
                            Group <SelectBox value={this.state.formData.group} modelName="group" options={this.state.groups} name="Group" /> <SelectBox value={this.state.formData.component} modelName="component" name="Component" options={this.state.components}/> based on <SelectBox value={this.state.formData.base_field} modelName="base_field" name="Field" options={this.state.fileds} /> where <SelectBox name="Field" value={this.state.formData.filter_field} modelName="filter_field" options={this.state.fileds} /> <SelectBox value={this.state.formData.condition} modelName="condition" name="Condition" options={this.state.conditions} /> <input name="value" id="value" value={this.state.formData.value} onChange={this.handleChange} />
                        </div>
                        <br />
                    </div>
                    <br /><br />
                        {/* <p> */}
                            <b> Statement : </b>  Group <DynamicValues value={this.state.formData.group}/> <DynamicValues value={ this.state.formData.component } /> based on <DynamicValues value={ this.state.formData.base_field } /> where <DynamicValues value={ this.state.formData.filter_field } /> is <DynamicValues value={ this.state.formData.condition } /> <DynamicValues value={ this.state.formData.value } />
                            <br /><br />
                            <b> Query : </b>  {this.getSQLQuery()}
                        {/* </p> */}
                    {/* <div className="row">
                        <div className="col-md-12">
                            <table >
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Field</th>
                                        <th>Condition</th>
                                        <th>Value</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.state.formData.rules.map((rule, ruleInd) => 
                                        <tr key={ruleInd} >
                                            <td>{ ruleInd + 1 }</td>
                                            <td> 
                                                <select name="field" id="field" onChange={(ev) => this.handleRuleChange(ev, ruleInd)} value={rule.field}  >
                                                    <option selected> Please selete field </option>
                                                    { this.state.fileds.map((field, fieldInd) => 
                                                        <option key={fieldInd} value={field}> {field} </option>
                                                    ) }
                                                </select>
                                            </td>
                                            <td> 
                                                <select name="condition" id="condition" onChange={(ev) => this.handleRuleChange(ev, ruleInd)} value={rule.condition}  >
                                                    <option selected> Please selete condition </option>
                                                    { this.state.conditions.map((condition, fieldInd) => 
                                                        <option key={fieldInd} value={condition}> {condition} </option>
                                                    ) }
                                                </select>
                                            </td>
                                            <td>
                                                { rule.condition === "Between" ?
                                                    <Fragment>
                                                        <input type="text" name="start" id="start" placeholder="Please enter Rule start value" className="col-md-12" onChange={(ev) => this.handleRuleChange(ev, ruleInd)} value={rule.start} />
                                                        <input type="text" name="end" id="end" placeholder="Please enter Rule end value" className="col-md-12" onChange={(ev) => this.handleRuleChange(ev, ruleInd)} value={rule.end} />
                                                    </Fragment>
                                                    :
                                                    <input type="text" name="value" id="value" placeholder="Please enter Rule value" className="col-md-12" onChange={(ev) => this.handleRuleChange(ev, ruleInd)} value={rule.value} />
                                                    }
                                            </td>
                                            <td>
                                                <button className="btn btn-success" onClick={this.addRuleRow}> <i className="fa fa-plus"></i> </button>
                                                <button className="btn btn-danger" onClick={(ev) => this.removeRuleRow(ev,ruleInd)}> <i className="fa fa-times"></i> </button>
                                            </td>
                                        </tr>
                                    ) } 
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                </form>
            </Fragment>
        )
    }
}

export default GroupingRules;