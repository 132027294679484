import React, { Component, Fragment } from 'react'
import {Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import { getRequest } from '../../globalhelper/helper';
import '../CI/COD.css';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class Organization_Search extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData: {
            organizationid: '',
            organizationname: ''
         },
         showtable: false,
         showError: false,
         warning: 'Please select atleast one field',
         tableData: [],
         dropdown_organizationid: [],
         dropdown_organizationname: [],
         companyDetails: []
      }
    }

    componentDidMount(){
        localStorage.setItem("pageName", "Search Organization Master")

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const companies = responseData.map(item => item.orgid)
            const orgname = responseData.map(item => item.orgname)

            this.setState({
                ...this.state,
                dropdown_organizationid: companies,
                dropdown_organizationname: orgname,
                companyDetails: responseData
            })
        }).catch(err => console.log(err))
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            showtable: false,
            showError: false,
            formData: {
                organizationid: '',
                organizationname: ''
            }
        })
    }

    handleSearch = () => {
        const name = this.state.formData.organizationname

        if(name){

            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.organizationname)
    
            const companyid = id.map(item => item.orgid)

            getRequest(`api/edureka/getOrganizationSearchData?id=${companyid[0]}&name=${name}`).then(res => {
                if(res.res.status === 'success'){
                    let temp = JSON.parse(res.res.data)
    
                    this.setState({
                        ...this.state,
                        tableData: temp,
                        showtable: true,
                        showError: false
                    })
                } else {
                    console.log('No Data')
                }
            })
        } else {
            this.setState({
                ...this.state,
                showError: true
            })
        }

    }

    handleOrg = (i) => {
        const orgid = i['Organization ID']

        getRequest(`api/edureka/getOrgMasterUpdatedJSON?orgid=${orgid}`).then(res => {
            if(res.res.status === 'success'){
                console.log(res.res.data)

                this.setState({ redirect : true, SearchJSON : res.res.data })                
            } else {
                console.log("No Data")
            }
        })
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
            screenName = {"OrganizationMaster"}
            screenAction ={"edit"}
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {this.onBack}
            />
        )
    }
    else
    return (
      <Fragment>
        <div className='header_div'>
            Search Organization
        </div>

        <br/>

        <Form style={{display:'flex',flexDirection:'column'}}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:</div>
                <select className='col-lg-3' name='organizationname' value={this.state.formData.organizationname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_organizationname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization ID:</div>
                <select className='col-lg-3' name='organizationid' value={this.state.formData.organizationid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_organizationid.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div> */}

            <br/>

            <div style={{textAlign:'center'}}>
                <Button variant='primary' onClick={this.handleSearch}>Search</Button>
                <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>
        </Form>

        <br/>

        {this.state.showtable && this.state.tableData.length > 0 ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Organization ID</th>
                        <th>Organization Name</th>
                        <th>Organization Type</th>
                        <th>GST Treatment</th>
                        <th>Status</th>
                        <th>Organization Group</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.sort((a,b)=>a['Organization ID'].localeCompare(b['Organization ID'])).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td><Button variant='link' onClick={()=>this.handleOrg(item)}>{item['Organization ID']}</Button></td>
                                <td>{item['Organization Name']}</td>
                                <td>{item['Organization Type']}</td>
                                <td>{item['GST Treatment']}</td>
                                <td>{item['Status']}</td>
                                <td>{item['Organization Group']}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>: null}

        {this.state.showError &&
        <div className='center-screen'>{this.state.warning}</div>}
      </Fragment>
    )
  }
}

export default Organization_Search