export const LOGOUT = "LOGOUT";
export const Initiate_Login = "Initiate_Login";
export const REQUESTCURRENTSTATE = "REQUESTCURRENTSTATE";
export const onLogin = "onLogin";
export const SUCCESSLOGIN = "SUCCESSLOGIN";
export const SHOWALERT = "SHOWALERT";
export const HIDEALERT = "HIDEALERT";
export const SAVETOKEN = "SAVETOKEN";
export const NAVURL = "NAVURL";
export const INITIATECOMMON = "INITIATECOMMON";
export const VERIFYLOGIN = "VERIFYLOGIN";
export const REQUEST_AUTH_KEY = "REQUEST_AUTH_KEY";
export const SET_AUTH_KEY = "SET_AUTH_KEY";
export const SET_MENU_TREE = "SET_MENU_TREE";
export const FLUSH_MENU_TREE = "FLUSH_MENU_TREE";
export const FLESH_COMMON_OBJECT = "FLESH_COMMON_OBJECT";
export const GET_CURRENT_MENU_TREE = "GET_CURRENT_MENU_TREE";
export const FETCH_DIALOGFLOW_REQUEST = "FETCH_DIALOGFLOW_REQUEST";
export const FETCH_DIALOGFLOW_SUCCESS = "FETCH_DIALOGFLOW_SUCCESS";
export const FETCH_DIALOGFLOW_FAILURE = "FETCH_DIALOGFLOW_FAILURE";
export const SET_MODULES = "SET_MODULES";
export const TOGGLE_SELECT_VR_SCREEN_MODE = "TOGGLE_SELECT_VR_SCREEN_MODE";

export const ADD_COMPONENT = "ADD_COMPONENT";
export const CHANGE_PROP = "CHANGE_PROP";
export const CHANGE_VALUE_JSON = "CHANGE_VALUE_JSON";
export const SET_NEW_TOKEN = "SET_NEW_TOKEN";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const GET_USER_CONFIGURATION = "Get User Configuration";
export const UPDATE_USER_CONFIGURATION = "Update User Configuration";

///masters
export const ADDCOMPNAY = "ADDCOMPNAY";