import { getRequest } from "../../globalhelper/helper";
import {
  SELECT_APP,
  GET_MENU,
  SET_CHAT_ITEMS,
  FLUSH_CHAT_ITEMS
} from "./saTypes";
// import store from '../../store';

export const selectApp = (selectedApp) => {
  return {
    type: SELECT_APP,
    payload: selectedApp,
  };
};

export const flushChatbotItems = () => {
  return {
    type: FLUSH_CHAT_ITEMS
  };
};

export const fetchMenuItems = () => {
  return (dispatch) => (

    getMenu().then(response => {
      dispatch({
        type: GET_MENU,
        payload: response.res.data,
      })

    })
    );
};

export const fetchChatbotItems = () => {
  return (dispatch) => (

    getChatbotItems().then(response => {
      // console.log(response);
      if(response)
      {
        dispatch({
          type: SET_CHAT_ITEMS,
          payload: response.res.data,
        })
      }

    })
    );
};

const getMenu = () => {
  // const currendState = store.getState();
  return getRequest('api/module/getMenus?product=' + localStorage.getItem("module"))
}

const getChatbotItems = () => {
  // const currendState = store.getState();
  return getRequest('api/chatbot/getChatbotItems')
}