import { useEffect, useState } from "react"
import React from 'react';
import { getRequest, getHumanReadableDate, postRequest, getStandardDate } from "../../globalhelper/helper";
import '../CI/COD.css';
import { Button, Form, Modal } from 'react-bootstrap';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { CSVLink } from "react-csv";
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import { connect } from "react-redux";
import './Modal.css'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTimes } from '@fortawesome/free-solid-svg-icons';

const SelectType = ({ label, name, value, onChange }) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value='' disabled>Select</option>
                    <option value='BillExtract'>Bill</option>
                    <option value='GoodsAndServicesEntries'>GSE</option>
                    <option value="CreditCard">Credit Card</option>
                    <option value="DigitalWalletAccounting">Digital Wallet Accounting</option>
                    <option value='EmployeeCost'>Employee Cost</option>
                    <option value='EmployeeCostAccrued'>Employee Cost Accrued</option>
                    <option value='EmployeeCostFnF'>Employee Cost FnF</option>
                    <option value='EmployeeCostAccruedReversal'>Employee Cost Accrued Reversal</option>
                    <option value="EmployeeReimbursement">Employee Reimbursement</option>
                    <option value='InstructorAccrualReversal'>Instructor Accrual Reversal</option>
                    <option value='InstructorCostAccrual'>Instructor Cost Accrual</option>
                    <option value='InstructorInvoice'>Instructor Invoice</option>
                    <option value='Payment'>Payment</option>
                    <option value='PettyCash'>Petty Cash</option>
                    <option value='VendorAdvancePayment'>Vendor Advance Payment</option>
                </select>
            </Form.Group>
        </div>
    )
}

const SelectStatus = ({ label, name, value, onChange }) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className="spacing" name={name} value={value} onChange={onChange}>
                    <option value=''>Select</option>
                    <option value='Active'>Active</option>
                    <option value="Waiting For Approval">Waiting For Approval</option>
                    <option value="Rejected">Rejected</option>
                </select>
            </Form.Group>
        </div>
    )
}
const SelectCSVDownloaded = ({ label, name, value, onChange }) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select
                    className="spacing"
                    name={name} // Make sure the name matches the formData field
                    value={value} // Make sure value comes from formData
                    onChange={onChange} // Ensure onChange is working
                >
                    <option value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                </select>
            </Form.Group>
        </div>
    );
};

const BulkApprovals = (props) => {
    const screenName = localStorage.getItem("pageName")
    const [formData, setFormData] = useState({
        transtype: '',
        fromDate: '',
        toDate: '',
        company: '',
        email: '',
        transstatus: '',
        csvDownloaded: '',
        vendorName: [],  // Ensure this is an array
        expenseLedgers: []
    })

    const [companyName, setCompanyName] = useState([])
    const [companyDetails, setCompanyDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [queryResult, setQueryResult] = useState([])
    const [qresult, setQResult] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedRowData, setSelectedRowData] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [activityCode, setActivityCode] = useState([])
    const [showError, setShowError] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [month, setMonth] = useState("")
    const [year, setYear] = useState("")
    const [showScrollButton, setShowScrollButton] = useState(false)
    const [isBillType, setIsBillType] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [redirectBill, setRedirectBill] = useState(false);
    const [searchJSON, setSearchJSON] = useState(null);
    const [UserInputdetails, setUserInputdetails] = useState({});
    const [pdfUrl, setPdfUrl] = useState(null);
    const [activePdf, setActivePdf] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [downloadedItems, setDownloadedItems] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [approvalStatus, setApprovalStatus] = useState('');
    const [cdata, setcdata] = useState('')
    const [sortOrder, setSortOrder] = useState('asc');


    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)

            const companyid = responseData.map(item => item.orgid);
            console.log("companyid", companyid);
            const COD = companyid.sort()
            console.log("COD", COD);
            const CID = companyid[0]
            console.log("CID", CID);

            getRequest(`api/edureka/getUserInputFE?orgID=${CID}&page=${screenName}`).then(response => {
                console.log(response.res.data);
                const userdata = response.res.data.Item
                console.log("USERDTA", userdata);
                setUserInputdetails(userdata)
                setFormData({
                    company: userdata.orgId[0] || '',
                    fromDate: userdata.forprdfrom || '',
                    toDate: userdata.forprdto || '',
                    transtype: userdata.type || '',
                    transstatus: userdata.transstatus || ''
                });
            }).catch(err => console.log(err));
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        setIsBillType(formData.transtype === 'BillExtract' ? true : false);
    }, [formData.transtype]);

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => {
            return { ...prev, [name]: value }
        })
    }

    // Handle multi-select for React Select components
    const handleSelectChange = (selectedOption, field) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: selectedOption ? selectedOption.value : ''
        }));
    };
       

    const handleexChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => {
            return { ...prev, [name]: value }
        })
    }

    const getPdf = async (pdfloc) => {

        const id = companyDetails.filter(item => item.orgname === formData.company);
        const companyid = id.length > 0 ? id.map(item => item.orgid)[0] : UserInputdetails.orgId;
        console.log("COMPANYID", companyid);

        postRequest(`api/edureka/getThePdf?pdfloc=${pdfloc}&orgID=${companyid}`).then(response => {
            console.log("R", response);
            const bufferData = response.res.data.data;
            console.log("BD", bufferData);
            const byteArray = new Uint8Array(bufferData);
            const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(url);
            setActivePdf(pdfloc);
            setIsFormVisible(false);
        }).catch(error => {
            console.error('Error fetching PDF:', error);
        });

    };

    const closeModal = () => {
        setPdfUrl(null);
        setActivePdf(null);
    };

    const handleSubmit = () => { 
        const id = companyDetails.filter(item => item.orgname === formData.company);
        const companyid = id.length > 0 ? id.map(item => item.orgid) : UserInputdetails.orgId;
        console.log("COMPANYID", companyid);


        const item = {
            forprdfrom: formData.fromDate,
            forprdto: formData.toDate,
            type: formData.transtype,
            orgid: companyid,
            email: formData.email,
            transstatus: formData.transstatus,
            page: screenName
        };

        setLoading(true);
        setShowError(false);

        postRequest(`api/edureka/getBulkApprovals`, item).then(response => {
            console.log(response.res.data);
            const res = response.res.data;
            if (!res.length) {
                console.log("Entered");
                setShowError(true);
                setWarningMessage("No data found, please try some other combination");
            }
            const billNumbers = res.map(item => item.createdid);
            const field = formData.transstatus ? formData.transstatus : "Waiting For Approval";
            const filteredData = res.filter(item => item.ActivityStatus === field);

            const newDownloadedItems = {};
            filteredData.forEach(item => {
                if (item["ActivityStatus"] === 'Active') {
                    newDownloadedItems[item["createdid"]] = {
                        downloaded: false,
                        timestamp: ''
                    };
                }
            });

            // console.log(newDownloadedItems);


            // Merge with previously downloaded data
            setDownloadedItems(prev => ({ ...prev, ...newDownloadedItems }));

            setQResult(filteredData);
            setQueryResult(res);
            setLoading(false);
            setActivityCode([...new Set(billNumbers)]);

            if (formData.transtype === 'Payment') {
                 // Fetch downloaded status and timestamp for the createdids in queryResult
                const createdIds = res.map(item => item["createdid"]);
                console.log(`api/edureka/getDownloadedItemsByCreatedIds`, { createdIds })
                postRequest(`api/edureka/getDownloadedItemsByCreatedIds`, { createdIds })
                .then(downloadResponse => {
                    // console.log('Raw download response:', downloadResponse);

                    // Ensure response format is correct
                    if (downloadResponse && downloadResponse.res && downloadResponse.res.data) {
                        const downloadedData = downloadResponse.res.data;
                        console.log('Parsed download data:', downloadedData);

                        // Initialize updatedDownloadedItems
                        const updatedDownloadedItems = {};

                        // Decode response data
                        Object.keys(downloadedData).forEach(createdId => {
                            const item = downloadedData[createdId];
                            updatedDownloadedItems[createdId] = {
                                downloaded: item.downloaded,
                                timestamp: item.timestamp
                            };
                        });

                        // Set the state with the decoded data
                        setDownloadedItems(updatedDownloadedItems);
                    } else {
                        console.error('Unexpected response format:', downloadResponse);
                    }
                })
                .catch(err => {
                    console.error('Error in postRequest:', err);
                });
            }else{
                console.log(Error);
            }
        }).catch(err => {
            setLoading(false);
            console.log(err);
        });
    };

    // const handleSelectAll = () => {
    //     if (selectAll) {
    //         setSelectedItems([])
    //         setSelectedRowData([])
    //     } else {
    //         if (formData.transtype === "GoodsAndServicesEntries" || formData.transtype === "BillExtract") {
    //             const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
    //             setSelectedItems(allIndices)
    //             const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
    //             const selectedRows = allIndices
    //                 .map((index) => [
    //                     queryResult[index]["createdid"]
    //                 ])
    //                 .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["createdid"] === item[0]))
    //                 .flat()
    //             const deDuplicatedArray = [...new Set(selectedRows)]
    //             console.log(deDuplicatedArray)
    //             setSelectedItems(deDuplicatedArray)
    //             setSelectedRowData(deDuplicatedArray)
    //         }else{
    //         const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
    //         setSelectedItems(allIndices)
    //         const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
    //         const selectedRows = allIndices
    //             .map((index) => [
    //                 queryResult[index]["FileLocation"],
    //                 queryResult[index]["AD OUTPUT FILE"]
    //             ])
    //             .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item[0]))
    //             .flat()
    //         const deDuplicatedArray = [...new Set(selectedRows)]
    //         console.log(deDuplicatedArray)
    //         setSelectedItems(deDuplicatedArray)
    //         setSelectedRowData(deDuplicatedArray)
    //     }
    //     }
    //     setSelectAll(!selectAll)
    // }

    const handleSelectAll = () => {
        if (formData.transtype !== "GoodsAndServicesEntries" || formData.transtype !== "BillExtract") {{
            if (selectAll) {
                setSelectedItems([])
                setSelectedRowData([])
            } else {
                if (formData.transtype === "GoodsAndServicesEntries" || formData.transtype === "BillExtract") {
                    const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
                    setSelectedItems(allIndices)
                    const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
                    const selectedRows = allIndices
                        .map((index) => [
                            queryResult[index]["createdid"]
                        ])
                        .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["createdid"] === item[0]))
                        .flat()
                    const deDuplicatedArray = [...new Set(selectedRows)]
                    console.log(deDuplicatedArray)
                    setSelectedItems(deDuplicatedArray)
                    setSelectedRowData(deDuplicatedArray)
                }else{
                const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
                setSelectedItems(allIndices)
                const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
                const selectedRows = allIndices
                    .map((index) => [
                        queryResult[index]["FileLocation"],
                        queryResult[index]["AD OUTPUT FILE"]
                    ])
                    .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item[0]))
                    .flat()
                const deDuplicatedArray = [...new Set(selectedRows)]
                console.log(deDuplicatedArray)
                setSelectedItems(deDuplicatedArray)
                setSelectedRowData(deDuplicatedArray)
            }
            }
            setSelectAll(!selectAll)
        }   
        }else{
        let itemsToSelect;
    
        // Apply filters for Vendor Name and Expense Ledgers if they are selected
        if (formData.vendorName || formData.expenseLedgers) {
            itemsToSelect = queryResult.filter(item => {
                const matchesVendor = formData.vendorName ? item.VendorNames === formData.vendorName : true;
                const matchesExpenseLedger = formData.expenseLedgers ? item.ExpenseLedgers === formData.expenseLedgers : true;
                return matchesVendor && matchesExpenseLedger;
            });
        } else {
            // Select all items if no filters are applied
            itemsToSelect = queryResult;
        }
    
        if (selectAll) {
            setSelectedItems([]);
            setSelectedRowData([]);
        } else {
            const allIndices = itemsToSelect.map((item, index) => index);
            const filteredColumns = itemsToSelect.filter(item => item.Status === 'Yes' || item.Status === 'No');
            const selectedRows = allIndices
                .map(index => [itemsToSelect[index]["createdid"]])
                .filter(item => !filteredColumns.some(filteredItem => filteredItem["createdid"] === item[0]))
                .flat();
            const deDuplicatedArray = [...new Set(selectedRows)];
            
            setSelectedItems(deDuplicatedArray);
            setSelectedRowData(deDuplicatedArray);
        }
    
        setSelectAll(!selectAll);
    };
}
    

    const handleCheckboxChange = (cid, index, adFileLocation) => {
        if(formData.transtype==="BillExtract" || formData.transtype==="GoodsAndServicesEntries"){
            console.log(cid)
            const selected = [...selectedItems];
            if ( selected.includes(cid)) {
                const filteredSelected = selected.filter((item) => item !== cid);
                setSelectedItems(filteredSelected);
                setSelectedRowData(filteredSelected);
            } else {
                setSelectedItems([...selected,cid]);
                setSelectedRowData([...selected,cid]);
            }
            setWarningMessage('');
        }else{
            console.log(cid, index)
            const selected = [...selectedItems];
            if (selected.includes(cid) && selected.includes(index)) {
                const filteredSelected = selected.filter((item) => item !== cid && item !== index);
                setSelectedItems(filteredSelected);
                setSelectedRowData(filteredSelected);
            } else {
                setSelectedItems([...selected, cid, index]);
                setSelectedRowData([...selected, cid, index]);
            }
            setWarningMessage('');
        }

    };

    const handleReset = () => {
        setFormData({
            transtype: '',
            fromDate: '',
            toDate: '',
            company: '',
            email: '',
            transstatus: ''
        })
        setSelectedItems([])
        setSelectedRowData([])
        setSelectAll(false)
        setQueryResult([])
        setLoading(false)
        setWarningMessage('')
        setShowError(false)
        setYear("")
        setMonth("")
    }

    const handleApproval = (status) => {
        if (status === "Rejected") {
          setApprovalStatus(status);
          setShowModal(true);
        } else {
          processApproval(status, null);
        }
      };
    
      const processApproval = (status, reason) => {
        console.log("REJ");
        
        const id = UserInputdetails.orgId;
        console.log("ID", id);
    
        let selectedActivities = [];
        let RowData = [];
    
        if (formData.transtype === 'BillExtract' || formData.transtype === 'GoodsAndServicesEntries') {
          selectedActivities = queryResult
            .filter(item => selectedItems.includes(item["createdid"]))
            .map(item => status === "Approved" ? item["YTAActivityCode"] : item["YTAActivityCode"])
            .filter(value => value)
            .flatMap(value => value.split(','))
            .filter((value, index, self) => self.indexOf(value) === index);
        } else {
          selectedActivities = queryResult
            .filter(item => selectedItems.includes(item.FileLocation))
            .map(item => status === "Approved" ? item["YTAActivityCode"] : item["YTAActivityCode"])
            .filter(value => value)
            .flatMap(value => value.split(','))
            .filter((value, index, self) => self.indexOf(value) === index);
        }
    
        RowData = selectedRowData.flatMap(value => value.split(','));
        console.log("LOCATION", RowData);
    
        const requestParams = {
          s3FilePaths: RowData,
          activityCode: selectedActivities,
          status,
          orgId: id,
          type: formData.transtype,
          reason: reason // add the rejection reason here if available
        };
        console.log("REQPARAMS", requestParams);
    
        setLoading(true);
        setShowError(false);
    
        postRequest(`api/edureka/bulkApprovalStatusUpdate`, requestParams)
          .then(response => {
            console.log(response.res.data);
    
            const item = {
              forprdfrom: formData.fromDate,
              forprdto: formData.toDate,
              type: formData.transtype,
              orgid: id
            };
    
            postRequest(`api/edureka/getBulkApprovals`, item)
              .then(response => {
                const finalResponse = response.res.data;
                const val = response.res.data.map(item => item["AActivityCode"]);
                setLoading(false);
                setQueryResult(finalResponse.sort((a, b) => new Date(b.accountingdate) - new Date(a.accountingdate))) // Sort by date
                setActivityCode([...new Set(val)]);
                setSelectedItems([]);
                setSelectedRowData([]);
              })
              .catch(err => {
                setLoading(false);
                console.log(err);
              });
    
          })
          .catch(err => {
            setLoading(false);
            console.log(err);
          });
      };

    const handleScroll = () => {
        const isCloseToBottom =
            window.innerHeight + window.scrollY >= document.body.offsetHeight
        setShowScrollButton(isCloseToBottom);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleBill = async (item) => {
        try {
            const id = companyDetails.filter(company => company.orgname === formData.company);
            const companyid = id.length > 0 ? id.map(company => company.orgid) : UserInputdetails.orgId;
            console.log("COMPANYID", companyid);
            console.log(`key=${item["createdid"]}&companyid=${companyid}`);
            
            const response = await getRequest(`api/edureka/getJSONViaKey1?key=${item["createdid"]}&companyid=${companyid}`);
            
            if (response && response.res && response.res.data) {
                console.log(response.res.data);
                setSearchJSON(response.res.data);
                setRedirectBill(true);
            } else {
                setRedirectBill(false);
            }
        } catch (error) {
            console.error("Error fetching the data", error);
            setRedirectBill(false); // Set to false if an error occurs
        }
    };
    

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleModalSubmit = () => {
        processApproval(approvalStatus, rejectionReason);
        setShowModal(false);
        setRejectionReason(''); // clear rejection reason after use
      };

    // const handleCSVDownload = () => {
    //     const now = new Date().toLocaleString();

    //     // Filter only selected items that have not been downloaded
    //     const itemsToDownload = filteredResults.filter(item => {
    //         const downloadInfo = downloadedItems[item["createdid"]] || {};
    //         return selectedItems.includes(item["FileLocation"]) && downloadInfo.downloaded === false;
    //     });

    //     // console.log(itemsToDownload);

    //     if (itemsToDownload.length === 0) {
    //         alert("No rows selected or selected rows have already been downloaded.");
    //         return; // Prevent download if no valid rows
    //     }

    //     setcdata(itemsToDownload);

    //     // Update the downloaded status for the items being downloaded
    //     const updatedDownloadedItems = { ...downloadedItems };
    //     itemsToDownload.forEach(item => {
    //         updatedDownloadedItems[item["createdid"]] = {
    //             downloaded: true,
    //             timestamp: now
    //         };
    //     });

    //     // console.log(updatedDownloadedItems);
    //     setDownloadedItems(updatedDownloadedItems);

    //     // Call the API to save the updated download status
    //     postRequest(`api/edureka/datenandtime`, updatedDownloadedItems)
    //         .then(response => {
    //             console.log('Download timestamps updated successfully:', response);
    //         })
    //         .catch(err => console.error('Error updating download timestamps:', err));
    // };


    // const filteredResults = formData.transstatus ? queryResult.filter(item => item["Status"] === formData.transstatus) : qresult
    

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}`;
    };

    const handleCSVDownload = () => {
        const now = new Date().toLocaleString();
    
        // Filter only selected items that have not been downloaded
        const itemsToDownload = filteredResults.filter(item => {
            const downloadInfo = downloadedItems[item["createdid"]] || {};
            return selectedItems.includes(item["FileLocation"]) && downloadInfo.downloaded === false;
        });
    
        if (itemsToDownload.length === 0) {
            alert("No rows selected or selected rows have already been downloaded.");
            return; // Prevent download if no valid rows
        }
    
        // Select only the necessary columns for the CSV
        const selectedColumns = itemsToDownload.map(item => ([
            item["IorN"], item["createdid"], item["PartyAcNumber"], item["ThisBillPymt"] ? Number(item["ThisBillPymt"]).toFixed(2) : "0.00", item["PartyName"], "", "","","","","","","", item["createdid"],"","","","","","","","",formatDate(new Date()),"", item["PartyIFSCCode"], item["PartyBankName"],"","payment.advices@edureka.co"
    ]));
    
        setcdata(selectedColumns);
    
        // Update the downloaded status for the items being downloaded
        const updatedDownloadedItems = { ...downloadedItems };
        itemsToDownload.forEach(item => {
            updatedDownloadedItems[item["createdid"]] = {
                downloaded: true,
                timestamp: now
            };
        });
    
        setDownloadedItems(updatedDownloadedItems);
    
        // Call the API to save the updated download status
        postRequest(`api/edureka/datenandtime`, updatedDownloadedItems)
            .then(response => {
                console.log('Download timestamps updated successfully:', response);
            })
            .catch(err => console.error('Error updating download timestamps:', err));
    };

    const vendorNames = queryResult && queryResult.length 
    ? [...new Set(queryResult.map(item => item["VendorNames"]).filter(Boolean))] 
    : [];
    const expenseLedgers = queryResult && queryResult.length 
        ? [...new Set(queryResult.map(item => item["ExpenseLedgers"]).filter(Boolean))] 
        : [];

// Filtering the results
const filteredResults = queryResult && queryResult.length 
    ? queryResult.filter(item => {
        const matchesTransStatus = formData.transstatus
            ? (formData.transtype === 'Payment' 
                ? item["ActivityStatus"] === formData.transstatus 
                : item["Status"] === formData.transstatus)
            : true;

        const matchesVendorName = formData.vendorName
            ? item["VendorNames"] === formData.vendorName
            : true;

        const matchesExpenseLedgers = formData.expenseLedgers
            ? item["ExpenseLedgers"] === formData.expenseLedgers
            : true;

        return matchesTransStatus && matchesVendorName && matchesExpenseLedgers;
    }) 
    : [];

    const csvColumnOrder = [
        'Status',
        'Approved Activity Code',
        'Vendor Name',
        'Expense Ledgers',
        'Vendor Invoice List',
        'Accounting Date',
        'Created Id',
        'For Period From',
        'For Period To',
        'Prd Provisions',
        'Prd Expense Booked',
        'Prd PrePaid Expense Booked',
        'IGST Input',
        'CGST Input',
        'SGST Input',
        'Input Tax Classification',
        'RCM Output Tax Classification',
        'IGST Output',
        'CGST Output',
        'SGST Output',
        'Prd Total Input Tax',
        'Prd Total Output Tax',
        'Prd WH Tax Amount',
        'WH Tax Classification',
        'Prd Payable Amount',
        'LOB Allocated Per',
        'LOB Allocated Value',
        'Transaction Type',
        'Item Type',
        'Account Groups',
        'MIS Group Names',
        'Vendor ID',
        'Account Code',
        'Yet To Approve Activity Code',
        'Journals'
    ];

// Mapping filtered results for CSV download
const mappedFilteredData = filteredResults.map(item => {
    return {
        Status: item.Status,
        'Approved Activity Code': item.AActivityCode,
        'Vendor Name': item.VendorNames,
        'Expense Ledgers': item.ExpenseLedgers,
        'Vendor Invoice List': item.VendorInvoiceList,
        'Accounting Date': item.accountingdate,
        'Created Id': item.createdid,
        'For Period From': item.ReportingPrdFrom,
        'For Period To': item.ReportingPrdTo,
        'Prd Provisions': Number(item.PrdProvisions || 0).toFixed(2),
        'Prd Expense Booked': Number(item.PrdExpenseBooked || 0).toFixed(2),
        'Prd PrePaid Expense Booked': Number(item.PrdPrePaidExpenseBooked || 0).toFixed(2),
        'IGST Input': Number(item.IGSTInput || 0).toFixed(2),
        'CGST Input': Number(item.CGSTInput || 0).toFixed(2),
        'SGST Input': Number(item.SGSTInput || 0).toFixed(2),
        'Input Tax Classification': item.InputTaxClassification,
        'RCM Output Tax Classification': item.RCMOutputTaxClassification,
        'IGST Output': Number(item.IGSTOutput || 0).toFixed(2),
        'CGST Output': Number(item.CGSTOutput || 0).toFixed(2),
        'SGST Output': Number(item.SGSTOutput || 0).toFixed(2),
        'Prd Total Input Tax': Number(item.PrdTotalInputTax || 0).toFixed(2),
        'Prd Total Output Tax': Number(item.PrdTotalOutputTax || 0).toFixed(2),
        'Prd WH Tax Amount': Number(item.PrdWHTaxAmount || 0).toFixed(2),
        'WH Tax Classification': item.WHTaxClassification,
        'Prd Payable Amount': Number(item.PrdPayableAmount || 0).toFixed(2),
        'LOB Allocated Per': item.LobAllocPer,
        'LOB Allocated Value': item.LobAllocValue,
        'Transaction Type': item.transactiontype,
        'Item Type': item.ItemType,
        'Account Groups': item.AccountGroups,
        'MIS Group Names': item.MISGroupNames,
        'Vendor ID': item.VendorID,
        'Account Code': item.AccountCode,
        'Yet To Approve Activity Code': item.YTAActivityCode,
        'Journals': item.Journals,
    };
});
    
    

    const filteredSecondTableResults = queryResult.filter(item => {
        const downloadInfo = downloadedItems[item["createdid"]] || {};
        // Filter by CSV Downloaded based on formData.csvDownloaded
        const matchesCSVDownloaded = formData.csvDownloaded ?
            (formData.csvDownloaded === 'Yes' ? downloadInfo.downloaded === true : downloadInfo.downloaded === false)
            : true;
        // Add any other filtering logic, such as transstatus or transtype here if needed
        const matchesTransStatus = formData.transstatus
        ? (formData.transtype === 'Payment'
            ? item["ActivityStatus"] === formData.transstatus
            : item["Status"] === formData.transstatus)
        : true;
        return matchesCSVDownloaded && matchesTransStatus;
    });

    if (redirectBill) {
        return (
            <VR2_Forms
                screenName={"BillExtract"}
                screenAction={"edit"}
                From_SearchJSON={searchJSON}
                onBack={() => setRedirectBill(false)}
            />
        )
    }

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };
    
    // Sort filteredSecondTableResults within the render
    const sortedResults = [...filteredSecondTableResults].sort((a, b) => {
        const docA = a.accountingdocnumber || ''; // Default to empty string if undefined
        const docB = b.accountingdocnumber || ''; // Default to empty string if undefined
        if (sortOrder === 'asc') {
            return docA.localeCompare(docB, undefined, { numeric: true });
        } else {
            return docB.localeCompare(docA, undefined, { numeric: true });
        }
    });

    return (
        <>
             <div>
            <Button onClick={toggleFormVisibility} variant="primary">
                {isFormVisible ? '<' : '>'}
            </Button>
            <div style={{
                maxHeight: isFormVisible ? '1000px' : '0',
                overflow: 'hidden',
                transition: 'max-height 0.5s ease-in-out',
                borderBottom: !isFormVisible ? '1px solid #ccc' : 'none',
                paddingBottom: !isFormVisible ? '10px' : '0'
            }}>

            {isFormVisible && (
                <>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label>Company</Form.Label>
                                <select className="spacing" value={formData.company} name='company' onChange={handleChange}>
                                    <option value='' disabled>Select</option>
                                    {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                                </select>
                            </Form.Group>
                        </div>

                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label>From Date</Form.Label>
                                <input
                                    type="date" name="fromDate" value={formData.fromDate} onChange={handleChange} className="form-control"
                                />
                            </Form.Group>
                        </div>

                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label>To Date</Form.Label>
                                <input
                                    type="date" name="toDate" value={formData.toDate} onChange={handleChange} className="form-control"
                                />
                            </Form.Group>
                        </div>

                        <div className="col-md-2">
                            <SelectType label="Type" name='transtype' value={formData.transtype} onChange={handleChange} />
                        </div>
                        {!loading && queryResult.length && formData.transtype === "BillExtract" ? (
                        <div className="col-md-2">
                            <Form.Group>
                                <Form.Label>Vendor Name</Form.Label>
                                <select
                                    name="vendorName"
                                    value={formData.vendorName}
                onChange={handleChange}
                                    className="form-control"
                                >
                                    <option value="">Select Vendor Name</option>
                                    {/* Filter vendor names based on filtered results */}
                {Array.from(new Set(queryResult.map(item => item.VendorNames))).map((vendor, index) => (
                                        <option key={index} value={vendor}>
                                            {vendor}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                        </div>
                    ) : null}

{/* Expense Ledgers Dropdown */}
{!loading && queryResult.length && formData.transtype === "BillExtract" ? (
    <div className="col-md-2">
        <Form.Group>
            <Form.Label>Expense Ledgers</Form.Label>
            <select
                name="expenseLedgers"
                value={formData.expenseLedgers}
                onChange={handleChange}
                className="form-control"
            >
                <option value="">Select Expense Ledger</option>
                {Array.from(new Set(queryResult.map(item => item.ExpenseLedgers))).map((ledger, index) => (
                                        <option key={index} value={ledger}>
                                            {ledger}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                        </div>
                    ) : null}
                        {!loading && queryResult.length ?

                                        <div className="col-md-2">
                                            <SelectStatus label="Transaction Status" name='transstatus' value={formData.transstatus} onChange={handleChange} />
                                        </div> : null}

                                    {!loading && queryResult.length && formData.transtype == "Payment" && formData.transstatus !== "Waiting For Approval" ?
                                        <div className="col-md-2">
                                            <SelectCSVDownloaded
                                                label="Pymt File Generated"
                                                name='csvDownloaded'
                                                value={formData.csvDownloaded}
                                                onChange={handleChange}
                                            />
                                        </div> : null}
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                <Button size="sm" onClick={handleSubmit} variant="warning">Submit</Button>
                                <Button size="sm" onClick={handleReset} style={{ marginLeft: '10px' }} variant="secondary">Reset</Button>

                                {queryResult.length ? (
                                    <CSVLink
                                        filename="FilteredBulkApprovals.csv"
                                        size="sm"
                                        className="btn btn-primary"
                                        style={{ marginLeft: '10px' }}
                                        data={mappedFilteredData}
                                        headers={csvColumnOrder.map(col => ({ label: col, key: col }))}
                                    >Download</CSVLink>
                                    ) : null}
                                    {filteredResults.length && formData.transtype === "Payment" ? (
                                        (formData.transstatus !== "Waiting For Approval" && formData.transstatus !== "Rejected" && formData.csvDownloaded !== "Yes" && formData.csvDownloaded !== "") ? (
                                            <CSVLink
                                                size="sm"
                                                className="btn btn-primary"
                                                style={{ marginLeft: '10px' }}
                                                filename="GENERATED BULK DATA.csv"
                                                data={cdata}
                                                headers={false}
                                                onClick={handleCSVDownload}
                                            >
                                                Generate CSV
                                            </CSVLink>
                                        ) : (
                                            <button
                                                size="sm"
                                                className="btn btn-primary"
                                                style={{ marginLeft: '10px' }}
                                                disabled
                                            >
                                                Generate CSV
                                            </button>
                                        )
                                    ) : null}
                                    {selectedRowData.length && (selectedStatus === "Rejected" || "Waiting For Approval" || "Approved") ? (
                                        <Button size="sm" onClick={() => handleApproval("Approved")} style={{ marginLeft: '10px' }} variant="success">Approve</Button>
                                    ) : null}
                                    {selectedRowData.length && (selectedStatus === "Approved" || "Active") ? (
                                        <Button size="sm" onClick={() => handleApproval("Rejected")} style={{ marginLeft: '10px' }} variant="danger">Reject</Button>
                                    ) : null}
                                </div>
                            </form>
                            <Modal show={showModal} onHide={() => setShowModal(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Reason for Rejection</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label>Please enter the reason for rejection:</Form.Label>
                                        <Form.Control type="text" value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                                    <Button variant="primary" onClick={handleModalSubmit}>Continue</Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                    )}
                </div>
            </div>

            {!loading && queryResult.length && formData.transtype === 'BillExtract' ? (
                <div style={{ width: '100vw' }}>
                    <div className={`contin ${pdfUrl ? 'split' : ''}`}>
                        <div className="left-panel">
                        <table border='1' id="table-css">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                        </th>
                                        {/* <th>Transaction Status</th> */}
                                        <th>PDF</th>
                                        <th>Status</th>
                                        <th>Approved Activity Code</th>
                                        <th>Vendor Name</th>
                                        <th>Expense Ledgers</th>
                                        <th>Vendor Invoice List</th>
                                        <th>Accounting Date</th>
                                        <th>Created Id</th>
                                        <th>For Period From</th>
                                        <th>For Period To</th>
                                        <th>Prd Provisions</th>
                                        <th>Prd Expense Booked</th>
                                        <th>Prd PrePaid Expense Booked</th>
                                        <th>IGST Input</th>
                                        <th>CGST Input</th>
                                        <th>SGST Input</th>
                                        <th>Input Tax Classification</th>
                                        <th>RCM Output Tax Classification</th>
                                        <th>IGST Output</th>
                                        <th>CGST Output</th>
                                        <th>SGST Output</th>
                                        <th>Prd Total Input Tax</th>
                                        <th>Prd Total Output Tax</th>
                                        <th>Prd WH Tax Amount</th>
                                        <th>WH Tax Classification</th>
                                        <th>Prd Payable Amount</th>
                                        <th style={{ maxWidth: '20px' }}>LOB Allocated Per</th>
                                        <th>LOB Allocated Value</th>
                                        <th>Transaction Type</th>
                                        <th>Item Type</th>
                                        <th>Account Groups</th>
                                        <th>MIS Group Names</th>
                                        <th>Vendor ID</th>
                                        <th>Account Code</th>
                                        <th>Yet To Approve Activity Code</th>
                                        <th>Journals</th>  
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredResults.map((item, index) => (
                                        <tr key={index}
                                            style={{
                                                backgroundColor: selectedItems.includes(item["createdid"]) ? '#a0d3ff' : 'transparent',
                                            }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(item["createdid"])}
                                                    onChange={() => handleCheckboxChange(item["createdid"], selectedStatus)}
                                                    
                                                />
                                            </td>
                                            <td>
                                                <a href="#" onClick={() => getPdf(item["FileLocation"])} style={{ color: activePdf === item["FileLocation"] ? 'red' : 'black' }}>
                                                    <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />
                                                </a>
                                            </td>
                                            {/* <td>{item["ActivityStatus"]}</td> */}
                                            <td>{item["Status"]}</td>
                                            <td>{item["AActivityCode"]}</td>
                                            <td>{item.VendorNames}</td>
                                            <td>{item.ExpenseLedgers}</td>
                                            <td>{item.VendorInvoiceList}</td>
                                            <td>{item.accountingdate}</td>
                                            <td><Button variant='link' size='sm' onClick={() => handleBill(item)}>{item["createdid"]}</Button></td>
                                            {/* <td>{item["createdid"]}</td> */}
                                            <td>{item.ReportingPrdFrom}</td>
                                            <td>{item.ReportingPrdTo}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdProvisions ? item.PrdProvisions : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
                                            <td>{item.InputTaxClassification}</td>
                                            <td>{item.RCMOutputTaxClassification}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
                                            <td>{item.WHTaxClassification}</td>
                                            <td style={{ textAlign: 'right' }}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
                                            <td style={{ maxWidth: '600px' }}>{item.LobAllocPer}</td>
                                            <td>{item.LobAllocValue}</td>
                                            <td>{item.transactiontype}</td>
                                            <td>{item.ItemType}</td>
                                            <td>{item.AccountGroups}</td>
                                            <td>{item.MISGroupNames}</td>
                                            <td>{item.VendorID}</td>
                                            <td>{item.AccountCode}</td>
                                            <td>{item["YTAActivityCode"]}</td>
                                            <td>{item.Journals}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {pdfUrl && (
                            <div className="right-panel">
                                <button className="close-btn" onClick={closeModal}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                                <iframe src={pdfUrl} style={{ width: '100%', height: '100%' }} />
                            </div>
                        )}
                    </div>
                </div>
            ):null}
    
            {/* GSE Table */}
            {!loading && queryResult.length && formData.transtype === 'GoodsAndServicesEntries' ? (
                <div style={{ width: '100vw' }}>
                <div className={`contin ${pdfUrl ? 'split' : ''}`}>
                    <div className="left-panel">
                    <table border='1' id="table-css">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    {/* <th>Transaction Status</th> */}
                                    {/* <th>PDF</th> */}
                                    <th>Status</th>
                                    <th>Approved Activity Code</th>
                                    <th>Accounting Date</th>
                                    <th>Created Id</th>
                                    <th>For Period From</th>
                                    <th>For Period To</th>
                                    <th>Account Code</th>
                                    <th>Expense Ledgers</th>
                                    <th>Prd Provisions</th>
                                    <th>Prd Expense Booked</th>
                                    <th>Prd PrePaid Expense Booked</th>
                                    <th>WH Tax Classification</th>
                                    <th>Prd WH Tax Amount</th>
                                    <th>Input Tax Classification</th>
                                    <th style={{ maxWidth: '20px' }}>LOB Allocated Per</th>
                                    <th>LOB Allocated Value</th>
                                    <th>IGST Input</th>
                                    <th>CGST Input</th>
                                    <th>SGST Input</th>
                                    <th>RCM Output Tax Classification</th>
                                    <th>IGST Output</th>
                                    <th>CGST Output</th>
                                    <th>SGST Output</th>
                                    <th>Prd Total Input Tax</th>
                                    <th>Prd Total Output Tax</th>
                                    <th>Prd Payable Amount</th>
                                    <th>Item Type</th>
                                    <th>Account Groups</th>
                                    <th>MIS Group Names</th>
                                    <th>Vendor ID</th>
                                    <th>Vendor Name</th>
                                    <th>Vendor Invoice List</th>
                                    <th>Journals</th>

                                    <th>Transaction Type</th>
                                    <th>Yet To Approve Activity Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredResults.map((item, index) => (
                                    <tr key={index}
                                        style={{
                                            backgroundColor: selectedItems.includes(item["createdid"]) ? '#a0d3ff' : 'transparent',
                                        }}
                                    >
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(item["createdid"])}
                                                onChange={() => handleCheckboxChange(item["createdid"], selectedStatus)}
                                                
                                            />
                                        </td>
                                        {/* <td>
                                            <a href="#" onClick={() => getPdf(item["FileLocation"])} style={{ color: activePdf === item["FileLocation"] ? 'red' : 'black' }}>
                                                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />
                                            </a>
                                        </td> */}
                                        {/* <td>{item["ActivityStatus"]}</td> */}
                                        <td>{item["Status"]}</td>
                                        <td>{item["AActivityCode"]}</td>
                                        <td>{item.accountingdate}</td>
                                        {/* <td><Button variant='link' size='sm' onClick={() => handleBill(item)}>{item["createdid"]}</Button></td> */}
                                        <td>{item["createdid"]}</td>
                                        <td>{item.ReportingPrdFrom}</td>
                                        <td>{item.ReportingPrdTo}</td>
                                        <td>{item.AccountCode}</td>
                                        <td>{item.ExpenseLedgers}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdProvisions ? item.PrdProvisions : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
                                        <td>{item.WHTaxClassification}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
                                        <td>{item.InputTaxClassification}</td>
                                        <td style={{ maxWidth: '600px' }}>{item.LobAllocPer}</td>
                                        <td>{item.LobAllocValue}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
                                        <td>{item.RCMOutputTaxClassification}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
                                        <td style={{ textAlign: 'right' }}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
                                        <td>{item.ItemType}</td>
                                        <td>{item.AccountGroups}</td>
                                        <td>{item.MISGroupNames}</td>
                                        <td>{item.VendorID}</td>
                                        <td>{item.VendorNames}</td>
                                        <td>{item.VendorInvoiceList}</td>
                                        <td>{item.Journals}</td>

                                        <td>{item.transactiontype}</td>
                                        <td>{item["YTAActivityCode"]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            ):null}
    
            {/* Third Table (For Other Transaction Types) */}
            {!loading && queryResult.length && formData.transtype !== 'BillExtract' && formData.transtype !== 'GoodsAndServicesEntries' && formData.transtype !== 'Payment' ? (
                <div style={{ width: '100vw' }}>
                    <div className={`contin ${pdfUrl ? 'split' : ''}`}>
                        <div className="left-panel">
                        <table border='1' id="table-css">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Status</th>
                                    <th>Accounting Journal</th>
                                    <th>Created ID</th>
                                    <th style={{ maxWidth: '50px' }}>Accounting Date</th>
                                    {/* <th style={{maxWidth:'50px'}}>Activity Status</th> */}
                                    <th>Ledger Name</th>
                                    <th style={{ maxWidth: '50px' }}>Debit (INR)</th>
                                    <th style={{ maxWidth: '50px' }}>Credit (INR)</th>
                                    <th>Activity Name</th>
                                    <th>Approved Activity Code</th>
                                    <th>Yet To Approve Activity Code</th>

                                </tr>
                            </thead>
                            <tbody>
                                {sortedResults.map((item, index) => {
                                    return (
                                        <tr key={index}
                                            style={{
                                                backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
                                            }}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedItems.includes(item["FileLocation"])}
                                                    onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"])}
                                                />
                                            </td>
                                            <td>{item["Status"]}</td>
                                            <td>{item.accountingdocnumber}</td>
                                            <td>{item["createdid"]}</td>
                                            <td style={{ maxWidth: '40px' }}>{getHumanReadableDate(item["accountingdate"])}</td>
                                            {/* <td style={{maxWidth:'60px'}}>{item["Activity Status"]}</td> */}
                                            <td>{item.ledgername}</td>
                                            <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Positive Amount"] ? item["Positive Amount"] : 0).toFixed(2)}</td>
                                            <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Negative Amount"] ? item["Negative Amount"] : 0).toFixed(2)}</td>
                                            <td>{item["Activity Name"]}</td>
                                            <td>{item["AActivityCode"]}</td>
                                            <td>{item["YTAActivityCode"]}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : null}

            {!loading && queryResult.length && formData.transtype === 'Payment' ? (
                <div style={{ width: '100vw' }}>
                    <div className={`contin ${pdfUrl ? 'split' : ''}`}>
                        <div className="left-panel">
                            <table border='1' id="table-css">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                        </th>
                                        <th>PaymentStatus</th>
                                        <th>senderaccount</th>
                                        <th>BillAcctgDate</th>
                                        <th>PaymentID</th>
                                        <th>Bill ID</th>
                                        <th>CreditDays</th>
                                        <th>Currency</th>
                                        <th>DueDate</th>
                                        <th>Invoice Date</th>
                                        <th>InvoiceNo</th>
                                        <th>IorN</th>
                                        <th>NowReleasing</th>
                                        <th>OutBankAccount</th>
                                        <th>OutBankName</th>
                                        <th>PAN</th>
                                        <th>PartyAcNumber</th>
                                        <th>PartyBankName</th>
                                        <th>Party IFSC Code</th>
                                        <th>PartyName</th>
                                         <th>ActivityStatus</th> 
                                        <th>PaymtDate</th>
                                        <th>Basic</th>
                                        <th>PrdWHTaxAmount</th>
                                        <th>Tax</th>
                                        <th>BillAmount</th>
                                        <th>transactiontype</th>
                                        <th>category</th>
                                        {filteredSecondTableResults.some(item => item["ActivityStatus"] === "Active") && (
                                            <>
                                                <th>CSV Downloaded</th>
                                                <th>Download Timestamp</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredSecondTableResults.map((item, index) => {
                                        const downloadInfo = downloadedItems[item["createdid"]] || {};
                                        return (
                                            <tr key={index}
                                                style={{
                                                    backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
                                                }}
                                            >
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedItems.includes(item["FileLocation"])}
                                                        onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"])}
                                                    />
                                                </td>
                                                <td>{item["ActivityStatus"]}</td>
                                                <td>{item.senderaccount}</td>
                                                <td>{item.BillAcctgDate}</td>
                                                <td>{item.createdid}</td>
                                                <td>{item.BillID}</td>
                                                <td>{item.CreditDays}</td>
                                                <td>{item.Currency}</td>
                                                <td>{item.DueDate}</td>
                                                <td>{item.InvoiceDate}</td>
                                                <td>{item.InvoiceNo}</td>
                                                <td>{item.IorN}</td>
                                                <td>{item.ThisBillPymt}</td>
                                                <td>{item.OutBankAccount}</td>
                                                <td>{item.OutBankName}</td>
                                                <td>{item.PAN}</td>
                                                <td>{item.PartyAcNumber}</td>
                                                <td>{item.PartyBankName}</td>
                                                <td>{item.PartyIFSCCode}</td>
                                                <td>{item.PartyName}</td>
                                                 <td>{item.Status}</td> 
                                                <td>{item.PaymtDate}</td>
                                                <td>{item.Basic}</td>
                                                <td>{item.WHTaxAmount}</td>
                                                <td>{item.Tax}</td>
                                                <td>{item.BillPayableAmt}</td>
                                                <td>{item.transactiontype}</td>
                                                <td>{item.category}</td>
                                                {item["ActivityStatus"] === "Active" && (
                                                    <>
                                                        <td>{downloadInfo.downloaded ? 'Yes' : 'No'}</td>
                                                        <td>{downloadInfo.timestamp || '-'}</td>
                                                    </>
                                                )}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ):null}
            <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}>
                {showScrollButton ? (
                    <>
                        <Button size='sm' onClick={scrollToTop}><NorthIcon /></Button>
                        <br />
                        <br />
                        <Button size='sm' onClick={scrollToBottom}><SouthIcon /></Button>
                    </>
                ) : null}
            </div>
            {showError &&
                <div className="center-screen">
                    {warningMessage}
                </div>}
            {loading ? <div className='center'>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
            </div> : null}
        </>
    )
}
export default BulkApprovals;



// import { useEffect, useState } from "react"
// import React from 'react';
// import { getRequest, getHumanReadableDate, postRequest, getStandardDate } from "../../globalhelper/helper";
// import '../CI/COD.css';
// import { Button, Form, Modal } from 'react-bootstrap';
// import SouthIcon from '@mui/icons-material/South';
// import NorthIcon from '@mui/icons-material/North';
// import { CSVLink } from "react-csv";
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
// import { connect } from "react-redux";
// import './Modal.css'
// import Select from 'react-select';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf, faTimes } from '@fortawesome/free-solid-svg-icons';

// const SelectType = ({ label, name, value, onChange }) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select className="spacing" name={name} value={value} onChange={onChange}>
//                     <option value='' disabled>Select</option>
//                     <option value='BillExtract'>Bill</option>
//                     <option value='GoodsAndServicesEntries'>GSE</option>
//                     <option value="CreditCard">Credit Card</option>
//                     <option value="DigitalWalletAccounting">Digital Wallet Accounting</option>
//                     <option value='EmployeeCost'>Employee Cost</option>
//                     <option value='EmployeeCostAccrued'>Employee Cost Accrued</option>
//                     <option value='EmployeeCostFnF'>Employee Cost FnF</option>
//                     <option value='EmployeeCostAccruedReversal'>Employee Cost Accrued Reversal</option>
//                     <option value="EmployeeReimbursement">Employee Reimbursement</option>
//                     <option value='InstructorAccrualReversal'>Instructor Accrual Reversal</option>
//                     <option value='InstructorCostAccrual'>Instructor Cost Accrual</option>
//                     <option value='InstructorInvoice'>Instructor Invoice</option>
//                     <option value='Payment'>Payment</option>
//                     <option value='PettyCash'>Petty Cash</option>
//                 </select>
//             </Form.Group>
//         </div>
//     )
// }

// const SelectStatus = ({ label, name, value, onChange }) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select className="spacing" name={name} value={value} onChange={onChange}>
//                     <option value=''>Select</option>
//                     <option value='Active'>Active</option>
//                     <option value="Waiting For Approval">Waiting For Approval</option>
//                     <option value="Rejected">Rejected</option>
//                 </select>
//             </Form.Group>
//         </div>
//     )
// }
// const SelectCSVDownloaded = ({ label, name, value, onChange }) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <select
//                     className="spacing"
//                     name={name} // Make sure the name matches the formData field
//                     value={value} // Make sure value comes from formData
//                     onChange={onChange} // Ensure onChange is working
//                 >
//                     <option value=''>Select</option>
//                     <option value='Yes'>Yes</option>
//                     <option value='No'>No</option>
//                 </select>
//             </Form.Group>
//         </div>
//     );
// };

// const BulkApprovals = (props) => {
//     const screenName = localStorage.getItem("pageName")
//     const [formData, setFormData] = useState({
//         transtype: '',
//         fromDate: '',
//         toDate: '',
//         company: '',
//         email: '',
//         transstatus: '',
//         csvDownloaded: '',
//         vendorName: [],  // Ensure this is an array
//         expenseLedgers: []
//     })

//     const [companyName, setCompanyName] = useState([])
//     const [companyDetails, setCompanyDetails] = useState([])
//     const [loading, setLoading] = useState(false)
//     const [queryResult, setQueryResult] = useState([])
//     const [qresult, setQResult] = useState([])
//     const [selectedItems, setSelectedItems] = useState([])
//     const [selectedRowData, setSelectedRowData] = useState([])
//     const [selectAll, setSelectAll] = useState(false)
//     const [activityCode, setActivityCode] = useState([])
//     const [showError, setShowError] = useState(false)
//     const [warningMessage, setWarningMessage] = useState('')
//     const [month, setMonth] = useState("")
//     const [year, setYear] = useState("")
//     const [showScrollButton, setShowScrollButton] = useState(false)
//     const [isBillType, setIsBillType] = useState(false);
//     const [selectedStatus, setSelectedStatus] = useState('');
//     const [redirectBill, setRedirectBill] = useState(false);
//     const [searchJSON, setSearchJSON] = useState(null);
//     const [UserInputdetails, setUserInputdetails] = useState({});
//     const [pdfUrl, setPdfUrl] = useState(null);
//     const [activePdf, setActivePdf] = useState(null);
//     const [isFormVisible, setIsFormVisible] = useState(true);
//     const [downloadedItems, setDownloadedItems] = useState({});
//     const [showModal, setShowModal] = useState(false);
//     const [rejectionReason, setRejectionReason] = useState('');
//     const [approvalStatus, setApprovalStatus] = useState('');
//     const [cdata, setcdata] = useState('')
//     const [sortOrder, setSortOrder] = useState('asc');


//     useEffect(() => {
//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const orgname = responseData.map(item => item.orgname)
//             setCompanyDetails(responseData)
//             setCompanyName(orgname)

//             const companyid = responseData.map(item => item.orgid);
//             console.log("companyid", companyid);
//             const COD = companyid.sort()
//             console.log("COD", COD);
//             const CID = companyid[0]
//             console.log("CID", CID);

//             getRequest(`api/edureka/getUserInputFE?orgID=${CID}&page=${screenName}`).then(response => {
//                 console.log(response.res.data);
//                 const userdata = response.res.data.Item
//                 console.log("USERDTA", userdata);
//                 setUserInputdetails(userdata)
//                 setFormData({
//                     company: userdata.orgId[0] || '',
//                     fromDate: userdata.forprdfrom || '',
//                     toDate: userdata.forprdto || '',
//                     transtype: userdata.type || '',
//                     transstatus: userdata.transstatus || ''
//                 });
//             }).catch(err => console.log(err));
//         }).catch(err => console.log(err));
//     }, []);

//     useEffect(() => {
//         setIsBillType(formData.transtype === 'BillExtract' ? true : false);
//     }, [formData.transtype]);

//     const handleChange = (e) => {
//         const { name, value } = e.target
//         setFormData((prev) => {
//             return { ...prev, [name]: value }
//         })
//     }

//     // Handle multi-select for React Select components
//     const handleSelectChange = (selectedOption, field) => {
//         setFormData(prevFormData => ({
//             ...prevFormData,
//             [field]: selectedOption ? selectedOption.value : ''
//         }));
//     };
       

//     const handleexChange = (e) => {
//         const { name, value } = e.target
//         setFormData((prev) => {
//             return { ...prev, [name]: value }
//         })
//     }

//     const getPdf = async (pdfloc) => {

//         const id = companyDetails.filter(item => item.orgname === formData.company);
//         const companyid = id.length > 0 ? id.map(item => item.orgid)[0] : UserInputdetails.orgId;
//         console.log("COMPANYID", companyid);

//         postRequest(`api/edureka/getThePdf?pdfloc=${pdfloc}&orgID=${companyid}`).then(response => {
//             console.log("R", response);
//             const bufferData = response.res.data.data;
//             console.log("BD", bufferData);
//             const byteArray = new Uint8Array(bufferData);
//             const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
//             const url = URL.createObjectURL(pdfBlob);
//             setPdfUrl(url);
//             setActivePdf(pdfloc);
//             setIsFormVisible(false);
//         }).catch(error => {
//             console.error('Error fetching PDF:', error);
//         });

//     };

//     const closeModal = () => {
//         setPdfUrl(null);
//         setActivePdf(null);
//     };

//     const handleSubmit = () => { 
//         const id = companyDetails.filter(item => item.orgname === formData.company);
//         const companyid = id.length > 0 ? id.map(item => item.orgid) : UserInputdetails.orgId;
//         console.log("COMPANYID", companyid);


//         const item = {
//             forprdfrom: formData.fromDate,
//             forprdto: formData.toDate,
//             type: formData.transtype,
//             orgid: companyid,
//             email: formData.email,
//             transstatus: formData.transstatus,
//             page: screenName
//         };

//         setLoading(true);
//         setShowError(false);

//         postRequest(`api/edureka/getBulkApprovals`, item).then(response => {
//             console.log(response.res.data);
//             const res = response.res.data;
//             if (!res.length) {
//                 console.log("Entered");
//                 setShowError(true);
//                 setWarningMessage("No data found, please try some other combination");
//             }
//             const billNumbers = res.map(item => item.createdid);
//             const field = formData.transstatus ? formData.transstatus : "Waiting For Approval";
//             const filteredData = res.filter(item => item.ActivityStatus === field);

//             const newDownloadedItems = {};
//             filteredData.forEach(item => {
//                 if (item["ActivityStatus"] === 'Active') {
//                     newDownloadedItems[item["createdid"]] = {
//                         downloaded: false,
//                         timestamp: ''
//                     };
//                 }
//             });

//             // console.log(newDownloadedItems);


//             // Merge with previously downloaded data
//             setDownloadedItems(prev => ({ ...prev, ...newDownloadedItems }));

//             setQResult(filteredData);
//             setQueryResult(res);
//             setLoading(false);
//             setActivityCode([...new Set(billNumbers)]);

//             if (formData.transtype === 'Payment') {
//                  // Fetch downloaded status and timestamp for the createdids in queryResult
//                 const createdIds = res.map(item => item["createdid"]);
//                 console.log(`api/edureka/getDownloadedItemsByCreatedIds`, { createdIds })
//                 postRequest(`api/edureka/getDownloadedItemsByCreatedIds`, { createdIds })
//                 .then(downloadResponse => {
//                     // console.log('Raw download response:', downloadResponse);

//                     // Ensure response format is correct
//                     if (downloadResponse && downloadResponse.res && downloadResponse.res.data) {
//                         const downloadedData = downloadResponse.res.data;
//                         console.log('Parsed download data:', downloadedData);

//                         // Initialize updatedDownloadedItems
//                         const updatedDownloadedItems = {};

//                         // Decode response data
//                         Object.keys(downloadedData).forEach(createdId => {
//                             const item = downloadedData[createdId];
//                             updatedDownloadedItems[createdId] = {
//                                 downloaded: item.downloaded,
//                                 timestamp: item.timestamp
//                             };
//                         });

//                         // Set the state with the decoded data
//                         setDownloadedItems(updatedDownloadedItems);
//                     } else {
//                         console.error('Unexpected response format:', downloadResponse);
//                     }
//                 })
//                 .catch(err => {
//                     console.error('Error in postRequest:', err);
//                 });
//             }else{
//                 console.log(Error);
//             }
//         }).catch(err => {
//             setLoading(false);
//             console.log(err);
//         });
//     };

//     // const handleSelectAll = () => {
//     //     if (selectAll) {
//     //         setSelectedItems([])
//     //         setSelectedRowData([])
//     //     } else {
//     //         if (formData.transtype === "GoodsAndServicesEntries" || formData.transtype === "BillExtract") {
//     //             const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
//     //             setSelectedItems(allIndices)
//     //             const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
//     //             const selectedRows = allIndices
//     //                 .map((index) => [
//     //                     queryResult[index]["createdid"]
//     //                 ])
//     //                 .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["createdid"] === item[0]))
//     //                 .flat()
//     //             const deDuplicatedArray = [...new Set(selectedRows)]
//     //             console.log(deDuplicatedArray)
//     //             setSelectedItems(deDuplicatedArray)
//     //             setSelectedRowData(deDuplicatedArray)
//     //         }else{
//     //         const allIndices = Array.from({ length: queryResult.length }, (_, i) => i)
//     //         setSelectedItems(allIndices)
//     //         const filteredColumns = queryResult.filter((item) => item.Status === 'Yes' || item.Status === 'No')
//     //         const selectedRows = allIndices
//     //             .map((index) => [
//     //                 queryResult[index]["FileLocation"],
//     //                 queryResult[index]["AD OUTPUT FILE"]
//     //             ])
//     //             .filter((item) => !filteredColumns.some((filteredItem) => filteredItem["FileLocation"] === item[0]))
//     //             .flat()
//     //         const deDuplicatedArray = [...new Set(selectedRows)]
//     //         console.log(deDuplicatedArray)
//     //         setSelectedItems(deDuplicatedArray)
//     //         setSelectedRowData(deDuplicatedArray)
//     //     }
//     //     }
//     //     setSelectAll(!selectAll)
//     // }

//     const handleSelectAll = () => {
//         let itemsToSelect;
    
//         // Apply filters for Vendor Name and Expense Ledgers if they are selected
//         if (formData.vendorName || formData.expenseLedgers) {
//             itemsToSelect = queryResult.filter(item => {
//                 const matchesVendor = formData.vendorName ? item.VendorNames === formData.vendorName : true;
//                 const matchesExpenseLedger = formData.expenseLedgers ? item.ExpenseLedgers === formData.expenseLedgers : true;
//                 return matchesVendor && matchesExpenseLedger;
//             });
//         } else {
//             // Select all items if no filters are applied
//             itemsToSelect = queryResult;
//         }
    
//         if (selectAll) {
//             setSelectedItems([]);
//             setSelectedRowData([]);
//         } else {
//             const allIndices = itemsToSelect.map((item, index) => index);
//             const filteredColumns = itemsToSelect.filter(item => item.Status === 'Yes' || item.Status === 'No');
//             const selectedRows = allIndices
//                 .map(index => [itemsToSelect[index]["createdid"]])
//                 .filter(item => !filteredColumns.some(filteredItem => filteredItem["createdid"] === item[0]))
//                 .flat();
//             const deDuplicatedArray = [...new Set(selectedRows)];
            
//             setSelectedItems(deDuplicatedArray);
//             setSelectedRowData(deDuplicatedArray);
//         }
    
//         setSelectAll(!selectAll);
//     };
    

//     const handleCheckboxChange = (cid, index, adFileLocation) => {
//         if(formData.transtype==="BillExtract" || formData.transtype==="GoodsAndServicesEntries"){
//             console.log(cid)
//             const selected = [...selectedItems];
//             if ( selected.includes(cid)) {
//                 const filteredSelected = selected.filter((item) => item !== cid);
//                 setSelectedItems(filteredSelected);
//                 setSelectedRowData(filteredSelected);
//             } else {
//                 setSelectedItems([...selected,cid]);
//                 setSelectedRowData([...selected,cid]);
//             }
//             setWarningMessage('');
//         }else{
//             console.log(cid, index)
//             const selected = [...selectedItems];
//             if (selected.includes(cid) && selected.includes(index)) {
//                 const filteredSelected = selected.filter((item) => item !== cid && item !== index);
//                 setSelectedItems(filteredSelected);
//                 setSelectedRowData(filteredSelected);
//             } else {
//                 setSelectedItems([...selected, cid, index]);
//                 setSelectedRowData([...selected, cid, index]);
//             }
//             setWarningMessage('');
//         }

//     };

//     const handleReset = () => {
//         setFormData({
//             transtype: '',
//             fromDate: '',
//             toDate: '',
//             company: '',
//             email: '',
//             transstatus: ''
//         })
//         setSelectedItems([])
//         setSelectedRowData([])
//         setSelectAll(false)
//         setQueryResult([])
//         setLoading(false)
//         setWarningMessage('')
//         setShowError(false)
//         setYear("")
//         setMonth("")
//     }

//     const handleApproval = (status) => {
//         if (status === "Rejected") {
//           setApprovalStatus(status);
//           setShowModal(true);
//         } else {
//           processApproval(status, null);
//         }
//       };
    
//       const processApproval = (status, reason) => {
//         console.log("REJ");
        
//         const id = UserInputdetails.orgId;
//         console.log("ID", id);
    
//         let selectedActivities = [];
//         let RowData = [];
    
//         if (formData.transtype === 'BillExtract' || formData.transtype === 'GoodsAndServicesEntries') {
//           selectedActivities = queryResult
//             .filter(item => selectedItems.includes(item["createdid"]))
//             .map(item => status === "Approved" ? item["YTAActivityCode"] : item["YTAActivityCode"])
//             .filter(value => value)
//             .flatMap(value => value.split(','))
//             .filter((value, index, self) => self.indexOf(value) === index);
//         } else {
//           selectedActivities = queryResult
//             .filter(item => selectedItems.includes(item.FileLocation))
//             .map(item => status === "Approved" ? item["YTAActivityCode"] : item["AActivityCode"])
//             .filter(value => value)
//             .flatMap(value => value.split(','))
//             .filter((value, index, self) => self.indexOf(value) === index);
//         }
    
//         RowData = selectedRowData.flatMap(value => value.split(','));
//         console.log("LOCATION", RowData);
    
//         const requestParams = {
//           s3FilePaths: RowData,
//           activityCode: selectedActivities,
//           status,
//           orgId: id,
//           type: formData.transtype,
//           reason: reason // add the rejection reason here if available
//         };
//         console.log("REQPARAMS", requestParams);
    
//         setLoading(true);
//         setShowError(false);
    
//         postRequest(`api/edureka/bulkApprovalStatusUpdate`, requestParams)
//           .then(response => {
//             console.log(response.res.data);
    
//             const item = {
//               forprdfrom: formData.fromDate,
//               forprdto: formData.toDate,
//               type: formData.transtype,
//               orgid: id
//             };
    
//             postRequest(`api/edureka/getBulkApprovals`, item)
//               .then(response => {
//                 const finalResponse = response.res.data;
//                 const val = response.res.data.map(item => item["AActivityCode"]);
//                 setLoading(false);
//                 setQueryResult(finalResponse.sort((a, b) => new Date(b.accountingdate) - new Date(a.accountingdate))) // Sort by date
//                 setActivityCode([...new Set(val)]);
//                 setSelectedItems([]);
//                 setSelectedRowData([]);
//               })
//               .catch(err => {
//                 setLoading(false);
//                 console.log(err);
//               });
    
//           })
//           .catch(err => {
//             setLoading(false);
//             console.log(err);
//           });
//       };

//     const handleScroll = () => {
//         const isCloseToBottom =
//             window.innerHeight + window.scrollY >= document.body.offsetHeight
//         setShowScrollButton(isCloseToBottom);
//     };

//     useEffect(() => {
//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const scrollToBottom = () => {
//         window.scrollTo({
//             top: document.body.scrollHeight,
//             behavior: 'smooth',
//         });
//     };

//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth',
//         });
//     };

//     const handleBill = async (item) => {
//         try {
//             const id = companyDetails.filter(company => company.orgname === formData.company);
//             const companyid = id.length > 0 ? id.map(company => company.orgid) : UserInputdetails.orgId;
//             console.log("COMPANYID", companyid);
//             console.log(`key=${item["createdid"]}&companyid=${companyid}`);
            
//             const response = await getRequest(`api/edureka/getJSONViaKey1?key=${item["createdid"]}&companyid=${companyid}`);
            
//             if (response && response.res && response.res.data) {
//                 console.log(response.res.data);
//                 setSearchJSON(response.res.data);
//                 setRedirectBill(true);
//             } else {
//                 setRedirectBill(false);
//             }
//         } catch (error) {
//             console.error("Error fetching the data", error);
//             setRedirectBill(false); // Set to false if an error occurs
//         }
//     };
    

//     const toggleFormVisibility = () => {
//         setIsFormVisible(!isFormVisible);
//     };

//     const handleModalSubmit = () => {
//         processApproval(approvalStatus, rejectionReason);
//         setShowModal(false);
//         setRejectionReason(''); // clear rejection reason after use
//       };

//     // const handleCSVDownload = () => {
//     //     const now = new Date().toLocaleString();

//     //     // Filter only selected items that have not been downloaded
//     //     const itemsToDownload = filteredResults.filter(item => {
//     //         const downloadInfo = downloadedItems[item["createdid"]] || {};
//     //         return selectedItems.includes(item["FileLocation"]) && downloadInfo.downloaded === false;
//     //     });

//     //     // console.log(itemsToDownload);

//     //     if (itemsToDownload.length === 0) {
//     //         alert("No rows selected or selected rows have already been downloaded.");
//     //         return; // Prevent download if no valid rows
//     //     }

//     //     setcdata(itemsToDownload);

//     //     // Update the downloaded status for the items being downloaded
//     //     const updatedDownloadedItems = { ...downloadedItems };
//     //     itemsToDownload.forEach(item => {
//     //         updatedDownloadedItems[item["createdid"]] = {
//     //             downloaded: true,
//     //             timestamp: now
//     //         };
//     //     });

//     //     // console.log(updatedDownloadedItems);
//     //     setDownloadedItems(updatedDownloadedItems);

//     //     // Call the API to save the updated download status
//     //     postRequest(`api/edureka/datenandtime`, updatedDownloadedItems)
//     //         .then(response => {
//     //             console.log('Download timestamps updated successfully:', response);
//     //         })
//     //         .catch(err => console.error('Error updating download timestamps:', err));
//     // };


//     // const filteredResults = formData.transstatus ? queryResult.filter(item => item["Status"] === formData.transstatus) : qresult
    

//     const formatDate = (date) => {
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
//         const day = String(date.getDate()).padStart(2, '0');
//         return `${year}/${month}/${day}`;
//     };

//     const handleCSVDownload = () => {
//         const now = new Date().toLocaleString();
    
//         // Filter only selected items that have not been downloaded
//         const itemsToDownload = filteredResults.filter(item => {
//             const downloadInfo = downloadedItems[item["createdid"]] || {};
//             return selectedItems.includes(item["FileLocation"]) && downloadInfo.downloaded === false;
//         });
    
//         if (itemsToDownload.length === 0) {
//             alert("No rows selected or selected rows have already been downloaded.");
//             return; // Prevent download if no valid rows
//         }
    
//         // Select only the necessary columns for the CSV
//         const selectedColumns = itemsToDownload.map(item => ([
//             item["IorN"], item["createdid"], item["PartyAcNumber"], item["ThisBillPymt"] ? Number(item["ThisBillPymt"]).toFixed(2) : "0.00", item["PartyName"], "", "","","","","","","", item["createdid"],"","","","","","","","",formatDate(new Date()),"", item["PartyIFSCCode"], item["PartyBankName"],"","payment.advices@edureka.co"
//     ]));
    
//         setcdata(selectedColumns);
    
//         // Update the downloaded status for the items being downloaded
//         const updatedDownloadedItems = { ...downloadedItems };
//         itemsToDownload.forEach(item => {
//             updatedDownloadedItems[item["createdid"]] = {
//                 downloaded: true,
//                 timestamp: now
//             };
//         });
    
//         setDownloadedItems(updatedDownloadedItems);
    
//         // Call the API to save the updated download status
//         postRequest(`api/edureka/datenandtime`, updatedDownloadedItems)
//             .then(response => {
//                 console.log('Download timestamps updated successfully:', response);
//             })
//             .catch(err => console.error('Error updating download timestamps:', err));
//     };

//     const vendorNames = queryResult && queryResult.length 
//     ? [...new Set(queryResult.map(item => item["VendorNames"]).filter(Boolean))] 
//     : [];
//     const expenseLedgers = queryResult && queryResult.length 
//         ? [...new Set(queryResult.map(item => item["ExpenseLedgers"]).filter(Boolean))] 
//         : [];

//         const filteredResults = queryResult && queryResult.length 
//         ? queryResult.filter(item => {
//             const matchesTransStatus = formData.transstatus
//                 ? (formData.transtype === 'Payment' 
//                     ? item["ActivityStatus"] === formData.transstatus 
//                     : item["Status"] === formData.transstatus)
//                 : true;
    
//             const matchesVendorName = formData.vendorName
//                 ? item["VendorNames"] === formData.vendorName
//                 : true;
    
//             const matchesExpenseLedgers = formData.expenseLedgers
//                 ? item["ExpenseLedgers"] === formData.expenseLedgers
//                 : true;
    
//             return matchesTransStatus && matchesVendorName && matchesExpenseLedgers;
//         }) 
//         : [];
    
    

//     const filteredSecondTableResults = queryResult.filter(item => {
//         const downloadInfo = downloadedItems[item["createdid"]] || {};
//         // Filter by CSV Downloaded based on formData.csvDownloaded
//         const matchesCSVDownloaded = formData.csvDownloaded ?
//             (formData.csvDownloaded === 'Yes' ? downloadInfo.downloaded === true : downloadInfo.downloaded === false)
//             : true;
//         // Add any other filtering logic, such as transstatus or transtype here if needed
//         const matchesTransStatus = formData.transstatus
//         ? (formData.transtype === 'Payment'
//             ? item["ActivityStatus"] === formData.transstatus
//             : item["Status"] === formData.transstatus)
//         : true;
//         return matchesCSVDownloaded && matchesTransStatus;
//     });

//     if (redirectBill) {
//         return (
//             <VR2_Forms
//                 screenName={"BillExtract"}
//                 screenAction={"edit"}
//                 From_SearchJSON={searchJSON}
//                 onBack={() => setRedirectBill(false)}
//             />
//         )
//     }

//     const toggleSortOrder = () => {
//         setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
//     };
    
//     // Sort filteredSecondTableResults within the render
//     const sortedResults = [...filteredSecondTableResults].sort((a, b) => {
//         const docA = a.accountingdocnumber || ''; // Default to empty string if undefined
//         const docB = b.accountingdocnumber || ''; // Default to empty string if undefined
//         if (sortOrder === 'asc') {
//             return docA.localeCompare(docB, undefined, { numeric: true });
//         } else {
//             return docB.localeCompare(docA, undefined, { numeric: true });
//         }
//     });

//     return (
//         <>
//              <div>
//             <Button onClick={toggleFormVisibility} variant="primary">
//                 {isFormVisible ? '<' : '>'}
//             </Button>
//             <div style={{
//                 maxHeight: isFormVisible ? '1000px' : '0',
//                 overflow: 'hidden',
//                 transition: 'max-height 0.5s ease-in-out',
//                 borderBottom: !isFormVisible ? '1px solid #ccc' : 'none',
//                 paddingBottom: !isFormVisible ? '10px' : '0'
//             }}>

//             {isFormVisible && (
//                 <>
//                 <form onSubmit={handleSubmit}>
//                     <div className="row">
//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>Company</Form.Label>
//                                 <select className="spacing" value={formData.company} name='company' onChange={handleChange}>
//                                     <option value='' disabled>Select</option>
//                                     {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                                 </select>
//                             </Form.Group>
//                         </div>

//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>From Date</Form.Label>
//                                 <input
//                                     type="date" name="fromDate" value={formData.fromDate} onChange={handleChange} className="form-control"
//                                 />
//                             </Form.Group>
//                         </div>

//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>To Date</Form.Label>
//                                 <input
//                                     type="date" name="toDate" value={formData.toDate} onChange={handleChange} className="form-control"
//                                 />
//                             </Form.Group>
//                         </div>

//                         <div className="col-md-2">
//                             <SelectType label="Type" name='transtype' value={formData.transtype} onChange={handleChange} />
//                         </div>
//                         {!loading && queryResult.length && formData.transtype === "BillExtract" ? (
//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>Vendor Name</Form.Label>
//                                 <select
//                                     name="vendorName"
//                                     value={formData.vendorName}
//                                     onChange={e => handleChange(e)}
//                                     className="form-control"
//                                 >
//                                     <option value="">Select Vendor Name</option>
//                                     {/* Filter vendor names based on filtered results */}
//                                     {Array.from(new Set(filteredResults.map(item => item.VendorNames))).map((vendor, index) => (
//                                         <option key={index} value={vendor}>
//                                             {vendor}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </Form.Group>
//                         </div>
//                     ) : null}

//                     {/* Expense Ledgers Dropdown */}
//                     {!loading && queryResult.length && formData.transtype === "BillExtract" ? (
//                         <div className="col-md-2">
//                             <Form.Group>
//                                 <Form.Label>Expense Ledgers</Form.Label>
//                                 <select
//                                     name="expenseLedgers"
//                                     value={formData.expenseLedgers}
//                                     onChange={e => handleChange(e)}
//                                     className="form-control"
//                                 >
//                                     <option value="">Select Expense Ledger</option>
//                                     {/* Filter expense ledgers based on filtered results */}
//                                     {Array.from(new Set(filteredResults.map(item => item.ExpenseLedgers))).map((ledger, index) => (
//                                         <option key={index} value={ledger}>
//                                             {ledger}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </Form.Group>
//                         </div>
//                     ) : null}
//                         {!loading && queryResult.length ?

//                                         <div className="col-md-2">
//                                             <SelectStatus label="Transaction Status" name='transstatus' value={formData.transstatus} onChange={handleChange} />
//                                         </div> : null}

//                                     {!loading && queryResult.length && formData.transtype == "Payment" && formData.transstatus !== "Waiting For Approval" ?
//                                         <div className="col-md-2">
//                                             <SelectCSVDownloaded
//                                                 label="Pymt File Generated"
//                                                 name='csvDownloaded'
//                                                 value={formData.csvDownloaded}
//                                                 onChange={handleChange}
//                                             />
//                                         </div> : null}
//                                 </div>

//                                 <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
//                                 <Button size="sm" onClick={handleSubmit} variant="warning">Submit</Button>
//                                 <Button size="sm" onClick={handleReset} style={{ marginLeft: '10px' }} variant="secondary">Reset</Button>

//                                 {queryResult.length ? (
//                                     <CSVLink
//                                         size="sm"
//                                         className="btn btn-primary"
//                                         style={{ marginLeft: '10px' }}
//                                         filename="BulkApprovals.csv"
//                                         data={filteredResults.length ? filteredResults : queryResult}
//                                     >
//                                         Download
//                                     </CSVLink>
//                                     ) : null}
//                                     {filteredResults.length && formData.transtype === "Payment" ? (
//                                         (formData.transstatus !== "Waiting For Approval" && formData.transstatus !== "Rejected" && formData.csvDownloaded !== "Yes" && formData.csvDownloaded !== "") ? (
//                                             <CSVLink
//                                                 size="sm"
//                                                 className="btn btn-primary"
//                                                 style={{ marginLeft: '10px' }}
//                                                 filename="GENERATED BULK DATA.csv"
//                                                 data={cdata}
//                                                 headers={false}
//                                                 onClick={handleCSVDownload}
//                                             >
//                                                 Generate CSV
//                                             </CSVLink>
//                                         ) : (
//                                             <button
//                                                 size="sm"
//                                                 className="btn btn-primary"
//                                                 style={{ marginLeft: '10px' }}
//                                                 disabled
//                                             >
//                                                 Generate CSV
//                                             </button>
//                                         )
//                                     ) : null}
//                                     {selectedRowData.length && (selectedStatus === "Rejected" || "Waiting For Approval" || "Approved") ? (
//                                         <Button size="sm" onClick={() => handleApproval("Approved")} style={{ marginLeft: '10px' }} variant="success">Approve</Button>
//                                     ) : null}
//                                     {selectedRowData.length && (selectedStatus === "Approved" || "Active") ? (
//                                         <Button size="sm" onClick={() => handleApproval("Rejected")} style={{ marginLeft: '10px' }} variant="danger">Reject</Button>
//                                     ) : null}
//                                 </div>
//                             </form>
//                             <Modal show={showModal} onHide={() => setShowModal(false)}>
//                                 <Modal.Header closeButton>
//                                     <Modal.Title>Reason for Rejection</Modal.Title>
//                                 </Modal.Header>
//                                 <Modal.Body>
//                                     <Form.Group>
//                                         <Form.Label>Please enter the reason for rejection:</Form.Label>
//                                         <Form.Control type="text" value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} />
//                                     </Form.Group>
//                                 </Modal.Body>
//                                 <Modal.Footer>
//                                     <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
//                                     <Button variant="primary" onClick={handleModalSubmit}>Continue</Button>
//                                 </Modal.Footer>
//                             </Modal>
//                         </>
//                     )}
//                 </div>
//             </div>

//             {!loading && queryResult.length && formData.transtype === 'BillExtract' ? (
//                 <div style={{ width: '100vw' }}>
//                     <div className={`contin ${pdfUrl ? 'split' : ''}`}>
//                         <div className="left-panel">
//                         <table border='1' id="table-css">
//                                 <thead>
//                                     <tr>
//                                         <th>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={selectAll}
//                                                 onChange={handleSelectAll}
//                                             />
//                                         </th>
//                                         {/* <th>Transaction Status</th> */}
//                                         <th>PDF</th>
//                                         <th>Status</th>
//                                         <th>Approved Activity Code</th>
//                                         <th>Vendor Name</th>
//                                         <th>Expense Ledgers</th>
//                                         <th>Vendor Invoice List</th>
//                                         <th>Accounting Date</th>
//                                         <th>Created Id</th>
//                                         <th>For Period From</th>
//                                         <th>For Period To</th>
//                                         <th>Prd Provisions</th>
//                                         <th>Prd Expense Booked</th>
//                                         <th>Prd PrePaid Expense Booked</th>
//                                         <th>IGST Input</th>
//                                         <th>CGST Input</th>
//                                         <th>SGST Input</th>
//                                         <th>Input Tax Classification</th>
//                                         <th>RCM Output Tax Classification</th>
//                                         <th>IGST Output</th>
//                                         <th>CGST Output</th>
//                                         <th>SGST Output</th>
//                                         <th>Prd Total Input Tax</th>
//                                         <th>Prd Total Output Tax</th>
//                                         <th>Prd WH Tax Amount</th>
//                                         <th>WH Tax Classification</th>
//                                         <th>Prd Payable Amount</th>
//                                         <th style={{ maxWidth: '20px' }}>LOB Allocated Per</th>
//                                         <th>LOB Allocated Value</th>
//                                         <th>Transaction Type</th>
//                                         <th>Item Type</th>
//                                         <th>Account Groups</th>
//                                         <th>MIS Group Names</th>
//                                         <th>Vendor ID</th>
//                                         <th>Account Code</th>
//                                         <th>Yet To Approve Activity Code</th>
//                                         <th>Journals</th>  
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {filteredResults.map((item, index) => (
//                                         <tr key={index}
//                                             style={{
//                                                 backgroundColor: selectedItems.includes(item["createdid"]) ? '#a0d3ff' : 'transparent',
//                                             }}
//                                         >
//                                             <td>
//                                                 <input
//                                                     type="checkbox"
//                                                     checked={selectedItems.includes(item["createdid"])}
//                                                     onChange={() => handleCheckboxChange(item["createdid"], selectedStatus)}
                                                    
//                                                 />
//                                             </td>
//                                             <td>
//                                                 <a href="#" onClick={() => getPdf(item["FileLocation"])} style={{ color: activePdf === item["FileLocation"] ? 'red' : 'black' }}>
//                                                     <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />
//                                                 </a>
//                                             </td>
//                                             {/* <td>{item["ActivityStatus"]}</td> */}
//                                             <td>{item["Status"]}</td>
//                                             <td>{item["AActivityCode"]}</td>
//                                             <td>{item.VendorNames}</td>
//                                             <td>{item.ExpenseLedgers}</td>
//                                             <td>{item.VendorInvoiceList}</td>
//                                             <td>{item.accountingdate}</td>
//                                             <td><Button variant='link' size='sm' onClick={() => handleBill(item)}>{item["createdid"]}</Button></td>
//                                             {/* <td>{item["createdid"]}</td> */}
//                                             <td>{item.ReportingPrdFrom}</td>
//                                             <td>{item.ReportingPrdTo}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.PrdProvisions ? item.PrdProvisions : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
//                                             <td>{item.InputTaxClassification}</td>
//                                             <td>{item.RCMOutputTaxClassification}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
//                                             <td>{item.WHTaxClassification}</td>
//                                             <td style={{ textAlign: 'right' }}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
//                                             <td style={{ maxWidth: '600px' }}>{item.LobAllocPer}</td>
//                                             <td>{item.LobAllocValue}</td>
//                                             <td>{item.transactiontype}</td>
//                                             <td>{item.ItemType}</td>
//                                             <td>{item.AccountGroups}</td>
//                                             <td>{item.MISGroupNames}</td>
//                                             <td>{item.VendorID}</td>
//                                             <td>{item.AccountCode}</td>
//                                             <td>{item["YTAActivityCode"]}</td>
//                                             <td>{item.Journals}</td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                         {pdfUrl && (
//                             <div className="right-panel">
//                                 <button className="close-btn" onClick={closeModal}>
//                                     <FontAwesomeIcon icon={faTimes} />
//                                 </button>
//                                 <iframe src={pdfUrl} style={{ width: '100%', height: '100%' }} />
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             ):null}
    
//             {/* GSE Table */}
//             {!loading && queryResult.length && formData.transtype === 'GoodsAndServicesEntries' ? (
//                 <div style={{ width: '100vw' }}>
//                 <div className={`contin ${pdfUrl ? 'split' : ''}`}>
//                     <div className="left-panel">
//                     <table border='1' id="table-css">
//                             <thead>
//                                 <tr>
//                                     <th>
//                                         <input
//                                             type="checkbox"
//                                             checked={selectAll}
//                                             onChange={handleSelectAll}
//                                         />
//                                     </th>
//                                     {/* <th>Transaction Status</th> */}
//                                     {/* <th>PDF</th> */}
//                                     <th>Status</th>
//                                     <th>Approved Activity Code</th>
//                                     <th>Accounting Date</th>
//                                     <th>Created Id</th>
//                                     <th>For Period From</th>
//                                     <th>For Period To</th>
//                                     <th>Account Code</th>
//                                     <th>Expense Ledgers</th>
//                                     <th>Prd Provisions</th>
//                                     <th>Prd Expense Booked</th>
//                                     <th>Prd PrePaid Expense Booked</th>
//                                     <th>WH Tax Classification</th>
//                                     <th>Prd WH Tax Amount</th>
//                                     <th>Input Tax Classification</th>
//                                     <th style={{ maxWidth: '20px' }}>LOB Allocated Per</th>
//                                     <th>LOB Allocated Value</th>
//                                     <th>IGST Input</th>
//                                     <th>CGST Input</th>
//                                     <th>SGST Input</th>
//                                     <th>RCM Output Tax Classification</th>
//                                     <th>IGST Output</th>
//                                     <th>CGST Output</th>
//                                     <th>SGST Output</th>
//                                     <th>Prd Total Input Tax</th>
//                                     <th>Prd Total Output Tax</th>
//                                     <th>Prd Payable Amount</th>
//                                     <th>Item Type</th>
//                                     <th>Account Groups</th>
//                                     <th>MIS Group Names</th>
//                                     <th>Vendor ID</th>
//                                     <th>Vendor Name</th>
//                                     <th>Vendor Invoice List</th>
//                                     <th>Journals</th>

//                                     <th>Transaction Type</th>
//                                     <th>Yet To Approve Activity Code</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {filteredResults.map((item, index) => (
//                                     <tr key={index}
//                                         style={{
//                                             backgroundColor: selectedItems.includes(item["createdid"]) ? '#a0d3ff' : 'transparent',
//                                         }}
//                                     >
//                                         <td>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={selectedItems.includes(item["createdid"])}
//                                                 onChange={() => handleCheckboxChange(item["createdid"], selectedStatus)}
                                                
//                                             />
//                                         </td>
//                                         {/* <td>
//                                             <a href="#" onClick={() => getPdf(item["FileLocation"])} style={{ color: activePdf === item["FileLocation"] ? 'red' : 'black' }}>
//                                                 <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />
//                                             </a>
//                                         </td> */}
//                                         {/* <td>{item["ActivityStatus"]}</td> */}
//                                         <td>{item["Status"]}</td>
//                                         <td>{item["AActivityCode"]}</td>
//                                         <td>{item.accountingdate}</td>
//                                         {/* <td><Button variant='link' size='sm' onClick={() => handleBill(item)}>{item["createdid"]}</Button></td> */}
//                                         <td>{item["createdid"]}</td>
//                                         <td>{item.ReportingPrdFrom}</td>
//                                         <td>{item.ReportingPrdTo}</td>
//                                         <td>{item.AccountCode}</td>
//                                         <td>{item.ExpenseLedgers}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.PrdProvisions ? item.PrdProvisions : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.PrdExpenseBooked ? item.PrdExpenseBooked : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.PrdPrePaidExpenseBooked ? item.PrdPrePaidExpenseBooked : 0).toFixed(2)}</td>
//                                         <td>{item.WHTaxClassification}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.PrdWHTaxAmount ? item.PrdWHTaxAmount : 0).toFixed(2)}</td>
//                                         <td>{item.InputTaxClassification}</td>
//                                         <td style={{ maxWidth: '600px' }}>{item.LobAllocPer}</td>
//                                         <td>{item.LobAllocValue}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.IGSTInput ? item.IGSTInput : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.CGSTInput ? item.CGSTInput : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.SGSTInput ? item.SGSTInput : 0).toFixed(2)}</td>
//                                         <td>{item.RCMOutputTaxClassification}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.IGSTOutput ? item.IGSTOutput : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.CGSTOutput ? item.CGSTOutput : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.SGSTOutput ? item.SGSTOutput : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalInputTax ? item.PrdTotalInputTax : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.PrdTotalOutputTax ? item.PrdTotalOutputTax : 0).toFixed(2)}</td>
//                                         <td style={{ textAlign: 'right' }}>{Number(item.PrdPayableAmount ? item.PrdPayableAmount : 0).toFixed(2)}</td>
//                                         <td>{item.ItemType}</td>
//                                         <td>{item.AccountGroups}</td>
//                                         <td>{item.MISGroupNames}</td>
//                                         <td>{item.VendorID}</td>
//                                         <td>{item.VendorNames}</td>
//                                         <td>{item.VendorInvoiceList}</td>
//                                         <td>{item.Journals}</td>

//                                         <td>{item.transactiontype}</td>
//                                         <td>{item["YTAActivityCode"]}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             </div>
//             ):null}
    
//             {/* Third Table (For Other Transaction Types) */}
//             {!loading && queryResult.length && formData.transtype !== 'BillExtract' && formData.transtype !== 'GoodsAndServicesEntries' && formData.transtype !== 'Payment' ? (
//                 <div style={{ width: '100vw' }}>
//                     <div className={`contin ${pdfUrl ? 'split' : ''}`}>
//                         <div className="left-panel">
//                         <table border='1' id="table-css">
//                             <thead>
//                                 <tr>
//                                     <th>
//                                         <input
//                                             type="checkbox"
//                                             checked={selectAll}
//                                             onChange={handleSelectAll}
//                                         />
//                                     </th>
//                                     <th>Status</th>
//                                     <th>Accounting Journal</th>
//                                     <th>Created ID</th>
//                                     <th style={{ maxWidth: '50px' }}>Accounting Date</th>
//                                     {/* <th style={{maxWidth:'50px'}}>Activity Status</th> */}
//                                     <th>Ledger Name</th>
//                                     <th style={{ maxWidth: '50px' }}>Debit (INR)</th>
//                                     <th style={{ maxWidth: '50px' }}>Credit (INR)</th>
//                                     <th>Activity Name</th>
//                                     <th>Approved Activity Code</th>
//                                     <th>Yet To Approve Activity Code</th>

//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {sortedResults.map((item, index) => {
//                                     return (
//                                         <tr key={index}
//                                             style={{
//                                                 backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
//                                             }}
//                                         >
//                                             <td>
//                                                 <input
//                                                     type="checkbox"
//                                                     checked={selectedItems.includes(item["FileLocation"])}
//                                                     onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"])}
//                                                 />
//                                             </td>
//                                             <td>{item["Status"]}</td>
//                                             <td>{item.accountingdocnumber}</td>
//                                             <td>{item["createdid"]}</td>
//                                             <td style={{ maxWidth: '40px' }}>{getHumanReadableDate(item["accountingdate"])}</td>
//                                             {/* <td style={{maxWidth:'60px'}}>{item["Activity Status"]}</td> */}
//                                             <td>{item.ledgername}</td>
//                                             <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Positive Amount"] ? item["Positive Amount"] : 0).toFixed(2)}</td>
//                                             <td style={{ textAlign: 'right', maxWidth: '40px' }}>{Number(item["Negative Amount"] ? item["Negative Amount"] : 0).toFixed(2)}</td>
//                                             <td>{item["Activity Name"]}</td>
//                                             <td>{item["AActivityCode"]}</td>
//                                             <td>{item["YTAActivityCode"]}</td>
//                                             </tr>
//                                         )
//                                     })}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             ) : null}

//             {!loading && queryResult.length && formData.transtype === 'Payment' ? (
//                 <div style={{ width: '100vw' }}>
//                     <div className={`contin ${pdfUrl ? 'split' : ''}`}>
//                         <div className="left-panel">
//                             <table border='1' id="table-css">
//                                 <thead>
//                                     <tr>
//                                         <th>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={selectAll}
//                                                 onChange={handleSelectAll}
//                                             />
//                                         </th>
//                                         <th>PaymentStatus</th>
//                                         <th>senderaccount</th>
//                                         <th>BillAcctgDate</th>
//                                         <th>PaymentID</th>
//                                         <th>Bill ID</th>
//                                         <th>CreditDays</th>
//                                         <th>Currency</th>
//                                         <th>DueDate</th>
//                                         <th>Invoice Date</th>
//                                         <th>InvoiceNo</th>
//                                         <th>IorN</th>
//                                         <th>NowReleasing</th>
//                                         <th>OutBankAccount</th>
//                                         <th>OutBankName</th>
//                                         <th>PAN</th>
//                                         <th>PartyAcNumber</th>
//                                         <th>PartyBankName</th>
//                                         <th>Party IFSC Code</th>
//                                         <th>PartyName</th>
//                                         {/* <th>ActivityStatus</th> */}
//                                         <th>PaymtDate</th>
//                                         <th>PrdWHTaxAmount</th>
//                                         <th>Tax</th>
//                                         <th>transactiontype</th>
//                                         <th>category</th>
//                                         {filteredSecondTableResults.some(item => item["ActivityStatus"] === "Active") && (
//                                             <>
//                                                 <th>CSV Downloaded</th>
//                                                 <th>Download Timestamp</th>
//                                             </>
//                                         )}
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {filteredSecondTableResults.map((item, index) => {
//                                         const downloadInfo = downloadedItems[item["createdid"]] || {};
//                                         return (
//                                             <tr key={index}
//                                                 style={{
//                                                     backgroundColor: selectedItems.includes(item["FileLocation"]) ? '#a0d3ff' : 'transparent',
//                                                 }}
//                                             >
//                                                 <td>
//                                                     <input
//                                                         type="checkbox"
//                                                         checked={selectedItems.includes(item["FileLocation"])}
//                                                         onChange={() => handleCheckboxChange(item["FileLocation"], item["AD OUTPUT FILE"])}
//                                                     />
//                                                 </td>
//                                                 <td>{item["ActivityStatus"]}</td>
//                                                 <td>{item.senderaccount}</td>
//                                                 <td>{item.BillAcctgDate}</td>
//                                                 <td>{item.createdid}</td>
//                                                 <td>{item.BillID}</td>
//                                                 <td>{item.CreditDays}</td>
//                                                 <td>{item.Currency}</td>
//                                                 <td>{item.DueDate}</td>
//                                                 <td>{item.InvoiceDate}</td>
//                                                 <td>{item.InvoiceNo}</td>
//                                                 <td>{item.IorN}</td>
//                                                 <td>{item.ThisBillPymt}</td>
//                                                 <td>{item.OutBankAccount}</td>
//                                                 <td>{item.OutBankName}</td>
//                                                 <td>{item.PAN}</td>
//                                                 <td>{item.PartyAcNumber}</td>
//                                                 <td>{item.PartyBankName}</td>
//                                                 <td>{item.PartyIFSCCode}</td>
//                                                 <td>{item.PartyName}</td>
//                                                 {/* <td>{item.Status}</td> */}
//                                                 <td>{item.PaymtDate}</td>
//                                                 <td>{item.WHTaxAmount}</td>
//                                                 <td>{item.Tax}</td>
//                                                 <td>{item.transactiontype}</td>
//                                                 <td>{item.category}</td>
//                                                 {item["ActivityStatus"] === "Active" && (
//                                                     <>
//                                                         <td>{downloadInfo.downloaded ? 'Yes' : 'No'}</td>
//                                                         <td>{downloadInfo.timestamp || '-'}</td>
//                                                     </>
//                                                 )}
//                                             </tr>
//                                         )
//                                     })}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             ):null}
//             <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}>
//                 {showScrollButton ? (
//                     <>
//                         <Button size='sm' onClick={scrollToTop}><NorthIcon /></Button>
//                         <br />
//                         <br />
//                         <Button size='sm' onClick={scrollToBottom}><SouthIcon /></Button>
//                     </>
//                 ) : null}
//             </div>
//             {showError &&
//                 <div className="center-screen">
//                     {warningMessage}
//                 </div>}
//             {loading ? <div className='center'>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//                 <div class="wave"></div>
//             </div> : null}
//         </>
//     )
// }
// export default BulkApprovals;


