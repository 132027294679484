import React, { Component } from 'react';
import './signup.css';
import ApiConstants from "../../config";
import axios from 'axios'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import { PopupButton, PopupWidget } from "react-calendly";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { onLogin } from '../../actions/login_actions'
import { connect } from 'react-redux'



class Signup extends Component {
    constructor(props){
      super(props);
      this.currentState = this.props.state;
      this.state = {
        link : ['', ''],
        ui : 'signup',
        display : "page1",
        userOTP: "",
        OTP: "",
        pass_eye : false,
        buttonStatus : false,
        emailErr_calendy : false,
        emailErr : '',
        phoneErr : '',
        passErr : '',
        OTPErr : '',
        resend_Email_Info : null,
        userInfo : null,
        formData : {
            email: '',
            phone : '',
            password: '',
        }
     }

     var d = window.location.pathname;

     let nav = d.includes('terms') ? 'terms' : d.includes('privacy') ? 'privacy' : '';

     if(nav)  this.state.ui = nav; 

    }


    componentDidMount(){

        this.setState({ link : this.portChange(ApiConstants.externals.serverUrl) },
         () =>{

             console.log(this.state.link, 'link');
             console.log(this.state.ui, 'ui');
             
         })
    }


    portChange = (input) => {

        if(input.includes('4000')) {
            return [
                 input.replace('4000', '3000') + 'signup/terms',
                 input.replace('4000', '3000') + 'signup/privacy'
                ]
        }
        else if (input.includes('backend/')) {
            return [
                input.replace('backend/', '') + 'signup/terms',
                input.replace('backend/', '') + 'signup/privacy'
               ]        
        }
    }
    
    
    togglePass = () => {
        const val = this.state.pass_eye
        this.setState({
            pass_eye: !val
        })
    }

    OTPOnChange = (e) => {
        let inputString = e.target.value

        const numericOnly = inputString.replace(/\D/g, '');
        const firstSixNumeric = numericOnly.slice(0, 6);

        console.log(firstSixNumeric);

        this.setState({
            userOTP : firstSixNumeric, OTPErr : ''
        })
    }


    passOnChange = (e) => {

        let val = e.target.value
        console.log(val)

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                password: val,
            }
        }, () => {

            if(this.state.formData.password.length >= 6){

                this.setState({ passErr : '' })                
            }
            else{                

                this.setState({ passErr : 'Password must be atleast 6 characters long' })
            }
        })
    }


    OTPOnSubmit = (e) => {
        e.preventDefault()

        let OPT = this.state.OTP
        let userOTP = this.state.userOTP

        if(OPT == userOTP){
            this.setState({ display: 'Pass', OTPErr : ''})
        }
        else{
            this.setState({ OTPErr : "OTP is wrong"})
        }
        
    }


    passOnSubmit = (e) => {
        e.preventDefault()

        const val = this.state.formData.password

        if(val.length >= 6){

            const INFO = this.state.userInfo
            INFO.password = this.state.formData.password

            axios.post(ApiConstants.externals.serverUrl + 'api/user/signupPasswordSubmitNew', INFO)
            .then(res => {

                console.log(res.data)

                if(res.data.message === "User Created"){

                    this.getLoggedIn("FM00001")
                }
            })
        }
    }


    getLoggedIn = (tenant_id) => {
          
        let userData = {
            email : this.state.formData.email,
            password : this.state.formData.password,
            tenantId : tenant_id
        };
        localStorage.setItem("tenant_id",tenant_id)
        this.props.onLogin(userData);
    }

    
    resendEmail = (e) => {

        e.preventDefault()

        const INFO = this.state.resend_Email_Info

        axios.post(ApiConstants.externals.serverUrl + 'api/user/resendEmail', INFO)
        .then(res => {

            console.log(res.data) 
        })
    }


    emailOnChange = (e) => {

        this.setState({
            ...this.state,
            emailErr: '',
            formData: {
                ...this.state.formData,
                email: e.target.value.toLowerCase(),
            }
        }, () => { 
            console.log(this.state.formData.email, 'email'); 
        })
    }


    setPhone = (phone) => {
        console.log(phone, 'phone')
        console.log(phone.length)
        
        this.setState({
            ...this.state,
            phoneErr: '',
            formData: {
                ...this.state.formData,
                phone : phone,
            }
        })
    }


    checkAll = () => {         

        // email
        this.setState( prevState => {
            
            let email = prevState.formData.email
            
            if ( email !== '' && email.includes("@") && email.split("@")[1].includes(".") ) {
              
              if (email.split("@")[0].length > 0 && email.split("@")[1].length > 0 && email.split("@")[1].split(".")[0].length > 0 && email.split("@")[1].split(".")[1].length > 0) {        
    
                return{ buttonStatus: true, emailErr: '' }
              } 
              else {
        
                return{ buttonStatus: false, emailErr: 'Please enter valid email' }
              }
            }
            else {
    
              return{ buttonStatus: false, emailErr: 'Please enter valid email' }
            }

        }, () => {

            // phone
            this.setState( prevState => {
    
                let phone = prevState.formData.phone
                let country_code = phone.slice(0, 2)
    
                if(phone.length !== '' && Number(phone)){
        
                    if(country_code === "91" && phone.length === 12) {
                        
                        return{ buttonStatus: true, phoneErr: '' }
                    }
                    else if (country_code !== "91") {
                        
                        return{ buttonStatus: true, phoneErr: '' }
                    }
                    else {
                        
                        return{ buttonStatus: false, phoneErr: 'Please enter valid phone number' }
                    }     
                }
                else {
    
                    return{ buttonStatus: false, phoneErr: 'Please enter valid phone number' }
                }
                
            }, () => {
    
                if(!this.state.emailErr && !this.state.phoneErr) {
                    console.log('Valid Submit')

                    const INFO = {   
                        email : this.state.formData.email,
                        phone: {
                            number: this.state.formData.phone,
                            country_name: this.state.formData.phone.slice(0, 2) === "91" ? "India" : "",
                        }
                    }

                    console.log(INFO) 
                    this.setState({ userInfo : INFO })

                    axios.post(ApiConstants.externals.serverUrl + 'api/user/signupEmailSubmitNew', INFO)
                    .then(res => {
                        // console.log(res.data)

                        if( res.data.message === "Email already exists" ){
                                
                            this.setState({ buttonStatus: false, emailErr: `Email already exists. So please Sign in`})                                
                        }
                        
                        else if ( res.data.message === "OTP sent to your email" ) {

                            this.setState({ display: 'OTP', OTP: res.data.data})
                        }

                    })

                }
                else {
                    console.log('Invalid Submit')
                }

            })
            
        }) 

    }

    
    onSubmit = (e) => {
        e.preventDefault()
        this.checkAll()       
    }
    

    parentCall = (e) => {

        // calling the parent component </Login>       
        this.props.parentCallback('signup')
        e.preventDefault()
    }
    


  render() {
    return (
      <div>
        {this.state.ui === 'signup' ?
        <div className="container">  

            <PopupWidget
                url="https://calendly.com/datatwin/understanding-your-business?text_color=061027&primary_color=1E46A3"
                /*
                * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                */
                rootElement={document.getElementById("root")}
                text="Talk to us !"
                textColor="#ffffff"
                color="#00a2ff"
            />

            <div className="cs-form">

                { this.state.display === "page1" ?
                    <div id="page1">
                    <div className="cs-form__title">Please complete your account setup</div>

                    <form id="signup" className='signup' name="signup" autoComplete="off">
                    
                    <div className="cs-form__group">
                        <div className="cs-form__label">Email</div>
                        <div className="cs-form__field" id="email_div">
                            <input type="email" id="email" name="email" placeholder="(e.g) name@company.com" autoFocus autoComplete="off" className="cs-form__control emailInput"  onChange={this.emailOnChange} />
                        </div>
                        {this.state.emailErr && <div className="help-block">{this.state.emailErr}</div>}
                    </div>
                    
                    <div className="cs-form__group">
                        <div className="cs-form__label">Phone no</div>
                        <div className="cs-form__field" id="phone_div" >
                        {/* <input  type="text" id="phone" name="phone" autoComplete="off" className="cs-form__control phoneInput" onChange="enableButton()" />  */}
                        
                        <PhoneInput style={{paddingLeft: '0px', marginLeft: '0px'}}
                            inputStyle={{marginLeft: '45px', border: 'none', marginTop: '8.5px'}}
                            country={"in"}
                            enableSearch={true}
                            value={this.state.formData.phone}
                            onChange={(phone) => this.setPhone(phone)}
                            autoComplete="off" className="cs-form__control phoneInput"
                        />
                        
                        </div>
                        {this.state.phoneErr && <div className="help-block">{this.state.phoneErr}</div>}
                    </div>

                    </form>

                    <div className="cs-form__actions" style={{display:'block'}}>

                    <div className="cs-form__action">

                        <div className="cs-form__field">
                            <button id="sign-up-submit" className="cs-form__btn" onClick={this.onSubmit} >Signup →</button>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <a onClick={this.parentCall} className="ui_link">Already have an account? Sign in.</a>
                            </div>
                        </div>
                        
                        <div className="cs-form__group" style={{marginTop:'40px', marginBottom:'12px', fontSize:'13px', color:'#7f7f7f'}}>
                            By clicking on Complete Signup, you agree to our
                            <a href={this.state.link[0]} target="_blank" style={{color:'#00000a', textDecoration:'none'}}> Terms</a> and you acknowledge having read our 
                            <a href={this.state.link[1]} target="_blank" style={{color:'#00000a', textDecoration:'none'}}> Privacy Notice</a>
                        </div>
                        
                        <div className="cs-form__group" style={{fontSize:'11px', marginBottom:'0', color:'#9f9ca6'}}>
                            <sup>*</sup>This includes periodic newsletters, emails about usage tips, billing
                            practices, and other communications. You can opt out anytime within the app.
                        </div>

                    </div>
                    </div> 

                    </div>

                    : null
                }
               
                { this.state.display === "Pass" ?

                    <div id="page3" >
                    <div className="cs-form__title">Please complete your account setup</div>
                    <div className="cs-form__group"  style={{ marginTop: '2.5rem'}} >
                    <div className="cs-form__label">Set Your Password</div>
                    <div id="pass" className="cs-form__field">
                        <div className="pass">
                        <input type={ this.state.pass_eye ? "text" : "password"} id="password" name="password" placeholder="Password" autoFocus autoComplete="off" className="cs-form__control" style={{width: '100%'}} onChange={this.passOnChange} />     
                        </div>
                        <div className="eye">
                        <i className={ this.state.pass_eye ? "fa fa-eye" : "fa fa-eye-slash"} id="togglePassword" onClick={this.togglePass} aria-hidden="true"></i>
                        </div>      
                    </div>
                    {this.state.passErr? 
                        <div className="help-block">{this.state.passErr}</div>
                        :
                        null
                    }
                    </div>
                    <button className="pass_btn" onClick={this.passOnSubmit}> Submit </button>
                    </div>

                    : null
                }

                { this.state.display === "OTP" ?

                    <div id="page4" >
                    <h2 className="cs-form__title">You're just a step away</h2>
                    <p className="page4_otp">We've sent an email to <span style={{color: '#1E46A3'}}>"{this.state.formData.email}"</span> with a OTP to complete your account setup.</p>
                    {/* <p className="page4_p"> <button className="resend_mail" style={{cursor: 'pointer'}} onClick={this.resendEmail}>Click to resend</button> the email if you can't find it in your inbox or spam
                    folder.</p> */}
                    <div className="cs-form__group"  style={{ marginTop: '2.5rem'}} >
                    <div className="cs-form__label">Enter your OTP</div>
                    <div id="pass" className="cs-form__field">
                        <div className="pass">
                        <input type="text" id="OTP" name="OTP" placeholder="Enter your OTP" autoFocus autoComplete="off" className="cs-form__control" style={{width: '100%'}} value={this.state.userOTP} onChange={this.OTPOnChange} />     
                        </div>
                    </div>  
                    {this.state.OTPErr? 
                        <div className="help-block">{this.state.OTPErr}</div>
                        :
                        null
                    }                  
                    </div>
                    <button className="pass_btn" onClick={this.OTPOnSubmit}> Enter </button>
                    </div>

                    : null
                }


            </div>
        </div>
        
            : this.state.ui === 'terms' ?

            <div className="item2">
                <p className="page2_head">Terms & Conditions</p>
                <p className="page2_p">Help protect your website and its users with clear and fair website terms and conditions. These terms and conditions for a website set out key issues such as acceptable use, privacy, cookies, registration and passwords, intellectual property, links to other sites, termination and disclaimers of responsibility. Terms and conditions are used and necessary to protect a website owner from liability of a user relying on the information or the goods provided from the site then suffering a loss.
                Making your own terms and conditions for your website is hard, not impossible, to do. It can take a few hours to few days for a person with no legal background to make. But worry no more; we are here to help you out.
                All you need to do is fill up the blank spaces and then you will receive an email with your personalized terms and conditions.</p>
            </div>

            : this.state.ui === 'privacy' ?

            <div className="item2">
                <p className="page2_head">Privacy Notice</p>
                <p className="page2_p">Help protect your website and its users with clear and fair website terms and conditions. These terms and conditions for a website set out key issues such as acceptable use, privacy, cookies, registration and passwords, intellectual property, links to other sites, termination and disclaimers of responsibility. Terms and conditions are used and necessary to protect a website owner from liability of a user relying on the information or the goods provided from the site then suffering a loss.
                Making your own terms and conditions for your website is hard, not impossible, to do. It can take a few hours to few days for a person with no legal background to make. But worry no more; we are here to help you out.
                All you need to do is fill up the blank spaces and then you will receive an email with your personalized terms and conditions.</p>
            </div>

            : null
        }
      </div>
    )
  }
}


const mapStateToProps = (state) => {
    return {
      state
    }
  };


export default connect(
    mapStateToProps,
    { onLogin }
)(Signup);
