import Api from '../config.json';
import {
    getRequest
} from '../globalhelper/helper';
import { TOGGLE_SELECT_VR_SCREEN_MODE } from '../constants/action-types'

export function fetchData(formData, process) {
    return (dispatch) => {
        dispatch(requestFormData());
        dispatch(receiveFormData(formData, process))
    }
}

export function toggleVRScreenMode(){
    return{
        type : TOGGLE_SELECT_VR_SCREEN_MODE
    }
}

export function fetchAccountingData(formData) {
    return (dispatch) => {
        dispatch(requestFormData());
        return fetchAccountingDetatils(formData).then(([response, json]) => {
            if (json.status === "success") {
                dispatch(receiveAccountingData(formData.process, json.data))
            }

        })
    }
}


function receiveFormData(details, process) {
    if (details.length === 0) {
        details.push({})
    }
    return {
        type: 'receiveFormData',
        details: details,
        process : process
    }
}


function receiveAccountingData(process, formData) {
    return {
        type: 'receiveAccountingData',
        process: process,
        treeData: formData
    }
}

function requestFormData() {
    return {
        type: 'requestTreeData'
    }
}

function addField(treeData) {
    return {
        type: 'addField',
        treeData: treeData
    }
}

function addGroup(treeData) {
    return {
        type: 'addGroup',
        treeData: treeData
    }
}

function flipTableHeader(treeData) {
    return {
        type: 'flipTableHeader',
        treeData: treeData
    }
}

function editField(treeData) {
    return {
        type: 'editField',
        treeData: treeData
    }
}

function receiveTreeData(treeData) {
    console.log(treeData, "response.res.data.json")
    return {
        type: 'receiveTreeData',
        treeData: treeData
    }
}

export function flushTree() {
    return {
        type: 'flushTree'
    }
}

function requestTreeData() {
    return {
        type: "requestTreeData"
    }
}

function moveTreeData(treeData) {
    console.log(treeData)
    return {
        type: 'changeTreeData',
        treeData: treeData
    }
}
export function changeTreeData(treeData) {
    console.log(treeData)
    return {
        type: 'changeTreeData',
        treeData: treeData
    }
}


export function fetchTreeData(tenant_id) {

    return (dispatch) => {
        dispatch(requestTreeData());
        return getRequest('api/visual/getHomePage?module=' + localStorage.getItem("module") +'&tenant_id=' + localStorage.getItem("tenant_id")).then((response) => {
            console.log(response);
            if (response.res.status === "success") {
                dispatch(receiveTreeData(response.res.data))
            }

        })
    }
}


export function fetchSavedTreeData() {

    return (dispatch) => {
        dispatch(requestTreeData());
        console.log('harish...')
        return getRequest("api/visual/getSavedVisualRenderingPage?product=" + localStorage.getItem("module") + "&page=" + localStorage.getItem("pagename")).then((response) => {
            console.log(response.res.data.json,"response.res.data.json")
            if (response.res.status === "info") {
                dispatch(receiveTreeData(response.res.data.json))
            }

        })
    }
}
export function handleTreeChange(treeData) {

    return (dispatch) => {
        dispatch(changeTreeData(treeData))
    }
}
export function addTreeField(treeData) {

    return (dispatch) => {
        dispatch(addField(treeData))
    }
}
export function handleChangeTreeMove(treeData) {
    return (dispatch) => {
        dispatch(moveTreeData(treeData))
    }
}

// function fetchTree() {
//     const URL = Api.externals.serverUrl + "api/visual/getHomePage";
//     return fetch(URL, {
//             method: 'GET'
//         })
//         .then(response =>
//             Promise.all([response, response.json()])
//         )
// }

function fetchSavedTree() {
    const URL = Api.externals.serverUrl + "api/visual/getSavedVisualRenderingPage?product=" + localStorage.getItem("module")
    return fetch(URL, {
            method: 'GET'
        })
        .then(response =>
            Promise.all([response, response.json()])
        )
}


function fetchDetatils(formData) {
    var URL = Api.externals.serverUrl + 'api/lease/getDetails';
    return fetch(URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response =>
            Promise.all([response, response.json()])
        )
}



function getModificationData(formData) {
    formData.external_id = formData.id;
    formData.tenantId = formData.tenant_id;
    var URL = Api.externals.serverUrl + 'api/lease/getModification';
    return fetch(URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response =>
            Promise.all([response, response.json()])
        )
}


function fetchAccountingDetatils(formData) {
    var URL = Api.externals.serverUrl + 'api/lease/getAccDetails';
    return fetch(URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response =>
            Promise.all([response, response.json()])
        )
}