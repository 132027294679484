import React , { Component } from 'react';
import { getCurrentDate, getStandardDateTime, postRequest } from '../globalhelper/helper';
import DatePicker from "react-datepicker";
import { navUrl } from '../actions/common_actions'
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const sources = [
    {
        value : "Fipola",
        name : "Fipola"
    }
]

const deltaSource = [
    {
        value : "TenderCuts",
        name : "Tender Cuts"
    }
]


class SchGenerator extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            formData : {
                fromdate : new Date(new Date().setDate(new Date().getDate()-7)),
                todate : new Date(),
                source : "Fipola",
                deltaRef : ""
            },
            data : [],
            showTable : false,
            fetchingItems : false,
            showingTodaySaved : false
        }

    }

    
    setDate = (date, field) => {

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [field]: date
            }
        })
    }

    onApprove = () => {
        const DATA = this.state.data;
        postRequest('api/pricing/saveApprovedPrice', { data : JSON.stringify(DATA) }).then(result => {
            if(result.type === "success")
            {
                window.location.reload();
                // this.setState({
                //     ...this.state,
                //     data : result.res.data,
                //     showProcess : true,
                // })
            }
        })
    }


    getAllItems = () => {
        this.setState({
            ...this.state,
            fetchingItems : true,
            showTable : false,
        }, () => {
            var formData = this.state.formData;
            console.log(formData.fromdate, formData.todate);
            // var processes = this.state.processes;

            var sData = {
                fromDate : formData.fromdate,
                toDate : formData.todate,
                source : this.state.formData.source,
                deltaRef : this.state.formData.deltaRef
            }

            this.setState({ ...this.state, loading : true, showProcess :false })
            postRequest('api/pricing/getPricingSheet',sData)
            .then(result => {
                if(result.type === "success")
                {
                    this.setState({
                        ...this.state,
                        data : result.res.data,
                        showTable : true,
                        fetchingItems : false,
                        showingTodaySaved : false,
                    })
                }
            })
        } )
    }


    getTodayChangedItems = () => {
        this.setState({
            ...this.state,
            fetchingItems : true,
            showTable : false,
        }, () => {
            var formData = this.state.formData;
            console.log(formData.fromdate, formData.todate);
            // var processes = this.state.processes;

            var sData = {
                fromDate : formData.fromdate,
                toDate : formData.todate,
                source : this.state.formData.source,
                deltaRef : this.state.formData.deltaRef
            }

            this.setState({ ...this.state, loading : true, showProcess :false })
            postRequest('api/pricing/getTodayChangedItems',sData)
            .then(result => {
                if(result.type === "success")
                {
                    this.setState({
                        ...this.state,
                        data : result.res.data,
                        showTable : true,
                        showingTodaySaved : true,
                        fetchingItems : false
                    })
                }
            })
        } )
    }

    handleChange = (ev) => {
        console.log(ev);
        this.setState({
            ...this.state,
            formData : {
                ...this.state.formData,
                [ev.target.name] : ev.target.value
            }
        })
    }

    onApprovedPriceChange = (ev, index) => {
        var value = ev.target.value;
        console.log(value);
        this.setState(prevState =>  {
            var prevData = prevState.data;
            var currentItem = prevData[index];
            currentItem.ApprovedPrice = value;

            return prevState;
        })
    }

    render (){
        return (
            <>
                <div className="row">
                    <div className="col-md-2">
                        <br />
                        <FormControl style={{ width : '100%' }}>
                            <InputLabel id="source">Source</InputLabel>
                            <Select
                            labelId="source"
                            id="demo-controlled-open-select"
                            name="source"
                            value={this.state.formData.source}
                            onChange={this.handleChange}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            { sources.map((source, key) =>
                                <MenuItem value={source.value}>{source.name}</MenuItem>
                             ) }
                            </Select>
                        </FormControl>

                    </div>
                    <div className="col-md-2">
                        <br />
                        <FormControl style={{ width : '100%' }}>
                            <InputLabel id="deltaRef">Competetor</InputLabel>
                            <Select
                            labelId="deltaRef"
                            id="demo-controlled-open-select"
                            name="deltaRef"
                            value={this.state.formData.deltaRef}
                            onChange={this.handleChange}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            { deltaSource.map((source, key) =>
                                <MenuItem value={source.value}>{source.name}</MenuItem>
                             ) }
                            </Select>
                        </FormControl>

                    </div>
                    <div className="col-md-2">
                        {/* <label htmlFor="fromdate">From</label> <br />
                        <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false} id="fromdate"
                            name="fromdate" maxDate={ this.state.formData.todate } dateFormat="dd-MM-yyyy"
                            className="col-md-12" onChange={ (date)=> this.setDate(date,'fromdate') } /> */}
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                // disableToolbar
                                variant="inline"
                                style={{ width : '100%' }}
                                format="DD/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="From date"
                                value={this.state.formData.fromdate}
                                onChange={(date) => this.setDate(date, 'fromdate')}
                                name="fromdate"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-md-2">
                        {/* <label htmlFor="fromdate">From</label> <br />
                        <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false} id="fromdate"
                            name="fromdate" maxDate={ this.state.formData.todate } dateFormat="dd-MM-yyyy"
                            className="col-md-12" onChange={ (date)=> this.setDate(date,'fromdate') } /> */}
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                // disableToolbar
                                variant="inline"
                                style={{ width : '100%' }}
                                format="DD/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="To date"
                                value={this.state.formData.todate}
                                onChange={(date) => this.setDate(date, 'todate')}
                                name="todate"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-md-2 text-center" style={{ marginTop : "25px" }}>
                        <button className="btn btn-teal" disabled={ this.state.processPending } onClick={ this.getTodayChangedItems }> { this.state.processPending ? "Process Already Pending" : "Load" } </button>
                    </div>
                </div>
                <br/><br/>
                { this.state.fetchingItems ? 
                    <div align="middle" style={{ flex : 1, alignContent : 'center', alignItems : "center" }} >
                        <CircularProgress disableShrink /> <br />
                        <h3>Please wait while fetching data...</h3>
                    </div>

                 :
                    this.state.showTable ?
                        <>
                            {
                            this.state.data.length ?
                            <>
                                <div style={{ height : 680, overflow : 'auto' }} >
                                    <table border={1} width="100%" className="scroll-body"  >
                                        <thead>
                                            <tr >
                                                <th style={{ padding : 5, }} >S.no</th>
                                                <th style={{ padding : 5, }} >SKU Code</th>
                                                <th style={{ padding : 5, width : 100 }} >Category</th>
                                                <th style={{ padding : 5, width : 200 }} >Product</th>
                                                <th style={{ padding : 5, }} >Today(CP)</th>
                                                <th style={{ padding : 5, }} >Packing Charges(PC)</th>
                                                <th style={{ padding : 5, }} > Total Cost (CP+PC)</th>
                                                <th style={{ padding : 5, }} >Price by Margin</th>
                                                <th style={{ padding : 5, }} >Reference Price</th>
                                                <th style={{ padding : 5, }} >Reference Margin</th>
                                                <th style={{ padding : 5, }} >Difference between Margin</th>
                                                <th style={{ padding : 5, }} >Suggested Price</th>
                                                <th style={{ padding : 5, }} >Approved Price</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            { this.state.data.map((item, key) => 
                                                <tr key={key}>
                                                    <td style={{ textAlign : 'center' }} > {key + 1} </td>
                                                    <td style={{ textAlign : "center" }} >{ item.MainComp }</td>
                                                    <td style={{ paddingLeft : 5 }}> { item.CompType } </td>
                                                    <td style={{ paddingLeft : 5 }}> { item.Name } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.CostPrice).toFixed(2) } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.PackingCost).toFixed(2) } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.TotalCost).toFixed(2) } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.PriceByMargin).toFixed(2) } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.RefPrice).toFixed(2) } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.RefMargin).toFixed(2) } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.DeltaMargin).toFixed(2) } </td>
                                                    <td style={{ textAlign : 'right', paddingRight : 5 }} > { Number(item.SuggestedPrice).toFixed(2) } </td>
                                                    <td > <input type="number" style={{ width : "100%", border : 0, textAlign : 'right', paddingRight : 5 }} value={ item.ApprovedPrice} onChange={(ev) => this.onApprovedPriceChange(ev, key)} /> </td>
                                                </tr>
                                            ) }
                                        </tbody>
                                    </table>

                                </div>
                                <br />
                                { this.state.showingTodaySaved &&
                                    <div className="row">
                                        <div className="col-md-12" align="right">
                                            <button className="btn btn-secondary" onClick={this.getAllItems}> Show all </button>
                                        </div>
                                    </div>
                                }
                                <br />
                                <div className="row">
                                    <div className="col-md-12" align="right">
                                            <button className="btn btn-primary" onClick={this.onApprove}> Approve </button>
                                    </div>
                                </div>
                            </>
                            : <div className="row" >
                                <div className="col-md-12" align="middle" >
                                    {/* <h3> */}
                                        {this.state.showingTodaySaved ? 
                                        <div>
                                            <h3>Pricing audit completed for { getStandardDateTime() }</h3>
                                            <h5>No differences were found from the current pricing or the competetor pricing.</h5>
                                        </div>
                                         :
                                         <h3> Items not found for the given criteria </h3>
                                         }
                                    {/* </h3> */}
                                </div>
                            </div>}
                            { this.state.showingTodaySaved && this.state.data.length === 0 &&
                                <div className="row">
                                    <div className="col-md-12" align="right">
                                        <button className="btn btn-secondary" onClick={this.getAllItems}> Show all </button>
                                    </div>
                                </div>
                            }
                        </>
                        : <div className="row">
                        <div className="col-md-12" align="middle" >
                            <h3> Please select criteria  </h3>
                        </div>
                    </div>
                }
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        state
    }
}


export default connect(
    mapStateToProps,
    { navUrl }
)(SchGenerator);
