import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './home.css';
import { connect } from 'react-redux';
import { fetchData, fetchSavedTreeData } from '../../actions/treeAction';
import { Spinner } from "react-bootstrap";
import { setMenuTree } from '../../actions/common_actions'
import {  getRequest } from '../../globalhelper/helper'
import '../../App.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import {
  Redirect
} from "react-router-dom";
import DatePicker from "react-datepicker";
import DisplayContainer from "../displayContainer";

class Home extends Component {
    
    constructor(props) {
        super(props);
        this.currentState = this.props.state;
        this.userData = this.currentState.login.userData;
        this.state = {
            AttendaceProdList : [],
            show_details : false,
            fetchingData : true,
            process_header : "",
            formData : {
                fromdate : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                todate : new Date(new Date().getFullYear(), new Date().getMonth() , 0),
                context : 'CL-STD-ASSREF',
                client : "AMZ",
                clientModel : "SL"
            },
            contextTypes : [
                {
                    value : 'CL-STD-ASSREF',
                    name : 'CL-STD-ASSREF'
                },
                {
                    value : 'CL-STD-STATION',
                    name : 'CL-STD-STATION'
                },
            ],
            clients : [
                {
                    value : 'AMZ',
                    name : 'Amazon'
                },
                {
                    value : 'FLIPKART',
                    name : 'Flipkart'
                },
            ],
            clientModelTypes : [
                {
                    value : 'SL',
                    name : 'Stationless'
                },
                {
                    value : 'ST',
                    name : 'Station'
                },
            ],
        };
        this.previousLoadLength = 0;
        // this.from_date = this.props.match.params.from_date;
        this.from_date = this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom ? this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom : new Date();
        // this.to_date = this.props.match.params.to_date;
        this.to_date = this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo ? this.props.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo : new Date();
    }

    // shouldComponentUpdate = (nextState, nextProps) => {
    //     if(this.from_date !== nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom || this.to_date !== nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo)
    //     {

    //     }
    // }

    componentWillReceiveProps(nextProps){
        if(nextProps.state.treeReducer.selection_mode === true && (this.from_date !== nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom || this.to_date !== nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo))
        {
            console.log('route changed', nextProps)
            // this.dashboard_id = nextProps.match.params.dashboard_id;
            this.from_date = nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom ? nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom :new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1) ;
            this.to_date = nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo ? nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo : new Date(new Date().getFullYear(), new Date().getMonth() , 0);
            this.setState({
                ...this.state,
                formData : {
                    fromdate : nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom ? nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdFrom :new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1) ,
                    todate : nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo ? nextProps.state.chatbotFormInputs.OCLCT1_BOTInput_ForPrdTo : new Date(new Date().getFullYear(), new Date().getMonth() , 0),
                }
            }, () => {
                this.getAttendanceProdList();
            })
            // this.forceUpdate()
            // window.location.reload()
        }
     }
    

    getAttendanceProdList = () => {
        this.setState({
            rateCards : [],
            fetchingData : true,
            show_details : false,
            selectedObj : []
        })
        // const FROM_DATE = getStandardDate(this.state.formData.fromdate)
        // const TO_DATE = getStandardDate(this.state.formData.todate)

        if(localStorage.getItem('pagename') === "CTMDownload")
        {
            this.setState({process_header : "OneCommerce"});
            var fromdate = this.state.formData.fromdate ? this.state.formData.fromdate.toISOString() : new Date().toISOString();
            var todate = this.state.formData.todate ? this.state.formData.fromdate.toISOString() : new Date().toISOString();
            var context = this.state.formData.context ? this.state.formData.context : 'CL-STD-ASSREF';
            var client = this.state.formData.client ? this.state.formData.client : 'AMZ';
            var clientModel = this.state.formData.clientModel ? this.state.formData.clientModel : 'SL';
            getRequest('api/transactions/getAllCTMData?tenant_id=AB00003&process_name=Client_Text_Map&context=' + context + "&client=" + client+ "&clientModel=" + clientModel + "&from_date=" + fromdate + "&to_date=" + todate).then(response => {
                // console.log(response);
                if(response.type === "success")
                {
                    var data = response.res.data;
                    this.setState({
                        ...this.state,
                        AttendaceProdList : data,
                        fetchingData : false,
                        show_details : true,
                        selectedObj : data
                    })
                }
            })
        }
        else{
            this.setState({process_header : "CIP_Header"});
            getRequest('api/transactions/getAllAttendanceProdList?tenant_id=AB00003&process_name=CIP_CIOS_Productivity&from_date=' + this.state.formData.fromdate.toISOString() + '&to_date=' + this.state.formData.todate.toISOString() + "&client=" + this.state.formData.client+ "&clientModel=" + this.state.formData.clientModel).then(response => {
                // console.log(response);
                if(response.type === "success")
                {
                    var data = response.res.data;
                    this.setState({
                        ...this.state,
                        AttendaceProdList : data,
                        fetchingData : false,
                        show_details : true,
                        selectedObj : data
                    })
                }
            })
        }
    }

    
    handleChange = (event) => {
        this.setState({ leaseSelected : false, 
            show_details : false,
            });
        var id = [event.target.id];
        this.setState({
            formData: {
                  ...this.state.formData,
                  [id]: event.target.value
            }
        })
        
    }


    componentDidMount = () => {
        // debugger;
        this.setState({
            ...this.state, 
            formData : {
                fromdate : this.from_date ? new Date(this.from_date) : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                todate : this.to_date ? new Date(this.to_date) : new Date(new Date().getFullYear(), new Date().getMonth() , 0)
            }
        }, () => {
            this.getAttendanceProdList();
        })
    }

    detectBottomOfDiv = () => {
        var element = document.getElementById("scroll-div")
        // window.addEventListener('scroll', function() {
        // var scrollDiv = document.querySelectorAll('[id="scroll-div"]');
        // console.log(element.scrollTop,element.scrollHeight);
        if (element.scrollHeight <= element.scrollTop + 1000 && !this.state.fetchingData) {

            this.setState({
                ...this.state,
                fetchingData : true
            })


            let formData = this.state.formData;
            formData.tenantId = this.userData.tenant_id;
            formData.org = this.userData.organization;
            console.log("you're at the bottom of the page");
            //show loading spinner and make fetch request to api
        }
    }

    

    getItem = (rc) => {
        console.log(rc, "selected Obj");
        rc.type = "live_rendering";
        this.setState({
            ...this.state,
            selectedObj: rc,
            show_details : true
        });

        this.props.fetchData(rc, localStorage.getItem("module"));
    }

    setDate = (date,field) =>{

        this.setState({
        ...this.state,
        formData : {
            ...this.state.formData,
            [field] : date
        }
        })
    }

    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div key={localStorage.getItem("pagename")} >
            <div className="row">
                   <div className='col-md-2'>
                       From 
                       {/* <label>From</label>  */}
                       <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false}  maxDate={ this.state.formData.todate } dateFormat="dd/MM/yyyy" className="col-md-9" onChange={ (date) => this.setDate(date,'fromdate') } id="fromdate" />
                   </div>
                   <div className='col-md-2'>
                       To
                       <DatePicker selected={this.state.formData.todate} showPopperArrow={false}  minDate={ this.state.formData.fromdate } dateFormat="dd/MM/yyyy" className="col-md-9" onChange={ (date) => this.setDate(date,'todate') } id="todate" />
                   </div>
                   { localStorage.getItem("module") === "OneCommerce" &&
                        <>
                            <div className='col-md-2'>
                                Client
                                <div className="form-group">
                                        <select className="form-control"  id="client" model={ this.state.formData.client } value={ this.state.formData.client } onChange={ (ev) => this.handleChange(ev) }>
                                            <option disabled>Client</option>
                                            { this.state.clients.map(opt => 
                                                <option key={opt.value} value={opt.value}> {opt.name} </option> )
                                            }
                                        </select>
                                    </div>
                            </div>
                            <div className='col-md-2'>
                                Client Model
                                <div className="form-group">
                                        <select className="form-control"  id="clientModel" model={ this.state.formData.clientModel } value={ this.state.formData.clientModel } onChange={ (ev) => this.handleChange(ev) }>
                                            <option disabled>Client Model</option>
                                            { this.state.clientModelTypes.map(opt => 
                                                <option key={opt.value} value={opt.value}> {opt.name} </option> )
                                            }
                                        </select>
                                    </div>
                            </div>
                        </>
                   }
                   { localStorage.getItem("pagename") === "CTMDownload" &&
                        <div className='col-md-2'>
                            Context
                            <div className="form-group">
                                    <select className="form-control"  id="context" model={ this.state.formData.context } value={ this.state.formData.context } onChange={ (ev) => this.handleChange(ev) }>
                                        <option disabled>Context</option>
                                        { this.state.contextTypes.map(opt => 
                                            <option key={opt.value} value={opt.value}> {opt.name} </option> )
                                        }
                                    </select>
                                </div>
                        </div>
                    }
                   
                   <div className="col-md-2 text-center"  style={{ marginTop : "25px" }}>
                       <button className="btn btn-teal" onClick={ this.getAttendanceProdList }> Load </button>
                   </div>
               </div>
               
               <div className="col-md-11">
                    { this.state.show_details ?
                        <DisplayContainer title={this.state.process_header} data={ this.state.AttendaceProdList } />
                        : 
                        this.state.fetchingData ?
                        
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                                <h6> Please Wait... </h6> 
                                <Spinner animation="grow" variant="text-navy"  /> 
                            </div>
                :
                        <h5 className="text-navy"> Please Select an Item to view </h5>
                    }
                </div>
            </div>            
        );
    }
}


function mapStateToProps(state) {
    return {
        state
    }
}


export default connect(
    mapStateToProps,
    { fetchData, fetchSavedTreeData, setMenuTree }
)(Home);
