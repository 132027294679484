import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getRequest } from "../../globalhelper/helper"

const initialState = {
    isLoading : false,
    AlreadyPaidBills :[],
    error:'' 
}

export const AlreadyPaidBillsByVendorID = createAsyncThunk("AlreadyPaidBillsByVendorID",async(params, thunkAPI)=>{
    try{
        let response = await getRequest(`api/veranda/getAlreadyPaidBillsByVendorID?OrgID=${params.OrgID}&VendorID=${params.VendorID}&CCID=${params.CCID}`);
        console.log(response);
        return response.res.data
    }catch(error){
        console.log(error);
        return thunkAPI.rejectWithValue(error.message);
    }
})

const AlreadyPaidBillsByVendorIDReducer = createSlice({
    name:'AlreadyPaidBillsByVendorID',
    initialState,
    extraReducers:(builders)=>{
        builders.addCase(AlreadyPaidBillsByVendorID.pending,(state)=>{
            state.isLoading = true;
            state.error = ''
        }).addCase(AlreadyPaidBillsByVendorID.fulfilled,(state,action)=>{
            state.isLoading = false;
            try {
                state.AlreadyPaidBills= JSON.parse(action.payload); // Ensure payload format
              } catch {
                state.error = "Failed to parse bills data.";
              }
        }).addCase(AlreadyPaidBillsByVendorID.rejected,(action,state)=>{
            state.BillsLoading = false;
            state.error = action.payload || "Failed to fetch bills data.";
        })
    }
})
// Export the reducer
export default AlreadyPaidBillsByVendorIDReducer.reducer;

