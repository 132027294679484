// import React, { Component, Fragment } from 'react'
// import {Form, Button} from 'react-bootstrap';
// import '../CI/COD.css';
// import { getRequest } from '../../globalhelper/helper';
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

// export class InternalPlan_Dropdown extends Component {
//     constructor(props) {
//       super(props)
    
//       this.state = {
//         showtable: false,
//          formData: {
//             createddate: '',
//             vendorname: '',
//             vendorid: '',
//             ipnumber: '',
//             taxcode: '',
//             periodfrom: '',
//             periodto: '',
//             status: '',
//             orgname: ''
//          },
//          tableData: [],
//          dropdown_orgname: [],
//          dropdown_vendorid: [],
//          dropdown_vendorname: [],
//          dropdown_ipnumber: [],
//          dropdown_status: [],
//          companyDetails: [],
//          error: false,
//          errorMessage: ''
//       }
//     }

//     componentDidMount(){
//         localStorage.setItem("pageName", "Search InternalPlan")

//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const arr = responseData.map(item => item.orgname)
//             console.log(arr)
//             this.setState({
//                 ...this.state,
//                 dropdown_orgname: arr,
//                 companyDetails: responseData
//             })
//         }).catch(err => console.log(err))

//         getRequest(`api/edureka/getPORemainingValues`).then(res => {
//             const responseData = res.res.data

//             this.setState({
//                 ...this.state,
//                 dropdown_status: responseData
//             })
//         })
//     }

//     handleChange = (e) => {
//         const NAME = e.target.name, VALUE = e.target.value

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [NAME]: VALUE
//             }
//         })
//     }

//     handleSearch = () => {

//         const orgname = this.state.formData.orgname

//         if(orgname){

//             const ipnumber = this.state.formData.ipnumber
//             const vendorname = this.state.formData.vendorname
//             const vendorid = this.state.formData.vendorid
//             const status = this.state.formData.status

//             this.setState({
//                 ...this.state,
//                 error: false
//             })

//             const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
//             const companyid = id.map(item => item.orgid)

//             getRequest(`api/edureka/getIPData?ipnumber=${ipnumber}&vendorname=${vendorname}&status=${status}&vendorid=${vendorid}&orgid=${companyid[0]}`).then(res => {
//                 let temp = JSON.parse(res.res.data)
//                 console.log(temp)

//                 if(temp.length){
//                     this.setState({
//                         ...this.state,
//                         tableData: temp,
//                         showtable: true
//                     })
//                 } else {
//                     this.setState({
//                         ...this.state,
//                         error: true,
//                         errorMessage: "No data found. Please try some other combination"
//                     })
//                 }
//             })
//         } else {
//             this.setState({
//                 ...this.state,
//                 error: true,
//                 errorMessage: "Please select all the required fields"
//             })
//         }       
//     }

//     handleReset = () => {
//         this.setState({
//             ...this.state,
//             showtable: false,
//             error: false,
//             formData: {
//                 createddate: '',
//                 vendorname: '',
//                 vendorid: '',
//                 ipnumber: '',
//                 taxcode: '',
//                 periodfrom: '',
//                 periodto: '',
//                 status: '',
//                 orgname: ''
//             }
//         })
//     }

//     handleIP = (item) => {
//         console.log(item)
//         const planid = item['IP Number']

//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getIPUpdatedJSON?planid=${planid}&orgid=${companyid[0]}`).then(res => {
//             if(res.res.status === 'success'){
//                 console.log(res.res.data)

//                 this.setState({ redirect : true, SearchJSON : res.res.data })                
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     onBack = () => {
//         this.setState({
//             redirect: false,
//             SearchJSON: []
//         })
//     }

//     handleOrgChange = (e) => {
//         const {name, value} = e.target

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [name]: value
//             }
//         }, () => this.handleRemainingAPIs())
//     }

//     handleRemainingAPIs = () => {
//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getPOVendor?orgid=${companyid[0]}`).then(res => {
//             const responseData = JSON.parse(res.res.data)
//             const vendorName = responseData.map(item => item["Vendor Name"])
//             const vendorID = responseData.map(item => item["Vendor ID"])

//             this.setState({
//                 ...this.state,
//                 dropdown_vendorname: vendorName,
//                 dropdown_vendorid: vendorID
//             })
//         })

//         getRequest(`api/edureka/getIP2?orgid=${companyid[0]}`).then(res => {
//             const responseData = JSON.parse(res.res.data)
//             const IPNumber = responseData.map(value => value['IP Number'])

//             this.setState({
//                 ...this.state,
//                 dropdown_ipnumber: IPNumber
//             })
//         })
//     }

//   render() {
//     if(this.state.redirect){
//         return(
//             <VR2_Forms
//             screenName = {"InternalPlan"}
//             screenAction = {"edit"}  
//             From_SearchJSON = {this.state.SearchJSON}    
//             onBack = {this.onBack}
//             />        
//         )
//     }
//     else
//     return (
//       <Fragment>
//         <div className='header_div'>Search Internal Plan</div>

//         <br/>

//         <Form style={{display:'flex', flexDirection:'column'}}>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
//                 <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleOrgChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2'style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
//                 <select className='col-lg-3' value={this.state.formData.vendorname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorname' onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendorname.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2'style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor ID:</div>
//                 {/* <input className="col-lg-3" value={this.state.formData.vendorid} type='text' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorid' onChange={this.handleChange}></input> */}
//                 <select className='col-lg-3' value={this.state.formData.vendorid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorid' onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendorid.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>IP Number:</div>
//                 <select className='col-lg-3' name='ipnumber' value={this.state.formData.ipnumber} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                 <option value='' disabled>Select</option>
//                     {this.state.dropdown_ipnumber.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Status:</div>
//                 <select className='col-lg-3' name='status' value={this.state.formData.status} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_status.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{textAlign:'center'}}>
//                 <Button variant='primary' onClick={this.handleSearch}>Search</Button>
//                 <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
//             </div>
//         </Form>

//         <br/>

//         {this.state.showtable ?
//         <div>
//             <table border='1' id='table_styling'>
//                 <thead>
//                     <tr>
//                         <th>IP Number</th>
//                         <th>Date</th>
//                         <th>Vendor ID</th>
//                         <th>Vendor Name</th>
//                         {/* <th>Organization ID</th> */}
//                         <th>Status</th>
//                         {/* <th>Total Value</th> */}
//                         {/* <th>Expected Delivery Date</th> */}
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {this.state.tableData.sort((a,b) =>a['IP Number'].localeCompare(b['IP Number'])).map((item, index) => {
//                         return(
//                             <tr>
//                                 <td><Button variant='link' onClick={()=>this.handleIP(item)}>{item['IP Number']}</Button></td>
//                                 <td>{item.Date}</td>
//                                 <td>{item['Vendor ID']}</td>
//                                 <td>{item['Vendor Name']}</td>
//                                 {/* <td>{item['Organization ID']}</td> */}
//                                 <td>{item.Status}</td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div> : null}

//         {this.state.error ?
//         <div className='center-screen'>
//             {this.state.errorMessage}
//         </div> :  null}
//       </Fragment>
//     )
//   }
// }

// export default InternalPlan_Dropdown



import React, { Component, Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select'; // Import React Select
import '../CI/COD.css';
import { getRequest } from '../../globalhelper/helper';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class InternalPlan_Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showtable: false,
      formData: {
        createddate: '',
        vendorname: '',
        vendorid: '',
        ipnumber: '',
        taxcode: '',
        periodfrom: '',
        periodto: '',
        status: '',
        orgname: ''
      },
      tableData: [],
      dropdown_orgname: [],
      dropdown_vendorid: [],
      dropdown_vendorname: [],
      dropdown_ipnumber: [],
      dropdown_status: [],
      companyDetails: [],
      error: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    localStorage.setItem('pageName', 'Search InternalPlan');

    getRequest(`api/edureka/getCompanyAssignedToUser`)
      .then((response) => {
        console.log(response.res.data);
        const responseData = response.res.data;
        const arr = responseData.map((item) => item.orgname);
        console.log(arr);
        this.setState({
          dropdown_orgname: arr,
          companyDetails: responseData
        });
      })
      .catch((err) => console.log(err));

    getRequest(`api/edureka/getPORemainingValues`).then((res) => {
      const responseData = res.res.data;

      this.setState({
        dropdown_status: responseData
      });
    });
  }

  handleChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : '';

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  };

  handleSearch = () => {
    const orgname = this.state.formData.orgname;

    if (orgname) {
      const ipnumber = this.state.formData.ipnumber;
      const vendorname = this.state.formData.vendorname;
      const vendorid = this.state.formData.vendorid;
      const status = this.state.formData.status;

      this.setState({
        error: false
      });

      const id = this.state.companyDetails.filter(
        (item) => item.orgname === this.state.formData.orgname
      );

      const companyid = id.map((item) => item.orgid);

      getRequest(
        `api/edureka/getIPData?ipnumber=${ipnumber}&vendorname=${vendorname}&status=${status}&vendorid=${vendorid}&orgid=${companyid[0]}`
      ).then((res) => {
        let temp = JSON.parse(res.res.data);
        console.log(temp);

        if (temp.length) {
          this.setState({
            tableData: temp,
            showtable: true
          });
        } else {
          this.setState({
            error: true,
            errorMessage: 'No data found. Please try some other combination'
          });
        }
      });
    } else {
      this.setState({
        error: true,
        errorMessage: 'Please select all the required fields'
      });
    }
  };

  handleReset = () => {
    this.setState({
      showtable: false,
      error: false,
      formData: {
        createddate: '',
        vendorname: '',
        vendorid: '',
        ipnumber: '',
        taxcode: '',
        periodfrom: '',
        periodto: '',
        status: '',
        orgname: ''
      }
    });
  };

  handleIP = (item) => {
    console.log(item);
    const planid = item['IP Number'];

    const id = this.state.companyDetails.filter(
      (item) => item.orgname === this.state.formData.orgname
    );

    const companyid = id.map((item) => item.orgid);

    getRequest(
      `api/edureka/getIPUpdatedJSON?planid=${planid}&orgid=${companyid[0]}`
    ).then((res) => {
      if (res.res.status === 'success') {
        console.log(res.res.data);

        this.setState({ redirect: true, SearchJSON: res.res.data });
      } else {
        console.log('No Data');
      }
    });
  };

  onBack = () => {
    this.setState({
      redirect: false,
      SearchJSON: []
    });
  };

  handleOrgChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';

    this.setState(
      {
        formData: {
          ...this.state.formData,
          orgname: value
        }
      },
      () => this.handleRemainingAPIs()
    );
  };

  handleRemainingAPIs = () => {
    const id = this.state.companyDetails.filter(
      (item) => item.orgname === this.state.formData.orgname
    );

    const companyid = id.map((item) => item.orgid);

    getRequest(`api/edureka/getPOVendor?orgid=${companyid[0]}`).then((res) => {
      const responseData = JSON.parse(res.res.data);
      const vendorName = responseData.map((item) => item['Vendor Name']);
      const vendorID = responseData.map((item) => item['Vendor ID']);

      this.setState({
        dropdown_vendorname: vendorName,
        dropdown_vendorid: vendorID
      });
    });

    getRequest(`api/edureka/getIP2?orgid=${companyid[0]}`).then((res) => {
      const responseData = JSON.parse(res.res.data);
      const IPNumber = responseData.map((value) => value['IP Number']);

      this.setState({
        dropdown_ipnumber: IPNumber
      });
    });
  };

  render() {
    const {
      dropdown_orgname,
      dropdown_vendorname,
      dropdown_vendorid,
      dropdown_ipnumber,
      dropdown_status,
      formData,
      showtable,
      tableData,
      error,
      errorMessage,
      redirect,
      SearchJSON
    } = this.state;

    const orgnameOptions = dropdown_orgname.map((item) => ({
      label: item,
      value: item
    }));

    const vendornameOptions = dropdown_vendorname.map((item) => ({
      label: item,
      value: item
    }));

    const vendoridOptions = dropdown_vendorid.map((item) => ({
      label: item,
      value: item
    }));

    const ipnumberOptions = dropdown_ipnumber.map((item) => ({
      label: item,
      value: item
    }));

    const statusOptions = dropdown_status.map((item) => ({
      label: item,
      value: item
    }));

    if (redirect) {
      return (
        <VR2_Forms
          screenName={'InternalPlan'}
          screenAction={'edit'}
          From_SearchJSON={SearchJSON}
          onBack={this.onBack}
        />
      );
    }

    return (
      <Fragment>
        <div className='header_div'>Search Internal Plan</div>

        <br />

        <Form style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <div
              className='col-lg-2'
              style={{
                marginRight: 15,
                fontFamily: 'sans-serif',
                fontWeight: 'bold'
              }}
            >
              Organization Name:<span style={{ color: 'red' }}>*</span>
            </div>
            <Select
              className='col-lg-3'
              name='orgname'
              value={orgnameOptions.find((option) => option.value === formData.orgname)}
              onChange={this.handleOrgChange}
              options={orgnameOptions}
              placeholder='Select'
            //   styles={{
            //     control: (provided) => ({
            //       ...provided,
            //       padding: '5px',
            //       borderRadius: '5px',
            //       border: '1px solid #ccc'
            //     })
            //   }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <div
              className='col-lg-2'
              style={{
                marginRight: 15,
                fontFamily: 'sans-serif',
                fontWeight: 'bold'
              }}
            >
              Vendor Name:
            </div>
            <Select
              className='col-lg-3'
              name='vendorname'
              value={vendornameOptions.find((option) => option.value === formData.vendorname)}
              onChange={this.handleChange}
              options={vendornameOptions}
              placeholder='Select'
            //   styles={{
            //     control: (provided) => ({
            //       ...provided,
            //       padding: '5px',
            //       borderRadius: '5px',
            //       border: '1px solid #ccc'
            //     })
            //   }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <div
              className='col-lg-2'
              style={{
                marginRight: 15,
                fontFamily: 'sans-serif',
                fontWeight: 'bold'
              }}
            >
              Vendor ID:
            </div>
            <Select
              className='col-lg-3'
              name='vendorid'
              value={vendoridOptions.find((option) => option.value === formData.vendorid)}
              onChange={this.handleChange}
              options={vendoridOptions}
              placeholder='Select'
            //   styles={{
            //     control: (provided) => ({
            //       ...provided,
            //       padding: '5px',
            //       borderRadius: '5px',
            //       border: '1px solid #ccc'
            //     })
            //   }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <div
              className='col-lg-2'
              style={{
                marginRight: 15,
                fontFamily: 'sans-serif',
                fontWeight: 'bold'
              }}
            >
              IP Number:
            </div>
            <Select
              className='col-lg-3'
              name='ipnumber'
              value={ipnumberOptions.find((option) => option.value === formData.ipnumber)}
              onChange={this.handleChange}
              options={ipnumberOptions}
              placeholder='Select'
            //   styles={{
            //     control: (provided) => ({
            //       ...provided,
            //       padding: '5px',
            //       borderRadius: '5px',
            //       border: '1px solid #ccc'
            //     })
            //   }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <div
              className='col-lg-2'
              style={{
                marginRight: 15,
                fontFamily: 'sans-serif',
                fontWeight: 'bold'
              }}
            >
              Status:
            </div>
            <Select
              className='col-lg-3'
              name='status'
              value={statusOptions.find((option) => option.value === formData.status)}
              onChange={this.handleChange}
              options={statusOptions}
              placeholder='Select'
            //   styles={{
            //     control: (provided) => ({
            //       ...provided,
            //       padding: '5px',
            //       borderRadius: '5px',
            //       border: '1px solid #ccc'
            //     })
            //   }}
            />
          </div>

          <div style={{ textAlign: 'center' }}>
            <Button variant='primary' onClick={this.handleSearch}>
              Search
            </Button>
            <Button style={{ marginLeft: 10 }} variant='danger' onClick={this.handleReset}>
              Reset
            </Button>
          </div>
        </Form>

        <br />

        {showtable ? (
          <div>
            <table border='1' id='table_styling'>
              <thead>
                <tr>
                  <th>IP Number</th>
                  <th>Date</th>
                  <th>Vendor ID</th>
                  <th>Vendor Name</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {tableData
                  .sort((a, b) => a['IP Number'].localeCompare(b['IP Number']))
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Button variant='link' onClick={() => this.handleIP(item)}>
                            {item['IP Number']}
                          </Button>
                        </td>
                        <td>{item.Date}</td>
                        <td>{item['Vendor ID']}</td>
                        <td>{item['Vendor Name']}</td>
                        <td>{item.Status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : null}

        {error ? <div className='center-screen'>{errorMessage}</div> : null}
      </Fragment>
    );
  }
}

export default InternalPlan_Dropdown;
