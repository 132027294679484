import React, { Component } from 'react';
import './COD.css';
import {getRequest, getHumanReadableDate, convertToINR, postRequest} from '../../globalhelper/helper';
import LARPopup from './LARPopup';
import CIPopup from './CIPopup';
import {Button, Form} from 'react-bootstrap';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ApiConstants from '../../config'
import download from 'downloadjs';
import axios from 'axios'
import { CSVLink } from 'react-csv';

export class RecoveryHistory extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        fetching: false,
        tableData: [],
        tableDisplay: [],
        dropdown_client: [],
        dropdown_model: [],
        dropdown_zone: [],
        dropdown_stationcode: [],
        unique_stationcode: [],
        show_maintable: false,
        show_filtertable: false,
        allData: [],
        downloadData: [],
        showpopup: false,
        deleteitem: [],
        formData: {
            client: '',
            model: '',
            zone: '',
            stationcode: ''
        }
      }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            fetching: true
        })

        getRequest('api/cod/lossAndRecoveryHistory').then(response => {
            console.log(response.res.data)
            let client = []
            let stationcode = []
            
            response.res.data.forEach(item => {
                client.push(item.client)
                stationcode.push(item.stationcode)
            })

            let temp = [... new Set(client)]
            let temp1 = [... new Set(stationcode)]

            this.setState({
                ...this.state,
                fetching:false,
                dropdown_client: temp,
                dropdown_stationcode: temp1,
                unique_stationcode: temp1,
                tableData: response.res.data === undefined ? 1 : response.res.data,
                show_maintable: true
            })
        })
    }

    downloadReceipt = (item) => {
        const fileName = item.attachment
        console.log(fileName)
        axios.post(ApiConstants.externals.serverUrl +  `api/tds/downloadRecovery`, {filename: fileName} ,
          { responseType: 'blob' }
        )
        .then(response => {
                console.log(response.data)
                const blob = response.data;
                download(blob, item.attachment);
        })
    }

    handleClientChange = (event) => {
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            show_maintable: false,
            formData: {
                ...this.state.formData,
                client: VALUE
            }
        })

        let arrModel = []

        this.state.tableData.forEach((item) => {
            if(item.client === VALUE){
                arrModel.push(item.businessmodel)
                console.log(arrModel)
                return arrModel
            }
        })

        let arr1 = [...new Set(arrModel)]
        this.setState({
            ...this.state,
            dropdown_model: arr1,
            formData: {
                ...this.state.formData,
                model: '',
                stationcode: '',
                zone: '',
                client: VALUE
            }
        }, () => this.handleClientFilter()) 
    }

    handleClientFilter = () => {
        let newArray = []

        this.state.tableData.forEach(item => {
            if(item.client === this.state.formData.client){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            tableDisplay: newArray.length >= 1 ? newArray : []
        })
    }

    handleModelChange = (event) => {
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        let arrZone = []

        this.state.tableData.forEach(each => {
            if(each.client === this.state.formData.client && each.businessmodel === VALUE){
                arrZone.push(each.zone)
                return arrZone
            }
        })
        let arr2 = [...new Set(arrZone)]
            this.setState({
                ...this.state,
                dropdown_zone: arr2,
                formData: {
                    ...this.state.formData,
                    stationcode: '',
                    zone: '',
                    model: VALUE
                    }
            }, () => this.handleModelFilter())       
    }

    handleModelFilter = () => {
        let newArray = []

        this.state.tableData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            tableDisplay: newArray.length >= 1 ? newArray : []
        })
    }

    handleZoneChange = (event) => {
        const VALUE = event.target.value;

        let stationcode = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                zone : VALUE
            }
        })

        this.state.tableData.forEach(item => {
            if(item.zone === VALUE && item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                stationcode.push(item.stationcode)
                return stationcode
            }
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formData: {
                ...this.state.formData,
                stationcode: '',                
                zone: VALUE
            }
        }, () => this.handleZoneFilter())
    }

    handleZoneFilter = () => {
        let newArray = []

        this.state.tableData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model && item.zone === this.state.formData.zone){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            tableDisplay: newArray.length >= 1 ? newArray : []
        })
    }

    handleStationChange = (event) => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                stationcode: event.target.value
            }
        }, () => this.handleStationFilter())
    }

    handleStationFilter = () => {
        let newArray = []

        this.state.tableData.forEach(item => {
            if(item.stationcode === this.state.formData.stationcode){
                newArray.push(item)
                console.log(newArray)
            }
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            tableDisplay: newArray.length >= 1 ? newArray : []
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            show_filtertable: false,
            show_maintable: true,
            dropdown_model: [],
            dropdown_zone: [],
            dropdown_stationcode: this.state.unique_stationcode,
            formData: {
                client: '',
                model: '',
                zone: '',
                stationcode: ''
            }
        })
    }

    handleDownload = async () => {
        await getRequest(`api/cod/lossAndRecoveryHistory`).then(res => {
            let tableData = res.res.data

            const filteredData = tableData.map(obj => {
                const {attachment, checksum, inprdfrom, inprdto, input, output, pk, sk, ...rest} = obj
                return rest
            })

            this.setState({
                ...this.state,
                downloadData: filteredData
            }, () => {
                this.downloadLink.link.click()
            })
        })
    }

    handleDelete = (item) => {

        this.setState({
            ...this.state,
            showpopup: true,
            deleteitem: item
        })
        // const pk = item.pk
        // const sk = item.sk

        // postRequest(`api/cod/deleteRecoveryHistoryRecords`, {pk: pk, sk: sk}).then(res => {
        //     console.log(res.res.data)

        //     setTimeout(() => {
        //         window.location.reload()
        //     }, 1000)
        // })
    }

    handleEdit = (item) => {
        console.log(item)
        this.setState({
            ...this.state,
            redirectScreen: true,
            valueJSON: item
        })
    }

  render() {

    if(this.state.redirectScreen){
        return (
            <LARPopup item={this.state.valueJSON}/>
        )
    }
    return (
      <div>
        <div className='header_div'>
            COD Loss & Recovery History
        </div>

        {!this.state.fetching ?
        <div>
        <div>
            <Button variant='danger' className='pull-right' onClick={this.handleReset}>Reset Filter</Button>
        </div> 

        
        <div>
            <Button variant='success' className='pull-right' onClick={this.handleDownload} disabled={this.state.tableData.length < 0}>Download</Button>
            <CSVLink data={this.state.downloadData} filename='Loss&Recovery.csv' ref={(r)=>this.downloadLink = r}></CSVLink>
        </div> 

        </div> : null}

        <br/>
        <br/>

        {!this.state.fetching ?

        <div className='row'>
            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Client</Form.Label>
                    <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_client.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Model</Form.Label>
                    <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_model.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Zone</Form.Label>
                    <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_zone.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Station Code</Form.Label>
                    <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_stationcode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>
        </div> : null }

        {this.state.fetching ? <div className='center'>
            <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
        </div>: null}

        {this.state.showpopup ? 
            <CIPopup handleClose={() => this.setState({...this.state, deleteitem: [], showpopup: false})} handleDeleteAfterConfirmation={() => {
                const pk = this.state.deleteitem.pk
                const sk = this.state.deleteitem.sk

                this.setState({
                    ...this.state,
                    showpopup: false
                })

                postRequest(`api/cod/deleteRecoveryHistoryRecords`, {pk: pk, sk: sk}).then(res => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                })
            }}/> : null}

        <br/>
        <br/>

        {this.state.show_maintable && this.state.tableData.length > 0 ?       
        <div className='offset-1'>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Business Model</th>
                        <th>Zone</th>
                        <th>Station Code</th>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Remarks</th>
                        <th>Proof Download</th>
                        <th>Posted By</th>
                        <th>Posted On</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.tableData.map((item, index) => {
                        return(
                            <tr key={index}>
                                <td>{item.client}</td>
                                <td>{item.businessmodel}</td>
                                <td>{item.zone}</td>
                                <td>{item.stationcode}</td>
                                <td>{item.sourcetype}</td>
                                <td>{convertToINR(item.amount)}</td>
                                <td>{item.remarks}</td>
                                <td><Button variant='link' onClick={() => this.downloadReceipt(item)} disabled={!item.attachment}><FileDownloadIcon/></Button></td>
                                <td>{item.postedby}</td>
                                <td>{getHumanReadableDate(item.posteddate)}</td>
                                <td><Button variant='primary' onClick={() => this.handleEdit(item)}>Edit</Button></td>
                                <td><Button variant='danger' onClick={() => this.handleDelete(item)}>Delete</Button></td>
                            </tr>
                        )
                    })}                        
                </tbody>                   
            </table>
        </div> : null}

        {this.state.show_filtertable && this.state.tableDisplay.length >0?       
        <div className='offset-1'>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Business Model</th>
                        <th>Zone</th>
                        <th>Station Code</th>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Remarks</th>
                        <th>Proof Download</th>
                        <th>Posted By</th>
                        <th>Posted On</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.tableDisplay.map((item, index) => {
                        return(
                            <tr key={index}>
                                <td>{item.client}</td>
                                <td>{item.businessmodel}</td>
                                <td>{item.zone}</td>
                                <td>{item.stationcode}</td>
                                <td>{item.sourcetype}</td>
                                <td>{convertToINR(item.amount)}</td>
                                <td>{item.remarks}</td>
                                <td><Button variant='link' onClick={() => this.downloadReceipt(item)} disabled={!item.attachment}><FileDownloadIcon/></Button></td>
                                <td>{item.postedby}</td>
                                <td>{getHumanReadableDate(item.posteddate)}</td>
                                <td><Button variant='primary' onClick={() => this.handleEdit(item)}>Edit</Button></td>
                                <td><Button variant='danger' onClick={() => this.handleDelete(item)}>Delete</Button></td>
                            </tr>
                        )
                    })}                        
                </tbody>                   
            </table>
        </div> : null}

        {this.state.tableDisplay.length <= 0 && this.state.show_filtertable ? <div className='center-screen'>
            <h5>No Data Found</h5>
        </div>: null}

        <br/>
        <br/>
      </div>
    )
  }
}

export default RecoveryHistory