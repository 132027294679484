import React, {
    PureComponent, Fragment
} from 'react';
import { connect } from 'react-redux'
import { postRequest } from '../../globalhelper/helper';
import { Modal, Button } from 'react-bootstrap';
import { changeValueJSON } from '../../actions/components';


class DisplaySectionContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showModal : false,
            saveObject : {},
            saveIndex : 0,
            openValuesModal : false,
            sampleValues : "{}"
        }
        this.userData = this.props.state.login.userData;
    }

    toggleModal = (component, index) => {
        this.setState({
            showModal : true,
            saveObject : component,
            saveIndex : index
        })
    }

    openValuesModal = () => {
        this.setState({
            openValuesModal : true
        })
    }

    handleChange= (ev) => {
        var name = ev.target.name;
        var value = name === "sampleVaues" ? JSON.stringify(ev.target.value) : ev.target.value;
        this.setState({
            ...this.state,
            [name] : value
        })
    }
    
    saveElement = () => {
        const BODY = {
            json  : this.props.elements,
            component : this.state.section_name
        }
        postRequest('api/visual/saveSection', BODY).then(res => {
            if(res.type === "success")
            {
                window.location.reload();
            }
        })

    }

    changeValues = (ev) => {
        var name = ev.target.name;
        var value = ev.target.value;
        // this.props
    }

    changeValueObj = (ev) => {
        this.props.changeValueJSON(ev.target.value);
    }

    render() {
        const { values } = this.props.state.components;
        return ( 
            <Fragment key={this.props.elements}>
                <div className="col-md-12" align="right">
                    <Button onClick={ this.openValuesModal }>Values</Button>
                </div>
                <br/>
                <div className="tree-container" style={{  minHeight : '100px', height : 'auto', whiteSpace : 'pre-line' }}>
                    <br/>
                    <table className="table-responsive">
                        <tbody>
                            <tr className="row">
                            { !this.state.openValuesModal && 
                            this.props.elements.map((component,index) => 
                                <Fragment key={index} >
                                    {
                                    component.json.type === "input" ?
                                        <>
                                            <td className="col-md-2" style={{ ...component.json.key_style, position : 'initial' }}>
                                                <label htmlFor={component.json.name} style={{ ...component.json.key_style, whiteSpace : 'pre-line' }}> {component.json.displayName} : </label> 
                                            </td>
                                            <td className="col-md-3" style={{ ...component.json.value_style, position : 'initial'}}>
                                                <input type={component.json.inputType} name={component.json.name} style={{ ...component.json.value_style}} onClick={() => this.props.onElementClick(component, index)} id={component.json.name} />
                                            </td>
                                        </>
                                    : 
                                    component.json.type === "dropdown" ?
                                        <>
                                            <td className="col" style={{ ...component.json.key_style, position : 'initial' }}>
                                                {/* <br/> */}
                                                <label htmlFor={component.json.name} style={{ ...component.json.key_style, whiteSpace : 'pre-line' }}> {component.json.displayName} :  </label> 
                                            </td>
                                            <td className="col" style={{ ...component.json.value_style, position : 'initial'}}>
                                                
                                            <select name={component.name} id={component.name} onClick={() => this.props.onElementClick(component, index)} style={component.json.value_style} >
                                                    <option value="test1">Sample Option 1</option>
                                                    <option value="test2">Sample Option 2</option>
                                                    <option value="test3">Sample Option 3</option>
                                                </select>
                                            </td>
                                        </>
                                    : 
                                    component.json.type === "image" ?
                                        <td style={component.json.style}>
                                            <img src={JSON.parse(values)[component.json.domain]} alt={component.json.alt} style={component.json.style} onClick={() => this.props.onElementClick(component, index)}></img>
                                        </td>
                                    : 
                                    component.json.type === "label" ?
                                        <td style={component.json.style}>
                                            <p style={component.json.style} onClick={() => this.props.onElementClick(component, index)}> { component.json[component.value_key] ? component.json[component.value_key] : "Test Value" } </p>
                                        </td>
                                    : 
                                    component.json.type === "button" ?
                                        <td style={component.json.style}>
                                            <button style={component.json.style} className="btn btn-teal" onClick={() => this.props.onElementClick(component, index)}> { JSON.parse(values)[component.json.domain] ? JSON.parse(values)[component.json.domain] : "Test Value" } </button>
                                        </td>
                                        
                                        : 
                                        component.json.type === "table" ?
                                            <td style={component.json.style}>
                                                <div className="scroll-table">
                                                <table border={1} >
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth : 200 }}>Name</th>
                                                            <th style={{ minWidth : 200 }}>Age</th>
                                                            <th style={{ minWidth : 200 }}>Phone</th>
                                                            <th style={{ minWidth : 200 }}>Email</th>
                                                            <th style={{ minWidth : 200 }}>Address</th>
                                                            <th style={{ minWidth : 200 }}>Organisation</th>
                                                            <th style={{ minWidth : 200 }}>Designation</th>
                                                            <th style={{ minWidth : 200 }}>Salary</th>
                                                            <th style={{ minWidth : 200 }}>DOB</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { values.table_data.map((itemArr, key) => 
                                                            <Fragment key={key}>
                                                                { itemArr.map((item, index) => 
                                                                    <tr key={index} style={{ border : '2px solid black' }}>
                                                                        { index === 0 && <td rowSpan={itemArr.length}> <input name={item.name} className="table-input" disabled id={item.name} value={item.name} /> </td> }
                                                                        <td> 
                                                                            <input name={item.age} className="table-input" onChange={this.changeValues} id={item.age} value={item.age} /> 
                                                                        </td>
                                                                        <td> <input name={item.phone} className="table-input" disabled id={item.phone} value={item.phone} /> </td>
                                                                        <td> <input name={item.email} className="table-input" disabled id={item.email} value={item.email} /> </td>
                                                                        <td> <input name={item.address} className="table-input" disabled id={item.address} value={item.address} /> </td>
                                                                        <td> <input name={item.organisation} className="table-input" disabled id={item.organisation} value={item.organisation} /> </td>
                                                                        <td> <input name={item.designation} className="table-input" disabled id={item.designation} value={item.designation} /> </td>
                                                                        <td> <input name={item.salary} className="table-input" disabled id={item.salary} value={item.salary} /> </td>
                                                                        <td> <input name={item.dob} className="table-input" disabled id={item.dob} value={item.dob} /> </td>
                                                                    </tr>
                                                                ) }
                                                            </Fragment>
                                                        ) }
                                                    </tbody>
                                                </table>
                                                </div>
                                            </td>
                                    : 
                                    component.json.type === "key-value" ?
                                        <>
                                            <td style={component.json.key_style}>
                                                    <p style={component.json.key_style} onClick={() => this.props.onElementClick(component, index)}> { (component.json[component.value_key] ? component.json[component.value_key] : "Test Value" ) + ":" } </p> 
                                            </td>
                                            <td style={component.json.value_style}>
                                                    <p style={component.json.value_style} onClick={() => this.props.onElementClick(component, index)}> { JSON.parse(values)[component.json.domain] } </p>
                                            </td>
                                        </>
                                    : 
                                    null}
                                </Fragment>
                            )}
                            </tr>
                            <div className="row">
                                <div className="col-md-12" align="right" style={{ marginTop : '24px' }}>
                                    <Button onClick={this.toggleModal} disabled={!this.props.elements.length} > Save </Button>
                                </div>
                            </div>
                    </tbody>
                </table>
                </div>
                
                <Modal
                    show={this.state.showModal}
                    onHide={() => {
                        this.setState({
                            ...this.state,
                            showModal : false
                        })
                    }}
                    dialogClassName="modal-90w"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-custom-modal-styling-title"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Save Element
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div  className="row">
                            <div className="col-md-12">
                                <label htmlFor="section_name"> Section Name </label>
                                <input className="form-control" type="text" id="section_name" name="section_name" placeholder="Input the Name for the Design" aria-label="Search" onChange={ (ev) => this.handleChange(ev) } /> <br/>
                            </div>
                        </div> 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-teal" disabled={ this.state.section_name === "" } onClick={this.saveElement}>Save</Button>
                    </Modal.Footer>
                    </Modal>


                <Modal
                    show={this.state.openValuesModal}
                    onHide={() => {
                        this.setState({
                            ...this.state,
                            openValuesModal : false
                        })
                    }}
                    dialogClassName="modal-90w"
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-custom-modal-styling-title"
                    >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Values
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div  className="row">
                            <div className="col-md-12">
                                <label htmlFor="sampleValues"> Values </label>
                                <textarea className="form-control" rows={15} type="text" id="sampleValues" value={values} name="sampleValues" placeholder="Input the sample value object" aria-label="Search" onChange={ (ev) => this.changeValueObj(ev) } /> <br/>
                            </div>
                        </div> 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-teal" onClick={() => {
                        this.setState({
                            ...this.state,
                            openValuesModal : false
                        })
                    }}>Save</Button>
                    </Modal.Footer>
                    </Modal>

           </Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
      state
    }
  };

const MapDispatchToProps = {
    changeValueJSON
}

  export default connect(
    mapStateToProps,
    MapDispatchToProps
)(DisplaySectionContainer);