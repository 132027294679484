import React , { Component } from 'react';
import TreeContainer from './treeContainer'
import DisplayContainer from './displayContainer'

class VisualRender extends Component {
    constructor(props){
        super(props);
        this.state = {
          data : [{
            tenant_id: "AB00003",
            type : 'designing',
            "Client": "AMZ",
            "ClientModel": "SL",
            "ClientStationCd": "AMDG",
            "CondText": "bike reject",
            "CondUOM": "Per Dly",
            "CondValue": 19,
            "ForPrdFrom": 1604188800000000000,
            "ForPrdTo": 1606694400000000000,
            "Graph_ID": "GR->Z0SM527B",
            "InPrdFrom": 1609372800000000000,
            "InPrdTo": 4133894400000000000,
            "InternalID": "RT->AB0AMDGAMZSL202011012020123112204489232BIKEREJECT",
            "MainErrClass": null,
            "PK": "TID#AB00003#TY#RT#CL#AMZ#CLM#SL",
            "SK": "FPF#",
            "StdLocation": null,
            "StdRevenueHead": null,
            "SubErrClass": null,
            "Type": "RT",
            "ValidityExpr": null,
            "Ver": "1"
          }]
        }
    }

    componentWillMount = () => {
      // if(localStorage.getItem("module").toLowerCase() === "fixedasset")
      // {
      //   this.setState({
      //     data : {
      //       external_id: "BANLR-FA0000015846",
      //       status: "Active",
      //       tenant_id: "AB00002",
      //       type: "designing"
      //     }
      //   })
      // }
    }

    render(){
        return(
            <div className="row" style={{ width : '103%' }}> 
            <div className="scroll-div col-md-4" style={{ height : 'unset', minHeight : "80vh" }}>
              <TreeContainer />
            </div>
            <div className="inspector  col-md-8">
              <DisplayContainer data={ this.state.data }/>
            </div>
          </div>
        )
    }
}

export default VisualRender;
