// import React, {useState, Fragment, useEffect} from 'react'
// import { getRequest } from '../../globalhelper/helper';
// import {Form, Button} from 'react-bootstrap'
// import NewDynamicTable from './NewDynamicTable';
// import {CSVLink} from 'react-csv'

// const DateInput = (props) => {
//     return (
//         <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom: 10}}>
//             <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize:'15', fontWeight: 'bold'}}>{props.title}</div>
//             <input className='col-lg-3' name={props.name} value={props.value} type='date' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={props.handleChange}></input>
//         </div>
//     )
// }

// const Input = ({label, type, name, value, onChange}) => {
//     return (
//         <div>
//             <Form.Group>
//                 <Form.Label>{label}</Form.Label>
//                 <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
//             </Form.Group>
//         </div>
//     )
// }

// const ExpenseAmortizationReport = () => {
//     const [companyName, setCompanyName] = useState([])
//     const [companyDetails, setCompanyDetails] = useState([])
//     const [tableData, setTableData] = useState([])
//     const [showError, setShowError] = useState(false)
//     const [loading, setLoading] = useState(false)
//     const [errorMessage, setErrorMessage] = useState("")

//     const [formData, setFormData] = useState({
//         company: '',
//         fromDate: '2023-04-01',
//         toDate: ''
//     })

//     useEffect(() => {
//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             const responseData = response.res.data
//             const orgname = responseData.map(item => item.orgname)
//             setCompanyDetails(responseData)
//             setCompanyName(orgname)
//         })
//     }, [])

//     const handleChange = (e) => {
//         const {name, value} = e.target
//         setFormData((prev) => {
//             return {...prev, [name]: value}
//         })
//     }

//     const handleReset = () => {
//         setFormData({
//             fromDate: '', toDate: '', company: ''
//         })
//         setTableData([])
//         setLoading(false)
//         setShowError(false)
//     }

//     const handleSubmit = () => {
//         const {fromDate, toDate, company} = formData

//         if(fromDate && toDate && company){
//             setLoading(true)
//             setShowError(false)

//             const id = companyDetails.filter(item => item.orgname === company)

//             const companyid = id.map(item => item.orgid)

//             getRequest(`api/edureka/getAmortizationStatusReport?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}`).then(response => {
//                 const responseData = JSON.parse(response.res.data)
//                 if(responseData.length){
//                     setTableData(responseData)
//                     setLoading(false)
//                 } else {
//                     setLoading(false)
//                     setShowError(true)
//                     setErrorMessage("No data found, please try some other combination")
//                 }               
//             }).catch(err => {
//                 setLoading(false)
//                 setShowError(true)
//                 setErrorMessage("Oops something went wrong!!")
//             })
//         } else {
//             setShowError(true)
//             setErrorMessage("Please select all the required fields")
//         }

//     }

//   return (
//     <Fragment>
//         <form onSubmit={handleSubmit}>
//             <div className='row'>
//                 <div className='col-md-3'>
//                     <Form.Group>
//                         <Form.Label>Company</Form.Label>
//                         <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
//                             <option value='' disabled>Select</option>
//                             {companyName.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
//                         </select>
//                     </Form.Group>
//                 </div>

//                 <div className='col-md-3'>
//                     <Input type="date" label="For Period From" name="fromDate" value={formData.fromDate} onChange={handleChange} />
//                 </div>

//                 <div className='col-md-3'>
//                     <Input type="date" label="For Period To" name="toDate" value={formData.toDate} onChange={handleChange} />
//                 </div>
//             </div>

//             <div style={{display:'flex', justifyContent:'center', marginBottom: '20px'}}>
//                 <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
//                 <Button size='sm' variant='danger' onClick={handleReset} style={{marginLeft:'10px'}}>Reset</Button>
//                 {tableData.length ?
//                 <CSVLink data={tableData} filename='Expense_Amortization_Status_Report.csv' style={{marginLeft:'10px'}}>Download</CSVLink> : null}
//             </div>
//         </form>

//         <br/>

//         {tableData.length ?
//         <NewDynamicTable data={tableData}></NewDynamicTable> : null}

//         {showError &&
//          <div className="center-screen">
//             {errorMessage}
//         </div>}

//         {loading ? <div className='center'>
//                 <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//                   <div class="wave"></div>
//         </div>: null}


//     </Fragment>
//   )
// }

// export default ExpenseAmortizationReport

import React, { useState, Fragment, useEffect } from 'react';
import { getRequest } from '../../globalhelper/helper';
import { Form, Button } from 'react-bootstrap';
import NewDynamicTable from './NewDynamicTable';
import { CSVLink } from 'react-csv';

const DateInput = (props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
            <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontSize: 15, fontWeight: 'bold' }}>{props.title}</div>
            <input className='col-lg-3' name={props.name} value={props.value} type='date' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={props.handleChange} min={props.min} max={props.max}></input>
        </div>
    );
};

const Input = ({ label, type, name, value, onChange, min, max }) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange} min={min} max={max} />
            </Form.Group>
        </div>
    );
};

const ExpenseAmortizationReport = () => {
    const [companyName, setCompanyName] = useState([]);
    const [companyDetails, setCompanyDetails] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [formData, setFormData] = useState({
        company: '',
        fromDate: '',
        toDate: ''
    });

    // Fiscal year end date state
    const [fiscalYearEnd, setFiscalYearEnd] = useState("");

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data;
            const orgname = responseData.map(item => item.orgname);
            setCompanyDetails(responseData);
            setCompanyName(orgname);
        });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "fromDate") {
            // Check if fromDate is April 1st
            const fromDate = new Date(value);
            if (fromDate.getMonth() === 3 && fromDate.getDate() === 1) {
                // Set fiscal year end to March 31st of the next year
                const fiscalEnd = new Date(fromDate.getFullYear() + 1, 2, 31);
                setFiscalYearEnd(fiscalEnd.toISOString().split("T")[0]);
            } else {
                setFiscalYearEnd("");
            }

            setFormData((prev) => ({
                ...prev,
                [name]: value,
                toDate: "" // Reset toDate when fromDate changes
            }));
        } else if (name === "toDate") {
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleReset = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            company: ''
        });
        setTableData([]);
        setLoading(false);
        setShowError(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { fromDate, toDate, company } = formData;

        if (fromDate && toDate && company) {
            setLoading(true);
            setShowError(false);

            const id = companyDetails.filter(item => item.orgname === company);
            const companyid = id.map(item => item.orgid);

            getRequest(`api/edureka/getAmortizationStatusReport?fromDate=${fromDate}&toDate=${toDate}&company=${companyid[0]}`).then(response => {
                const responseData = JSON.parse(response.res.data);
                if (responseData.length) {
                    setTableData(responseData);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setShowError(true);
                    setErrorMessage("No data found, please try some other combination");
                }
            }).catch(err => {
                setLoading(false);
                setShowError(true);
                setErrorMessage("Oops something went wrong!!");
            });
        } else {
            setShowError(true);
            setErrorMessage("Please select all the required fields");
        }
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-md-3'>
                        <Form.Group>
                            <Form.Label>Company</Form.Label>
                            <select className='spacing' value={formData.company} name='company' onChange={handleChange}>
                                <option value='' disabled>Select</option>
                                {companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </select>
                        </Form.Group>
                    </div>

                    <div className='col-md-3'>
                        <Input
                            type="date"
                            label="For Period From"
                            name="fromDate"
                            value={formData.fromDate}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='col-md-3'>
                        <Input
                            type="date"
                            label="For Period To"
                            name="toDate"
                            value={formData.toDate}
                            onChange={handleChange}
                            min={formData.fromDate || "1900-01-01"} // Default min date for `toDate`
                            max={fiscalYearEnd || "2099-12-31"} // Max date based on fiscal year calculation
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <Button size='sm' variant='success' type="submit">Submit</Button>
                    <Button size='sm' variant='danger' onClick={handleReset} style={{ marginLeft: '10px' }}>Reset</Button>
                    {tableData.length ?
                        <CSVLink data={tableData} filename='Expense_Amortization_Status_Report.csv' style={{ marginLeft: '10px' }}>Download</CSVLink> : null}
                </div>
            </form>

            <br />

            {tableData.length ?
                <NewDynamicTable data={tableData}></NewDynamicTable> : null}

            {showError &&
                <div className="center-screen">
                    {errorMessage}
                </div>}

            {loading ? <div className='center'>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
            </div> : null}
        </Fragment>
    );
};

export default ExpenseAmortizationReport;
