import React, { PureComponent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import {
  Redirect
} from "react-router-dom";
import { getRequest } from '../../globalhelper/helper';
import Iframe from 'react-iframe';

class ClientDataReconcileReports extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            embed_url : '',
            show_dashboard_button : false,
        };
        this.dashboard_id = this.props.match.params.dashboard_id;
    }

    componentWillReceiveProps(nextProps){
        if(this.dashboard_id !== nextProps.match.params.dashboard_id)
        {
            console.log('route changed', nextProps)
            this.dashboard_id = nextProps.match.params.dashboard_id;
            this.getDashboard();
        }
     }

     getDashboard = () => {
        getRequest('api/transactions/getDashboard?dashboard_id=' + this.dashboard_id).then(response => {
            if(response.res.status === "info")
            {
                this.setState({
                    show_dashboard_button : true,
                    embed_url : response.res.data.EmbedUrl
                })
            }
        })
     }
   
    componentDidMount = () => {
        this.getDashboard()
    }

    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div key={this.dashboard_id} >
            {!this.state.show_dashboard_button ?
               <h5> Please wait while preparing your report</h5>
               :
               <div>
                <a href={this.state.embed_url} target="_blank" rel="noreferrer" className="btn btn-primary" >Open Dashboard in New Tab</a>
                <br/>
                <Iframe url={ this.state.embed_url }
                        id="myId"
                        className="iFrame"
                        display="initial"
                        position="relative"/>
            </div>
            }
            </div>
        );
    }
}

export default ClientDataReconcileReports;
