import { Edit } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  gridColumnDefinitionsSelector,
  gridRowsLookupSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import globalhelper from "../../../helper/globalhelper";

export default function EditCostAllocation({
  availableCAMs,
  costAllocationMap,
  availableLOBs,
  onUpdate,
}) {
  const apiRef = useGridApiContext();
  const selectedRows = apiRef.current.getSelectedRows();
  const gridColumns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const rowsLookup = useGridSelector(apiRef, gridRowsLookupSelector);
  const [rowID, setRowID] = useState();
  const [costAllocation, setCostAllocation] = useState({});
  const [open, setOpen] = useState(false);
  const [isCustomCAM, setIsCustomCAM] = useState(false);
  const [minToDate, setMinToDate] = useState(null);
  const [expectedTotal, setExpectedTotalAmount] = useState(0);
  const [currentTotal, setCurrentTotalAmount] = useState(0);
  const [invalid, setInvalid] = useState(false);
  const [customLOBData, setCustomLOBData] = useState({});

  const getCAMLabel = (CAM) => {
    return `${CAM?.costallocationmethod}`.replaceAll(/\s+/g, "-").toUpperCase();
  };

  const LOBData = useMemo(() => {
    let currentTotal = 0;
    const updatedLOBData = {};
    let allocations = new Map();
    const periods = costAllocationMap.get(getCAMLabel(costAllocation));
    for (const period of periods?.keys() || []) {
      const range = period.split("|");
      const fromPrd = moment(range[0], "yyyy-MM-DD");
      const toPrd = range[1] ? moment(range[1], "yyyy-MM-DD") : moment();
      if (
        moment(costAllocation.forprdfrom, "yyyy-MM-DD").isBetween(
          fromPrd,
          toPrd
        )
      ) {
        allocations = periods.get(period);
        break;
      }
    }
    for (const LOB of availableLOBs) {
      const allocation = allocations.get(LOB);
      if (allocation) {
        const amount = parseFloat(
          (Number(costAllocation.ExpAmt) / 100) *
            Number(allocation.costallocationvalue)
        );
        currentTotal += amount;
        updatedLOBData[LOB] = amount.toFixed(2);
      } else {
        updatedLOBData[LOB] = parseFloat(0).toFixed(2);
      }
    }
    setCurrentTotalAmount(currentTotal);
    setInvalid(currentTotal.toFixed(2) !== expectedTotal.toFixed(2));
    return updatedLOBData;
  }, [expectedTotal, availableLOBs, costAllocationMap, costAllocation]);

  useEffect(() => {
    const rowID = Array.from(selectedRows.keys()).at(0);
    setRowID(rowID);
    const rowData = rowsLookup[rowID] || null;
    if (rowData) {
      if (rowData.forprdfrom) {
        setMinToDate(moment(rowData.forprdfrom, "yyyy-MM-DD"));
      }
      setExpectedTotalAmount(rowData.ExpAmt);
      setIsCustomCAM(rowData.costallocationmethod === "Custom");
    }
    setCostAllocation(rowData);
  }, [selectedRows, rowsLookup]);

  const handleEdit = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    let updatedRowData = {};
    if (isCustomCAM) {
      updatedRowData = { ...costAllocation, ...customLOBData };
    } else {
      updatedRowData = { ...costAllocation, ...LOBData };
    }
    apiRef.current.updateRows([{ id: rowID, ...updatedRowData }]);
    apiRef.current.publishEvent("rowEditStart", {
      id: rowID,
      row: updatedRowData,
      columns: gridColumns,
    });
    setTimeout(() => {
      apiRef.current.publishEvent("rowEditStop", {
        id: rowID,
        row: updatedRowData,
        columns: gridColumns,
      });
    });
    onUpdate({ ...costAllocation, ...updatedRowData });
  };

  const handleCancel = () => {
    apiRef.current.selectRow(rowID, false);
    setOpen(false);
  };

  const handleChange = (field, value, isLOB) => {
    if (isLOB) {
      setCustomLOBData((customLOBData) => {
        const updatedData = { ...customLOBData };
        updatedData[field] = parseFloat(value);
        let currentTotal = 0;
        for (const LOB in updatedData) {
          currentTotal += updatedData[LOB] || 0;
        }
        setCurrentTotalAmount(currentTotal);
        setInvalid(currentTotal.toFixed(2) !== expectedTotal.toFixed(2));
        return updatedData;
      });
      return;
    }
    if (field === "costallocationmethod") {
      setIsCustomCAM(value === "Custom");
    } else {
      customLOBData.current = {};
    }
    if (field === "forprdfrom") {
      setMinToDate(value);
    }
    setCostAllocation((rowData) => {
      let updatedRowData = { ...rowData };
      updatedRowData[field] = value;
      return updatedRowData;
    });
  };

  return (
    <>
      <Button
        size="small"
        startIcon={<Edit />}
        disabled={!Boolean(costAllocation && rowID)}
        onClick={handleEdit}
      >
        Edit
      </Button>
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle>Edit Cost Allocation</DialogTitle>
        <DialogContent dividers>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">
              Expected Amount: {globalhelper.currencyFormatter(expectedTotal)}
            </Typography>
            <Typography variant="h5">
              Current Total: {globalhelper.currencyFormatter(currentTotal)}
            </Typography>
          </Stack>
          {costAllocation && (
            <form>
              <Grid container spacing={2} sx={{ marginTop: 0.1 }}>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="For Period From"
                      value={costAllocation["forprdfrom"]}
                      onChange={(value) => handleChange("forprdfrom", value)}
                      inputFormat="yyyy-MM-DD"
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          label="For Period From"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="For Period To"
                      value={costAllocation["forprdto"]}
                      onChange={(value) => handleChange("forprdto", value)}
                      inputFormat="yyyy-MM-DD"
                      minDate={minToDate}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          label="For Period To"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    label="Cost Allocation Method"
                    value={costAllocation["costallocationmethod"]}
                    onChange={(event, value) =>
                      handleChange("costallocationmethod", value)
                    }
                    options={availableCAMs}
                    renderInput={(params) => (
                      <TextField label="Cost Allocation Method" {...params} />
                    )}
                  />
                </Grid>
                {availableLOBs.map((LOB) => (
                  <Grid item xs={4} key={LOB}>
                    <FormControl fullWidth>
                      {isCustomCAM ? (
                        <TextField
                          size="small"
                          type="number"
                          label={LOB}
                          error={invalid}
                          value={customLOBData?.[LOB] || ""}
                          onChange={(event) =>
                            handleChange(LOB, event.target.value, true)
                          }
                          fullWidth
                        />
                      ) : (
                        <TextField
                          size="small"
                          type="number"
                          InputProps={{ readOnly: true }}
                          label={LOB}
                          value={LOBData[LOB]}
                          fullWidth
                        />
                      )}
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </form>
          )}
          {invalid && (
            <Alert open={true} severity="error" sx={{ marginTop: 2 }}>
              Sum of all LOBs should equal&nbsp;
              {globalhelper.currencyFormatter(expectedTotal)}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={invalid}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
