import React, {
    Component, Fragment
} from 'react';
import { connect } from 'react-redux';
import { onChangeComponentProp, addComponent } from '../../actions/components'

const fontFamilyHolder = [ 'Times New Roman', "Arial", "Arial Black", "Arial Narrow", "Calibri", "Verdana", "Algerian" ]
const fontWeightHolder = [ 800,700,600,500,400,300,200,100 ]

class TreeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_component : true,
            componentTypes : [
                {
                    value : 'input',
                    name : 'Input'
                },
                {
                    value : 'dropdown',
                    name : 'Dropdown'
                },
                {
                    value : 'image',
                    name : 'Image'
                },
                {
                    value : 'label',
                    name : 'Label'
                },
                {
                    value : 'button',
                    name : 'Button'
                },
                {
                    value : 'key-value',
                    name : 'Key - Value'
                },
                {
                    name : 'Table',
                    value : 'table',
                },
            ]
        }
        this.userData = this.props.state.login.userData;
    }
    
    onPropertyChange = (ev, index) => {
        var components = this.props.state.components;
        var component = components.components[index];
        console.log(component)
        component[ev.target.name] = ev.target.value;
        this.props.onChangeComponentProp(components)
    }
    
    onStyleChange = (ev, index, type = null) => {
        var components = this.props.state.components;
        var component = components.components[index];
        if(type)
        {
            component[type + "_style"] = {
                ...component[type + "_style"],
                [ev.target.name] : ev.target.value
            }
            console.log(components);
            this.props.onChangeComponentProp(components);
        }
        else{
            component.style = {
                ...component.style,
                [ev.target.name] : ev.target.value
            };
            this.props.onChangeComponentProp(components);
        }
    }
    

        
    handleChange = (event) => {
        this.props.addComponent(event.target.value);
        
    }


    render() {
        return ( 
            <Fragment>
                <div className="tree-container">
                    <div className="row">
                        
                    <div className='col-md-6'>
                        Component
                        <div className="form-group">
                                <select className="form-control"  id="type" model={ this.props.state.components.component } value={ this.props.state.components.component } onChange={ (ev) => this.handleChange(ev) }>
                                    <option disabled>Component</option>
                                    { this.state.componentTypes.map(opt => 
                                        <option key={opt.value} value={opt.value}> {opt.name} </option> )
                                    }
                                </select>
                            </div>
                    </div>
                    </div>
                    { this.state.show_component &&
                    this.props.state.components.components.map((component,index) => 
                        component.type === "input" ?
                        <div key={index}>
                            {/* <label htmlFor="displayName">Display Name : </label>
                            <input name="displayName" id="displayName" value={component.displayName} onChange={(ev) => this.onPropertyChange(ev, index)} />
                            <br/> */}
                            <label htmlFor="inputType">Input Type : </label>
                            <input name="inputType" id="inputType" value={component.inputType} onChange={(ev) => this.onPropertyChange(ev, index)} />
                            <br/>
                            <label htmlFor="color">Color : </label>
                            <input name="color" id="color" value={component.style.color} onChange={(ev) => this.onStyleChange(ev, index)} />
                            <br/>
                            <label htmlFor="backgroundColor">Background Color : </label>
                            <input name="backgroundColor" id="backgroundColor" value={component.style.backgroundColor} onChange={(ev) => this.onStyleChange(ev, index)} />
                            <br/>
                            {/* <label htmlFor="fontSize">Font Size : </label>
                            <input name="fontSize" id="fontSize" value={component.style.fontSize} onChange={(ev) => this.onStyleChange(ev, index)} /> */}


                        </div>
                        : component.type === "select" ?
                        <div key={index}>
                            {/* <label htmlFor=""></label>
                            <select> </select> */}
                            <br/>
                        </div>
                        : component.type === "image" ?
                            <div key={index}>
                                <label htmlFor="src">Image Source : </label>
                                <input name="src" id="src" value={component.style.src} onChange={(ev) => this.onPropertyChange(ev, index)} />
                                <br/>
                            <br/>

                            </div>
                        : component.type === "date" ?
                        <div key={index}></div>
                        : component.type === "label" ?
                        <div key={index}>
                            <label htmlFor="color">Color : </label>
                                <input name="color" id="color" value={component.style.color} onChange={(ev) => this.onStyleChange(ev, index)} />
                            <br/>
                            <label htmlFor="backgroundColor">Background Color : </label>
                                <input name="backgroundColor" id="backgroundColor" value={component.style.backgroundColor} onChange={(ev) => this.onStyleChange(ev, index)} /> <br/>
                            <label htmlFor="fontSize">Font Size : </label>
                                <input name="fontSize" id="fontSize" value={component.style.fontSize} onChange={(ev) => this.onStyleChange(ev, index)} /> <br/>
                            <label htmlFor="fontFamily">Font Family : </label> 
                                {/* <input name="fontFamily" id="fontFamily" value={component.style.fontFamily} onChange={(ev) => this.onStyleChange(ev, index)} />  */}
                                <select className="form-control"  id="fontFamily" name="fontFamily" model={ component.fontFamily } value={ component.fontFamily } onChange={ (ev) => this.onStyleChange(ev, index) }>
                                    <option disabled>Font Family</option>
                                    { fontFamilyHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                            <label htmlFor="fontWeight">Font Weight : </label> 
                                <select className="form-control"  id="fontWeight" name="fontWeight" model={ component.fontWeight } value={ component.fontWeight } onChange={ (ev) => this.onStyleChange(ev, index) }>
                                    <option disabled>Font Weight</option>
                                    { fontWeightHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                        </div>
                        : component.type === "button" ?
                        <div key={index}>
                            <label htmlFor="color">Color : </label>
                                <input name="color" id="color" value={component.style.color} onChange={(ev) => this.onStyleChange(ev, index)} />
                            <br/>
                            <label htmlFor="borderColor">Border Color : </label>
                                <input name="borderColor" id="borderColor" value={component.style.color} onChange={(ev) => this.onStyleChange(ev, index)} />
                            <br/>
                            <label htmlFor="backgroundColor">Background Color : </label>
                                <input name="backgroundColor" id="backgroundColor" value={component.style.backgroundColor} onChange={(ev) => this.onStyleChange(ev, index)} /> <br/>
                            <label htmlFor="fontSize">Font Size : </label>
                                <input name="fontSize" id="fontSize" value={component.style.fontSize} onChange={(ev) => this.onStyleChange(ev, index)} /> <br/>
                            <label htmlFor="fontFamily">Font Family : </label> 
                                {/* <input name="fontFamily" id="fontFamily" value={component.style.fontFamily} onChange={(ev) => this.onStyleChange(ev, index)} />  */}
                                <select className="form-control"  id="fontFamily" name="fontFamily" model={ component.fontFamily } value={ component.fontFamily } onChange={ (ev) => this.onStyleChange(ev, index) }>
                                    <option disabled>Font Family</option>
                                    { fontFamilyHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                            <label htmlFor="fontWeight">Font Weight : </label> 
                                <select className="form-control"  id="fontWeight" name="fontWeight" model={ component.fontWeight } value={ component.fontWeight } onChange={ (ev) => this.onStyleChange(ev, index) }>
                                    <option disabled>Font Weight</option>
                                    { fontWeightHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                        </div>
                        : component.type === "key-value" ?
                        <div key={index}>
                            <b> Key Style</b> <br/>
                            <label htmlFor="color">Color : </label>
                                <input name="color" id="color" value={component.key_style.color} onChange={(ev) => this.onStyleChange(ev, index, "key")} />
                            <br/>
                            <label htmlFor="backgroundColor">Background Color : </label>
                                <input name="backgroundColor" id="backgroundColor" value={component.key_style.backgroundColor} onChange={(ev) => this.onStyleChange(ev, index, "key")} /> <br/>
                            <label htmlFor="fontSize">Font Size : </label>
                                <input name="fontSize" id="fontSize" value={component.key_style.fontSize} onChange={(ev) => this.onStyleChange(ev, index, "key")} /> <br/>
                            <label htmlFor="fontFamily">Font Family : </label> 
                                {/* <input name="fontFamily" id="fontFamily" value={component.key_style.fontFamily} onChange={(ev) => this.onStyleChange(ev, index, "key")} />  */}
                                <select className="form-control"  id="fontFamily" name="fontFamily" model={ component.fontFamily } value={ component.fontFamily } onChange={ (ev) => this.onStyleChange(ev, index, "key") }>
                                    <option disabled>Font Family</option>
                                    { fontFamilyHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                            <label htmlFor="fontWeight">Font Weight : </label> 
                                <select className="form-control"  id="fontWeight" name="fontWeight" model={ component.fontWeight } value={ component.fontWeight } onChange={ (ev) => this.onStyleChange(ev, index, "key") }>
                                    <option disabled>Font Weight</option>
                                    { fontWeightHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                                <br/>
                            <b> Value Style</b> <br/>
                            <label htmlFor="color">Color : </label>
                                <input name="color" id="color" value={component.value_style.color} onChange={(ev) => this.onStyleChange(ev, index, "value")} />
                            <br/>
                            <label htmlFor="backgroundColor">Background Color : </label>
                                <input name="backgroundColor" id="backgroundColor" value={component.value_style.backgroundColor} onChange={(ev) => this.onStyleChange(ev, index, "value")} /> <br/>
                            <label htmlFor="fontSize">Font Size : </label>
                                <input name="fontSize" id="fontSize" value={component.value_style.fontSize} onChange={(ev) => this.onStyleChange(ev, index, "value")} /> <br/>
                            <label htmlFor="fontFamily">Font Family : </label> 
                                {/* <input name="fontFamily" id="fontFamily" value={component.value_style.fontFamily} onChange={(ev) => this.onStyleChange(ev, index, "value")} />  */}
                                <select className="form-control"  id="fontFamily" name="fontFamily" model={ component.fontFamily } value={ component.fontFamily } onChange={ (ev) => this.onStyleChange(ev, index, "value") }>
                                    <option disabled>Font Family</option>
                                    { fontFamilyHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                            <label htmlFor="fontWeight">Font Weight : </label> 
                                <select className="form-control"  id="fontWeight" name="fontWeight" model={ component.fontWeight } value={ component.fontWeight } onChange={ (ev) => this.onStyleChange(ev, index, "value") }>
                                    <option disabled>Font Weight</option>
                                    { fontWeightHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                        </div>
                        : component.type === "table" ?
                        <div key={index}>
                            <b> Table Header Style</b> <br/>
                            <label htmlFor="color">Color : </label>
                                <input name="color" id="color" value={component.key_style.color} onChange={(ev) => this.onStyleChange(ev, index, "key")} />
                            <br/>
                            <label htmlFor="backgroundColor">Background Color : </label>
                                <input name="backgroundColor" id="backgroundColor" value={component.key_style.backgroundColor} onChange={(ev) => this.onStyleChange(ev, index, "key")} /> <br/>
                            <label htmlFor="fontSize">Font Size : </label>
                                <input name="fontSize" id="fontSize" value={component.key_style.fontSize} onChange={(ev) => this.onStyleChange(ev, index, "key")} /> <br/>
                            <label htmlFor="fontFamily">Font Family : </label> 
                                {/* <input name="fontFamily" id="fontFamily" value={component.key_style.fontFamily} onChange={(ev) => this.onStyleChange(ev, index, "key")} />  */}
                                <select className="form-control"  id="fontFamily" name="fontFamily" model={ component.fontFamily } value={ component.fontFamily } onChange={ (ev) => this.onStyleChange(ev, index, "key") }>
                                    <option disabled>Font Family</option>
                                    { fontFamilyHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                            <label htmlFor="fontWeight">Font Weight : </label> 
                                <select className="form-control"  id="fontWeight" name="fontWeight" model={ component.fontWeight } value={ component.fontWeight } onChange={ (ev) => this.onStyleChange(ev, index, "key") }>
                                    <option disabled>Font Weight</option>
                                    { fontWeightHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                                <br/>
                            <b> Table Data Style</b> <br/>
                            <label htmlFor="color">Color : </label>
                                <input name="color" id="color" value={component.value_style.color} onChange={(ev) => this.onStyleChange(ev, index, "value")} />
                            <br/>
                            <label htmlFor="backgroundColor">Background Color : </label>
                                <input name="backgroundColor" id="backgroundColor" value={component.value_style.backgroundColor} onChange={(ev) => this.onStyleChange(ev, index, "value")} /> <br/>
                            <label htmlFor="fontSize">Font Size : </label>
                                <input name="fontSize" id="fontSize" value={component.value_style.fontSize} onChange={(ev) => this.onStyleChange(ev, index, "value")} /> <br/>
                            <label htmlFor="fontFamily">Font Family : </label> 
                                {/* <input name="fontFamily" id="fontFamily" value={component.value_style.fontFamily} onChange={(ev) => this.onStyleChange(ev, index, "value")} />  */}
                                <select className="form-control"  id="fontFamily" name="fontFamily" model={ component.fontFamily } value={ component.fontFamily } onChange={ (ev) => this.onStyleChange(ev, index, "value") }>
                                    <option disabled>Font Family</option>
                                    { fontFamilyHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                            <label htmlFor="fontWeight">Font Weight : </label> 
                                <select className="form-control"  id="fontWeight" name="fontWeight" model={ component.fontWeight } value={ component.fontWeight } onChange={ (ev) => this.onStyleChange(ev, index, "value") }>
                                    <option disabled>Font Weight</option>
                                    { fontWeightHolder.map(opt => 
                                        <option key={opt} value={opt}> {opt} </option> )
                                    }
                                </select>
                                <br/>
                        </div>
                        : null
                    )}
                </div>
           </Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
      state
    }
  };



  export default connect(
    mapStateToProps,
    {
        onChangeComponentProp,
        addComponent
    }
)(TreeContainer);