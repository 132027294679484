import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sch_summary_details.css';
import ApiConstants from "../../../config";
import '../../../App.css';
import DatePicker from "react-datepicker";
import {
  Redirect
} from "react-router-dom";
import axios from 'axios'; 
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import GetAppIcon from '@material-ui/icons/GetApp';
// import { faDownload, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, Button } from 'react-bootstrap';
import { postRequest } from '../../../globalhelper/helper';
import { connect } from 'react-redux';
// import store from '../../../store';
// const state = store.getState();
// const userData = state.login.userData;

class SchSummaryDetails extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            alert : "",
            message : "",
            filename : "",
            tableData : [],
            type : 'period',
            tableHeader : [
                {
                    sort : true,
                    dataField : "LeaseExternalID",
                    text : "Lease ID"
                },
                {
                    sort : true,
                    dataField : "LeaseExecutionPlace",
                    text : "Lease Execution Place",
                },
                {
                    sort : true,
                    dataField : "LeaseExecutionDt",
                    text : "Lease Execution Date",
                    formatter : (rowContent, row) => {
                        return <p style={{ color : 'black', whiteSpace : 'nowrap' }}> { rowContent.split("T")[0] } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Period_From",
                    text : "Calculated From",
                    formatter : (rowContent, row) => {
                        return <p style={{ color : 'black', whiteSpace : 'nowrap' }}> { rowContent.split("T")[0] } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Period_To",
                    text : "Calculated To",
                    formatter : (rowContent, row) => {
                        return <p style={{ color : 'black', whiteSpace : 'nowrap' }}> { rowContent.split("T")[0] } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Period_in_Units",
                    text : "Calculated Units",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right",  color : 'black', whiteSpace : 'nowrap' }}> { rowContent } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "LE_CalculationMode",
                    text : "Calculation Mode",
                },
                {
                    sort : true,
                    dataField : "Liab_discP",
                    text : "Discounting Per Unit",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Liab_Opening_Bal",
                    text : "Lease Liab Opening Bal",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_Interest_Amt",
                    text : "Total Interest Amt",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_Repayments",
                    text : "Repayment",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_Liab_Modifier",
                    text : "Liability Modifications",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Liab_Closing_Bal",
                    text : "Lease Liab.Closing Bal",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "ROUAsset_Opening_Bal",
                    text : "ROU Asset Opening Bal",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_Depreciation",
                    text : "Total Depreciation",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_Asset_Modifier",
                    text : "ROU Asset Modifications",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "ROUAsset_Closing_Bal",
                    text : "ROU Asset Closing Bal",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_GainLoss",
                    text : "Total Gain Loss (Modification)",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_RetainedEarnings",
                    text : "Total Retained Earnings",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Total_PL_Charge",
                    text : "Total P&L Charge (IndAS116)",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { (row.Total_Interest_Amt - row.Total_Depreciation).toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "Sch_Line_AS17Charge",
                    text : "Total P&L Charge (IndAS17)",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { rowContent.toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "PL_Diff",
                    text : "P&L Diff",
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'right' };
                    },
                    formatter : (rowContent, row) => {
                        return <p style={{ textAlign : "right", minWidth : '120px',  color : 'black', whiteSpace : 'nowrap' }}> { ((row.Total_Interest_Amt - row.Total_Depreciation) - (row.Sch_Line_AS17Charge)).toFixed(2) } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "LE_Start_Dt",
                    text : "Contract Start Dt",
                    formatter : (rowContent, row) => {
                        return <p style={{ color : 'black', whiteSpace : 'nowrap' }}> { rowContent.split("T")[0] } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "LE_End_Dt",
                    text : "Contract End Dt",
                    formatter : (rowContent, row) => {
                        return <p style={{ color : 'black', whiteSpace : 'nowrap' }}> { rowContent.split("T")[0] } </p>
                    }
                },
                {
                    sort : true,
                    dataField : "LE_Approach",
                    text : "Approach",
                },
            ],
            showFile : false,
            redirect : null,
            formData : {
                fromdate : new Date("2020-01-01"),
                todate : new Date("2020-04-01")
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
   
    handleSubmit = () =>{
        postRequest( 'api/lease/getSchDetailsReportShow',{
            fromdate : this.state.formData.fromdate,
            todate : this.state.formData.todate,
            type : this.state.type,
            tenantId : this.props.state.login.userData.tenant_id
          })
        .then(response => {
            if(response.res.status === "success")
            {
                var newArray = response.res.data.map(d => d._id);
                this.setState({
                    ...this.state,
                    showFile : true,
                   tableData : newArray
                },() => {
                    console.log(this.state)
                })
            }
        });
    }
   
    downloadExcel = () =>{
        axios.post(ApiConstants.externals.serverUrl + 'api/reports/schSummaryDetailsReport',{
            fromdate : this.state.formData.fromdate,
            todate : this.state.formData.todate,
            type : this.state.type,
            tenantId : this.props.state.login.userData.tenant_id
          }, { responseType: 'arraybuffer' })
        .then(response => {
            var FileSaver = require('file-saver');
            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'Schedule Summary Details Report.xlsx');
        });
    }

    handleChange = (event) =>{

        var id = [event.target.id];
        this.setState({
            formData: {
                    ...this.state.formData,
                    [id]: event.target.value
            }
        },() => {
            console.log(this.state)
        })
    }

    
    
    setDate = (date,field) =>{

        this.setState({
        ...this.state,
        formData : {
            ...this.state.formData,
            [field] : date
        }
        },() => {
        console.log(this.state)
        })
    }

    
    toggleViewType = (type) =>{
        this.setState({
            ...this.state,
            showFile : false,
            type : type
        },() => {
            if(type === 'all')
            {
                this.handleSubmit();
            }
        })
    }



    render (){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div>
            <div className="row">
                <div className="col-md-12" align="center">
                <ButtonGroup aria-label="Basic example">
                    <Button variant={ this.state.type === 'period' ? 'primary' : "outline-primary"} onClick={() => this.toggleViewType('period')}>For Period</Button>
                    <Button variant={  this.state.type === 'all' ? 'success' : 'outline-success'}  onClick={() => this.toggleViewType('all')}>Full Contract</Button>
                </ButtonGroup>
                </div>
            </div>
            { this.state.type === 'period' ?
                <div className="row">
                    <div className='col-md-3'>
                        <label>From</label> <br/>
                            <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false}  maxDate={ this.state.formData.todate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'fromdate') } id="fromdate" />
                    </div>
                    

                    <div className='col-md-3'>
                        <label>To</label><br/>
                        <DatePicker selected={this.state.formData.todate} showPopperArrow={false}  minDate={ this.state.formData.fromdate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'todate') } id="todate" />
                    </div>
                    
                    <div className="col-md-3 text-center" style={{ marginTop : "25px" }}>
                        <button className="btn btn-teal" onClick={ this.handleSubmit }> Load </button>
                    </div>
                </div>
                : null }
                <br/>
                <br/>

                { this.state.showFile ?
                <div>
                    <div className="row">
                        <div className="col-md-12" align="right">
                            <button className="btn btn-info"  onClick={ this.downloadExcel }>
                                 <GetAppIcon /> </button>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div className="scroll-table" style={{ maxHeight : "500px" }}>
                        <BootstrapTable classes="BootstrapTable" key="table1"  keyField='_id.Period_From' pagination={ paginationFactory() } data={ this.state.tableData } columns={ this.state.tableHeader } />
                    </div>
                </div>
                : null }
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        state
    }
}


export default connect(
    mapStateToProps
)(SchSummaryDetails);


// export default SchSummaryDetails;
