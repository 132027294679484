import {
    LOGOUT,
    Initiate_Login,
    REQUESTCURRENTSTATE,
    SUCCESSLOGIN,
    SAVETOKEN,
    REQUEST_AUTH_KEY,
    SET_AUTH_KEY,
    SET_NEW_TOKEN
} from "../constants/action-types";
import {
    getRequest,
    postRequestWithProvidedHeader,
    postRequestWithoutAuth
} from '../globalhelper/helper'
import { navUrl } from "./common_actions";

export function onLogout() {
    return (dispatch) => {
        // dispatch(flushCommonObject());
        dispatch({
            type: LOGOUT
        })
    }
}

export function requestCurrentState() {
    return (dispatch) => {
        dispatch({
            type: REQUESTCURRENTSTATE
        })
    }
}

export function requestAuthKey() {
    return (dispatch) => {
        dispatch({
            type: REQUEST_AUTH_KEY
        })
    }
}

function successLogin(data) {
    return (dispatch) => {
        
        dispatch({
            type: SUCCESSLOGIN,
            payload: data,
            authkey: data.token
        // }, () => {
        //     navUrl(data.landing_page_url)
        })
    }
}

function saveToken(data) {
    return (dispatch) => {
        dispatch({
            type: SAVETOKEN,
            forgetPasswordToken: data
        })
    }
}

function setAuthKey(data) {
    return (dispatch) => {
        dispatch({
            type: SET_AUTH_KEY,
            authkey: data.token
        })
    }
}

export function onLogin(formData) {
    return (dispatch) => {
        console.log("On Login")
        // dispatch(flushCommonObject());
        dispatch(requestCurrentState());
        return login(formData).then((data) => {
            if (data.type === "success") {
                localStorage.setItem("go_to_landing_page" , "1");
                console.log(data.res)
                localStorage.setItem("organization", data.res.data.USR_Organization)
                dispatch(setAuthKey(data.res.data))
                setTimeout(() => {
                    dispatch(successLogin(data.res.data))
                    console.log(data.res.data.landing_page_url);
                    // dispatch(navUrl(data.res.data.landing_page_url))
                }, 2000)
            }
        })
    }
}

export function onChangePass(formData, header) {
    return (dispatch) => {
        dispatch(requestCurrentState());
        return changePass(formData, header).then((data) => {
            if (data.type === "success") {
                // dispatch(onSuccessChange(data.res.data))
            }
        })
    }
}

export function onRequestPassword(email) {
    return (dispatch) => {
        dispatch(requestCurrentState());
        return requestOtp(email).then((data) => {
            if (data.type === "success") {
                dispatch(saveToken(data.res.data))
            }
        })
    }
}

export function initiateLogin(initialState) {
    return (dispatch) => {
        dispatch({
            type: Initiate_Login,
            data: initialState
        })
    }
}

const login = async (formData) => {
    var d = postRequestWithoutAuth("api/user/login", formData)
    return d;
}

const requestOtp = async (email) => {
    var d = postRequestWithoutAuth("api/user/passwordReset", {
        email: email
    })
    return d;
}

const changePass = async (formData, header) => {
    var d = postRequestWithProvidedHeader("api/user/changePass", formData, header)
    return d;
}

export const SetNewToken = (new_token) => {
    return(dispatch) => {
        dispatch({
            type : SET_NEW_TOKEN,
            payload : new_token
        })
    }
}