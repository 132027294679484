import { FLUSH_CHAT_ITEMS, GET_MENU, SELECT_APP, SET_CHAT_ITEMS } from "./saTypes";

const initialState = {
  app: "",
  chatItems : []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_APP:
      return {
        ...state,
        app: action.payload,
      };
    case GET_MENU:
      return {
        ...state,
        menuTree: action.payload,
      };
    case SET_CHAT_ITEMS:
      return {
        ...state,
        chatItems: [action.payload],
      };
    case FLUSH_CHAT_ITEMS:
      return {
        ...state,
        chatItems: [],
      };
    default:
      return state;
  }
};

export default reducer;