import React, { Fragment, Component } from 'react';
import { checkValidDate, getRequest, getStandardDate, getYearFromDate, postRequest, groupArrayBy } from '../../globalhelper/helper';
import _ from 'lodash';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import GetAppIcon from '@material-ui/icons/GetApp';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {CSVLink} from "react-csv";
import { v4 as uuidv4 } from 'uuid'


const ASSESSMENTS_YEARS = [
    "All",
    "2019-2020",
    "2020-2021",
    "2021-2022",
    "2022-2023",
    "2023-2024",
]

const LEVELS = [
    {
        name : "Level 9 (Company)",
        value : "GET GROUP7",
        groupref : 'groupref7',
        label : "Company"
    },
    {
        name : "Level 8 (TAN)",
        value : "GET GROUP6",
        groupref : 'groupref6',
        label : "TAN"
    },
    {
        name : "Level 7 (Recon Status)",
        value : "GET GROUP1-recon_status",
        groupref : 'recon_status',
        label : "Recon Status"
    },
    {
        name : "Level 6 (Match Code)",
        value : "GET GROUP1-match_code",
        groupref : 'match_code',
        label : "Match Code"
    },
    {
        name : "Level 5 (Customer)",
        value : "GET GROUP5",
        groupref : 'groupref5',
        label : "Customer"
    },
    {
        name : "Level 4 (Fiscal Year)",
        value : "GET GROUP4",
        groupref : 'groupref4',
        label : "Fiscal Year"
    },
    {
        name : "Level 3 (Quarter)",
        value : "GET GROUP3",
        groupref : 'groupref3',
        label : "Quarter"
    },
    {
        name : "Level 2 (Month)",
        value : "GET GROUP2",
        groupref : 'groupref2',
        label : "Month"
    },
    {
        name : "Level 1 (Link Ref.)",
        value : "GET GROUP1",
        groupref : 'groupref1',
        label : "Link Ref"
    },
]

const SAMPLEHEADERS = []

const MONTHS = [
    // {
    //     name : "April",
    //     value : "04",
    // },
    {
        name : "May",
        value : "05",
    },
    {
        name : "June",
        value : "06",
    },
    {
        name : "July",
        value : "07",
    },
    {
        name : "August",
        value : "08",
    },
    {
        name : "September",
        value : "09",
    },
    {
        name : "October",
        value : "10",
    },
    {
        name : "November",
        value : "11",
    },
    {
        name : "December",
        value : "12",
    },
    {
        name : "January",
        value : "01",
    },
    {
        name : "February",
        value : "02",
    },
    {
        name : "March",
        value : "03",
    },
]

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  
//   formatter.format(2500); /* $2,500.00 */

class TDSRecon extends Component{
    constructor(props){
        super(props);
        this.state = {
            formData : {
                assessment_year : "2020-2021",
                month : "05",
                company : "",
                group_level : 'GET GROUP1',
                tan : '',
                customer_account : ''
            },
            aggregation : {
                quarter : '',
                tds_ledger_total : 0,
                f26as_tds_total : 0,
                difference : 0
            },
            show_details : false,
            showRecomModal : false,
            showDetailsTable : false,
            show_consolidated : false,
            recommendations : [],
            recommendatedItems : [],
            detailsArray : [],
            grouped : [],
            reconData : [],
            groupedByQuarter : [],
            data_to_download : [],
            details : [],
            companiesData : [],
            tanData : [],
            customersData : [],
            headers : [],
            mappedItems : [],
            detailsHeaders : [],
        }
    }

    getTANData = () => {
        this.hideDetails()
        this.setState({
            ...this.state,
            tanData : [],
            formData : {
                ...this.state.formData,
                tan : ''
            }
        }, () => {
            getRequest('api/tds/getDistinctTANForPeriod?assessment_year=' + this.state.formData.assessment_year + "&month=" + this.state.formData.month + "&company=" + this.state.formData.company).then(response => {
                if(response.type === "success")
                {
                    this.setState({
                        ...this.state,
                        tanData : JSON.parse(response.res.data)
                    }, () => {
                        if(this.state.tanData.length)
                        {
                            this.setState({
                                ...this.state,
                                formData : {
                                    ...this.state.formData,
                                    tan : this.state.tanData[0].value
                                }
                            }, () => { 
                                this.getCustomerData();
                            })
                        }
                    })
                } 
            })
        })
    }

    getCustomerData = () => {
        this.hideDetails()
        this.setState({
            ...this.state,
            customersData : [],
            formData : {
                ...this.state.formData,
                customer_account : ''
            }
        }, () => {
            getRequest('api/tds/getDistinctCustomerAccForTAN?assessment_year=' + this.state.formData.assessment_year + "&month=" + this.state.formData.month + "&company=" + this.state.formData.company + '&tan=' + this.state.formData.tan).then(response => {
                if(response.type === "success")
                {
                    this.setState({
                        ...this.state,
                        customersData : JSON.parse(response.res.data)
                    }, () => {
                        if(this.state.customersData.length)
                        {
                            this.setState({
                                ...this.state,
                                formData : {
                                    ...this.state.formData,
                                    customer_account : this.state.customersData[0].value
                                }
                            })
                        }
                    })
                } 
            })
        })
    }

    handleChange = (ev) => {
        let name = ev.target.name, value = ev.target.value;

        this.setState({ 
            ...this.state,
            formData : {
                ...this.state.formData,
                [name] : value
            }
        }, () => {
            this.hideDetails();
            if(name === "assessment_year" || name === "month")
            {
                this.getDistinctCompaniesForPeriod()
            }
            else if(name === "company")
            {
                this.getTANData();
            }
            else if(name === "tan")
            {
                this.getCustomerData();
            }
            if(name === "group_level")
            {
                this.hideDetails();
                this.fetchConsolidation(this.state.formData.group_level)
            }
        })
    }

    getDistinctCompaniesForPeriod = () => {
        this.hideDetails()
        this.setState({
            ...this.state,
            companiesData : [],
            formData : {
                ...this.state.formData,
                company : ''
            }
        }, () => {
            getRequest('api/tds/getDistinctCompaniesForPeriod?assessment_year=' + this.state.formData.assessment_year + "&month=" + this.state.formData.month).then(response => {
                if(response.type === "success")
                {
                    this.setState({
                        ...this.state,
                        companiesData : JSON.parse(response.res.data)
                    }, () => {
                        if(this.state.companiesData.length)
                        {
                            this.setState({
                                ...this.state,
                                formData : {
                                    ...this.state.formData,
                                    company : this.state.companiesData[0].value
                                }
                            }, () => {      
                                this.getTANData();
                            })
                        }
                    })
                } 
            })
        })
    }

    componentDidMount = () => {
        this.getDistinctCompaniesForPeriod();
    }

    fetchDetails = () => {
        this.hideDetails()
        const YEAR = this.state.formData.assessment_year;
        const COMPANY = this.state.formData.company;
        const MONTH = this.state.formData.month;
        getRequest('api/tds/getTDSDetailsForAssessmentYear?assessment_year=' + YEAR + "&company=" + COMPANY + "&month=" + MONTH).then(response => {
            if(response.type === "success")
            {
                var data = JSON.parse(response.res.data);
                var headers = Object.keys(data[0])
                this.setState({
                    ...this.state,
                    details : data,
                    show_details : true,
                    detailsHeaders : headers
                })
            }
        })
    }

    hideDetails = () => {
        this.setState({
            ...this.state,
            show_details : false,
            show_consolidated : false,
            grouped : [],
            aggregation : {}
        })
    }

    fetchConsolidation = () => {
        var instruction = this.state.formData.group_level;
        this.hideDetails()
        // this.fetchDetails();
        const YEAR = this.state.formData.assessment_year;
        const MONTH = this.state.formData.month;
        const COMPANY = this.state.formData.company;
        var groups = _.filter(LEVELS, (level) => level.value === instruction);
        var groupref = groups[0].groupref;
        this.setState({
            ...this.state,
            recommendations : []
        })
        // console.log(instruction);
        // getRequest('api/tds/getTDSAggregation?assessment_year=' + YEAR + "&month=" + MONTH + "&company=" + COMPANY).then(response => {
        // getRequest('api/tds/getInstructionRunnedItem?assessment_year=' + YEAR + "&month=" + MONTH + "&company=" + COMPANY).then(response => {
        getRequest('api/tds/getTDSRecon?instruction='+ instruction +'&assessment_year=' + YEAR + "&month=" + MONTH + "&company=" + COMPANY + "&groupref=" + groupref + "&tan=" + this.state.formData.tan + "&cusotmer_account=" + this.state.formData.customer_account).then(response => {
            if(response.type === "success")
            {
                var result = JSON.parse(response.res.data)
                var {mapping, mappedItems} = result;
                var data = _.sortBy(mapping, "s_no");
                // var groupedByQuarter = data;
                var reconData = _.filter(data, (item) => item["Recon Status"] === "REC")
                // var groupedByQuarter = _.filter(data, (item) => item["Recon Status"] !== "REC")
                // if(Object.keys(data[0]).includes("Quarter"))
                // {
                //     groupedByQuarter = groupArrayBy(groupedByQuarter, 'Quarter');
                // }
                // console.log(groupedByQuarter);
                // var grouped = data;
                // grouped = grouped.map((item, ind) => {
                //     delete item.groupref1;
                //     delete item.groupref2;
                //     delete item.groupref3;
                //     delete item.groupref4;
                //     delete item.groupref5;
                //     delete item.groupref6;
                //     delete item.groupref7;
                //     if(ind === grouped.length - 1)
                //     {
                        // console.log(grouped);
                        var headers = data.length ? Object.keys(data[0]) : SAMPLEHEADERS
                        this.setState({
                            ...this.state,
                            grouped : data,
                            mappedItems,
                            // groupedByQuarter,
                            reconData,
                            data_to_download : data,
                            show_consolidated : true,
                            show_details : true,
                            headers : headers
                })
                // var headers = Object.keys(data[0])
            }
        })
    }

    groupData = (header, value) => {
        console.log(header, value);
    }

    saveInstructionAndQuery = () => {
        var data = this.state.details;
        var selected = _.filter(data, (item) => item.selected);
        // console.log(selected);
        postRequest('api/tds/saveInstructionAndQuery', { selected : JSON.stringify(selected) }).then(response => {
            if(response.type === "success")
            {
                var data = JSON.parse(response.res.data);
                var headers = data.length ? Object.keys(data[0]) : SAMPLEHEADERS
                this.setState({
                    ...this.state,
                    grouped : data,
                    show_details : true,
                    headers : headers
                })
            }
        })
    }

    runRecommendation = () => {
        this.setState({
            ...this.state,
            recommendations : []
        }, () => {
            var aggregation = JSON.parse(JSON.stringify(this.state.aggregation));
            // var qNum = aggregation.quarter.substring(1) | 0;
            var sData = {
                company : this.state.formData.company,
                difference : Number(aggregation.f26as_tds_total) - Number(aggregation.tds_ledger_total),
                asksFor : Math.abs(aggregation.f26as_tds_total) > Math.abs(aggregation.tds_ledger_total) ? "TDSR" : "Form26AS",
                tan : this.state.formData.tan,
                customer_account : this.state.formData.customer_account,
                mappedItems : Math.abs(aggregation.f26as_tds_total) > Math.abs(aggregation.tds_ledger_total) ? JSON.stringify(this.state.mappedItems.tdsr) : JSON.stringify(this.state.mappedItems.form26as)
            };

            postRequest('api/tds/runRecommendation', sData).then(response => {
                if(response.type === "success")
                {
                    console.log(this.state.aggregation, "this.state.aggregation", response.res.data)
                    response.res.data[0].Source = this.state.aggregation.selected_ref;
                    this.setState({
                        ...this.state,
                        recommendations : response.res.data
                    })
                }
            })
        })
    }


    getRecommendation = () => {
        this.setState({
            ...this.state,
            recommendations : []
        }, () => {
            var aggregation = JSON.parse(JSON.stringify(this.state.aggregation));
            // var qNum = aggregation.quarter.substring(1) | 0;
            var sData = {
                recommendation_quarter : "Q4" ,
                company : this.state.formData.company,
                difference : Number(aggregation.f26as_tds_total) - Number(aggregation.tds_ledger_total),
                asksFor : Math.abs(aggregation.f26as_tds_total) > Math.abs(aggregation.tds_ledger_total) ? "TDSR" : "Form26AS",
                tan : this.state.formData.tan,
                customer_account : this.state.formData.customer_account,
                mappedItems : Math.abs(aggregation.f26as_tds_total) > Math.abs(aggregation.tds_ledger_total) ? JSON.stringify(this.state.mappedItems.tdsr) : JSON.stringify(this.state.mappedItems.form26as)
            };

            postRequest('api/tds/getRecommendation', sData).then(response => {
                if(response.type === "success")
                {
                    this.setState({
                        ...this.state,
                        recommendations : response.res.data
                    })
                }
            })
        })

        // var filtered = _.filter(this.state.grouped, (item) => item.selected);
        // var non_selected = _.filter(this.state.grouped, (item) => !item.selected);
        // var totalTDSValue = 0;
        // filtered.map((item, ind) => {
        //     totalTDSValue += item.difference
        //     if(ind === filtered.length - 1)
        //     {
        //         var groups = _.filter(LEVELS, (level) => level.value === this.state.formData.group_level);
        //         var groupref = groups[0].groupref;


        //         var differenceLevel = Math.abs(filtered[0]['TDS Ledger  [C]']) > Math.abs(filtered[0]['TDS (F26AS) [E]']) ? "Form26AS" : "TDSR";


        //         var filteredBySource = [];
        //         if(differenceLevel === "Form26AS")
        //         {
        //             filteredBySource = _.filter(non_selected, (item) => Number(item['TDS (F26AS) [E]']) != 0 );
        //         }
        //         else{
        //             filteredBySource = _.filter(non_selected, (item) => Number(item['TDS Derived (Rcpt) [C]']) != 0 );
        //         }
                
        //         var nonZeroItems = _.filter(filteredBySource, (item) => Number(item.difference) != 0 );

        //         console.log(nonZeroItems, differenceLevel);
        //         let txnIDS = nonZeroItems.map(a => a[groupref]);
        //         let txnVals = nonZeroItems.map(a => Math.abs(a['difference']));
        //         var sData = {
        //             value : totalTDSValue,
        //             txnIDS : JSON.stringify(txnIDS),
        //             txnVals : JSON.stringify(txnVals),
        //             level : this.state.formData.group_level,
        //             assessment_year : this.state.formData.assessment_year,
        //             month : this.state.formData.month
        //         }
        //         postRequest('api/tds/getRecommendation', sData).then(response => {
        //             if(response.type === "success")
        //             {
        //                 this.setState({
        //                     ...this.state,
        //                     recommendations : response.res.data
        //                 })
        //             }
        //         })
        //     }
        // })
    }

    getLableOutput = (value) => {
        if(typeof value === "number")
        {
        //   if(Number.isInteger(value))
        //   {
        //     return <p className="number-format"> { (Number(value)).toLocaleString()} </p>;
        //   }
        //   else{
            var d = (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(2)
            return <p className="number-format"> { formatter.format(d) } </p>
        //   }
        }
        else if(typeof value === "string")
        {
            if (checkValidDate(value) && getYearFromDate(getStandardDate(value)) <= 3000  && getYearFromDate(getStandardDate(value)) >= 1800)
          {
              return <p style={{ padding : 0, margin : 0, fontWeight : 'unset', paddingLeft : 3, textAlign : "center" }}> {value.split("T")[0] === "1800-01-01" || value.split("T")[0] === "2100-12-31" ? "" : getStandardDate(value)} </p>
          }
          else{
              return <p style={{ padding : 0, margin : 0, fontWeight : 'unset', paddingLeft : 3, textAlign : "left" }}> {value.split("_").join(" ")} </p>
          }
        }
        else if(typeof value === "date")
        {
            return <p style={{ padding : 0, margin : 0, fontWeight : 'unset', paddingLeft : 3, textAlign : "center" }}> {value.split("T")[0]} </p>
        }
    }

    getDetails = (itemsArray, type="Summary") => { 

        itemsArray.map((item, ind) => {
            item = item.replace(".0", '');
            if(ind === itemsArray.length - 1)
            {
                postRequest('api/tds/getItemsDetails', { items : JSON.stringify(itemsArray), type  }).then(response => {
                    if(response.type === "success")
                    {
                        var headers = JSON.parse(response.res.data).length ? Object.keys(JSON.parse(response.res.data)[0]) : []
                        this.setState({
                            ...this.state,
                            detailsArray : JSON.parse(response.res.data)
                        }, () => {
                            this.setState({
                                ...this.state,
                                showDetailsTable : true,
                                detailsHeaders : headers
                            })
                        })
                    }
                })
            }
        })
    }

    onSelectRecommendation = (itemInd) => {
        var detailedItems = this.state.recommendations[itemInd].Combinations;
        
        var summaryItems = this.state.recommendations[itemInd].Source;
        detailedItems = detailedItems.concat(summaryItems);
        console.log(detailedItems, summaryItems, "detailedItems")
        this.getDetails(detailedItems, "Recommendation")
        this.setState(prevState => {
            prevState.recommendations.map((item,ind) => {
                if(ind === itemInd)
                {
                    item.selected = true;
                }
                else{
                    item.selected = false;
                }
                if(ind === prevState.recommendations.length - 1)
                {
                    var prevDetails = JSON.parse(JSON.stringify(prevState.details));
                    var selectedItems = _.filter(prevState.grouped, (item) => item.selected);
                    // prevState.showRecomModal = true;
                    var recommendatedItems = [];
                    selectedItems.map((sItem, sInd) => {
                        console.log(sItem);
                        recommendatedItems = recommendatedItems.concat(_.filter(prevDetails, (item) => item["Link Ref"] === sItem["Link Ref"] && item["Source Object Name"].includes("TDS") && item["Source"] === "TDSR") );
                    })
                    // recommendatedItems.push(selectedItem);
                    var groups = _.filter(LEVELS, (level) => level.value === prevState.formData.group_level);
                    var groupref = groups[0].groupref === "groupref1" ? "Link Ref" : groups[0].groupref;
                    _.filter(prevState.recommendations, (item) => item.selected).map((recom, recomInd) => {
                        console.log(recom);
                        recom.Combinations.map((combs, combsInd) => {
                            recommendatedItems.push(_.filter(prevDetails, (item) => item["Txn Number"] === combs && item["Source Object Name"].includes("TDS"))[0])
                            if(recomInd === _.filter(prevState.recommendations, (item) => item.selected).length - 1 && combsInd === recom.Combinations.length - 1)
                            {
                                var ids = _.map(recommendatedItems, groupref);
                                var groupedRef = uuidv4();
                                recommendatedItems.map((lastItem, lastInd) => {
                                    if(lastItem)
                                    {
                                        lastItem[groupref] = groupedRef;
                                        if(lastInd === recommendatedItems.length - 1)
                                        {
                                            prevState.recommendatedItems = recommendatedItems;
                                            // prevState.recommendatedItems[0] = recommendatedItems.reduce(function(acc, x) {
                                            //     acc['TDS (Sales) [A]'] += x["TDS (Sales) [A]"]
                                            //     acc['Rcpt Amt [B]'] += x["Rcpt Amt [B]"]
                                            //     acc['TDS Derived (Rcpt) [C]'] += x["TDS Derived (Rcpt) [C]"]
                                            //     acc['TDS Ledger  [C]'] += x["TDS Ledger  [C]"]
                                            //     acc['Amt Credited (F26AS) [E]'] += x["Amt Credited (F26AS) [E]"]
                                            //     acc['TDS (F26AS) [E]'] += x["TDS (F26AS) [E]"]
                                            //     acc['TDS Diff[F]-[D]  (F26AS Vs TDSLed)'] += x["TDS Diff[F]-[D]  (F26AS Vs TDSLed)"]
                                            //     acc['TDS Diff[F]-[C]  (F26AS Vs TDSDerived)'] += x["TDS Diff[F]-[C]  (F26AS Vs TDSDerived)"]
                                            //     // } else {
                                            //     //   acc[x[groupref]] = x
                                            //     //   delete x[groupref]
                                            //     // }
                                            //     return acc
                                            // });
                                            console.log(prevState.recommendatedItems);
                                            // recommendatedItems.reduce(function(res, value) {
                                            //     if (!res[value.Id]) {
                                            //       res[value.Id] = { Id: value.Id, qty: 0 };
                                            //       prevState.recommendatedItems.push(res[value.Id])
                                            //     }
                                            //     res[value.Id].qty += value.qty;
                                            //     return res;
                                            //   }, {});

                                            return prevState;
                                        }
                                    }
                                })
                            }
                        })
                    })
                }
            })
        })
    }

    groupSelectedRecoms = () => {
        var selectedRecoms =  _.filter(this.state.recommendations, (item) => item.selected);

        console.log(selectedRecoms, this.state.formData.group_level);
        postRequest('api/tds/saveInstructionAndQuery', { selected : JSON.stringify(selectedRecoms), level : this.state.formData.group_level }).then(response => {
            if(response.type === "success")
            {
                // var data = JSON.parse(response.res.data);
                // var headers = data.length ? Object.keys(data[0]) : SAMPLEHEADERS
                // this.setState({
                //     ...this.state,
                //     grouped : data,
                //     show_details : true,
                //     headers : headers
                // })
            }
        })
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            showRecomModal : false
        })
    }

    onConfirmRecommendedItems = () => {
        this.hideDetails();
        console.log(this.state.recommendatedItems);
        var groups = _.filter(LEVELS, (level) => level.value === this.state.formData.group_level);
        var groupref = groups[0].groupref ;
        postRequest('api/tds/saveInstructionAndQuery',{ selected : JSON.stringify(this.state.recommendatedItems), formData : {...this.state.formData, groupref : groupref}, level : this.state.formData.group_level }).then(res => {
            if(res.type === "success")
            {
                this.fetchConsolidation();
            }
        })
    }

    groupSelectedItems = () => {
        var selectedRecoms =  _.filter(this.state.grouped, (item) => item.selected);

        var groups = _.filter(LEVELS, (level) => level.value === this.state.formData.group_level);
        var groupref = groups[0].groupref;

        postRequest('api/tds/saveInstructionAndQuery', { selected : JSON.stringify(selectedRecoms), formData : {...this.state.formData, grouref : groupref} }).then(response => {
            if(response.type === "success")
            {
                // var data = JSON.parse(response.res.data);
                // var headers = data.length ? Object.keys(data[0]) : SAMPLEHEADERS
                // this.setState({
                //     ...this.state,
                //     grouped : data,
                //     show_details : true,
                //     headers : headers
                // })
            }
        })
    }

    download = (event, type) => {
        this.setState({
            ...this.state,
            data_to_download : []
        }, () => {
            this.setState(prevState => {
                var grouped = prevState.grouped;

                grouped = grouped.map((item, ind) => {
                    delete item.groupref1;
                    delete item.groupref2;
                    delete item.groupref3;
                    delete item.groupref4;
                    delete item.groupref5;
                    delete item.groupref6;
                    delete item.groupref7;
                    if(ind === grouped.length - 1)
                    {
                        prevState.data_to_download = grouped;
                        return prevState;
                    }
                })

            }, () => {
                this.csvLink.link.click()
            })
        })
        // const currentRecords = type === "group" ? this.state.grouped : this.state.details;
        // var data_to_download = []
        // for (var index = 0; index < currentRecords.length; index++) {
        //    let record_to_download = {}
        //    for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
        //       record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
        //    }
        //    data_to_download.push(record_to_download)
        // }
        // this.setState({ dataToDownload: data_to_download }, () => {
           // click the CSVLink component to trigger the CSV download
        // })
      } 

    onGroupSelected = (ev,quarter) => {
        const CHECKED = ev.target.checked;
        console.log("Checked : ", CHECKED);
        this.setState(prevState => {
            var aggregation = {
                quarter,
                tds_ledger_total : 0,
                f26as_tds_total : 0,
            };
            aggregation.quarter = quarter;
            prevState.grouped.map((item, ind) => {
                if(item.Quarter === quarter)
                {
                    item.selected = CHECKED;
                }

                var lNum = item.Quarter.substring(1) | 0; // | 0 to cast to integer
                var rNum = quarter.substring(1) | 0; // | 0 to cast to integer
                console.log(lNum, rNum)
                if(lNum <= rNum)
                {
                    aggregation.tds_ledger_total += item["TDS Ledger  [C]"];
                    aggregation.f26as_tds_total += item["TDS (F26AS) [E]"];
                }
                if(ind === prevState.groupedByQuarter[quarter].length - 1)
                {
                    prevState.aggregation = aggregation;
                    return prevState;
                }
            })
        }, () => {
            this.forceUpdate()
        })
    }

    setAggregation = () => {
        var grouped = JSON.parse(JSON.stringify(this.state.grouped));

        var groups = _.filter(LEVELS, (level) => level.value === this.state.formData.group_level);
        var groupref = groups[0].label;

        var selected = _.filter(grouped, item => item.selected);
        var selected_ref = _.map(selected, item => item[groupref]).join(" , ");
        // this.getDetails(selected, "Summary")
        var aggregation = {
            tds_ledger_total : 0,
            f26as_tds_total : 0,
            difference : 0,
            selected_ref
        };
        selected.map((item, ind) => {
            aggregation.tds_ledger_total += item["TDS Ledger  [C]"];
            aggregation.f26as_tds_total += item["TDS (F26AS) [E]"];
            if(ind === selected.length - 1)
            {
                // aggregation.difference = Numberaggregation.f26as_tds_total - Numberaggregation.tds_ledger_total
                this.setState({
                    ...this.state,
                    aggregation : aggregation
                })
            }
        })

    }

    render(){
        return(
            <Fragment>
                {/* <div className="row">
                    <div className="col-md-2">
                        <label htmlFor="assessment_year">Assessment Year</label> <br />
                        <select name="assessment_year" id="assessment_year" style={{ width : "100%"  }} onChange={this.handleChange} value={this.state.formData.assessment_year} >
                            <option disabled > Please select an assessment year </option>
                            { ASSESSMENTS_YEARS.map((year, ind) =>
                                <option key={ind} value={year} > {year} </option>
                            ) }
                        </select>
                    </div>
                    { this.state.formData.assessment_year === "All" ? null :
                        <div className="col-md-2">
                            <label htmlFor="month">Till Month</label> <br />
                            <select name="month" id="month" style={{ width : "100%"  }} onChange={this.handleChange} value={this.state.formData.month} >
                                <option disabled > Please select a Till month </option>
                                { MONTHS.map((month, ind) =>
                                    <option key={ind} value={month.value} > {month.name} </option>
                                ) }
                            </select>
                        </div>
                    }
                    <div className="col-md-2">
                        <label htmlFor="company"> Company</label> <br />
                        <select name="company" id="company" style={{ width : "100%"  }} onChange={this.handleChange} value={this.state.formData.company} >
                            <option disabled > Please select a company </option>
                            { this.state.companiesData.map((company, ind) =>
                                <option key={ind} value={company.value} > {company.name} </option>
                            ) }
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="tan"> TAN</label> <br />
                        <select name="tan" id="tan" style={{ width : "100%"  }} onChange={this.handleChange} value={this.state.formData.tan} >
                            <option disabled > Please select a tan </option>
                            { this.state.tanData.map((tan, ind) =>
                                <option key={ind} value={tan.value} > {tan.name} </option>
                            ) }
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="customer_account"> Customer Account</label> <br />
                        <select name="customer_account" id="customer_account" style={{ width : "100%"  }} onChange={this.handleChange} value={this.state.formData.customer_account} >
                            <option disabled > Please select a customer </option>
                            { this.state.customersData.map((customer, ind) =>
                                <option key={ind} value={customer.value} > {customer.name} </option>
                            ) }
                        </select>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-primary" style={{ marginTop : 27 }} onClick={ this.fetchConsolidation } disabled={this.state.formData.company === "" || this.state.formData.tan === "" || this.state.formData.customer_account === ""} >Fetch Details</button>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                </div>
                <br />
                { this.state.show_details && this.state.show_consolidated ?
                    <Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <h4>Summary</h4>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="group_level">Group Level</label> 
                                <select name="group_level" id="group_level" style={{ width : "100%"  }} onChange={this.handleChange} value={this.state.formData.group_level} >
                                    <option disabled > Please select a Group Level </option>
                                    { LEVELS.map((level, ind) =>
                                        <option key={ind} value={level.value} > {level.name} </option>
                                    ) }
                                </select>
                            </div>
                            <div className="col-md-4">
                                { _.filter(this.state.grouped, (item) => item.selected).length ?
                                    <Fragment>
                                        {/* <div className="row">
                                            <div className="col-md-6"> Quarter : </div>
                                            <div className="col-md-6" align="right"> {this.state.aggregation.quarter} </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-6"> TDS Ledger [C] : </div>
                                            <div className="col-md-6" align="right" > { this.getLableOutput(this.state.aggregation.tds_ledger_total)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"> TDS (F26AS) [E] : </div>
                                            <div className="col-md-6" align="right" > { this.getLableOutput(this.state.aggregation.f26as_tds_total)} </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-6">TDS Diff[E]-[C]  (F26AS Vs TDSDerived) :</div>
                                            <div className="col-md-6" align="right" > { this.getLableOutput(Number(this.state.aggregation.f26as_tds_total) - Number(this.state.aggregation.tds_ledger_total))} </div>
                                        </div>
                                    </Fragment>
                                : null}
                            </div>
                            <div className="col-md-3" align="left">
                                    <Fragment>
                                        <button className="btn btn-success" onClick={this.getRecommendation} >Get Recommendation</button>
                                        <br /><br />
                                        { _.filter(this.state.grouped, (item) => item.selected).length ?
                                            <button className="btn btn-info" disabled={Number(Number(this.state.aggregation.f26as_tds_total) - Number(this.state.aggregation.tds_ledger_total)) == 0} onClick={this.runRecommendation} >Run Recommendation</button>
                                        :
                                        null}
                                    </Fragment>
                            </div>
                            <div className="col-md-2" align="right">
                                {/* <div> */}
                                    {/*  */}
                                {/* </div> */}
                                <CSVLink data={this.state.data_to_download} filename={this.state.formData.group_level + ".csv"} > 
                                    <button className="btn btn-info">
                                        <GetAppIcon />
                                    </button>
                                </CSVLink>
                                {/* <div>
                                    <CSVLink
                                        data={this.state.grouped}
                                        filename="data.csv"
                                        className="hidden"
                                        // ref={(r) => this.csvLink = r}
                                        target="_blank"/>

                                </div> */}
                                {/* <ReactHTMLTableToExcel
                                    id="test-table-xls-button "
                                    className="download-table-xls-button btn btn-info"
                                    table={"grouped"}
                                    filename={ this.state.formData.group_level }
                                    sheet={this.state.formData.group_level}
                                    buttonText={<GetAppIcon /> }/> */}
                            </div>
                            <br />
                            {/* <div className="col-md-12">
                                { this.state.recommendations.length > 0 &&
                                    <Fragment>
                                        <div className="row recommendation-holder">
                                            <div className="col-md-12">
                                                <b style={{ textDecoration : 'underline' }}>Recommendations by DataTwin</b>
                                            </div>
                                            { this.state.recommendations.map((item,itemInd) => 
                                                <div key={itemInd} onClick={() => this.onSelectRecommendation(itemInd)} className={ item.selected ?  "col-xl-2 recommendation recom-selected" :  "col-xl-2 recommendation"}>
                                                    <p style={{ wordWrap : 'break-word' }}>Combinations : { item.Combinations.join(",") }</p>
                                                    <p>Difference : { Number(item.Difference).toFixed(2) }</p>
                                                </div>
                                            ) }
                                        </div>
                                    </Fragment>
                                 }
                            </div> */}
                            {/* <br /> */}
                            <div className="col-md-12">
                                <div className="table-holder">
                                    <table id="grouped" className="tds-recon-table" ref={(r) => this.csvLink = r} width="100%" border="1">
                                        <thead>
                                            <tr>
                                                <th > Select </th>
                                                { this.state.headers.map((header, ind) =>
                                                    !header.includes("groupref") && !header.includes("difference") && !header.includes("Company")  && !header.includes("Month")  && !header.includes("Posting Date")  &&  !header.includes("s_no") &&
                                                    <th style={{ whiteSpace : header.includes('F26AS') ? 'auto' : 'nowrap', maxWidth : header.includes("Link Ref") ? 50 : 'auto' }} key={ind} > {header} </th>
                                                ) }
                                            </tr>
                                        </thead>
                                        {/* {this.state.headers.includes("Quarter") && !this.state.formData.group_level.includes('-')  ?
                                            <tbody>
                                                { this.state.reconData.map((item,itemInd) =>
                                                    <tr style={{ backgroundColor : "lightgreen" }} key={itemInd}>
                                                        <td></td>
                                                        { this.state.headers.map((header, ind) =>
                                                            !header.includes("groupref") && !header.includes("difference") && !header.includes("Company") &&
                                                            <td style={{ whiteSpace : 'nowrap' }} key={ind + "_" + itemInd} > 
                                                                { header === 'Link Ref' ? item[header] : this.getLableOutput(item[header])} 
                                                            </td>
                                                        ) }
                                                    </tr>
                                                )}
                                                {Object.keys(this.state.groupedByQuarter).map((quarter, i) => {
                                                return this.state.groupedByQuarter[quarter].map((item, index) =>
                                                    index === 0 ? (
                                                    <tr style={{ backgroundColor : item.selected ? 'yellow' : 'transparent' }} className={item.selected == "true" ? "selected" : "unselected"} key={i}>
                                                        
                                                        <td rowSpan={this.state.groupedByQuarter[quarter].length} key={i + "_" + index + "_0"}>
                                                            { quarter !== "Q4" &&
                                                                <input type="checkbox" checked={item.selected} name="selected" id="selected" onChange={(ev) => this.onGroupSelected(ev,quarter)}  /> 
                                                            }
                                                        </td>
                                                        
                                                        <td rowSpan={this.state.groupedByQuarter[quarter].length} key={i + "_" + index}>
                                                        {quarter}
                                                        </td>
                                                        { this.state.headers.map((header, ind) =>
                                                            !header.includes("groupref") && !header.includes("difference") && !header.includes("Company")  && 
                                                            <td style={{ whiteSpace : 'nowrap' }} key={ind + "_" + index} > 
                                                                { header === 'Link Ref' ? item[header] : this.getLableOutput(item[header])} 
                                                            </td>
                                                        ) }
                                                    </tr>
                                                    ) : (
                                                    <tr style={{ backgroundColor : item.selected ? 'yellow' : 'transparent' }} className={item.selected == "true" ? "selected" : "unselected"} key={i + "_" + index}>
                                                        { this.state.headers.map((header, ind) =>
                                                            !header.includes("groupref") && !header.includes("difference") && !header.includes("Company")  && 
                                                            <td style={{ whiteSpace : 'nowrap' }} key={ind + "_" + index} > 
                                                                { header === 'Link Ref' ? item[header] : this.getLableOutput(item[header])} 
                                                            </td>
                                                        ) }
                                                    </tr>
                                                    )
                                                )
                                                })}
                                            </tbody>
                                            : */}
                                         <tbody>
                                            { this.state.grouped.map((data, dataInd) => 
                                                <tr key={dataInd} style={{ backgroundColor : data.selected ? 'yellow' : data["Recon Status"] === "REC" ? "lightskyblue" : data["Recon Status"] === "PAR" ? "#3ded97" : data["Recon Status"] === "NAR" ? 'lightgrey' : data["Sales Register [A]"] != 0 && data["Rcpt Amt [B]"] != 0 ? "#99edc3"  : 'transparent' }} className={data.selected == "true" ? "selected" : "unselected"}>
                                                    <td key={dataInd + "-check"}> <input type="checkbox" checked={data.selected} disabled={Number(data['TDS Diff[F]-[D]  (F26AS Vs TDSLed)']) === 0 || data["Recon Status"] === "REC" || data["Recon Status"] === "NAR"} name="selected" id="selected" onChange={(ev) => {data.selected = ev.target.checked; this.setAggregation();}}  /> </td>
                                                    {/* <td key={dataInd + "-sno"} > { dataInd + 1 } </td> */}
                                                    { this.state.headers.map((header, ind) =>
                                                        !header.includes("groupref") && !header.includes("difference") && !header.includes("Company")  && !header.includes("Month")  && !header.includes("Posting Date")  && !header.includes("s_no") &&
                                                        <td style={{ whiteSpace : header.includes('F26AS') ? 'auto' : 'nowrap', maxWidth : header.includes("Link Ref") ? 150 : 'auto', overflow :'hidden' }} key={ind + "_" + dataInd} >
                                                            { header === 'Link Ref' ? data[header] : this.getLableOutput(data[header])} 
                                                        </td>
                                                    ) }
                                                </tr>
                                            ) }
                                        </tbody>
                                        {/* } */}
                                    </table>
                                </div>
                            </div>
                            <br />
                            <br />
                            
                            {/* <div className="col-md-12"> */}
                                { this.state.recommendations.length > 0 &&
                                    <Fragment>
                                        <div className="col-md-12">
                                            <br />
                                            <h4 style={{ textDecoration : 'underline' }}>Recommendations by DataTwin</h4>
                                        </div>
                                        
                                    <div className="col-md-12" align="right">
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button "
                                            className="download-table-xls-button btn btn-info"
                                            table={"recommendation-table"}
                                            filename={ "Recommendations by DataTwin" }
                                            sheet={"Recommendations by DataTwin"}
                                            buttonText={<GetAppIcon /> }/>
                                        <br />
                                        <br />

                                    </div>
                                        <table width="100%" id='recommendation-table' className="tds-recon-table" border={1}>
                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Source</th>
                                                    <th>Recommendation</th>
                                                    <th>Difference</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            { this.state.recommendations.map((recom, recomInd) => 
                                                <tr key={recomInd}>
                                                    <td> <input type="radio" onChange={() => this.onSelectRecommendation(recomInd)} /> </td>
                                                    <td> { this.getLableOutput(recom.Source) } </td>
                                                    <td>{ this.getLableOutput(recom.Combinations.join(" , ")) }</td>
                                                    <td>{ this.getLableOutput(Number(recom.Difference)) }</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </Fragment>
                                }
                            {/* </div> */}
                            { this.state.showDetailsTable ?
                                <Fragment>
                                    <div className="col-md-12">
                                        <br />
                                        <h4>Details</h4>
                                    </div>
                                    <div className="col-md-12" align="right">
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button "
                                            className="download-table-xls-button btn btn-info"
                                            table={"details"}
                                            filename={ "Details" }
                                            sheet={"Details"}
                                            buttonText={<GetAppIcon /> }/>
                                        <br />
                                        <br />

                                    </div>
                                    <div className="col-md-12">
                                        <div className="table-holder">
                                            <table id="details" className="tds-recon-table" width="100%" border="1">
                                                <thead>
                                                    <tr>
                                                        {/* <th > Select </th> */}
                                                        <th > S. No. </th>
                                                        { this.state.detailsHeaders.map((header, ind) =>
                                                            !header.includes("groupref") && !header.includes("difference")  &&!header.includes("Txn Header Text") &&!header.includes("Txn Entity") &&!header.includes("Std Object Name") &&!header.includes("Txn Type") &&!header.includes("Txn Item Text") &&!header.includes("Txn Status") &&!header.includes("_ref") &&
                                                            <th style={{ whiteSpace : 'nowrap' }} key={ind} > {header} </th>
                                                        ) }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { this.state.detailsArray.map((data, dataInd) => 
                                                        <tr key={dataInd} className={data.selected == "true" ? "selected" : "unselected"}>
                                                            {/* <td key={dataInd + "-check"}> <input type="checkbox" checked={data.selected} name="selected" id="selected" onChange={(ev) => data.selected = ev.target.checked}  /> </td> */}
                                                            <td key={dataInd + "-sno"} > { dataInd + 1 } </td>
                                                            { this.state.detailsHeaders.map((header, ind) =>
                                                                !header.includes("groupref") && !header.includes("difference")  &&!header.includes("Txn Header Text") &&!header.includes("Txn Entity") &&!header.includes("Std Object Name") &&!header.includes("Txn Type") &&!header.includes("Txn Item Text") &&!header.includes("Txn Status") &&!header.includes("_ref") &&
                                                                <td style={{ whiteSpace : 'nowrap' }} key={ind + "_" + dataInd} > 
                                                                    {/* <i style={{ cursor : "pointer", textAlign : "right", paddingRight : 10 }} onClick={() => this.groupData(header,data[header] )} className="fa fa-align-center icon-with-title"> <span>{"Group items where " + header + " is " + data[header]}</span> </i> */}
                                                                    { header === 'Txn Number' ? data[header] : this.getLableOutput(data[header])} 
                                                                </td>
                                                            ) }
                                                        </tr>
                                                    ) }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Fragment>
                            : null }
                        </div>
                    </Fragment>
                    :
                    null}

                <Modal
                    show={this.state.showRecomModal}
                    onHide={this.handleClose}
                    dialogClassName="modal-70w"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Recommendation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12" align="right">
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button "
                                    className="download-table-xls-button btn btn-info"
                                    table={"recommendations"}
                                    filename={ "Recommendations" }
                                    sheet={"Recommendations"}
                                    buttonText={<GetAppIcon /> }/>
                                <br />
                            </div>
                            <div className="col-md-12">
                                <div className="table-holder">
                                    <table id="recommendations" className="tds-recon-table" width="100%" border="1">
                                        <thead>
                                            <tr>
                                                <th > S. No. </th>
                                                { this.state.detailsHeaders.map((header, ind) =>
                                                    !header.includes("groupref") && !header.includes("difference") &&!header.includes("Txn Header Text") &&!header.includes("Txn Entity") &&!header.includes("Txn Header Ref") &&!header.includes("Std Object Name") &&!header.includes("Txn Type") &&!header.includes("Txn Item Text") &&!header.includes("Txn Status") &&!header.includes("_ref") &&
                                                    <th style={{ whiteSpace : header.includes('F26AS') ? 'auto' : 'nowrap' }} key={ind} > {header} </th>
                                                ) }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.recommendatedItems.map((data, dataInd) => 
                                                // style={{ backgroundColor : data.selected ? 'yellow' : 'transparent' }} className={data.selected == "true" ?      "selected" : "unselected"}
                                                // style={{ backgroundColor : data['type'] === "Source" ? "lightsalmon" : "lightcoral" }}
                                                <tr key={dataInd}>
                                                    <td key={dataInd + "-sno"} > { dataInd + 1 } </td>
                                                { this.state.detailsHeaders.map((header, ind) =>
                                                        !header.includes("groupref") && !header.includes("difference") &&!header.includes("Txn Header Text") &&!header.includes("Txn Entity") &&!header.includes("Txn Header Ref") &&!header.includes("Std Object Name") &&!header.includes("Txn Type") &&!header.includes("Txn Item Text") &&!header.includes("Txn Status") &&!header.includes("_ref") &&
                                                        <td style={{ whiteSpace : 'nowrap' }} key={ind + "_" + dataInd} > 
                                                            {/* <i style={{ cursor : "pointer", textAlign : "right", paddingRight : 10 }} onClick={() => this.groupData(header,data[header] )} className="fa fa-align-center icon-with-title"> <span>{"Group items where " + header + " is " + data[header]}</span> </i> */}
                                                            { header === 'Link Ref' ? data[header] : this.getLableOutput(data[header])} 
                                                        </td>
                                                    ) }
                                                </tr>
                                            ) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.onConfirmRecommendedItems}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }

}

export default TDSRecon;