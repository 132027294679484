import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { getHumanReadableDate, getRequest, getStandardDate, postRequest } from '../../globalhelper/helper';
import globalhelper from '../../helper/globalhelper';
import '../CI/COD.css';
import { Table, TableHead, TableBody, TableRow, TableCell,Checkbox } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
const Input = ({ label, type, name, value, onChange }) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <input
          className="spacing"
          type={type}
          name={name}
          value={value}
          onChange={onChange}></input>
      </Form.Group>
    </div>
  );
};

const Select = ({ label, name, value, options, onChange }) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <select
          className="spacing"
          name={name}
          value={value}
          onChange={onChange}>
          <option
            value=""
            disabled>
            Select
          </option>
          {options.sort().map((item, index) => (
            <option
              key={index}
              value={item}>
              {item}
            </option>
          ))}
        </select>
      </Form.Group>
    </div>
  );
};

const SelectMonth = ({ label, name, value, options, onChange }) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <select
          className="spacing"
          name={name}
          value={value}
          onChange={onChange}>
          <option
            value=""
            disabled>
            Select
          </option>
          {options.map((item, index) => (
            <option
              key={index}
              value={item}>
              {item}
            </option>
          ))}
        </select>
      </Form.Group>
    </div>
  );
};

const SelectType = ({ label, name, value, onChange }) => {
  return (
    <div>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <select
          className="spacing"
          name={name}
          value={value}
          onChange={onChange}>
          <option
            value=""
            disabled>
            Select
          </option>
          <option value="CreditCard">Credit Card</option>
          <option value="Journal">Journal</option>
          <option value="DigitalWalletAccounting">Digital Wallet Accounting</option>
          <option value="EmployeeCost">Employee Cost</option>
          <option value="EmployeeCostAccrued">Employee Cost Accrued</option>
          <option value="EmployeeCostFnF">Employee Cost FnF</option>
          <option value="EmployeeCostAccruedReversal">Employee Cost Accrued Reversal</option>
          <option value="EmployeeReimbursement">Employee Reimbursement</option>
          <option value="InstructorAccrualReversal">Instructor Accrual Reversal</option>
          <option value="InstructorCostAccrual">Instructor Cost Accrual</option>
          <option value="InstructorInvoice">Instructor Invoice</option>
          <option value="Master">Master List (Bill)</option>
          <option value="Payment">Payment</option>
          <option value="PettyCash">Petty Cash</option>
          <option value="PeriodEndProcess">Period End Process</option>
          <option value="Transaction">Transaction List (Bill)</option>
          <option value='Vendor'>Vendor</option>
        </select>
      </Form.Group>
    </div>
  );
};

const TallyInputUserSelect = () => {
  const [queryResult, setQueryResult] = useState([]);

  const [companyDetails, setCompanyDetails] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const [masterLedgerInfo, setMasterLedgerInfo] = useState([]);

  const [permanentData, setPermanentData] = useState([]);

  const [formData, setFormData] = useState({
    transtype: '',
    fromDate: '',
    toDate: '',
    companyName: ''
  });

  const [month, setMonth] = useState('');

  const [year, setYear] = useState('');

  const [monthInInt, setMonthInInt] = useState('');

  const [showPopup, setShowpopup] = useState(false);

  const [filterTable, setFilterTable] = useState('');

  const [dropDown, setDropDown] = useState({
    parentName: [],
    primaryGroup: []
  });

  const [showError, setShowError] = useState(false);

  const [warningMessage, setWarningMessage] = useState('');

  const [companies, setCompanies] = useState([]);

  const [dropdown_month, setDropdown_month] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]);

  const [dropdown_year, setDropdown_year] = useState(['2023', '2024' ,'2025']);

  const [companyCode, setCompanyCode] = useState('');
  const [vendorDetails,setVendorDetails] =useState([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    if (companyCode !== '') {
      getRequest(`api/edureka/getLedgerNameForTally?companycode=${companyCode} `).then((response) => {
        const data = JSON.parse(response.res.data);
        const tempParentName = data.filter((item) => item !== undefined).map((item) => item['Parent Name']);
        const tempPrimaryGroup = data.filter((item) => item !== undefined).map((item) => item['Primary Group']);
        setDropDown({
          parentName: [...new Set(tempParentName)],
          primaryGroup: [...new Set(tempPrimaryGroup)]
        });
      });
    }
  }, [companyCode]);

  useEffect(() => {
    getRequest(`api/edureka/getCompanyAssignedToUser`)
      .then((response) => {
        const responseData = response.res.data;
        const orgname = responseData.map((item) => item.orgname);
        setCompanyDetails(responseData);
        setCompanies(orgname);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (formData.companyName !== '') {
      const id = companyDetails.find((item) => item.orgname === formData.companyName);
      const companyid = id.orgid;
      setCompanyCode(companyid);
    }
  }, [formData.companyName]);

  const handleChange = () => {
    const { fromDate, toDate, transtype, companyName } = formData;
    if (
      fromDate &&
      toDate &&
      transtype !== 'Master' &&
      transtype !== 'Journal' &&
      transtype !== 'Transaction' &&
      transtype !== 'CreditCard' &&
      transtype !== 'PettyCash' &&
      transtype !== 'InstructorInvoice' &&
      transtype !== 'InstructorCostAccrual' &&
      transtype !== 'InstructorAccrualReversal' &&
      transtype !== 'PeriodEndProcess' &&
      transtype !== 'DigitalWalletAccounting' &&
      transtype !== 'Payment' &&
      transtype !== 'EmployeeReimbursement' &&
      transtype !== 'EmployeeCost' &&
      transtype !== 'EmployeeCostAccrued' &&
      transtype !== 'EmployeeCostFnF' &&
      transtype !== 'Vendor'&&
      companyName &&
      transtype
    ) {
      setShowError(false);
      setWarningMessage('');
      setSelectedItems([]);
      setSelectedRowData([]);
      setSelectAll(false);
      setShowSpinner(false);
      setLoading(true);
      setMasterLedgerInfo([]);

      getRequest(`api/vr2/getBookClosureRecord?Company=${companyCode}&Year=${year}&Month=${monthInInt}`).then(
        (resp) => {
          if (resp.res.status === 'success') {
            let data = resp.res.data;
            if (data.Bill === 'close' && formData.transtype === 'Transaction') {
              setLoading(false);
              setShowpopup(true);
            } else {
              Promise.all([
                getRequest(
                  `api/edureka/getTallyDataForOtherReports?startDate=${formData.fromDate}&endDate=${formData.toDate}&type=${formData.transtype}&companycode=${companyCode}`
                ),
                getRequest(`api/edureka/getDynamoLogsForTally`)
              ])
                .then(([tallyResponse, statusResponse]) => {
                  const tallyRes = JSON.parse(tallyResponse.res.data);
                  if (!tallyRes.length) {
                    setShowError(true);
                    setWarningMessage('No data to display..Please try some other combination');
                  }
                  const statusRes = statusResponse.res.data;
                  const res = tallyRes
                    .filter((item) => item['Ledger Name'] !== undefined)
                    .map((item, index) => {
                      return item;
                    });

                  const combinedArray = tallyRes.map((item) => {
                    if (statusRes) {
                      // const matched = statusRes.find(val => item["Accounting Journal"] === val.ajlist)

                      const matched = statusRes.find((val) => val.SK === item['Accounting Journal']);

                      if (matched) {
                        return { ...item, ...matched };
                      } else {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  });
                  setQueryResult(combinedArray);
                  setPermanentData(combinedArray);
                  setLoading(false);
                })
                .catch((err) => console.log(err));
            }
          }
        }
      );
    } else if (fromDate && toDate && transtype && companyName) {
      setShowError(false);
      setWarningMessage('');
      setSelectedItems([]);
      setSelectedRowData([]);
      setSelectAll(false);
      setShowSpinner(false);
      setLoading(true);
      setMasterLedgerInfo([]);
      setVendorDetails([])
      if (fromDate && toDate && transtype === "Vendor" && companyName) {
        setQueryResult([])
        setVendorDetails([])
        getRequest(`api/edureka/getVendorDetails?startDate=${formData.fromDate}&endDate=${formData.toDate}&type=${formData.transtype}&companycode=${companyCode}`)
            .then((response) => {
                console.log(response.res.data);
               if(response.res.data.length < 1){                        
                setShowError(true)
                setWarningMessage("No data to display... Please try some other combination")
                setLoading(false)
               }else{
                setLoading(false)
                setVendorDetails(response.res.data);                       
            }
            });
        return; 
    }
      getRequest(`api/vr2/getBookClosureRecord?Company=${companyCode}&Year=${year}&Month=${monthInInt}`)
        .then((resp) => {
          if (resp.res.status === 'success') {
            let data = resp.res.data;
            if (data.Bill === 'close' && formData.transtype === 'Transaction') {
              setLoading(false);
              setShowpopup(true);
            } else {
              Promise.all([
                getRequest(
                  `api/edureka/getTallyDataFENew?startDate=${formData.fromDate}&endDate=${formData.toDate}&type=${formData.transtype}&companycode=${companyCode}`
                ),
                getRequest(`api/edureka/getDynamoLogsForTally`)
              ])
                .then(([tallyResponse, statusResponse]) => {
                  const tallyRes = JSON.parse(tallyResponse.res.data);
                  if (!tallyRes.length) {
                    setShowError(true);
                    setWarningMessage('No data to display..Please try some other combination');
                  }
                  const statusRes = statusResponse.res.data;

                  if (formData.transtype === 'Master') {
                    const res = tallyRes
                      .filter((item) => item['Ledger Name'] !== undefined)
                      .map((item, index) => {
                        return item;
                      });

                    const combinedArray = res.map((item) => {
                      if (statusRes) {
                        const matched = statusRes.find((val) => val.SK === item['Accounting Journal']);
                        if (matched) {
                          return { ...item, ...matched };
                        } else {
                          return item;
                        }
                      } else {
                        return item;
                      }
                    });
                    setQueryResult(combinedArray);
                    setPermanentData(combinedArray);
                    setLoading(false);
                  } else if (
                    formData.transtype === 'Transaction' ||
                    formData.transtype === 'Journal' ||
                    formData.transtype === 'CreditCard' ||
                    formData.transtype === 'PettyCash' ||
                    formData.transtype === 'InstructorInvoice' ||
                    formData.transtype === 'InstructorCostAccrual' ||
                    formData.transtype === 'InstructorAccrualReversal' ||
                    formData.transtype === 'PeriodEndProcess' ||
                    formData.transtype === 'DigitalWalletAccounting' ||
                    formData.transtype === 'Payment' ||
                    formData.transtype === 'EmployeeReimbursement' ||
                    formData.transtype === 'EmployeeCost' ||
                    formData.transtype === 'EmployeeCostAccrued' ||
                    formData.transtype === 'EmployeeCostFnF'
                  ) {
                    const res = tallyRes
                      .filter((item) => item['Ledger Name'] !== undefined)
                      .map((item, index) => {
                        return item;
                      });

                    const combinedArray = res.map((item) => {
                      if (statusRes) {
                        const matched = statusRes.find((val) => val.SK === item['Accounting Journal']);
                        if (matched) {
                          return { ...item, ...matched };
                        } else {
                          return item;
                        }
                      } else {
                        return item;
                      }
                    });
                    setQueryResult(combinedArray);
                    setPermanentData(combinedArray);
                    setLoading(false);
                  }
                })
                .catch((err) => console.log(err));
            }
          } else {
            setShowError(true);
            setWarningMessage('No data to display..Please try some other combination');
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setShowError(true);
      setWarningMessage('Please fill all the required fields');
    }
  };

  const handleCheckboxChange = (index) => {
    const selected = [...selectedItems];
    if (selected.includes(index)) {
      selected.splice(selected.indexOf(index), 1);
    } else {
      selected.push(index);
    }
    setSelectedItems(selected);
    setSelectedRowData(selected);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
      setSelectedRowData([]);
    } else {
      const allIndices = Array.from({ length: queryResult.length }, (_, i) => i);
      setSelectedItems(allIndices);
      const filteredColumns = queryResult.filter((item) => item.Status === 'Initiated' || item.Status === 'Success');
      const selectedRows = allIndices
        .map((index) => queryResult[index]['Accounting Journal'])
        .filter((item) => !filteredColumns.some((filteredItem) => filteredItem['Accounting Journal'] === item));
      setSelectedItems(selectedRows);
      setSelectedRowData(selectedRows);
    }
    setSelectAll(!selectAll);
  };

  const handlePush = () => {
    setShowSpinner(true);
    if (
      formData.transtype !== 'Master' &&
      formData.transtype !== 'Transaction' &&
      formData.transtype !== 'Journal' &&
      formData.transtype !== 'CreditCard' &&
      formData.transtype !== 'PettyCash' &&
      formData.transtype !== 'InstructorInvoice' &&
      formData.transtype !== 'InstructorCostAccrual' &&
      formData.transtype !== 'InstructorAccrualReversal' &&
      formData.transtype !== 'PeriodEndProcess' &&
      formData.transtype !== 'DigitalWalletAccounting' &&
      formData.transtype !== 'Payment' &&
      formData.transtype !== 'EmployeeReimbursement' &&
      formData.transtype !== 'EmployeeCost' &&
      formData.transtype !== 'EmployeeCostAccrued' &&
      formData.transtype !== 'EmployeeCostFnF'  && 
      formData.transtype !== "Vendor"
    ) {
      const request = {
        selectedItems: queryResult,
        company: formData.companyName,
        type: formData.transtype,
        companycode: companyCode
      };
      postRequest(`api/edureka/convertJSON`, request)
        .then((response) => {
          handleChange();
        })
        .catch((err) => {
          setQueryResult([]);
          setPermanentData([]);
          setShowError(true);
          setWarningMessage('Oops!! Something went wrong please try again after sometime');
        });
    } else {
      const uniqueFileName =`file_${uuidv4()}`;
      const request = {
        selectedRowData: selectedRowData,
        ledgerInfo: masterLedgerInfo,
        company: formData.companyName,
        type: formData.transtype,
        companycode : companyCode,
        filename:uniqueFileName
      };

      postRequest(`api/edureka/pushToTallyViaSFTP`, request)
        .then((response) => {
          handleChange();
        })
        .catch((err) => {
          setQueryResult([]);
          setPermanentData([]);
          setMasterLedgerInfo([]);
          setShowError(true);
          setWarningMessage('Oops!! Something went wrong please try again after sometime');
        });
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    const selectedYear = year;
    const selectedMonth = month;
    const monthIndex = new Date(Date.parse(selectedMonth + '1,' + selectedYear)).getMonth();
    const startDate = new Date(selectedYear, monthIndex, 1);
    const endDate = new Date(selectedYear, monthIndex + 1, 0);
    const monthInNumber = monthIndex + 1 < 10 ? '0' + Number(monthIndex + 1) : Number(monthIndex + 1);
    setMonthInInt(monthInNumber);
    setFormData((prev) => {
      return { ...prev, fromDate: getStandardDate(startDate), toDate: getStandardDate(endDate) };
    });
  }, [year, month]);

  const handleReset = () => {
    setFormData({
      transtype: '',
      fromDate: '',
      toDate: '',
      companyName: ''
    });
    setSelectedItems([]);
    setSelectedRowData([]);
    setSelectAll(false);
    setQueryResult([]);
    setShowError(false);
    setWarningMessage('');
    setLoading(false);
    setMasterLedgerInfo([]);
    setFilterTable('');
    setYear('');
    setMonth('');
  };

  const handleItemSelect = (val, e) => {
    const { name, value } = e.target;
    const data = [...queryResult];
    const mappedData = data.map((item, index) => {
      if (item['Ledger Name'] === val['Ledger Name']) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });

    setQueryResult(mappedData);
    const masterList = mappedData.map((item) => {
      return {
        ledger_name: item['Ledger Name'] || '',
        account_group: item['parentName'] || '',
        primary_group: item['primaryGroup'] || ''
      };
    });

    const uniqueArray = masterList.filter((item, index, array) => {
      const isDuplicate =
        array.findIndex(
          (element) =>
            element.ledger_name === item.ledger_name &&
            element.account_group === item.account_group &&
            element.primary_group === item.primary_group
        ) === index;

      return isDuplicate;
    });

    setMasterLedgerInfo(uniqueArray);
  };

  const handleFilterTable = (e) => {
    setFilterTable(e.target.value);
    let data = [];
    if (e.target.value === 'No') {
      data = permanentData.filter((item) => item.Status === undefined);
    } else {
      data = permanentData.filter((item) => item.Status === e.target.value);
    }
    setQueryResult(data);
  };

  const getAllocationColumnValue = (item) => {
    return (
      <div style={{ display: 'flex', padding: 4 }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {(item['LobAllocValue'] || []).map((costCenter) =>
            costCenter
              ? Object.keys(costCenter).map((key, index) => (
                  <p
                    key={`${key}_${index}`}
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      whiteSpace: 'nowrap'
                    }}>
                    {key}
                  </p>
                ))
              : ''
          )}
        </div>
        <div style={{ flex: 1 }}>
          {(item['LobAllocValue'] || []).map((costCenter) =>
            costCenter
              ? Object.values(costCenter).map((value, index) => (
                  <p
                    key={`${value}_${index}`}
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      whiteSpace: 'nowrap'
                    }}>
                    {' '}
                    {globalhelper.currencyFormatter(value)}
                  </p>
                ))
              : ''
          )}
        </div>
      </div>
    );
  };

  const handleVendorRowSelect = (item, index) => {
    const isSelected = selectedCheckboxes.includes(index);
  
    if (isSelected) {
     
      setSelectedCheckboxes((prev) => prev.filter((i) => i !== index));
      setSelectedRowData((prev) => prev.filter((_, idx) => selectedCheckboxes[idx] !== index));
      setSelectedItems((prev) => prev.filter((_, idx) => selectedCheckboxes[idx] !== index));
    } else {
      
      setSelectedCheckboxes((prev) => [...prev, index]);
      setSelectedItems((prev) => [...prev, item]);
      setSelectedRowData((prev) => [...prev, item]);
    }
  
    setShowSpinner(false);
  };
  
  const handleVendorSelectAll = () => {
    if (selectedCheckboxes.length === vendorDetails.length) {
      setSelectedCheckboxes([]);
      setSelectedItems([]);
      setSelectedRowData([]);

    } else {
     
     
      const allIndexes = vendorDetails.map((_, index) => index);
      setSelectedCheckboxes(allIndexes);
      setSelectedItems(vendorDetails);
      setSelectedRowData([vendorDetails]);
    }
  };

 
  const handleVendorRowRemove = (index) => {
    setSelectedCheckboxes((prev) => prev.filter((i) => i !== index));
    setSelectedItems((prev) => prev.filter((_, idx) => idx !== index));
  };


  return (
    <>
      <form
        style={{ marginTop: '20px' }}
        onSubmit={handleChange}>
        <div className="row">
          <div className="col-md-2">
            <Select
              label="Company"
              name="companyName"
              options={companies}
              value={formData.companyName}
              onChange={handleDateChange}
            />
          </div>

          <div className="col-md-2">
            <SelectMonth
              label="Month"
              name="month"
              options={dropdown_month}
              value={month}
              onChange={handleMonthChange}
            />
          </div>

          <div className="col-md-2">
            <Select
              label="Year"
              name="year"
              options={dropdown_year}
              value={year}
              onChange={handleYearChange}
            />
          </div>

          <div className="col-md-2">
            <SelectType
              label="Type"
              name="transtype"
              value={formData.transtype}
              onChange={handleTypeChange}
            />
          </div>

          {!loading && permanentData.length ? (
            <div className="col-md-2">
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <select
                  className="spacing"
                  name="filterTable"
                  value={filterTable}
                  onChange={handleFilterTable}>
                  <option
                    value=""
                    disabled>
                    Select
                  </option>
                  <option value="Initiated">Initiated</option>
                  <option value="No">Not Processed</option>
                  <option value="Failed">Failed</option>
                  <option value="Success">Success</option>
                </select>
              </Form.Group>
            </div>
          ) : null}
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <Button
            size="sm"
            onClick={handleChange}
            variant="success">
            Submit
          </Button>
          <Button
            size="sm"
            style={{ marginLeft: '10px' }}
            onClick={handleReset}
            variant="danger">
            Reset
          </Button>
          {selectedRowData.length && !showSpinner ? (
            <Button
              size="sm"
              style={{ marginLeft: '10px' }}
              variant="warning"
              onClick={handlePush}>
              Push to ERP
            </Button>
          ) : null}

          {showSpinner ? (
            <Button
              style={{ marginLeft: '10px' }}
              variant="warning"
              disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : null}
        </div>
      </form>

      {!loading && queryResult.length && vendorDetails.length < 1  ?
        <div>
          <div align="right">
            <CSVLink
              size="sm"
              className="btn btn-primary"
              filename="Tally.csv"
              data={queryResult.sort((a, b) => a['Accounting Journal'].localeCompare(b['Accounting Journal']))}>
              <GetAppIcon />
            </CSVLink>
          </div>
          <table
            border="1"
            id="table-css">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th>Status</th>
                <th>Reason</th>
                <th>Accounting Date</th>
                <th>Accounting Journal</th>
                {formData.transtype === 'Master' ? <th style={{ minWidth: '100px' }}>Parent Name</th> : null}
                {formData.transtype === 'Master' ? <th style={{ minWidth: '100px' }}>Primary Group</th> : null}
                <th>Debit (INR)</th>
                <th>Credit (INR)</th>
                <th>Ledger Name</th>
                <th>Bill Number</th>
                <th>Narration</th>
                {formData.transtype === 'EmployeeCostFnF' ||
                formData.transtype === 'EmployeeCost' ||
                formData.transtype === 'EmployeeCostAccrued' ? (
                  <th>Cost Center Allocation</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {queryResult
                .sort((a, b) => a['Accounting Journal'].localeCompare(b['Accounting Journal']))
                .map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: selectedItems.includes(item['Accounting Journal']) ? '#a0d3ff' : 'transparent'
                      }}>
                      <td>
                        <input
                          disabled={item.Status === 'Initiated' || item.Status === 'Success'}
                          type="checkbox"
                          checked={selectedItems.includes(item['Accounting Journal'])}
                          onChange={() => handleCheckboxChange(item['Accounting Journal'])}
                        />
                      </td>
                      <td>{item.Status}</td>
                      <td>{item.Reason}</td>
                      <td>{getHumanReadableDate(item['Accounting Date'])}</td>
                      <td>{item['Accounting Journal']}</td>

                      {formData.transtype === 'Master' ? (
                        <td style={{ minWidth: '100px' }}>
                          <select
                            name="parentName"
                            value={item.parentName || ''}
                            style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }}
                            onChange={(e) => handleItemSelect(item, e)}
                            required>
                            <option
                              value=""
                              disabled>
                              Select
                            </option>
                            {dropDown.parentName.sort().map((item, index) => (
                              <option
                                key={index}
                                value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}

                      {formData.transtype === 'Master' ? (
                        <td style={{ minWidth: '100px' }}>
                          <select
                            name="primaryGroup"
                            value={item.primaryGroup || ''}
                            style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }}
                            onChange={(e) => handleItemSelect(item, e)}
                            required>
                            <option
                              value=""
                              disabled>
                              Select
                            </option>
                            {dropDown.primaryGroup.sort().map((item, index) => (
                              <option
                                key={index}
                                value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : null}

                      <td style={{ textAlign: 'right' }}>{globalhelper.currencyFormatter(item['Positive Amount'])}</td>
                      <td style={{ textAlign: 'right' }}>{globalhelper.currencyFormatter(item['Negative Amount'])}</td>
                      <td>{item['Ledger Name']}</td>
                      <td>{item['Bill No']}</td>
                      <td>{item['Narration']}</td>
                      <td>
                        {formData.transtype === 'EmployeeCostFnF' ||
                        formData.transtype === 'EmployeeCost' ||
                        formData.transtype === 'EmployeeCostAccrued'
                          ? getAllocationColumnValue(item)
                          : null}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      : null}

      {showError && <div className="center-screen">{warningMessage}</div>}

      {loading ? (
        <div className="center">
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
      ) : null}

      {showPopup ? (
        <div>
          <Modal
            show={showPopup}
            onHide={false}
            backdrop="static"
            keyboard={true}>
            <Modal.Header>
              <Modal.Title>Oops! This action cannot be done</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {`${'The books have been closed for the selected period - '}`} {`${month}, ${year}`}
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="danger"
                size="sm"
                onClick={() => setShowpopup(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : null}

{vendorDetails && vendorDetails.length > 0  && queryResult.length < 1 ? (
       <div>
      
        <Table>
          <TableHead >
            <TableRow>
            <TableCell style={{ backgroundColor: "white" }}>
            <Checkbox
              checked={selectedCheckboxes.length === vendorDetails.length}
              indeterminate={
                selectedCheckboxes.length > 0 &&
                selectedCheckboxes.length < vendorDetails.length
              }
              onChange={handleVendorSelectAll}
            />
          </TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Vendor ID</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Vendor Name</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Ledger Name</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Account Group</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Primary Group</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>GST Type</TableCell>
              <TableCell style={{ backgroundColor:'white'}} >GSTIN</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>PAN</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Address</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>State</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Country</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Pincode</TableCell>             
              <TableCell style={{ backgroundColor:'white'}}>Acc No</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>IFSC Code</TableCell>
              <TableCell style={{ backgroundColor:'white'}}>Bank Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendorDetails.map((row, index) => (
              <TableRow key={index}>
                
                 <TableCell>
              <Checkbox
                checked={selectedCheckboxes.includes(index)}
                onChange={() => handleVendorRowSelect(row, index)}
              /></TableCell>
                <TableCell>{row["VendorID"] || ""}</TableCell>
                <TableCell>{row["VendorName"] || ""}</TableCell>
                <TableCell>{row["Ledger Name"] || ""}</TableCell>
                <TableCell>{row["AccountGroup"] || ""}</TableCell>
                <TableCell>{row["PrimaryGroup"] || ""}</TableCell>
                <TableCell>{row["GST Type"] || ""}</TableCell>
                <TableCell>{row["GSTIN"] || ""}</TableCell>
                <TableCell>{row.PAN || "" }</TableCell>
                <TableCell>{row.Details.Address || "" }</TableCell>
                <TableCell>{row.Details.State || "" }</TableCell>
                <TableCell>{row.Details.Country || "" }</TableCell>
                <TableCell>{row.Details.Pincode || "" }</TableCell>
                <TableCell>{row.Details["A/c No"] || ""}</TableCell>
                <TableCell>{row.Details["IFSC Code"] || ""}</TableCell>
                <TableCell>{row.Details["Bank Name"] || ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    
    </div>
      ) : (
        null
      )}
    </>
  );
};

export default TallyInputUserSelect;
