import React, { Component } from 'react'
import './apps.css'
import Spinner from "react-bootstrap/Spinner";
import { connect } from 'react-redux'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BuildIcon from '@material-ui/icons/Build';
import  StorageIcon  from '@material-ui/icons/Storage';
import  LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import TransitEnterexitIcon  from '@material-ui/icons/TransitEnterexit';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import  MultilineChartIcon  from '@material-ui/icons/MultilineChart';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {
  Redirect
} from "react-router-dom";
import { getRequest } from '../../globalhelper/helper'
import { navUrl, setMenuTree, flushMenuTree } from '../../actions/common_actions';
import { getSetModules } from '../../actions/interactions_action';
import store from '../../store';
const state = store.getState();
const common = state.common;
// var auth = state.login.auth;
var gotKey = false;
class MyApps extends Component {

  constructor (props){
    super(props);
    this.state = {
      alert : '',
      modules : [],
      redirect : null,
      noModules : false,
      gotKey : false
    }
  }

  navigateToHome = (module) => {

    console.log(this.props.store.login)
    
    let LANDING_PAGE = null;

    if(module.name === 'OneRetail'){

      LANDING_PAGE = '/26AS_dashboard'
    }
    else if(module.name === 'PMA'){

      LANDING_PAGE = '/uploadv2'
    }
    else if(this.props.store.login.userData.USR_Tenant === "FM00001"){

      LANDING_PAGE = '/schemaNew'
    }
    else {
      LANDING_PAGE = '/uploadv3'
    }

    localStorage.setItem("menuTree",null);
    this.props.flushMenuTree()
    localStorage.setItem("module" , module.name);
    localStorage.setItem("module_desc" , module.desc);
    localStorage.setItem("module_desc_for_ui_only" , '');
    localStorage.setItem("pageName" , "");
    localStorage.setItem("system" , module.system);
    // this.setState({
    //   ...this.state,
    //   redirect : module.name.includes('P2P_Application') ?  '/po_dashboard' :  module.name.includes('Lease') ? '/home' : '/upload'
    // })
    // getRequest('api/module/getMenus?product=' + module.name)
    // .then(result => {
    //   console.log(result);
    //   var homePageModules = [
    //     // "Lease",
    //     // "FixedAsset"
    //   ]
    //   if(result.res.status === "success")
    //   {
    //     console.log("onResult", common)
    //     this.props.setMenuTree(result.res.data);
    //     localStorage.setItem("menuTree",JSON.stringify(result.res.data));
    //     let publicUrl = (homePageModules.indexOf(module.name) > -1 ? true : false);
    //     setTimeout(() =>  
    
    if(this.props.store.login.userData.landing_page_url !== ""){

      let L_PAGE = this.props.store.login.userData.landing_page_url

      if(L_PAGE) {

        L_PAGE = (  L_PAGE.charAt(0) === "/" ?  
                    "/"  :  "/" + L_PAGE.charAt(0)
                  ) 
                  + L_PAGE.slice(1);
      }

      this.props.navUrl( L_PAGE );
      // localStorage.setItem("go_to_landing_page" , "0");
    }
    else {

      console.log(LANDING_PAGE)

      this.props.navUrl(LANDING_PAGE)
    }


    //     1000)
    //   }
    // })
  }

  getModules = () => {

    this.props.getSetModules();

    // getRequest("api/module/modules?tenantId=" + this.props.store.login.userData.USR_Tenant)
    //   .then(result => {
    //     // console.log(result);
    //     if (result.res.status === 'success') {
    //       if (result.res.data.length > 0) {
    //         this.setState({
    //           ...this.state,
    //           modules: result.res.data
    //         })
    //       } else {
    //         this.setState({
    //           ...this.state,
    //           noModules: true
    //         })
    //       }
    //     }
    //   })
  }

checkVariable = () => {
  var auth = this.props.store.login.auth;
  // console.log(auth,this.state.gotKey)
    if (auth !== "" && !this.state.gotKey ) {
      // gotKey = true;
      this.setState({
        ...state,
        gotKey : true
      })
      this.getModules();
        // Here is your next action
    }
  }
 
  // componentWillMount = () => {
  //   localStorage.setItem("module" , 'My Apps');
  //   localStorage.setItem("module_desc" , 'My Apps');
  //   localStorage.setItem("pageName" , 'My Apps');
  // }

  componentWillMount = () => {
    localStorage.setItem("module_desc_for_ui_only" , 'My Apps');
    localStorage.setItem("pageName" , '');
  }
  
  componentDidMount = () =>{

    console.log(this.props.store, this.props.store.login, "apps.js_when_login" );
    this.interval = setInterval(this.checkVariable, 1000);

    // if(localStorage.getItem("go_to_landing_page") == "1")
    // {
    //   this.props.navUrl('/' + this.props.store.login.userData.landing_page_url);
    //   // localStorage.setItem("go_to_landing_page" , "0");
    // }
    // else{
    //   if(this.props.store.interactions.modules && this.props.store.interactions.modules.length === 1)
    //   {
    //     this.navigateToHome(this.props.store.interactions.modules[0])
    //   }
    // }

    // setTimeout(checkVariable, 1000);
    // setTimeout(() => 
    //   getRequest("api/module/modules?tenantId=" + this.props.store.login.userData.tenant_id)
    //         .then(result => {
    //           console.log(result);
    //             if(result.res.status === 'success')
    //             {
    //               if(result.res.data.length > 0)
    //               {
    //                 this.setState({
    //                     ...this.state,
    //                     modules : result.res.data
    //                 })
    //               }
    //               else
    //               {
    //                 this.setState({
    //                     ...this.state,
    //                     noModules : true
    //                 })
    //               }
    //           }
    //         }),
    //         3000)
    
  }

  componentWillUnmount = () =>{
    clearInterval(this.interval);
  }

  whenOneModule = () => {

    console.log(
      'only one module so loging in to ', 
      this.props.store.login.userData.landing_page_url !== "" ?
      this.props.store.login.userData.landing_page_url
      :
      'defaultPage'
     )
   
      
    this.navigateToHome(this.props.store.interactions.modules[0])      
  }
  

  render() {
    
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div>
        { this.props.store.interactions.modules && this.props.store.interactions.modules.length > 0 ?
          <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            {this.props.store.interactions && this.props.store.interactions.modules && this.props.store.interactions.modules.length 
            && this.props.store.interactions.modules.length === 1 && this.whenOneModule() }

            {this.props.store.interactions.modules.map((module,idx) => 
            <div  key={idx}>
              <div className="col-md-5 text-center module-container" align="center" onClick={() => this.navigateToHome(module)}>
                <div className="row">
                  <div className="col-md-3 mobile-25" style={{ marginTop: "12px" }}>
                    {
                      module.name.includes('Lease') ?
                      // <FontAwesomeIcon icon="file-contract"  size='5x' /> 
                      <FileCopyIcon style={{ fontSize: 70 }} />
                      : 
                      module.name.includes('Enterprise_Configurations') ?
                      // <FontAwesomeIcon icon="tools" size='5x' /> 
                      <BuildIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('Finance_APIs') ?
                      // <FontAwesomeIcon icon="exchange-alt" size='5x' /> 
                      <TransitEnterexitIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('Financial_Instruments') ?
                      // <FontAwesomeIcon icon="money-check-alt" size='5x' /> 
                      <LocalAtmIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('HIS_Inventory_Management') ?
                      // <FontAwesomeIcon icon="hospital" size='5x' /> 
                      <LocalHospitalIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('P2P_Application') ?
                      // <FontAwesomeIcon icon="chart-line" size='5x' /> 
                      
                      <MultilineChartIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('ERP_Materials_Management_APIs') ?
                      // <FontAwesomeIcon icon={faDatabase}  size='5x' /> 
                      <StorageIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('FixedAsset') ?
                      // <FontAwesomeIcon icon={faDatabase}  size='5x' /> 
                      <EmojiTransportationIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('OneCommerce') ?
                      // <FontAwesomeIcon icon={faDatabase}  size='5x' /> 
                      <AccountTreeIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('OneRetail') ?
                      // <FontAwesomeIcon icon={faDatabase}  size='5x' /> 
                      <HomeWorkIcon style={{ fontSize: 70 }} />
                      :
                      module.name.includes('COD') ?
                      <PaymentIcon style={{ fontSize: 70 }}/>
                      :
                      module.name.includes('DTFOWS') ?
                      <AccountBalanceIcon style={{ fontSize: 70 }}/>
                      :
                      null
                    }
                  </div>
                  {/* { module.name === 'OneRetail' ?  */}

                  {/* <div className="col-md-9  mobile-75">
                    <div className="row">
                      <div className="col-md-12" align="left" style={{padding: '2.3rem 0 0 1.7rem'}}>
                        <h5 style={{fontWeight: 'bold'}}> {module.desc.split("_").join(" ")}</h5> 
                      </div>
                    </div>                 
                  </div> */}
                   
                  <div className="col-md-9  mobile-75">
                    <div className="row">
                      <div className="col-md-12" align="left">
                        <h5 style={{fontWeight: 'bold'}}> {module.name === 'OneRetail' ? '26AS Reconciler' : module.name === 'OneCommerce' ? 'Payouts' : module.name.split("_").join(" ")}</h5> <br/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12" align="left">
                        <b> {module.desc}</b>
                      </div>
                    </div>
                  </div>
                  {/* } */}

                </div>
               
              </div>
            </div>
            )}
          </div>
        :
        this.state.noModules ?
         <div className="row">
           <div className="col-md-12"  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
              <h4>You don't have access for any modules!</h4>
           </div>
         </div>
         : 
         <div className="row">
            <div className="col-md-12" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                <h6> Please Wait... </h6> 
                <Spinner animation="grow" variant="text-navy"  /> 
            </div>
         </div>
        }
        
        {this.state.alert}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store : state
  }
};

// export default  MyApps;

export default connect(
  mapStateToProps,
  { navUrl, setMenuTree,flushMenuTree, getSetModules }
)(MyApps);