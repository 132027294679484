import { FormatListBulleted } from '@material-ui/icons';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
export default function CostAllocationCellRenderer({ formattedValue, value, CAMPeriodMap, tabIndex }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCAMSelectorOpen, setCAMSelectorOpen] = useState(false);
  const handleCAMSelectorClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCAMSelectorOpen(true);
  };
  const CAMPeriods = Array.from(CAMPeriodMap?.get(value)?.keys() || []).map((period) => {
    let allocationType = '';
    const allocations = Array.from(CAMPeriodMap?.get(value).get(period).values() || [])
      .map((cam) => {
        allocationType = cam.allocationtype;
        return `${cam.lob} (${cam.costallocationvalue})`;
      })
      .join(', ');
    return `${period} [${allocationType}] ${allocations}`.replace('|', ' - ');
  });
  const handleCAMSelectorClose = () => {
    setAnchorEl(null);
    setCAMSelectorOpen(false);
  };
  return (
    <div
      className="MuiDataGrid-cellContent"
      role="presentation">
      <Box
        gap={2}
        display="flex"
        alignItems="center">
        {(formattedValue || value) && (
          <Button
            tabIndex={tabIndex}
            variant="text"
            disabled={CAMPeriods.length === 0}
            aria-controls={isCAMSelectorOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isCAMSelectorOpen ? 'true' : undefined}
            onClick={handleCAMSelectorClick}>
            <FormatListBulleted />
          </Button>
        )}
        <Typography>{formattedValue || value}</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={isCAMSelectorOpen}
        hideBackdrop
        onClose={handleCAMSelectorClose}>
        {CAMPeriods.map((CAM) => (
          <MenuItem
            key={CAM}
            onClick={handleCAMSelectorClose}>
            {CAM}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
