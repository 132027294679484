import './COD.css';
import {Form} from 'react-bootstrap';
import {Tooltip, IconButton} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import Typography from '@mui/material/Typography';

function FormWithToolTipSelect({label, text}){
    return (
        <div>
            <Form.Label>{label}
                <Tooltip title={<Typography fontSize={3}>{text}</Typography>}>
                    <IconButton>
                        <InfoIcon sx={{paddingBottom:1}}></InfoIcon>
                    </IconButton>
                </Tooltip>
            </Form.Label>
        </div>
    )
}

export default FormWithToolTipSelect