import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Button,
  // Box,
  // IconButton,
  Typography,
} from '@material-ui/core';
// import { no } from '@material-ui/icons';
import create from 'zustand';



const useConfirmDialogStore = create((set) => ({
  message: '',
  yes: undefined,
  no:  undefined,
  close: () => set({ yes: undefined, no: undefined }),
}));


export const confirmDialog = (message, yes, no) => {
  useConfirmDialogStore.setState({
    message,
    yes,
    no
  });
};

const ConfirmDialog = () => {
  // destructure the store data and functions
 const { message, yes, no, close } = useConfirmDialogStore();
 return (
    // if the yes is undefined the dialog will be nod. 
    // no() function sets the yes to undefined, 
    // so it will no the dialog, if we pass it to the onno attribute.
   <Dialog open={Boolean(yes)} maxWidth="xs" fullWidth> 
     <DialogTitle>Confirm the action</DialogTitle>     
     <DialogContent>
       <Typography>{message}</Typography>
     </DialogContent>
     <DialogActions>
       <button
        className='btn btn-secondary btn-sm' variant="contained" 
        onClick={() => {
           if (no) {
             no();
           }
           close();
         }}>
         No
       </button>
       <button
         className='btn btn-success btn-sm'
         variant="contained"
         onClick={() => {
           if (yes) {
             yes();
           }
           close();
         }}
       >
         Yes
       </button>
     </DialogActions>
   </Dialog>
 );
};

export default ConfirmDialog



 
  
 


// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';

// const  ResponsiveDialog = () => {
//   const [open, setOpen] = React.useState(false);
//   const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleno = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button variant="outlined" onClick={handleClickOpen}>
//         click
//       </Button>
//       <Dialog open={open} aria-labelledby="responsive-dialog-title">
//         <DialogTitle id="responsive-dialog-title">
//           {"Confirm to submit"}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//           `Are you want to apply "$" theme for all files?`
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button autoFocus onClick={handleno}>
//             No
//           </Button>
//           <Button onClick={handleno} autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

// export default ResponsiveDialog
