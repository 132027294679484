import React, {Fragment, PureComponent} from 'react';
import DatePicker from "react-datepicker";
import { getRequest, getHumanReadableDate } from '../../globalhelper/helper';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Spinner from 'react-bootstrap/Spinner'

const { ExportCSVButton } = CSVExport;
const columns = [{
    dataField: 'eventdt',
    csvFormatter: (cell, row, rowIndex) => {
        console.log(cell);
        return getHumanReadableDate(cell)
    },
    formatter : (cell, row, rowIndex) => {
        console.log(cell);
        return getHumanReadableDate(cell)
    },
    text: 'Event Date',
}, {
    dataField: 'clientstationcode',
    csvFormatter: (cell, row, rowIndex) => {
        return cell === null ? " " : cell
    },
    text: 'Client Station Code'
}, {
    dataField: 'ClientAssociateCd',
    csvFormatter: (cell, row, rowIndex) => {
        return cell === null ? " " : cell
    },
    text: 'Client Associate Code'
},{
    dataField: 'RawSLADelyCnt',
    csvFormatter: (cell, row, rowIndex) => {
        return cell === null ? " " : cell
    },
    text: 'Raw SL Dely. Count',
    style: {
        width: '250px',
    }
}, {
    dataField: 'AudireSLADelyCnt',
    csvFormatter: (cell, row, rowIndex) => {
        return cell === null ? " " : cell
    },
    text: 'Audire SL Dely. Count'
}, {
    dataField: 'SLADelyDiff',
    csvFormatter: (cell, row, rowIndex) => {
        return cell
    },
    text: 'SL Dely. Diff.',
    style: (cell, row) => {
        return {
            backgroundColor : Number(cell) !== 0 ? 'red' : 'green',
            color : 'white',
            fontWeight : 'bold'
        }
    }
}, {
    dataField: 'RAWSTADelyCnt',
    csvFormatter: (cell, row, rowIndex) => {
        return cell === null ? " " : cell
    },
    text: 'Raw ST Dely. Count',
}, {
    dataField: 'AudireSTADelyCnt',
    csvFormatter: (cell, row, rowIndex) => {
        return cell === null ? " " : cell
    },
    text: 'Audire ST Dely. Count'
}, {
    dataField: 'STADelyDiff',
    csvFormatter: (cell, row, rowIndex) => {
        return cell === null ? " " : cell
    },
    text: 'ST Dely. Diff.',
    
    style: (cell, row) => {
        return {
            backgroundColor : Number(cell) !== 0 ? 'red' : 'green',
            color : 'white',
            fontWeight : 'bold'
        }
    }
}];
  

class FlipkartRecon extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
            formData : {
                month : new Date()
            },
            showRecords : false,
            loading : false,
            records : []
        }
    }

    componentDidMount = () => {
        this.getDetails();
    }
    
    setDate = (date,field) =>{
    
        this.setState({
        ...this.state,
        formData : {
            ...this.state.formData,
            [field] : date,
        }
        })
    }

    getDetails = () => {
        this.setState({
            ...this.state,
            loading : true
        }, () => {

            getRequest('api/reports/getFlipkartInVsOut?month=' + this.state.formData.month).then(result => {
                if(result.type === "success")
                {
                    this.setState({
                        ...this.state,
                    records : JSON.parse(result.res.data),
                    showRecords : true
                }, () => {
                    this.setState({
                        ...this.state,
                        loading : false
                    })
                })
            }
        })
    })
    }

    render(){
        const PAGINATION_OPTIONS = { sizePerPageList: [{
            text: '100', value: 100
          }, {
            text: '200', value: 200
          }, {
            text: '300', value: 300
          }, {
            text: '500', value: 500
          }, {
            text: 'All', value: this.state.records.length
          }] }
        return(
            <Fragment>
                <div className="row">
                    <div className='col-md-3'>
                        <label>Month</label> <br/>
                        <DatePicker selected={this.state.formData.month} dateFormat="MM/yyyy" showMonthYearPicker className="col-md-12" onChange={ (date) => this.setDate(date,'month') } id="month" />
                    </div>
                    <div className='col-md-3'>
                        <button className="btn btn-primary" onClick={this.getDetails} style={{ marginTop : 27 }} >Load</button>
                    </div>
                </div> 
                <br />
                <br />
                <div className="row">
                    <div className="col-md-12">
                        {this.state.loading ? 
                            <div className="d-flex align-items-center justify-content-center h-100" >
                                <Fragment>
                                    <Spinner animation="border" role="status" />
                                </Fragment>
                                <br />
                                <br />
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        :
                         this.state.showRecords ?
                            <ToolkitProvider
                            keyField="ClientAssociateCd"
                            data={ this.state.records }
                            columns={ columns }
                            exportCSV
                            
                          >
                            {
                              props => (
                                <div>
                                  <ExportCSVButton { ...props.csvProps } style={{ textAlign : "right" }} ><button className="btn btn-info float-right" > Export CSV </button></ExportCSVButton>
                                  <hr />
                                  <BootstrapTable { ...props.baseProps }  pagination={ paginationFactory(PAGINATION_OPTIONS) } />
                                </div>
                              )
                            }
                          </ToolkitProvider>
                          : null
                         }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default FlipkartRecon;