import React, { Fragment, Component } from "react";
import { convertNumberIntoINR, getHumanReadableDateTime, getRequest, postRequest } from "../../globalhelper/helper";
import Select from 'react-select';
import Datatable from "../../components/DataTable2";

class ReconStatus extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            formData :{
                // txnentity : '',
                tan : '',
                // txnentity_obj : '',
                tan_obj : ''
            },
            columns : [
                {
                    name: 'TAN',
                    selector: row => row["TAN"],
                    sortable: true,
                    width : '200px'
                },
                {
                    name: 'Recon Status',
                    selector: row => row["Recon Status"],
                    sortable: true,
                    width : '100px'
                },
                {
                    name: 'Sales',
                    selector: row => convertNumberIntoINR(Number(row["Sales"]).toFixed(2)),
                    right : true,
                    sortable: true,
                    width : '150px'
                },
                {
                    name: 'F26AS Amt Credited',
                    selector: row => convertNumberIntoINR(Number(row["F26AS Amt Credited"]).toFixed(2)),
                    right : true,
                    sortable: true,
                    width : '150px'
                },
                {
                    name: 'TDS WO Debits',
                    selector: row => convertNumberIntoINR(Number(row["TDS WO Debits"]).toFixed(2)),
                    right : true,
                    sortable: true,
                    width : '150px'
                },
                {
                    name: 'F26AS TDS Deposited',
                    selector: row => convertNumberIntoINR(Number(row["F26AS TDS Deposited"]).toFixed(2)),
                    right : true,
                    sortable: true,
                    width : '150px'
                },
                {
                    name: 'Revenue Diff',
                    selector: row => convertNumberIntoINR(Number(row["Revenue Diff"]).toFixed(2)),
                    right : true,
                    sortable: true,
                    width : '150px'
                },
                {
                    name: 'TDS Diff',
                    selector: row => convertNumberIntoINR(Number(row["TDS Diff"]).toFixed(2)),
                    right : true,
                    sortable: true,
                    width : '150px'
                },
                {
                    name: 'TAN Status',
                    selector: row => row["TAN Status"],
                    sortable: true,
                    style : {
                        paddingLeft : '20px'
                    },
                    width : '150px'
                },
            ],
            fetching : false,
            txnEntityList : [],
            tanList : [],
            reconStatusList : [],
         };
    }

    // getTxnEntityList = () => {
    //     getRequest('api/tds/getTxnEntityList').then(res => {
    //         if(res.type === 'success') {
    //             this.setState({
    //                 txnEntityList : JSON.parse(res.res.data)
    //             });
    //         }
    //     });
    // }

    handleChange = (ev) => {
        let { formData } = this.state;
        let NAME = ev.target.name, VALUE = ev.target.value;
        console.log(NAME, VALUE)
        formData[NAME] = VALUE;
        if(NAME === 'txnentity_obj'){
            formData['txnentity'] = VALUE.value;
        }
        if(NAME === 'tan_obj'){
            formData['tan'] = VALUE.map(tan => tan.value).join(',');
        }
        this.setState({
            ...this.state,
            formData
        }, () => {
            if(NAME === "txnentity_obj")
            {
                this.getTansListForTxnEntity(this.state.formData.txnentity);
            }
        });
    }

    getTansListForTxnEntity = () => {
        getRequest('api/tds/getTanListForTxnEntity?txnentity=' + this.state.formData.txnentity).then(res => {
            if(res.type === 'success') {
                this.setState({
                    tanList : JSON.parse(res.res.data)
                });
            }
        });
    }


    componentDidMount = () => {
        // this.getTxnEntityList();
        
        this.getTansListForTxnEntity();
    }

    onGetStatus = () => {
        this.setState({
            fetching : true,
        }, () => {
            const BODY = {
                tan : this.state.formData.tan,
                txnentity : this.state.formData.txnentity
            }
            postRequest('api/tds/getTDSReconStatus', { data : JSON.stringify(BODY)}).then(res => {
                if(res.type === 'success') {
                    // window.location.reload();
                    this.setState({
                        ...this.state,
                        reconStatusList : JSON.parse(res.res.data),
                        fetching : false
                    })
                }
            });
        })

    }

    render() { 
        return (  
            <Fragment>
                <form >
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>TAN</label>
                                <Select
                                    value={this.state.formData.tan_obj}
                                    isMulti
                                    onChange={(value) => this.handleChange({ target : { name : 'tan_obj', value } })}
                                    options={this.state.tanList}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-primary" style={{ marginTop : 33 }} onClick={this.onGetStatus} >Get Status</button>
                        </div>
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <Datatable columns={this.state.columns} title="TDS Reconciliation Status" loading={this.state.fetching} key={this.state.reconStatusList} data={this.state.reconStatusList} ></Datatable>
                    </div>
                </div>
            </Fragment>
        );
    }
}
 
export default ReconStatus;