import React, {
    Component, Fragment
} from 'react';
import DatePicker from "react-datepicker";
import { getRequest, postRequest, getDateFormat, getStandardDateTime, getStartDateOfMonth, getEndDateOfMonth } from '../globalhelper/helper';
import store from '../store';
import DataTableTemplate from "../components/datatable";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import axios from'axios';
import ApiConstants from "../config";
import download from 'downloadjs';
import { connect } from 'react-redux'
import { showAlert, hideAlert }  from '../actions/common_actions'

// const state = store.getState();
// const userData = state.login.userData;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class FixedAssetRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                from_date: new Date().setMonth(new Date().getMonth()-1, 1),
                to_date: new Date().setMonth(new Date().getMonth(), 1)
            },
            data : [],
            loading: false,
            processPending : false,
            downloading : false,
            pendingProcess : "",
        }
        this.userData = this.props.state.login.userData;
        this.tempCol = [
          {
            name: 'Period',
            // selector: 'description',
            cell : (row) => {
                // 
                // return monthNames[new Date(row.From_Period).getMonth()] + " - " + monthNames[new Date(row.To_Period).getMonth()]
                return monthNames[new Date(row.From_Period).getMonth()] + " " + new Date(row.From_Period).getFullYear() + " - " + monthNames[new Date(row.To_Period).getMonth()]  + " " + new Date(row.To_Period).getFullYear()
            },
            sortable: true,
            width : '150px',
            wrap : true,
          },
          {
            name: 'Total Count',
            selector: 'Total_Count',
            sortable: true,
            width : '120px',
            wrap : true,
          },
          {
            name: 'Status',
            cell: (row) => <Button variant="outlined" size="small" color={ row.Status === "Completed" ? "primary" : "secondary" }>{ row.Status }</Button>,
            sortable: true,
            wrap : true,
            width : '150px',
            button: false,
          },
          {
            name : "Stated",
            cell: (row) => {
                return getStandardDateTime(row.Started);
            },
            wrap : true,
            width : '200px',
            button: true,
          },
          {
            name : "Ended",
            cell: (row) => {
                return getStandardDateTime(row.Ended);
            },
            wrap : true,
            width : '200px',
            button: true,
          },
          {
            name : "Total Time (In Mins)",
            cell: (row) => {
                return (Number(row.Total_Sec) / 60).toFixed(2) !== 'NaN' ? (Number(row.Total_Sec) / 60).toFixed(2) : "Report Generating...";
            },
            wrap : true,
            width : '100px',
            button: true,
          },
          {
            name : "Report",
            cell: (row) => {
                return (<Button disabled={this.state.downloading || row.Filename === '' } onClick={() => this.downloadFile(row.Filename)} color="primary">
                { row.Filename !== "" ? row.Filename : "No Data Found"}
              </Button>)
            },
            wrap : true,
            width : '400px',
            button: true,
          },
        ]
        this.columns = this.tempCol;
    }
    
    downloadFile = (Filename) => {
        this.props.showAlert("Please wait your file is downloading... Please don't Reload or Go back", "info")

        this.setState({
            ...this.state,
            downloading : true
        })

        axios.post(ApiConstants.externals.serverUrl +  "api/fixedasset/downloadReport",
          {
            filename: Filename,
            tenantId : this.userData.tenant_id
          },
          { responseType: 'blob' }
        )
        .then(response => {
                console.log(response.data)
                const blob = response.data;
                download(blob, Filename);
                this.setState({
                    ...this.state,
                    downloading : false
                })
                this.props.hideAlert()
        })
        .catch(err => {
            this.props.showAlert("Error while downloading File", "error")
            setTimeout(() => {
                this.props.hideAlert();
                window.location.reload();
            },3000)
        })
    }

    getReports = () => {
        getRequest('api/fixedasset/getReportsList?tenant_id=' + localStorage.getItem("tenant_id"))
        .then(result => {
            if(result.type === "success")
            {
                this.setState({
                    ...this.state,
                    data : result.res.data
                })
            }
        })
    }

    componentDidMount = () => {
        var tenant_id = localStorage.getItem("tenant_id");
        getRequest('api/fixedasset/checkPendingForReport?tenant_id='+ tenant_id)
        .then(result => {
            if(result.type === "success")
            {
                var process = result.res.data;
                var proName = "";
                var f = 0;
                process.forEach((pro,index) => {
                    if(pro.count > 0)
                    {
                        f++;
                        console.log(proName);
                        proName += index === 0 && proName === "" ? "" : " , " + pro.process;
                        console.log(proName);
                    }
                })
                if(f > 0)
                {
                    this.setState({
                        ...this.state,
                        processPending : true,
                        pendingProcess : proName
                    })  
                }
            }
            })
            this.getReports();
    }
    
    setDate = (date, field) => {

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [field]: new Date(date)
            }
        })
    }

    handleSubmit = () => {
        var formData = {};
        formData.from_date = getStartDateOfMonth(this.state.formData.from_date)
        formData.to_date = getEndDateOfMonth(this.state.formData.to_date)
        formData.tenant_id = localStorage.getItem("tenant_id");
        postRequest('api/fixedasset/getReports',formData)
        .then(result => {
            console.log(result);
            window.location.reload()
        })
    }


    render() {
        const { data } = this.state;
        return ( 
            <Fragment>
            <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="from_date">From</label> <br />
                        <DatePicker selected={this.state.formData.from_date} showPopperArrow={false} id="from_date"
                            name="from_date" maxDate={ this.state.formData.to_date } dateFormat="MMM-yyyy"
                            className="col-md-12" showMonthYearPicker onChange={ (date)=> this.setDate(date,'from_date') } />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="to_date">To</label> <br />
                        <DatePicker selected={this.state.formData.to_date} showPopperArrow={false} id="to_date"
                            name="to_date" minDate={ this.state.formData.from_date } className="col-md-12"
                            dateFormat="MMM-yyyy" showMonthYearPicker onChange={ (date)=> this.setDate(date,'to_date') } />
                    </div>
                    <div className="col-md-3 text-center" style={{ marginTop : "25px" }}>
                        <button className="btn btn-teal" onClick={ this.handleSubmit } disabled={this.state.processPending} > { this.state.processPending ? "Process Already Pending!" : "Load" } </button>
                    </div>
                </div>
                <br/><br/>
                    
                <Card style={{ height: '100%' }}>
                    <DataTableTemplate 
                    name="Available Records"
                    columns={this.columns}
                    data={data}
                    defaultSortField='index'
                    />
                </Card>
                </Fragment>
        )
    }

}



const mapStateToProps = (state) => {
    return {
      state
    }
  };



  export default connect(
    mapStateToProps,
    { showAlert, hideAlert }
)(FixedAssetRegister);