function openPopup(popupData) {
    return {
        type: 'openPopup',
        popVisible: popupData.popVisible,
        currentNodeData : popupData.currentNodeData,
    }
}

function closePopup(popupData) {
    return {
        type: 'closePopup',
        popVisible: popupData.popVisible, 
    }
}
function popupNodeAdd(popupData) {
    return {
        type: 'popupNodeAdd',
        nodeType:popupData.nodeType ? popupData.nodeType : 'group',
        nodeName:popupData.nodeName,
        position:popupData.position
    }
}
function popupFieldChange(popupData) {
    return {
        type: 'popupFieldChange',      
        fieldName:popupData.fieldName,
        nodeType:popupData.nodeType,
        enable: Boolean(Number(popupData.enable)),
        position:popupData.position
    }
}

export function openPopupDialog(popupData){
    return (dispatch) =>{
        dispatch(openPopup(popupData))
       } 
}
export function closePopupDialog(popupData){
    
    return (dispatch) =>{
        dispatch(closePopup(popupData))
       } 
}
export function onPopupFieldChange(popupData){
    console.log(popupData);
    return (dispatch) =>{
        dispatch(popupFieldChange(popupData))
       } 
}
export function onPopupNodeAdd(popupData){
    
    return (dispatch) =>{
        dispatch(popupNodeAdd(popupData))
       } 
}
