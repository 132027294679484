import {
    ADD_COMPONENT, CHANGE_PROP, CHANGE_VALUE_JSON
} from "../constants/action-types";
var initial_state = {
    component : 'input',
    components : [
        {
            name : 'Name',
            displayName : 'Name',
            inputType : 'input',
            type : 'input',
            style : {
                color : 'black',
                backgroundColor : 'transparent',
                borderRadius : 0,
                // minWidth : '65%',
                // position : 'absolute'
            },
            key_style : {
                color : 'black',
                backgroundColor : 'transparent',
                borderRadius : 0,
                // minWidth : '65%',
                // position : 'absolute'
            },
            value_style : {
                color : 'black',
                backgroundColor : 'transparent',
                borderRadius : 0,
                // minWidth : '65%',
                // position : 'absolute'
            }
        }
    ],
    values : {
        table_header : [

        ],
        table_data : [
            [
                {
                    name : "Harish",
                    age : "23",
                    phone : "7871381534",
                    email : "harish@harish.com",
                    address : "tirupur",
                    organisation : "OneIntegral",
                    designation : "Developer",
                    salary : "250000",
                    dob : "22-11-1997",
                },
                {
                    name : "Harish",
                    age : "23",
                    phone : "7871381534",
                    email : "harish@harish.com",
                    address : "tirupur",
                    organisation : "OneIntegral",
                    designation : "Developer",
                    salary : "250000",
                    dob : "22-11-1997",
                },
            ],
            [
                {
                        name : "Harish 1",
                        age : "23 1",
                        phone : "7871381534 1",
                        email : "harish@harish.com 1",
                        address : "tirupur 1",
                        organisation : "OneIntegral 1",
                        designation : "Developer 1",
                        salary : "250000 1",
                        dob : "22-11-1997 1",
                },
            ],[
                {
                        name : "Harish 2",
                        age : "23 2",
                        phone : "7871381534 2",
                        email : "harish@harish.com 2",
                        address : "tirupur 2",
                        organisation : "OneIntegral 2",
                        designation : "Developer 2",
                        salary : "250000 2",
                        dob : "22-11-1997 2",
                },
            ],[
                {
                        name : "Harish 3",
                        age : "23 3",
                        phone : "7871381534 3",
                        email : "harish@harish.com 3",
                        address : "tirupur 3",
                        organisation : "OneIntegral 3",
                        designation : "Developer 3",
                        salary : "250000 3",
                        dob : "22-11-1997 3",
                },
            ],[
                {
                        name : "Harish 4",
                        age : "23 4",
                        phone : "7871381534 4",
                        email : "harish@harish.com 4",
                        address : "tirupur 4",
                        organisation : "OneIntegral 4",
                        designation : "Developer 4",
                        salary : "250000 4",
                        dob : "22-11-1997 4",
                },
            ]
        ],
    }
}
export default function componentsReducer(state = initial_state, action) {
    switch (action.type) {
        case ADD_COMPONENT:
            return {
                ...state,
                component: action.payload,
                components : [
                    {
                        name : 'Name',
                        displayName : 'Name',
                        type : action.payload,
                        style : {
                            color : 'black',
                            backgroundColor : 'transparent',
                            borderRadius : 0
                        },
                        key_style : {
                            color : 'black',
                            backgroundColor : 'transparent',
                            borderRadius : 0,
                            // minWidth : '65%',
                            // position : 'absolute'
                        },
                        value_style : {
                            color : 'black',
                            backgroundColor : 'transparent',
                            borderRadius : 0,
                            // minWidth : '65%',
                            // position : 'absolute'
                        }
                    }
                ]
              }
        case CHANGE_PROP:
            return {
                ...state,
                components: action.payload
              }
        case CHANGE_VALUE_JSON:
            return {
                ...state,
                values: action.payload
              }
        default:
            return state
    }
}
export const getTreeDatas = state => state.treeData;