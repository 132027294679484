import React, { useState } from 'react';
import Filter from './filter'
import './CollapsibleSection.css'; 
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import Main from './main'
import JSON from './input.json'




export default function Container() {

      const [isOpen, setIsOpen] = useState(true);

      const toggleSidebar = () => {
      setIsOpen(!isOpen);
      }
      const [isOpen2, setIsOpen2] = useState(true);

      const toggleSidebar2 = () => {
      setIsOpen2(!isOpen2);
      }
      const [list, setlist] = useState([
        {
          name: "schema 1"
        },
        {
          name: "schema 2"
        },
        {
          name: "schema 3"
        },
        {
          name: "schema 4"
        },
        {
          name: "schema 5"
        },
        {
          name: "schema 6"
        },
        {
          name: "schema 7"
        },
        {
          name: "schema 8"
        },
        {
          name: "schema 9"
        },
        {
          name: "schema 10"
        },
        {
          name: "schema 11"
        },
        {
          name: "schema 12"
        },
        {
          name: "schema 13"
        },
        {
          name: "schema 14"
        },
        {
          name: "schema 15"
        },
        {
          name: "schema 16"
        },
        {
          name: "schema 17"
        },
        {
          name: "schema 18"
        },
        {
          name: "schema 19"
        },
        {
          name: "schema 20"
        },
      ]);


      const [selected, setSelected] = useState('')

      const set_Selected = (input) => {
        setSelected(input)
      }

      const [store, setStore] = useState(JSON)

      const onCheck = (updated) => setStore(updated)

      
      const [ showShemas, setShowShemas] = useState(false)

      const onSave = () => setShowShemas(true)

    

  return (

    <div className="app-container">

    <div className={`sidebar one ${isOpen ? 'open' : 'closed'}`}>
      <div className="toggle-container">
        <div className="toggle-button" onClick={toggleSidebar}>
          {isOpen ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />}
        </div>
      </div>
      <div className="content">
      {isOpen ? <Filter store={store} uptStore={onCheck} onSave={onSave}/> : null}
      </div>
    </div>
    
    {showShemas && <div className={`sidebar two ${isOpen2 ? 'open' : 'closed'}`}>
      <div className="toggle-container">
        <div className="toggle-button" onClick={toggleSidebar2}>
          {isOpen ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />}
        </div>
      </div>
      <div className="content">
      {isOpen2 ? 
      <div>
        {list.map(each => {
          return(
            <div>

              <div style={{padding:'1rem', border: '.5px solid #444'}} onClick={() => set_Selected(each.name)}>{each.name}</div>

            </div>
          )
        })}
      </div> : null}
      </div>
    </div>}

    {selected && <div className={`main_content m-2 ${isOpen ? 'open' : 'closed'} ${isOpen2 ? 'open2' : 'closed2'}`}>

      <Main />

    </div>}
    
    </div>
  )
}


