import React, { Component } from 'react'
import axios from 'axios';
import { navUrl } from './../actions/common_actions'
import { connect } from 'react-redux';
import { getRequest, postRequest } from './../globalhelper/helper'
import { Animated } from "react-animated-css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';


// import './my.css';


class Etds extends Component {

    constructor(props) {
      super(props)

      this.currentState = this.props.state;
      this.userData = this.currentState.login.userData;
    
      this.state = {

          showTable: false,
          etdsData: [],
          targetProcess: "",
          targetData: [],
          sourceProcess: "",
          sourceData: [],
          themeAction: "",
          existingThemes: [],
          showTheme: false,
          newTheme : "",
          formData : {
            branch :this.userData.USR_Organization,
            company: this.userData.USR_Organization,
            module : localStorage.getItem("module"),
            theme : "",
        },
        listProcessOptions : [],
        system : localStorage.getItem("system"),

      }
    }


    navPage = (url) => {
        this.props.navUrl(url)
    }

    
    GetProcess = () => { 

        let module = this.state.formData.module;
        console.log(module);
        if(module !== "" && module !== null && module !== 'default1')
        {
            postRequest( 'api/module/moduleProcess', {
                tenantId : this.userData.tenant_id,
                moduleName : this.state.formData.module
            })
            .then(response => {
                if(response.res.status === "success")
                {
                    this.setState({
                        ...this.state,
                        listProcessOptions : response.res.data,

                    })
                }
                else{
                    this.setState({
                        alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem With Connecting Server</strong></div> </Animated>
                    });
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
                }
            })
            .catch(err => {
                    this.setState({
                        alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem With Connecting Server</strong></div> </Animated>
                    });
                    setTimeout(() => { this.setState({alert : null}); }, 3000);
            })
        }
    }


    // when page load or reload
    componentDidMount() {
        this.GetProcess()
    }


    onTargetSelect = (e) => {

        const VALUE = e.target.value
        const FORMDATA = this.state.formData
        
        
        this.setState({
            
            targetProcess: VALUE
        }, () => {

            const PROCESS = this.state.targetProcess

            getRequest(`api/upload/getKGHeadings?process=${PROCESS}&module=${FORMDATA.module}`)
            .then( res => {

                let ans = JSON.parse(res.res.data)

                // console.log( ans)                
                this.setState( { targetData : ans } )

            })            

        })        
        
    }

    onSourceSelect = (e) => {

        const VALUE = e.target.value
        const FORMDATA = this.state.formData
        const TARGET_PROCESS = this.state.targetProcess


        if( TARGET_PROCESS.length !== 0){


            this.setState({
                
                sourceProcess: VALUE
            }, () => {
                
                getRequest(`api/upload/getKGHeadings?process=${VALUE}&module=${FORMDATA.module}`)
                .then( res => {
    
                    let ans = JSON.parse(res.res.data)
                    
                    // console.log( ans)
                    this.setState( { sourceData : ans})    
                })
                               

                getRequest(`api/upload/checkEtdsTheme?target=${TARGET_PROCESS}&source=${VALUE}&tenant_id=${this.userData.USR_Tenant}`)
                .then( res => {
    
                    let ans = JSON.parse(res.res.data)
    
                    // console.log(ans)
                    this.setState( { existingThemes : ans, showTheme: true})
                })    
                
            })            
            
        }        
        
    }


    
    themeOnChange = (e) => {

        if( this.state.targetProcess.length !== 0 && this.state.sourceProcess.length !== 0) {

            const theme = e.target.value

            if(theme === "Create New"){
    
                this.setState({ themeAction: theme }, () => {
    
                    this.ETDS_Capture()
                })
            } 

            else if ( theme !== ""){

                this.setState({ themeAction: theme }, () => {

                    getRequest(`api/upload/getEtdsMapping?target=${this.state.targetProcess}&source=${this.state.sourceProcess}&tenant_id=${this.userData.USR_Tenant}&theme=${theme}`)
                    .then( res => {
        
                        let ans = JSON.parse(res.res.data)
        
                        console.log(ans, 'existingTheme_Data')
        
                        this.setState( { etdsData : ans, showTable: true} )
                    })
    
                })

            }
        }
    }


    CreateNewTheme = (e) => {

        const VALUE = e.target.value.replace(/ /g, "")

        this.setState( prevState => {

            let etdsData = prevState.etdsData

            for ( let i=0 ; i < prevState.targetData.length; i++) {

                etdsData[i].etdsname = VALUE         
                
                if( i === prevState.targetData.length - 1){
                    
                    return { etdsData, newTheme: VALUE }
                }
            }
        })
    
    }


    ETDS_Capture = () => {

            let targetData = this.state.targetData

            let etds = []

            const DATE = moment(new Date()).format('DD-MM-YYYY')

            if( this.state.themeAction === "Create New") {

                let sourceProcess = this.state.sourceProcess
                let targetProcess = this.state.targetProcess

                
                for( let i=0 ; i < targetData.length; i++) {  
                
                    etds[i] = {
        
                    forprdfrom: DATE,
                    forprdto: " ",
                    etdsname: " ",
                    lineid: "",
                    totalline: "",
                    sourceprocesscode: sourceProcess,
                    sourcefieldname : " ",
                    targetprocesscode: targetProcess,
                    targetfieldname : targetData[i].short_text,
                    targetfieldfunction: " ",
                    version: "0"
        
                    }
        
                    if( i === targetData.length -1 ){  
        
                    this.setState({ etdsData: etds, showTable: true }, 
                        () => {
        
                            console.log(this.state.etdsData, "etdsdata")
                            
                        })
                    }    
                }

            }          

    }


    sourceMapOnChange = (index , e) => {

        const VALUE = e.target.value
  
       this.setState(prevState => {

           let etdsData = prevState.etdsData

           etdsData[index].sourcefieldname = VALUE

           return { etdsData }

       }, () => {

           console.log(this.state.etdsData)

       })

    }


    constantOnChange = (index, e) => {

        let VALUE = e.target.value.replace(/ /g, "")

        this.setState(prevState => {

            let etdsData = prevState.etdsData

            if( VALUE === "") {
                
                VALUE = " "
            }

            etdsData[index].targetfieldfunction = VALUE

            return { etdsData }
        }, ()=> console.log(this.state.etdsData[index]))
    }
  
  
    totalLineOnChange = (e) => {

        const VALUE = e.target.value.replace(/ /g, "")

        const LENGTH = this.state.targetData.length

        this.setState(prevState => {

            let etdsData = prevState.etdsData

            for ( let i=0 ; i < LENGTH; i++) {

                etdsData[i].totalline = VALUE

                if( i === LENGTH -1 ) {

                    return { etdsData }
                }
            }

        })
  
    }
  
  
    lineIdOnChange = (e) => {

        const VALUE = e.target.value.replace(/ /g, "")

        const LENGTH = this.state.targetData.length

        this.setState(prevState => {

            let etdsData = prevState.etdsData

            for ( let i=0 ; i < LENGTH; i++) {

                etdsData[i].lineid = VALUE

                if( i === LENGTH -1) {

                    return { etdsData }
                }
            }

        }) 
         
    }

    onSubmit = () => {

        const newTheme = this.state.newTheme
        const existingThemes = this.state.existingThemes

        for( let i = 0; i < existingThemes.length; i++){

            if( newTheme === existingThemes[i].etdsname ){

                return alert(`"${newTheme}" is already exist please enter new unique theme name.`)    
            }            
        }

        const themeAction = this.state.themeAction

        if( themeAction === "Create New"){

            const ETDS_DATA = JSON.stringify(this.state.etdsData)
    
            postRequest(`api/upload/etdsUpload?target=${this.state.targetProcess}&source=${this.state.sourceProcess}&tenant_id=${this.userData.USR_Tenant}&version=${this.state.etdsData[0].version}&theme=${this.state.newTheme}`, { data: ETDS_DATA })
            .then( res => {

                console.log(res.res.data)
            })
        }

        else if ( themeAction !== "" ){

            this.setState( prevState => {

                let etdsData = prevState.etdsData

                for(let j=0; j < etdsData.length ; j++ )  {

                    etdsData[j].version = String(Number(etdsData[j].version) + 1 )

                    if( j === etdsData.length -1 ){

                        return{ etdsData }
                    }
                }
                
            }, () => {

                console.log(this.state.etdsData)

                const ETDS_DATA = JSON.stringify(this.state.etdsData)
    
                postRequest(`api/upload/etdsUpload?target=${this.state.targetProcess}&source=${this.state.sourceProcess}&tenant_id=${this.userData.USR_Tenant}&version=${this.state.etdsData[0].version}`, { data: ETDS_DATA })
                .then( res => {

                    console.log(res.res.data)
                })
            })
            
        }
    }





    render() {

        return ( 
            <div>

                <div className='row' >

                    <div className='col-md-3'>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                        <InputLabel id="target">Select a Target</InputLabel>
                        <Select labelId="targetProcess" id="targetProcess" name="targetProcess" onChange={this.onTargetSelect} value={this.state.targetProcess} label="Select a Target" disabled={this.state.showTable}>
                        {/* <MenuItem value="" disabled >Select a Target</MenuItem> */}
                                { this.state.listProcessOptions.map( (each) => <MenuItem key={each.process_code} value={each.process_code}>{each.short_text} ({each.process_code})</MenuItem> )}
                        </Select>
                    </FormControl>
                    </div>

                    <div className='col-md-3'>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                        <InputLabel id="source">Select a Source</InputLabel>
                        <Select labelId="sourceProcess" id="sourceProcess" name="sourceProcess" onChange={this.onSourceSelect} value={this.state.sourceProcess} label="Select a Source" disabled={this.state.showTable} >
                        {/* <MenuItem value="" disabled >Select a Source</MenuItem> */}
                                { this.state.listProcessOptions.map( (each) => <MenuItem key={each.process_code} value={each.process_code}>{each.short_text} ({each.process_code})</MenuItem> )}
                        </Select>
                    </FormControl>
                    </div>


                    { this.state.showTheme ?

                    <div className='col-md-3'>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                        <InputLabel id="theme">Select a Theme</InputLabel>
                        <Select labelId="theme" id="theme" name="theme" onChange={this.themeOnChange} value={this.state.themeAction} label="Select a Theme" disabled={this.state.showTable} >
                        <MenuItem value="Create New" >Create New</MenuItem>
                        { this.state.existingThemes.map( (each) => <MenuItem key={each.etdsname} value={each.etdsname}>{each.etdsname}</MenuItem> )}
                        </Select>
                    </FormControl>
                    </div>

                    : null
                    }


                    {  this.state.themeAction === "Create New" ?

                        <div className='col-md-3' style={{ marginTop: '2.2rem' }}>     
                        {/* <TextField id="standard-basic" label="Theme Name" variant="standard" placeholder="Theme Name" onChange={this.CreateNewTheme} />     */}
                        <input type="text" id="theme" name="theme" placeholder="Theme Name"  onChange={this.CreateNewTheme} />          
                        </div>
                        :
                        null
                    }
                
                </div>


                { this.state.etdsData.length !==0 && this.state.showTable  ?

                <div style={{ marginTop:'3rem'}}>
                    <div className='row'>

                        <div className='col-md-1'></div>

                        <div className='col-md-9'>

                        <div className='scroll-table'>

                            <table style={{width: '100%'}}>

                            <thead>
                                <tr>
                                <th>No</th>
                                <th>Target</th>
                                <th>Source</th>
                                <th>Constant Value / Derive Function</th>
                                </tr>
                            </thead>


                            <tbody>
                            
                                { this.state.etdsData.map( (each, i) => {
                                    return(
                                        
                                        <tr key={each.targetfieldname} >

                                            <td>{i+1}</td>
                                            <td>{each.targetfieldname}</td>

                                            <td>
                                                <select 
                                                value={each.sourcefieldname}
                                                onChange={(e) => this.sourceMapOnChange(i, e)}>
                                                <option value=" "></option>
                                                {
                                                this.state.sourceData.map( (one) =>  <option key={one.short_text} value={one.short_text}>{one.short_text}</option> )
                                                }  
                                                </select>
                                            </td>

                                            <td>
                                            <input type="text" id='constant' name='constant' key={i} value={each.targetfieldfunction} onChange={(e) => this.constantOnChange(i, e)} />
                                            </td>

                                        </tr>
                                    )
                                    })
                                }        
                            </tbody>            

                            </table>
                        </div>
                        </div>
                    </div>


                    <br />
                    <div style={{ display: "block",  marginTop:'1rem'}} align="center">
                        <label htmlFor="TotalLine">Total Line - </label>
                        <input type="text" id="TotalLine" name="TotalLine" placeholder="Total Line" key="TotalLine" value={this.state.etdsData[0].totalline} onChange={this.totalLineOnChange} style={{ marginRight: "1rem"}}/>
                        <label htmlFor="LineID">Line ID - </label>
                        <input type="text" id="LineID" name="LineID" placeholder="Line ID" key="LineID" value={this.state.etdsData[0].lineid} onChange={this.lineIdOnChange}/>                        
                    </div>
                    <br />

                    <div style={{ display: "block", marginTop:'1rem'}} align="center">
                        <button onClick={this.onSubmit} className='btn btn-success'
                        disabled={ ( this.state.themeAction === "Create New" && this.state.newTheme.length === 0 ) || this.state.etdsData[0].totalline.length === 0 || this.state.etdsData[0].lineid.length === 0 }
                        >Submit</button>
                    </div>


                </div>

                :
                null

                }

            </div>

        )
    }

}


function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(
    mapStateToProps,
    { navUrl }
)(Etds);