import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import GetAppIcon from '@material-ui/icons/GetApp';
import { convertNumberIntoINR } from '../globalhelper/helper';


function convertArrayOfObjectsToCSV(array, columns) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    console.log(columns);
    const keys = columns.map(column => column.name);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            if(item[key] === 0) {

                result += item[key]

            } else {
                
                if(typeof item[key] === "string" && item[key].includes("#")){
                    console.log(item[key], '"#" found')

                    item[key] = item[key].replace("#", "")                                        
                } 
                
                // result += item[key] ? item[key] : " ";
                result += item[key] ? (typeof item[key] === "string" ? item[key].replace(/\,/g, ';') : item[key]) : " ";
            }

            
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}


function downloadCSV(array, filename, columns) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array, columns);
    if (csv == null) return;


    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}

const Export = ({ onExport }) => <button className='btn btn-primary' onClick={e => onExport(e.target.value)}> <GetAppIcon /> CSV</button>;

function ActionsMemo(props){
    return (React.useMemo(() => <Export onExport={() => downloadCSV(props.data, props.fileName, props.columns)} />, []))
}

const paginationComponentOptions = {
    selectAllRowsItem : true
}

class DataTable2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns : this.props.columns,
            data : this.props.data,
        }
    }

    componentDidMount = () => {
        console.log("from componentDidMount DataTable2")
        console.log(this.state.columns, "cl1")
        let {columns} = this.state;
        columns.map((column, index) => {
            column.wrap = column.wrap ? column.wrap : true;
            column.compact = true;
            column.row = (row) => row[column.name] ? row[column.name] : " ";
            if(column.isNumber) {
                column.row = (row) => <p className='number-format' > {row[column.name]} </p>
                column.right = true;
            }

            if(column.isAmount)
            {
                column.row = (row) => <p className='number-format' > { convertNumberIntoINR(row[column.name])} </p>
                column.right = true;
            }
            if(index === columns.length - 1) {
                this.setState({
                    columns : columns
                }, () => {
                    console.log(this.state.columns, "cl2")
                })
            }

        })
    }

    render() { 
        return (
            <DataTable title={this.props.title} fixedHeader fixedHeaderScrollHeight="65vh" columns={this.state.columns} data={this.props.data} pagination paginationComponentOptions={paginationComponentOptions}  progressPending={this.props.loading} actions={ this.props.data.length > 0 ? <ActionsMemo data={this.props.data} fileName={this.props.title} striped={true} highlightOnHover={true} pointerOnHover={true} dense={true} columns={this.state.columns} /> : null} />
        );
    }
}
 
export default DataTable2;