import {
    combineReducers
} from 'redux'
import treeReducer, * as fromTree from './treeReducer'
import tree_Reducer, * as from_Tree from './tree_Reducer'
import VR2_Reducer from  './vr2_reducer'
import fileDataReducer from "../redux/FileData/fileDataReducer"
import FormInputs from "../redux/FormInputs/formReducer"
import selectAppReducer from "../redux/SelectApp/saReducer"
import selectProcessReducer from "../redux/SelectProcess/spReducer"
import csvUploadReducer from "../redux/api/CsvUpload/csvUploadReducer"
import dialogflowReducer from "../redux/api/Dialogflow/dialogflowReducer"
import downloadTemplateReducer from "../redux/api/DownloadTemplate/downloadTemplateReducer"
import getTemplateReducer from "../redux/api/GetTemplate/getTemplateReducer"
import processArrayReducer from "../redux/api/ProcessArray/processArrayReducer"
import processDefnReducer from "../redux/api/ProcessDefn/processDefnReducer"
import common from './common_reduceres'
import componentsReducer from './components'
import Dynamic_Forms_Reducer from './dynamic_formsReducer'
import interactions from './interactions_reducers'
import login from './login_reduxres'
import popupReducer from './popupReducer'
import dateFormReducer from "../redux/DateForm/formReducer";
import dataLoadReducer from "../redux/DataLoad/dlReducer";
import BillsReducers from "../redux/BillData/BillDataReducer";
import AlreadyPaidBillsByVendorIDReducer from "../redux/AlreadyPaidBillsByVendorID/AlreadyPaidBillsByVendorIDReducer";


export default combineReducers({
    treeReducer,
    tree_Reducer,
    VR2_Reducer,
    Dynamic_Forms_Reducer,
    popupReducer,
    login,
    common,
    interactions,
    components: componentsReducer,
    selectApp: selectAppReducer,
    selectProcess: selectProcessReducer,
    fileData: fileDataReducer,
    dateForm: dateFormReducer,
    dataLoad: dataLoadReducer,
    dialogflow: dialogflowReducer,
    processArray: processArrayReducer,
    processDefn: processDefnReducer,
    getTemplate: getTemplateReducer,
    downloadTemplate: downloadTemplateReducer,
    csvUpload: csvUploadReducer,
    chatbotFormInputs: FormInputs,
    billsByOrgID:BillsReducers,
    AlreadyPaidBillsByVendorID:AlreadyPaidBillsByVendorIDReducer
})