import { CHANGE_PROP, ADD_COMPONENT, CHANGE_VALUE_JSON } from '../constants/action-types';
import { postRequest } from '../globalhelper/helper'
import { requestCurrentState } from './login_actions';


export function onChangeComponentProp(components) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_PROP,
            payload: components.components
        })
    }
}


export function addComponent(component) {
    console.log(component);
    return (dispatch) => {
        dispatch({
            type: ADD_COMPONENT,
            payload: component
        })
    }
}


export function changeValueJSON(json) {
    console.log(json);
    return (dispatch) => {
        dispatch({
            type: CHANGE_VALUE_JSON,
            payload: json
        })
    }
}


export function verifyLogin() {
    return (dispatch) => {
        dispatch(requestCurrentState());
        return postRequest("api/user/verifyLogin").then((data) => {
            if (data) {
                if (data.type === "success") {
                    // console.log(data)
                    return data.res;
                }
            }
        })
    }
}