// import React, { Component, Fragment } from 'react'
// import {Form, Button} from 'react-bootstrap';
// import {connect} from 'react-redux'
// import { getRequest} from '../../globalhelper/helper';
// import '../CI/COD.css'
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

// export class VendorMaster_Search extends Component {
//     constructor(props) {
//       super(props)
    
//       this.state = {
//         showtable: false,
//          formData: {
//             vendorname: '',
//             vendorid: '',
//             vendortype: '',
//             status: ''
//          },
//          tableData: [],
//          dropdown_vendorname: [],
//          dropdown_vendorid: [],
//          dropdown_vendortype: [],
//       }
//     }

//     componentDidMount(){
//         localStorage.setItem("pageName", "Search VendorMaster")

//         getRequest(`api/edureka/getVM1`).then(res => {
//             if(res.res.status === 'success'){
//                 let temp = JSON.parse(res.res.data)
//                 console.log(temp)
//                 let temp1 = temp.map(value => value['Vendor Name'])
//                 let vendorname = [... new Set(temp1)]
//                 console.log(vendorname)
//                 let temp3 = temp.map(value => value['Vendor Type'])
//                 let tem = temp3.filter(value=> value)
//                 let vendortype = [... new Set(tem)]

//                 let vid1 = temp.map(value=> value["Vendor ID"])
//                 let vid2 = [... new Set(vid1)]
    
//                 this.setState({
//                     ...this.state,
//                     dropdown_vendorname: vendorname,
//                     dropdown_vendorid: vid2,
//                     dropdown_vendortype: vendortype
//                 })
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     handleChange = (e) => {
//         const NAME = e.target.name, VALUE = e.target.value

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [NAME]: VALUE
//             }
//         })
//     }

//     handleVendor = (i) => {
//         console.log(i)
//         const vendorid = i['Vendor ID']

//         getRequest(`api/edureka/getVMUpdatedJSON?vendorid=${vendorid}`).then(res => {
//             if(res.res.status === 'success'){
//                 console.log(res.res.data)

//                 this.setState({ redirect : true, SearchJSON : res.res.data })                
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     handleSearch = () => {

//         const vendorname = this.state.formData.vendorname
//         const vendortype = this.state.formData.vendortype
//         const status = this.state.formData.status
//         const vendorid = this.state.formData.vendorid

//         getRequest(`api/edureka/getVMData?vendorname=${vendorname}&vendortype=${vendortype}&status=${status}&vendorid=${vendorid}`).then(res => {
//             if(res.res.status === 'success'){
//                 let temp = JSON.parse(res.res.data)
//                 console.log(temp)
    
//                 this.setState({
//                     ...this.state,
//                     tableData: temp,
//                     showtable: true
//                 })
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     handleReset = () => {
//         this.setState({
//             ...this.state,
//             showtable: false,
//             formData: {
//                 vendorname: '',
//                 vendorid: '',
//                 vendortype: '',
//                 status: ''
//              }
//         })
//     }

//     onBack = () => {
//         this.setState({
//             redirect: false,
//             SearchJSON: []
//         })
//     }

//   render() {

//     if(this.state.redirect){
//         return(
//             <VR2_Forms
//             screenName = {"VendorMaster"}
//             screenAction = {"edit"}  
//             From_SearchJSON = {this.state.SearchJSON} 
//             onBack = {this.onBack}
//             />        
//         )
//     }
//     else return (

//       <Fragment>
//         <div className='header_div'>Search Vendor Master</div>
//         <br/>

//         <Form style={{display:'flex', flexDirection:'column'}}>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
//                 <select className='col-lg-3' name='vendorname' value={this.state.formData.vendorname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendorname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
//                 <input className="col-lg-3" value={this.state.formData.vendorname} type='text' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorname' onChange={this.handleChange}></input>
//             </div> */}

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor ID:</div>
//                 <select className='col-lg-3' name='vendorid' value={this.state.formData.vendorid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendorid.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Type:</div>
//                 <select className='col-lg-3' name='vendortype' value={this.state.formData.vendortype} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendortype.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Status:</div>
//                 <select className='col-lg-3' name='status' value={this.state.formData.status} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     <option value='Active'>Active</option>
//                     <option value='Inactive'>Inactive</option>
//                     <option value='Not Released'>Not Released</option>
//                     <option value='Rejected'>Rejected</option>
//                     <option value='Void'>Void</option>
//                     <option value='Waiting For Approval'>Waiting For Approval</option>
//                 </select>
//             </div>

//             <div style={{display:'inline-block'}}>
//                 <Button style={{marginLeft:500}} variant='primary' onClick={this.handleSearch}>Search</Button>
//                 <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
//             </div>

//         </Form>

//         <br/>

//         {this.state.showtable && this.state.tableData.length >0 ?
//         <div>
//             <table border='1' id='table_styling'>
//                 <thead>
//                     <tr>
//                         <th>Vendor ID</th>
//                         <th>Vendor Name</th>
//                         <th>Member Type</th>
//                         <th>GST Treatment</th>
//                         <th>State</th>
//                         <th>Status</th>
//                         <th>Vendor Group</th>
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {this.state.tableData.sort((a,b)=>a['Vendor ID'].localeCompare(b['Vendor ID'])).map((item, index) => {
//                         return (
//                             <tr key={index}>
//                                 <td><Button variant='link' onClick={() => this.handleVendor(item)}>{item['Vendor ID']}</Button></td>
//                                 <td>{item['Vendor Name']}</td>
//                                 <td>{item['Member Type']}</td>
//                                 <td>{item['GST Treatment']}</td>
//                                 <td>{item['State']}</td>
//                                 <td>{item['Status']}</td>
//                                 <td>{item['Vendor Group']}</td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div>: null}
//       </Fragment>
//     )
//   }
// }

// function mapStateToProps(state){
//     return {
//         userData: state.login.userData
//     }
// }

// export default connect(mapStateToProps)(VendorMaster_Search)

 

 
import React, { Component, Fragment } from 'react'
import {Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import { getRequest} from '../../globalhelper/helper';
import '../CI/COD.css'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import ReactSelect from 'react-select';

export class VendorMaster_Search extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        showtable: false,
         formData: {
            vendorname: '',
            vendorid: '',
            vendortype: '',
            status: ''
         },
         tableData: [],
         dropdown_vendorname: [],
         dropdown_vendorid: [],
         dropdown_vendortype: [],
      }
    }

    componentDidMount(){
        localStorage.setItem("pageName", "Search VendorMaster")

        getRequest(`api/edureka/getVM1`).then(res => {
            if(res.res.status === 'success'){
                let temp = JSON.parse(res.res.data)
                console.log(temp)
                let temp1 = temp.map(value => value['Vendor Name'])
                let vendorname = [... new Set(temp1)]
                console.log(vendorname)
                let temp3 = temp.map(value => value['Vendor Type'])
                let tem = temp3.filter(value=> value)
                let vendortype = [... new Set(tem)]

                let vid1 = temp.map(value=> value["Vendor ID"])
                let vid2 = [... new Set(vid1)]
    
                this.setState({
                    ...this.state,
                    dropdown_vendorname: vendorname,
                    dropdown_vendorid: vid2,
                    dropdown_vendortype: vendortype
                })
            } else {
                console.log("No Data")
            }
        })
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleVendor = (i) => {
        console.log(i)
        const vendorid = i['Vendor ID']

        getRequest(`api/edureka/getVMUpdatedJSON?vendorid=${vendorid}`).then(res => {
            if(res.res.status === 'success'){
                console.log(res.res.data)

                this.setState({ redirect : true, SearchJSON : res.res.data })                
            } else {
                console.log("No Data")
            }
        })
    }

    handleSearch = () => {

        const vendorname = this.state.formData.vendorname
        const vendortype = this.state.formData.vendortype
        const status = this.state.formData.status
        const vendorid = this.state.formData.vendorid

        getRequest(`api/edureka/getVMData?vendorname=${vendorname}&vendortype=${vendortype}&status=${status}&vendorid=${vendorid}`).then(res => {
            if(res.res.status === 'success'){
                let temp = JSON.parse(res.res.data)
                console.log(temp)
    
                this.setState({
                    ...this.state,
                    tableData: temp,
                    showtable: true
                })
            } else {
                console.log("No Data")
            }
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            showtable: false,
            formData: {
                vendorname: '',
                vendorid: '',
                vendortype: '',
                status: ''
             }
        })
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
            screenName = {"VendorMaster"}
            screenAction = {"edit"}  
            From_SearchJSON = {this.state.SearchJSON} 
            onBack = {this.onBack}
            />        
        )
    }
    else return (

      <Fragment>
        <div className='header_div'>Search Vendor Master</div>
        <br/>

        <Form style={{display:'flex', flexDirection:'column'}}>

           {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
                <select className='col-lg-3' name='vendorname' value={this.state.formData.vendorname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_vendorname.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor ID:</div>
                <select className='col-lg-3' name='vendorid' value={this.state.formData.vendorid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_vendorid.sort().map(item => <option value={item}>{item}</option>)}
                </select>
            </div>*/}

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Vendor Name:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='vendorname'
                    value={this.state.dropdown_vendorname.find(option => option.value === this.state.formData.vendorname)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'vendorname', value: selectedOption.value } })}
                    options={this.state.dropdown_vendorname.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Vendor ID:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='vendorid'
                    value={this.state.dropdown_vendorid.find(option => option.value === this.state.formData.vendorid)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'vendorid', value: selectedOption.value } })}
                    options={this.state.dropdown_vendorid.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Vendor Type:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='vendortype'
                    value={this.state.dropdown_vendortype.find(option => option.value === this.state.formData.vendortype)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'vendortype', value: selectedOption.value } })}
                    options={this.state.dropdown_vendortype.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Status:</div>
                <select className='col-lg-3' name='status' value={this.state.formData.status} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    <option value='Not Released'>Not Released</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='Void'>Void</option>
                    <option value='Waiting For Approval'>Waiting For Approval</option>
                </select>
            </div>

            <div style={{display:'inline-block'}}>
                <Button style={{marginLeft:500}} variant='primary' onClick={this.handleSearch}>Search</Button>
                <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>

        </Form>

        <br/>

        {this.state.showtable && this.state.tableData.length >0 ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Vendor ID</th>
                        <th>Vendor Name</th>
                        <th>Member Type</th>
                        <th>GST Treatment</th>
                        <th>State</th>
                        <th>Status</th>
                        <th>Vendor Group</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.sort((a,b)=>a['Vendor ID'].localeCompare(b['Vendor ID'])).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td><Button variant='link' onClick={() => this.handleVendor(item)}>{item['Vendor ID']}</Button></td>
                                <td>{item['Vendor Name']}</td>
                                <td>{item['Member Type']}</td>
                                <td>{item['GST Treatment']}</td>
                                <td>{item['State']}</td>
                                <td>{item['Status']}</td>
                                <td>{item['Vendor Group']}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>: null}
      </Fragment>
    )
  }
}

function mapStateToProps(state){
    return {
        userData: state.login.userData
    }
}

export default connect(mapStateToProps)(VendorMaster_Search)

 

 