import {
  LOGOUT,
  Initiate_Login,
  REQUESTCURRENTSTATE,
  SUCCESSLOGIN,
  SAVETOKEN,
  REQUEST_AUTH_KEY,
  SET_AUTH_KEY,
  SET_NEW_TOKEN
} from "../constants/action-types";

export default function login(state = [], action) {
  switch (action.type) {
    case LOGOUT:
      console.log("Logging out...")
      return {
        user: {
            login: false
          },
          userData: {},
          auth: ''
      }
      case Initiate_Login:
        return action.data;
      case REQUESTCURRENTSTATE:
        return state;
      case REQUEST_AUTH_KEY:
        console.log(state)
        return state.login;
      case SET_AUTH_KEY:
        return {
          ...state,
          auth: action.authkey
        };
      case SET_NEW_TOKEN:
        return {
          ...state,
          auth: action.payload
        };
      case SAVETOKEN:
        return {
          ...state,
          forgetPasswordToken: action.forgetPasswordToken
        };
      case SUCCESSLOGIN:
        localStorage.setItem("authkey", action.payload)
        return {
          ...state,
          userData: action.payload,
            auth: action.authkey,
            user: {
              login: true
            }
        };
      default:
        return state
  }
}

// export default login;