import React, { Component, Fragment } from 'react'
import {Form, Button, Card, Accordion} from 'react-bootstrap';
import {connect} from 'react-redux'
import { getHumanReadableDate, getLast60Days, getRequest, getStandardDate } from '../../globalhelper/helper';
import VR2_Forms from '../VR2_Form_Rendering/displayContainer';

export class AreaManagerHistory extends Component {
    constructor(props) {
      super(props)

      this.current = this.props.state
    
      this.state = {
        userAssignedStations: [],
        max_date: getStandardDate(new Date()),
        min_date : getLast60Days(new Date()),
         formData: {
            date: '',
            user: this.current.login.userData.USR_Email,
            staffplanned: '',
            staffpresent: '',
            deliveryassociatesplanned: '',
            deliveryassociatespresent: '',
            attendancemarkedda: '',
            dasnotreportedforwork: '',
            backendstaffplanned: '',
            backendstaffpresent: '',
            attendancemarkedbackendstaff: '',
            backendstaffnotreportedforwork: '',
            loadersplanned: '',
            loaderspresent: '',
            attendancemarkedloaders: '',
            loadersnotreportedforwork: '',
            vansplanned: '',
            vanspresent: '',
            vansreportedlate: '',
            vansnotpresent: '',
            numberofvanshired: '',
            vanshiredaction: '',
            totalnoofshipmentsreceived: '',
            noofshipmentsphysicallyreceived: '',
            shipmentsaspersystemdata: '',
            discrepanciesreceivedexcess: '',
            discrepanciesnotreceived: '',
            incomingshipmentsplanned: '',
            incomingshipmentsactual: '',
            varianceinplannedvsactual: '',
            incomingshipmentweight: '',
            noofinscannedshipments: '',
            yesterdayundeliveredsentoutfordelivery: '',
            totalshipmentsoutfordelivery: '',
            noofcodshipmentsoutfordelivery: '',
            totalcodcashvalue: '',
            shipmentsonholdatofficeonclientsinstruction: '',
            heldatofficenottakenfordelivery: '',
            totalshipmentsdelivered: '',
            totalcodshipmentsdelivered: '',
            codamountcollected: '',
            codamountshortreceived: '',
            lostormissingnoofshipments: '',
            noofshipmentsnotreceivedathub: '',
            endofdayholdatofficestock: '',
            totaloffrolestaffnotreportedforwork: '',
            reasonforstaffshortageandactiontaken: '',
            vansnotreported: '',
            reasonforvansnotreportingandactiontaken: '',
            varianceinvolume: '',
            clientwhohasnotmettarget: '',
            plantomeettargetshortfall: ''
         }
      }
    }

    // componentDidMount(){
    //     getRequest(`api/ciportal/getAssignedStations`).then(res => {
    //         console.log(res.res.data)
    //         if(res.res.status === "success" && res.res.data.length){
    //             this.setState({
    //                 ...this.state,
    //                 userAssignedStations: res.res.data
    //             })
    //         }
    //     })
    // }

    // handleBranchCodeChange = (e) => {
    //     const branchcode = e.target.value

    //     const data = this.state.userAssignedStations

    //     data.forEach(item => {
    //         if(item.branchcode === branchcode){
    //             this.setState({
    //                 ...this.state,
    //                 formData: {
    //                     ...this.state.formData,
    //                     branch: branchcode,
    //                     branchname: item.branchname,
    //                     areaname: item.areaname
    //                 }
    //             })
    //         }
    //     })
    // }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleSearch = () => {
        const date = getHumanReadableDate(this.state.formData.date)
            // if(this.state.formData.report === 'AM' && this.state.formData.branch && this.state.formData.date && this.state.formData.branchname){
            //     getRequest(`api/ciportal/getAMUpdatedJSON?branchcode=${this.state.formData.branch}&date=${date}&branchname=${this.state.formData.branchname}`).then(res => {
            //         if(res.res.data && res.res.data.length){
            //             this.setState({
            //                 NoData: '',
            //                 AMJSON:true,
            //                 AMData: res.res.data
            //             })
            //         } else {
            //             this.setState({
            //                 NoData: 'No Data'
            //             })
            //         }
            //     })
            // } else if (this.state.formData.report === 'PM' && this.state.formData.branch && this.state.formData.date && this.state.formData.branchname){
            //     getRequest(`api/ciportal/getPMUpdatedJSON?branchcode=${this.state.formData.branch}&date=${date}&branchname=${this.state.formData.branchname}`).then(res => {
            //         if(res.res.data && res.res.data.length){
            //             this.setState({
            //                 NoData: '',
            //                 PMJSON:true,
            //                 PMData: res.res.data
            //             })
            //         } else {
            //             this.setState({
            //                 NoData: 'No Data'
            //             })
            //         }
            //     })
            // } else if (this.state.formData.report === 'EXC' && this.state.formData.branch && this.state.formData.date && this.state.formData.branchname){
            //     getRequest(`api/ciportal/getEXCUpdatedJSON?branchcode=${this.state.formData.branch}&date=${date}&branchname=${this.state.formData.branchname}`).then(res => {
            //         if(res.res.data && res.res.data.length){
            //             this.setState({
            //                 NoData: '',
            //                 EXCJSON:true,
            //                 EXCData: res.res.data
            //             })
            //         } else {
            //             this.setState({
            //                 NoData: 'No Data'
            //             })
            //         }
            //     })
            // } 
            if (this.state.formData.date) {
                getRequest(`api/ciportal/getAreaManagerUpdatedJSON?date=${this.state.formData.date}&user=${this.state.formData.user}`).then(res => {    
                    console.log(JSON.parse(res.res.data))
                    let data = JSON.parse(res.res.data)               
                    if(data && data.length){
                        let value = JSON.parse(res.res.data)
                        this.setState({
                            NoData: '',
                            AMRJSON:true,
                            AMRData: value,
                            formData: {
                                ...this.state.formData,
                                staffplanned: value[0]['staffplanned'],
                                staffpresent: value[0]['staffpresent'],
                                deliveryassociatesplanned: value[0]['deliveryassociatesplanned'],
                                deliveryassociatespresent: value[0]['deliveryassociatespresent'],
                                attendancemarkedda: value[0]['attendancemarkedda'],
                                dasnotreportedforwork: value[0][`dasnotreportedforwork`],
                                backendstaffplanned: value[0]['backendstaffplanned'],
                                backendstaffpresent: value[0]['backendstaffpresent'],
                                attendancemarkedbackendstaff: value[0]['attendancemarkedbackendstaff'],
                                backendstaffnotreportedforwork: value[0]['backendstaffnotreportedforwork'],
                                loadersplanned: value[0]['loadersplanned'],
                                loaderspresent: value[0]['loaderspresent'],
                                attendancemarkedloaders: value[0]['attendancemarkedloaders'],
                                loadersnotreportedforwork: value[0]['loadersnotreportedforwork'],
                                vansplanned: value[0]['vansplanned'],
                                vanspresent: value[0]['vanspresent'],
                                vansreportedlate: value[0]['vansreportedlate'],
                                vansnotpresent: value[0]['vansnotpresent'],
                                numberofvanshired: value[0]['numberofvanshired'],
                                vanshiredaction: value[0]['whyvanswerehiredjustifyactionandcost'],
                                totalnoofshipmentsreceived: value[0]['totalnumberofshipmentsreceived'],
                                noofshipmentsphysicallyreceived: value[0]['numberofshipmentsphysicallyreceived'],
                                shipmentsaspersystemdata: value[0]['numberofshipmentsaspersystemdata'],
                                discrepanciesreceivedexcess: value[0]['discrepanciesreceivedexcessagainstdata'],
                                discrepanciesnotreceived: value[0]['discrepanciesnotreceivedagainstdata'],
                                incomingshipmentsplanned: value[0]['incomingshipmentsplanned'],
                                incomingshipmentsactual: value[0]['incomingshipmentsactual'],
                                varianceinplannedvsactual: value[0]['varianceinplannedvsactual'],
                                incomingshipmentweight: value[0]['incomingshipmentweight'],
                                noofinscannedshipments: value[0]['numberofinscannedshipments'],
                                yesterdayundeliveredsentoutfordelivery: value[0]['yesterdayundeliveredsentoutfordelivery'],
                                totalshipmentsoutfordelivery: value[0]['totalshipmentsoutfordelivery'],
                                noofcodshipmentsoutfordelivery: value[0]['numberofcodshipmentsoutfordelivery'],
                                totalcodcashvalue: value[0]['totalcodcashvalueoutfordelivery'],
                                shipmentsonholdatofficeonclientsinstruction: value[0][`shipmentsonholdatofficeonclientsinstruction`],
                                heldatofficenottakenfordelivery: value[0]['heldatofficenottakenoutfordelivery'],
                                totalshipmentsdelivered: value[0]['totalshipmentsdelivered'],
                                totalcodshipmentsdelivered: value[0]['numberofcodshipmentsdelivered'],
                                codamountcollected: value[0]['codamountcollected'],
                                codamountshortreceived: value[0]['codamountshortreceived'],
                                lostormissingnoofshipments: value[0]['lostormissingnumberofshipments'],
                                noofshipmentsnotreceivedathub: value[0]['numberofshipmentsnotdeliveredreceivedathubbts'],
                                endofdayholdatofficestock: value[0]['endofdayholdatofficestock'],
                                totaloffrolestaffnotreportedforwork: value[0]['totaloffrolestaffnotreportedforwork'],
                                reasonforstaffshortageandactiontaken: value[0]['reasonforstaffshortageandactiontaken'],
                                vansnotreported: value[0]['vansnotreported'],
                                reasonforvansnotreportingandactiontaken: value[0]['reasonforvansnotreportingandactiontaken'],
                                varianceinvolume: value[0]['varianceinvolumeplannedvsactual'],
                                clientwhohasnotmettarget: value[0]['clientwhohasnotmetvolumetarget'],
                                plantomeettargetshortfall: value[0]['plantomeettargetshortfall']
                            }
                        })
                    } else {
                        this.setState({
                            NoData: 'No Data'
                        })
                    }
                })
            }
    }
  render() {

    if(this.state.AMRJSON){
        return (
            <Fragment>
        <br/>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Area Manager Report</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Date: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='date' name='date' value={this.state.formData.date} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>User: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='text' name='user' value={this.state.formData.user} disabled></input>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Staff Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Staff Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='staffplanned' value={this.state.formData.staffplanned} onChange={this.handleChange} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Staff Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='text' name='staffpresent' value={this.state.formData.staffpresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Delivery Associates (CIA) Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='deliveryassociatesplanned' value={this.state.formData.deliveryassociatesplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Delivery Associates (CIA) Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='deliveryassociatespresent' value={this.state.formData.deliveryassociatespresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Attendance Marked - DA: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='attendancemarkedda' value={this.state.formData.attendancemarkedda} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>DA's Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='dasnotreportedforwork' value={this.state.formData.dasnotreportedforwork} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Backend Staff Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='backendstaffplanned' value={this.state.formData.backendstaffplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Backend Staff Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='backendstaffpresent' value={this.state.formData.backendstaffpresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Attendance Marked - Backend Staff: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='attendancemarkedbackendstaff' value={this.state.formData.attendancemarkedbackendstaff} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Backend Staff Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='backendstaffnotreportedforwork' value={this.state.formData.backendstaffnotreportedforwork} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Loaders Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='loadersplanned' value={this.state.formData.loadersplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Loaders Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='loaderspresent' value={this.state.formData.loaderspresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Attendance Marked - Loaders: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='attendancemarkedloaders' value={this.state.formData.attendancemarkedloaders} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Loaders Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='loadersnotreportedforwork' value={this.state.formData.loadersnotreportedforwork} disabled></input>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Van Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Vans Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansplanned' value={this.state.formData.vansplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vanspresent' value={this.state.formData.vanspresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Reported Late: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansreportedlate' value={this.state.formData.vansreportedlate} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Not Present: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansnotpresent' value={this.state.formData.vansnotpresent} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Vans Hired: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='numberofvanshired' value={this.state.formData.numberofvanshired} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Why Vans Were Hired - Justify Action And Cost: <span style={{color:'red'}}>*</span> </div>
                <textarea className='col-lg-4' name='vanshiredaction' value={this.state.formData.vanshiredaction} rows='4' cols='50' disabled ref={this.vansHiredRef}></textarea>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Business Volume Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Total Number Of Shipments Received: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalnoofshipmentsreceived' value={this.state.formData.totalnoofshipmentsreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Shipments Physically Received: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofshipmentsphysicallyreceived' value={this.state.formData.noofshipmentsphysicallyreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Shipments As Per System Data: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='shipmentsaspersystemdata' value={this.state.formData.shipmentsaspersystemdata} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Discrepancies - Received Excess Against Data: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='discrepanciesreceivedexcess' value={this.state.formData.discrepanciesreceivedexcess} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Discrepancies - Not Received Against Data: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='discrepanciesnotreceived' value={this.state.formData.discrepanciesnotreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Incoming Shipments Planned: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='incomingshipmentsplanned' value={this.state.formData.incomingshipmentsplanned} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Incoming Shipments Actual: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='incomingshipmentsactual' value={this.state.formData.incomingshipmentsactual} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Variance In Planned Vs Actual: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='varianceinplannedvsactual' value={this.state.formData.varianceinplannedvsactual} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Incoming Shipment Weight: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='incomingshipmentweight' value={this.state.formData.incomingshipmentweight} disabled></input>
              </div>
            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Other Shipment Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Number Of In Scanned Shipments: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofinscannedshipments' value={this.state.formData.noofinscannedshipments} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Yesterday Undelivered Sent Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='yesterdayundeliveredsentoutfordelivery' value={this.state.formData.yesterdayundeliveredsentoutfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Total Shipments Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalshipmentsoutfordelivery' value={this.state.formData.totalshipmentsoutfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of COD Shipments Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofcodshipmentsoutfordelivery' value={this.state.formData.noofcodshipmentsoutfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Total COD Cash Value Out For Delivery: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalcodcashvalue' value={this.state.formData.totalcodcashvalue} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Shipments On Hold At Office On Client's Instruction: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='shipmentsonholdatofficeonclientsinstruction' value={this.state.formData.shipmentsonholdatofficeonclientsinstruction} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Held At Office (Not Taken Out For Delivery): <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='heldatofficenottakenfordelivery' value={this.state.formData.heldatofficenottakenfordelivery} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Total Shipments Delivered: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalshipmentsdelivered' value={this.state.formData.totalshipmentsdelivered} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of COD Shipments Delivered: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totalcodshipmentsdelivered' value={this.state.formData.totalcodshipmentsdelivered} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>COD Amount Collected: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='codamountcollected' value={this.state.formData.codamountcollected} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>COD Amount Short Received: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='codamountshortreceived' value={this.state.formData.codamountshortreceived} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Lost Or Missing Number Of Shipments: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='lostormissingnoofshipments' value={this.state.formData.lostormissingnoofshipments} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Number Of Shipments Not Delivered Received @ Hub BTS: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='noofshipmentsnotreceivedathub' value={this.state.formData.noofshipmentsnotreceivedathub} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>End Of Day Hold At Office Stock: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='endofdayholdatofficestock' value={this.state.formData.endofdayholdatofficestock} disabled></input>
              </div>


            </fieldset>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
              <Accordion.Toggle>
                <div className='division-title' style={{color:'black', textAlign:'left'}}>Area Manager Additional Info</div>
              </Accordion.Toggle>
          </Card.Header>

          <Card.Body>
            <fieldset style={{marginTop: 0, paddingTop: 0}} className='field-container col-lg-12'>
              <div className='row'>
                <div className='col-lg-4'>Total Off Role Staff Not Reported For Work: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='totaloffrolestaffnotreportedforwork' value={this.state.formData.totaloffrolestaffnotreportedforwork} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Reason For Staff Shortage And Action Taken: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='reasonforstaffshortageandactiontaken' value={this.state.formData.reasonforstaffshortageandactiontaken} rows='4' cols='50' disabled ref={this.staffShortageRef}></textarea>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Vans Not Reported: <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='vansnotreported' value={this.state.formData.vansnotreported} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Reason For Vans Not Reporting And Action Taken: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='reasonforvansnotreportingandactiontaken' value={this.state.formData.reasonforvansnotreportingandactiontaken} rows='4' cols='50' disabled ref={this.vansNotReporting}></textarea>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Variance In Volume (Planned Vs Actual): <span style={{color:'red'}}>*</span></div>
                  <input className='col-lg-4' type='number' name='varianceinvolume' value={this.state.formData.varianceinvolume} disabled></input>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Client Who Has Not Met Target: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='clientwhohasnotmettarget' value={this.state.formData.clientwhohasnotmettarget} rows='4' cols='50' disabled ref={this.clientTarget}></textarea>
              </div>

              <br/>

              <div className='row'>
                <div className='col-lg-4'>Plan To Meet Target Shortfall: <span style={{color:'red'}}>*</span></div>
                <textarea className='col-lg-4' name='plantomeettargetshortfall' value={this.state.formData.plantomeettargetshortfall} rows='4' cols='50' disabled ref={this.plan}></textarea>
              </div>
            </fieldset>
          </Card.Body>
        </Card>
        
      </Fragment>
        )       
    }

    return (
        <>
            <div className='header_div'>Area Manager History</div>

            <br/>

            <Form style={{display:'flex', flexDirection:'column'}}>

                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Select Report:</div>
                    <select className='col-lg-3' value={this.state.formData.report} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='report' onChange={this.handleChange}>
                        <option value='' disabled>Select</option>
                        <option value='AM'>AM Report</option>
                        <option value='PM'>PM Report</option>
                        <option value='EXC'>Exception Report</option>
                        <option value='AMR'>Area Manager Report</option>
                    </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Select Branch:</div>
                    <select className='col-lg-3' value={this.state.formData.branch} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='branch' onChange={this.handleBranchCodeChange}>
                        <option value='' disabled>Select</option>
                        {this.state.userAssignedStations.map((item, index) => <option value={item.branchcode} key={index}>{item.branchcode}</option>)}
                    </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Branch Code:</div>
                    <select className='col-lg-3' value={this.state.formData.branchname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='branchname'>
                        <option value='' disabled>Select</option>
                        {this.state.userAssignedStations.map((item, index) => <option disabled value={item.branchname} key={index}>{item.branchname}</option>)}
                    </select>
                </div> */}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Date:</div>
                    <input className="col-lg-3" value={this.state.formData.date} type='date' min={this.state.min_date} max={this.state.max_date} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='date' onChange={this.handleChange}></input>
                </div>

                <div style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
                    <Button variant='primary' onClick={this.handleSearch}>View</Button>
                </div>
            </Form>
            
            <br/>
            <br/>

            <div style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
                {this.state.NoData === 'No Data' ? <div style={{fontSize:20}}>No Data Found</div> : null}
            </div>

        </>
      
    )
  }
}

function mapStateToProps(state){
    return {
        state
    }
}

export default connect(mapStateToProps) (AreaManagerHistory)