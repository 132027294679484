
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyForm from './myform'
import MyLable from './mylable'
import { fetchData, fetchSavedTreeData, fetchAccountingData, flushTree } from '../actions/treeAction'
import MyTable from './mytable'
import Spinner from "react-bootstrap/Spinner";
import { postRequest } from '../globalhelper/helper';
import Modal from 'react-bootstrap/Modal'

class DisplayContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showModal : false,
            formData : {
                page : ''
            },
            showScreen : false
        }
    }

    checkTreeData = () => {
        if(this.props.treeData !== undefined)
        {
            this.setState({
                ...this.state,
                showScreen : true
            })
        }
        else{
            this.setState({
                ...this.state,
                showScreen : false
            })
        }
    }
    
    componentDidMount = () =>{
        this.props.flushTree();
        this.interval = setInterval(() => this.checkTreeData(),1000)
        var formData = this.props.data;
        // console.log(formData)
        // if(formData[0].type)
        // {
            if( formData.length > 0 && formData[0].type !== 'designing')
            {
                this.props.fetchSavedTreeData()
            }
        // }
        this.props.fetchData(formData, this.props.title);
    }

    openModal = () => {
        this.setState({
            showModal : true
        })
    }

    handleSubmit = () =>{
        var treeData = this.props.treeData;
        // console.log(this.props.treeData)
        treeData.forEach((tree,index) => {
            console.log(tree.enable, typeof tree.enable);
            if(tree.enable)
            {
                tree.children.forEach((child,key) => {
                    if(child.enable)
                    {
                        child.children.forEach((gc,ix) => {
                            if(!gc.enable)
                            {
                                child.children.splice(ix,1)
                            }
                        })
                    }
                    else{
                        tree.children.splice(key,1)
                    }
                })
            }
            else{
                treeData.splice(index,1)
            }
        })
        postRequest('api/visual/saveHomePage', { page : this.state.formData.page, product : localStorage.getItem("module") , treeData : treeData} )
        .then(response => {
            console.log(response);
            window.location.reload();
        })
    }

    getRequestedData = (collection,process, treenode) =>{
        console.log(treenode)
        var External_ID = this.props.data.id
        var tenant_id = this.props.data.tenant_id
        var ledger = this.props.data.ledger
        treenode.children.forEach(function(child){
            if(child.title.includes("_AcDoc_Line_Holder"))
            {
                if(collection === 'Finance')
                {
                    var formData = {
                        External_ID : External_ID,
                        tenant_id : tenant_id,
                        ledger : ledger,
                        process : child.title,
                        title : process
                    }
        
                    this.props.fetchAccountingData(formData)
                }
            }
        }.bind(this))
    }

    
    // toggleClass = (id)  => {
    //     var element = document.getElementById( id +"-card");
    //     element.classList.toggle("accodion show");
    //  }

    // handleToggle = (id,collection,process, treeNode) => {
    //     this.toggleClass(id);
    //     this.getRequestedData(collection, treeNode.process, treeNode)
    // }
    
    handleChange = (event) => {
        var id = [event.target.id];
        this.setState({
            formData: {
                  ...this.state.formData,
                  [id]: event.target.value
            }
        })
        
    }

    render() {
        if (this.props.treeData !== undefined && this.props.formData  && this.props.formData.length > 0 && this.state.showScreen === true) {
            return (
            <>
            <Accordion defaultActiveKey={0 + "-card"}>
                {
                    this.props.treeData.map((treeNode, index) => {
                        if(treeNode.enable)
                        {
                            return (
                                <Card key={index} >
                                    <Card.Header >
                                        <Accordion.Toggle eventKey={index + "-card"} onClick={ () => this.getRequestedData(treeNode.collection, treeNode.process, treeNode) }>
                                            <div className="division-title">{treeNode.short_text } </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={index + "-card"}>
                                        <Card.Body>
                                            <div className="row">
                                                <div className={treeNode.divisonView === 'v'  ? "col-md-6" : "col-md-12" }>
                                                    <MyLable tree={treeNode} formData={this.props.formData}></MyLable>
                                                </div>
                                                <div className={treeNode.divisonView === 'v'  ? "col-md-6" : "col-md-12" }>
                                                    <MyForm tree={treeNode}></MyForm>
                                                </div>
                                                <div className={treeNode.divisonView === 'v'  ? "col-md-6" : "col-md-12" }>
                                                    {this.props.formData.length > 0 ?
                                                        <MyTable tableData={treeNode} formData={this.props.formData} data={ this.props.data  } ></MyTable>
                                                    : 
                                                    <h3> No data found </h3>}
                                                </div>
                                            </div>                                      
                                        
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        }
                        return null;
                    })
                }
            </Accordion>
            <br /><br />
            { this.props.data[0].type === "designing" ? 
                <>
                    <div className="row">
                        <div className="col-md-11" align="right">
                            <button type="submit" className="btn btn-primary" onClick={ () => this.openModal() }>Next</button>
                        </div>
                    </div>
                    { this.state.showModal ? 
                        <Modal
                            show={this.state.showModal}
                            onHide={() => this.handleSubmit()}
                            dialogClassName="modal-90w"
                            style={{ marginTop : 20 }}
                            backdrop="static"
                            keyboard={false}
                            aria-labelledby="example-custom-modal-styling-title"
                            >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Save Design
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                
                                <div  className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="page"> Pagename </label>
                                        <input className="form-control" type="text" id="page" name="page" placeholder="Input the Name for the Design" aria-label="Search" onChange={ (ev) => this.handleChange(ev) } /> <br/>
                                    </div>
                                </div> 
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn btn-teal" disabled={ this.state.formData.page === "" } onClick={this.handleSubmit}>Save</Button>
                            </Modal.Footer>
                            </Modal>

                    : null
                }
                </>
            : null
            }
        </>
            )
        }
        else if(this.props.treeData === undefined && this.state.showScreen === true) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                    <h6> Screen Not Found! </h6> 
                    {/* <Spinner animation="grow" variant="text-navy"  />  */}
                </div>
            )
        }
         else {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                    <h6> Preparing screen... </h6> 
                    <Spinner animation="grow" variant="text-navy"  /> 
                </div>
                )
        }

    }
}
function mapStateToProps(state) {
    return {
        treeData: state.treeReducer.treeData,
        formData: state.treeReducer.formData,
    }
}

export default connect(
    mapStateToProps,
    {fetchData, fetchSavedTreeData, fetchAccountingData, flushTree}
)(DisplayContainer);

