import { Autocomplete, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

export default function CostContributionSearch({
  loaded,
  companies,
  company,
  setCompany,
  period,
  setPeriod,
  fetchCostAllocations,
  resetCostAllocation,
  clearFilterData,
  clearFilterInputs
}) {
  const handleSearch = () => {
    fetchCostAllocations();
  };
  
  const resetSearch = () => {
    clearFilterData?.current?.();
    clearFilterInputs?.current?.();
    resetCostAllocation();
  };

  return (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      <Grid item xs={4}>
        <Autocomplete
          size="small"
          readOnly={loaded}
          value={company}
          label="Company"
          getOptionLabel={(company) => company?.orgname || ""}
          getOptionKey={(company) => company?.orgid || ""}
          options={companies}
          onChange={(event, value) => setCompany(value)}
          renderInput={(params) => <TextField label="Company" {...params} />}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          label="Period"
          value={period}
          readOnly={loaded}
          openTo="month"
          minDate={moment().subtract(1, "year").startOf("year")}
          maxDate={moment().endOf("year")}
          views={["year", "month"]}
          onChange={(newValue) => {
            setPeriod(newValue);
          }}
          renderInput={(params) => (
            <TextField fullWidth size="small" {...params} />
          )}
        />
      </Grid>
      <Grid item xs={4} display="flex" gap={2}>
        <Button variant="outlined" onClick={resetSearch}>
          Reset
        </Button>
        <Button
          type="button"
          variant="contained"
          disabled={!company}
          onClick={() => handleSearch()}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
}
