import React, { Component } from 'react';
import {Form, Button, Pagination, Modal} from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react' 
import './COD.css';
import { getRequest, postRequest } from '../../globalhelper/helper';
import Pagination1 from './Pagination';
import { CSVLink } from 'react-csv';
import AddNewStation from './AddNewStation';

export class SelectionScreen extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData: {
            client: '',
            model: '',
            zone: '',
            stationcode: '',
            status: ''
         },
         viewuserItem: {},
         selectedUsers: [],
         deleteuserRecord: '',
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         dropdown_stationcode: [],
         uniqueModel: [],
         uniqueZone: [],
         reset_code: [],
         dropdown_status: ["Enabled", "Disabled"],
         show_screen: false,
         show_table: false,
         fetching: false,
         allData: [],
         codData: [],
         currentPage: 1,
         postsPerPage: 25,
         tableDisplay: [],
         displayData: [],
         downloadData: [],
         popup: false,
         user: {
            username: '',
            password: '',
            stdcode: ''
         },
         listUsers: [],
         showpopup: false
      }
    }

    componentDidMount() {
        getRequest('api/cod/getClientForSelection').then(response => {
            if(response.res.status === 'success'){
                let client = response.res.data

                let dropdown_client = client.map(value => value.client_short_name)

                this.setState({
                    dropdown_client: dropdown_client,
                    show_screen: true
                })
            } 
        })

        getRequest(`api/cod/allData`).then(response => {

            if(response.res.status === 'success'){
                let data = response.res.data
                console.log(data)

                let dropdown_model = data.map(value => value.ci_business_type)
                let dropdown_zone = data.map(value => value.zone)

                this.setState({
                    ...this.state,
                    uniqueModel: dropdown_model,
                    uniqueZone: dropdown_zone,
                    codData: data
                })
            } else {
                console.log("Error")
            }
        })

        getRequest(`api/cod/getAllStationCode`).then(response => {

            let uniqueClientData = response.res.data

            let dropdown_clientcode = uniqueClientData.map(value => value.client_hub_code)

            this.setState({
                ...this.state,
                dropdown_stationcode: dropdown_clientcode,
                reset_code: dropdown_clientcode
            })
        })
    }

    handleDownload = async() => {
        await getRequest(`api/cod/getSelectionScreenEnabledItems`).then(response => {
            let data = response.res.data

            this.setState({
                ...this.state,
                downloadData: data
            }, () => {
                this.downloadLink.link.click()
            })
        })
    }
    handleClientChange = (event) => {
        const VALUE = event.target.value
        this.setState({
            ...this.state,
            formData: {
                client: VALUE
            }
        })

        let arrModel = []

        this.state.codData.forEach(item => {
            if(item.client_short_name === VALUE){
                arrModel.push(item.ci_business_type)
            }
        })

        let arr1 = [...new Set(arrModel)]

        this.setState({
            ...this.state,
            dropdown_model: arr1,
            dropdown_stationcode: [],
            dropdown_zone: [],
            formData: {
                ...this.state.formData,
                model: '',
                zone: '',
                stationcode: '',
                status: '',
                client: VALUE
            }
        })
    }

    handleModelChange = (event) => {
        const VALUE = event.target.value
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        let arrZone = []

        this.state.codData.forEach(item => {
            if(item.client_short_name === this.state.formData.client && item.ci_business_type === VALUE){
                arrZone.push(item.zone)
            }
        })

        let arr2 = [...new Set(arrZone)]

        this.setState({
            ...this.state,
            dropdown_zone: arr2,
            formData: {
                ...this.state.formData,
                zone: '',
                stationcode: '',
                status: '',
                model: VALUE
            }
        })
    }

    handleZoneChange = (event) => {
        const VALUE = event.target.value;

        let stationcode = []

        this.setState({
            formData: {
                ...this.state.formData,
                zone : VALUE
            }
        })

        this.state.codData.forEach(item => {
            if(item.zone === VALUE && item.client_short_name === this.state.formData.client && item.ci_business_type === this.state.formData.model){
                stationcode.push(item.client_hub_code)
                return stationcode
            }
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formCompleted: false,
            formData: {
                ...this.state.formData,
                stationcode: '',  
                status: '',              
                zone: VALUE
            }
        }, () => this.getStationCode())
    }

    handleStationCodeChange = (event) => {
        const VALUE = event.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                stationcode: VALUE
            }
        }, () => {
            this.getRemainingValues()
        })
    }

    getRemainingValues = () => {
        getRequest(`api/cod/getRemainingValues?input=` + this.state.formData.stationcode).then(response => {
            if(response.res.status === 'success'){
                let remainingData = response.res.data

                console.log(remainingData)

                let dropdown_client = remainingData.map(value=> value.client_short_name)

                let dropdown_model = remainingData.map(value => value.ci_business_type)

                let dropdown_zone = remainingData.map(value => value.zone)

                this.setState({
                    ...this.state,
                    dropdown_model: [dropdown_model[0]],
                    dropdown_zone: [dropdown_zone[0]],
                    formData: {
                        ...this.state.formData,
                        client: dropdown_client[0],
                        model: dropdown_model[0],
                        zone: dropdown_zone[0],
                    }
                }, () =>{
                    {this.state.formData.stationcode === '' ?
                    this.getStationCode() : this.getSingleStationCode()}
                } )
            }
        })
    }

    getSingleStationCode = () => {
        const client = this.state.formData.client
        const model = this.state.formData.model
        const zone = this.state.formData.zone
        const stationcode = this.state.formData.stationcode

        this.setState({
            ...this.state,
            show_table: false,
            fetching: true
        })
        getRequest(`api/cod/singleselectionscreenData?client=${client}&model=${model}&zone=${zone}&stationcode=${stationcode}`).then(response => {
            console.log(response.res.data)

            let stationcode1 = response.res.data

            this.setState({
                ...this.state,
                allData: stationcode1,
                fetching: false,
                show_table: true
            }, () => this.handlePagination())
        })
    }

    getStationCode = () => {
        const client = this.state.formData.client
        const model = this.state.formData.model
        const zone = this.state.formData.zone

        console.log(client, model, zone)

        this.setState({
            ...this.state,
            show_table: false,
            fetching: true
        })
        getRequest(`api/cod/selectionscreenData?client=${client}&model=${model}&zone=${zone}`).then(response => {
            console.log(response.res.data)

            let stationcode = response.res.data

            this.setState({
                ...this.state,
                allData: stationcode,
                fetching: false,
                show_table: true
            }, () => this.handlePagination())
        })
    }

    handleStatusChange = (event) => {
        const VALUE = event.target.value
        console.log(VALUE)
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                status: VALUE
            }
        }, () => {
            this.handleStatusFilter()
        })
    }

    handleStatusFilter = () => {

        const status = this.state.formData.status
        console.log(status)
        this.setState({
            ...this.state,
            fetching: true,
            formData: {
                ...this.state.formData,
                stationcode: ''
            }
        }, () => this.handleGlobalFilter())
    }

    handleGlobalFilter = () => {
       let client = [this.state.formData.client]
       let model = [this.state.formData.model]
       let zone = [this.state.formData.zone]
       let status = [this.state.formData.status]
       let data = this.state.codData

       console.log(client, model, zone, status)

       if(!client[0]){
        client = this.state.dropdown_client
       } 

       if(!model[0]){
        model = this.state.uniqueModel
       }

       if(!zone[0]){
        zone = this.state.uniqueZone
       }

       if(!status[0]){
        status = ["Enabled", "Disabled"]
       }
       console.log(client, model, zone, status)
       console.log(data)

       let filteredData = []

       for(const[i, item] of data.entries()){
        console.log(client, model, zone, status)
        console.log(item.client_short_name, item.ci_business_type, item.zone, item.status)
        if(client.includes(item.client_short_name) && model.includes(item.ci_business_type) && zone.includes(item.zone) && status.includes(item.status)){
            filteredData.push(item)
        }

        if(i===data.length-1){
            this.setState({
                ...this.state,
                allData: filteredData,
                fetching: false,
                show_table: true
            }, () => this.handlePagination())
        }
       }
    }

    handleSwitch = (item) => {
        const array = this.state.codData
        const array1 = this.state.tableDisplay
        const array2 = this.state.allData

        const objIndex = array.findIndex(obj => obj.client_hub_code === item.client_hub_code)
        const objIndex1 = array1.findIndex(obj => obj.client_hub_code === item.client_hub_code)
        const objIndex2 = array2.findIndex(obj => obj.client_hub_code === item.client_hub_code)

        if(objIndex === -1){
            return
        }

        if(item.status === "Enabled"){
            const update = {...array[objIndex], status: "Disabled"}

            const update1 = {...array1[objIndex1], status: "Disabled"}

            const update2 = {...array2[objIndex2], status: "Disabled"}

            const updatedArray = [...array.slice(0, objIndex), update, ...array.slice(objIndex +1)]

            const updatedArray1 = [...array1.slice(0, objIndex1), update1, ...array1.slice(objIndex1 +1)]

            const updatedArray2 = [...array2.slice(0, objIndex2), update2, ...array2.slice(objIndex2 +1) ]

            console.log(updatedArray1)

            this.setState({
                ...this.state,
                tableDisplay: updatedArray1,
                codData: updatedArray,
                allData: updatedArray2
            }, () => console.log(this.state.allData))
       } 

       if(item.status === "Disabled"){
        const update = {...array[objIndex], status: "Enabled"}

        const update1 = {...array1[objIndex1], status: "Enabled"}

        const update2 = {...array2[objIndex2], status: "Enabled"}

        const updatedArray = [...array.slice(0, objIndex), update, ...array.slice(objIndex +1)]

        const updatedArray1 = [...array1.slice(0, objIndex1), update1, ...array1.slice(objIndex1 +1)]

        const updatedArray2 = [...array2.slice(0, objIndex2), update2, ...array2.slice(objIndex2 +1) ]

        console.log(updatedArray1)

        this.setState({
            ...this.state,
            tableDisplay: updatedArray1,
            codData: updatedArray,
            allData: updatedArray2
        },
        () => console.log(this.state.allData))

       }
    }

    handleSubmit = async () => {

        await postRequest(`api/cod/selectionUpload`, this.state.codData).then(response => {
            console.log(response)
            setTimeout(() => {
                window.location.reload()
            }, 1500)           
        })
    }

    handlePagination = () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        console.log(this.state.allData)
        let currentPosts = this.state.allData.sort((a,b)=>a.client_hub_code.localeCompare(b.client_hub_code)).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            tableDisplay: currentPosts
        })
    }

    paginate = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            show_table: false,
            dropdown_model: [],
            dropdown_zone: [],
            fetching: false,
            dropdown_stationcode: this.state.reset_code,
            formData: {
                ...this.state.formData,
                client: '',
                model: '',
                zone: '',
                stationcode: '',
                status: ''
            }
        })
    }

    openPopUp = (item) => {
        console.log(item)
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                stdcode: item.client_hub_code
            },
            popup: true
        })
    }

    closePopup = () => {
        this.setState({
            ...this.state,
            popup: false
        })
    }

    handleUsernameChange = (event) => {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                username: event.target.value
            }
        })
    }

    handlePasswordChange = (event) => {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                password: event.target.value
            }
        })
    }   

    handleAssign = () => {
        // alert(`U: ${this.state.user.username}, P:${this.state.user.password}, S:${this.state.user.stdcode}`)
        postRequest(`api/user/createCIUser`, this.state.user).then(response => {
            console.log(response)
        })

        this.setState({
            ...this.state,
            popup: false
        })
    }

    viewUsers = (item) => {
        console.log(item)
        this.setState({
            ...this.state,
            viewuserItem: item
        })

        let listUsers = []
        getRequest(`api/cod/viewUsers?stationcode=${item.client_hub_code}`).then(res => {
            console.log(res.res.data)
            this.state.deleteuserRecord = res.res.data
        

            if(res.res.data.length > 0){
                console.log(res.res.data);

                let users = res.res.data
                users.forEach((user, index) => {
                    let replace = user.UserID.replace("#USR#", '')
                    listUsers.push(replace)
                    console.log(listUsers)
    
                    if(index === users.length - 1){
                        this.setState({
                            ...this.state,
                            listUsers: listUsers
                        }, () => this.showUsers())
                    }
                })
            } else {
                this.setState({
                    ...this.state,
                    listUsers: []
                }, () => this.showUsers())
            }
        })
    }

    deleteUser = () => {
        const selectedUsers = this.state.selectedUsers;
        const deletePromises = selectedUsers.map(user => {
            // Find the record for this user
            const userRecord = this.state.deleteuserRecord.find(record => 
                record.UserID.replace("#USR#", '') === user
            );
    
            if (userRecord) {
                const UserID = encodeURIComponent(userRecord.UserID);
                const pk = encodeURIComponent(userRecord.PK);
                const sk = encodeURIComponent(userRecord.SK);
    
                const url = `api/cod/deleteUsers?UserID=${UserID}&pk=${pk}&sk=${sk}`;
                return getRequest(url); // Return the promise for each request
            }
            
            return Promise.resolve(); // Return a resolved promise if userRecord not found
        });
    
        Promise.all(deletePromises).then(responses => {
            // Handle successful responses here
            console.log('All users deleted', responses);
            // Update state to reflect the changes
            this.setState({
                ...this.state,
                listUsers: this.state.listUsers.filter(user => !selectedUsers.includes(user)),
                selectedUsers: []  // Clear selected users after deletion
            });
        }).catch(error => {
            // Handle errors here
            console.error('Error deleting users', error);
        });
    };
    
    

    showUsers = () => {
        console.log(this.state.listUsers)
        this.setState({
            ...this.state,
            showpopup: true
        })
    }

    handleUsersClose = () => {
        this.setState({
            ...this.state,
            showpopup: false
        })
    }

    handleAddNewStation = () => {
        this.setState({
            ...this.state,
            redirectScreen: true
        })
    }

    toggleUserSelection = (item) => {
        this.setState((prevState) => {
            const selectedUsers = [...prevState.selectedUsers];
            const userIndex = selectedUsers.indexOf(item);
            
            if (userIndex > -1) {
                selectedUsers.splice(userIndex, 1);
            } else {
                selectedUsers.push(item);
            }
    
            return { selectedUsers };
        });
    };
    


  render() {

    if(this.state.redirectScreen){
        return (
            <AddNewStation/>
        )
    }

    return (
      <div>
        <div className='header_div_selection_screen'>
            COD Station Enablement 
        </div>

        {this.state.show_screen ?

        <div>
            <div>
                <Button variant='primary' className='pull-right' style={{marginLeft:10}} onClick={this.handleAddNewStation}>Add Station</Button>
            </div>
            <div>
                <Button className='pull-right' style={{marginLeft:10}} variant='success' onClick={this.handleDownload}>Download Enabled Stations</Button>
                <CSVLink data={this.state.downloadData} filename='Enabled Stations.csv' ref={(r)=>this.downloadLink =r} target='_blank'></CSVLink>
            </div>
            <div>
                <Button className='pull-right' variant='danger' onClick={this.handleReset}>Reset Filter</Button>
            </div>
        </div>

         : null }

        <br/>
        <br/>

        {this.state.show_screen ?

        <Form>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Client</Form.Label>
                        <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_client.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Model</Form.Label>
                        <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_model.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Zone</Form.Label>
                        <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_zone.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <select className='spacing' value={this.state.formData.status} name='status' onChange={this.handleStatusChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_status.map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Station Code</Form.Label>
                        <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationCodeChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_stationcode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

            </div>

            {this.state.fetching ? <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div>: null}


            <br/>

            {this.state.show_table ?
            <div className='table_wrapper column offset-2'>
                <table border='1' id='table_styling'>
                    <thead>
                        <tr>
                            <th>Client</th>
                            <th>Business Model</th>
                            <th>Zone</th>
                            <th>Station Code</th>
                            <th>Status</th>
                            <th>Enable/Disable</th>
                            <th>Assign User</th>
                            <th>View Users</th>
                            {/* <th>Disable</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.tableDisplay.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td value={item.client_short_name}>{item.client_short_name}</td>
                                    <td value={item.ci_business_type}>{item.ci_business_type}</td>
                                    <td value={item.zone}>{item.zone}</td>
                                    <td value={item.client_hub_code}>{item.client_hub_code}</td>
                                    <td value={item.status}>{item.status}</td>
                                    {/* <td><Button variant='success' onClick={()=>this.handleEnable(item,i)} disabled={item.status === "Enabled"}>Enable</Button></td>
                                    <td><Button variant='danger' onClick={()=>this.handleDisable(item,i)} disabled={item.status === "Disabled"}>Disable</Button></td> */}
                                    <td>
                                        <div>
                                           <BootstrapSwitchButton onlabel='Enabled' offlabel='Disabled'  width='100'  checked={item.status==="Enabled"} onChange={()=>this.handleSwitch(item)}></BootstrapSwitchButton>
                                        </div>
                                    </td>
                                    <td><Button variant='success' onClick={() => this.openPopUp(item)}>Assign To</Button></td>
                                    <td><Button variant='warning' onClick={() => this.viewUsers(item)}>View</Button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div> : null
            }

        
            
        </Form>
        : <div className='center'>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
                <div class="wave"></div>
        </div>}

        <div>
            <Modal show={this.state.popup}>
                <Modal.Header>
                    <Modal.Title>Create User</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='column'>
                        <div className='row'>
                            <div className='col-lg-4'>Username</div>
                            <input className='col-lg-6' type='text' onChange={this.handleUsernameChange}></input>
                        </div>
                        <br/>

                        <div className='row'>
                            <div className='col-lg-4'>Password</div>
                            <input className='col-lg-6' type='text' onChange={this.handlePasswordChange}></input>
                        </div>

                    </div>
                    
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={this.closePopup}>Close</Button>
                    <Button variant='primary' onClick={this.handleAssign}>Assign</Button>
                </Modal.Footer>
            </Modal>
        </div>

        {/* {this.state.showpopup ? 
        <div>
            <div className='popup'>
                <h2>List Of Users</h2>
                {this.state.listUsers.length >0 ? this.state.listUsers.map((item, index)=>  <li>{item}</li>)  : 'No Users'}
            </div>

        </div> : null} */}

        <div>
            <Modal show={this.state.showpopup}>
                <Modal.Header>
                    <Modal.Title style={{textAlign:'center'}}>List Of Users</Modal.Title>
                </Modal.Header>

                {/*<Modal.Body>
                    {this.state.listUsers.length >0 ? this.state.listUsers.map((item, index)=>  <ul><li>{item}</li></ul>)  : 'No Users'}
                </Modal.Body>*/}
                <Modal.Body>
                {this.state.listUsers.length > 0 ? this.state.listUsers.map((item, index) => (
                    <ul key={index} style={{ listStyleType: 'none', padding: '0', margin: '10px 0' }}>
                    <li style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                        type="checkbox"
                        checked={this.state.selectedUsers.includes(item)}
                        onChange={() => this.toggleUserSelection(item)}
                        style={{
                            marginRight: '10px',
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer'
                        }}
                        />
                        <span style={{ fontSize: '16px', color: '#333' }}>{item}</span>
                    </li>
                    </ul>
                )) : 'No Users'}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={this.handleUsersClose}>Close</Button>
                    <Button variant='primary' onClick={this.deleteUser}>Remove Selected Users</Button>
                </Modal.Footer>
            </Modal>
        </div>

        {this.state.show_table ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.allData.length} paginate={this.paginate}/>
        </div> : null }
        
        {this.state.show_table ?

        <div className='wrapper mt-3'>
            <Button onClick={this.handleSubmit}>Submit</Button>
        </div>
        : null}
      </div>
    )
  }
}

export default SelectionScreen