import React,{ memo, useCallback, useState, useEffect } from 'react'
import Table from './table'
import { getRequest, postRequest } from '../../globalhelper/helper';


export default function Main() {


    let ok = {
          "Process": "Standalone_Compute",
          "TenantID": "DM00005",
          "SchemaKey": "MySchemaDef/SC00016.json",
          "SourceDataMap": {
            "Subscription": "SCDP/COMP_STANDALONE/Input/GoV3/Go_Subcription.csv",
            "Consumption": "SCDP/COMP_STANDALONE/Input/GoV3/Go_ContentConsumption.csv"
          }
        }

        
    const [inputData, setInputData] = useState({});
    const [outputData, setOutputData] = useState({});


    useEffect( () => {

        let input = ok.SourceDataMap

        const fetchData = async () => {
        try {
               
            // Create an array of promises to fetch data for each dynamic key
            const promises = Object.keys(input).map(async (key) => {

            const response = await postRequest(`api/free/getInputforSchema`, {KEY : input[key]})

            return { key, res: await response };
            });
    
            // Wait for all promises to resolve using Promise.all
            const results = await Promise.all(promises);
    
            // Update the state with the received data for each dynamic key
            const newData = {};
            results.forEach(({ key, res }) => {
            newData[key] = res.res.status === 'success' ? res.res.data : []
            });

            console.log(newData)
            setInputData(newData);
    
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        };
    
        fetchData();


    }, [])

    const data_ = [
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        },
        {
            id: 1,
            name : "name",
            age : "22",
            employeeId : '1234',
            phoneNo : '9876543211',
            address : 'no32 , street, chennai'
        }
    ]

    const onCompute = () => {

        let Input = ok

        Input.SourceDataMap = inputData

        postRequest(`api/free/onComputeSchema`, { input : JSON.stringify(Input) })
        .then( res => {

            console.log(res)

            if(res.res.status === "success"){

                let data = JSON.parse(res.res.data.Payload)
    
                console.log(data)

                setOutputData(data);
            }
            
        })
    }



  return (
    <div>

        

            {Object.keys(inputData).map( (each, i) => (

                <>
                    {i === 0 && <label>Input Files</label>}

                    <Table title={each} data={inputData[each]} uniqueId={i}/>

                    {i === (Object.keys(inputData).length - 1) && 
                        <div className='m-4' align="center"><button className='btn btn-success' onClick={onCompute}> Compute </button></div>
                    }
                </>

            ))}




            {Object.keys(outputData).map( (each, i) => (

                <>
                    {i === 0 && <label>Output Files</label>}
                    <Table title={each} data={outputData[each]}/>
                </>

            ))}
        
    </div>
  )
}
