import React, { Fragment, Component } from 'react';

class ConsolidatedDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            formData : {
                device_id : ''
            }
         }
    }
    render() { 
        return ( 
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Consolidated Dashboard</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Device ID</label>
                                            <input type="text" className="form-control" name="device_id" onChange={this.onChange} value={this.state.formData.device_id} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button className="btn btn-primary" onClick={this.onFetchData}>Fetch Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
         );
    }
}
 
export default ConsolidatedDashboard;