import React, { Component } from 'react'
import {Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux'
import { getHumanReadableDate, getLast60Days, getRequest, getStandardDate } from '../../globalhelper/helper';
import VR2_Forms from '../VR2_Form_Rendering/displayContainer';

export class BranchManagerHistory extends Component {
    constructor(props) {
      super(props)

      this.current = this.props.state
    
      this.state = {
        userAssignedStations: [],
        userMail: this.current.login.userData.USR_Email,
        max_date: getStandardDate(new Date()),
        min_date : getLast60Days(new Date()),
         formData: {
            report: '',
            branch: '',
            date: '',
            branchname: ''
         }
      }
    }

    componentDidMount(){
        getRequest(`api/ciportal/getAssignedStations`).then(res => {
            console.log(res.res.data)
            if(res.res.status === "success" && res.res.data.length){
                this.setState({
                    ...this.state,
                    userAssignedStations: res.res.data
                })
            }
        })
    }

    handleBranchCodeChange = (e) => {
        const branchcode = e.target.value

        const data = this.state.userAssignedStations

        data.forEach(item => {
            if(item.branchcode === branchcode){
                this.setState({
                    ...this.state,
                    formData: {
                        ...this.state.formData,
                        branch: branchcode,
                        branchname: item.branchname
                    }
                })
            }
        })
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    changeAMEdit = () => {
        let JSON = this.state.AMData

        if(this.state.userMail === 'kalyankumar.s@connectindia.com'){
            this.setState({
                ...this.state,
                AMJSON: true
            })
        } else {
            JSON.forEach((item, index) => {
                if(item.title === 'Submit'){
                    item['editable'] = "false"
                    console.log(item)
                    this.setState({
                        ...this.state,
                        AMJSON: true,
                        AMData: JSON
                    })
                }
            })
        }
    }

    changePMEdit = () => {
        let JSON = this.state.PMData

        if(this.state.userMail === 'kalyankumar.s@connectindia.com'){
            this.setState({
                ...this.state,
                PMJSON: true
            })
        } else {
            JSON.forEach((item, index) => {
                if(item.title === 'Submit'){
                    item['editable'] = "false"
                    console.log(item)
                    this.setState({
                        ...this.state,
                        PMJSON: true,
                        PMData: JSON
                    }, () => console.log(this.state.PMData))
                }
            })
        }
    }

    changeEXEdit = () => {
        let JSON = this.state.EXCData
        
        if(this.state.userMail === 'kalyankumar.s@connectindia.com'){
            this.setState({
                ...this.state,
                EXCJSON: true
            })
        } else {
            JSON.forEach((item, index) => {
                if(item.title === 'Submit'){
                    item['editable'] = "false"
                    console.log(item)
                    this.setState({
                        ...this.state,
                        EXCJSON: true,
                        EXCData: JSON
                    }, () => console.log(this.state.EXCData))
                }
            })
        }
    }

    handleSearch = () => {
        const date = getHumanReadableDate(this.state.formData.date)
            if(this.state.formData.report === 'AM' && this.state.formData.branch && this.state.formData.date && this.state.formData.branchname){
                getRequest(`api/ciportal/getAMUpdatedJSON?branchcode=${this.state.formData.branch}&date=${date}&branchname=${this.state.formData.branchname}`).then(res => {
                    if(res.res.data && res.res.data.length){
                        this.setState({
                            NoData: '',
                            AMData: res.res.data
                        }, () => {
                            this.changeAMEdit()
                        })
                    } else {
                        this.setState({
                            NoData: 'No Data'
                        })
                    }
                })
            } else if (this.state.formData.report === 'PM' && this.state.formData.branch && this.state.formData.date && this.state.formData.branchname){
                getRequest(`api/ciportal/getPMUpdatedJSON?branchcode=${this.state.formData.branch}&date=${date}&branchname=${this.state.formData.branchname}`).then(res => {
                    if(res.res.data && res.res.data.length){
                        this.setState({
                            NoData: '',
                            PMData: res.res.data
                        }, () => {
                            this.changePMEdit()
                        })
                    } else {
                        this.setState({
                            NoData: 'No Data'
                        })
                    }
                })
            } else if (this.state.formData.report === 'EXC' && this.state.formData.branch && this.state.formData.date && this.state.formData.branchname){
                getRequest(`api/ciportal/getEXCUpdatedJSON?branchcode=${this.state.formData.branch}&date=${date}&branchname=${this.state.formData.branchname}`).then(res => {
                    if(res.res.data && res.res.data.length){
                        this.setState({
                            NoData: '',
                            EXCData: res.res.data
                        }, () => {
                            this.changeEXEdit()
                        })
                    } else {
                        this.setState({
                            NoData: 'No Data'
                        })
                    }
                })
            }
    }
  render() {

    if(this.state.AMJSON){
        return (
            <VR2_Forms
            screenName={'AM_Screen'}
            screenAction={'edit'}
            From_SearchJSON={this.state.AMData}/>
        )       
    }

    if(this.state.PMJSON){
        return (
            <VR2_Forms
            screenName={'PM_Screen'}
            screenAction={'edit'}
            From_SearchJSON={this.state.PMData}/>
        )       
    }

    if(this.state.EXCJSON){
        return (
            <VR2_Forms
            screenName={'EXC_Screen'}
            screenAction={'edit'}
            From_SearchJSON={this.state.EXCData}/>
        )       
    }

    return (
        <>
            <div className='header_div'>Branch Manager History</div>

            <br/>

            <Form style={{display:'flex', flexDirection:'column'}}>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Select Report:</div>
                    <select className='col-lg-3' value={this.state.formData.report} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='report' onChange={this.handleChange}>
                        <option value='' disabled>Select</option>
                        <option value='AM'>AM Report</option>
                        <option value='PM'>PM Report</option>
                        <option value='EXC'>Exception Report</option>
                    </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Select Branch:</div>
                    <select className='col-lg-3' value={this.state.formData.branch} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='branch' onChange={this.handleBranchCodeChange}>
                        <option value='' disabled>Select</option>
                        {this.state.userAssignedStations.map((item, index) => <option value={item.branchcode} key={index}>{item.branchcode}</option>)}
                    </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Branch Code:</div>
                    <select className='col-lg-3' value={this.state.formData.branchname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='branchname'>
                        <option value='' disabled>Select</option>
                        {this.state.userAssignedStations.map((item, index) => <option disabled value={item.branchname} key={index}>{item.branchname}</option>)}
                    </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                    <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Date:</div>
                    <input className="col-lg-3" value={this.state.formData.date} type='date' min={this.state.min_date} max={this.state.max_date} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='date' onChange={this.handleChange}></input>
                </div>

                <div style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
                    <Button variant='primary' onClick={this.handleSearch}>View</Button>
                </div>
            </Form>
            
            <br/>
            <br/>

            <div style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
                {this.state.NoData === 'No Data' ? <div style={{fontSize:20}}>No Data Found</div> : null}
            </div>

        </>
      
    )
  }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(mapStateToProps)
(BranchManagerHistory) 
