import { Box, Stack, styled, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { hideLoader, showLoader, updateUserConfiguration } from '../../../actions/common_actions';
import { renderSelectEditInputCell } from '../../../components/Grid/GridSelectEditor';
import { getDateFormat, postRequest } from '../../../globalhelper/helper';
import globalhelper from '../../../helper/globalhelper';
import CostAllocationCellRenderer from './CostAllocationCellRenderer';
import CostContributionToolbar from './CostContributionToolbar';
const CostContributionTable = ({
  gridRef,
  company,
  setLoaded,
  CAMPeriodMap,
  allKnownCAMs,
  availableLOBs,
  costAllocations,
  setErrorMessage,
  setSuccessMessage,
  resetFilteredCostAllocation
}) => {
  const updatedCostAllocations = useRef({});
  const invalidCostAllocations = useRef({});
  let rows = [];
  const updateRows = () => {
    rows = [...costAllocations].map((costAllocation) => {
      if (costAllocation.id in updatedCostAllocations.current) {
        return updatedCostAllocations.current[costAllocation.id];
      }
      const allocations = (costAllocation.LOBAllocList || '').split(',');
      for (const allocation of allocations) {
        const LOBGroup = allocation.replace(/\)/, '').split('(');
        const LOB = LOBGroup[0];
        const LOBValue = globalhelper.roundOff(Number(LOBGroup[1] || '0'));
        if (LOB && LOBValue) {
          costAllocation[LOB] = LOBValue;
        }
      }
      return costAllocation;
    });
  };
  updateRows();
  const store = useStore();
  const state = store.getState();
  const StyledDataGrid = styled(DataGrid)(({ theme }) => {
    return {
      '& .MuiDataGrid-row.Mui-hovered.Mui-selected': {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.info.contrastText
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.contrastText,
        '& .MuiDataGrid-cell:focus': {
          outline: theme.palette.background.paper
        }
      },
      '& .MuiDataGrid-row:not(.invalid-row):not(.Mui-selected):not(.updated-row)': {
        '& .MuiDataGrid-cell.editable-cell': {
          backgroundColor: theme.palette.grey['200']
        }
      },
      '& .MuiDataGrid-row.updated-row:not(.invalid-row)': {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.info.contrastText
      },
      '& .MuiDataGrid-row.invalid-row': {
        backgroundColor: theme.palette.error.light
      }
    };
  });

  const getCAMLabel = (CAM) => {
    return `${CAM.costallocationmethod}`.replaceAll(/\s+/g, '-').toUpperCase();
  };

  const getRowClassName = ({ id }) => {
    const classes = [];
    if (id in updatedCostAllocations.current) {
      classes.push('updated-row');
    }
    if (id in invalidCostAllocations.current) {
      classes.push('invalid-row');
    }
    return classes.join(' ');
  };

  const getCellClassName = (cell) => {
    const classes = [];
    if (cell.isEditable) {
      classes.push('editable-cell');
    }
    return classes.join(' ');
  };

  const checkIfCellEditable = (params) => {
    if (availableLOBs.includes(params.field)) {
      return params.row.costallocationmethod === 'Dynamic';
    }
    return true;
  };

  const initialState = useRef({
    sorting: {
      sortModel: [{ field: 'id', sort: 'asc' }]
    },
    columns: {
      columnVisibilityModel: {
        accountingdate: false,
        CA_transactionid: false,
        accountingdocnumber: false,
        createdid: true,
        itemid: false,
        accounttype: false,
        documentref: false
      }
    }
  });

  const [columns, setColumns] = useState([]);
  const [LOBTotalMap, setLOBTotalMap] = useState(new Map());
  useEffect(() => {
    const totalMap = new Map();
    availableLOBs.forEach((LOB) => {
      totalMap.set(LOB, 0);
    });
    let expectedTotal = 0;
    for (const costAllocation of costAllocations) {
      expectedTotal += Number(costAllocation.ExpAmt);
      for (const LOB of availableLOBs) {
        let totalLOB = totalMap.get(LOB);
        totalLOB += Number(costAllocation[LOB] || 0);
        totalLOB = globalhelper.roundOff(totalLOB);
        totalMap.set(LOB, totalLOB);
      }
    }
    totalMap.set('ExpAmt', expectedTotal);
    setLOBTotalMap(totalMap);
  }, [costAllocations, availableLOBs]);

  const processColumns = useCallback(() => {
    const originalColumns = [
      {
        headerName: 'Document Number',
        field: 'createdid',
        width: 200
      },
      {
        headerName: 'Transaction Type',
        field: 'refTransactionType',
        width: 200
      },
      {
        headerName: 'Accounting Journal',
        field: 'accountingdocnumber',
        width: 200
      },
      {
        headerName: 'Accounting Date',
        field: 'accountingdate'
      },
      {
        headerName: 'For Prd From',
        field: 'forprdfrom',
        type: 'date',
        width: 150,
        valueGetter: ({ value }) => {
          if (value instanceof moment) {
            return value.format(getDateFormat());
          } else {
            return moment(value).format(getDateFormat());
          }
        },
        valueFormatter: ({ value }) => {
          if (value instanceof moment) {
            return value.format(getDateFormat());
          } else {
            return moment(value).format(getDateFormat());
          }
        },
        preProcessEditCellProps: (params) => {
          const value = params.props.value;
          let error = '';
          if (!value) {
            error = 'Please enter For Period From';
          }

          if (error) {
            setErrorMessage(error);
            return { ...params.props, error: true };
          }
          return { ...params.props, error: false };
        }
      },
      {
        headerName: 'For Prd To',
        field: 'forprdto',
        width: 150,
        type: 'date',
        valueGetter: ({ value }) => {
          if (value instanceof moment) {
            return value.format(getDateFormat());
          } else {
            return moment(value).format(getDateFormat());
          }
        },
        valueFormatter: ({ value }) => {
          if (value instanceof moment) {
            return value.format(getDateFormat());
          } else {
            return moment(value).format(getDateFormat());
          }
        },
        preProcessEditCellProps: (params) => {
          const value = params.props.value;
          let error = '';
          if (value) {
            const forPrdFrom = moment(params.row.forprdfrom);
            const forPrdTo = moment(value);
            const hasError = forPrdTo.isBefore(forPrdFrom);
            if (hasError) {
              error = 'For Period To must be after For Period From';
            }
          } else {
            error = 'Please enter For Period From';
          }

          if (error) {
            setErrorMessage(error);
            return { ...params.props, error: true };
          }
          return { ...params.props, error: false };
        }
      },
      {
        headerName: 'Party Name',
        field: 'sendername',
        minWidth: 200,
        flex: 1
      },
      {
        headerName: 'Account Class/Ledger Name',
        field: 'ledgername',
        minWidth: 200,
        flex: 1
      },
      {
        headerName: 'Item ID',
        field: 'itemid',
        width: 200
      },
      {
        headerName: 'Account Type',
        field: 'accounttype',
        width: 200
      },
      {
        headerName: 'Document Ref',
        field: 'documentref',
        width: 200
      },
      {
        headerName: 'Cost Allocation Method',
        field: 'costallocationmethod',
        minWidth: 300,
        flex: 1,
        editable: true,
        renderEditCell: renderSelectEditInputCell,
        renderCell: ({ formattedValue, value, tabIndex }) => {
          return (
            <CostAllocationCellRenderer
              CAMPeriodMap={CAMPeriodMap}
              formattedValue={formattedValue}
              value={value}
              tabIndex={tabIndex}
            />
          );
        },
        valueOptions: allKnownCAMs
      },
      {
        headerName: 'Total Amount',
        field: 'ExpAmt',
        width: 150,
        valueFormatter: (params) => {
          return Number(params.value || '').toFixed(2);
        },
        renderHeader: () => {
          const total = LOBTotalMap.get('ExpAmt');
          return (
            <Box
              lineHeight={1}
              display="flex"
              gap={1}
              flexDirection="column"
              alignItems="center">
              <p>Total</p>
              {globalhelper.currencyFormatter(total)}
            </Box>
          );
        },
        align: 'right',
        headerAlign: 'right'
      }
    ];
    const LOBColumns = [];
    for (const LOB of availableLOBs) {
      const total = LOBTotalMap.get(LOB);
      LOBColumns.push({
        headerName: LOB,
        field: LOB,
        width: 120,
        align: 'right',
        type: 'number',
        headerAlign: 'right',
        editable: true,
        renderHeader: () => {
          return (
            <Box
              lineHeight={1}
              display="flex"
              gap={1}
              flexDirection="column"
              alignItems="center">
              <p>{LOB}</p>
              {globalhelper.currencyFormatter(total)}
            </Box>
          );
        },
        valueFormatter: (params) => {
          return Number(params.value || '').toFixed(2);
        }
      });
    }
    setColumns(originalColumns.concat(LOBColumns));
  }, [availableLOBs, allKnownCAMs, setErrorMessage, CAMPeriodMap, LOBTotalMap]);

  const updateAllKnownCAMs = useCallback(
    (dynamicCAM) => {
      const CAMSet = new Set(allKnownCAMs);
      CAMSet.delete('Dynamic');
      CAMSet.add(dynamicCAM);
      const CAMList = Array.from(CAMSet.values()).sort();
      CAMList.unshift('Dynamic');
      const updatedColumns = [...columns].map((column) => {
        if (column.field === 'costallocationmethod') {
          return {
            ...column,
            valueOptions: CAMList
          };
        }
        return column;
      });
      gridRef?.current?.updateColumns?.(updatedColumns);
    },
    [allKnownCAMs, gridRef, columns]
  );

  const reCalculateCostAllocation = useCallback(
    (row) => {
      return new Promise((resolve) => {
        const newRowValues = { ...row };
        if (newRowValues.costallocationmethod === 'None') {
          newRowValues.costallocationmethod = undefined;
        }
        let allocations = new Map();
        const periods = CAMPeriodMap.get(getCAMLabel(newRowValues));
        for (const period of periods?.keys() || []) {
          const range = period.split('|');
          const fromPrd = moment(range[0], 'yyyy-MM-DD');
          const toPrd = range[1] ? moment(range[1], 'yyyy-MM-DD') : moment();
          const isWithInPeriod =
            moment(newRowValues.forprdfrom, 'yyyy-MM-DD').isSameOrAfter(fromPrd, 'day') &&
            moment(newRowValues.forprdfrom, 'yyyy-MM-DD').isSameOrBefore(toPrd, 'day');
          if (isWithInPeriod) {
            allocations = periods.get(period);
            break;
          }
        }
        let totalCostAllocationValue = 0;

        for (const LOB of availableLOBs) {
          const allocation = allocations.get(LOB);
          if (allocation) {
            totalCostAllocationValue += Number(allocation.costallocationvalue);
          }
        }

        if (!totalCostAllocationValue) {
          totalCostAllocationValue = 1;
        }
        let currentTotal = 0;
        let dynamicAllocations = [];
        const isDynamic = newRowValues.costallocationmethod === 'Dynamic';
        for (const LOB of availableLOBs) {
          const allocation = allocations.get(LOB);
          if (!isDynamic) {
            if (allocation) {
              const percent = Number(allocation.costallocationvalue) / totalCostAllocationValue;
              let costAllocation = percent * Number(newRowValues.ExpAmt);
              newRowValues[LOB] = globalhelper.roundOff(costAllocation);
            } else {
              newRowValues[LOB] = 0;
            }
          } else {
            if (newRowValues[LOB]) {
              const percent = newRowValues[LOB] / (Number(newRowValues.ExpAmt) / 100);
              dynamicAllocations.push(`${LOB}(${globalhelper.roundOff(percent)}%)`);
            }
          }

          const roundedAllocation = globalhelper.roundOff(Number(newRowValues[LOB] || '0'));
          currentTotal += roundedAllocation;
        }
        // To Do update all know CAMs and CAM map
        if (Math.round(newRowValues.ExpAmt) === Math.round(currentTotal)) {
          delete invalidCostAllocations.current[newRowValues.id];
        } else {
          invalidCostAllocations.current = {
            ...invalidCostAllocations.current,
            [newRowValues.id]: newRowValues
          };
        }
        updatedCostAllocations.current = {
          ...updatedCostAllocations.current,
          ...{ [newRowValues.id]: newRowValues }
        };
        resolve(newRowValues);
      });
    },
    [CAMPeriodMap, availableLOBs]
  );

  useEffect(() => {
    processColumns();
  }, [availableLOBs, processColumns]);

  const handleUpdateError = (error) => {
    setErrorMessage('Invalid data: Please check the values provided');
  };

  const handleCancel = () => {
    resetFilteredCostAllocation?.();
    updatedCostAllocations.current = {};
    invalidCostAllocations.current = {};
  };

  const handleSave = () => {
    if (!Object.keys(updatedCostAllocations.current).length) {
      setErrorMessage('No changes to save');
      return;
    }
    if (Object.keys(invalidCostAllocations.current).length) {
      setErrorMessage('Invalid data: Please check the values provided');
      return;
    }
    store.dispatch(showLoader());
    const updatedDocuments = new Map();
    const updatedAllocations = Object.values(updatedCostAllocations.current).map((costAllocation) => {
      updatedDocuments.set(costAllocation.createdid, {
        documentNumber: costAllocation.createdid,
        refTransactionType: costAllocation.refTransactionType,
        lineRef: costAllocation.ADLineRef
      });
      return costAllocation;
    });
    const request = {
      data: updatedAllocations
        .concat(
          costAllocations.filter((ca) => {
            const transaction = updatedDocuments.get(ca.createdid);
            return (
              transaction &&
              !updatedCostAllocations.current[ca.id] &&
              transaction.documentNumber === ca.createdid &&
              transaction.refTransactionType === ca.refTransactionType &&
              transaction.lineRef !== ca.ADLineRef
            );
          })
        )
        .map((costAllocation) => {
          availableLOBs.forEach((LOB, index) => {
            if (costAllocation[LOB] !== '0.00') {
              costAllocation[`LOB${index + 1}`] = LOB;
              costAllocation[`LOB${index + 1}Amt`] = parseFloat(costAllocation[LOB]);
            }
            delete costAllocation[LOB];
          });
          return costAllocation;
        }),
      company: company.orgid,
      userID: state.login.userData.USR_Name,
      lobList: availableLOBs
    };
    postRequest(`api/edureka/updateCAMAllocation`, request)
      .then(() => {
        handleCancel();
        setSuccessMessage('Cost allocation updated successfully. Search again to see the udpated values.');
        store.dispatch(hideLoader());
        setLoaded(false);
        updatedCostAllocations.current = {};
        invalidCostAllocations.current = {};
      })
      .catch((err) => {
        setErrorMessage('Cost Allocation failed');
        store.dispatch(hideLoader());
      });
  };

  const onColumnVisibilityChange = (event) => {
    globalhelper.debounce(() => store.dispatch(updateUserConfiguration({ costContributionColumns: event })), 5000);
  };

  return (
    <StyledDataGrid
      columns={columns}
      rows={rows}
      apiRef={gridRef}
      initialState={initialState.current}
      sx={{ width: '100%', height: '100%' }}
      editMode="row"
      experimentalFeatures={{ newEditingApi: true }}
      getCellClassName={getCellClassName}
      getRowClassName={getRowClassName}
      onColumnVisibilityModelChange={onColumnVisibilityChange}
      processRowUpdate={reCalculateCostAllocation}
      isCellEditable={(params) => checkIfCellEditable(params)}
      onProcessRowUpdateError={handleUpdateError}
      slots={{
        toolbar: CostContributionToolbar,
        noRowsOverlay: () => (
          <Stack
            alignItems="center"
            justifyContent="center"
            height="100%">
            <Typography variant="h6"> Search for Cost Allocation By Company </Typography>
          </Stack>
        ),
        noResultsOverlay: () => (
          <Stack alignItems="center">
            <Typography variant="h6"> No results found for the given criteria </Typography>
          </Stack>
        )
      }}
      slotProps={{
        filterPanel: {
          filterFormProps: {
            linkOperatorInputProps: {
              variant: 'outlined',
              size: 'small'
            },
            columnInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' }
            },
            operatorInputProps: {
              variant: 'outlined',
              size: 'small',
              sx: { mt: 'auto' }
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'outlined',
                size: 'small'
              }
            },
            deleteIconProps: {
              sx: {
                '& .MuiSvgIcon-root': { color: '#d32f2f' }
              }
            }
          },
          sx: {
            '& .MuiDataGrid-filterForm': { p: 2 },
            '& .MuiDataGrid-filterForm:nth-child(even)': {
              backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#444' : '#f5f5f5')
            },
            '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
            '& .MuiDataGrid-filterFormColumnInput': {
              mr: 2,
              width: 150
            },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
            '& .MuiDataGrid-filterFormValueInput': { width: 200 }
          }
        },
        baseSelect: {
          MenuProps: { sx: { maxHeight: 400, maxWidth: '100%' } }
        },
        toolbar: {
          availableCAMs: allKnownCAMs,
          costAllocationMap: CAMPeriodMap,
          availableLOBs,

          onCancel: handleCancel,
          onSave: handleSave,
          onUpdate: reCalculateCostAllocation,
          updatedRows: updatedCostAllocations.current
        }
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    state
  };
}

export default connect(mapStateToProps)(CostContributionTable);
