import React from 'react';
import { connect } from 'react-redux';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import {onPopupFieldChange,onPopupNodeAdd} from '../actions/popupAction'

class Mypopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { nodeName: '', nodeType: 'group', fieldName: '' , position:''};
        console.log(this.props)
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;     
      
        this.props.onPopupNodeAdd({ [nam]: val, position:''});
    }
    myfieldHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.props.onPopupFieldChange({ [nam]: val, nodeType: 'field' });
    }

    submitPopupData = (event) => {
        event.preventDefault();
        console.log("On Submission :", this.props.popupData)
        this.props.getData(this.props.popupData, this.props.data);
    }
    render() {
        var popupcontent;

        if (this.props.data.node.type === 'group') {

            popupcontent = <div> <h3>Add Field</h3>
                <div className="row p-3">
                    <div className="col-lg-6">
                        <label>Field Name</label>
                    </div>
                    <div className="col-lg-6">
                        <input type="text" name="fieldName" onChange={this.myfieldHandler} />
                    </div>
                </div>
                <div className="row p-3 ">
                    <button className="btn btn-primary" onClick={this.submitPopupData}>Submit </button>
                </div>
            </div>
        } else {

            if (this.props.data.node.children === undefined) {
                popupcontent = <div><h3>Edit Field</h3>
                    <div className="row p-3">
                        <div className="col-lg-6">
                            <label>Field position:</label>
                        </div>
                        <div className="col-lg-6">
                            <input type="radio"  name="position" onChange={this.myfieldHandler}  value="header"/>
                            <label className="radio-button-label"> Header</label>
                            <input type="radio" name="position" onChange={this.myfieldHandler} value="footer" />
                            <label className="radio-button-label"> Footer</label>
                        </div>
                    </div>
                    {/* <div className="row p-3">
                        <div className="col-lg-6">
                            <label>Status:</label>
                        </div>
                        <div className="col-lg-6">
                            <input type="radio"  name="enable" onChange={this.myfieldHandler}  value={true}/>
                            <label className="radio-button-label"> Enable</label>
                            <input type="radio" name="enable" onChange={this.myfieldHandler} value={false} />
                            <label className="radio-button-label"> Disable</label>
                        </div>
                    </div> */}
                    <div className="row p-3 ">
                        <button className="btn btn-primary" onClick={this.submitPopupData}>Submit </button>
                    </div>
                </div>

            } else {

                popupcontent = <div> <h3>Add Node</h3>
                    <div className="row p-3">
                        <div className="col-lg-6">
                            <label>Node Type</label>
                        </div>
                        <div className="col-lg-6">
                            <select name="nodeType" onChange={this.myChangeHandler}>
                                <option value="group" >Group</option>
                                <option value="field">Field</option>
                            </select>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-lg-6">
                            <label>Node Name</label>
                        </div>
                        <div className="col-lg-6">
                            <input type="text" name="nodeName" onChange={this.myChangeHandler} />
                        </div>
                    </div>
                    <div className="row p-3 ">
                        <button className="btn btn-primary" onClick={this.submitPopupData}>Submit </button>
                    </div>
                </div>
            }
        }




        return (
            <Rodal visible={this.props.show} onClose={this.props.hide}>
                <div>
                    {popupcontent}
                </div>
            </Rodal>
        );
    }
}


function mapStateToProps(state) {
    console.log(state.popupReducer.enable)
    return {
        popupData: { 
            nodeName: state.popupReducer.nodeName, 
            nodeType: state.popupReducer.nodeType, 
            fieldName: state.popupReducer.fieldName ,
            enable: state.popupReducer.enable,
            position:state.popupReducer.position
        }
    }
}




export default connect(
    mapStateToProps,
    { onPopupFieldChange,onPopupNodeAdd  }
)(Mypopup);