import React, { Component } from 'react';
import { Form, Button} from 'react-bootstrap';
import {getRequest, getHumanReadableDate, convertToINR} from '../../globalhelper/helper';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ApiConstants from '../../config'
import download from 'downloadjs';
import axios from 'axios'
import './COD.css'
import Pagination1 from './Pagination';
import { CSVLink } from 'react-csv';

export class DepositHistory extends Component {
    constructor(props) {
      super(props)
   
      this.state = {
         formData: {
            client: '',
            model: '',
            zone: '',
            stationcode: '',
            startdate: '',
            enddate: '',
            status: ''
         },
         downloadData: [{
            client: '',
            model: '',
            zone: '',
            stationcode: '',
            date: '',
            depositedamount: '',
            slipnumber: '',
            postedon: '',
            postedby: '',
            approvalstatus: '',
            depositstatus: '',
            pendingamount: ''
         }],
         error: '',
         allData: [],
         filterTable: [],
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         dropdown_stationcode: [],
         tableData: [],
         show_filtertable: false,
         show_maintable: false,
         fetching: false,
         currentPage1: 1,
         currentPage2: 1,
         postsPerPage1: 15,
         postsPerPage2: 15,
         tableDisplay: [],
         filterDisplay: [],
         uniqueModel: [],
         uniqueZone: [],
         uniqueStationcode: [],
         uniqueData: []
      }
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            fetching: true
        })

        // Function to get all the deposit history records

        getRequest('api/cod/getDepositReport').then(response => {
            if(response.res.data !== undefined) {
                let tableData = response.res.data
                let client = []
                tableData.forEach(item => {
                    client.push(item.client)
                })

                let dropdown_model = response.res.data.map(value=> value.businessmodel)
                let dropdown_zone = response.res.data.map(value => value.zone)
                let dropdown_stationcode = response.res.data.map(value => value.stationcode)

                let temp = [... new Set(client)]
                let temp1 = [... new Set(dropdown_stationcode)]
                this.setState({
                    ...this.state,
                    allData: response.res.data,
                    dropdown_client: temp,
                    uniqueModel: dropdown_model,
                    uniqueZone: dropdown_zone,
                    uniqueStationcode: temp1,
                    uniqueData: tableData,
                    dropdown_stationcode: temp1,
                    fetching: false,
                    show_maintable: true
                }, () => this.handlePagination1())
            }
            else {
                console.log('No data')
            }
        })
    }

    // Filters by client, first sets the Client state and trigger the client filter function

    handleClientChange = (event) => {
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                client: VALUE
            }
        })

        let arrModel = []

        this.state.allData.forEach((item) => {
            if(item.client === VALUE){
                arrModel.push(item.businessmodel)
                console.log(arrModel)
                return arrModel
            }
        })

            let arr1 = [...new Set(arrModel)]
            this.setState({
                ...this.state,
                dropdown_model: arr1,
                dropdown_stationcode: [],
                dropdown_zone: [],
                formData: {
                    ...this.state.formData,
                    model: '',
                    stationcode: '',
                    zone: '',
                    status: '',
                    client: VALUE
                }
            }, () => this.handleClientFilter()) 
    }

    // Filter by Client function

    handleClientFilter = () => {
        let newArray = []

        if(this.state.filterTable.length > 0){
            this.state.filterTable.forEach(item => {
                if(item.client === this.state.formData.client){
                    newArray.push(item)
                }
            })
        } else {
            this.state.allData.forEach(item => {
                if(item.client === this.state.formData.client){
                    newArray.push(item)
                    console.log(newArray)
                }
            })
        }

        const newArray1 = newArray.map(obj => {
            const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
            return rest
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage2: 1,
            filterTable: newArray1.length >= 1 ? newArray1 : []
        },()=>this.handlePagination2())
    }

    // Filters by Model, first sets the Nodel state and trigger the model filter function

    handleModelChange = (event) => {
        const VALUE = event.target.value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        let arrZone = []

        this.state.allData.forEach(each => {
            if(each.client === this.state.formData.client && each.businessmodel === VALUE){
                arrZone.push(each.zone)
                return arrZone
            }
        })
        let arr2 = [...new Set(arrZone)]
            this.setState({
                ...this.state,
                dropdown_zone: arr2,
                dropdown_stationcode: [],
                formData: {
                    ...this.state.formData,
                    stationcode: '',
                    zone: '',
                    status: '',
                    model: VALUE
                    }
                }, () => this.handleModelFilter())
        
    }

    // Model Filter function

    handleModelFilter = () => {
        let newArray = []

        this.state.allData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                newArray.push(item)
                console.log(newArray)
            }
        })

        const newArray1 = newArray.map(obj => {
            const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
            return rest
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage2: 1,
            filterTable: newArray1.length >= 1 ? newArray1 : []
        },()=>this.handlePagination2())
    }

    // Sets the start date

    handleChange = (event) => {
        const NAME = event.target.name, VALUE = event.target.value
        this.setState({
            ...this.state,
            error: '',
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    // Date validation function

    handleEndDateChange = (event) => {
        const NAME = event.target.name
        const VALUE = event.target.value 

        if(VALUE < this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'End date must be greater than start date',
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        } else if (!this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'Please select start date first',
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: '',
                formData: {
                    ...this.state.formData,
                    [NAME]: VALUE
                }
            })
        }
    }

    // Handles Zone change, sets the zone and triggers the zone filter function

    handleZoneChange = (event) => {
        const VALUE = event.target.value;

        let stationcode = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                zone : VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.zone === VALUE && item.client === this.state.formData.client && item.businessmodel === this.state.formData.model){
                stationcode.push(item.stationcode)
                return stationcode
            }
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formData: {
                ...this.state.formData,
                stationcode: '',                
                zone: VALUE
            }
        }, () => this.handleZoneFilter())
    }

    // Handles filter by Zone

    handleZoneFilter = () => {
        let newArray = []

        this.state.allData.forEach(item => {
            if(item.client === this.state.formData.client && item.businessmodel === this.state.formData.model && item.zone === this.state.formData.zone){
                newArray.push(item)
                console.log(newArray)
            }
        })

        const newArray1 = newArray.map(obj => {
            const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
            return rest
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage2: 1,
            filterTable: newArray1.length >= 1 ? newArray1 : []
        }, () => this.handlePagination2())
    }

    // Sets the status in the state and triggers the status filter function

    handleStatusChange = (event) => {
        const VALUE = event.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                status: VALUE
            }
        }, () => this.handleStatusFilter())
    }

    // Handles filter by status function

    handleStatusFilter = () => {

        let client = [this.state.formData.client]
        let model = [this.state.formData.model]
        let zone = [this.state.formData.zone]
        let stationcode = [this.state.formData.stationcode]
        let status = [this.state.formData.status]
        let data = this.state.uniqueData

        console.log(client, model, zone, stationcode, status)

        if(!client[0]){
            client = this.state.dropdown_client
        }

        if(!model[0]){
        model = this.state.uniqueModel
       }

       if(!zone[0]){
        zone = this.state.uniqueZone
       }

       if(!stationcode[0]){
        stationcode = this.state.uniqueStationcode
       }

       let filteredData = []

       for(const[i, item] of data.entries()){
        console.log(item.client, item.businessmodel, item.zone, item.stationcode, item.approvalstatus)
        if(client.includes(item.client) && model.includes(item.businessmodel) && zone.includes(item.zone) && stationcode.includes(item.stationcode) && status.includes(item.approvalstatus)){
            filteredData.push(item)
        }

        const newArray1 = filteredData.map(obj => {
            const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
            return rest
        })

        if(i===data.length-1){
            this.setState({
                ...this.state,
                show_filtertable: true,
                show_maintable: false,
                currentPage2: 1,
                filterTable: newArray1.length >= 1 ? newArray1 : [] 
            }, () => this.handlePagination2())
        }
       }
    }

    handleStationChange = (event) => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                stationcode: event.target.value
            }
        }, () => this.handleStationFilter())
    }

    // Handles filter by Station change

    handleStationFilter = () => {
        let newArray = []

        if(this.state.filterTable.length >0){
            this.state.filterTable.forEach(item => {
                if(item.stationcode === this.state.formData.stationcode){
                    newArray.push(item)
                }               
            })
        } else {
            this.state.allData.forEach(item => {
                if(item.stationcode === this.state.formData.stationcode){
                    newArray.push(item)
                    console.log(newArray)
                }
            })
        }

        const newArray1 = newArray.map(obj => {
            const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
            return rest
        })

        this.setState({
            ...this.state,
            show_filtertable: true,
            show_maintable: false,
            currentPage2: 1,
            filterTable: newArray1.length >= 1 ? newArray1 : []
        }, ()=> this.handlePagination2())
    }

    // Handles the filter by station code, and date range

    filterContents = async () => {

        const startdate = this.state.formData.startdate
        const enddate = this.state.formData.enddate
        const stationcode = this.state.formData.stationcode

        await getRequest(`api/cod/filterContents?startdate=${startdate}&enddate=${enddate}&stationcode=${stationcode}`).then(response => {
            let tableData = response.res.data
            // console.log(tableData)

            const tableDatafiltered = tableData.map(obj => {
                const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
                return rest
            })

            this.setState({
                ...this.state,
                show_filtertable: true,
                show_maintable: false,
                currentPage2: 1,
                filterTable: tableDatafiltered.length >= 0 ? tableDatafiltered: []
            }, () => this.handlePagination2())
        })
    }

    // Downloads the deposit slips(receipt)

    downloadReceipt = (item) => {
        const fileName = item.receipt
        console.log(fileName)
        axios.post(ApiConstants.externals.serverUrl +  `api/tds/downloadConvertedFile`, {filename: fileName} ,
          { responseType: 'blob' }
        )
        .then(response => {
                console.log(response.data)
                const blob = response.data;
                download(blob, item.receipt);
        })
    }

    // Downloads the ERP Screenshot

    downloadERP = (item) => {
        const fileName = item.erpscreenshot
        console.log(fileName)
        axios.post(ApiConstants.externals.serverUrl +  `api/tds/downloadERPFile`, {filename: fileName} ,
          { responseType: 'blob' }
        )
        .then(response => {
                console.log(response.data)
                const blob = response.data;
                download(blob, item.erpscreenshot);
        })
    }

    // Function to download the deposit report as csv

    downloadDepositReport = async () => {
        if(this.state.filterTable.length <=0){
            
            await getRequest('api/cod/getDepositReport').then(response => {
                let tableData = response.res.data

                const tableDatafiltered = tableData.map(obj => {
                    const {inprdfrom, inprdto, pk, sk, checksum, transactionid, input, output,  ...rest } = obj
                    return rest
                })
    
                this.setState({
                    ...this.state,
                    tableData: tableDatafiltered
                }, ()=> {
                    this.downloadLink.link.click()
                })
            })
        } else {
            this.setState({
                ...this.state,
                filterTable: this.state.filterTable
            }, () => {
                this.downloadLink.link.click()
            })
        }
    }

    // Pagination for tables


    handlePagination1 = () => {
        let indexOfLastPost = this.state.currentPage1 * this.state.postsPerPage1
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage1
        // console.log(this.state.allData)
        let currentPosts = this.state.allData.sort((a,b)=>b.date.localeCompare(a.date)).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            tableDisplay: currentPosts
        })
    }

    handlePagination2 = () => {
        console.log(this.state.currentPage2)
        let indexOfLastPost = this.state.currentPage2 * this.state.postsPerPage2
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage2
        // console.log(this.state.allData)
        let currentPosts = this.state.filterTable.sort((a,b)=>b.date.localeCompare(a.date)).slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            filterDisplay: currentPosts
        })
    }

    paginate1 = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage1: pageNumber
        }, () => this.handlePagination1())
    }

    paginate2 = (pageNumber) => {
        console.log(pageNumber)
        this.setState({
            ...this.state,
            currentPage2: pageNumber
        }, () => this.handlePagination2())
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            error: '',
            show_filtertable: false,
            show_maintable: true,
            filterDisplay: [],
            filterTable: [],
            dropdown_model: [],
            dropdown_zone: [],
            dropdown_stationcode: this.state.uniqueStationcode,
            formData: {
                client: '',
                model: '',
                zone: '',
                stationcode: '',
                status: '',
                startdate: '',
                enddate: ''
            }
        })
    }


  render() {
    return (
      <div>
        <div className='header_div'>
            COD Daily Submission History
        </div>

        {!this.state.fetching && this.state.allData.length > 0 ? 
        <div>

            <div>
                <Button variant='success' style={{marginLeft:10}} className='pull-right' onClick={this.downloadDepositReport}>Download</Button>
                <CSVLink data={this.state.filterTable.length<=0 ? this.state.tableData : this.state.filterTable} filename='Deposit_History.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
            </div>

            <div>
                <Button variant='danger' className='pull-right' onClick={this.handleReset}>Reset Filter</Button>
            </div>
        </div>: null}

        <br/>
        <br/>

        {!this.state.fetching && this.state.allData.length > 0 ? 
        
        <div className='row'>
            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Client</Form.Label>
                    <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_client.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Model</Form.Label>
                    <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_model.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Zone</Form.Label>
                    <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_zone.sort().map((item,index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Station Code</Form.Label>
                    <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationChange}>
                        <option value='' disabled>Select</option>
                        {this.state.dropdown_stationcode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                    </select>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <input type='date' className='spacing' value={this.state.formData.startdate} name='startdate' onChange={this.handleChange}></input>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <input type='date' className='spacing' value={this.state.formData.enddate} name='enddate' onChange={this.handleEndDateChange}></input>
                </Form.Group>
                {this.state.error &&<div style={{color:'red'}}>{this.state.error}</div>}
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <select className='spacing' value={this.state.formData.status} name='status' onChange={this.handleStatusChange}>
                        <option value='' disabled>Select</option>
                        <option value='Approved'>Approved</option>
                        <option value='Rejected'>Rejected</option>
                        <option value='Pending For Approval'>Pending For Approval</option>
                    </select>
                </Form.Group>
            </div>
            
        </div> : null
    }
        {this.state.fetching ? <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div>: null}

        
        {!this.state.fetching && this.state.allData.length > 0 ?
        <div className='wrapper'>
            <Button onClick={this.filterContents} disabled={!this.state.formData.enddate}>Filter</Button>
        </div> : null}
        
        <br/>
        <br/>

        {this.state.show_maintable && this.state.allData.length > 0 ?       
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Business Model</th>
                        <th>Zone</th>
                        <th>Station Code</th>
                        <th style={{minWidth: 90}}>Deposit Date</th>
                        <th style={{minWidth: 100}}>Submitted On</th>
                        <th>Posted By</th>
                        <th>Deposit Status</th>
                        <th>Receipt Name</th>
                        <th>ERP Screenshot</th>
                        <th>Slip Number</th>
                        <th>Deposit Amount</th>
                        <th>Pending Amount</th>
                        <th>Reason</th>
                        <th>Rejection Reason</th>
                        <th>Approved By</th>
                        <th>Approved On</th>
                        <th>Approval Status</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.tableDisplay.map((item, index) => {
                        // Initialize the original date string
                        
                    const originalDate = item.postedon
                    const approvedDate = item.approvedon
                    // console.log(originalDate) 

                    // Create a new Date object from the original string
                    const dateObject = new Date(originalDate);
                    const dateObject1 = new Date(approvedDate)

                    // Extract the day, month, and year from the date object
                    const day = dateObject.getDate().toString().padStart(2, '0');
                    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                    const year = dateObject.getFullYear();
                    const hours = dateObject.getHours().toString().padStart(2, '0');
                    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
                    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

                    const day1 = dateObject1.getDate().toString().padStart(2, '0');
                    const month1 = (dateObject1.getMonth() + 1).toString().padStart(2, '0');
                    const year1 = dateObject1.getFullYear();
                    const hours1 = dateObject1.getHours().toString().padStart(2, '0');
                    const minutes1 = dateObject1.getMinutes().toString().padStart(2, '0');
                    const seconds1 = dateObject1.getSeconds().toString().padStart(2, '0');

                    // Build up a new string in the desired format
                    const newDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
                    const newDate1 = `${day1}-${month1}-${year1} ${hours1}:${minutes1}:${seconds1}`;
                    // console.log(newDate)

                        return(
                            <tr key={index}>
                                <td>{item.client}</td>
                                <td>{item.businessmodel}</td>
                                <td>{item.zone}</td>
                                <td>{item.stationcode}</td>
                                <td>{getHumanReadableDate(item.date)}</td>
                                <td>{newDate === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate}</td>
                                <td>{item.postedby}</td>
                                <td>{item.depositstatus}</td>
                                <td><Button variant='link' onClick={() => this.downloadReceipt(item)} disabled={!item.receipt}><FileDownloadIcon/></Button></td> 
                                <td><Button variant='link' onClick={() => this.downloadERP(item)} disabled={!item.erpscreenshot}><FileDownloadIcon/></Button></td>  
                                <td>{item.slipnumber}</td>
                                <td>{convertToINR(item.depositamount)}</td>
                                <td>{convertToINR(item.pendingamount)}</td>
                                <td>{item.reason}</td>
                                <td>{item.rejectionreason}</td>
                                <td>{item.approvedby}</td>
                                <td>{newDate1 === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate1}</td>
                                <td>{item.approvalstatus}</td>
                            </tr>
                        )
                    })}                        
                </tbody>                   
            </table>
        </div> : null}

        {this.state.show_filtertable && this.state.filterTable.length > 0 ?       
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Business Model</th>
                        <th>Zone</th>
                        <th>Station Code</th>
                        <th style={{minWidth: 90}}>Deposit Date</th>
                        <th style={{minWidth: 100}}>Submitted On</th>
                        <th>Posted By</th>
                        <th>Deposit Status</th>
                        <th>Receipt Name</th>
                        <th>ERP Screenshot</th>
                        <th>Slip Number</th>
                        <th>Deposit Amount</th>
                        <th>Pending Amount</th>
                        <th>Reason</th>
                        <th>Rejection Reason</th>
                        <th>Approved By</th>
                        <th>Approved On</th>
                        <th>Approval Status</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.filterDisplay.sort((a,b)=>b.date.localeCompare(a.date)).map((item, index) => {
                        // Initialize the original date string
                        
                    const originalDate = item.postedon
                    const approvedDate = item.approvedon
                    // console.log(originalDate) 

                    // Create a new Date object from the original string
                    const dateObject = new Date(originalDate);
                    const dateObject1 = new Date(approvedDate)

                    // Extract the day, month, and year from the date object
                    const day = dateObject.getDate().toString().padStart(2, '0');
                    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
                    const year = dateObject.getFullYear();
                    const hours = dateObject.getHours().toString().padStart(2, '0');
                    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
                    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

                    const day1 = dateObject1.getDate().toString().padStart(2, '0');
                    const month1 = (dateObject1.getMonth() + 1).toString().padStart(2, '0');
                    const year1 = dateObject1.getFullYear();
                    const hours1 = dateObject1.getHours().toString().padStart(2, '0');
                    const minutes1 = dateObject1.getMinutes().toString().padStart(2, '0');
                    const seconds1 = dateObject1.getSeconds().toString().padStart(2, '0');

                    // Build up a new string in the desired format
                    const newDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
                    const newDate1 = `${day1}-${month1}-${year1} ${hours1}:${minutes1}:${seconds1}`;
                    // console.log(newDate)

                        return(
                            <tr key={index}>
                                <td>{item.client}</td>
                                <td>{item.businessmodel}</td>
                                <td>{item.zone}</td>
                                <td>{item.stationcode}</td>
                                <td>{getHumanReadableDate(item.date)}</td>
                                <td>{newDate === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate}</td>
                                <td>{item.postedby}</td>
                                <td>{item.depositstatus}</td>
                                <td><Button variant='link' onClick={() => this.downloadReceipt(item)} disabled={!item.receipt}><FileDownloadIcon/></Button></td> 
                                <td><Button variant='link' onClick={() => this.downloadERP(item)} disabled={!item.erpscreenshot}><FileDownloadIcon/></Button></td>  
                                <td>{item.slipnumber}</td>
                                <td>{convertToINR(item.depositamount)}</td>
                                <td>{convertToINR(item.pendingamount)}</td>
                                <td>{item.reason}</td>
                                <td>{item.rejectionreason}</td>
                                <td>{item.approvedby}</td>
                                <td>{newDate1 === "NaN-NaN-NaN NaN:NaN:NaN" ? null : newDate1}</td>
                                <td>{item.approvalstatus}</td>
                            </tr>
                        )
                    })}                        
                </tbody>                   
            </table>
        </div> : null}

        {this.state.allData.length <= 0 && !this.state.fetching? <div className='center-screen'>
            <h5>No Data Found</h5>
        </div>: null}
     
        <br/>
        
        {this.state.show_maintable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage1} totalPosts={this.state.allData.length} paginate={this.paginate1}></Pagination1>
        </div>       
        : null }

        {this.state.show_filtertable ?
        <div className='mt-2'>
            <Pagination1 postsPerPage={this.state.postsPerPage2} totalPosts={this.state.filterTable.length} paginate={this.paginate2}></Pagination1>
        </div>       
        : null }
          
      </div>
    )
  }
}

export default DepositHistory