import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './404.css';
import Wip from '../../assets/wip.jpg';
import '../../App.css';

class Nopage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};

    }


    render (){
        return (
            <div className="flex-screen-404">
                {/* <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red !important',  height: '85vh', fontWeight : '900', fontSize : '1.5rem'}}>
                    <label style={{ color: 'red',  fontWeight : '900', fontSize : '1.5rem'}}> Error 404. Page Not Found </label>
                </div> */}
                <img src={Wip} alt="Work In Progress" className="wip-image" />
            </div>
        );
    }
}

export default Nopage;
