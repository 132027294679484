import { Select } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

function SelectEditInputCell(props) {
  const { id, value, field, row, api } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopRowEditMode({id, field });
    apiRef.current.publishEvent('rowEditStop', { id, field, row, columns: api.getAllColumns(), reason:'row-edit-close'});
  };
  return (
    <Select
      value={value}
      onChange={handleChange}
      defaultValue={null}
      native
      fullWidth
      autoFocus
    >
      {props.colDef.valueOptions.map((option) => (
        <option key={option} value={option}>{option}</option>
      ))}
    </Select>
  );
}

export const renderSelectEditInputCell = (params) => {
  return <SelectEditInputCell {...params} />;
};
