import React, {useState, useEffect} from 'react'
import { getRequest, postRequest } from '../../globalhelper/helper'
import { Animated } from "react-animated-css";
import { useDispatch, useSelector } from 'react-redux';


function AdminScreen() {

  const [alert, setAlert] = useState("")
  const [AllUsers, setAllUsers] = useState([])
  const [All_RA, setAll_RA] = useState([])
  const [AllRoleNames, setAllRoleNames] = useState([])
  const [All_UPA, setAll_UPA] = useState([])
  const [All_Modules, setAll_Modules] = useState([])
  const [All_Menus, setAll_Menus] = useState([])
  const [Action, setAction] = useState("")
  const [nameofuser, setnameofuser] = useState("")
  const [Email, setEmail] = useState("")
  const [Pass, setPass] = useState("")
  const [RoleType, setRoleType] = useState("")
  const [RoleName, setRoleName] = useState("")
  const [Items, setItems] = useState("")
  const [Process, setProcess] = useState("")
  const [Buton, setButon] = useState(true)

  const state = useSelector(state => state);

  useEffect( () => {
    fetchData()
  },[])

  const fetchData = () => {

    getRequest(`api/admin/getAllUsers`)
    .then( res => {
        console.log(res.res.data)
        setAllUsers(res.res.data)
    })
    getRequest(`api/admin/getAll_RA`)
    .then( res => {
        const data = res.res.data
        console.log(data)
        setAll_RA(data)

        const AllRole_Names = [...new Set(data.map(each => each.RoleName))]

        setAllRoleNames(AllRole_Names)
    })
    getRequest(`api/admin/getAll_UPA`)
    .then( res => {
        console.log(res.res.data)
        setAll_UPA(res.res.data)
    })
    getRequest(`api/admin/getAll_Modules?module=${localStorage.getItem("module")}`)
    .then( res => {
        console.log(res.res.data)
        setAll_Modules(res.res.data)
    })
    getRequest(`api/admin/getAll_Menus`)
    .then( res => {
        console.log(res.res.data)
        setAll_Menus(res.res.data)
    })
  }


  const OnReload = () => {
    setAllUsers([])
    setAll_RA([])
    setAllRoleNames([])
    setAll_UPA([])
    setAll_Modules([])
    setAll_Menus([])
    setAction("")
    setnameofuser("")
    setEmail("")
    setPass("")
    setRoleType("")
    setRoleName("")
    setItems("")
    setProcess("")
    setButon(true)

    setTimeout(() => {
      fetchData()
    }, 500);
  }

    
  function validateEmailFormat(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  function isSameOrganization(givenEmail, existingEmail) {
      const getDomain = email => email.substring(email.lastIndexOf("@") + 1);
      return getDomain(givenEmail) === getDomain(existingEmail);
  }

  function isStrongPassword(password) {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    if (password.length < minLength) {
      return "Password must be at least 8 characters long."
    }
    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter."
    }
    if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter."
    }
    if (!hasNumbers) {
      return "Password must contain at least one number."
    }
    if (!hasSpecialChars) {
      return "Password must contain at least one special character."
    }
  
    return true;
  }
  

  const CreateNewUser = () => {

    let isStrongPass = isStrongPassword(Pass)

    if (!validateEmailFormat(Email)) {
      return TopErrAlert("Invalid email format")
    }
    // else if (!isSameOrganization(Email, state.login.userData.USR_Email)) {
    //   return TopErrAlert("Email is not from the same organization")
    // }
    else if(typeof isStrongPass === "string"){
      return TopErrAlert(isStrongPass)
    }
    else{
      setButon(false)

      let send = JSON.parse(JSON.stringify(AllUsers[0]))
  
      send.PK = `#USR#${Email}`
      send.SK = `#Header#${Email}`
      send.USR_Email = Email
      send.USR_Name = nameofuser  
  
      postRequest(`api/admin/CreateNewUser`, {data: send, email:Email, pass: Pass})
      .then(res => {
        console.log(res)
        OnReload()
      })
      
    }
  }

  const DeleteUser = () => {

    setButon(false)    
    getRequest(`api/admin/DeleteUser?email=${Email}`)
    .then( res => {
      console.log(res)
      OnReload()
    })
  }

  const CreateRole = () => {

    setButon(false)
    let send = {
      RoleType : RoleType,
      RoleName : RoleName,
      Items : Items
    }
    console.log(send)

    postRequest(`api/admin/CreateRole`, send)
    .then( res => {
      console.log(res)
      OnReload()
    })
  }

  const MapRole = () => {
    
    setButon(false)
    let send = {
      RoleName : RoleName,
      Process : Process,
      Email : Email
    }
    console.log(send)

    postRequest(`api/admin/Map_Or_UnMap_Role`, send)
    .then( res => {
      console.log(res)
      OnReload()
    })
  }



  const TopErrAlert = (msg) => {

    setAlert(<Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong>{msg}</strong></div> </Animated>)
    setTimeout(() => { setAlert(null) }, 3000);
  }

  return (
    <div>
      {alert}
      <div className="row mt-2">

        <div className='col-md-2'>
            <label htmlFor="action" style={{fontSize: '1rem'}}> Action </label>
            <select className="browser-default custom-select" id="action" autoFocus name="action" value={Action} onChange={(e) => setAction(e.target.value)} >
                <option value=""> select </option>
                <option value="CreateUser"> Create User </option>
                <option value="RemoveUser"> Remove User </option>
                <option value="CreateRole"> Create Role </option>
                <option value="AssignRole"> Map / UnMap Role </option>
            </select>
        </div>
        
      </div>
      
      { Action === "CreateUser" &&

        <div className='row mt-3'>

          <div className='col-md-2'>
              <label htmlFor="name" style={{fontSize: '1rem'}}> Name </label>
              <input id="name" name="name" type='text' value={nameofuser} onChange={(e) => setnameofuser(e.target.value)} placeholder='Name' />
          </div>
          <div className='col-md-3'>
              <label htmlFor="email" style={{fontSize: '1rem'}}> Email </label>
              <input id="email" name="email" type='text' value={Email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' style={{width: '18rem'}} />
          </div>
          <div className='col-md-2'>
              <label htmlFor="Password" style={{fontSize: '1rem'}}> Password </label>
              <input id="password" name="password" type='text' value={Pass} onChange={(e) => setPass(e.target.value)} placeholder='Password' />
          </div>
          <div className='col-md-2'>
              <button className='btn btn-success mt-4' disabled={!nameofuser || !Email || !Pass || !Buton} onClick={CreateNewUser}>Create</button>
          </div>

        </div>      
      }


      { Action === "RemoveUser" &&

        <div className='row mt-3'>

          <div className="col-md-3">
              <label htmlFor="email" style={{fontSize: '1rem'}}> Email </label>
              <select className="browser-default custom-select" id="email" name="email" value={Email} onChange={(e) => setEmail(e.target.value)} style={{width: '19rem'}} >
                  <option value=""> select </option>
                  {AllUsers.map( (each, indx) => {
                    if(state.login.userData.USR_Email !== each.USR_Email){
                      return(
                        <option key={indx} value={each.USR_Email}>{each.USR_Email}</option>
                      )
                    }
                  })}
              </select>
          </div>
          <div className='col-md-2'>
              <button className='btn btn-danger mt-4' disabled={!Email || !Buton} onClick={DeleteUser}>Delete</button>
          </div>

        </div>      
      }


      {
        ["CreateUser", "RemoveUser"].includes(Action) && 

        <div className="scroll-table mt-4" style={{width: "500px"}}>
          <table id="table-css">
            <thead>
                <tr>
                    <th style={{width: '1rem'}}>Existing Users</th>                       
                </tr>
            </thead>
            <tbody>
                {AllUsers.map((item, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{item["USR_Email"]}</td>
                    </tr>
                ))}                    
            </tbody>
          </table>
        </div>
      }
      

      {
        Action === "CreateRole" &&

        <>
        <div className='row mt-3'>

          <div className='col-md-2'>
            <label htmlFor="RoleType" style={{fontSize: '1rem'}}> RoleType </label>
            <select className="browser-default custom-select" id="RoleType" name="RoleType" value={RoleType} 
            onChange = {
              (e) => {
                setRoleType(e.target.value);
                if(e.target.value === "ActivityCode" ) setRoleName("ActivityCode-")
                else setRoleName("")
              }
            }>
              <option value=""> select </option>
              <option value="Module"> Module </option>
              <option value="MenuList"> MenuList </option>
              <option value="LPage"> LandingPage </option>
              <option value="ActivityCode"> ActivityCode </option> 
            </select>
          </div>

          {/* { RoleType &&  */}          
            <div className='col-md-4'>
                <label htmlFor="RoleName" style={{fontSize: '1rem'}}> RoleName </label> <br />
                <input id="RoleName" name="RoleName" type='text' value={RoleName} onChange={(e) => {setRoleName(e.target.value); setItems("")}} placeholder='RoleName' />(or)
                <select id="RoleName" name="RoleName" value={RoleName} onChange={(e) => {setRoleName(e.target.value); setItems("")}} >
                  <option value=""> select </option>
                  {AllRoleNames.map( (each, indx) => {
                    return(
                      <option key={indx} value={each}>{each}</option>
                    )
                  })}
                </select>
            </div>
          {/* } */}

          { ["Module", "MenuList", "LPage"].includes(RoleType) && RoleName &&
            
            <div className='col-md-2'>
              <label htmlFor={RoleType} style={{fontSize: '1rem'}}> {RoleType} </label> <br />
              <select id={RoleType} name={RoleType} value={Items} onChange={(e) => setItems(e.target.value)} >
                <option value=""> select </option>

                {RoleType !== "LPage" &&
                  <option value="*"> All(*) </option>
                }

                {RoleType === "Module" && All_Modules.map( (each, indx) => {
                  return(
                    <option key={indx} value={each.name}>{each.name}</option>
                  )
                })}

                {["MenuList", "LPage"].includes(RoleType) && All_Menus.map( (each, indx) => {
                  return(
                    <option key={indx} value={each.url}>{each.name}</option>
                  )
                })}

              </select>
            </div>
          }

          { RoleType === "ActivityCode" && RoleName &&

            <div className='col-md-2'>
              <label htmlFor="ActivityCode" style={{fontSize: '1rem'}}> ActivityCode </label> <br />
              <input id="ActivityCode" name="ActivityCode" type='text' value={Items} onChange={(e) => setItems(e.target.value)} placeholder='ActivityCode' />
            </div>
          }

          <div className='col-md-2'>
            <button className='btn btn-success mt-4' disabled={ !RoleType || !RoleName || RoleName === "ActivityCode-" || !Items || !Buton} onClick={CreateRole}>Create</button>
          </div>
        </div>

        <div className="scroll-table mt-4" style={{width: "1000px"}}>
          <table id="table-css">
            <thead>
                <tr>
                    <th style={{width: '1rem'}}>RoleType</th>                       
                    <th style={{width: '1rem'}}>RoleName</th>   
                    <th style={{width: '1rem'}}>Value</th>   

                </tr>
            </thead>
            <tbody>
                {All_RA.map((item, rowIndex) => {
                  if(!RoleType && !RoleName){
                    return(
                      <tr key={rowIndex}>
                        <td>{item.AuthMasterObjectName}</td>
                        <td>{item.RoleName}</td>
                        <td>{item.AuthObjectValueStartingRange || item.AuthObjectValueEndingRange}</td>
                      </tr>
                    )
                  }
                  else if(RoleType && RoleType === item.AuthMasterObjectName && RoleName && RoleName === item.RoleName ){
                    return(
                      <tr key={rowIndex}>
                        <td>{item.AuthMasterObjectName}</td>
                        <td>{item.RoleName}</td>
                        <td>{item.AuthObjectValueStartingRange || item.AuthObjectValueEndingRange}</td>
                      </tr>
                    )
                  }
                  else if(!RoleType && RoleName && RoleName === item.RoleName ){
                    return(
                      <tr key={rowIndex}>
                        <td>{item.AuthMasterObjectName}</td>
                        <td>{item.RoleName}</td>
                        <td>{item.AuthObjectValueStartingRange || item.AuthObjectValueEndingRange}</td>
                      </tr>
                    )
                  }
                  else if((!RoleName || RoleName === "ActivityCode-") && RoleType && RoleType === item.AuthMasterObjectName){
                    return(
                      <tr key={rowIndex}>
                        <td>{item.AuthMasterObjectName}</td>
                        <td>{item.RoleName}</td>
                        <td>{item.AuthObjectValueStartingRange || item.AuthObjectValueEndingRange}</td>
                      </tr>
                    )
                  }
                })}                    
            </tbody>
          </table>
        </div>
        </>
      }


      { Action === "AssignRole" &&

        <>
        <div className='row mt-3'>

          <div className="col-md-3">
            <label htmlFor="email" style={{fontSize: '1rem'}}> Email </label>
            <select className="browser-default custom-select" id="email" name="email" value={Email} onChange={(e) => setEmail(e.target.value)} style={{width: '19rem'}} >
                <option value=""> select </option>
                {AllUsers.map( (each, indx) => {
                  if(state.login.userData.USR_Email !== each.USR_Email){
                    return(
                      <option key={indx} value={each.USR_Email}>{each.USR_Email}</option>
                    )
                  }
                })}
            </select>
          </div>

          <div className="col-md-2">          
            <label htmlFor="Process" style={{fontSize: '1rem'}}> Process </label>
            <select className="browser-default custom-select" id="Process" name="Process" value={Process} onChange={(e) => setProcess(e.target.value)} >
              <option value=""> select </option>
              <option value="Map Role"> Map Role</option>
              <option value="UnMap Role"> UnMap Role</option>
            </select>
          </div>

          <div className="col-md-2">          
            <label htmlFor="RoleName" style={{fontSize: '1rem'}}> RoleName </label>
            <select className="browser-default custom-select" id="RoleName" name="RoleName" value={RoleName} onChange={(e) => setRoleName(e.target.value)} >
              <option value=""> select </option>
              {AllRoleNames.map( (each, indx) => {
                return(
                  <option key={indx} value={each}>{each}</option>
                )
              })}
            </select>
          </div>

          {
            Process &&
            <div className='col-md-2'>
              <button className='btn btn-success mt-4' disabled={!Email || !RoleName || !Process || !Buton} onClick={MapRole}>{Process}</button>
            </div>
          }

        </div>

        <div className="scroll-table mt-4" style={{width: "800px"}}>
        <table id="table-css">
          <thead>
              <tr>
                  <th style={{width: '1rem'}}>Users</th>                       
                  <th style={{width: '1rem'}}>Roles</th>
              </tr>
          </thead>
          <tbody>
              {All_UPA.map((item, rowIndex) => {
                let emaiL = item.UserID.replace("#USR#", "")
                if(!Email && !RoleName){
                  return(
                    <tr key={rowIndex}>
                      <td>{emaiL}</td>
                      <td>{item.RoleID}</td>
                    </tr>
                  )
                }
                else if(!RoleName && Email && item.UserID.includes(Email)){
                  return(
                    <tr key={rowIndex}>
                      <td>{emaiL}</td>
                      <td>{item.RoleID}</td>
                    </tr>
                  )
                }               
                else if(RoleName && item.RoleID === RoleName && Email && item.UserID.includes(Email)){
                  return(
                    <tr key={rowIndex}>
                      <td>{emaiL}</td>
                      <td>{item.RoleID}</td>
                    </tr>
                  )
                }               
                else if(!Email && item.RoleID === RoleName){
                  return(
                    <tr key={rowIndex}>
                      <td>{emaiL}</td>
                      <td>{item.RoleID}</td>
                    </tr>
                  )
                }               
              })}                    
          </tbody>
        </table>
        </div>
        </>

      }

      
    </div>
  )
}

export default AdminScreen