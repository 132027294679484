import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getRequest, postRequest } from "../../globalhelper/helper";
import { useDispatch, useSelector } from "react-redux";
import { navUrl } from "../../actions/common_actions";
import moment from "moment";
import _ from "lodash";
import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
// const TransactionTypesMap = {
//     "OM0000000001": ["GRN", "ERP", "Journal", "InstructorCostAccrual", "InstructorInvoice", "Collection"],
//     "OM0000000002": ["Sales", "Collection"]
// };
// const TransactionTypes_1 = ["Sales", "Collection", "GRN", "ERP", "Journal", "InstructorCostAccrual", "InstructorInvoice"]
// const TransactionTypes_2 = ["BILL", "ERP Data"]
const DocumentTypes = ["Supplier Bill", "Transporter Bill"];
// const ThemeNames = {
//   OM0000000001: {
//     GRN: ["GRNDTV1"],
//     ERP: ["TALLY_ERPV1"],
//     Journal: ["DTJrnlUpld_SAV1"],
//     InstructorCostAccrual: ["Edureka_InsProv"],
//     InstructorInvoice: ["Edureka_IMS"],
//     Collection: ["JKShah_Collection"],
//   },
//   OM0000000002: {
//     Sales: ["JKShah_Sales"],
//     Collection: ["JKShah_Collection"],
//   },
// };
function Upload4() {
  const[ThemeNames,setthemeNames]=useState([])  
  const [TransactionType, setTransactionType] = useState("");
  const [DocumentType, setDocumentType] = useState("");
  const [ThemeName, setThemeName] = useState("");
  const [AllCompanies, setAllCompanies] = useState([]);
  const [Companies, setCompanies] = useState([]);
  const [Company, setCompany] = useState("");
  const [GSTINs, setGSTINs] = useState([]);
  const [GSTIN, setGSTIN] = useState("");
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const [AllRecentHistory, setAllRecentHistory] = useState([]);
  const [RecentHistory, setRecentHistory] = useState([]);
  const state = useSelector((state) => state);
  const [TransactionTypes, setTransactionTypes] = useState([]);
  const dispatch = useDispatch();
  const navPage = (url) => {
    dispatch(navUrl(url));
  };
  useEffect(() => {
    getRequest(`api/upload/getCompany`).then((res) => {
      if (res.type === "success") {
        let data = JSON.parse(res.res.data);
        data = _.sortBy(data, "orgname");
        const uniqueIds = new Set();
        const uniqueArray = data.filter((obj) => {
          if (!uniqueIds.has(obj.orgid)) {
            uniqueIds.add(obj.orgid);
            return true;
          }
          return false;
        });
        setCompanies(uniqueArray);
        setAllCompanies(data);
      }
    });
    var currentDate = new Date();
    var firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    var lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    function formatDate(date) {
      var year = date.getFullYear();
      var month = (date.getMonth() + 1).toString().padStart(2, "0");
      var day = date.getDate().toString().padStart(2, "0");
      return year + "-" + month + "-" + day;
    }
    var formattedFirstDay = formatDate(firstDay);
    var formattedLastDay = formatDate(lastDay);
    setFrom(formattedFirstDay);
    setTo(formattedLastDay);
  }, []);
  useEffect(() => {
    postRequest("api/transactions/getTransactions").then((resp) => {
      if (resp.type === "success") {
        const DATA = JSON.parse(resp.res.data);
        console.log(DATA);
        setAllRecentHistory(DATA);
        setRecentHistory(DATA);
      }
    });
  }, []);

  const updateCompany = (e) => {
    let val = e.target.value;
    console.log(val);

    setCompany(val);
    postRequest("api/upload/uploadV4Credentials", { orgid: val }).then(
      (response) => {
        const result = response.res.data;
        console.log(result.transactionType);
        setTransactionType(""); // Reset transaction type when company changes
        setTransactionTypes(result.transactionTypes || []);
        setThemeName(""); // Reset theme name when company changes
      }
    );
    if (TransactionType === "InstructorInvoice") {
      let GSTIN = [];
      AllCompanies.map((each) => {
        if (each.orgid === Company) {
          GSTIN.push(each.GSTIN);
        }
      });
      setGSTINs(GSTIN);
    }
  };

  const updateTT = (e) => {
    let val = e.target.value;
    let key = e.target.id;
    console.log(val);

    setTransactionType(val);
    postRequest("api/upload/uploadV4Credentials", {  orgid:Company,transactionType: val }).then(
        (response) => {
          const result = response.res.data;
          console.log(result.themename);
        //   setTransactionType(""); // Reset transaction type when company changes
          setthemeNames(result.themename || []);
        //   setThemeName(""); // Reset theme name when company changes
        }
      );
    
    if (val === "InstructorInvoice") {
      let GSTIN = [];
      AllCompanies.map((each) => {
        if (each.orgid === Company) {
          GSTIN.push(each.GSTIN);
        }
      });
      setGSTINs(GSTIN);
    }
  };
  // useEffect(() => {
  //   if (AllRecentHistory.length) {
  //     if (TransactionType && ThemeName) {
  //       let nEW = AllRecentHistory.filter((each) => {
  //         if (
  //           each["TransactionType"] === TransactionType &&
  //           each["ThemeName"] === ThemeName
  //         )
  //           return each;
  //       });
  //       setRecentHistory(nEW);
  //     }
  //     if (TransactionType && !ThemeName) {
  //       let nEW = AllRecentHistory.filter((each) => {
  //         if (each["TransactionType"] === TransactionType) return each;
  //       });
  //       setRecentHistory(nEW);
  //     }
  //     if (!TransactionType && ThemeName) {
  //       let nEW = AllRecentHistory.filter((each) => {
  //         if (each["ThemeName"] === ThemeName) return each;
  //       });
  //       setRecentHistory(nEW);
  //     }
  //     if (!TransactionType && !ThemeName) {
  //       setRecentHistory(AllRecentHistory);
  //     }
  //   }
  // }, [TransactionType, ThemeName]);
  useEffect(() => {
    if (AllRecentHistory.length) {
      let filteredData = [...AllRecentHistory];     
      if (Company) {
        filteredData = filteredData.filter((each) => each["OrgId"] === Company);
      }     
      if (TransactionType) {
        filteredData = filteredData.filter(
          (each) => each["TransactionType"] === TransactionType
        );
      }  
      if (ThemeName) {
        filteredData = filteredData.filter(
          (each) => each["ThemeName"] === ThemeName
        );
      }
  
      setRecentHistory(filteredData);
    }
  }, [Company,TransactionType, ThemeName, AllRecentHistory]);

  const updateTN = (e) => {
    let val = e.target.value;
    setThemeName(val);
  };
  const updateFrom = (e) => {
    let val = e.target.value;
    setFrom(val);
  };
  const updateTo = (e) => {
    let val = e.target.value;
    setTo(val);
  };
  const updateGSTIN = (e) => {
    let val = e.target.value;
    setGSTIN(val);
  };
  const fileInputOnChange = (e) => {
    let file = e.target.files[0];
    let additionalParam = TransactionType === "Journal" ? "SA" : 
    TransactionType === "Connections" ? "CONX" : 
    TransactionType === "EmployeeCost" ? "EC" :
    TransactionType === "BankStatements" ? "BS" :
    ["GRN", "InstructorCostAccrual", "InstructorInvoice", "PettyCash"].includes(TransactionType) ? "BT3I" : "BT3I";

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      postRequest(
        `api/upload/singleUpload2?OrgID=${Company}&TransactionType=${TransactionType}&ThemeName=${ThemeName}&GSTIN=${GSTIN}&From=${From}&To=${To}&ProcessCode=${additionalParam}&DocType=${DocumentType}`,
        formData
      ).then((res) => {
        console.log(res);
      });
    }
  };
  const downloadCSV = () => {
    if (!Company || !TransactionType || !ThemeName) {
      console.error("All dropdowns must be selected before downloading the file.");
      return;
    }
  
    postRequest(
      `api/upload/getcsv?OrgID=${Company}&TransactionType=${TransactionType}&ThemeName=${ThemeName}`,
      {
        responseType: "json", // Expecting JSON, as you get a buffer object
      }
    )
      .then((res) => {
        if (res?.res?.data?.type === "Buffer" && Array.isArray(res.res.data.data)) {
          // Get the buffer data (an array of byte values)
          const byteArray = new Uint8Array(res.res.data.data);
  
          // Determine the content type
          const contentType =
            res?.res?.headers?.["content-type"] || "application/octet-stream";
  
          // Log the content type to debug
          console.log("Content Type:", contentType);
  
          // Map the content types to extensions
          const fileExtensionMap = {
            "text/csv": ".csv",
            "application/pdf": ".pdf",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
            "application/zip": ".zip", // If it's a zip file
          };
  
          // Default to .bin if unknown type
          const extension = fileExtensionMap[contentType] || ".csv";
  
          // Create a Blob from the byte array and generate a URL for it
          const blob = new Blob([byteArray], { type: contentType });
          const url = window.URL.createObjectURL(blob);
  
          // Create a temporary anchor element and trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = `${ThemeName}${extension}`;
          document.body.appendChild(link);
          link.click();
  
          // Clean up
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Invalid file data structure:", res.res.data);
        }
      })
      .catch((err) => {
        console.error("Error downloading file:", err);
      });
  };

  const refreshRow = (transaction, OrgId, tansactiontype) => {
    postRequest(
      `api/upload/refreshTransaction?OrgID=${OrgId}&TransactionId=${transaction}&TransactionType=${tansactiontype}`
    ).then((res) => {
      if (res.type === "success") {
        window.location.reload();
      }
    });
  };
  return (
    <div>
      <div className="row mt-2">
        <div className="col-md-2">
          <label htmlFor="Company" style={{ fontSize: "1rem" }}>
            {" "}
            Company{" "}
          </label>
          <select
            className="browser-default custom-select"
            id="Company"
            autoFocus
            name="Company"
            value={Company}
            onChange={updateCompany}
          >
            <option value=""> select </option>
            {Companies.map((item, key) => (
              <option key={key} value={item.orgid}>
                {" "}
                {`${item.orgname}(${item.orgid})`}{" "}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2">
          <label htmlFor="TransactionType" style={{ fontSize: "1rem" }}>
            {" "}
            TransactionType{" "}
          </label>
          <select
            className="browser-default custom-select"
            id="TransactionType"
            name="TransactionType"
            value={TransactionType}
            onChange={updateTT}
          >
            <option value=""> select </option>
            {Company &&
              TransactionTypes.map((item, key) => (
                <option key={key} value={item}>
                  {" "}
                  {item}{" "}
                </option>
              ))}
          </select>
        </div>
        {!TransactionType ||
          (TransactionType !== "BILL" && (
            <div className="col-md-2">
              <label htmlFor="ThemeName" style={{ fontSize: "1rem" }}>
                {" "}
                ThemeName{" "}
              </label>
              <select
                className="browser-default custom-select"
                id="ThemeName"
                name="ThemeName"
                value={ThemeName}
                onChange={updateTN}
              >
                <option value=""> select </option>
                { ThemeNames.map((item, key) => (
                      <option key={key} value={item}>
                        {" "}
                        {item}{" "}
                      </option>
                    ))
                  }
              </select>
            </div>
          ))}
        {Company && TransactionType && ThemeName && (
          <Button
            variant="contained"
            color="secondary"
            onClick={downloadCSV}
            style={{
              marginLeft: "10px",
              marginTop: "25px",
              height: "45px",
              width: "50px",
              fontSize: "10px",
              background: "#233067"
            }}
          >
            Download Theme
          </Button>
        )}
        {TransactionType === "InstructorInvoice" ? (
          <div className="col-md-2">
            <label htmlFor="GSTIN" style={{ fontSize: "1rem" }}>
              {" "}
              GSTIN{" "}
            </label>
            <select
              className="browser-default custom-select"
              id="GSTIN"
              name="GSTIN"
              value={GSTIN}
              onChange={updateGSTIN}
            >
              <option value=""> select </option>
              {GSTINs.map((item, key) => (
                <option key={key} value={item}>
                  {" "}
                  {item}{" "}
                </option>
              ))}
            </select>
          </div>
        ) : TransactionType === "InstructorCostAccrual" ||
          !TransactionType ? null : TransactionType === "BILL" ? (
          <div className="col-md-2">
            <label htmlFor="DocType" style={{ fontSize: "1rem" }}>
              {" "}
              DocType{" "}
            </label>
            <select
              className="browser-default custom-select"
              id="DocType"
              name="DocType"
              value={DocumentType}
              onChange={(e) => setDocumentType(e.target.value)}
            >
              <option value=""> select </option>
              {Company &&
                TransactionType &&
                DocumentTypes.map((item, key) => (
                  <option key={key} value={item}>
                    {" "}
                    {item}{" "}
                  </option>
                ))}
            </select>
          </div>
        ) : (
          <>
            <div className="col-md-2 pl-4">
              <label htmlFor="From" style={{ fontSize: "1rem" }}>
                {" "}
                ForPrdFrom{" "}
              </label>
              <input
                type="date"
                name="From"
                value={From}
                onChange={updateFrom}
              ></input>
            </div>
            <div className="col-md-2">
              <label htmlFor="To" style={{ fontSize: "1rem" }}>
                {" "}
                ForPrdTo{" "}
              </label>
              <input
                type="date"
                name="To"
                value={To}
                onChange={updateTo}
              ></input>
            </div>
          </>
        )}
        <div className="col-md-2">
          <Button
            disabled={
              !Company ||
              !TransactionType ||
              (!ThemeName && TransactionType !== "BILL") ||
              !From ||
              !To ||
              (TransactionType === "InstructorInvoice" && !GSTIN)
            }
            component="label"
            variant="contained"
            sx={{ marginTop: 3 }}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              accept=".csv, .xlsx, .pdf,.xls"
              onChange={fileInputOnChange}
            />
          </Button>
        </div>
      </div>
      {Company && RecentHistory.length > 0 && (
        <div className="mt-5 ml-2">
          <label className="row ml-1" style={{ fontSize: "1rem" }}>
            Recently Uploaded
          </label>
          <div className={`scroll-table`}>
            <table id="table-css">
              <thead>
                <tr>
                  <th>Refresh</th>
                  <th>Date & Time</th>
                  <th>TransactionID</th>
                  <th>TransactionType</th>
                  <th>ThemeName</th>
                  <th>ForPrdFrom</th>
                  <th>ForPrdTo</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {RecentHistory.sort((a, b) => {
                  const dateA = a.Start
                    ? a.Start / 1000000
                    : new Date(a.Created_At).getTime();
                  const dateB = b.Start
                    ? b.Start / 1000000
                    : new Date(b.Created_At).getTime();
                  return dateB - dateA; // Descending order, change to dateA - dateB for ascending
                }).map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td>
                      {/* { */}
                      {/* // [
                        // "Sales",
                        // "Collection",
                        // "GRN",
                        // "Journal",
                        // "InstructorCostAccrual",
                        // "InstructorInvoice",
                      // ] */}
                      {/* {TransactionType.includes(item.TransactionType) && ( */}
                        <IconButton
                          color="primary"
                          onClick={() =>
                            refreshRow(
                              item["TransactionID"],
                              item["OrgId"],
                              item["TransactionType"]
                            )
                          }
                        >
                          <RefreshIcon />
                        </IconButton>
                      {/* )} */}
                    </td>
                    <td>
                      {item.Start
                        ? moment(item.Start / 1000000).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        : item.Created_At}
                    </td>
                    <td>{item["TransactionID"]}</td>
                    <td>{item["TransactionType"]}</td>
                    <td>{item["ThemeName"]}</td>
                    <td>{item["ForPrdFrom"]}</td>
                    <td>{item["ForPrdTo"]}</td>
                    <td>{item["Status"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
export default Upload4;




// import React, { useState, useEffect, useRef } from 'react'
// import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import { getRequest, postRequest } from '../../globalhelper/helper'
// import { useDispatch, useSelector } from 'react-redux';
// import { navUrl } from '../../actions/common_actions';
// import moment from 'moment'
// import _ from 'lodash';
// import { IconButton } from '@mui/material';
// import RefreshIcon from '@mui/icons-material/Refresh';
// import DownloadIcon from '@mui/icons-material/Download';
// const VisuallyHiddenInput = styled('input')({
//     clip: 'rect(0 0 0 0)',
//     clipPath: 'inset(50%)',
//     height: 1,
//     overflow: 'hidden',
//     position: 'absolute',
//     bottom: 0,
//     left: 0,
//     whiteSpace: 'nowrap',
//     width: 1,
// });
// const TransactionTypesMap = {
//     "OM0000000001": ["GRN", "ERP", "Journal", "InstructorCostAccrual", "InstructorInvoice", "Collection"],
//     "OM0000000002": ["Sales", "Collection"]
// };
// // const TransactionTypes_1 = ["Sales", "Collection", "GRN", "ERP", "Journal", "InstructorCostAccrual", "InstructorInvoice"]
// // const TransactionTypes_2 = ["BILL", "ERP Data"]
// const DocumentTypes = ["Supplier Bill", "Transporter Bill"]
// const ThemeNames = {
//     "OM0000000001": {
//         "GRN": ["GRNDTV1"],
//         "ERP": ["TALLY_ERPV1"],
//         "Journal": ["DTJrnlUpld_SAV1"],
//         "InstructorCostAccrual": ["Edureka_InsProv"],
//         "InstructorInvoice": ["Edureka_IMS"],
//         "Collection": ["JKShah_Collection"]
//     },
//     "OM0000000002": {
//         "Sales": ["JKShah_Sales"],
//         "Collection": ["JKShah_Collection"],
//     }
// }
// function Upload4() {
//     const [TransactionType, setTransactionType] = useState("")
//     const [DocumentType, setDocumentType] = useState("")
//     const [ThemeName, setThemeName] = useState("")
//     const [AllCompanies, setAllCompanies] = useState([])
//     const [Companies, setCompanies] = useState([])
//     const [Company, setCompany] = useState("")
//     const [GSTINs, setGSTINs] = useState([])
//     const [GSTIN, setGSTIN] = useState("")
//     const [From, setFrom] = useState("")
//     const [To, setTo] = useState("")
//     const [AllRecentHistory, setAllRecentHistory] = useState([])
//     const [RecentHistory, setRecentHistory] = useState([])
//     const state = useSelector(state => state);
//     const [TransactionTypes, setTransactionTypes] = useState([])
//     const dispatch = useDispatch();
//     const navPage = (url) => {
//         dispatch(navUrl(url));
//     };
//     useEffect(() => {
//         getRequest(`api/upload/getCompany`)
//             .then(res => {
//                 if (res.type === "success") {
//                     let data = JSON.parse(res.res.data)
//                     data = _.sortBy(data, "orgname")
//                     const uniqueIds = new Set();
//                     const uniqueArray = data.filter(obj => {
//                         if (!uniqueIds.has(obj.orgid)) {
//                             uniqueIds.add(obj.orgid);
//                             return true;
//                         }
//                         return false;
//                     });
//                     setCompanies(uniqueArray)
//                     setAllCompanies(data)
//                 }
//             })
//         var currentDate = new Date();
//         var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
//         var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
//         function formatDate(date) {
//             var year = date.getFullYear();
//             var month = (date.getMonth() + 1).toString().padStart(2, '0');
//             var day = date.getDate().toString().padStart(2, '0');
//             return year + "-" + month + "-" + day;
//         }
//         var formattedFirstDay = formatDate(firstDay);
//         var formattedLastDay = formatDate(lastDay);
//         setFrom(formattedFirstDay)
//         setTo(formattedLastDay)
//     }, [])
//     useEffect(() => {
//         postRequest('api/transactions/getTransactions')
//             .then(resp => {
//                 if (resp.type === "success") {
//                     const DATA = JSON.parse(resp.res.data)
//                     console.log(DATA)
//                     setAllRecentHistory(DATA)
//                     setRecentHistory(DATA)
//                 }
//             })
//     }, [])
//     const updateCompany = (e) => {
//         let val = e.target.value
//         console.log(val);
//         setTransactionType(""); // Reset transaction type when company changes
//         setTransactionTypes(TransactionTypesMap[val] || []);
//         setThemeName(""); // Reset theme name when company changes

//         setCompany(val)
//         if (TransactionType === "InstructorInvoice") {
//             let GSTIN = []
//             AllCompanies.map(each => {
//                 if (each.orgid === Company) {
//                     GSTIN.push(each.GSTIN)
//                 }
//             })
//             setGSTINs(GSTIN)
//         }
//     }
//     const updateTT = (e) => {
//         let val = e.target.value
//         let key = e.target.id
//         console.log(val);

//         setTransactionType(val)
//         if (val === "InstructorInvoice") {
//             let GSTIN = []
//             AllCompanies.map(each => {
//                 if (each.orgid === Company) {
//                     GSTIN.push(each.GSTIN)
//                 }
//             })
//             setGSTINs(GSTIN)
//         }
//     }
//     useEffect(() => {
//         if (AllRecentHistory.length) {
//             if (TransactionType && ThemeName) {
//                 let nEW = AllRecentHistory.filter(each => {
//                     if (each["TransactionType"] === TransactionType && each["ThemeName"] === ThemeName) return each
//                 })
//                 setRecentHistory(nEW)
//             }
//             if (TransactionType && !ThemeName) {
//                 let nEW = AllRecentHistory.filter(each => {
//                     if (each["TransactionType"] === TransactionType) return each
//                 })
//                 setRecentHistory(nEW)
//             }
//             if (!TransactionType && ThemeName) {
//                 let nEW = AllRecentHistory.filter(each => {
//                     if (each["ThemeName"] === ThemeName) return each
//                 })
//                 setRecentHistory(nEW)
//             }
//             if (!TransactionType && !ThemeName) {
//                 setRecentHistory(AllRecentHistory)
//             }
//         }
//     }, [TransactionType, ThemeName])
//     const updateTN = (e) => {
//         let val = e.target.value
//         setThemeName(val)
//     }
//     const updateFrom = (e) => {
//         let val = e.target.value
//         setFrom(val)
//     }
//     const updateTo = (e) => {
//         let val = e.target.value
//         setTo(val)
//     }
//     const updateGSTIN = (e) => {
//         let val = e.target.value
//         setGSTIN(val)
//     }
//     const fileInputOnChange = (e) => {
//         let file = e.target.files[0];
//         let additionalParam = ThemeName === "DTJrnlUpld_SAV1" ? "SA" : "BT3I";
//         if (file) {
//             const formData = new FormData();
//             formData.append('file', file);
//             postRequest(`api/upload/singleUpload2?OrgID=${Company}&TransactionType=${TransactionType}&ThemeName=${ThemeName}&GSTIN=${GSTIN}&From=${From}&To=${To}&ProcessCode=${additionalParam}&DocType=${DocumentType}`, formData)
//                 .then(res => {
//                     console.log(res);
//                 });
//         }
//     };
//     const downloadCSV = () => {
//         postRequest(`api/edureka/getcsv?OrgID=${Company}&TransactionType=${TransactionType}&ThemeName=${ThemeName}`, {
//             responseType: 'json' // Ensure you expect a JSON response
//         }).then(res => {
//             if (res && res.res && res.res.data && res.res.data.type === "Buffer") {
//                 // Convert the Buffer data to a Uint8Array
//                 const uint8Array = new Uint8Array(res.res.data.data);

//                 // Create a Blob from the Uint8Array
//                 const blob = new Blob([uint8Array], { type: 'text/csv' });
//                 const url = window.URL.createObjectURL(blob);

//                 // Create a temporary link element
//                 const link = document.createElement('a');
//                 link.href = url;
//                 link.setAttribute('download', 'data.csv'); // Specify the file name

//                 // Append the link to the body and trigger the download
//                 document.body.appendChild(link);
//                 link.click();

//                 // Clean up and remove the link
//                 link.parentNode.removeChild(link);
//                 window.URL.revokeObjectURL(url); // Free up memory
//             } else {
//                 console.error("Response is not valid", res);
//             }
//         }).catch(err => {
//             console.error("Error downloading CSV:", err);
//         });
//     };


//     const refreshRow = (transaction, OrgId, tansactiontype) => {
//         postRequest(`api/upload/refreshTransaction?OrgID=${OrgId}&TransactionId=${transaction}&TransactionType=${tansactiontype}`).then(res => {
//             if (res.type === "success") {
//                 window.location.reload()
//             }
//         });
//     };
//     return (
//         <div>
//             <div className='row mt-2'>
//                 <div className='col-md-2'>
//                     <label htmlFor="Company" style={{ fontSize: '1rem' }}> Company </label>
//                     <select className="browser-default custom-select" id="Company" autoFocus name="Company" value={Company} onChange={updateCompany} >
//                         <option value=""> select </option>
//                         {Companies.map((item, key) =>
//                             <option key={key} value={item.orgid}> {`${item.orgname}(${item.orgid})`} </option>
//                         )}
//                     </select>
//                 </div>
//                 <div className='col-md-2'>
//                     <label htmlFor="TransactionType" style={{ fontSize: '1rem' }}> TransactionType </label>
//                     <select className="browser-default custom-select" id="TransactionType" name="TransactionType" value={TransactionType} onChange={updateTT} >
//                         <option value=""> select </option>
//                         {Company && TransactionTypes.map((item, key) =>
//                             <option key={key} value={item}> {item} </option>
//                         )}
//                     </select>
//                 </div>
//                 {
//                     !TransactionType || (TransactionType !== "BILL") &&
//                     <div className='col-md-2'>
//                         <label htmlFor="ThemeName" style={{ fontSize: '1rem' }}> ThemeName </label>
//                         <select className="browser-default custom-select" id="ThemeName" name="ThemeName" value={ThemeName} onChange={updateTN} >
//                             <option value=""> select </option>
//                             {Company && ThemeNames[Company] && ThemeNames[Company][TransactionType] ?
//                                 ThemeNames[Company][TransactionType].map((item, key) =>
//                                     <option key={key} value={item}> {item} </option>
//                                 ) : null
//                             }
//                         </select>
//                     </div>
//                 }
//                 {
//                     TransactionType === "InstructorInvoice" ?
//                         <div className='col-md-2'>
//                             <label htmlFor="GSTIN" style={{ fontSize: '1rem' }}> GSTIN </label>
//                             <select className="browser-default custom-select" id="GSTIN" name="GSTIN" value={GSTIN} onChange={updateGSTIN} >
//                                 <option value=""> select </option>
//                                 {GSTINs.map((item, key) =>
//                                     <option key={key} value={item}> {item} </option>
//                                 )}
//                             </select>
//                         </div>
//                         :
//                         TransactionType === "InstructorCostAccrual" || !TransactionType ?
//                             null
//                             :
//                             TransactionType === "BILL" ?
//                                 <div className='col-md-2'>
//                                     <label htmlFor="DocType" style={{ fontSize: '1rem' }}> DocType </label>
//                                     <select className="browser-default custom-select" id="DocType" name="DocType" value={DocumentType} onChange={(e) => setDocumentType(e.target.value)} >
//                                         <option value=""> select </option>
//                                         {Company && TransactionType && DocumentTypes.map((item, key) =>
//                                             <option key={key} value={item}> {item} </option>
//                                         )}
//                                     </select>
//                                 </div>
//                                 :
//                                 <>
//                                     <div className='col-md-2 pl-5'>
//                                         <label htmlFor="From" style={{ fontSize: '1rem' }}> ForPrdFrom </label>
//                                         <input type='date' name='From' value={From} onChange={updateFrom}></input>
//                                     </div>
//                                     <div className='col-md-2 pl-5' style={{ marginLeft: "0" }}>
//                                         <label htmlFor="To" style={{ fontSize: '1rem' }}> ForPrdTo </label>
//                                         <input type='date' name='To' value={To} onChange={updateTo}></input>
//                                     </div>
//                                 </>
//                 }
//                 <div className='col-md-2 pl-5'>
//                     <Button
//                         disabled={!Company || !TransactionType || (!ThemeName && TransactionType !== "BILL") || !From || !To || (TransactionType === "InstructorInvoice" && !GSTIN)}
//                         component="label" variant="contained" sx={{ marginTop: 0 }} startIcon={<CloudUploadIcon />}>
//                         Upload file
//                         <VisuallyHiddenInput type="file" accept='.csv, .xlsx, .pdf' onChange={fileInputOnChange} />
//                     </Button>
//                     {Company && TransactionType && ThemeName && (
//                         <Button
//                             variant="contained"
//                             sx={{ marginTop: 1 }}
//                             onClick={downloadCSV}
//                             startIcon={<DownloadIcon />} // Add the icon here
//                             style={{ color: "#233067" }}
//                         ></Button>
//                     )}
//                 </div>
//             </div>
//             {
//                 RecentHistory.length > 0 &&
//                 <div className='mt-5 ml-2'>
//                     <label className='row ml-1' style={{ fontSize: '1rem' }}>Recently Uploaded</label>
//                     <div className={`scroll-table`}>
//                         <table id="table-css">
//                             <thead>
//                                 <tr>
//                                     <th>Refresh</th>
//                                     <th >Date & Time</th>
//                                     <th >TransactionID</th>
//                                     <th >TransactionType</th>
//                                     <th >ThemeName</th>
//                                     <th >ForPrdFrom</th>
//                                     <th >ForPrdTo</th>
//                                     <th >Status</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {RecentHistory.sort((a, b) => {
//                                     const dateA = a.Start ? a.Start / 1000000 : new Date(a.Created_At).getTime();
//                                     const dateB = b.Start ? b.Start / 1000000 : new Date(b.Created_At).getTime();
//                                     return dateB - dateA; // Descending order, change to dateA - dateB for ascending
//                                 }).map((item, rowIndex) => (
//                                     <tr key={rowIndex}>
//                                         <td>
//                                             {['Sales', 'Collection', 'GRN', 'Journal', 'InstructorCostAccrual', 'InstructorInvoice'].includes(item.TransactionType) && (
//                                                 <IconButton color="primary" onClick={() => refreshRow(item["TransactionID"], item["OrgId"], item["TransactionType"])}>
//                                                     <RefreshIcon />
//                                                 </IconButton>
//                                             )}
//                                         </td>
//                                         <td>{item.Start ? moment(item.Start / 1000000).format("DD/MM/YYYY HH:mm:ss") : item.Created_At}</td>
//                                         <td>{item["TransactionID"]}</td>
//                                         <td>{item["TransactionType"]}</td>
//                                         <td>{item["ThemeName"]}</td>
//                                         <td>{item["ForPrdFrom"]}</td>
//                                         <td>{item["ForPrdTo"]}</td>
//                                         <td>{item["Status"]}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             }
//         </div>
//     )
// }
// export default Upload4;