import {
  FLESH_COMMON_OBJECT,
  FLUSH_MENU_TREE,
  GET_CURRENT_MENU_TREE,
  GET_USER_CONFIGURATION,
  HIDE_LOADER,
  HIDEALERT,
  INITIATECOMMON,
  NAVURL,
  SET_MENU_TREE,
  SHOW_LOADER,
  SHOWALERT,
  UPDATE_USER_CONFIGURATION,
} from "../constants/action-types";
import { getRequest, patchRequest, postRequest } from "../globalhelper/helper";
import { requestCurrentState } from "./login_actions";
import store from "../store";

export function showAlert(msg, status) {
  return (dispatch) => {
    dispatch({
      type: SHOWALERT,
      text: msg,
      status: status,
    });
  };
}

export function hideAlert() {
  return (dispatch) => {
    dispatch({
      type: HIDEALERT,
    });
  };
}

export function flushCommonObject() {
  return (dispatch) => {
    dispatch({
      type: FLESH_COMMON_OBJECT,
    });
  };
}

export function flushMenuTree() {
  return (dispatch) => {
    dispatch({
      type: FLUSH_MENU_TREE,
    });
  };
}

export function getCurrentMenuTree() {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_MENU_TREE,
    });
  };
}

export function setMenuTree(menuTree) {
  return (dispatch) => {
    console.log(menuTree, "Actions");
    dispatch(flushMenuTree());
    dispatch({
      type: SET_MENU_TREE,
      menuTree: menuTree,
    });
  };
}

export function navUrl(url) {
  return (dispatch) => {
    dispatch({
      type: NAVURL,
      url: url,
    });
  };
}

export function initiateCommon(data) {
  return (dispatch) => {
    dispatch({
      type: INITIATECOMMON,
      data: data,
    });
  };
}

export function verifyLogin() {
  return (dispatch) => {
    dispatch(requestCurrentState());
    return postRequest("api/user/verifyLogin").then((data) => {
      if (data) {
        if (data.type === "success") {
          return data.res;
        }
      }
    });
  };
}

export function showLoader() {
  return (dispatch) => {
    dispatch({ type: SHOW_LOADER });
  };
}

export function hideLoader() {
  return (dispatch) => {
    dispatch({ type: HIDE_LOADER });
  };
}

export function getUserConfiguration() {
  return (dispatch) => {
    const email = store.getState()?.login?.userData?.USR_Email;
    getRequest(`api/user/userConfiguration?email=${email}`)
      .then((response) => {
        dispatch({ type: GET_USER_CONFIGURATION, data: response.res.data });
      })
      .catch(() => {
        dispatch({ type: GET_USER_CONFIGURATION, data: {} });
      });
  };
}

export function updateUserConfiguration(configuration) {
  return (dispatch) => {
    const email = store.getState()?.login?.userData?.USR_Email;
    patchRequest("api/user/userConfiguration", { email, configuration })
      .then((response) => {
        dispatch({ type: UPDATE_USER_CONFIGURATION, data: response.res.data });
      })
      .catch(() => {
        dispatch({ type: UPDATE_USER_CONFIGURATION, data: {} });
      });
  };
}
