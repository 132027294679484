import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Snackbar, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { hideLoader, showLoader } from '../../../actions/common_actions';
import { getRequest, postRequest } from '../../../globalhelper/helper';
import CostContributionFilter from './CostContributionFilter';
import CostContributionSearch from './CostContributionSearch';
import CostContributionTable from './CostContributionTable';
const CostContributionV2 = () => {
  const store = useStore();
  const gridRef = useGridApiRef();
  const [initialized, setInitialized] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [period, setPeriod] = useState(moment());
  const [loaded, setLoaded] = useState(false);
  const [availableLOBs, setAvailableLOBs] = useState([]);
  const [allKnownCAMs, setAllKnownCAMs] = useState([]);
  const [CAMPeriodMap, setCAMPeriodMap] = useState(new Map());
  const [costAllocations, setCostAllocations] = useState([]);
  const [filteredCostAllocations, setFilteredCostAllocations] = useState([]);
  const clearFilterData = useRef(null);
  const clearFilterInputs = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (!initialized) {
      loadCompanies();
    }
    setInitialized(true);
  }, [initialized]);

  const getCAMLabel = (CAM) => {
    return `${CAM.costallocationmethod}`.replaceAll(/\s+/g, '-').toUpperCase();
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMessage('');
  };

  const loadCompanies = () => {
    getRequest(`api/edureka/getCompanyAssignedToUser`)
      .then(({ res }) => {
        setCompanies((res.data || []).sort((a, b) => (a.orgname || '').localeCompare(b.orgname || '')));
      })
      .catch(setErrorMessage);
  };

  const processCAMs = (CAMList) => {
    const CAMMap = new Map();
    const CAMSet = new Set();
    for (const CAM of CAMList) {
      if (!CAM.costallocationmethod) {
        continue;
      }
      CAMSet.add(CAM.costallocationmethod);
      let periodMap = CAMMap.get(getCAMLabel(CAM));
      if (!periodMap) {
        periodMap = new Map();
      }
      let allocations = periodMap.get(`${CAM.forprdfrom}|${CAM.forprdto}`);
      if (!allocations) {
        allocations = new Map();
      }
      allocations.set(CAM.lob, CAM);
      periodMap.set(`${CAM.forprdfrom}|${CAM.forprdto}`, allocations);
      CAMMap.set(getCAMLabel(CAM), periodMap);
    }
    const updatedCAMs = Array.from(CAMSet);
    updatedCAMs.unshift('Dynamic');
    updatedCAMs.unshift('None');
    setAllKnownCAMs(updatedCAMs);
    setCAMPeriodMap(CAMMap);
  };

  const loadAvailableLOBs = () => {
    return new Promise((resolve, reject) => {
      const params = {
        company: company?.orgid || ''
      };
      postRequest(`api/edureka/getDistinctLOBs`, params)
        .then(({ res }) => {
          let allLOBs = Array.isArray(JSON.parse(res.data)) ? JSON.parse(res.data) : [] || [];
          allLOBs = allLOBs.sort((a, b) => (a.lob || '').localeCompare(b.lob || '')).map((lob) => lob.lob);
          setAvailableLOBs(allLOBs);
          resolve();
        })
        .catch((error) => {
          reject();
          setErrorMessage(error);
        });
    });
  };

  const loadAllKnownCAMs = () => {
    return new Promise((resolve, reject) => {
      const params = {
        company: company.orgid,
        fromDate: period.startOf('month').format('yyyy-MM-DD'),
        toDate: period.endOf('month').format('yyyy-MM-DD')
      };
      postRequest(`api/edureka/getDistinctCAMForGivenPeriod`, params)
        .then(({ res }) => {
          const allKnownCAMs = Array.isArray(JSON.parse(res.data)) ? JSON.parse(res.data) : [] || [];
          allKnownCAMs.sort((a, b) => (a.costallocationmethod || '').localeCompare(b.costallocationmethod || ''));
          processCAMs(allKnownCAMs);
          resolve();
        })
        .catch((error) => {
          reject();
          setErrorMessage(error.message);
        });
    });
  };

  const loadCostAllocationAndContribution = () => {
    return new Promise((resolve, reject) => {
      const params = {
        company: company.orgid,
        fromDate: period.startOf('month').format('yyyy-MM-DD'),
        toDate: period.endOf('month').format('yyyy-MM-DD')
      };
      postRequest(`api/edureka/getCostAllocationAndContribution`, params)
        .then(({ res }) => {
          const costAllocations = Array.isArray(JSON.parse(res.data)) ? JSON.parse(res.data) : [] || [];
          costAllocations.sort((CAM_A, CAM_B) => {
            return CAM_A.createdid.localeCompare(CAM_B.createdid);
          });
          setCostAllocations([...costAllocations]);
          resolve();
        })
        .catch((error) => {
          setCostAllocations([]);
          reject();
          setErrorMessage(error);
        });
    });
  };

  const fetchCostAllocations = () => {
    store.dispatch(showLoader());
    Promise.all([loadAvailableLOBs(), loadAllKnownCAMs()]).then(() => {
      loadCostAllocationAndContribution().then(() => {
        store.dispatch(hideLoader());
        setLoaded(true);
      });
    });
  };

  const resetCostAllocation = () => {
    setLoaded(false);
    setCostAllocations([]);
    setCompany(null);
    setPeriod(moment());
    setAvailableLOBs([]);
    setAllKnownCAMs([]);
    setCAMPeriodMap(new Map());
    store.dispatch(hideLoader());
    clearFilterData.current?.();
  };

  const resetFilteredCostAllocation = () => {
    clearFilterInputs.current?.();
    setFilteredCostAllocations(() => {
      return [...costAllocations];
    });
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap={2}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}>
        <Snackbar
          open={Boolean(errorMessage)}
          onClose={handleErrorClose}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
          <Alert
            severity="error"
            onClose={handleErrorClose}>
            {errorMessage.toString()}
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(successMessage)}
          onClose={handleSuccessClose}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
          <Alert
            severity="success"
            onClose={handleSuccessClose}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Search</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CostContributionSearch
              loaded={loaded}
              companies={companies}
              company={company}
              setCompany={setCompany}
              period={period}
              setPeriod={setPeriod}
              fetchCostAllocations={fetchCostAllocations}
              resetCostAllocation={resetCostAllocation}
              clearFilterData={clearFilterData}
              clearFilterInputs={clearFilterInputs}
            />
            {loaded && (
              <CostContributionFilter
                setFilteredCostAllocations={setFilteredCostAllocations}
                costAllocations={costAllocations}
                clearFilterData={clearFilterData}
                clearFilterInputs={clearFilterInputs}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        alignContent="center"
        justifyItems="center"
        flex={1}
        overflow="hidden">
        {loaded && (
          <CostContributionTable
            gridRef={gridRef}
            company={company}
            costAllocations={filteredCostAllocations}
            allKnownCAMs={allKnownCAMs}
            CAMPeriodMap={CAMPeriodMap}
            availableLOBs={availableLOBs}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            setLoaded={setLoaded}
            resetFilteredCostAllocation={resetFilteredCostAllocation}
          />
        )}
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    state
  };
}

export default connect(mapStateToProps)(CostContributionV2);
