import React from 'react'

function PaginationEmail({postsPerPage, totalPosts, currentPage, paginate}) {
    const pageNumbers = []

    for(let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++){
        pageNumbers.push(i)
    }
  return (
    <nav className='column offset-2'>
        <div style={{width: '100%', overflowX: 'auto'}}>
            <ul className='pagination'>
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={()=> paginate(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    </nav>
  )
}

export default PaginationEmail
