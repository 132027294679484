import { Cancel, Save } from '@material-ui/icons';
import { Button } from '@mui/material';
import {
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import GridToolbarReOrder from '../../../components/Grid/GridToolbarReOrder';
import globalhelper from '../../../helper/globalhelper';
import { reorder } from '../../../utils';
import EditCostAllocation from './EditCostAllocation';

export default function CostContributionToolbar({
  availableCAMs,
  availableLOBs,
  costAllocationMap,
  onSave,
  onCancel,
  onUpdate,
  updatedRows
}) {
  const apiRef = useGridApiContext();
  const [hasChanges, setHasChanges] = useState(false);
  const gridColumns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const visibleColumns = useGridSelector(apiRef, gridColumnVisibilityModelSelector);
  useEffect(() => {
    apiRef.current.subscribeEvent(
      'rowEditStop',
      globalhelper.debounce(() => setHasChanges(true))
    );
    setHasChanges(Object.keys(updatedRows).length > 0);
  }, [updatedRows, apiRef]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setColumns(gridColumns.filter((column) => visibleColumns[column.field] !== false));
  }, [gridColumns, visibleColumns]);
  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const reOrderedColumns = reorder(columns, source.index, destination.index);
    setColumns(reOrderedColumns);
    apiRef.current.setState((prevState) => {
      const state = { ...prevState };
      state.columns.orderedFields = reOrderedColumns.map((column) => column.field);
      apiRef.current.forceUpdate();
      return state;
    });
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ fileName: 'cost-allocation', delimiter: ';' }} />
      <EditCostAllocation
        availableCAMs={availableCAMs}
        costAllocationMap={costAllocationMap}
        availableLOBs={availableLOBs}
        onUpdate={onUpdate}
      />
      {!hasChanges && (
        <GridToolbarReOrder
          columns={columns}
          onDragEnd={onDragEnd}
        />
      )}
      {hasChanges && (
        <>
          <Button
            size="small"
            startIcon={<Cancel />}
            color="error"
            onClick={onCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            startIcon={<Save />}
            onClick={onSave}>
            Save
          </Button>
        </>
      )}
    </GridToolbarContainer>
  );
}
