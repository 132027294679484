import { List, Paper } from '@mui/material';
import React, { memo } from 'react';
import {
  DragDropContext,
  Droppable
} from 'react-beautiful-dnd';
import DraggableListItem from './DraggableListItem';


const DraggableList = memo(({ items, itemId, onDragEnd, itemLabel }) => {  
  return (
    <Paper elevation={8}>
      <DragDropContext onDragEnd={onDragEnd} >
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableListItem item={item} itemLabel={itemLabel} itemId={itemId} index={index} key={item[itemId]} />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
});

export default DraggableList;
