import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from "../../globalhelper/helper";
import DataTableS3Download from "../../components/DataTableS3Download";
import { connect } from 'react-redux';
import { navUrl } from '../../actions/common_actions';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import Select from 'react-select';
// import ApiConstants from "../../config";
// import download from 'downloadjs';
// import axios from 'axios'; 
// import {Animated} from "react-animated-css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {  faExclamation } from "@fortawesome/free-solid-svg-icons";



class ReconciliationDetailReport extends Component {
    constructor(props) {
        super(props)
        this.currentState = this.props.state;
        this.userData = this.currentState.login.userData;
    
      this.state = {
        s3Key: 'key',
        input : '',
        input_option : [],
        data : [],
        fetching : false,
        columns : [{name: 'NewMatchCode', sortable: true, selector: row => row['NewMatchCode']},
        {name: 'GroupRef4', sortable: true, selector: row => row['GroupRef4']},
        {name: 'GroupRef3', sortable: true, selector: row => row['GroupRef3']},
        {name: 'GroupRef2', sortable: true, selector: row => row['GroupRef2']},
        {name: 'GroupRef1', sortable: true, selector: row => row['GroupRef1']},
        {name: 'ReconStatus', sortable: true, selector: row => row['ReconStatus']},
        {name: 'Legalentity', sortable: true, selector: row => row['Legalentity']},
        {name: 'TAN', sortable: true, selector: row => row['TAN']},
        {name: 'Namesasperbooks', sortable: true, selector: row => row['Namesasperbooks']},
        {name: 'Namesasperf26as', sortable: true, selector: row => row['Namesasperf26as']},
        {name: 'Account', sortable: true, selector: row => row['Account']},
        {name: 'SalepostingDts', sortable: true, selector: row => row['SalepostingDts']},
        {name: 'TDSRrDts', sortable: true, selector: row => row['TDSRrDts']},
        {name: 'F26aspostingDts', sortable: true, selector: row => row['F26aspostingDts']},
        {name: 'SalesRevenue', sortable: true, selector: row => row['SalesRevenue']},
        {name: 'Receipts', sortable: true, selector: row => row['Receipts']},
        {name: 'TDSRDebits', sortable: true, selector: row => row['TDSRDebits']},
        {name: 'F26asamtCredited', sortable: true, selector: row => row['F26asamtCredited']},
        {name: 'F26astdsDeposited', sortable: true, selector: row => row['F26astdsDeposited']},
        {name: 'F26as(tds)vsTDSled', sortable: true, selector: row => row['F26as(tds)vsTDSled']},
        {name: 'F26as(amtcr)vsSalesrev', sortable: true, selector: row => row['F26as(amtcr)vsSalesrev']},
        {name: 'tds(current-year)', sortable: true, selector: row => row['tds(current-year)']},
        {name: 'tdsref(current-year)', sortable: true, selector: row => row['tdsref(current-year)']},
        {name: 'tds(previous-year1)', sortable: true, selector: row => row['tds(previous-year1)']},
        {name: 'tdsref(previous-year1)', sortable: true, selector: row => row['tdsref(previous-year1)']},
        {name: 'tds(previous-year2)', sortable: true, selector: row => row['tds(previous-year2)']},
        {name: 'tdsref(previous-year2)', sortable: true, selector: row => row['tdsref(previous-year2)']}
    ]
      }
    }

    componentDidMount() {

        getRequest('api/tds/getTxnentity')
        .then(res => {
            let data = JSON.parse(res.res.data)
            console.log(data)

            let input_option = data.map( each => each.txnentity )

            this.setState({ input_option : input_option })
        })
        .catch(err => {
            console.log(err)
        })
    }

    
    getDATA = () => {
        
        this.setState({
                    fetching: true,
                    data: [],
                    s3Key: 'key',
                    // columns: []
                },
            () => {
                getRequest('api/tds/getDetailReport?input=' + this.state.input)
                .then(res => {
                    let data = JSON.parse(res.res.data.data)
                    console.log(data)

                    // let KEYS = Object.keys(data[0])
                    // console.log(KEYS)

                    // let columns = []

                    // KEYS.forEach( each => {
                    //     columns.push({
                    //         name: each,
                    //         selector: row => row[each],
                    //         sortable: true
                    //         // width : '250px',
                    //     })
                    // })
    
                    this.setState({
                        data: data,
                        fetching: false,
                        s3Key : res.res.data.S3Location
                        // columns: columns
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ fetching : false })
                })
            })
    }


    onInputChange = (e) => {
        this.setState({
            input: e.target.value,
            data : [],
            // columns: []
        }, 
        () => this.getDATA()
        )
    }

    // downloadResultFile = () =>{
    //     let obj = {
    //         key : this.state.s3Key,
    //         tenant_id : this.userData.USR_Tenant
    //     }
    //     axios.post( ApiConstants.externals.serverUrl +'api/transactions/downloadS3FileWithKey', obj ,
    //     { responseType: 'blob' } )
    //     .then(result => {
    //         if(result.data.type === 'text/csv')
    //         {
    //           const blob = result.data;
    //           download(blob, 'AthenaResult.csv');
    //         }
    //         else{
    //           console.log("No such file found")
    //           this.setState({
    //             ...this.state,
    //             alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong  > <FontAwesomeIcon icon={faExclamation} /> No such file found</strong></div> </Animated>
    //           })
    //           setTimeout(() => { this.setState({alert : null}); }, 3000);
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         console.log("No such file found")
    //         this.setState({
    //             ...this.state,
    //             alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong  > <FontAwesomeIcon icon={faExclamation} /> No such file found</strong></div> </Animated>
    //           })
    //           setTimeout(() => { this.setState({alert : null}); }, 3000);
    //     })
    // }

            
  render() {
    return (
        <Fragment>
            <div className='row'>
                <div className='col-md-2'>
                <label htmlFor="input">Company</label>
                    <select name="input" id="input" style={{width: '9rem', borderRadius: '20%'}} value={this.state.input} onChange={this.onInputChange}>
                        <option value="" disabled>select</option>
                        {this.state.input_option.map( each => <option key={each} value={each}>{each}</option>)}
                    </select>
                </div>
            </div>

            <div className="row mt-2">
            {/* {this.state.alert}     */}
            {/* <button type="button" className="btn btn-info" onClick={ () => this.downloadResultFile() }><GetAppIcon /></button> */}
                <div className="col-md-12">
                    <DataTableS3Download columns={this.state.columns} title="Reconciliation Detail Report" loading={this.state.fetching} key={this.state.data} data={this.state.data} s3Key={this.state.s3Key} TenantID={this.userData.USR_Tenant} ></DataTableS3Download>
                </div>
            </div>
        </Fragment>
    )
  }
}


function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(
    mapStateToProps,
    { navUrl }
)(ReconciliationDetailReport);