// import React, { Component, Fragment } from 'react'
// import {Form, Button} from 'react-bootstrap';
// import { getRequest } from '../../globalhelper/helper';
// import '../CI/COD.css'
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
// import ApiConstants from '../../config'
// import download from 'downloadjs';
// import axios from 'axios';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { connect } from 'react-redux';

// export class PO_Dropdown extends Component {
//     constructor(props) {
//       super(props)

//       console.log(this.props.state)

//       console.log(this.props.state.login.userData.USR_Tenant)
    
//       this.state = {
//         showtable: false,
//          formData: {
//             orgname: '',
//             date: '',
//             vendorname: '',
//             ponumber: '',
//             vendorid: '',
//             organizationid: '',
//             taxcode: '',
//             periodfrom: '',
//             periodto: '',
//             status: ''
//          },
//          tableData: [],
//          dropdown_orgname: [],
//          dropdown_vendorname: [],
//          dropdown_vendorid: [],
//          dropdown_ponumber: [],
//          dropdown_organizationid: [],
//          dropdown_taxcode: [],
//          dropdown_status: [],
//          companyDetails: [],
//          error: false,
//          errorMessage: ''
//       }
//     }

//     componentDidMount(){
//         localStorage.setItem("pageName", "Search PurchaseOrder")

//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const arr = responseData.map(item => item.orgname)
//             console.log(arr)
//             this.setState({
//                 ...this.state,
//                 dropdown_orgname: arr,
//                 companyDetails: responseData
//             })
//         }).catch(err => console.log(err))

//         getRequest(`api/edureka/getPORemainingValues`).then(res => {
//             const responseData = res.res.data

//             this.setState({
//                 ...this.state,
//                 dropdown_status: responseData
//             })
//         })
//     }

//     handleChange = (e) => {
//         const NAME = e.target.name, VALUE = e.target.value
//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [NAME]: VALUE
//             }
//         })
//     }

//     handleSearch = () => {

//         const orgname = this.state.formData.orgname

//         if(orgname){

//             const ponumber = this.state.formData.ponumber
//             const vendorname = this.state.formData.vendorname
//             const vendorid = this.state.formData.vendorid
//             const status = this.state.formData.status

//             this.setState({
//                 ...this.state,
//                 error: false
//             })

//             const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
//             const companyid = id.map(item => item.orgid)

//             getRequest(`api/edureka/getPOData?ponumber=${ponumber}&vendorname=${vendorname}&orgid=${companyid[0]}&status=${status}&vendorid=${vendorid}`).then(res => {
//                 let temp = JSON.parse(res.res.data)

//                 if(temp.length){
//                     this.setState({
//                         ...this.state,
//                         tableData: temp,
//                         showtable: true
//                     })
//                 } else {
//                     this.setState({
//                         ...this.state,
//                         error: true,
//                         errorMessage: "No data found. Please try some other combination"
//                     })
//                 }
    
//             })
//         } else {
//             this.setState({
//                 ...this.state,
//                 error: true,
//                 errorMessage: "Please select all the required fields"
//             })
//         }
//     }

//     handlePO = (item) => {
//         console.log(item['PO Number'])
//         const ponumber = item['PO Number']

//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getPOUpdatedJSON?ponumber=${ponumber}&orgid=${companyid[0]}`).then(res => {
//             if(res.res.status === 'success'){
//                 console.log(res.res.data)

//                 this.setState({redirect: true, SearchJSON: res.res.data})
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     handleReset = () => {
//         this.setState({
//             ...this.state,
//             showtable: false,
//             error: false,
//             formData: {
//                 ...this.state.formData,
//                 vendorname: '',
//                 ponumber: '',
//                 vendorid: '',
//                 status: '',
//                 orgname: ''
//             }
//         })
//     }

//     handleDownload = (item) => {
//         console.log(item)

//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
//         const companyid = id.map(item => item.orgid)

//         axios.post(ApiConstants.externals.serverUrl +  `api/edureka/handlePODownload`, {filename: item, tenantid: this.props.state.login.userData.USR_Tenant, orgid: companyid[0]} ,
//           { responseType: 'blob' }
//         )
//         .then(response => {
//                 console.log(response.data)
//                 const blob = response.data;
//                 download(blob, item + '.pdf');
//         })
//     }

//     onBack = () => {
//         this.setState({
//             redirect: false,
//             SearchJSON: []
//         })
//     }

//     handleOrgChange = (e) => {
//         const {name, value} = e.target

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [name]: value
//             }
//         }, () => this.handleRemainingAPIs())
//     }

//     handleRemainingAPIs = () => {
//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getPOVendor?orgid=${companyid[0]}`).then(res => {
//             const responseData = JSON.parse(res.res.data)
//             const vendorName = responseData.map(item => item["Vendor Name"])
//             const vendorID = responseData.map(item => item["Vendor ID"])

//             this.setState({
//                 ...this.state,
//                 dropdown_vendorname: vendorName,
//                 dropdown_vendorid: vendorID
//             })
//         })

//         getRequest(`api/edureka/getPOTaxCode?orgid=${companyid[0]}`).then(res => {
//             const responseData = JSON.parse(res.res.data)
//             const PONumber = responseData.map(value => value['PO Number'])

//             this.setState({
//                 ...this.state,
//                 dropdown_ponumber: PONumber
//             })
//         })
//     }

//   render() {

//     if(this.state.redirect){
//         return(
//             <VR2_Forms
//             screenName = {"PurchaseOrder"}
//             screenAction = {"edit"}
//             From_SearchJSON = {this.state.SearchJSON}
//             onBack = {this.onBack}
//             />
//         )
//     }
//     else return (
//       <Fragment>
//         <div className='header_div'>Search PO</div>

//         <br/>
        
//         <Form style={{display:'flex', flexDirection:'column'}}>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
//                 <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleOrgChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Date:</div>
//                 <input className="col-lg-3" value={this.state.formData.date} type='date' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='date' onChange={this.handleChange}></input>
//             </div> */}

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>PO Number:<span style={{color:'red'}}>*</span></div>
//                 <select className='col-lg-3' value={this.state.formData.ponumber} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='ponumber' onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_ponumber.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2'style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor ID:</div>
//                 {/* <input className="col-lg-3" value={this.state.formData.vendorid} type='text' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorid' onChange={this.handleChange}></input> */}
//                 <select className='col-lg-3' value={this.state.formData.vendorid} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorid' onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendorid.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2'style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Vendor Name:</div>
//                 {/* <input className="col-lg-3" value={this.state.formData.vendorname} type='text' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorname' onChange={this.handleChange}></input> */}
//                 <select className='col-lg-3' value={this.state.formData.vendorname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='vendorname' onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_vendorname.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Tax Code:</div>
//                 <select className='col-lg-3' value={this.state.formData.taxcode} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='taxcode' onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     <option value='SGST/CGST'>SGST/CGST</option>
//                     <option value='IGST'>IGST</option>
//                 </select>
//             </div> */}

//             {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Period From:</div>
//                 <input className='col-lg-3' value={this.state.formData.periodfrom} type='date' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='periodfrom' onChange={this.handleChange}></input>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Period To:</div>
//                 <input className='col-lg-3' value={this.state.formData.periodto} type='date' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='periodto' onChange={this.handleChange}></input>
//             </div> */}

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Status:</div>
//                 <select className='col-lg-3' value={this.state.formData.status} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='status' onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_status.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{textAlign:'center'}}>
//                 <Button variant='primary' style={{marginRight:'10px'}} onClick={this.handleSearch}>Search</Button>
//                 <Button variant='danger' onClick={this.handleReset}>Reset</Button>
//             </div>
//         </Form>

//         <br/>

//         {this.state.showtable && this.state.tableData.length >0 ?
//         <div>
//             <table border='1' id='table_styling'>
//                 <thead>
//                     <tr>
//                         <th>PO Number</th>
//                         <th>Date</th>
//                         <th>Vendor ID</th>
//                         <th>Vendor Name</th>
//                         {/* <th>Organization ID</th> */}
//                         <th>Status</th>
//                         <th>Download</th>
//                         {/* <th>Total Value</th> */}
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {this.state.tableData.sort((a,b) => a['PO Number'].localeCompare(b['PO Number'])).map((item, index) => {
//                         return(
//                             <tr key={index}>
//                                 <td><Button variant='link' size='sm' onClick={() => this.handlePO(item)}>{item['PO Number']}</Button></td>
//                                 <td>{item['Date']}</td>
//                                 <td>{item['Vendor ID']}</td>
//                                 <td>{item['Vendor Name']}</td>
//                                 {/* <td>{item['Organization ID']}</td> */}
//                                 <td>{item['Status']}</td>
//                                 <td><Button variant='link' size='sm' onClick={() => this.handleDownload(item['PO Number'])}><FileDownloadIcon/></Button></td>
//                                 {/* <td>{item[]}</td> */}
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div> : null}

//         {this.state.error ?
//         <div className='center-screen'>
//             {this.state.errorMessage}
//         </div> :  null}
//       </Fragment>
//     )
//   }
// }

// function mapStateToProps(state) {
//     return {
//         state
//     }
// }

// export default connect(mapStateToProps) (PO_Dropdown)


import React, { Component, Fragment } from 'react'
import {Form, Button} from 'react-bootstrap';
import { getRequest } from '../../globalhelper/helper';
import '../CI/COD.css'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import ApiConstants from '../../config'
import download from 'downloadjs';
import axios from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';

export class PO_Dropdown extends Component {
    constructor(props) {
      super(props)

      console.log(this.props.state)

      console.log(this.props.state.login.userData.USR_Tenant)
    
      this.state = {
        showtable: false,
         formData: {
            orgname: '',
            date: '',
            vendorname: '',
            ponumber: '',
            vendorid: '',
            organizationid: '',
            taxcode: '',
            periodfrom: '',
            periodto: '',
            status: ''
         },
         tableData: [],
         dropdown_orgname: [],
         dropdown_vendorname: [],
         dropdown_vendorid: [],
         dropdown_ponumber: [],
         dropdown_organizationid: [],
         dropdown_taxcode: [],
         dropdown_status: [],
         companyDetails: [],
         error: false,
         errorMessage: ''
      }
    }

    componentDidMount(){
        localStorage.setItem("pageName", "Search PurchaseOrder")

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const arr = responseData.map(item => item.orgname)
            console.log(arr)
            this.setState({
                ...this.state,
                dropdown_orgname: arr,
                companyDetails: responseData
            })
        }).catch(err => console.log(err))

        getRequest(`api/edureka/getPORemainingValues`).then(res => {
            const responseData = res.res.data

            this.setState({
                ...this.state,
                dropdown_status: responseData
            })
        })
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        })
    }

    handleSearch = () => {

        const orgname = this.state.formData.orgname

        if(orgname){

            const ponumber = this.state.formData.ponumber
            const vendorname = this.state.formData.vendorname
            const vendorid = this.state.formData.vendorid
            const status = this.state.formData.status

            this.setState({
                ...this.state,
                error: false
            })

            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
            const companyid = id.map(item => item.orgid)

            getRequest(`api/edureka/getPOData?ponumber=${ponumber}&vendorname=${vendorname}&orgid=${companyid[0]}&status=${status}&vendorid=${vendorid}`).then(res => {
                let temp = JSON.parse(res.res.data)

                if(temp.length){
                    this.setState({
                        ...this.state,
                        tableData: temp,
                        showtable: true
                    })
                } else {
                    this.setState({
                        ...this.state,
                        error: true,
                        errorMessage: "No data found. Please try some other combination"
                    })
                }
    
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: "Please select all the required fields"
            })
        }
    }

    handlePO = (item) => {
        console.log(item['PO Number'])
        const ponumber = item['PO Number']

        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
        const companyid = id.map(item => item.orgid)

        getRequest(`api/edureka/getPOUpdatedJSON?ponumber=${ponumber}&orgid=${companyid[0]}`).then(res => {
            if(res.res.status === 'success'){
                console.log(res.res.data)

                this.setState({redirect: true, SearchJSON: res.res.data})
            } else {
                console.log("No Data")
            }
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            showtable: false,
            error: false,
            formData: {
                ...this.state.formData,
                vendorname: '',
                ponumber: '',
                vendorid: '',
                status: '',
                orgname: ''
            }
        })
    }

    handleDownload = (item) => {
        console.log(item)

        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
        const companyid = id.map(item => item.orgid)

        axios.post(ApiConstants.externals.serverUrl +  `api/edureka/handlePODownload`, {filename: item, tenantid: this.props.state.login.userData.USR_Tenant, orgid: companyid[0]} ,
          { responseType: 'blob' }
        )
        .then(response => {
                console.log(response.data)
                const blob = response.data;
                download(blob, item + '.pdf');
        })
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

    handleOrgChange = (e) => {
        const {name, value} = e.target

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        }, () => this.handleRemainingAPIs())
    }

    handleRemainingAPIs = () => {
        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)
    
        const companyid = id.map(item => item.orgid)

        getRequest(`api/edureka/getPOVendor?orgid=${companyid[0]}`).then(res => {
            const responseData = JSON.parse(res.res.data)
            const vendorName = responseData.map(item => item["Vendor Name"])
            const vendorID = responseData.map(item => item["Vendor ID"])

            this.setState({
                ...this.state,
                dropdown_vendorname: vendorName,
                dropdown_vendorid: vendorID
            })
        })

        getRequest(`api/edureka/getPOTaxCode?orgid=${companyid[0]}`).then(res => {
            const responseData = JSON.parse(res.res.data)
            const PONumber = responseData.map(value => value['PO Number'])

            this.setState({
                ...this.state,
                dropdown_ponumber: PONumber
            })
        })
    }

  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
            screenName = {"PurchaseOrder"}
            screenAction = {"edit"}
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {this.onBack}
            />
        )
    }
    else return (
      <Fragment>
        <div className='header_div'>Search PO</div>

        <br/>
        
        <Form style={{display:'flex', flexDirection:'column'}}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization Name:<span style={{color:'red'}}>*</span> </div>
                <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleOrgChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
                </select>
            </div>

            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Date:</div>
                <input className="col-lg-3" value={this.state.formData.date} type='date' style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='date' onChange={this.handleChange}></input>
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                    PO Number:<span style={{ color: 'red' }}>*</span>
                </div>
                <ReactSelect
                    className='col-lg-3'
                    name='ponumber'
                    value={this.state.dropdown_ponumber.find(option => option.value === this.state.formData.ponumber)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'ponumber', value: selectedOption.value } })}
                    options={this.state.dropdown_ponumber.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Vendor ID:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='vendorid'
                    value={this.state.dropdown_vendorid.find(option => option.value === this.state.formData.vendorid)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'vendorid', value: selectedOption.value } })}
                    options={this.state.dropdown_vendorid.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Vendor Name:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='vendorname'
                    value={this.state.dropdown_vendorname.find(option => option.value === this.state.formData.vendorname)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'vendorname', value: selectedOption.value } })}
                    options={this.state.dropdown_vendorname.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>Status:</div>
                <ReactSelect
                    className='col-lg-3'
                    name='status'
                    value={this.state.dropdown_status.find(option => option.value === this.state.formData.status)}
                    onChange={(selectedOption) => this.handleChange({ target: { name: 'status', value: selectedOption.value } })}
                    options={this.state.dropdown_status.map(item => ({ value: item, label: item }))}
                    styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    placeholder="Select"
                />
            </div>

            <div style={{textAlign:'center'}}>
                <Button variant='primary' style={{marginRight:'10px'}} onClick={this.handleSearch}>Search</Button>
                <Button variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>
        </Form>

        <br/>

        {this.state.showtable && this.state.tableData.length >0 ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>PO Number</th>
                        <th>Date</th>
                        <th>Vendor ID</th>
                        <th>Vendor Name</th>
                        {/* <th>Organization ID</th> */}
                        <th>Status</th>
                        <th>Download</th>
                        {/* <th>Total Value</th> */}
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.sort((a,b) => a['PO Number'].localeCompare(b['PO Number'])).map((item, index) => {
                        return(
                            <tr key={index}>
                                <td><Button variant='link' size='sm' onClick={() => this.handlePO(item)}>{item['PO Number']}</Button></td>
                                <td>{item['Date']}</td>
                                <td>{item['Vendor ID']}</td>
                                <td>{item['Vendor Name']}</td>
                                {/* <td>{item['Organization ID']}</td> */}
                                <td>{item['Status']}</td>
                                <td><Button variant='link' size='sm' onClick={() => this.handleDownload(item['PO Number'])}><FileDownloadIcon/></Button></td>
                                {/* <td>{item[]}</td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}

        {this.state.error ?
        <div className='center-screen'>
            {this.state.errorMessage}
        </div> :  null}
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(mapStateToProps) (PO_Dropdown)