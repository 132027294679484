import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import {Animated} from "react-animated-css";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import store from '../store';
import { postRequest, getCurrentDate, getStandardDateTime } from '../globalhelper/helper';
import DataTableTemplate from "../components/datatable";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { showAlert, hideAlert }  from '../actions/common_actions'
import { connect } from 'react-redux'


// const state = store.getState();
// const userData = state.login.userData;
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class AccountReports extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            showFile : false,
            noData : false,
            formData : {
                fromdate : new Date(new Date().setDate(new Date().getDate()-30)),
                todate : new Date()
            }
        };

        this.tempCol = [
            {
              name: 'Asset No',
              selector: 'AssetNo',
              sortable: true,
              wrap : true,
              width : '200px',
            },
            {
              name: 'Asset Description',
              selector: 'AssetDescription',
              sortable: true,
              wrap : true,
              width : '250px',
            },
            {
              name: 'Location',
              selector: 'Location',
              sortable: true,
              wrap : true,
              width : '100px',
            },
            {
              name: 'Asset Class',
              selector: 'AssetClass',
              sortable: true,
              wrap : true,
              width : '100px',
            },
            {
              name: 'Asset Sub Class',
              selector: 'AssetSubClass',
              sortable: true,
              wrap : true,
              width : '100px',
            },
            {
              name: 'Old Asset Ref No',
              selector: 'OldAssetRefNo',
              sortable: true,
              wrap : true,
              width : '200px',
            },
            {
              name: 'Qty',
              selector: 'Qty',
              sortable: true,
              wrap : true,
              width : '50px',
            },
            {
              name : "Date of Capitalization",
              cell: (row) => {
                  return getStandardDateTime(row.DateofCapitalization);
              },
              selector: 'DateofCapitalization',
              wrap : true,
              width : '150px',
              button: true,
            },
            {
                name: 'Depreciation Rate Per',
                selector: 'DepreciationRatePer',
                right : true,
                cell : (row) => {
                  return Number(row.IFRS_DepDuringThePeriod).toFixed(2)
                },
                sortable: true,
                wrap : true,
                width : '150px',
            },
            {
                name : "Date of Deletion",
                cell: (row) => {
                    return getStandardDateTime(row.DateofDeletion);
                },
                selector: 'DateofDeletion',
                wrap : true,
                width : '150px',
                button: true,
            },
            {
                name: 'Qttr',
                selector: 'Qttr',
                sortable: true,
                wrap : true,
                width : '150px',
            },
            {
                name : "Voucher Ref Dt",
                selector: 'VoucherRefDt',
                wrap : true,
                width : '300px',
                button: true,
            },
            {
              name: 'Opening Gross Block',
              selector: 'OpeningGrossBlock',
                right : true,
              cell : (row) => {
                return Number(row.OpeningGrossBlock).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'Accumulated Depr',
              selector: 'AccumulatedDepr',
                right : true,
              cell : (row) => {
                return Number(row.AccumulatedDepr).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'DeprDuring The Period',
              selector: 'DeprDuringThePeriod',
                right : true,
              cell : (row) => {
                return Number(row.DeprDuringThePeriod).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'Net RealiZable Value',
              selector: 'NetRealisableValue',
              sortable: true,
                right : true,
              cell : (row) => {
                return Number(row.NetRealisableValue).toFixed(2)
              },
              wrap : true,
              width : '150px',
            },
            {
              name: 'Profit Loss On Sale of FA',
              selector: 'ProfitLossOnSaleofFA',
                right : true,
              cell : (row) => {
                return Number(row.ProfitLossOnSaleofFA).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Dep Per',
              selector: 'IFRSDepPer',
                right : true,
              cell : (row) => {
                return Number(row.IFRSDepPer).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Opening Accum Dep',
              selector: 'IFRS_OpeningAccumDep',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_OpeningAccumDep).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS DepDuring The Period',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_DepDuringThePeriod).toFixed(2)
              },
              selector: 'IFRS_DepDuringThePeriod',
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Dep On Deletion',
              selector: 'IFRS_DepOnDeletion',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_DepOnDeletion).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Dep On Reclass',
              selector: 'IFRS_DepOnReclass',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_DepOnReclass).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Dep On Transfer',
              selector: 'IFRS_DepOnTransfer',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_DepOnTransfer).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Closing Accum Dep',
              selector: 'IFRS_ClosingAccumDep',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_ClosingAccumDep).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Closing Book Value',
              selector: 'IFRS_ClosingBookValue',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_ClosingBookValue).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            },
            {
              name: 'IFRS Profit Loss On Sale of FA',
              selector: 'IFRS_ProfitLossOnSaleofFA',
                right : true,
              cell : (row) => {
                return Number(row.IFRS_ProfitLossOnSaleofFA).toFixed(2)
              },
              sortable: true,
              wrap : true,
              width : '150px',
            }
          ]
          this.columns = this.tempCol;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
   
    handleSubmit = () =>{
        this.setState({
            ...this.state,
            data : []
        })
        postRequest( 'api/reports/getFixedAssetDeletionTable',{
            fromdate : this.state.formData.fromdate,
            todate : this.state.formData.todate,
            tenantId : localStorage.getItem("tenant_id")
          })
        .then(response => {
            if(response.res.status === "success")
            {
                var data = response.res.data;
                var data_length = data.length;
                if(data_length > 0)
                {
                    this.setState({
                        ...this.state,
                        data : data
                    },() => {
                        console.log(this.state);
                    })
                }
                else{
                    this.setState({
                        ...this.state,
                        noData : true
                    })
                }
            }
        })
        .catch(err => {
            this.props.showAlert("Problem with connecting Dataserver...", "warning")
            // this.setState({
            //     alert : <Animated animationIn="fadeInDown" animationOut="fadeInUp" isVisible={true}> <div className="alert alert-danger"><strong> Problem with connecting Dataserver... </strong></div> </Animated>
            // });
            setTimeout(() => { this.props.hideAlert() }, 3000);
        });
    }

    setDate = (date,field) =>{

        this.setState({
        ...this.state,
        formData : {
            ...this.state.formData,
            [field] : date
        }
        },() => {
        console.log(this.state)
        })
    }


    handleChange = (event) =>{

        var id = [event.target.id];
        this.setState({
            formData: {
                    ...this.state.formData,
                    [id]: event.target.value
            }
        })
    }


    render (){
        const { data } = this.state;
        return (
            <div>
                <div className="row">
                    <div className='col-md-3'>
                        <label>From</label> <br/>
                            <DatePicker selected={this.state.formData.fromdate} showPopperArrow={false}  maxDate={ this.state.formData.todate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'fromdate') } id="fromdate" />
                    </div>
                    

                    <div className='col-md-3'>
                        <label>To</label><br/>
                        <DatePicker selected={this.state.formData.todate} showPopperArrow={false}  minDate={ this.state.formData.fromdate } dateFormat="dd/MM/yyyy" className="col-md-12" onChange={ (date) => this.setDate(date,'todate') } id="todate" />
                    </div>
                    
                    <div className="col-md-2 text-center" style={{ marginTop : "25px" }}>
                        <button className="btn btn-teal" onClick={ this.handleSubmit }> Load </button>
                    </div>
                </div>

                { this.state.data && this.state.data.length > 0
                ?
                <div>
                    <Card style={{ height: '100%' }}>
                        <DataTableTemplate 
                        name="Available Records"
                        columns={this.columns}
                        data={data}
                        enableExport='true'
                        exportFileName="Fixed Asset Deletion"
                        defaultSortField='AssetClass'
                        />
                    </Card>
                </div>
                    : 
                    
                    this.state.noData ? 
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                        <h6> No Data Found for the given dates !</h6>
                    </div>
                    : 
                    null
                    }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      state
    }
  };



  export default connect(
    mapStateToProps,
    { showAlert, hideAlert }
)(AccountReports);
