import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    changeDependencyValues,
    getDropdownOptions,
} from "../../actions/dynamic_forms_action";

export default function SelectBox(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDropdownOptions(props.id));
    if (props.value) {
      let ev = {
        target: {
          name: props.serviceKey,
          value: props.value,
        },
      };
      props.onChange(ev, props.id, props.value);
    }
  }, []);

  const onDropDownValueChange = (ev) => {
    props.onChange(ev, props.id, props.serviceKey);
    changeDependencyValues(props.id);
  };

  return (
    <Fragment>
      <label htmlFor={props.value}> {props.label} </label> <br />
      <select
        name={props.serviceKey}
        style={props.inputOptions.css}
        id={props.serviceKey}
        onChange={(ev) => onDropDownValueChange(ev)}
        value={props.value}
      >
        <option></option>
        {props.options !== undefined &&
          props.options.map((opt, optKey) => (
            <option key={optKey} style={opt.inputOptions.css} value={opt.value}>
              {opt.label}
            </option>
          ))}
      </select>
      <br />
    </Fragment>
  );
}
