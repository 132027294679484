import React, { useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../globalhelper/helper';
import DownloadIcon from '@mui/icons-material/Download';
import _ from 'lodash';
import { Box, FormControl, InputLabel, MenuItem, Select, TableHead, TextField, Typography } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DataNotFoundImg from '../../../assets/Status/404.png';

function BankStatement(props) {
  const [companies, setCompanies] = useState([]);
  const [banks, setBanks] = useState([]);
  const [Reports, setReports] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    orgid: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    accNo: ''
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Reports.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const convertToCSV = (data) => {
    if (!data || !data.length) return '';

    // Get a consistent order of headers
    const headers = Object.keys(data[0]);
    // Join headers with commas to form the header row
    const headerRow = headers.join(',');

    // Map JSON data to CSV rows, ensuring the same order as headers
    const rows = data.map((row) => headers.map((header) => JSON.stringify(row[header] || '')).join(','));

    // Combine headers and rows
    return [headerRow, ...rows].join('\n');
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(Reports); // Convert JSON to CSV
    console.log(csvData);

    const now = new Date().toISOString().replace(/[-:.TZ]/g, ''); // ISO format for timestamp
    console.log(now);

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' }); // Create Blob with UTF-8 encoding
    const url = URL.createObjectURL(blob); // Create URL
    const link = document.createElement('a'); // Create a link element
    link.href = url;
    link.download = `Reports_${now}.csv`; // Specify file name
    document.body.appendChild(link); // Append to DOM
    link.click(); // Trigger download
    document.body.removeChild(link); // Clean up
  };

  const getCompanyByUser = async () => {
    try {
      const response = await getRequest('api/veranda/getCompany0');
      if (response.type === 'success') {
        let parsedData = JSON.parse(response.res.data);
        parsedData = _.sortBy(parsedData, 'orgname');
        setCompanies(parsedData);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getBanks = async () => {
    try {
      const response = await postRequest('api/edureka/getBankListByOrgID', {
        data: formData.orgid
      });
      if (response.type === 'success') {
        setBanks(response.res.data);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompanyByUser();
    console.log(Boolean(Reports.length < 1 && formSubmitted), formSubmitted);
  }, []);

  useEffect(() => {
    getBanks();
  }, [formData.orgid]);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleEndDateChange = (value) => {
    const formattedDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
    setFormData({ ...formData, endDate: formattedDate }); // Update the date field in formData
  };
  const handleStartDateChange = (value) => {
    const formattedDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
    setFormData({ ...formData, startDate: formattedDate }); // Update the date field in formData
  };
  const handleSubmit = async (e, formdata) => {
    try {
      e.preventDefault();
      const response = await postRequest('api/edureka/getBankStatement', {
        data: formdata
      });
      let parsedData = JSON.parse(response.res.data);
      if (response.type === 'success' && parsedData.length > 0) {
        setReports(parsedData);
      } else {
        setFormSubmitted(true);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page">
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 30, width: '100%', mb: 10 }}>
        <FormControl
          fullWidth
          sx={{ mb: 2 }}>
          <InputLabel id="demo-simple-select-label">Company</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.orgid}
            name="orgid"
            label="Select Company"
            onChange={handleChanges}>
            {companies && companies.length > 0 ? (
              companies.map((company) => (
                <MenuItem
                  value={company.orgid}
                  key={company.orgid}>
                  {company.orgname}
                </MenuItem>
              ))
            ) : ''}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              renderInput={(params) => (
                <TextField
                  name="startDate"
                  {...params}
                  fullWidth
                />
              )}
              className="startDate"
              value={formData.startDate}
              onChange={handleStartDateChange}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              renderInput={(params) => (
                <TextField
                  name="endDate"
                  {...params}
                  fullWidth
                />
              )}
              value={formData.endDate}
              className="endDate"
              onChange={handleEndDateChange}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl
          fullWidth
          sx={{ mb: 2 }}>
          <InputLabel id="demo-simple-select-label">Account No</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.accNo}
            name="accNo"
            label="Select Account No"
            onChange={handleChanges}>
            {banks && banks.length > 0 ? (
              banks.map((bank) => (
                <MenuItem
                  value={bank['BANK AC']}
                  key={bank['BANK AC']}>
                  {bank['BANK AC']}
                </MenuItem>
              ))
            ) : ''}
          </Select>
        </FormControl>
        <Button
          disabled={Object.values(formData).some((value) => value == '' || null)}
          variant="contained"
          onClick={(e) => handleSubmit(e, formData)}>
          Submit
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={Reports.length < 1}
          onClick={() => downloadCSV()}
          sx={{ whiteSpace: 'nowrap' }}
          title="Generate CSV">
          <DownloadIcon />
        </Button>
      </Box>
      {formSubmitted ? (
        Reports.length < 1 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <img
              src={DataNotFoundImg}
              alt="Data Not Found"
            />
          </Box>
        ) : (
          <Box>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 300 }}
                aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Account number
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Input theme name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Payment mode
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Transaction amount
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Transaction date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Transaction description
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Transaction key
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                      }}>
                      Transaction number
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? Reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : Reports
                  ).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.accountnumber}</TableCell>
                      <TableCell>{row.inputthemename}</TableCell>
                      <TableCell>{row.paymentmode}</TableCell>
                      <TableCell>{row.transactionamount}</TableCell>
                      <TableCell>{row.transactiondate}</TableCell>
                      <TableCell>{row.transactiondescription}</TableCell>
                      <TableCell>{row.transactionkey}</TableCell>
                      <TableCell>{row.transactionnumber}</TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={4}
                      count={Reports.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          inputProps: {
                            'aria-label': 'rows per page'
                          },
                          native: true
                        }
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        )
      ) : (
        ''
      )}
    </>
  );
}

export default BankStatement;
