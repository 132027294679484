import AppBar from '@material-ui/core/AppBar';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Maticons from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DataTwin_Logo_ from '../assets/data-twin-logo4.jpg';
import ApiConstants from "../config";

import { navUrl, setMenuTree } from '../actions/common_actions';
import { onLogout } from '../actions/login_actions';
import { flushChatbotItems } from '../redux/SelectApp/saActions';

import { Spinner } from 'react-bootstrap';
import { getRequest } from '../globalhelper/helper';
import store from '../store';


const drawerWidth = 225;

const ADMIN_USERS = [
    "#USR#hariprakash.babu@oneintegral.com",
    "#USR#dinesh.kumar@oneintegral.com",
    "#USR#usha.v@datatwin.ai",
    "#USR#raghavan@oneintegral.com",
]

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    drawerPaper: {
      width: drawerWidth,
    },
  }));

function ResponsiveDrawer(props) {

    setInterval(() => setMenuTree(), 1000);
    const [menus, setMenus] = React.useState([]);
    const [activeMenuIndex, setActiveMenuIndex] = React.useState(null);
 
    const bottom_menu = {
        name: "My Apps",
        url: '/',
        icon: 'AppsIcon'
    }

    const getMenus = () => {
        setMenus([]);
        getRequest('api/module/getMenus?product=' + localStorage.getItem("module"))
            .then(result => {
                if (result.res.status === "success") {
                    // this.props.setMenuTree(result.res.data);
                    setMenus(result.res.data);
                    localStorage.setItem("menuTree", JSON.stringify(result.res.data));
                }
            })
    }

    useEffect(() => {
        if(localStorage.getItem("module") !== "")
        {
            getMenus();
        }
    }, [localStorage.getItem("module")]);


    const { win } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleClick = (mn, index, menuType, menuName) => {
        localStorage.setItem("pageName", menuName);
        if (mn.group) {
            if (menuType === 'menu') {
                mn.open = !mn.open;
                var newMenu = [...menus];
                newMenu.forEach((menu, key) => {
                    if (key !== index) {
                        menu.open = false;
                    }
                })
                newMenu[index] = mn;
                setMenus(newMenu)
            }
            else {
                var newArr = [...menus];
                newArr.forEach((m) => {
                    if (m.group) {
                        m.submenus.forEach((sm, key) => {
                            if (key !== index) {
                                sm.open = false;
                            }
                            if (sm.name === mn.name) {
                                sm.open = !sm.open;
                            }
                        })
                        setMenus(newArr)
                    }
                })
            }
        }
        else {
            mn.pagetype && mn.pagetype === "dynamic" ? mn.pagename ? localStorage.setItem("pagename", mn.pagename) : localStorage.setItem("pagename", "") : localStorage.setItem("pagename", "");
            mn.dashboard_id ? localStorage.setItem("dashboard_id", mn.dashboard_id) : localStorage.setItem("dashboard_id", "");
            props.navUrl(mn.url);
        }
    };

    const logout = (ev) => {
        ev.preventDefault();
        props.onLogout();
        props.flushChatbotItems();
        props.navUrl("/");
    }

    const getUpdate = () => {
        var tenant_id = userData ? userData.tenant_id : null;
        getRequest('api/neo4j/getQueryFile?tenant_id=' + tenant_id).then(result => {
            if (result.type === 'success') {
                window.location.reload();
            }
        })
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // console.log(props,"Propssss");
    const state1 = store.getState();
    const userData = state1.login.userData;
    const tenant_id = userData ? userData.tenant_id : null;
    var t = tenant_id ? tenant_id + '.png' : 'user.svg'
    // var graphImage =    require('../assets/logo.png')

    function setSource() {
        // console.log(t)
        try {
            // graphImage = require(`../assets/${t}`)
            // this.setState({ src });
        }
        catch (err) {
            // graphImage = '../../assets/user.svg'
        }
    }
    setSource()


    const drawer = (
        <div className="menubar">
            <div className="sidebar-header">

                { localStorage.getItem("module") === "OneRetail" ?
                    <div style={{padding: '.6rem'}}></div>
                : null }

                {/* <h4 className="audire-sidebar-header username col-md-12">DataTwin </h4> */}
                <img alt="One Integral" className="logo-container" src={DataTwin_Logo_} />

                { localStorage.getItem("module") === "OneRetail" ?
                <div style={{padding: '3rem'}}></div>
                :                
                <div className="image-cropper">
                    <img src={ApiConstants.externals.serverUrl + "api/user/getTenantImage?tenant_id=" + props.state.login.userData.USR_Tenant + "&usr_type=" + props.state.login.userData.USR_Type} alt="Your Logo" className="profile-pic" />
                </div>
                }

                <div className="row">
                    <span className="username text-center col-md-12">{userData.USR_Name ? userData.USR_Name : "User"}</span><br />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="font-sans text-gray-200 col-md-12 username logout" onClick={logout} >Logout</span>
                    </div>

                </div>
            </div>
            <Divider className="white-border" />
            {props.type === "" ? (
                <List className="menu-bar">
                    <ListItem
                        button
                        onClick={() => {
                            localStorage.setItem("go_to_landing_page", "0");
                            setActiveMenuIndex(1); // Update active menu index
                            handleClick(bottom_menu, 1, "", "My Apps");
                        }}
                        style={{
                            backgroundColor: activeMenuIndex === 1 ? "#f0f0f0" : "transparent", // Set background color to a light shade of white
                            color: activeMenuIndex === 1 ? "#233067" : "inherit", // Set text color to #233067
                        }}
                    >
                        <ListItemIcon>
                            <Maticons.Apps
                                className="nav-icon"
                                style={{ color: activeMenuIndex === 1 ? "#233067" : "white" }} // Set icon color based on selection
                            />
                        </ListItemIcon>
                        <ListItemText primary={"My Apps"} />
                    </ListItem>
                </List>
            ) : (
                <List className="menu-bar">
                    {menus.length > 0 ? (
                        menus.map((menu, index) => {
                            var Icon = menu.icon;
                            const IconToRender = Maticons[Icon];
                            return (
                                <React.Fragment key={index}>
                                    {menu.url === "/visual_rendering" ? (
                                        ADMIN_USERS.includes(userData.PK) ? (
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    setActiveMenuIndex(index); // Update active menu index
                                                    handleClick(menu, index, "menu", menu.name);
                                                }}
                                                style={{
                                                    backgroundColor: activeMenuIndex === index ? "#f0f0f0" : "transparent", // Set background color to a light shade of white
                                                    color: activeMenuIndex === index ? "#233067" : "inherit", // Set text color to #233067
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <IconToRender
                                                        className="nav-icon"
                                                        style={{ color: activeMenuIndex === index ? "#233067" : "white" }} // Set icon color based on selection
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={menu.name} />
                                                {menu.group ? menu.open ? <ExpandLess /> : <ExpandMore /> : null}
                                            </ListItem>
                                        ) : null
                                    ) : (
                                        <ListItem
                                            button
                                            onClick={() => {
                                                setActiveMenuIndex(index); // Update active menu index
                                                handleClick(menu, index, "menu", menu.name);
                                            }}
                                            style={{
                                                backgroundColor: activeMenuIndex === index ? "#f0f0f0" : "transparent", // Set background color to a light shade of white
                                                color: activeMenuIndex === index ? "#233067" : "inherit", // Set text color to #233067
                                            }}
                                        >
                                            <ListItemIcon>
                                                <IconToRender
                                                    className="nav-icon"
                                                    style={{ color: activeMenuIndex === index ? "#233067" : "white" }} // Set icon color based on selection
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={menu.name} />
                                            {menu.group ? menu.open ? <ExpandLess /> : <ExpandMore /> : null}
                                        </ListItem>
                                    )}
                                    {(menu.group === true || menu.group == "true") && menu.submenus ? (
                                        <Collapse in={menu.open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding className="sub-menu-bar">
                                                {menu.submenus.map((sm, key) => {
                                                    const SubMenuIcon = Maticons[sm.icon];
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <ListItem
                                                                button
                                                                className={classes.nested}
                                                                onClick={() => {
                                                                    setActiveMenuIndex(`sub-${key}`); // Update active sub-menu index
                                                                    handleClick(sm, key, "sm", `${menu.name}/${sm.name}`);
                                                                }}
                                                                style={{
                                                                    backgroundColor: activeMenuIndex === `sub-${key}` ? "#f0f0f0" : "transparent", // Set background color to a light shade of white
                                                                    color: activeMenuIndex === `sub-${key}` ? "#233067" : "inherit", // Set text color to #233067
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                    <SubMenuIcon
                                                                        className="nav-icon"
                                                                        style={{ color: activeMenuIndex === `sub-${key}` ? "#233067" : "white" }} // Set icon color based on selection
                                                                    />
                                                                    <span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                </ListItemIcon>
                                                                <ListItemText primary={sm.name} />
                                                                {sm.group ? sm.open ? <ExpandLess /> : <ExpandMore /> : null}
                                                            </ListItem>
                                                            {sm.group ? (
                                                                sm.url === "/visual_rendering" || sm.url === "/view" ? null : (
                                                                    <Collapse in={sm.open} timeout="auto" unmountOnExit>
                                                                        <List component="div" disablePadding className="sub-menu-bar">
                                                                            {sm.submenus.map((ssm, ind) => {
                                                                                const SuperSubMenuIcon = Maticons[ssm.icon];
                                                                                return (
                                                                                    <React.Fragment key={ind}>
                                                                                        <ListItem
                                                                                            button
                                                                                            className={classes.nested}
                                                                                            onClick={() => {
                                                                                                setActiveMenuIndex(`supersub-${ind}`); // Update active super sub-menu index
                                                                                                handleClick(ssm, ind, "ssm", `${menu.name}/${sm.name}/${ssm.name}`);
                                                                                            }}
                                                                                            style={{
                                                                                                backgroundColor: activeMenuIndex === `supersub-${ind}` ? "#f0f0f0" : "transparent", // Set background color to a light shade of white
                                                                                                color: activeMenuIndex === `supersub-${ind}` ? "#233067" : "inherit", // Set text color to #233067
                                                                                            }}
                                                                                        >
                                                                                            <ListItemIcon>
                                                                                                <span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                                                <SuperSubMenuIcon
                                                                                                    className="nav-icon"
                                                                                                    style={{ color: activeMenuIndex === `supersub-${ind}` ? "#233067" : "white" }} // Set icon color based on selection
                                                                                                />
                                                                                                <span> &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                                            </ListItemIcon>
                                                                                            <ListItemText primary={ssm.name} />
                                                                                        </ListItem>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })}
                                                                        </List>
                                                                    </Collapse>
                                                                )
                                                            ) : null}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </List>
                                        </Collapse>
                                    ) : null}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
                            <Spinner animation="grow" variant="text-navy" />
                        </div>
                    )}
                </List>
            )}
            {/* <div> */}
                {props.type !== "" ?
                    <List className="bottom-menu-button">
                        {/* <ListItem button > */}
                        <ListItem button onClick={() => { localStorage.setItem("go_to_landing_page" , "0"); handleClick(bottom_menu, 1)}}>
                            <ListItemIcon>
                                <Maticons.Apps style={{ color: 'black' }} />
                            </ListItemIcon>
                            <ListItemText primary={"My Apps"} />
                        </ListItem>
                    </List>
                    : null}
                {/* <img alt="One Integral" className="logo-container" src={DataTwin_Logo} />
            </div> */}
        </div>
    );

    const container = win !== undefined ? () => win().document.body : undefined;

    return (
        <div className={classes.root} key={0}>
            <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: "white" }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        style={{ color: "#233067" }}
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" >
                        {/* <h6 className="lease-name" style={{ color : '#233067', fontSize : '1.25rem', fontWeight : '700', letterSpacing : '0.05em' }}> {localStorage.getItem("module_desc") ||  "Simplify Sensing Risk & Non-compliance"}</h6> */}

                        <h6 className="lease-name" style={{ color : '#233067', fontSize : '1.25rem', fontWeight : '700', letterSpacing : '0.05em' }}> 

                            {/* {localStorage.getItem("module_desc_for_ui_only") && !localStorage.getItem("pageName") ? 
                            localStorage.getItem("module_desc_for_ui_only") : localStorage.getItem("module_desc")
                            ||  
                            "Simplify Sensing Risk & Non-compliance"} */}
                            {localStorage.getItem("pageName") || ""}
                            
                        </h6>

                    </Typography> 
                    {/* - <p style={{ color : '#233067', opacity : .5, padding : 0, margin : 0, letterSpacing : '0.05em' }}> &nbsp; &nbsp; -  {localStorage.getItem("pageName") || ""} </p> */}

                    <IconButton
                        color="inherit"
                        className="refresh-button"
                        edge="end"
                        title="Refresh"
                        style={{ color: "#233067" }}
                        onClick={refreshPage}
                    >
                        <CachedIcon fontSize="large" />
                    </IconButton>

                    {/* <IconButton
                color="inherit"
                className="update-button"
                edge="center"
                title="Update"
                style={{ color: "#233067" }}
                onClick={getUpdate}
            >
                <SystemUpdateIcon fontSize="large" />
            </IconButton> */}
                </Toolbar>
                {/* <div className="row sidebar-header-holder" style={{}}>
                <div className="col-md-12" style={{backgroundColor : "white", color : '#233067', height : '65px'}}>
                    <h3 className="lease-name"> Simplify Sensing Risk & Non-compliance </h3>
                    <h3 className="page-name navbarshow"> {this.props.pageTitle} </h3>
                </div>
              </div> */}
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        key={1}
                        onClose={handleDrawerToggle}
                        className="nav-items"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />

            </main>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        state
    }
};


ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    //   actions: login_actions,
};

// connect(mapStateToProps /** no second argument */)(ResponsiveDrawer)
// export default ResponsiveDrawer;

export default connect(
    mapStateToProps,
    { onLogout, navUrl, flushChatbotItems }
)(ResponsiveDrawer);