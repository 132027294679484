import React, { Component } from 'react'
import './COD.css';
import './CIPopup.css';
import './LARPopup.css';
import { postRequest, convertNumberIntoINR, getRequest, convertToINR, getHumanReadableDate } from '../../globalhelper/helper';
import {Form, Button, Modal} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import ApiConstants from '../../config'

export class AgeingReport extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData: {
            client: '',
            model: '',
            zone: '',
            stationcode: '',
            startdate: '',
            enddate: ''
         },
         dropdown_client: [],
         dropdown_model: [],
         dropdown_zone: [],
         dropdown_stationcode: [],
         uniqueStationCode: [],
         allData: [],
         data: [],
         error: '',
         showtable: false,
         downloadData: [],
         fetching: false,
         showView: false,
         showpopup: false,
         selectedField: '',
         selectedValue: [],
         selectedAmount: [],
         ageingProfileDownload: []
      }
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            fetching: true
        })

        // Gets all the details about the stations
        getRequest('api/cod/getClient').then(response => {
            let client = response.res.data
            let dropdown_client = client.map(value=> value.client_short_name)

            this.setState({
                ...this.state,
                dropdown_client: dropdown_client
            })
        })

        // Gets the station codes

        getRequest(`api/cod/getStationCodeForAgeingReport`).then(response => {
            if(response.res.status === 'success'){
                console.log(response.res.data)
                let stationcode1 = []
                response.res.data.forEach(item => {
                    stationcode1.push(item.stationcode)
                })
                let stationcode = [... new Set(stationcode1)]

                this.setState({
                    ...this.state,
                    dropdown_stationcode: stationcode,
                    uniqueStationCode: stationcode
                })
            }
        })

        // Gets all the details about client, model etc

        getRequest(`api/cod/getAllData`).then(response => {
            let data = JSON.parse(response.res.data)

            this.setState({
                ...this.state,
                allData: data,
                fetching: false
            })
        })
    }

    // Gets the values of Client, zone, model if station code is given

    getRemainingValues = () => {
        getRequest(`api/cod/getRemainingValues?input=` + this.state.formData.stationcode).then(response => {
            if(response.res.status === 'success'){
                let client = response.res.data.map(value => value.client_short_name)
                let model = response.res.data.map(value => value.ci_business_type)
                let zone = response.res.data.map(value => value.zone)

                this.setState({
                    ...this.state,
                    dropdown_model: [model[0]],
                    dropdown_zone: [zone[0]],
                    formData: {
                        ...this.state.formData,
                        client: client[0],
                        model: model[0],
                        zone: zone[0]
                    }
                })
            }
        })
    }

    // Handles client change and fills model dropdown

    handleClientChange = (e) => {
        const VALUE = e.target.value
        let model = []
        
        this.setState({
            ...this.state,
            formData: {
                client: VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.client_short_name === VALUE){
                model.push(item.ci_business_type)
                return model
            }
        })

        let model1 = [... new Set(model)]

        this.setState({
            ...this.state,
            dropdown_model: model1,
            dropdown_stationcode: [],
            dropdown_zone: [],
            formData: {
                ...this.state.formData,
                client: VALUE,
                model: '',
                zone: '',
                stationcode: ''
            }
        })
    }

    // Handles model change and fills the zone dropdown

    handleModelChange = (e) => {
        const VALUE = e.target.value
        let zone = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                model: VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.ci_business_type === VALUE && item.client_short_name === this.state.formData.client){
                zone.push(item.zone)
                return zone
            } 
        })

        let zone1 = [... new Set(zone)]

        this.setState({
            ...this.state,
            dropdown_zone: zone1,
            dropdown_stationcode: [],
            formData: {
                ...this.state.formData,
                model: VALUE,
                zone: '',
                stationcode: ''
            }
        })
    }

    // Handles zone change and fills station code changes

    handleZoneChange = (e) => {
        const VALUE = e.target.value
        let stationcode = []

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                zone: VALUE
            }
        })

        this.state.allData.forEach(item => {
            if(item.ci_business_type === this.state.formData.model && item.client_short_name === this.state.formData.client && item.zone === VALUE){
                stationcode.push(item.client_hub_code)
                return stationcode
            } 
        })

        let stationcode1 = [... new Set(stationcode)]

        this.setState({
            ...this.state,
            dropdown_stationcode: stationcode1,
            formData: {
                ...this.state.formData,
                zone: VALUE,
                stationcode: ''
            }
        })
    }

    // Fills the station code and all the remaining values

    handleStationChange = (e) => {
        const VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                stationcode: VALUE
            }
        }, () => {
            this.getRemainingValues()
        })
    }

    // Handles start date change

    handleChange = (event) => {
        const NAME = event.target.name
        const VALUE = event.target.value

            this.setState({
                ...this.state,
                error: '',
                showView: false,
                formData: {
                    ...this.state.formData,
                    enddate: '',
                    [NAME]: VALUE
                }
            })
    }

    // Handles filter change

    filter = () => {
        if(this.state.formData.client && this.state.formData.startdate && this.state.formData.enddate){
            console.log("Hello")
            this.setState({
                ...this.state,
                showView: true
            }, () => console.log(this.state.showView))
        }
    }

    // Date validator

    handleEndDateChange = (e) => {
        const VALUE = e.target.value

        if(VALUE < this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'End date must be greater than start date',
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        } else if(!this.state.formData.startdate){
            this.setState({
                ...this.state,
                error: 'Please select start date first',
                formData: {
                    ...this.state.formData,
                    enddate: ''
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: '',
                formData: {
                    ...this.state.formData,
                    enddate: VALUE
                }
            }, () => this.filter())
        }
    }

    // Handles submit functionality to get the ageing values

    handleView = async () => {

        this.setState({
            ...this.state,
            fetching: true
        })

        const formData = {
            startdate: this.state.formData.startdate,
            enddate: this.state.formData.enddate,
            client: this.state.formData.client,
            model: this.state.formData.model,
            zone: this.state.formData.zone,
            stationcode: this.state.formData.stationcode
        }

        Promise.all([
            axios.post(ApiConstants.externals.serverUrl + 'api/cod/getLossAndRecoveryForAgeing', formData),
            axios.post(ApiConstants.externals.serverUrl + 'api/cod/ageingReport', formData)
        ]).then(([item, value]) => {
            let responseFromLAR = JSON.parse(item.data.data)
            console.log(responseFromLAR)
            let responseFromLambda = JSON.parse(value.data.data)
            responseFromLAR.forEach((item) => {
                responseFromLambda.forEach((value) => {
                    // console.log(value)
                    if(item.stationcode === value.StationCode && item.sourcetype === 'Recovered'){
                        value.Recovered = item.total
                        value["Amount Pending"] = value["Amount Pending"] - item.total
                    } else if (item.stationcode === value.StationCode && item.sourcetype === 'WriteOff'){
                        value["Approved For WO"] = item.total
                        value["Amount Pending"] = value["Amount Pending"] - item.total
                    }
                })
            })
            console.log(responseFromLambda)

            if(!responseFromLambda.length){               
                this.setState({
                    ...this.state,
                    fetching: false
                })
            } else {
                // let data = JSON.parse(response.data.data)
                // console.log(data) 
                this.setState({
                    ...this.state,
                    fetching: false,
                    data: responseFromLambda,
                    // ageingProfileDownload: finalData,
                    showtable: true
                })
            }
        })
    }

    // Helps in downloading the values in a specified manner

    handleProfileDownload = () => {
        const temp = []
        
        if(this.state.data.length){
            const value = this.state.data.forEach(obj => {

                if (obj['AgeingAmount (1 Days)'].length){
                    obj['AgeingAmount (1 Days)'].forEach((item, index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'AgeingDates': getHumanReadableDate(obj['AgeingDates (1 Days)'][index]),
                            'AgeingAmount': item,
                            'AgeingBucket': '1 Days'})
                    })
                    
                }

                if (obj['AgeingAmount (2 Days)'].length){
                    obj['AgeingAmount (2 Days)'].forEach((item, index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'AgeingDates': getHumanReadableDate(obj['AgeingDates (2 Days)'][index]),
                            'AgeingAmount': item,
                            'AgeingBucket': '2 Days'})
                    })
                    
                }
                
                if (obj['AgeingAmount (3 Days)'].length){
                    obj['AgeingAmount (3 Days)'].forEach((item, index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'AgeingDates': getHumanReadableDate(obj['AgeingDates (3 Days)'][index]),
                            'AgeingAmount': item,
                            'AgeingBucket': '3 Days'})
                    })
                    
                }
                if (obj['AgeingAmount (4-5 Days)'].length){
                    obj['AgeingAmount (4-5 Days)'].forEach((item, index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'AgeingDates': getHumanReadableDate(obj['AgeingDates (4-5 Days)'][index]),
                            'AgeingAmount': item,
                            'AgeingBucket': '4-5 Days'
                        })
                    })
                }
                if (obj['AgeingAmount (6-30 Days)'].length){
                    obj['AgeingAmount (6-30 Days)'].forEach((item, index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'AgeingDates': getHumanReadableDate(obj['AgeingDates (6-30 Days)'][index]),
                            'AgeingAmount': item,
                            'AgeingBucket': '6-30 Days'
                        })
                    })
                }
                if (obj['AgeingAmount (30-60 Days)'].length){
                    obj['AgeingAmount (30-60 Days)'].forEach((item,index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'AgeingDates': getHumanReadableDate(obj['AgeingDates (30-60 Days)'][index]),
                            'AgeingAmount': item,
                            'AgeingBucket': '30-60 Days'
                        })
                    })
                }
                if (obj['AgeingAmount (>60 Days)'].length){
                    obj['AgeingAmount (>60 Days)'].forEach((item,index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'AgeingDates': getHumanReadableDate(obj['AgeingDates (>60 Days)'][index]),
                            'AgeingAmount': item,
                            'AgeingBucket': '>60 Days'
                        })
                    })
                }
                if (obj['Amount Pending'].length){
                    obj['Amount Pending'].forEach((item,index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'PendingAmount': item,
                            'AgeingBucket': 'Total Amount'
                        })
                    })
                }
                if (obj['Excess Deposit'].length){
                    obj['Excess Deposit'].forEach((item,index) => {
                        temp.push({
                            'Client': obj.Client,
                            'Model': obj.Model,
                            'StationCode': obj.StationCode,
                            'Zone': obj.Zone,
                            'ExcessAmount': item,
                            'AgeingBucket': 'Excess Amount'
                        })
                    })
                }
            })
        }
        this.setState({
            ...this.state,
            ageingProfileDownload: temp
        }, () => {
            this.downloadLink1.link.click()
        })
        console.log(temp)
    }

    // Download the report as csv

    handleDownload = async () => {
        if(this.state.data.length){
            const value = this.state.data.map(obj => {
                const {'AgeingDates WO': AgeingDatesWO, 'AgeingDates Recovered': AgeingDatesRecovered, 'AgeingDates (1 Days)': AgeingDates1Days, 'AgeingDates (2 Days)': AgeingDates2Days, 'AgeingDates (3 Days)': AgeingDates3Days, 'AgeingDates (4-5 Days)': AgeingDates3to5Days, 'AgeingDates (6-30 Days)': AgeingDates6to30Days, 'AgeingDates (30-60 Days)': AgeingDates30to60Days, 'AgeingDates (>60 Days)': AgeingDates60Days, 'AgeingAmount WO': AgeingAmountWO, 'AgeingAmount Recovered': AgeingAmountRecovered, 'AgeingAmount (1 Days)': AgeingAmount1Days, 'AgeingAmount (2 Days)': AgeingAmount2Days, 'AgeingAmount (3 Days)': AgeingAmount3Days, 'AgeingAmount (4-5 Days)': AgeingAmount4to5Days, 'AgeingAmount (6-30 Days)': AgeingAmount6to30Days, 'AgeingAmount (30-60 Days)': AgeingAmount30to60Days, 'AgeingAmount (>60 Days)': AgeingAmount60Days, ...rest} = obj
            return rest
            })

            this.setState({
                ...this.state,
                downloadData: value
            }, () => {
                this.downloadLink.link.click()
            })
        } 
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            error: '',
            showView: false,
            showtable: false,
            dropdown_model: [],
            dropdown_zone: [],
            dropdown_stationcode: this.state.uniqueStationCode,
            formData: {
                client: '',
                model: '',
                zone: '',
                stationcode: '',
                startdate: '',
                enddate: ''
            }
        })
    }

    // Shows the ageing amount and value for the selected date

    handleModel = (value, amount, name) => {
        console.log(value)
        console.log(name)

        this.setState({
            ...this.state,
            showpopup: true,
            selectedField: name,
            selectedValue: value,
            selectedAmount: amount
        })
    }

    handleModalClose = () => {
        this.setState({
            ...this.state,
            showpopup: false,
            selectedField: '',
            selectedValue: [],
            selectedAmount: []
        })
    }

  render() {
    return (
      <div>
        <div className='header_div'>
            Stationwise Consolidated COD Status
        </div>

        {!this.state.fetching ?

        <div>
            <div>
                <Button size='sm' variant='danger' style={{marginLeft:10}} className='pull-right' onClick={this.handleReset}>Reset Filter</Button>
            </div> 

            <div>
                <Button size='sm' variant='success' className='pull-right' onClick={this.handleDownload} disabled={!this.state.showView}>Download Consolidated Report</Button>
                <CSVLink data={this.state.downloadData} filename='Ageing_Report.csv' ref={(r)=>this.downloadLink =r} target='_blank'/>
            </div>
        </div>
         : null }

        <br/>
        <br/>

        {!this.state.fetching ?

        <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Client</Form.Label>
                        <select className='spacing' value={this.state.formData.client} name='client' onChange={this.handleClientChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_client.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Model</Form.Label>
                        <select className='spacing' value={this.state.formData.model} name='model' onChange={this.handleModelChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_model.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Zone</Form.Label>
                        <select className='spacing' value={this.state.formData.zone} name='zone' onChange={this.handleZoneChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_zone.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Station Code</Form.Label>
                        <select className='spacing' value={this.state.formData.stationcode} name='stationcode' onChange={this.handleStationChange}>
                            <option value='' disabled>Select</option>
                            {this.state.dropdown_stationcode.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <input type='date' className='spacing' value={this.state.formData.startdate} name='startdate' onChange={this.handleChange}></input>
                </Form.Group>
            </div>

            <div className='col-md-3'>
                <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <input type='date' className='spacing' value={this.state.formData.enddate} name='enddate' onChange={this.handleEndDateChange}></input>
                </Form.Group>
                {this.state.error && <div style={{color: 'red'}}>{this.state.error}</div>}
            </div>
        </div>
        : null }

        <br/>

        {!this.state.fetching ?

        <div className='wrapper'>
            <Button variant='warning' onClick={this.handleView} disabled={!this.state.showView}>View</Button>
        </div> : null }

        <br/>
        <br/>

        {/* {!this.state.fetching && this.state.showtable ?
        <div>
            <Button size='sm' variant='success' className='pull-right' onClick={this.handleAgeingProfileDownload}>Download Ageing Profile</Button>
            <CSVLink data={this.state.ageingProfileDownload} filename='AgeingProfile.csv' ref={(r) => this.downloadLink1 = r} target='_blank'/>
        </div> : null} */}

        {!this.state.fetching && this.state.showtable? 
        <div>
            <Button variant='success' size='sm' className='pull-right' onClick={this.handleProfileDownload}>Download Ageing Profile</Button>
            <CSVLink data={this.state.ageingProfileDownload.length ? this.state.ageingProfileDownload : [] } filename='AgeingProfile.csv' ref={(r) => this.downloadLink1 = r} target='_blank'/>
        </div> : null}

        <br/>
        <br/>

        {!this.state.fetching && this.state.showtable ? 
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Client</th>
                        <th>Business Model</th>
                        <th>Zone</th>
                        <th>Station Code</th>
                        <th>Amount From Previous Period</th>
                        <th>Amount To Be Collected</th>
                        <th>Amount Deposited</th>
                        <th>Recovered Amount</th>
                        <th>Approved For WriteOff</th>
                        <th>Amount Pending</th>
                        <th>Ageing (1 Day)</th>
                        <th>Ageing (2 Day)</th>
                        <th>Ageing (3 Day)</th>
                        <th>Ageing (4-5 Days)</th>
                        <th>Ageing (6-30 Days)</th>
                        <th>Ageing (31-60 Days)</th>
                        <th>Ageing (Above 60 Days)</th>
                        <th>Excess Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.data.sort((a,b)=>a.Client.localeCompare(b.Client)).map((item, index)=> {
                        return(
                            <tr key={index}>
                                <td>{item.Client}</td>
                                <td>{item.Model}</td>
                                <td>{item.Zone}</td>
                                <td>{item.StationCode}</td>
                                <td>{convertToINR(item["Amount From Previous Period"])}</td>
                                <td>{convertToINR(item["Amount To Be Collected"])}</td>
                                <td>{convertToINR(item["Amount Deposited"])}</td>
                                <td>{convertToINR(item["Recovered"] *-1)}</td>
                                <td>{convertToINR(item["Approved For WO"] *-1)}</td>
                                {/* <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates Recovered'], item['AgeingAmount Recovered'], "Recovered Date")}>{convertToINR(item["Recovered"])}</Button></td> */}
                                {/* <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates WO'], item['AgeingAmount WO'], "WriteOff Date")}>{convertToINR(item["Approved For WO"])}</Button></td> */}
                                <td>{convertToINR(item["Amount Pending"])}</td>
                                <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates (1 Days)'], item['AgeingAmount (1 Days)'], "Ageing Date (1 Days)")}>{convertToINR(item["Ageing (1 Days)"])}</Button></td>
                                <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates (2 Days)'], item['AgeingAmount (2 Days)'], "Ageing Date (2 Days)")}>{convertToINR(item["Ageing (2 Days)"])}</Button></td>
                                <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates (3 Days)'], item['AgeingAmount (3 Days)'], "Ageing Date (3 Days)")}>{convertToINR(item["Ageing (3 Days)"])}</Button></td>
                                <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates (4-5 Days)'], item['AgeingAmount (4-5 Days)'], "Ageing Date (4-5 Days)")}>{convertToINR(item["Ageing (4-5 Days)"])}</Button></td>
                                <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates (6-30 Days)'], item['AgeingAmount (6-30 Days)'], "Ageing Date (6-30 Days)")}>{convertToINR(item["Ageing (6-30 Days)"])}</Button></td>
                                <td><Button size='sm' variant='link' onClick={() => this.handleModel(item['AgeingDates (30-60 Days)'], item['AgeingAmount (30-60 Days)'], "Ageing Date (30-60 Days)")}>{convertToINR(item["Ageing (30-60 Days)"])}</Button></td>
                                <td><Button size='sm' variant='link' onClick={() => this.handleModel(item["AgeingDates (>60 Days)"], item["AgeingAmount (>60 Days)"], "Ageing Date (>60 Days)")}>{convertToINR(item["Ageing (>60 Days)"])}</Button></td>
                                <td>{convertToINR(item["Excess Deposit"])}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>: this.state.fetching ? <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
            </div>: null}

            {this.state.showpopup ? 
            <div>
                <Modal show={true} onHide={false} backdrop='static'>
                    <Modal.Header>
                        <Modal.Title style={{fontSize:'1rem'}}>{this.state.selectedField}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {/* {this.state.selectedValue.length ? this.state.selectedValue.map(item => <ul><li>{getHumanReadableDate(item)}</li></ul>) : ('No Records To Display')} */}
                        {this.state.selectedValue.length ? (
                            <ul>
                                {this.state.selectedValue.map((item, index) => (
                                <li key={index}>
                                    {getHumanReadableDate(item)}
                                    {this.state.selectedAmount[index] && (
                                    <span> - {convertToINR(this.state.selectedAmount[index])}</span>
                                    )}
                                </li>
                                ))}
                            </ul>
                            ) : (
                            'No Records To Display'
                            )}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button size='sm' variant='danger' onClick={()=> this.handleModalClose()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div> : null}
      </div>
    )
  }
}

export default AgeingReport