import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import GetAppIcon from '@material-ui/icons/GetApp';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import { fetchTreeData, handleTreeChange, handleChangeTreeMove,addTreeField } from '../actions/treeAction'
import { openPopupDialog, closePopupDialog } from '../actions/popupAction'
import SortableTree, { addNodeUnderParent, changeNodeAtPath } from 'react-sortable-tree';
import Mypopup from './myPopup';
import ClearIcon from '@material-ui/icons/Clear';
import 'react-sortable-tree/style.css';
// import store from '../store'

// const state = store.getState();
// const userData = state.login.userData;


class TreeContainer extends Component {
    constructor(props) {
        super(props);
        this.userData = this.props.login.userData;
    }
    maxDepth = 4;
    componentDidMount() {
        console.log(this.userData);
        this.props.fetchTreeData(this.userData.tenant_id)
    }
    componentDidUpdate(nextProps, nextState) {
    }


    render() {
        if (this.props.treeData) {
            return (<div style={{ height: '100%' }}>
                <SortableTree
                    treeData={this.props.treeData}
                    onChange={this.handleTreeOnChange}
                    rowHeight={40}
                    onMoveNode={this.handleChangeTreeOnMove}
                    maxDepth={this.maxDepth}
                    canDrop={this.handleTreeOnDrop}
                    generateNodeProps={rowInfo => ({
                        buttons: [
                        <button className="tree-buttons" onClick={() => this.onDivisonView(rowInfo)}>{rowInfo.node.type === 'divison' ? rowInfo.node.divisonView === "v" ?<span><ViewAgendaIcon fontSize="small" /></span> : <span><ViewAgendaIcon  className="rowViewIcon" fontSize="small"/></span>:null}  </button>,
                        // className="rowViewIcon"
                        <button className="tree-buttons" onClick={ () => this.toggleStatus(rowInfo) }> <span> 
                                {rowInfo.node.enable ? <CheckCircleIcon style={{ color : 'green' }} fontSize="small" /> : <ClearIcon style={{ color : 'red' }} fontSize="small" />}
                            
                            {/* <FontAwesomeIcon style={{ color : rowInfo.node.enable ? 'green' : 'red' }} icon={ rowInfo.node.enable ? faCheckCircle : faTimesCircle }  /> */}
                            
                             </span></button>,

                            <button className="tree-buttons" onClick={ () => this.toggleDownload(rowInfo) }> 
                                <span> 
                                 { rowInfo.node.type == 'section' && rowInfo.node.view == "table" ?
                                     rowInfo.node.downloadable ? <GetAppIcon style={{ color : 'green' }} fontSize="small" /> : <OfflinePinIcon style={{ color : 'red' }} fontSize="small" />
                                     : null }
                                </span>
                             </button>,
                        
                            <button className="tree-buttons" onClick={() => this.OnTableHeaderReorder(rowInfo)} >  {rowInfo.node.type == 'section' && rowInfo.node.view == "table" ? rowInfo.node.headerView == 'v' ? <span><VerticalAlignCenterIcon  fontSize="small" /></span> : <span><VerticalAlignCenterIcon className="rowViewIcon" fontSize="small" /></span> : null}</button>,
                            <button
                                className="tree-buttons"
                                onClick={() => this.OnAddGroup(rowInfo)}
                            >

                                {rowInfo.node.type == 'group' ? <span><CreateIcon fontSize="small" /></span> : rowInfo.node.children === undefined ? <span><CreateIcon fontSize="small" /></span> : <span><AddIcon  fontSize="small"/></span>}

                            </button>

                        ],
                    })}
                />
                {this.props.popVisible ?
                    <Mypopup
                        text='Close Me'
                        show={this.props.popVisible}
                        hide={this.closePopup}
                        data={this.props.currentNodeData}
                        getData={this.getPopDataFunction}
                        closePopup={this.closePopup}
                    />
                    : null
                }
            </div>)
        } else {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
                <h6> Please Wait... </h6> 
                <Spinner animation="grow" variant="text-navy"  /> 
            </div>
           )
        }

    }
    handleTreeOnChange = treeData => {
        this.props.handleTreeChange(treeData)
    };
    handleChangeTreeOnMove = node => {
        let treeData = node.treeData
        for (var i = 0; i < treeData.length; i++) {
            if(treeData[i].children)
            {
                for (var j = 0; j < treeData[i].children.length; j++) {
                    if (treeData[i].children[j].type == 'field') {
                        if (treeData[i].children[j].children) {
                            treeData[i].children[j].type = 'group'
                        }
                    }
                }
            }
        }

        this.props.handleChangeTreeMove(treeData)
    }
    handleTreeOnDrop = node => {
        // console.log(node)
        if(node.nextParent)
        {
            if (node.nextParent.type == 'field') {
                return false
            } else {
                return true
            }
        }
        else{
            return true
        }
    }
    OnTableHeaderReorder = ({ node, path, treeIndex }) => {
        node.headerView = node.headerView == 'h' ? 'v' : 'h';       
         let newTreeData = changeNodeAtPath({
                treeData: this.props.treeData,
                path,
                getNodeKey: ({ treeIndex }) => treeIndex,
                newNode: node,
            })
            this.props.handleTreeChange(newTreeData)
     
    }
    onDivisonView = ({ node, path, treeIndex })=>{
        node.divisonView = node.divisonView == 'v' ? 'h' : 'v';       
        let newTreeData = changeNodeAtPath({
               treeData: this.props.treeData,
               path,
               getNodeKey: ({ treeIndex }) => treeIndex,
               newNode: node,
           })         
           this.props.handleTreeChange(newTreeData)
    }

    
    toggleStatus = ({ node, path, treeIndex })=>{
        console.log("toggle Status : ", node, path, treeIndex)
        if(node.children)
        {
            node.children.forEach(function(child){
                child.enable = !node.enable

                if(child.children)
                {
                    child.children.forEach(function(grandChild){
                        grandChild.enable = !node.enable
                    })
                }
            })
            
        }
        node.enable = !node.enable
        let newTreeData = changeNodeAtPath({
            treeData: this.props.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: node,
        })         
        this.props.handleTreeChange(newTreeData)
    }




    toggleDownload = ({ node, path, treeIndex })=>{
        console.log("toggle Status : ", node, path, treeIndex)
        if(node.children)
        {
            node.children.forEach(function(child){
                child.downloadable = !node.downloadable

                if(child.children)
                {
                    child.children.forEach(function(grandChild){
                        grandChild.downloadable = !node.downloadable
                    })
                }
            })
            
        }
        node.downloadable = !node.downloadable
        let newTreeData = changeNodeAtPath({
            treeData: this.props.treeData,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: node,
        })         
        this.props.handleTreeChange(newTreeData)
    }


    OnAddGroup = ({ node, path, treeIndex }) => {
        let popupData = {
            popVisible: true,
            currentNodeData: { node, path, treeIndex }
        }
        this.props.openPopupDialog(popupData);
 
    }
    closePopup = () => {
        this.props.closePopupDialog({
            popVisible: false,
        })
    }
    getPopDataFunction = (popupData, node) => {
        let NEW_NODE = { 'title': popupData.nodeName ? popupData.nodeName : popupData.fieldName, 'type': popupData.nodeType, 'position': popupData.position, 'enable': popupData.enable }
        // console.log(this.props.treeData)
        if (popupData.position === '' || popupData.position === undefined) {
            let newTree = addNodeUnderParent({
                treeData: this.props.treeData,
                newNode: NEW_NODE,
                expandParent: true,
                parentKey: node.treeIndex,
                getNodeKey: ({ treeIndex }) => treeIndex,
            });
        console.log("On Submission Tree Container : ", newTree.treeData)
          this.props.addTreeField(newTree.treeData)     
          
        } else {
          node.node.position = popupData.position;
          let newTreeData = changeNodeAtPath({
            treeData: this.props.treeData,
            path: node.path,
            getNodeKey: ({ treeIndex }) => treeIndex,
            newNode: node.node,
        })
       
        this.props.handleTreeChange(newTreeData)          
         
        }
           this.closePopup();
      }
}

function mapStateToProps(state) {
    return {
        login : state.login,
        treeData: state.treeReducer.treeData,
        popVisible: state.popupReducer.popVisible,
        currentNodeData: state.popupReducer.currentNodeData,
    }
}




export default connect(
    mapStateToProps,
    { fetchTreeData, handleTreeChange, handleChangeTreeMove, openPopupDialog, closePopupDialog,addTreeField }
)(TreeContainer);

