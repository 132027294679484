import React, { useEffect, useState } from 'react';
// import PATTERNJSON from './pat.json';
import { IoIosArrowDown, IoIosArrowUp, IoMdAddCircleOutline } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";





const Pattern = (props) => { 
  
  // const opts = ["PartyName",	"VendorID",	"AccountingScheme",	"AddlText1",	"AddlRef1",	"Parameter1",	"GroupTransactionRef",	"TransactionRef",	"ItemID"]
  const opts1 = ["GroupTransactionRef", "Reference1", "TransactionRef", "AddlText1", "AddlRef1", "Parameter1", "PartyName" ]
  const opts2 = ["AccountingSchemeCode", "ItemID", "VendorID", "BankAcClass" ]

  const AccountingSchemeCode = {
    CreditCard : ["D(IGST)C(CC)", "D(EI)C(CC)", "D(VE)C(CC)", "D(CA)C(CC)"],
    PettyCash : ["D(EI)C(PC)", "D(CA)C(PC)", "D(VE)C(PC)"],
    DigitalWalletAccounting : ["D(EI)C(DW)", "D(VE)C(DW)", "D(DW)C(BK-OUT)", "D(CA)C(DW)"],
    EmployeeReimbursement : ["D(EI)C(ER)", "D(CA)C(ER)"]
  }
  

  // const [PATTERN_JSON, setPATTERN_JSON] = useState(PATTERNJSON)
  const [PATTERN_JSON, setPATTERN_JSON] = useState(props.PATTERN_JSON)
  
  const [tempPatName, setTempPatName] = useState("")
  const [CUR_PAT_NAME, setCUR_PAT_NAME] = useState("")
  const [switch_Pattern, setSwitch_Pattern] = useState(false)
  
  const [CURRENT_PATTERN, setCURRENT_PATTERN] = useState(null)

  const [ saveBtn, setSaveBtn ] = useState(false)

  const [ AccSchemeCds, set_AccSchemeCds] = useState([])

  const [ ItemIdName, set_ItemIdName] = useState("")
  const [ VendorIdName, set_VendorIdName] = useState("")

  const [ errMsg, setErrMsg ] = useState("")


  useEffect( () => {
   
    console.log(props.ROW, props.PATTERN_JSON )

    if(props.ROW["PatternID"]){

      onSwitchPat(props.ROW["PatternID"])
    }
    else{
      onSwitchPat("new")
      setSwitch_Pattern(true)
    }

    set_AccSchemeCds(AccountingSchemeCode[props.LedgerType])

  },[])


  const onSwitchPat = (val) => {

    console.log(PATTERN_JSON, val)

    if(val === "new"){
      setCUR_PAT_NAME(val)
      setSaveBtn(false)
      setCURRENT_PATTERN(null)
    }
    else{
      setCUR_PAT_NAME(val)
      setSaveBtn(false)

      let currr = PATTERN_JSON[val]

      currr && currr.TextPatterns.forEach( (each, indx) => {       

        try {
          const regex1 = new RegExp(each.Prefix + each.Extract + each.Suffix);
          const ans = props.ROW["ItemDescription"].match(regex1);

          console.log(ans)
        
          if (ans && ans[1]) {
            currr.TextPatterns[indx]["ExtractedValue"] = ans[1]
          } else {
            currr.TextPatterns[indx]["ExtractedValue"] = "No match"
          }
        } 
        catch (error) {
          currr.TextPatterns[indx]["ExtractedValue"] = "Invalid re expression"
        }
        
      })

      setCURRENT_PATTERN(currr)

    }

  }


  const setCurPat = (current_Pat, save_Btn ) => {
    
    setSaveBtn(true)
    setCURRENT_PATTERN(current_Pat)
  }


  const editPAT = (e, TorV) => {

    let key = e.target.name
    let val = e.target.value
    let current_Pat = JSON.parse(JSON.stringify(CURRENT_PATTERN))

    if(key === "Extract") handleMatchClick(e)

    else if(key === "Type"){
  
      current_Pat[key] = val
      setCurPat(current_Pat)
    }
    else{

    
      let indx = e.target.id
  
      if(key === "Key"){
        let obj = {
          [val] : ""
        }
  
        current_Pat[TorV][indx] = obj

      }
      else if(key === "ItemID"){

        const selectedName = e.target.options[e.target.selectedIndex].getAttribute("sendName");

        console.log(selectedName)
        set_ItemIdName(selectedName)

        current_Pat[TorV][indx][key] = val

      }
      else if(key === "VendorID"){

        const selectedName = e.target.options[e.target.selectedIndex].getAttribute("sendName");

        console.log(selectedName)
        set_VendorIdName(selectedName)

        current_Pat[TorV][indx][key] = val

      }
      else{

        if(val !== "GroupTransactionRef"){

          current_Pat[TorV][indx][key] = val
        }
      }
  
      setCurPat(current_Pat)
    }
  }
  

  const handleMatchClick = (e) => {

    let indx = e.target.id
    let key = e.target.name
    let val = e.target.value

    const prefix = props.LedgerType === "CreditCard" ? "(" : "(.*?"
    const suffix = props.LedgerType === "CreditCard" ? ")" : ".*?)"

      val = prefix + val + suffix

    const lengthCheck = props.LedgerType === "CreditCard" ? 2 : 8

    if( val.startsWith(prefix) && val.endsWith(suffix) && val.length >= lengthCheck ) {
      
      let current_Pat = JSON.parse(JSON.stringify(CURRENT_PATTERN))
      console.log(current_Pat)
      current_Pat.TextPatterns[indx][key] = val
  
      try {
  
        let each = current_Pat.TextPatterns[indx]
        const regex = new RegExp(each.Prefix + each.Extract + each.Suffix);
        const match = props.ROW["ItemDescription"].match(regex);
  
        console.log(match)
  
        if (match && match[1]) {
          current_Pat.TextPatterns[indx]["ExtractedValue"] = match[1]
          setCurPat(current_Pat)
        } else {
          current_Pat.TextPatterns[indx]["ExtractedValue"] = "No match"
          setCurPat(current_Pat)
        }
      } catch (error) {
        current_Pat.TextPatterns[indx]["ExtractedValue"] = "Invalid re expression"
        setCurPat(current_Pat)
      }
    }

  }

  const addRow = (TorV, indx) => {

    let newObj;
    
    if( TorV === "TextPatterns" ){
      newObj = {
        "FieldName": "",
        "Prefix": "",
        "Extract": props.LedgerType === "CreditCard" ? "()" : "(.*?.*?)",
        "Suffix": ""
      }
    }
    else {
      newObj = {
        "" : ""
      }
    }

    let current_Pat = JSON.parse(JSON.stringify(CURRENT_PATTERN))

    // Insert the new object at the specified index
    current_Pat[TorV].splice(indx + 1, 0, newObj);

    setCurPat(current_Pat)
  }

  const delRow = (TorV, indx) => {

    let current_Pat = JSON.parse(JSON.stringify(CURRENT_PATTERN))

    // Insert the new object at the specified index
    current_Pat[TorV].splice(indx, 1);

    setCurPat(current_Pat)
  }

 
  const CreateNewPat = () => {

    const newPat = {
      "Sample": "",
      "Type": "",
      "TextPatterns": [{
        "FieldName": "",
        "Prefix": "",
        "Extract": props.LedgerType === "CreditCard" ? "()" : "(.*?.*?)",
        "Suffix": ""
      }],
      "ValueAssignments": [{
        "": ""
      }]
    }

    if( Object.keys(PATTERN_JSON).includes(tempPatName) ){

      setErrMsg("Pattern Name Already Exists")

      setTimeout( () => setErrMsg(""), 3000)
    }
    else{

      let PATTERN_JSON_ = JSON.parse(JSON.stringify(PATTERN_JSON))
      PATTERN_JSON_[tempPatName] = newPat
  
      setCUR_PAT_NAME(tempPatName)
      setPATTERN_JSON( PATTERN_JSON_ )
      setCurPat(PATTERN_JSON_[tempPatName])
      setTempPatName("")
    }

  }


  const saveModifiedPat = () => {

    setErrMsg("")

    let PATTERN_JSON_ = JSON.parse(JSON.stringify(PATTERN_JSON))

    PATTERN_JSON_[CUR_PAT_NAME] = JSON.parse(JSON.stringify(CURRENT_PATTERN))

    console.log(PATTERN_JSON_)
    
    setPATTERN_JSON( PATTERN_JSON_ )

    let tosend = {
      "PatternID" : CUR_PAT_NAME,
      "ItemID" : "",
      "ItemName" : ItemIdName,
      "VendorID" : "",
      "VendorName" : VendorIdName
    }

    PATTERN_JSON_[CUR_PAT_NAME].ValueAssignments.forEach( one => {

        let keys = Object.keys(one)

        tosend[keys[0]] = one[keys[0]]
    })

    console.log(tosend)

    if( !PATTERN_JSON_[CUR_PAT_NAME].Type ){
      
      setErrMsg(`Please provide "match" or "search"`)
    }
    else if(tosend.AccountingSchemeCode){

      if( ["D(IGST)C(CC)", "D(CA)C(CC)", "D(CA)C(PC)", "D(CA)C(DW)", "D(CA)C(ER)"].includes(tosend.AccountingSchemeCode) ){
        setSaveBtn(false)
        props.on_Save(PATTERN_JSON_, tosend)
      }
      else if( ["D(EI)C(CC)", "D(EI)C(PC)", "D(EI)C(DW)", "D(EI)C(ER)"].includes(tosend.AccountingSchemeCode) ){

        if(tosend["ItemID"]){
          setSaveBtn(false)
          props.on_Save(PATTERN_JSON_, tosend)
        }
        else{
          setErrMsg("Please provide ItemID")
        }
  
      }
      else if( ["D(VE)C(CC)", "D(VE)C(DW)", "D(VE)C(PC)"].includes(tosend.AccountingSchemeCode) ){

        if(tosend["VendorID"]){
          setSaveBtn(false)
          props.on_Save(PATTERN_JSON_, tosend)
        }
        else{
          setErrMsg("Please provide VendorID")
        }
      }
      else if( ["D(DW)C(BK-OUT)"].includes(tosend.AccountingSchemeCode) ){

        if(tosend["BankAcClass"]){
          setSaveBtn(false)
          props.on_Save(PATTERN_JSON_, tosend)
        }
        else{
          setErrMsg("Please provide BankAcClass")
        }
      }
    }
    else {

      setErrMsg("Please provide AccountingSchemeCode")
    }
    
  }


  // Function to move a key up
  function moveKeyUp() {
    let key = CUR_PAT_NAME
    let obj = JSON.parse(JSON.stringify(PATTERN_JSON))
    const keys = Object.keys(obj);
    const index = keys.indexOf(key);

    if (index > 0) {
        const temp = keys[index - 1];
        keys[index - 1] = key;
        keys[index] = temp;
    }

    const newObj = {};
    keys.forEach(k => {
        newObj[k] = obj[k];
    });

    setPATTERN_JSON( newObj )
  }

  // Function to move a key down
  function moveKeyDown() {
    let key = CUR_PAT_NAME
    let obj = JSON.parse(JSON.stringify(PATTERN_JSON))
    const keys = Object.keys(obj);
    const index = keys.indexOf(key);

    if (index < keys.length - 1) {
        const temp = keys[index + 1];
        keys[index + 1] = key;
        keys[index] = temp;
    }

    const newObj = {};
    keys.forEach(k => {
        newObj[k] = obj[k];
    });

    setPATTERN_JSON( newObj )
  }



  
  return (

    <>
                <div>
                  {
                    PATTERN_JSON && 
                    <div className='row mb-4 mt-4'>
                    <div className='col-md-3'>Pattern ID:
                          <select id="list" name="list" value={CUR_PAT_NAME} disabled={!switch_Pattern} onChange={(e)=>onSwitchPat(e.target.value)}>
                            <option value=""></option>
                            <option value="new">&lt;&lt; create new &gt;&gt;</option>
                            {Object.keys(PATTERN_JSON).map( each => (
                              <option value={each}>{each}</option>
                            ))}
                          </select>
                    </div>

                    <div className='col-md-2'>
                      <button className='btn btn-primary' onClick={()=>onSwitchPat("new")}>Create New</button>
                    </div>

                    {
                      CUR_PAT_NAME && CUR_PAT_NAME !== "new" &&
                      <div className='col-md-3'>
                        <button disabled className='btn' onClick={moveKeyUp}><IoIosArrowUp /></button>
                        {(Object.keys(PATTERN_JSON).indexOf(CUR_PAT_NAME) + 1)}/{Object.keys(PATTERN_JSON).length}
                        <button disabled className='btn' onClick={moveKeyDown}><IoIosArrowDown /></button>
                      </div>
                    } 

                  </div>
                  }
                  

                  {
                    CUR_PAT_NAME === "new" &&

                    <div className=''>Pattern ID:
                      <input id='newPat' type='text' placeholder='Enter Patter Name' value={tempPatName} onChange={(e) => setTempPatName(e.target.value)}></input>
                      <button className='btn btn-warning ml-3' onClick={CreateNewPat} disabled={!tempPatName}>Create</button>
                      <span style={{color: 'red'}}>{errMsg}</span>
                    </div>
                  } 


                  {
                    CURRENT_PATTERN &&

                  <>
                  
                  <div className='row mb-4 mt-4'>
                    <div className='col-md-8'>Item Description:
                        <input id='targetExpression' type='text' value={props.ROW["ItemDescription"]} disabled style={{ width : '80%'}}></input>
                    </div>
                    <div className='col-md-4'>Type:
                        <select id='Type' name="Type" value={CURRENT_PATTERN.Type} onChange={(e) => editPAT(e, "TextPatterns" )}>
                          <option value="" disabled></option>
                          <option value="match">match</option>
                          <option value="search">search</option>
                        </select>
                    </div>
                  </div>


                  <table id="table-css">
                    <thead>
                      <tr>
                          <th> </th>
                          <th> Type </th>
                          <th> Field Name </th>
                          <th> Prefix Exp </th>
                          <th> Extract Exp </th>
                          <th> Suffix Exp </th>
                          <th> Extracted Value </th>
                          <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {CURRENT_PATTERN.TextPatterns.map((item, rowIndex) => (
                        <tr key={rowIndex}>                  
                            <td><button className='btn' style={{border: 'none'}} onClick={() => addRow("TextPatterns", rowIndex)}><IoMdAddCircleOutline /></button></td>
                            <td>TextPatterns</td>
                            <td>
                              <select id={rowIndex} name="FieldName" value={item.FieldName} onChange={(e) => editPAT(e, "TextPatterns" )}>
                                <option value=""></option>
                                  {opts1.map( each => {

                                    if(props.LedgerType === "CreditCard"){
                                      return (
                                        <option value={each}>{each}</option>
                                      )
                                    }
                                    else if( each === "PartyName"){
                                      return (
                                        <option value={each}>{each}</option>
                                      )
                                    }
                                  }
                                )}
                              </select>
                            </td>
                            <td><input id={rowIndex} name="Prefix" value={item.Prefix} onChange={(e) => editPAT(e, "TextPatterns" )} disabled={props.LedgerType !== "CreditCard"}/></td>
                            <td>
                            <input id={rowIndex} name="Extract" 
                              value={props.LedgerType === "CreditCard" ? item.Extract.replace("(", "").replace(")", "") : item.Extract.replace("(.*?", "").replace(".*?)", "")} 
                              onChange={(e) => editPAT(e, "TextPatterns" )}/>
                            </td>
                            <td><input id={rowIndex} name="Suffix" value={item.Suffix} onChange={(e) => editPAT(e, "TextPatterns" )} disabled={props.LedgerType !== "CreditCard"}/></td>
                            <td>{item.ExtractedValue}</td>
                            <td><button className='btn' style={{border: 'none'}} onClick={() => rowIndex !== 0 && delRow("TextPatterns", rowIndex)}><MdDeleteForever /></button></td>
                        </tr>
                      ))}
                      {CURRENT_PATTERN.ValueAssignments.map((item, rowIndex) => (
                        <tr key={rowIndex}>                  
                            <td><button className='btn' style={{border: 'none'}} onClick={() => addRow("ValueAssignments", rowIndex)}><IoMdAddCircleOutline /></button></td>
                            <td>ValueAssignments</td>
                            <td>
                              <select id={rowIndex} name="Key" value={ Object.keys(item)[0] } onChange={(e) => editPAT(e, "ValueAssignments" )}>
                                <option value=""></option>
                                {opts2.map( each => (
                                  <option value={each}>{each}</option>
                                ))}
                              </select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <select id={rowIndex} name={Object.keys(item)[0]} value={item[Object.keys(item)[0]]} onChange={(e) => editPAT(e, "ValueAssignments")} >
                                  <option value=""></option>
                                  {
                                    Object.keys(item)[0] === "AccountingSchemeCode" ?
                                    <>
                                    {AccSchemeCds.map( each => (
                                      <option value={each}>{each}</option>
                                    ))}
                                    </>
                                    :
                                    Object.keys(item)[0] === "ItemID" ?
                                    <>
                                    {props.IM.map( each => (
                                      <option value={each["ItemID"]} sendName={each["ItemName"]}>{`${each["ItemName"]}(${each["ItemID"]})` }</option>
                                    ))}
                                    </>
                                    :
                                    Object.keys(item)[0] === "VendorID" ?
                                    <>
                                    {props.VM.map( each => (
                                      <option value={each["VendorID"]} sendName={each["VendorName"]}>{`${each["VendorName"]}(${each["VendorID"]})`}</option>
                                    ))}
                                    </>
                                    :
                                    Object.keys(item)[0] === "BankAcClass" ?
                                    <>
                                    {props.BankAcClass.map( each => (
                                      <option value={each["BankAcClass"]} sendName={each["BankAcClass"]}>{each["BankAcClass"]}</option>
                                    ))}
                                    </>
                                    :
                                    null

                                  }
                              </select>
                            </td>
                            <td><button className='btn' style={{border: 'none'}} onClick={() => rowIndex !== 0 && delRow("ValueAssignments", rowIndex)}><MdDeleteForever /></button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* <div className='row'> */}
                    
                  <div align='right'>
                    <button className={`mt-3 btn ${!saveBtn ? 'btn-secondary' : 'btn-primary'}`} disabled={!saveBtn} onClick={saveModifiedPat}>Save</button>< br/>
                    <span style={{color: 'red'}}>{errMsg}</span>
                  </div>
                  {/* </div> */}

                  </>
                  
                  }
                </div>
    </> 

  );
};

export default Pattern;