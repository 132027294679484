import React, { useState, useEffect } from 'react';
import '../CI/COD.css';
import { getRequest, postRequest } from '../../globalhelper/helper';
import { Button, Form } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Input = ({label, type, name, value, onChange}) => {
    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <input className='spacing' type={type} name={name} value={value} onChange={onChange}></input>
            </Form.Group>
        </div>
    )
}

const InstructorCostAccrualReport = () => {

    const [formData, setFormData] = useState({
        fromDate: "",
        toDate: "",
        company: ""
    })

    const [companyDetails, setCompanyDetails] = useState([])

    const [companyName, setCompanyName] = useState([])

    const [loading, setLoading] = useState(false)

    const [queryResult, setQueryResult] = useState([])

    // Function to get company details on screen loading

    useEffect(() => {
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            setCompanyDetails(responseData)
            setCompanyName(orgname)
        })
    }, [])

    // Function to handle Input change

    const handleChange = (e) => {
        const {name, value} = e.target

        setFormData((prevState) => {
            return {...prevState, [name]: value}
        })
    }

    const handleSubmit = () => {

        const {company, fromDate, toDate} = formData

        if(company && fromDate && toDate){
            setLoading(true)

            const id = companyDetails.find(item => item.orgname === company)
            const companyid = id.orgid

            const request = {
                fromDate,
                toDate,
                companyid
            }

            postRequest(`api/edureka/getInstructorCostAccrualReport`, request).then(response => {
                setLoading(false)
                const data = JSON.parse(response.res.data)
                if(data.length){
                    setQueryResult(data)
                } else {
                    toast.warning("No data found, please try some other combination")
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
                toast.error("Oops something went wrong")
            })
        } else {
            toast.error("Please select all the required fields")
        }

    }

    const handleReset = () => {
        setFormData({
            fromDate: '',
            toDate: ''
        })
        setQueryResult([])
    }

  return (
    <>
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' name='company' value={formData.company} onChange={handleChange}>
                            <option value='' disabled>Select</option>
                            {companyName.sort().map((item, index) => <option value={item} key={index}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Input type="date" label="From Date" name="fromDate" value={formData.fromDate} onChange={handleChange}/>
                </div>

                <div className='col-md-3'>
                    <Input type="date" label="To Date" name="toDate" value={formData.toDate} onChange={handleChange}/>
                </div>
            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
                <Button size='sm' variant='success' onClick={handleSubmit}>Submit</Button>
                <Button size='sm' variant='danger' onClick={handleReset} style={{marginLeft:'10px'}}>Reset</Button>
                {queryResult.length ?
                <CSVLink data={queryResult} filename='Instructor Cost Accrual Report.csv' style={{marginLeft:'10px'}}>
                    <Button size='sm'>Download</Button>
                </CSVLink> : null}
            </div>
        </form>
        <ToastContainer/>

        {loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
            </div>: null}

        {!loading && queryResult.length ?
        <div>
            <table border='1' id='table-css'>
                <thead>
                    <tr>
                        <th>Batch ID</th>
                        <th>For Period From</th>
                        <th>For Period To</th>
                        <th>Opening Accrual Balance</th>
                        <th>Prd Cost Accrued</th>
                        <th>Prd Accrual Reversal</th>
                        <th>Closing Accrual Balance</th>
                        <th>Prd Expense Booked</th>
                        <th>Prd WH Tax Amount</th>
                        <th>Prd Input Tax</th>
                        <th>Prd Payable Amount</th>
                        <th>Instructor IDs</th>
                        <th>Instructor Names</th>
                        <th>Instructor Invoice List</th>
                        <th>Journals</th>
                        <th>Course ID</th>
                        <th>Batch ID</th>
                    </tr>
                </thead>
                <tbody>
                    {queryResult.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.BatchID}</td>
                                <td>{item.ReportingPrdFrom}</td>
                                <td>{item.ReportingPrdTo}</td>
                                <td>{item.OpeningAccrualBalance}</td>
                                <td>{item.PrdCostAccrued}</td>
                                <td>{item.PrdAccrualReversal}</td>
                                <td>{item.ClosingAccrualBalance}</td>
                                <td>{item.PrdExpenseBooked}</td>
                                <td>{item.PrdWHTaxAmount}</td>
                                <td>{item.PrdInputTax}</td>
                                <td>{item.PrdPayableAmount}</td>
                                <td>{item.InstructorIDs}</td>
                                <td>{item.InstructorNames}</td>
                                <td>{item.InstructorInvoiceList}</td>
                                <td>{item.Journals}</td>
                                <td>{item.CourseID}</td>
                                <td>{item.BatchIDs}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div> : null}
    </>
  )
}

export default InstructorCostAccrualReport