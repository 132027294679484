import React, { Component } from 'react';
import { Circle } from 'rc-progress';

class ProgressComponent extends Component {

    constructor(props){
        super(props);
        this.state={
            animatedPercentage:0,
        };
    }
componentDidMount(){
    const {percentage} = this.props;
    setTimeout(()=>{
        this.setState({animatedPercentage:percentage});
    })
}

  getColorForPercentage(percentage) {
    if (percentage >= 0 && percentage < 21) {
      return "#FF6347"; 
    } else if (percentage >= 21 && percentage < 51) {
      return "#FF8C00"; 
    } else if (percentage >= 51 && percentage < 80) {
      return "#FFD700"; 
       } else if (percentage >= 80 && percentage < 91) {
      return "#32CD32";   
    } else if (percentage >= 90 && percentage <= 100) {
      return "#00FF00"; 
    } else {
      return "#D3D3D3"; 
    }
  }

  render() {
    const { percentage } = this.props;
    const circleSize = 40; 
    const fontSize = circleSize * 0.30; 
        const dy = fontSize * 0.25; 
    const strokeWidth = 8; 
    const strokeColor = this.getColorForPercentage(percentage);

    
    const textStyle = {
      fontSize: `${fontSize}px`, 
      fill: strokeColor,
      fontWeight: 'bold',
      textAnchor: 'middle',
      dominantBaseline: 'middle', 
    };

    return (
      <>
                <svg style={{ width: `${circleSize}px`, height: `${circleSize}px` }}> 
                <Circle
            percent={percentage}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            strokeLinecap="round"
            trailWidth={strokeWidth}
            trailColor="#F5F5F5"
          />
          <text x="50%" y="50%" dy={`${dy}px`} style={textStyle}>{`${percentage}%`}</text>
        </svg>
      </>
    );
  }
}

export default ProgressComponent;