import { applyMiddleware, legacy_createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  hideLoader,
  initiateCommon,
  verifyLogin
} from "./actions/common_actions";
import { initiateLogin } from "./actions/login_actions";
import reducers from "./reducers";

const preloadedState = localStorage.getItem("store")
  ? JSON.parse(localStorage.getItem("store"))
  : {
      login: {
        user: {
          login: false,
        },
        alert: null,
        auth: null,
      },
      common: {
        url: null,
        loading: false,
      },
    };

const middleware = [thunk];

const store = legacy_createStore(
  reducers,
  preloadedState,
  composeWithDevTools(applyMiddleware(...middleware))
);

store.dispatch(initiateLogin(preloadedState.login));
store.dispatch(initiateCommon(preloadedState.common));
store.dispatch(hideLoader());
if (preloadedState.login.auth !== null && preloadedState.login.user.login) {
  console.log("Verifying Token...");
  setInterval(() => store.dispatch(verifyLogin()), 660000);
}

export default store;
