import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../globalhelper/helper";

const initialState = {
  BillsLoading: true, // Tracks loading state
  BillsByFetch: [], // Stores fetched bills
  error: "", // Stores error message
};

// Async thunk for fetching bills by OrgID
export const fetchBillsByOrgID = createAsyncThunk(
  "Fetch-BillsBy_OrgID",
  async ({ OrgID }, thunkAPI) => {
    try {
      const response = await getRequest(
        `api/veranda/getBillsByOrgID?OrgID=${OrgID}`
      );
      console.log(response.res.data);
      return response.res.data; // Return fetched data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); // Handle error
    }
  }
);

// Slice for managing bills state
const BillsReducers = createSlice({
  name: "BillsByOrgID",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillsByOrgID.pending, (state) => {
        state.BillsLoading = true; 
        state.error = ""; 
      })
      .addCase(fetchBillsByOrgID.fulfilled, (state, action) => {
        state.BillsLoading = false; 
        state.BillsByFetch = Array.isArray(action.payload)
          ? action.payload
          : JSON.parse(action.payload); 
      })
      .addCase(fetchBillsByOrgID.rejected, (state, action) => {
        state.BillsLoading = false; 
        state.error = action.payload || "Failed to fetch bills data."; 
      });
  },
});

// Export the reducer
export default BillsReducers.reducer;
