import React, { Component, Fragment } from 'react'
import {Button, Form} from 'react-bootstrap';
import '../CI/COD.css';
import { getRequest, convertToINR, getHumanReadableDate, getStandardDate } from '../../globalhelper/helper';
import Pagination1 from '../CI/Pagination';
import FormBasicSelect from '../CI/FormBasicSelect';
import { CSVLink } from 'react-csv';

export class FunctionalJournal extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        selectedColumn: '',
        selectedValue: '',
        show_table_detailed: false,
        show_table_summary: false,
        tableData: [],
        tableDisplay: [],
        currentPage: 1,
        postsPerPage: 20,
        StartDate: '',
        EndDate: '',
        dropdown_type: ['Detailed', 'Summary'],
        dropdown_year: ['2023', '2024'],
        companyDetails: [],
        companyName: [],
        dropdown_month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        formData: {
            Month: '',
            FromDate: '',
            ToDate: '',
            Type: '',
            year: '',
            company: ''
        },
         error: false,
         errorMessage: '',
         loading: false
      }
    }

    componentDidMount(){
        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            const responseData = response.res.data
            const orgname = responseData.map(item => item.orgname)
            this.setState({
                ...this.state,
                companyDetails: responseData,
                companyName: orgname
            })
        })
    }

    handleChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME] : VALUE
            }
        })
    }

    handleYearChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        }, () => this.handleMonthToDateConversion(this.state.formData.Month, this.state.formData.year))
    }

    handleMonthToDateConversion = (month, selectedYear) => {

        const year = selectedYear
        const monthIndex = new Date(Date.parse(month + '1,' + year)).getMonth()
        const startDate = new Date(year, monthIndex, 1)
        const endDate = new Date(year, monthIndex + 1, 0)

        this.setState({
            ...this.state,
            StartDate: getStandardDate(startDate),
            EndDate: getStandardDate(endDate),
            formData: {
                ...this.state.formData,
                FromDate: getStandardDate(startDate),
                ToDate: getStandardDate(endDate)
            }
        }, () => console.log(this.state.StartDate, this.state.EndDate))
    }

    handleSubmit = () => {
        const {FromDate, ToDate, Type, company} = this.state.formData

        const id = this.state.companyDetails.filter(item => item.orgname === company)

        const companyid = id.map(item => item.orgid)

        if(company && FromDate && ToDate && Type === 'Detailed'){

            this.setState({
                ...this.state,
                loading: true,
                error: false,
                tableData: []
            })

            getRequest(`api/edureka/getFunctionalJournalDetail?FromDate=${FromDate}&ToDate=${ToDate}&company=${companyid[0]}`).then(response => {

                const responseData = JSON.parse(response.res.data)

                if(responseData.length){
                    this.setState({
                        ...this.state,
                        tableData: responseData,
                        show_table_summary: false,
                        show_table_detailed: true,
                        loading: false,
                        currentPage: 1
                    }, () => this.handlePagination())
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        error: true,
                        errorMessage: "No data found, please try some other combination"
                    })
                }
            })
        } else if (company && FromDate && ToDate && Type === 'Summary') {

            this.setState({
                ...this.state,
                loading: true,
                error: false,
                tableData: []
            })

            getRequest(`api/edureka/getFunctionalSummary?FromDate=${FromDate}&ToDate=${ToDate}&company=${companyid[0]}`).then(response => {

                const responseData = JSON.parse(response.res.data)

                if(responseData.length){
                    this.setState({
                        ...this.state,
                        tableData: responseData,
                        show_table_detailed: false,
                        show_table_summary: true,
                        currentPage: 1
                    }, () => this.handlePagination())
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        error: true,
                        errorMessage: "No data found, please try some other combination"
                    })
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: "Please select all the required fields"
            })
        }
    }

    handlePagination = () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage
        let indexofFirstPost = indexOfLastPost - this.state.postsPerPage
        let currentPosts = this.state.tableData.slice(indexofFirstPost, indexOfLastPost)
        this.setState({
            ...this.state,
            tableDisplay: currentPosts
        })
    }

    paginate = (pageNumber) => {
        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => this.handlePagination())
    }

    handleDownload = () => {
        this.setState({
            ...this.state
        }, () => {
            this.downloadLink.link.click()
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            loading: false,
            error: false,
            formData: {
                Month: '',
                FromDate: '',
                ToDate: '', 
                Type: '',
                year: '',
                company: ''
            }
        })
    }

    handleColumnChange = (e) => {
        this.setState({selectedColumn: e.target.value, selectedValue: ''});
    }

    handleValueChange = (e) => {
        this.setState({selectedValue: e.target.value}, this.applyFilter);
    }

    applyFilter = () => {
        const {selectedColumn, selectedValue, tableData} = this.state;
        if(selectedColumn && selectedValue){
            const filterData = tableData.filter(item => item[selectedColumn] === selectedValue);
            this.setState({tableDisplay: filterData})
        }else{
            this.setState({tableDisplay: tableData})
        }
    }

  render() {

    const{formData, companyName, dropdown_month, dropdown_year, dropdown_type, tableData, tableDisplay, selectedColumn, selectedValue, show_table_detailed, show_table_summary, error, errorMessage, loading, postsPerPage} = this.state;

    const columnNames = tableData.length ? Object.keys(tableData[0]) : [];

    const columnValues = selectedColumn ? [...new Set(tableData.map(item => item[selectedColumn]))] : [];

    return (
        <Fragment>
            {/* <div style={{alignItems:'left', fontSize:'large', fontWeight:'bolder', fontFamily:'sans-serif'}}>
                Functional P&L Journal
            </div> */}

            {/* <div>
                <Button variant='success' className='pull-right' onClick={this.handleDownload}>Download</Button>
                <CSVLink data={this.state.tableData} filename={`Functional Journal ${this.state.formData.FromDate} to ${this.state.formData.ToDate}.csv`} ref={(r)=>this.downloadLink = r} target='_blank'></CSVLink>
            </div> */}

            <br/>

            <div className='row'>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Company</Form.Label>
                        <select className='spacing' name='company'  value={this.state.formData.company} onChange={this.handleChange}>
                            <option value="" disabled>Select</option>
                            {this.state.companyName.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Month</Form.Label>
                        <select className='spacing' value={this.state.formData.Month} name='Month' onChange={this.handleChange}>
                            <option value=''>Select</option>
                            {this.state.dropdown_month.map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <div className='col-md-2'>
                    <Form.Group>
                        <Form.Label>Year</Form.Label>
                        <select className='spacing' value={this.state.formData.year} name='year' onChange={this.handleYearChange}>
                            <option value=''>Select</option>
                            {this.state.dropdown_year.map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </select>
                    </Form.Group>
                </div>

                <FormBasicSelect label='Type' value={this.state.formData.Type} name='Type' onChange={this.handleChange} options={this.state.dropdown_type}></FormBasicSelect>

            </div>

            {tableDisplay.length ? 
            <>
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Filter Column</Form.Label>
                        <select className='spacing' value={selectedColumn} onChange={this.handleColumnChange}>
                            <option value=''>Select</option>
                            {columnNames.map((col, index)=> <option key={index} value={col}>{col}</option>)}
                        </select>
                    </Form.Group>
                </div>
                <div className='col-md-3'>
                    <Form.Group>
                        <Form.Label>Filter Value</Form.Label>
                        <select className='spacing' value={selectedValue} onChange={this.handleValueChange} disabled={!selectedColumn}>
                            <option value=''>Select</option>
                            {columnValues.map((val, index)=> <option key={index} value={val}>{val}</option>)}
                        </select>
                    </Form.Group>
                </div>
            </div>
            </>: null}

            <div style={{display:'flex', justifyContent:'center', marginBottom: '20px'}}>
                <Button size='sm' variant='success' onClick={this.handleSubmit}>Submit</Button>
                <Button size='sm' variant='danger' onClick={this.handleReset} style={{marginLeft:'10px'}}>Reset</Button>
                {this.state.tableData.length ?
                // <CSVLink data={this.state.tableData} filename={`Functional Journal ${this.state.formData.FromDate} to ${this.state.formData.ToDate}.csv`} ref={(r)=>this.downloadLink = r} target='_blank' style={{marginLeft:'10px'}}>Download</CSVLink> 
                <CSVLink data={tableDisplay} filename={`Functional Journal ${formData.FromDate} to ${formData.ToDate}.csv`} ref={(r)=>this.downloadLink = r} target='_blank' style={{marginLeft:'10px'}}>Download</CSVLink> 
                : null}
            </div>

            {/* <div style={{textAlign:'center'}}>
                <Button type='primary' onClick={this.handleSubmit}>Submit</Button>
            </div> */}

            <br/>

            {this.state.show_table_detailed && this.state.tableData.length ?
            <div>
                <table border='1' id='table_styling'>
                    <thead>
                        <tr>
                            <th>Document ID</th>
                            <th>Interim ID</th>
                            <th>Sender Name</th>
                            <th>Account Type</th>
                            <th>Document Type</th>
                            <th>Ledger Name</th>
                            <th>Misgrouping</th>
                            <th>Item Type</th>
                            <th>Cost Allocation Method</th>
                            <th>Total Cost</th>
                            <th>For Period From</th>
                            <th>For Period To</th>
                            <th>Document Reference</th>
                            <th>B2C</th>
                            <th>PGP</th>
                            <th>B2B</th>
                            <th>ELC</th>
                            <th>HED</th>
                            <th>VLAB</th>
                            <th>Allocation Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tableDisplay.map((item,index) => {
                            return(
                                <tr key={index}>
                                    <td>{item.createdid}</td>
                                    <td>{item.interimid}</td>
                                    <td>{item.sendername}</td>
                                    <td>{item.accounttype}</td>
                                    <td>{item.documenttype}</td>
                                    <td>{item.ledgername}</td>
                                    <td>{item.misgrouping}</td>
                                    <td>{item.itemtype}</td>
                                    <td>{item.costallocationmethod}</td>
                                    <td>{convertToINR(item.TotalCost)}</td>
                                    <td>{getHumanReadableDate(item.forprdfrom)}</td>
                                    <td>{getHumanReadableDate(item.forprdto)}</td>
                                    <td>{item.documentref}</td>
                                    <td>{item.B2C}</td>
                                    <td>{item.PGP}</td>
                                    <td>{item.B2B}</td>
                                    <td>{item.ELC}</td>
                                    <td>{item.HED}</td>
                                    <td>{item.VLAB}</td>
                                    <td>{item.AllocType}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div> : null}

            <br/>

            {this.state.show_table_summary && this.state.tableData.length ?
            <div>
                <table border='1' id='table_styling'>
                    <thead>
                        <tr>
                            <th>Sender Name</th>
                            <th>Account Type</th>
                            <th>Document Type</th>
                            <th>Ledger Name</th>
                            <th>Misgrouping</th>
                            <th>Item Type</th>
                            <th>Invoice Number</th>
                            <th>Bill Number</th>
                            <th>Accounting Document Number</th>
                            <th>Accounting Date</th>
                            <th>Allocation Type</th>
                            <th>Total Cost</th>
                            <th>B2C</th>
                            <th>PGP</th>
                            <th>B2B</th>
                            <th>ELC</th>
                            <th>HED</th>
                            <th>VLAB</th>
                            <th>Error Indicator</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.tableDisplay.map((item,index) => {
                            return(
                                <tr key={index}>
                                    <td>{item.sendername}</td>
                                    <td>{item.accounttype}</td>
                                    <td>{item.documenttype}</td>
                                    <td>{item.ledgername}</td>
                                    <td>{item.misgrouping}</td>
                                    <td>{item.itemtype}</td>
                                    <td>{item.InvoiceNumbers}</td>
                                    <td>{item.BillNumbers}</td>
                                    <td>{item.AccountingDocNumbers}</td>
                                    <td>{getHumanReadableDate(item.AccountingDates)}</td>
                                    <td>{item.AllocType}</td>
                                    <td>{convertToINR(item.TotalCost)}</td>
                                    <td>{item.B2C ? convertToINR(item.B2C) : 0}</td>
                                    <td>{item.PGP ? convertToINR(item.PGP) : 0}</td>
                                    <td>{item.B2B ? convertToINR(item.B2B) : 0}</td>
                                    <td>{item.ELC ? convertToINR(item.ELC) : 0}</td>
                                    <td>{item.HED ? convertToINR(item.HED) : 0}</td>
                                    <td>{item.VLAB ? convertToINR(item.VLAB) : 0}</td>
                                    <td>{item.ErrIndicator ? convertToINR(item.ErrIndicator) : 0}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div> : null}

            {this.state.error ?
            <div className='center-screen'>
                {this.state.errorMessage}
            </div> : null}

            {this.state.loading ? <div className='center'>
                <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
                  <div class="wave"></div>
            </div>: null}


            {/* {this.state.show_table_detailed && !this.state.tableData.length ?
            <div className='center-screen'>
                <h5>No Data Found</h5>
            </div> : null}

            {this.state.show_table_summary && !this.state.tableData.length ?
            <div className='center-screen'>
                <h5>No Data Found</h5>
            </div> : null} */}

            <br/>

            {/* {this.state.show_table_detailed || this.state.show_table_summary ? <Pagination1 postsPerPage={this.state.postsPerPage} totalPosts={this.state.tableData.length} paginate={this.paginate}></Pagination1> : null} */}
        </Fragment>
      
    )
  }
}

export default FunctionalJournal