import React, { useState, useEffect, useRef } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getRequest, postRequest } from '../../globalhelper/helper'
import { useDispatch, useSelector } from 'react-redux';
import { navUrl } from '../../actions/common_actions';
import moment from 'moment'
import { AiFillClockCircle } from 'react-icons/ai';
import { FaClock } from "react-icons/fa";
import _ from 'lodash';




const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const TransactionTypes_1 = ["CreditCard", "PettyCash", "EmployeeReimbursement", "InstructorCostAccrual", "InstructorInvoice", "EmployeeCost", "EmployeeCostAccrued", "EmployeeCostFnF", "DigitalWalletAccounting"]
const TransactionTypes_2 = ["BILL", "ERP Data"]
const DocumentTypes = ["Supplier Bill", "Transporter Bill"]

const ThemeNames = { 
    // "Instructor Dashboard" : ["Edureka_IMS", "Race_IMS"],
    "InstructorCostAccrual" : ["Edureka_InsProv"],
    "InstructorInvoice" : ["Edureka_IMS"],
    "EmployeeCost" : ["Edureka_EC"],
    // "Payment Report" : ["Edureka_Payt"],    
    // "CreditCard Statement" : ["Edureka_CC"],
    "EmployeeCostAccrued" : ["Edureka_ECA"],
    "EmployeeCostFnF" : ["Edureka_FnF"],
    "CreditCard" : ["HDFC-CC1", "Statement-CC1"],
    "PettyCash" : ["Edureka_PC"],
    "DigitalWalletAccounting" : ["VOLO-DWA1"],
    "EmployeeReimbursement" : ["Edureka_ER"],
    "ERP Data" : ["POSOV1"]
}

function Upload3() {

    const [ TransactionType, setTransactionType] = useState("")
    const [ DocumentType, setDocumentType] = useState("")
    const [ ThemeName, setThemeName] = useState("")
    const [ AllCompanies, setAllCompanies] = useState([])
    const [ Companies, setCompanies] = useState([])
    const [ Company, setCompany] = useState("")
    const [ GSTINs, setGSTINs] = useState([])
    const [ GSTIN, setGSTIN] = useState("")
    const [ From, setFrom ] = useState("")
    const [ To, setTo ] = useState("")
    const [ AllRecentHistory, setAllRecentHistory ] = useState([])
    const [ RecentHistory, setRecentHistory ] = useState([])


    const state = useSelector(state => state);
    // this.props.state.login.userData.USR_Tenant

    const [TransactionTypes , setTransactionTypes] = useState(_.sortBy(state.login.userData.USR_Tenant === "TID00003" ? TransactionTypes_2 : TransactionTypes_1 ))

    const dispatch = useDispatch();

    const navPage = (url) => {
        dispatch(navUrl(url));
    };


    useEffect(  () => {

        getRequest(`api/upload/getCompany`)
        .then( res => {

            if(res.type === "success"){
                
                let data = JSON.parse(res.res.data)

                data = _.sortBy(data, "orgname")

                console.log(data)

                const uniqueIds = new Set();
                // Use filter to remove duplicates based on the 'id' property
                const uniqueArray = data.filter(obj => {
                // Check if the ID is already in the Set
                if (!uniqueIds.has(obj.orgid)) {
                    // If not, add it to the Set and include the object in the result
                    uniqueIds.add(obj.orgid);
                    return true;
                }
                return false;
                });

                setCompanies(uniqueArray)

                setAllCompanies(data)

            }
        })


        // Get current date
        var currentDate = new Date();

        // Get first day of the month
        var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        // Get last day of the month
        var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        
        // Function to format date as "YYYY-MM-DD"
        function formatDate(date) {
            var year = date.getFullYear();
            var month = (date.getMonth() + 1).toString().padStart(2, '0');
            var day = date.getDate().toString().padStart(2, '0');
            return year + "-" + month + "-" + day;
        }
        
        // Format the dates if needed
        var formattedFirstDay = formatDate(firstDay);
        var formattedLastDay = formatDate(lastDay);

        setFrom(formattedFirstDay)
        setTo(formattedLastDay)
    }, [])

    useEffect(  () => {

        postRequest( 'api/transactions/getTransactions')
        .then(resp => {
            if(resp.type === "success"){
             
              const DATA = JSON.parse(resp.res.data)

              console.log(DATA)

              setAllRecentHistory(DATA)
              setRecentHistory(DATA)
            }
        })
    }, [])


    const updateCompany = (e) => {

        let val = e.target.value
        setCompany(val)

        if(TransactionType === "InstructorInvoice"){

            let GSTIN = []
            
            AllCompanies.map( each => {
                if(each.orgid === Company){
                    GSTIN.push(each.GSTIN)
                }
            })

            console.log(GSTIN)

            setGSTINs(GSTIN)
        }
    } 


    const updateTT = (e) => {

        let val = e.target.value
        let key = e.target.id
        setTransactionType(val)

        if(val === "InstructorInvoice"){

            let GSTIN = []
            
            AllCompanies.map( each => {
                if(each.orgid === Company){
                    GSTIN.push(each.GSTIN)
                }
            })

            console.log(GSTIN)

            setGSTINs(GSTIN)
        }
    } 


    useEffect( () => {

        if(AllRecentHistory.length){

            if(TransactionType && ThemeName){

                let nEW = AllRecentHistory.filter( each => {
    
                    if(each["TransactionType"] === TransactionType && each["ThemeName"] === ThemeName ) return each
                })
                setRecentHistory(nEW)
            }
            if(TransactionType && !ThemeName){

                let nEW = AllRecentHistory.filter( each => {
    
                    if(each["TransactionType"] === TransactionType ) return each
                })
                setRecentHistory(nEW)
            }
            if(!TransactionType && ThemeName){

                let nEW = AllRecentHistory.filter( each => {
    
                    if(each["ThemeName"] === ThemeName ) return each
                })
                setRecentHistory(nEW)
            }
            if(!TransactionType && !ThemeName){
                
                setRecentHistory(AllRecentHistory)
            }

        }
    }, [TransactionType, ThemeName])


    const updateTN = (e) => {

        let val = e.target.value
        setThemeName(val)
    } 

    const updateFrom = (e) => {

        let val = e.target.value
        console.log(val)
        setFrom(val)
    }
    
    const updateTo = (e) => {

        let val = e.target.value
        console.log(val)
        setTo(val)
    }

    const updateGSTIN = (e) => {

        let val = e.target.value
        console.log(val)
        setGSTIN(val)
    }

    const fileInputOnChange = (e) => {

        console.log(e.target)

        let file = e.target.files[0]

        console.log(`OrgID=${Company}&TransactionType=${TransactionType}&ThemeName=${ThemeName}&GSTIN=${GSTIN}&From=${From}&To=${To}`)

        if(file){

            const formData = new FormData();
            formData.append('file', file);

            postRequest(`api/upload/singleUpload?OrgID=${Company}&TransactionType=${TransactionType}&ThemeName=${ThemeName}&GSTIN=${GSTIN}&From=${From}&To=${To}&DocType=${DocumentType}`, formData)
            .then( res => {

                console.log(res)
                
                // navPage('/transactions')

            })
        }
         
    }


  return (
    <div>
        
        <div className='row mt-2'>

            {/* <div className='col-md-3'>
                <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                    <InputLabel id="c">TransactionType</InputLabel>
                    <Select labelId="TransactionType" id="TransactionType" value={ TransactionType } label="TransactionType" onChange={updateTT}>
                            { TransactionTypes.map( each => <MenuItem key={each} value={each}>{each}</MenuItem> )}
                    </Select>
                </FormControl>
            </div>

            {
                TransactionType && 
                <div className='col-md-3'>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', marginTop: 2.5 }}>
                        <InputLabel id="ThemeName">ThemeName</InputLabel>
                        <Select labelId="ThemeName" id="ThemeName" value={ ThemeName } label="ThemeName" onChange={updateTN}>
                                { ThemeNames[TransactionType].map( each => <MenuItem key={each} value={each}>{each}</MenuItem> )}
                        </Select>
                    </FormControl>
                </div>
            } */}


            <div className='col-md-2'>
                <label htmlFor="Company" style={{fontSize: '1rem'}}> Company </label>
                <select className="browser-default custom-select" id="Company" autoFocus name="Company" value={Company} onChange={updateCompany} >
                    <option value=""> select </option>
                    { Companies.map((item, key) => 
                        <option key={key} value={item.orgid}> {`${item.orgname}(${item.orgid})`} </option>
                    )}
                </select>
            </div>

            <div className='col-md-2'>
                <label htmlFor="TransactionType" style={{fontSize: '1rem'}}> TransactionType </label>
                <select className="browser-default custom-select" id="TransactionType" name="TransactionType" value={TransactionType} onChange={updateTT} >
                    <option value=""> select </option>
                    { Company && TransactionTypes.map((item, key) => 
                        <option key={key} value={item}> {item} </option>
                    )}
                </select>
            </div>

            {
                !TransactionType || (TransactionType !== "BILL") &&

                <div className='col-md-2'>
                    <label htmlFor="ThemeName" style={{fontSize: '1rem'}}> ThemeName </label>
                    <select className="browser-default custom-select" id="ThemeName" name="ThemeName" value={ThemeName} onChange={updateTN} >
                        <option value=""> select </option>
                        { ThemeNames[TransactionType] ? ThemeNames[TransactionType].map((item, key) => 
                            <option key={key} value={item}> {item} </option>
                            ) : null}
                    </select>
                </div>
            }

            {
                TransactionType === "InstructorInvoice" ?

                <div className='col-md-2'>
                    <label htmlFor="GSTIN" style={{fontSize: '1rem'}}> GSTIN </label>
                    <select className="browser-default custom-select" id="GSTIN" name="GSTIN" value={GSTIN} onChange={updateGSTIN} >
                        <option value=""> select </option>
                        { GSTINs.map((item, key) => 
                            <option key={key} value={item}> {item} </option>
                        )}
                    </select>
                </div>

                :

                TransactionType === "InstructorCostAccrual" || !TransactionType ?

                null
                :

                TransactionType === "BILL" ?

                <div className='col-md-2'>
                    <label htmlFor="DocType" style={{fontSize: '1rem'}}> DocType </label>
                    <select className="browser-default custom-select" id="DocType" name="DocType" value={DocumentType} onChange={(e)=>setDocumentType(e.target.value)} >
                        <option value=""> select </option>
                        { Company && TransactionType && DocumentTypes.map((item, key) => 
                            <option key={key} value={item}> {item} </option>
                        )}
                    </select>
                </div>

                :

                <>
                <div className='col-md-2 pl-5'>
                    <label htmlFor="From" style={{fontSize: '1rem'}}> ForPrdFrom </label>
                    <input type='date' name='From' value={From} onChange={updateFrom}></input>
                </div>
                <div className='col-md-2'>
                    <label htmlFor="To" style={{fontSize: '1rem'}}> ForPrdTo </label>
                    <input type='date' name='To' value={To} onChange={updateTo}></input>
                </div>
                </>
                
            }
            
                      
            <div className='col-md-2'>
            
                <Button 
                disabled={ !Company || !TransactionType || (!ThemeName && TransactionType !== "BILL") || !From || !To || (TransactionType === "InstructorInvoice" && !GSTIN) } 
                component="label" variant="contained" sx={{ marginTop: 3 }} startIcon={<CloudUploadIcon />}>
                    Upload file
                    <VisuallyHiddenInput type="file" accept='.csv, .xlsx, .pdf' onChange={fileInputOnChange} />
                </Button>
            
            </div>
           

        </div>
            
            
            {
                RecentHistory.length > 0 &&

                <div className='mt-5 ml-2'>
                <label className='row ml-1' style={{fontSize: '1rem'}}>Recently Uploaded</label>
                <div className={`scroll-table`}>
                <table id="table-css">
                    <thead>
                        <tr>
                            <th >Date & Time</th>
                            <th >TransactionID</th>
                            <th >TransactionType</th>
                            <th >ThemeName</th>
                            <th >ForPrdFrom</th>
                            <th >ForPrdTo</th>
                            <th >Status</th>
                            <th >SchemaRun</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RecentHistory.sort((a, b) => {
                            // Assuming Start is the timestamp in microseconds
                            const dateA = a.Start ? a.Start / 1000000 : new Date(a.Created_At).getTime();
                            const dateB = b.Start ? b.Start / 1000000 : new Date(b.Created_At).getTime();
                            return dateB - dateA; // Descending order, change to dateA - dateB for ascending
                        }).map((item, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{item.Start ? moment(item.Start / 1000000).format("DD/MM/YYYY HH:mm:ss") : item.Created_At}</td>
                                <td>{item["TransactionID"]}</td>
                                <td>{item["TransactionType"]}</td>
                                <td>{item["ThemeName"]}</td>
                                <td>{item["ForPrdFrom"]}</td>
                                <td>{item["ForPrdTo"]}</td>
                                <td>{item["Status"]}</td>
                                <td>{item["SchemaRun"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                </div>
            }   
    </div>
  )
}

export default Upload3;

