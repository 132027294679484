import React, { Fragment, Component } from "react";
import { getRequest, postRequest } from '../../globalhelper/helper';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { BsCheckCircleFill, BsThreeDots } from 'react-icons/bs';
import { BiTimer } from 'react-icons/bi';
import  ConfirmDialog, { confirmDialog } from '../../components/dialog';




class Compute extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            month : 'current',
            options: [
                {
                value: "current",
                label: "Current Month"
                },
                {
                value: "prev",
                label: "Previous Month"
                }
                ],
            compute_Log : [],    
            header: [
                {
                    sort : true,
                    dataField: 'Year',
                    text: 'Year',
                    formatter : (rowContent, row) => {
                    return <div style={{fontSize: '1.1rem', fontWeight: "bold", textAlign: 'center'}} > 
                        { rowContent}
                    </div>
                }
                },
                {
                    sort : true,
                    dataField: 'Month',
                    text: 'Month',
                    formatter : (rowContent, row) => {
                    return <div style={{fontSize: '1.1rem', fontWeight: "bold", textAlign: 'center'}} > 
                        { rowContent}
                    </div>
                }
                },
                {
                    sort : true,
                    dataField: 'Status',
                    text: 'Status',
                    formatter : (rowContent, row) => {
                        return <div style={{fontSize: '1.1rem', fontWeight: "bold", textAlign: 'center', color: rowContent === "Completed" ? 'green' : rowContent=== "In Progress" ? 'orange' : rowContent=== "Started" ? "blue" : null }} > 
                        { rowContent } 
                        </div>
                }
                },
                {
                    sort : true,
                    dataField: 'Reliance',
                    text: 'Reliance',
                    formatter : (rowContent, row) => {
                        return this.showIcons(rowContent)
                }
                },
                {
                    sort : true,
                    dataField: 'BackendStaff',
                    text: 'BackendStaff',
                    formatter : (rowContent, row) => {
                        return this.showIcons(rowContent)
                }
                },
                {
                    sort : true,
                    dataField: 'Amazon',
                    text: 'Amazon',
                    formatter : (rowContent, row) => {
                    return this.showIcons(rowContent)
                }
                },
                {
                    sort : true,
                    dataField: 'Flipkart',
                    text: 'Flipkart',
                    formatter : (rowContent, row) => {
                        return this.showIcons(rowContent)
                }
                },
                {
                    sort : true,
                    dataField: 'Snapdeal',
                    text: 'Snapdeal',
                    formatter : (rowContent, row) => {
                        return this.showIcons(rowContent)
                }
                },              
                {
                    sort : true,
                    dataField: 'Compilation',
                    text: 'Compilation',
                    formatter : (rowContent, row) => {
                        return this.showIcons(rowContent)
                }
                },
                {
                    sort : true,
                    dataField: 'Dashboard_Update',
                    text: 'Dashboard_Update',
                    formatter : (rowContent, row) => {
                        return this.showIcons(rowContent)
                }
                },
                {
                    sort : true,
                    dataField: 'Completed',
                    text: 'Completed',
                    formatter : (rowContent, row) => {
                        return <div style={{textAlign: 'center', fontWeight: 'bold'}} > 
                        { rowContent }
                        </div>
                }
                },    
                {
                    sort : true,
                    dataField: 'Total',
                    text: 'Total',
                    formatter : (rowContent, row) => {
                        return <div style={{textAlign: 'center', fontWeight: 'bold'}} > 
                        { rowContent }
                        </div>
                }
                },
              ],
            buttonStatus: true    
         }
    }


    showIcons = (rowContent) => {
        return (
        <div style={{textAlign: 'center', fontSize:'1.3rem', color: rowContent === "Completed" ? 'green' : rowContent=== "In Progress" ? 'orange' : null }} > 
         {rowContent  === "Completed" ? <BsCheckCircleFill /> : rowContent=== "In Progress" ? <BsThreeDots /> : rowContent=== "Yet To Start" ? <BiTimer />  : null }
        </div>
        )
    }


    handleChange = (e) => {
        this.setState({
            month: e.target.value    
        },
        ()=> console.log(this.state.month))
    }


    componentDidMount() { 

        getRequest('api/transactions/getComputeLog')
        .then(data => {

            let answer = JSON.parse(data.res.data) 

            answer.map( (each, i) => {
                if (
                    each.Status !== "Completed" || 
                    each.Reliance !== "Completed" || 
                    each.BackendStaff !== "Completed" ||
                    each.Amazon !== "Completed" ||
                    each.Flipkart !== "Completed" ||
                    each.Snapdeal !== "Completed" ||
                    each.Compilation !== "Completed" ||
                    each.Dashboard_Update !== "Completed" 
                    ) {

                    this.setState({ buttonStatus: false})
                    return;
                }               
            })

            this.setState({ compute_Log: answer})
        })
    } 


    computeOnClick = () => {

        confirmDialog(`Do you want to Start Compute for ${this.state.month} month?`, 

        // if user click 'yes' to apply same theme to all
        () => {
            console.log('yes')

            this.setState({ buttonStatus: false},
                ()=> {
                    getRequest(`api/transactions/startCompute?month=${this.state.month}`)
                    .then(res => {
                        console.log(res)
                    })
                })
        },
        //if user click 'no'    
        () => {
            console.log('no')
        })

    }


    render() { 
        return (
                <>
                    <p className="mt-3">Please select a month to compute:</p><br />

                    <div className="row">
                    <select className="mr-5 ml-4" name="month" id="month" value={this.state.month} onChange={this.handleChange}>
                        <option value="" disabled>select</option>
                        {this.state.options.map( each => <option value={each.value}>{each.label}</option>)}
                    </select>

                    <button onClick={this.computeOnClick} className='btn btn-primary' disabled={!this.state.month || !this.state.buttonStatus} >Compute</ button> 

                    </div>  <br/><br/>

                    <ConfirmDialog />


                    <h5 style={{ fontWeight: 'bold'}}>Compute Status Log:</h5>

                    <div className="scroll-div">
                        <BootstrapTable key="table" keyField='ComputeLog' classess="mainTable"  filter={ filterFactory() }  headerWrapperClasses="BootstrapTable" data={ this.state.compute_Log } columns={ this.state.header } pagination={ paginationFactory() }/>
                    </div>
                </>
                
        );
    }
}
 
export default Compute;