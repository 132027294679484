import '../CI/COD.css'
import {Form} from 'react-bootstrap'

function FormBasicSelect({label, value, name, onChange, options}){
    return(
        <div className='col-md-3'>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <select className='spacing' value={value} name={name} onChange={onChange}>
                    <option value=''>Select</option>
                    {options.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </Form.Group>
        </div>
    )
}

export default FormBasicSelect