// import React, { Component, Fragment } from 'react';
// import {Form, Button} from 'react-bootstrap';
// import { getHumanReadableDate, getRequest, postRequest } from '../../globalhelper/helper';
// import '../CI/COD.css'
// import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
// import Popup from '../../components/SimplePOPUP';
// import moment from 'moment';



// export class CostAllocation_Search extends Component {
//     constructor(props) {
//       super(props)
    
//       this.state = {
//          formData:{
//             costallocationmethod: '',
//             forprdfrom: '',
//             orgname: ''
//          },
//          screenAction: '',
//          showtable: false,
//          tableData: [],
//          dropdown_costallocationmethod: [],
//          dropdown_forprdfrom: [],
//          forprdfrom : null,
//          isBetweenDates : true,
//          dropdown_orgname: [],
//          companyDetails: [],
//          error: false,
//          errorMessage: ''
//       }
//     }

//     componentDidMount(){
//         localStorage.setItem("pageName", "Search Cost Allocation Method")

//         getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
//             console.log(response.res.data)
//             const responseData = response.res.data
//             const arr = responseData.map(item => item.orgname)
//             console.log(arr)
//             this.setState({
//                 ...this.state,
//                 dropdown_orgname: arr,
//                 companyDetails: responseData
//             })
//         }).catch(err => console.log(err))
//     }

//     handleChange = (e) => {
//         const {name, value} = e.target

//         this.setState({
//             ...this.state,
//             formData: {
//                 ...this.state.formData,
//                 [name]: value
//             }
//         })
//     }

//     handleOrgChange = (e) => {
//         const NAME = e.target.name, VALUE = e.target.value

//         this.setState({
//             ...this.state,
//             showtable: false,
//             dropdown_costallocationmethod: [],
//             formData: {
//                 ...this.state.formData,
//                 [NAME]: VALUE
//             }
//         }, () => this.handleCostMethod())
//     }

//     handleCostMethod = () => {
//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//         const companyid = id.map(item => item.orgid)

//         getRequest(`api/edureka/getCostAllocationMethodDropdown?orgid=${companyid[0]}`).then(response => {
//             console.log(response.res.data)

//             const costAllocation = response.res.data.map(item => item.costallocationmethod)

//             this.setState({
//                 ...this.state,
//                 dropdown_costallocationmethod: costAllocation,
//                 formData: {
//                     ...this.state.formData,
//                     costallocationmethod: ''
//                 }
//             })
//         })
//     }

//     handleReset = () => {
//         this.setState({
//             ...this.state,
//             showtable: false,
//             error: false,
//             dropdown_costallocationmethod: [],
//             formData: {
//                 costallocationmethod: '',
//                 forprdfrom: '',
//                 orgname: ''
//             }
//         })
//     }

//     handleSearch = () => {
//         const cam = this.state.formData.costallocationmethod

//         if(cam && this.state.formData.orgname){

//             this.setState({
//                 ...this.state,
//                 error: false
//             })

//             const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//             const companyid = id.map(item => item.orgid)

//             postRequest(`api/edureka/getCostAllocationSearchData`, {cam: cam, orgid: companyid[0]}).then(res => {
//                 if(res.res.status === 'success'){
//                     let temp = JSON.parse(res.res.data)
    
//                     console.log(temp)
    
//                     this.setState({
//                         ...this.state,
//                         tableData: temp,
//                         showtable: true
//                     })
//                 } else {
//                     console.log('No Data')
//                 }
//             })
//         } else {
//             this.setState({
//                 ...this.state,
//                 error: true,
//                 errorMessage: "Please select all the required fields"
//             })
//         }

//     }

//     handleCAM = (i) => {
//         const method = i['costallocationmethod']
//         const date = getHumanReadableDate(i['forprdfrom']) 

//         const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//         const companyid = id.map(item => item.orgid)

//         postRequest(`api/edureka/getCostAllocationUpdatedJSON?date=${date}&orgid=${companyid[0]}`, {method: method}).then(res => {
//             if(res.res.status === 'success'){
//                 console.log(res.res.data)

//                 this.setState({
//                     redirect: true,
//                     screenAction: "edit",
//                     SearchJSON: res.res.data,
//                     noQuit: false
//                 })
//             } else {
//                 console.log("No Data")
//             }
//         })
//     }

//     isBetweenDates = (givenDate, startDate, endDate) => {
//         const given = new Date(givenDate);
//         const start = new Date(startDate);
//         const end = new Date(endDate);
      
//         return start <= given && given <= end;
//     }

//     onDateChn= (e) => {

//         let giveDate = e.target.value
//         let oneitem = this.state.oneitem
//         console.log(giveDate, oneitem.forprdfrom, oneitem.forprdto)

//         let isBetweenDates = this.isBetweenDates(giveDate, oneitem.forprdfrom, oneitem.forprdto)
//         this.setState({forprdfrom: giveDate, isBetweenDates: isBetweenDates},
//             () => console.log(this.state.isBetweenDates))
//     }


//     copyItem = (item) => {

//         console.log(item, 'item')

//         this.setState({
//             oneitem : item
//         })
                
//         this.setState({create_Clone : true})
//     }

    
//     create_Clone = (e) => {

//         e.preventDefault()

//         let item = this.state.oneitem

//         const forprdfrom = this.state.forprdfrom
        
//         console.log(forprdfrom)

//         if(forprdfrom){

//             const sourceDate = item.forprdfrom
            
//             let Item = JSON.parse(JSON.stringify(item))
    
//             if(sourceDate !== forprdfrom){

//                 const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

//                 const companyid = id.map(item => item.orgid)
    
//                 let input = {
//                     method : Item.costallocationmethod,
//                     date :  moment(forprdfrom, 'YYYY-MM-DD').format('DD-MM-YYYY'),
//                     sourceDate : moment(sourceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
//                     orgid : companyid[0]
//                 }
    
    
//                 postRequest(`api/edureka/costAllocationCopyJSON`, input).then(res => {
//                     if(res.res.status === 'success'){

//                         let JSON = res.res.data
//                         console.log(JSON)
//                         this.setState({
//                             create_Clone: null,
//                             forprdfrom: null,
//                             redirect: true,
//                             screenAction: "PartialCreate",
//                             SearchJSON: JSON,
//                             noQuit : true
//                         })
//                     } 
//                     else {
//                         console.log("fail")
//                     }
//                 })
                
//             }
//             else {
    
//                 this.setState({create_Clone: null, forprdfrom: null})
//             }
//         }
//     }

//     onBack = () => {
//         this.setState({
//             redirect: false,
//             SearchJSON: []
//         })
//     }

//   render() {

//     if(this.state.redirect){
//         return(
//             <VR2_Forms
//             screenName = {"CostAllocationMaster"}
//             screenAction ={this.state.screenAction}
//             noQuit = {this.state.noQuit}
//             From_SearchJSON = {this.state.SearchJSON}
//             onBack = {this.onBack}
//             />
//         )
//     }
//     else
//     return (
//         <Fragment>
//         { this.state.create_Clone && 
//             <Popup
//                 handleClose={() => this.setState({create_Clone: false})}
//                 content={
//                 <>
//                 <div className="mt-3 mb-3" align="center">
//                 <div className="col-lg-4">For Period From :</div>

//                     <input type="date" className="col-lg-4" name="forprdfrom" id="forprdfrom"
//                         onChange = {this.onDateChn}
//                     />

//                 </div>
//                 <div align="center">
//                     <button onClick={(e) => this.create_Clone(e)} disabled={this.state.isBetweenDates} className="btn btn-success">Copy</button>
//                 </div>
//                 </>
//                 }
//             />
//         }
//         <div className='header_div'>
//             Search Cost Allocation Master
//         </div>

//         <br/>

//         <Form style={{display:'flex',flexDirection:'column'}}>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className="col-lg-2" style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Organization ID:<span style={{color:'red'}}>*</span> </div>
//                 <select className='col-lg-3' value={this.state.formData.orgname} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} name='orgname' onChange={this.handleOrgChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_orgname.sort().map(item => <option value={item}>{item}</option>)}
//                 </select>
//             </div>

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>Cost Allocation Method:</div>
//                 <select className='col-lg-3' name='costallocationmethod' value={this.state.formData.costallocationmethod} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_costallocationmethod.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div>

//             {/* {this.state.formData.costallocationmethod ?

//             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
//                 <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>For Period From:</div>
//                 <select className='col-lg-3' name='forprdfrom' value={this.state.formData.forprdfrom} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
//                     <option value='' disabled>Select</option>
//                     {this.state.dropdown_forprdfrom.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
//                 </select>
//             </div> : null } */}

//             <br/>

//             <div style={{textAlign:'center'}}>
//                 <Button variant='primary' onClick={this.handleSearch}>Search</Button>
//                 <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
//             </div>
//         </Form>

//         <br/>

//         {this.state.showtable && this.state.tableData.length > 0 ?
//         <div>
//             <table border='1' id='table_styling'>
//                 <thead>
//                     <tr>
//                         <th>Cost Allocation Method</th>
//                         <th>For Period From</th>
//                         <th>For Period To</th>
//                         <th>Allocation Type</th>
//                         <th></th>
//                     </tr>
//                 </thead>

//                 <tbody>
//                     {this.state.tableData.sort((a,b)=>a['costallocationmethod'].localeCompare(b['costallocationmethod'])).map((item, index) => {
//                         return (
//                             <tr key={index}>
//                                 <td><Button variant='link' onClick={()=>this.handleCAM(item)}>{item['costallocationmethod']}</Button></td>
//                                 <td>{item['forprdfrom']}</td>
//                                 <td>{item['forprdto']}</td>
//                                 <td>{item['allocationtype']}</td>
//                                 <td><Button style={{marginLeft:10}} variant='success' disabled={!item['forprdfrom'] || !item['forprdto']} onClick={()=>this.copyItem(item)}>Copy</Button></td>
//                             </tr>
//                         )
//                     })}
//                 </tbody>
//             </table>
//         </div>: null}

//         {this.state.error ?
//         <div className='center-screen'>
//             {this.state.errorMessage}
//         </div> :  null}
//       </Fragment>
//     )
//   }
// }

// export default CostAllocation_Search




import React, { Component, Fragment } from 'react';
import {Form, Button} from 'react-bootstrap';
import { getHumanReadableDate, getRequest, postRequest } from '../../globalhelper/helper';
import '../CI/COD.css'
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';
import Popup from '../../components/SimplePOPUP';
import moment from 'moment';
import Select from 'react-select';



export class CostAllocation_Search extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         formData:{
            costallocationmethod: '',
            forprdfrom: '',
            orgname: ''
         },
         screenAction: '',
         showtable: false,
         tableData: [],
         dropdown_costallocationmethod: [],
         dropdown_forprdfrom: [],
         forprdfrom : null,
         isBetweenDates : true,
         dropdown_orgname: [],
         companyDetails: [],
         error: false,
         errorMessage: ''
      }
    }

    componentDidMount(){
        localStorage.setItem("pageName", "Search Cost Allocation Method")

        getRequest(`api/edureka/getCompanyAssignedToUser`).then(response => {
            console.log(response.res.data)
            const responseData = response.res.data
            const arr = responseData.map(item => item.orgname)
            console.log(arr)
            this.setState({
                ...this.state,
                dropdown_orgname: arr,
                companyDetails: responseData
            })
        }).catch(err => console.log(err))
    }

    handleChange = (e) => {
        const {name, value} = e.target

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleOrgChange = (e) => {
        const NAME = e.target.name, VALUE = e.target.value

        this.setState({
            ...this.state,
            showtable: false,
            dropdown_costallocationmethod: [],
            formData: {
                ...this.state.formData,
                [NAME]: VALUE
            }
        }, () => this.handleCostMethod())
    }

    handleCostMethod = () => {
        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

        const companyid = id.map(item => item.orgid)

        getRequest(`api/edureka/getCostAllocationMethodDropdown?orgid=${companyid[0]}`).then(response => {
            console.log(response.res.data)

            const costAllocation = response.res.data.map(item => item.costallocationmethod)

            this.setState({
                ...this.state,
                dropdown_costallocationmethod: costAllocation,
                formData: {
                    ...this.state.formData,
                    costallocationmethod: ''
                }
            })
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            showtable: false,
            error: false,
            dropdown_costallocationmethod: [],
            formData: {
                costallocationmethod: '',
                forprdfrom: '',
                orgname: ''
            }
        })
    }

    handleSearch = () => {
        const cam = this.state.formData.costallocationmethod

        if(cam && this.state.formData.orgname){

            this.setState({
                ...this.state,
                error: false
            })

            const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

            const companyid = id.map(item => item.orgid)

            postRequest(`api/edureka/getCostAllocationSearchData`, {cam: cam, orgid: companyid[0]}).then(res => {
                if(res.res.status === 'success'){
                    let temp = JSON.parse(res.res.data)
    
                    console.log(temp)
    
                    this.setState({
                        ...this.state,
                        tableData: temp,
                        showtable: true
                    })
                } else {
                    console.log('No Data')
                }
            })
        } else {
            this.setState({
                ...this.state,
                error: true,
                errorMessage: "Please select all the required fields"
            })
        }

    }

    handleCAM = (i) => {
        const method = i['costallocationmethod']
        const date = getHumanReadableDate(i['forprdfrom']) 

        const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

        const companyid = id.map(item => item.orgid)

        postRequest(`api/edureka/getCostAllocationUpdatedJSON?date=${date}&orgid=${companyid[0]}`, {method: method}).then(res => {
            if(res.res.status === 'success'){
                console.log(res.res.data)

                this.setState({
                    redirect: true,
                    screenAction: "edit",
                    SearchJSON: res.res.data,
                    noQuit: false
                })
            } else {
                console.log("No Data")
            }
        })
    }

    isBetweenDates = (givenDate, startDate, endDate) => {
        const given = new Date(givenDate);
        const start = new Date(startDate);
        const end = new Date(endDate);
      
        return start <= given && given <= end;
    }

    onDateChn= (e) => {

        let giveDate = e.target.value
        let oneitem = this.state.oneitem
        console.log(giveDate, oneitem.forprdfrom, oneitem.forprdto)

        let isBetweenDates = this.isBetweenDates(giveDate, oneitem.forprdfrom, oneitem.forprdto)
        this.setState({forprdfrom: giveDate, isBetweenDates: isBetweenDates},
            () => console.log(this.state.isBetweenDates))
    }


    copyItem = (item) => {

        console.log(item, 'item')

        this.setState({
            oneitem : item
        })
                
        this.setState({create_Clone : true})
    }

    
    create_Clone = (e) => {

        e.preventDefault()

        let item = this.state.oneitem

        const forprdfrom = this.state.forprdfrom
        
        console.log(forprdfrom)

        if(forprdfrom){

            const sourceDate = item.forprdfrom
            
            let Item = JSON.parse(JSON.stringify(item))
    
            if(sourceDate !== forprdfrom){

                const id = this.state.companyDetails.filter(item => item.orgname === this.state.formData.orgname)

                const companyid = id.map(item => item.orgid)
    
                let input = {
                    method : Item.costallocationmethod,
                    date :  moment(forprdfrom, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    sourceDate : moment(sourceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    orgid : companyid[0]
                }
    
    
                postRequest(`api/edureka/costAllocationCopyJSON`, input).then(res => {
                    if(res.res.status === 'success'){

                        let JSON = res.res.data
                        console.log(JSON)
                        this.setState({
                            create_Clone: null,
                            forprdfrom: null,
                            redirect: true,
                            screenAction: "PartialCreate",
                            SearchJSON: JSON,
                            noQuit : true
                        })
                    } 
                    else {
                        console.log("fail")
                    }
                })
                
            }
            else {
    
                this.setState({create_Clone: null, forprdfrom: null})
            }
        }
    }

    onBack = () => {
        this.setState({
            redirect: false,
            SearchJSON: []
        })
    }

  render() {

    if(this.state.redirect){
        return(
            <VR2_Forms
            screenName = {"CostAllocationMaster"}
            screenAction ={this.state.screenAction}
            noQuit = {this.state.noQuit}
            From_SearchJSON = {this.state.SearchJSON}
            onBack = {this.onBack}
            />
        )
    }
    else
    return (
        <Fragment>
        { this.state.create_Clone && 
            <Popup
                handleClose={() => this.setState({create_Clone: false})}
                content={
                <>
                <div className="mt-3 mb-3" align="center">
                <div className="col-lg-4">For Period From :</div>

                    <input type="date" className="col-lg-4" name="forprdfrom" id="forprdfrom"
                        onChange = {this.onDateChn}
                    />

                </div>
                <div align="center">
                    <button onClick={(e) => this.create_Clone(e)} disabled={this.state.isBetweenDates} className="btn btn-success">Copy</button>
                </div>
                </>
                }
            />
        }
        <div className='header_div'>
            Search Cost Allocation Master
        </div>

        <br/>

        <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className="col-lg-2" style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                    Organization ID:<span style={{ color: 'red' }}>*</span>
                </div>
                <div className='col-lg-3'>
                    <Select
                        value={this.state.dropdown_orgname.find(item => item.value === this.state.formData.orgname)}
                        options={this.state.dropdown_orgname.sort().map(item => ({ value: item, label: item }))}
                        onChange={(selectedOption) => this.handleOrgChange({ target: { name: 'orgname', value: selectedOption ? selectedOption.value : '' } })}
                        placeholder="Select"
                        styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    />
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{ marginRight: 15, fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                    Cost Allocation Method:
                </div>
                <div className='col-lg-3'>
                    <Select
                        value={this.state.dropdown_costallocationmethod.find(item => item.value === this.state.formData.costallocationmethod)}
                        options={this.state.dropdown_costallocationmethod.sort().map(item => ({ value: item, label: item }))}
                        onChange={(selectedOption) => this.handleChange({ target: { name: 'costallocationmethod', value: selectedOption ? selectedOption.value : '' } })}
                        placeholder="Select"
                        styles={{
                        container: (base) => ({ ...base, padding: 5 }),
                        control: (base) => ({ 
                            ...base, 
                            borderRadius: 5, 
                            border: '1px solid #ccc', 
                            minHeight: '38px',  
                            paddingLeft: '0px', // Remove left padding on the control to avoid clipping
                        }),
                        valueContainer: (base) => ({
                            ...base,
                            padding: '0 15px', // Add horizontal padding here instead
                        }),
                        input: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0, 
                        }),
                        singleValue: (base) => ({
                            ...base,
                            margin: 0,
                            padding: 0,
                        })
                    }}
                    />
                </div>
            </div>

            {/* {this.state.formData.costallocationmethod ?

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div className='col-lg-2' style={{marginRight:15, fontFamily:'sans-serif', fontSize: '15', fontWeight: 'bold'}}>For Period From:</div>
                <select className='col-lg-3' name='forprdfrom' value={this.state.formData.forprdfrom} style={{ padding: 5, borderRadius: 5, border: '1px solid #ccc' }} onChange={this.handleChange}>
                    <option value='' disabled>Select</option>
                    {this.state.dropdown_forprdfrom.sort().map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
            </div> : null } */}

            <br/>

            <div style={{textAlign:'center'}}>
                <Button variant='primary' onClick={this.handleSearch}>Search</Button>
                <Button style={{marginLeft:10}} variant='danger' onClick={this.handleReset}>Reset</Button>
            </div>
        </Form>

        <br/>

        {this.state.showtable && this.state.tableData.length > 0 ?
        <div>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th>Cost Allocation Method</th>
                        <th>For Period From</th>
                        <th>For Period To</th>
                        <th>Allocation Type</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.tableData.sort((a,b)=>a['costallocationmethod'].localeCompare(b['costallocationmethod'])).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td><Button variant='link' onClick={()=>this.handleCAM(item)}>{item['costallocationmethod']}</Button></td>
                                <td>{item['forprdfrom']}</td>
                                <td>{item['forprdto']}</td>
                                <td>{item['allocationtype']}</td>
                                <td><Button style={{marginLeft:10}} variant='success' disabled={!item['forprdfrom'] || !item['forprdto']} onClick={()=>this.copyItem(item)}>Copy</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>: null}

        {this.state.error ?
        <div className='center-screen'>
            {this.state.errorMessage}
        </div> :  null}
      </Fragment>
    )
  }
}

export default CostAllocation_Search