import React, { Component, Fragment } from 'react';
import { getHumanReadableDate, getRequest, getPreviousDateInReadableFormat, getPreviousDate } from '../../globalhelper/helper';
import {Button} from 'react-bootstrap';
import '../CI/COD.css';
import VR2_Forms from '../../pages/VR2_Form_Rendering/displayContainer';

export class AreaManagerDashboard extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         fetching: false,
         stationInfo: [],
         AM:[],
         PM:[],
         EXR:[],
         ARM: [],
         date: getPreviousDate(new Date())
      }
    }

    componentDidMount(){
        this.setState({
            fetching: true
        }, () => console.log(this.state.date))

        getRequest(`api/ciportal/getAssignedStations`).then(res => {
            console.log(res.res.data)

            this.setState({
                fetching: false,
                stationInfo: res.res.data
            })
        })

        getRequest(`api/ciportal/getAMStationsDashboard?date=${this.state.date}`).then(res => {
            console.log(res.res.data)
            this.setState({
                AM: res.res.data
            })
        })

        getRequest(`api/ciportal/getPMStationsDashboard?date=${this.state.date}`).then(res => {
            console.log(res.res.data)
            this.setState({
                PM: res.res.data
            })
        })

        getRequest(`api/ciportal/getEXRStationsDashboard?date=${this.state.date}`).then(res => {
            console.log(res.res.data)
            this.setState({
                EXR: res.res.data
            })
        })

        // getRequest(`api/ciportal/getAreaManagerDashboard?date=${this.state.date}`).then(res => {
        //     console.log(res.res.data)
        //     this.setState({
        //         ARM: res.res.data
        //     })
        // })
    }

    handleAM = (item) => {
        console.log(item)
        const branchcode = item.branchcode
        const branchname = item.branchname
        const date = getPreviousDateInReadableFormat(new Date())
        console.log(date)

        if(!this.state.AM.some(items => items.branchcode === branchcode)){
            alert(`${branchcode} haven't posted yet`)
        } else {
            getRequest(`api/ciportal/getAMUpdatedJSON?branchcode=${branchcode}&branchname=${branchname}&date=${date}`).then(res => {
                if(res.res.status === 'success'){
                    console.log(res.res.data)
    
                    this.setState({ redirectAM : true, SearchJSON : res.res.data })                
                } else {
                    console.log("No Data")
                }
            })
        }
    }

    handlePM = (item) => {
        console.log(item)
        const branchcode = item.branchcode
        const branchname = item.branchname
        const date = getPreviousDateInReadableFormat(new Date())

        if(!this.state.PM.some(items => items.branchcode === branchcode)){
            alert(`${branchcode} haven't posted yet`)
        } else {
            getRequest(`api/ciportal/getPMUpdatedJSON?branchcode=${branchcode}&branchname=${branchname}&date=${date}`).then(res => {
                if(res.res.status === 'success'){
                    console.log(res.res.data)
    
                    this.setState({ redirectPM : true, SearchJSON : res.res.data })                
                } else {
                    console.log("No Data")
                }
            })
        }
    }

    handleEXC = (item) => {
        console.log(item)
        const branchcode = item.branchcode
        const branchname = item.branchname
        const date = getPreviousDateInReadableFormat(new Date())

        if(!this.state.EXR.some(items => items.branchcode === branchcode)){
            alert(`${branchcode} haven't posted yet`)
        } else {
            getRequest(`api/ciportal/getEXCUpdatedJSON?branchcode=${branchcode}&branchname=${branchname}&date=${date}`).then(res => {
                if(res.res.status === 'success'){
                    console.log(res.res.data)
    
                    this.setState({ redirectEXC : true, SearchJSON : res.res.data })                
                } else {
                    console.log("No Data")
                }
            })
        }
    }

    // handleARM = (item) => {
    //     console.log(item)
    //     const branchcode = item.branchcode
    //     const areaname = item.areaname
    //     const date = getPreviousDateInReadableFormat(new Date())

    //     if(!this.state.ARM.some(items => items.stations === branchcode)){
    //         alert(`${branchcode} haven't posted yet`)
    //     } else {
    //         getRequest(`api/ciportal/getAreaManagerUpdatedJSON?branchcode=${branchcode}&areaname=${areaname}&date=${date}`).then(res => {
    //             if(res.res.status === 'success'){
    //                 console.log(res.res.data)
    
    //                 this.setState({ redirectEXC : true, SearchJSON : res.res.data })                
    //             } else {
    //                 console.log("No Data")
    //             }
    //         })
    //     }
    // }

  render() {
    if(this.state.redirectAM){
        return(
            <VR2_Forms
            screenName = {"AM_Screen"}
            screenAction = {"edit"}  
            From_SearchJSON = {this.state.SearchJSON}          
            />        
        )
    }

    if(this.state.redirectPM){
        return(
            <VR2_Forms
            screenName = {"PM_Screen"}
            screenAction = {"edit"}  
            From_SearchJSON = {this.state.SearchJSON}          
            />        
        )
    }

    if(this.state.redirectEXC){
        return(
            <VR2_Forms
            screenName = {"EXC_Screen"}
            screenAction = {"edit"}  
            From_SearchJSON = {this.state.SearchJSON}          
            />        
        )
    }
    return (
      <Fragment>
        <div className='header_div'>My Dashboard</div>

        <br/>

        {!this.state.fetching &&

        <div className='offset-2'>
            <table border='1' id='table_styling'>
                <thead>
                    <tr>
                        <th style={{minWidth:50, textAlign:'center'}}>S.No</th>
                        <th style={{minWidth:200, textAlign:'center'}}>AM Report</th>
                        <th style={{minWidth:200, textAlign:'center'}}>PM Report</th>
                        <th style={{minWidth:200, textAlign:'center'}}>Exception Report</th>
                        {/* <th style={{minWidth:200, textAlign:'center'}}>Area Manager Report</th> */}
                    </tr>
                </thead>
                <tbody>
                    {this.state.stationInfo.sort((a,b)=>a.branchcode.localeCompare(b.branchcode)).map((item, index)=> {
                        return (
                            <tr key={index}>
                                <td style={{textAlign:'center'}}>{index+1}</td>
                                <td style={{textAlign:'center'}}><Button variant={this.state.AM.some(items=>items.branchcode ===item.branchcode) ? 'success' : 'danger'} onClick={() => this.handleAM(item)}>{item.branchcode}</Button></td>
                                <td style={{textAlign:'center'}}><Button variant={this.state.PM.some(items=>items.branchcode ===item.branchcode) ? 'success' : 'danger'} onClick={() => this.handlePM(item)}>{item.branchcode}</Button></td>
                                <td style={{textAlign:'center'}}><Button variant={this.state.EXR.some(items=>items.branchcode ===item.branchcode) ? 'success' : 'danger'} onClick={() => this.handleEXC(item)}>{item.branchcode}</Button></td>
                                {/* <td style={{textAlign:'center'}}><Button variant={this.state.ARM.some(items=>items.stations ===item.branchcode) ? 'success' : 'danger'} onClick={() => this.handleARM(item)}>{item.branchcode}</Button></td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        } 

        {this.state.fetching ? <div className='center'>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
              <div class="wave"></div>
        </div>: null}
      </Fragment>
    )
  }
}

export default AreaManagerDashboard