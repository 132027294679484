import { SET_FORM_VALUE, SET_SELECTED_OBJ } from "./formTypes";

export const setFormValue = (key, value) => {
  return {
    type: SET_FORM_VALUE,
    payload: { key, value },
  };
};


export const setSelectedObj = (object) => {
  return{
    type : SET_SELECTED_OBJ,
    payload : object
  }
}